import React, { useState, Fragment, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Dialog, Transition } from "@headlessui/react";
import Layout from "../layout";
import { axiosAuth } from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { setTitle, setToken, setUser } from "../store/slices/mainSlice";
import { useLocation, useParams } from "react-router-dom";
import Pagination from "../components/pagination";
import DateComponent from "../components/date";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/animations/loading.json";
import deposit from "../assets/img/deposit.png";
import door from "../assets/img/door.png";
import floppy from "../assets/img/floppy.png";

const Deposits = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [action, setAction] = useState("edit");

  const { page } = useParams();
  const user = useSelector((state) => state.main.user);

  const [deposits, setDeposits] = useState([]);
  const [branches, setBranches] = useState([]);

  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);

  const [depositId, setDepositId] = useState(0);
  const [branchId, setBranchId] = useState(0);
  const [description, setDescription] = useState("");
  const [active, setActive] = useState(0);
  const [deleted, setDeleted] = useState(0);

  async function fetchDeposits() {
    let pageQuery = page === undefined ? 1 : page;

    setIsFetching(true);

    let deposits = await axiosAuth.get("/deposits?page=" + pageQuery);

    setIsFetching(false);

    if (deposits.data.error) {
      if (deposits.data.message === "token") {
        await axiosAuth.post("/logout");
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    setDeposits(deposits.data.deposits.rows);
    setTotal(deposits.data.deposits.count);
    setPages(deposits.data.deposits.pages);
    setBranches(deposits.data.branches);
  }

  useEffect(() => {
    fetchDeposits();
    dispatch(setTitle('Depósitos'))
  }, []);

  useEffect(() => {
    fetchDeposits();
  }, [location]);

  const editDeposit = (depositId) => {
    setAction("edit");
    setDepositId(depositId);

    let deposit = deposits.filter((deposit) => deposit.id === depositId)[0];

    setDescription(deposit.description);
    setBranchId(deposit.branchId);

    setActive(deposit.active);
    setDeleted(deposit.deleted);

    setOpen(true);
  };

  const clearFields = () => {
    setDepositId(0);
    setDescription("");
    setBranchId(0);
    setActive(0);
    setDeleted(0);
  };

  const handleSubmit = async () => {
    if (description === "" || branchId === 0) {
      alert("Debes completar los campos para continuar.");
      return false;
    }

    if (action === "edit") {
      let id = depositId;
      let newDepositsObj = [];

      let updateDepositRequest = await axiosAuth.put("/deposits", {
        id,
        description,
        branchId,
        active,
        deleted,
      });

      if (updateDepositRequest.data.error) {
        if (updateDepositRequest.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      deposits.map((deposit) => {
        if (deposit.id == id) {
          newDepositsObj.push(updateDepositRequest.data);
        } else {
          newDepositsObj.push(deposit);
        }
        return "";
      });

      setDeposits(newDepositsObj);
    } else {
      let createDepositRequest = await axiosAuth.post("/deposits", {
        description,
        branchId: parseInt(branchId),
        active,
        deleted,
      });

      if (createDepositRequest.data.error) {
        if (createDepositRequest.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      let newDeposits = [...deposits];
      newDeposits.push(createDepositRequest.data);
      setDeposits(newDeposits);
    }

    setOpen(false);
    clearFields();
  };

  const deleteDeposit = async (depositId) => {
    if (window.confirm("Desea eliminar este deposito ?")) {
      let depositDeleteRequest = await axiosAuth.delete("/deposits", {
        params: { id: depositId },
      });

      if (depositDeleteRequest.data.error) {
        if (depositDeleteRequest.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      let newDepositsObj = [];

      deposits.map((deposit) => {
        if (deposit.id !== depositId) {
          newDepositsObj.push(deposit);
        } else {
          if (user.roleId == 1) {
            deposit.deleted = 1;
            newDepositsObj.push(deposit);
          }
        }
        return "";
      });

      setDeposits(newDepositsObj);
    }
  };

  return (
    <>
      <Layout>
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              clearFields();
              setOpen(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto align-items-center justify-center items-center flex">
              <div className="flex justify-center p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-3xl bg-white text-left shadow-xl transition-all sm:my-8 w-[280px]">
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-0">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                          <Dialog.Title
                            as="h1"
                            className="text-center font-regular leading-6 primary-color text-3xl"
                          >
                            {action === "edit" ? "Editar" : "Crear"} deposito
                          </Dialog.Title>
                          <div className="mt-5">
                            <div className="w-full max-w-xs">
                              <form className="bg-white rounded">
                                {action === "edit" && (
                                  <div className="mb-4">
                                    <label
                                      className="block text-gray-700 text-sm font-bold"
                                      htmlFor="DepositId"
                                    >
                                      Id
                                    </label>
                                    <input
                                      readOnly
                                      className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      id="DepositId"
                                      type="text"
                                      value={depositId}
                                    />
                                  </div>
                                )}

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="description"
                                  >
                                    Descripcion
                                  </label>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                    id="description"
                                    type="text"
                                    value={description}
                                    onChange={(e) =>
                                      setDescription(e.target.value)
                                    }
                                  />
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="branchId"
                                  >
                                    Sucursal
                                  </label>
                                  <select
                                    className="input-bg-color appearance-none border rounded w-full py-2 
                                    px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="branchId"
                                    value={branchId}
                                    onChange={(e) =>
                                      setBranchId(e.target.value)
                                    }
                                  >
                                    <option key={0} value={0}>
                                      Elija una sucursal
                                    </option>
                                    {branches?.map((branch) => (
                                      <option key={branch.id} value={branch.id}>
                                        {branch.company.name} -{" "}
                                        {branch.description}
                                      </option>
                                    ))}
                                  </select>
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="active"
                                  >
                                    Activa
                                  </label>
                                  <select
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="active"
                                    value={active}
                                    onChange={(e) =>
                                      setActive(parseInt(e.target.value))
                                    }
                                  >
                                    <option value={0}>No</option>
                                    <option value={1}>Si</option>
                                  </select>
                                </div>

                                {user.roleId == 1 && (
                                  <div className="mb-4">
                                    <label
                                      className="block text-gray-700 text-sm font-bold"
                                      htmlFor="deleted"
                                    >
                                      Borrado
                                    </label>
                                    <select
                                      className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      id="deleted"
                                      value={deleted}
                                      onChange={(e) =>
                                        setDeleted(parseInt(e.target.value))
                                      }
                                    >
                                      <option value={0}>No</option>
                                      <option value={1}>Si</option>
                                    </select>
                                  </div>
                                )}
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 justify-center flex sm:px-6">
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => {
                          clearFields();
                          setOpen(false);
                        }}
                      >
                        <img src={door} className="w-5" />
                        CERRAR
                      </button>
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => handleSubmit()}
                      >
                        <img src={floppy} className="w-8" />
                        GUARDAR
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <h1 className="ml-5 mt-5 text-3xl secondary-color font-family-thin title">
          Depositos
        </h1>
        <DateComponent className="sm:block hidden" />
        <button
          type="button"
          className="relative right-[25px] sm:right-[50px] bottom-[10px] inline-flex w-[68px] float-right m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
          style={{ alignItems: "center" }}
          onClick={() => {
            setAction("create");
            setOpen(true);
          }}
        >
          <img src={deposit} className="w-8" />
          CREAR DEPOSITO
        </button>

        <div className="w-[95%] p-5 mx-auto overflow-auto">
          <table className="w-[95%] table-auto mx-auto w-full text-xs">
            <thead>
              <tr>
                <th className="uppercase">Id</th>
                <th className="uppercase">Descripcion</th>
                <th className="uppercase">Sucursal</th>
                <th className="uppercase">Activa</th>
                {user.roleId == 1 && <th className="uppercase">Borrado</th>}
                <th className="uppercase">Fecha Alta</th>
                <th className="uppercase">Accion</th>
              </tr>
            </thead>
            <tbody>
              {isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={8}>
                    <Lottie
                      animationData={loadingAnimation}
                      style={{ width: 100, height: 100, margin: "0 auto" }}
                      loop={true}
                    />
                  </td>
                </tr>
              )}
              {deposits.length > 0 &&
                !isFetching &&
                deposits?.map((deposit) => {
                  return (
                    <tr key={deposit.id}>
                      <td>{deposit.id}</td>
                      <td>{deposit.description}</td>
                      <td>{deposit.branch.description}</td>
                      <td>{deposit.active ? "Si" : "No"}</td>
                      {user.roleId == 1 && (
                        <td>{deposit.deleted ? "Si" : "No"}</td>
                      )}
                      <td>
                        {deposit.createdAt
                          .substr(0, 10)
                          .split("-")
                          .reverse()
                          .join("/")}
                      </td>
                      <td className="text-center">
                        <button
                          className="bg-transparent"
                          onClick={() => {
                            editDeposit(deposit.id);
                          }}
                        >
                          <FontAwesomeIcon
                            className="m-1 cursor-pointer secondary-color"
                            icon={faPen}
                          />
                        </button>
                        <button
                          className="bg-transparent"
                          onClick={() => {
                            deleteDeposit(deposit.id);
                          }}
                        >
                          <FontAwesomeIcon
                            className="m-1 cursor-pointer secondary-color"
                            icon={faTrash}
                          />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              {deposits.length <= 0 && !isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={8} className="text-center">
                    Sin registros (0)
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <Pagination
            path="depositos"
            page={page}
            pages={pages}
            total={total}
          />
        </div>
      </Layout>
    </>
  );
};

export default Deposits;
