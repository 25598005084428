import React, { useState, Fragment, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Dialog, Transition } from "@headlessui/react";
import Layout from "../layout";
import { axiosAuth } from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser } from "../store/slices/mainSlice";
import { useLocation, useParams } from "react-router-dom";
import Pagination from "../components/pagination";
import DateComponent from "../components/date";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/animations/loading.json";
import roleimg from "../assets/img/role.png";
import door from "../assets/img/door.png";
import floppy from "../assets/img/floppy.png";

const Roles = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector((state) => state.main.user);

  const [isFetching, setIsFetching] = useState(false);
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState("edit");
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);

  const { page } = useParams();

  const [roles, setRoles] = useState([]);

  const [roleId, setRoleId] = useState(0);
  const [description, setDescription] = useState("");
  const [deleted, setDeleted] = useState(0);

  async function fetchRoles() {
    let pageQuery = page == undefined ? 1 : page;

    setIsFetching(true);

    let roles = await axiosAuth.get("/roles?page=" + pageQuery);

    if (roles.data.error) {
      if (roles.data.message === "token") {
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    setIsFetching(false);

    setRoles(roles.data.rows);
    setPages(roles.data.pages);
    setTotal(roles.data.count);
  }

  useEffect(() => {
    fetchRoles();
  }, []);

  useEffect(() => {
    fetchRoles();
  }, [location]);

  let editRole = (roleId) => {
    setAction("edit");
    setRoleId(roleId);

    let role = roles.filter((role) => role.id === roleId);

    setDescription(role[0].description);
    setDeleted(role[0].deleted);
    setOpen(true);
  };

  const clearFields = () => {
    setDescription("");
    setRoleId(0);
    setDeleted(0);
  };

  const handleSubmit = async () => {
    if (action === "edit") {
      let newRoles = [];

      let updateRoleRequest = await axiosAuth.put("/roles", {
        id: roleId,
        description: description,
        deleted: deleted,
      });

      if (updateRoleRequest.data.error) {
        if (updateRoleRequest.data.message === "token") {
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      roles.map((role) => {
        if (role.id == roleId) {
          newRoles.push(updateRoleRequest.data);
        } else {
          newRoles.push(role);
        }
        return "";
      });

      setRoles(newRoles);
    } else {
      let newRoles = [...roles];

      let createRoleRequest = await axiosAuth.post("/roles", {
        description,
        deleted,
      });

      if (createRoleRequest.data.error) {
        if (createRoleRequest.data.message === "token") {
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      newRoles.push(createRoleRequest.data);
      setRoles(newRoles);
    }

    setOpen(false);
    clearFields();
  };

  const deleteRole = async (id) => {
    if (window.confirm("Desea eliminar este rol ?")) {
      let deleteRoleRequest = await axiosAuth.delete("/roles", {
        params: { id },
      });

      if (deleteRoleRequest.data.error) {
        if (deleteRoleRequest.data.message === "token") {
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      let newRoles = [];

      roles.map((role) => {
        if (role.id !== id) {
          newRoles.push(role);
        } else {
          if (user.roleId == 1) {
            role.deleted = 1;
            newRoles.push(role);
          }
        }
        return "";
      });

      setRoles(newRoles);
    }
  };

  return (
    <>
      <Layout>
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              clearFields();
              setOpen(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto align-items-center justify-center items-center flex">
              <div className="flex justify-center p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-3xl bg-white text-left shadow-xl transition-all sm:my-8 w-[280px]">
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-0">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                          <Dialog.Title
                            as="h1"
                            className="text-center font-regular leading-6 primary-color text-3xl"
                          >
                            {action === "edit" ? "Editar" : "Crear"} rol
                          </Dialog.Title>
                          <div className="mt-5">
                            <div className="w-full max-w-xs">
                              <form className="bg-white rounded">
                                {action === "edit" && (
                                  <div className="mb-4">
                                    <label
                                      className="block text-gray-700 text-sm font-bold"
                                      htmlFor="id"
                                    >
                                      Id
                                    </label>
                                    <input
                                      readOnly
                                      className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      id="id"
                                      type="text"
                                      value={roleId}
                                    />
                                  </div>
                                )}
                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="description"
                                  >
                                    Descripcion
                                  </label>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                    id="description"
                                    type="text"
                                    value={description}
                                    onChange={(e) =>
                                      setDescription(e.target.value)
                                    }
                                  />
                                </div>
                                {user.roleId == 1 && (
                                  <div className="mb-4">
                                    <label
                                      className="block text-gray-700 text-sm font-bold"
                                      htmlFor="deleted"
                                    >
                                      Borrado
                                    </label>
                                    <select
                                      className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      id="deleted"
                                      value={deleted}
                                      onChange={(e) =>
                                        setDeleted(parseInt(e.target.value))
                                      }
                                    >
                                      <option value={0}>No</option>
                                      <option value={1}>Si</option>
                                    </select>
                                  </div>
                                )}
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 justify-center flex sm:px-6">
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => {
                          clearFields();
                          setOpen(false);
                        }}
                      >
                        <img src={door} className="w-5" />
                        CERRAR
                      </button>
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => handleSubmit()}
                      >
                        <img src={floppy} className="w-8" />
                        GUARDAR
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <h1 className="ml-5 mt-5 text-3xl title secondary-color font-family-thin">
          Roles
        </h1>
        <DateComponent className="sm:block hidden" />
        <button
          type="button"
          className="relative right-[25px] sm:right-[50px] bottom-[10px] inline-flex w-[70px] float-right m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
          style={{ alignItems: "center" }}
          onClick={() => {
            setAction("create");
            setOpen(true);
          }}
        >
          <img src={roleimg} className="w-16" />
          CREAR ROL
        </button>

        <div className="w-[95%] p-5 mx-auto overflow-auto">
          <table className="w-[95%] table-auto mx-auto w-full text-xs">
            <thead>
              <tr>
                <th>Id</th>
                <th>Descripcion</th>
                {user.roleId == 1 && <th>Borrado</th>}
                <th>Fecha Alta</th>
                <th>Accion</th>
              </tr>
            </thead>
            <tbody>
              {isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={8}>
                    <Lottie
                      animationData={loadingAnimation}
                      style={{ width: 100, height: 100, margin: "0 auto" }}
                      loop={true}
                    />
                  </td>
                </tr>
              )}
              {roles.length > 0 &&
                !isFetching &&
                roles?.map((role) => {
                  return (
                    <tr key={role.id}>
                      <td>{role.id}</td>
                      <td>{role.description}</td>
                      {user.roleId == 1 && (
                        <td>{role.deleted ? "Si" : "No"}</td>
                      )}
                      <td>
                        {role.createdAt
                          .substr(0, 10)
                          .split("-")
                          .reverse()
                          .join("/")}
                      </td>
                      <td className="text-center">
                        <button
                          className="bg-transparent"
                          onClick={() => {
                            editRole(role.id);
                          }}
                        >
                          <FontAwesomeIcon
                            className="m-1 cursor-pointer secondary-color"
                            icon={faPen}
                          />
                        </button>
                        <button
                          className="bg-transparent"
                          onClick={() => {
                            deleteRole(role.id);
                          }}
                        >
                          <FontAwesomeIcon
                            className="m-1 cursor-pointer secondary-color"
                            icon={faTrash}
                          />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              {roles.length <= 0 && !isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={5} className="text-center">
                    Sin registros (0)
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <Pagination pages={pages} page={page} total={total} path="roles" />
        </div>
      </Layout>
    </>
  );
};

export default Roles;
