import React, { useState, useRef, useEffect } from "react";
import Layout from "../layout";
import { axiosAuth } from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser, setTitle } from "../store/slices/mainSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DateComponent from "../components/date";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/animations/loading.json";
import ComboBox from "../components/combobox";

import { DownloadTableExcel } from "react-export-table-to-excel";
import xls from "../assets/img/xls.png";
import search from "../assets/img/search.webp";
import door from "../assets/img/door.png";
import pdf from "../assets/img/pdf.png";
import printer from "../assets/img/printer.png";
import { numberWithCommas } from "../utils/constants";

const SalesReport = (props) => {
  const tableRef = useRef(null);

  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();
  const [reporType, setReporType] = useState(0);
  const dispatch = useDispatch();
  const [isFetching, setIsFetching] = useState(false);
  const [salesRankingByQty, setSalesRankingByQty] = useState(true);
  const [salesRankingByAmount, setSalesRankingByAmount] = useState(false);
  const [type, seType] = useState(state != undefined ? state.type : 0);

  const user = useSelector((state) => state.main.user);

  const [companyId, setCompanyId] = useState(
    user != undefined ? user.companyId : 0
  );
  const [clients, setClients] = useState([]);
  const [clientId, setClientId] = useState(0);

  const [reports, setReports] = useState([]);

  const [dateFromFilter, setDateFromFilter] = useState(() => {
    let MyDateString =
      (
        "0" +
        new Date(new Date().getFullYear(), new Date().getMonth(), 1).getDate()
      ).slice(-2) +
      "/" +
      (
        "0" +
        (new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          1
        ).getMonth() +
          1)
      ).slice(-2) +
      "/" +
      new Date(new Date().getFullYear(), new Date().getMonth(), 1)
        .getFullYear()
        .toLocaleString();

    return state != undefined
      ? state.dateFromFilter
      : MyDateString.replaceAll(".", "")
          .replaceAll("/", "-")
          .split("-")
          .reverse()
          .join("-");
  });
  const [dateToFilter, setDateToFilter] = useState(() => {
    return state != undefined
      ? state.dateToFilter
      : new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
          .toLocaleDateString()
          .replaceAll("/", "-")
          .split("-")
          .reverse()
          .map((d) => (d.length <= 1 ? "0" + String(d) : d))
          .join("-");
  });

  async function fetchReport() {
    setIsFetching(true);

    let reportRequest = await axiosAuth.get(
      "/salesranking?type=" +
        type +
        "&dateFrom=" +
        dateFromFilter +
        "&dateTo=" +
        dateToFilter +
        "&salesRankingByQty=" +
        salesRankingByQty +
        "&salesRankingByAmount=" +
        salesRankingByAmount
    );

    if (reportRequest.data.error) {
      if (reportRequest.data.message === "token") {
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    setIsFetching(false);
    setReports(reportRequest.data);
  }

  useEffect(() => {
    async function fetchClients() {
      let clientsRequest = await axiosAuth.get("/clients?page=1");

      if (clientsRequest.data.error) {
        if (clientsRequest.data.message === "token") {
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      setClients(clientsRequest.data.clients.rows);
    }

    // fetchClients();
  }, []);

  useEffect(() => {
    dispatch(setTitle("Ranking de Ventas"));
  }, []);

  const searchClients = async (search) => {
    const searchClientsRequest = await axiosAuth.get(
      "/searchClients?search=" + search
    );

    setClients(searchClientsRequest.data.clients.rows);
  };

  return (
    <>
      <Layout>
        <div className="flex flex-col md:flex-row border border-gray-200 border-r-0 border-b-1">
          <div className="hidden sm:block w-[70%] md:w-[40%] min-w-[212px] border border-b-0 border-r-2 h-[98%]">
            <h1 className="ml-5 mt-5 text-[20px] secondary-color title font-family-thin">
              Ranking de Ventas
            </h1>
            <DateComponent className="!text-[15px] sm:block hidden" />
          </div>

          <div className="flex flex-row md:flex-col lg:flex-row justify-center items-center w-full md:w-[45%] m-[auto] mr-4 sm:ml-2 ml-6">
            <div className="mr-6 flex flex-col items-center justify-start">
              {/* <div className="flex flex-row justify-start items-center">
                <ComboBox
                  data={clients}
                  setData={setClientId}
                  value={clientId}
                  placeholder="Cliente"
                  className="h-[27px] w-[100%] mb-2"
                  search={searchClients}
                />
              </div> */}

              <div className="w-[100%] flex flex-row justify-start items-center">
                <input
                  id="byItemFilter"
                  name="byItemFilter"
                  checked={salesRankingByQty}
                  type="checkbox"
                  className="mr-1"
                  onChange={(e) => {
                    setReports([]);
                    setSalesRankingByAmount(false);
                    setSalesRankingByQty(!salesRankingByQty);
                  }}
                />
                <label
                  className="block text-gray-700 text-sm uppercase !text-[11px]"
                  htmlFor="byItemFilter"
                >
                  Por cantidad
                </label>
              </div>

              <div className="w-[100%] flex flex-row justify-start items-center">
                <input
                  id="byItemFilter"
                  name="byItemFilter"
                  checked={salesRankingByAmount}
                  type="checkbox"
                  className="mr-1"
                  onChange={(e) => {
                    setReports([]);
                    setSalesRankingByQty(false);
                    setSalesRankingByAmount(!salesRankingByAmount);
                  }}
                />
                <label
                  className="block text-gray-700 text-sm uppercase !text-[11px]"
                  htmlFor="byItemFilter"
                >
                  Por monto
                </label>
              </div>
            </div>

            <div className="mr-5 w-[25%] min-w-[120px] mb-2">
              <input
                type="date"
                id="dateFromFilter"
                name="dateFromFilter"
                value={dateFromFilter}
                className="input-bg-color appearance-none border w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-[22px] font-[11px] mt-[1px]"
                onChange={(e) => {
                  setReports([]);
                  setDateFromFilter(e.target.value);
                }}
              />

              <input
                type="date"
                id="dateToFilter"
                name="dateToFilter"
                value={dateToFilter}
                className="input-bg-color appearance-none border w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-[22px] font-[11px] mt-[1px]"
                onChange={(e) => {
                  setReports([]);
                  setDateToFilter(e.target.value);
                }}
              />
            </div>
          </div>

          <div className="flex flex-row w-full justify-center md:w-[55%] mt-1 h-[75px] mr-[6%] relative sm:right-0 right-[-7%] sales-buttons-container">
            <div className="flex flex-col items-center h-[70px]">
              <button
                type="button"
                className="inline-flex flex-col w-[75px] h-[55px] mr-[2px] justify-center items-center rounded ternary-bg-color p-4 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 !text-[11px]"
                onClick={() => {
                  setReports([]);
                  fetchReport();
                }}
              >
                <img src={search} className="w-8" />
                CONSULTAR
              </button>
            </div>

            <div className="flex flex-col items-center h-[70px]">
              <DownloadTableExcel
                filename={
                  salesRankingByQty
                    ? "RANKING_VENTAS_"
                    : "RANKING_VENTAS_MONTO_" + new Date().toLocaleDateString()
                }
                sheet="RANKING-VENTAS"
                currentTableRef={tableRef.current}
              >
                <button
                  type="button"
                  className="inline-flex flex-col w-[75px] h-[55px] justify-center items-center rounded ternary-bg-color p-4 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 !text-[11px] mr-[2px]"
                >
                  <img src={xls} className="w-8" />
                  EXPORTAR
                </button>
              </DownloadTableExcel>
            </div>

            <div className="flex flex-col items-center hidden sm:block">
              <button
                type="button"
                className="inline-flex flex-col w-[75px] h-[55px] mr-[2px] justify-center items-center rounded ternary-bg-color p-4 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 !text-[11px]"
                onClick={() => {}}
              >
                <img src={printer} className="w-8" />
                IMPRIMIR
              </button>
            </div>

            <div className="flex flex-col items-center">
              <button
                type="button"
                className="inline-flex flex-col w-[75px] h-[55px] mr-[2px] justify-center items-center rounded ternary-bg-color p-4 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 !text-[11px]"
                onClick={() => {}}
              >
                <img src={pdf} className="w-8" />
                PDF
              </button>
            </div>

            <div className="flex flex-col items-center">
              <button
                type="button"
                className="inline-flex flex-col w-[75px] h-[55px] mr-[2px] justify-center items-center rounded ternary-bg-color p-4 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 !text-[11px]"
                onClick={() => {
                  navigate("/");
                }}
              >
                <img src={door} className="w-4" />
                SALIR
              </button>
            </div>
          </div>
        </div>

        <div
          className={`w-[95%] p-5 pb-0 mx-auto overflow-auto max-h-[300px] sm:max-h-[400px] relative`}
        >
          <table
            className="w-[95%] table-auto mx-auto w-full text-xs sales-report-table relative"
            ref={tableRef}
          >
            <thead className="bg-gray-600 !text-white relative">
              <tr className="w-full">
                <th className="!text-white uppercase">PRODUCTO</th>
                <th className="!text-white text-center uppercase">CANTIDAD</th>
                <th className="!text-white text-right uppercase">MONTO</th>
              </tr>
            </thead>

            <tbody>
              {isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={20}>
                    <Lottie
                      animationData={loadingAnimation}
                      style={{ width: 100, height: 100, margin: "0 auto" }}
                      loop={true}
                    />
                  </td>
                </tr>
              )}
              {!isFetching &&
                reports?.map((report, r) => {
                  return (
                    <>
                      <tr key={report.id} className="text-[10px]">
                        <td className="whitespace-nowrap text-left">
                          {report.name}
                        </td>
                        <td className="text-center whitespace-nowrap">
                          {report.qty}
                        </td>
                        <td className="text-right whitespace-nowrap">
                          {numberWithCommas(report.amount)}
                        </td>
                      </tr>
                    </>
                  );
                })}
            </tbody>
          </table>
        </div>
      </Layout>
    </>
  );
};

export default SalesReport;
