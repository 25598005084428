import React, { useState, Fragment, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Dialog, Transition } from "@headlessui/react";
import Layout from "../layout";
import { axiosAuth } from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser } from "../store/slices/mainSlice";
import { useLocation, useParams } from "react-router-dom";
import Pagination from "../components/pagination";
import DateComponent from "../components/date";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/animations/loading.json";
import accountypeimg from "../assets/img/accountype.webp";
import door from "../assets/img/door.png";
import floppy from "../assets/img/floppy.png";
import { color } from "framer-motion";

const CheckStatements = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [action, setAction] = useState("edit");

  const { page } = useParams();
  const user = useSelector((state) => state.main.user);

  const [checkStatements, setCheckStatements] = useState([]);

  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);

  const [checkStatementId, setCheckStatementId] = useState(0);
  const [description, setDescription] = useState("");
  const [colour, setColour] = useState("");
  const [active, setActive] = useState(1);
  const [deleted, setDeleted] = useState(0);

  async function fetchCheckStatements() {
    let pageQuery = page === undefined ? 1 : page;

    setIsFetching(true);

    let checkStatementsRequest = await axiosAuth.get(
      "/checkstatements?page=" + pageQuery
    );

    setIsFetching(false);

    if (checkStatementsRequest.data.error) {
      if (checkStatementsRequest.data.message === "token") {
        await axiosAuth.post("/logout");
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    setCheckStatements(checkStatementsRequest.data.checkStatements.rows);
    setTotal(checkStatementsRequest.data.checkStatements.count);
    setPages(checkStatementsRequest.data.checkStatements.pages);
  }

  useEffect(() => {
    fetchCheckStatements();
  }, []);

  useEffect(() => {
    fetchCheckStatements();
  }, [location]);

  const editCheckStatement = (checkStatementId) => {
    setAction("edit");
    setCheckStatementId(checkStatementId);

    let checkStatement = checkStatement.filter(
      (checkStatement) => checkStatement.id === checkStatementId
    )[0];

    setDescription(checkStatement.description);
    setColour(checkStatement.colour);
    setActive(checkStatement.active);
    setDeleted(checkStatement.deleted);

    setOpen(true);
  };

  const clearFields = () => {
    setCheckStatementId(0);
    setDescription("");
    setColour("");
    setActive(1);
    setDeleted(0);
  };

  const handleSubmit = async () => {
    if (description === "" || colour === "") {
      alert("Debes completar los campos para continuar.");
      return false;
    }

    if (action === "edit") {
      let id = checkStatementId;
      let newCheckStatementsObj = [];

      let updateCheckStatementRequest = await axiosAuth.put("/checkstatements", {
        id,
        description,
        colour,
        active,
        deleted,
      });

      if (updateCheckStatementRequest.data.error) {
        if (updateCheckStatementRequest.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      checkStatements.map((checkStatement) => {
        if (checkStatement.id == id) {
          newCheckStatementsObj.push(updateCheckStatementRequest.data);
        } else {
          newCheckStatementsObj.push(checkStatement);
        }
        return "";
      });

      setCheckStatements(newCheckStatementsObj);
    } else {
      let createCheckStaementRequest = await axiosAuth.post("/checkstatements", {
        description,
        colour,
        active,
        deleted,
      });

      if (createCheckStaementRequest.data.error) {
        if (createCheckStaementRequest.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      let newCheckStatements = [...checkStatements];
      newCheckStatements.push(createCheckStaementRequest.data);
      setCheckStatements(newCheckStatements);
    }

    setOpen(false);
  };

  const deleteCheckStatement = async (checkStatementId) => {
    if (window.confirm("Desea eliminar este tipo de cuenta ?")) {
      let checkStatementDeleteRequest = await axiosAuth.delete("/checkstatements", {
        params: { id: checkStatementId },
      });

      if (checkStatementDeleteRequest.data.error) {
        if (checkStatementDeleteRequest.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      let newCheckStatementsObj = [];

      checkStatements.map((checkStatement) => {
        if (checkStatement.id !== checkStatementId) {
            newCheckStatementsObj.push(checkStatement);
        } else {
          if (user.roleId == 1) {
            checkStatement.deleted = 1;
            newCheckStatementsObj.push(checkStatement);
          }
        }
        return "";
      });

      setCheckStatements(newCheckStatementsObj);
    }
  };

  return (
    <>
      <Layout>
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              clearFields();
              setOpen(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto align-items-center justify-center items-center flex">
              <div className="flex justify-center p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform sm:mt-[100px] mt-[0px] overflow-hidden bg-white text-left shadow-xl transition-all sm:my-8 w-[270px] pb-4 rounded-3xl">
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-0">
                      <div className="sm:flex sm:items-start w-full">
                        <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                          <Dialog.Title
                            as="h1"
                            className="text-center font-regular leading-6 primary-color text-3xl"
                          >
                            {action === "edit" ? "Editar" : "Crear"} estado de cheque
                          </Dialog.Title>
                          <div className="mt-5">
                            <div className="w-full">
                              <form className="bg-white rounded w-full">
                                {action === "edit" && (
                                  <div className="mb-4">
                                    <label
                                      className="block text-gray-700 text-sm font-bold"
                                      htmlFor="checkStatementId"
                                    >
                                      Id
                                    </label>
                                    <input
                                      readOnly
                                      className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      id="checkStatementId"
                                      type="text"
                                      value={checkStatementId}
                                    />
                                  </div>
                                )}

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="description"
                                  >
                                    Descripcion
                                  </label>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                    id="description"
                                    type="text"
                                    value={description}
                                    onChange={(e) =>
                                      setDescription(e.target.value)
                                    }
                                  />
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="colour"
                                  >
                                    Color (En ingles ej: red, blue)
                                  </label>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="colour"
                                    type="text"
                                    value={colour}
                                    onChange={(e) =>
                                      setColour(e.target.value)
                                    }
                                  />
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="active"
                                  >
                                    Activa
                                  </label>
                                  <select
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="active"
                                    value={active}
                                    onChange={(e) =>
                                      setActive(parseInt(e.target.value))
                                    }
                                  >
                                    <option value={0}>No</option>
                                    <option value={1}>Si</option>
                                  </select>
                                </div>

                                {user.roleId == 1 && (
                                  <div className="mb-4">
                                    <label
                                      className="block text-gray-700 text-sm font-bold"
                                      htmlFor="deleted"
                                    >
                                      Borrado
                                    </label>
                                    <select
                                      className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      id="deleted"
                                      value={deleted}
                                      onChange={(e) =>
                                        setDeleted(parseInt(e.target.value))
                                      }
                                    >
                                      <option value={0}>No</option>
                                      <option value={1}>Si</option>
                                    </select>
                                  </div>
                                )}
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 justify-center flex sm:px-6">
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => {
                          clearFields();
                          setOpen(false);
                        }}
                      >
                        <img src={door} className="w-5" />
                        CERRAR
                      </button>
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => handleSubmit()}
                      >
                        <img src={floppy} className="w-8" />
                        GUARDAR
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <h1 className="ml-5 mt-5 text-3xl text-white title secondary-color font-family-thin">
          Estados de Cheques
        </h1>
        <DateComponent className="sm:block hidden" />
        <button
          type="button"
          className="relative right-[25px] sm:right-[50px] bottom-[10px] inline-flex w-[68px] float-right m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
          style={{ alignItems: "center" }}
          onClick={() => {
            setAction("create");
            setOpen(true);
          }}
        >
          <img src={accountypeimg} className="w-8" />
          CREAR ESTADO DE CHEQUE
        </button>

        <div className="w-[97%] p-5 mx-auto overflow-auto">
          <table className="w-[97%] table-auto mx-auto w-full text-xs">
            <thead>
              <tr>
                <th className="uppercase">Id</th>
                <th className="uppercase">Descripcion</th>
                <th className="uppercase">Color</th>
                <th className="uppercase">Activa</th>
                {user.roleId == 1 && <th className="uppercase">Borrado</th>}
                <th className="uppercase">Fecha Alta</th>
                <th className="uppercase">Accion</th>
              </tr>
            </thead>
            <tbody>
              {isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={18}>
                    <Lottie
                      animationData={loadingAnimation}
                      style={{ width: 100, height: 100, margin: "0 auto" }}
                      loop={true}
                    />
                  </td>
                </tr>
              )}
              {checkStatements.length > 0 &&
                !isFetching &&
                checkStatements?.map((checkStatement) => {
                  return (
                    <tr key={checkStatement.id} style={{ color: checkStatement.colour }}>
                      <td>{checkStatement.id}</td>
                      <td>{checkStatement.description}</td>
                      <td>{checkStatement.colour}</td>
                      <td>{checkStatement.active ? "Si" : "No"}</td>
                      {user.roleId == 1 && (
                        <td>{checkStatement.deleted ? "Si" : "No"}</td>
                      )}
                      <td>
                        {checkStatement.createdAt
                          .substr(0, 10)
                          .split("-")
                          .reverse()
                          .join("/")}
                      </td>
                      <td className="text-center">
                        <button
                          className="bg-transparent"
                          onClick={() => {
                            editCheckStatement(checkStatement.id);
                          }}
                        >
                          <FontAwesomeIcon
                            className="m-1 secondary-color cursor-pointer"
                            icon={faPen}
                          />
                        </button>
                        <button
                          className="bg-transparent"
                          onClick={() => {
                            deleteCheckStatement(checkStatement.id);
                          }}
                        >
                          <FontAwesomeIcon
                            className="m-1 secondary-color cursor-pointer"
                            icon={faTrash}
                          />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              {checkStatements.length <= 0 && !isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={12} className="text-center">
                    Sin registros (0)
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <Pagination
            path="estadosdecheq"
            page={page}
            pages={pages}
            total={total}
          />
        </div>
      </Layout>
    </>
  );
};

export default CheckStatements;
