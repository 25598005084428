import axios from "axios";
import { store } from "../store/index";
import { host } from "./constants";

const axiosNoAuth = axios.create({
  baseURL: host,
  timeout: 60000,
});

const axiosAuth = axios.create({
  baseURL: host,
  timeout: 60000,
});

axiosAuth.interceptors.request.use(function (config) {
  config.headers.Authorization = "Bearer " + store.getState().main.token;
  return config;
});

export { axiosNoAuth, axiosAuth };
