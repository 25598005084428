import React, { useState, Fragment, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Dialog, Transition } from "@headlessui/react";
import Layout from "../layout";
import { axiosAuth } from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser } from "../store/slices/mainSlice";
import { useLocation, useParams } from "react-router-dom";
import Pagination from "../components/pagination";
import DateComponent from "../components/date";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/animations/loading.json";
import zoneimg from "../assets/img/zone.png";
import door from "../assets/img/door.png";
import floppy from "../assets/img/floppy.png";

const Zones = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector((state) => state.main.user);

  const [isFetching, setIsFetching] = useState(false);
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState("edit");
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);

  const { page } = useParams();

  const [zones, setZones] = useState([]);
  const [companies, setCompanies] = useState([]);

  const [zoneId, setZoneId] = useState(0);
  const [companyId, setCompanyId] = useState(user.companyId);
  const [name, setName] = useState("");
  const [observations, setObservations] = useState("");
  const [deleted, setDeleted] = useState(0);

  async function fetchZones() {
    let pageQuery = page == undefined ? 1 : page;

    setIsFetching(true);

    let zones = await axiosAuth.get("/zones?page=" + pageQuery);

    if (zones.data.error) {
      if (zones.data.message === "token") {
        await axiosAuth.post("/logout");
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    setIsFetching(false);

    setZones(zones.data.zones.rows);
    setPages(zones.data.zones.pages);
    setTotal(zones.data.zones.count);

    setCompanies(zones.data.companies.rows);
  }

  useEffect(() => {
    fetchZones();
  }, []);

  useEffect(() => {
    fetchZones();
  }, [location]);

  let editZone = (zoneId) => {
    setAction("edit");
    setZoneId(zoneId);

    let zone = zones.filter((zone) => zone.id === zoneId)[0];

    setCompanyId(zone.companyId);
    setName(zone.name);
    setDeleted(zone.deleted);
    setObservations(zone.observations);
    setOpen(true);
  };

  const clearFields = () => {
    setName("");
    setObservations("");
    setZoneId(0);
    setDeleted(0);
  };

  const handleSubmit = async () => {
    if (action === "edit") {
      let newZones = [];

      let updateZoneRequest = await axiosAuth.put("/zones", {
        id: zoneId,
        companyId,
        name,
        observations,
        deleted,
      });

      if (updateZoneRequest.data.error) {
        if (updateZoneRequest.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      zones.map((zone) => {
        if (zone.id == zoneId) {
          newZones.push(updateZoneRequest.data);
        } else {
          newZones.push(zone);
        }
        return "";
      });

      setZones(newZones);
    } else {
      let newZones = [...zones];

      let createZoneRequest = await axiosAuth.post("/zones", {
        companyId,
        name,
        observations,
        deleted,
      });

      if (createZoneRequest.data.error) {
        if (createZoneRequest.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      newZones.push(createZoneRequest.data);
      setZones(newZones);
    }

    setOpen(false);
    clearFields();
  };

  const deleteZone = async (id) => {
    if (window.confirm("Desea eliminar esta zona ?")) {
      let deleteZoneRequest = await axiosAuth.delete("/zones", {
        params: { id },
      });

      if (deleteZoneRequest.data.error) {
        if (deleteZoneRequest.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      let newZones = [];

      zones.map((zone) => {
        if (zone.id !== id) {
          newZones.push(zone);
        } else {
          if (user.roleId == 1) {
            zone.deleted = 1;
            newZones.push(zone);
          }
        }
        return "";
      });

      setZones(newZones);
    }
  };

  return (
    <>
      <Layout>
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              clearFields();
              setOpen(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto align-items-center justify-center items-center flex">
              <div className="flex justify-center p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-3xl bg-white text-left shadow-xl transition-all sm:my-8 w-[280px]">
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-0">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                          <Dialog.Title
                            as="h1"
                            className="text-center font-regular leading-6 primary-color text-3xl"
                          >
                            {action === "edit" ? "Editar" : "Crear"} zona
                          </Dialog.Title>
                          <div className="mt-5">
                            <div className="w-full max-w-xs">
                              <form className="bg-white rounded">
                                {action === "edit" && (
                                  <div className="mb-4">
                                    <label
                                      className="block text-gray-700 text-sm font-bold"
                                      htmlFor="id"
                                    >
                                      Id
                                    </label>
                                    <input
                                      readOnly
                                      className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      id="id"
                                      type="text"
                                      value={zoneId}
                                    />
                                  </div>
                                )}

                                {user.roleId == 1 && (
                                  <div className="mb-4">
                                    <label
                                      className="block text-gray-700 text-sm font-bold"
                                      htmlFor="companyId"
                                    >
                                      Empresa
                                    </label>
                                    <select
                                      className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                      id="companyId"
                                      value={companyId}
                                      onChange={(e) =>
                                        setCompanyId(e.target.value)
                                      }
                                    >
                                      <option>Seleccione una empresa</option>
                                      {companies.length > 0 &&
                                        companies.map((company) => (
                                          <option value={company.id}>
                                            {company.name}
                                          </option>
                                        ))}
                                    </select>
                                  </div>
                                )}

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="name"
                                  >
                                    Nombre
                                  </label>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                    id="name"
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                  />
                                </div>
                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="observations"
                                  >
                                    Observaciones
                                  </label>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="observations"
                                    type="text"
                                    value={observations}
                                    onChange={(e) =>
                                      setObservations(e.target.value)
                                    }
                                  />
                                </div>
                                {user.roleId == 1 && (
                                  <div className="mb-4">
                                    <label
                                      className="block text-gray-700 text-sm font-bold"
                                      htmlFor="deleted"
                                    >
                                      Borrado
                                    </label>
                                    <select
                                      className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      id="deleted"
                                      value={deleted}
                                      onChange={(e) =>
                                        setDeleted(parseInt(e.target.value))
                                      }
                                    >
                                      <option value={0}>No</option>
                                      <option value={1}>Si</option>
                                    </select>
                                  </div>
                                )}
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 justify-center flex sm:px-6">
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => {
                          clearFields();
                          setOpen(false);
                        }}
                      >
                        <img src={door} className="w-5" />
                        CERRAR
                      </button>
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => handleSubmit()}
                      >
                        <img src={floppy} className="w-8" />
                        GUARDAR
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <h1 className="ml-5 mt-5 text-3xl secondary-color title font-family-thin">
          Zonas
        </h1>
        <DateComponent className="sm:block hidden" />

        <button
          type="button"
          className="relative right-[25px] sm:right-[50px] bottom-[10px] inline-flex w-[78px] float-right m-1 mb-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
          style={{ alignItems: "center" }}
          onClick={() => {
            setAction("create");
            setOpen(true);
          }}
        >
          <img src={zoneimg} className="w-16" />
          CREAR ZONA
        </button>

        <div className="w-[95%] p-5 mx-auto overflow-auto">
          <table className="w-[95%] table-auto mx-auto w-full text-xs">
            <thead>
              <tr>
                <th className="uppercase">Id</th>
                {user.roleId == 1 && <th className="uppercase">Empresa</th>}
                <th className="uppercase">Nombre</th>
                <th className="uppercase">Observaciones</th>
                {user.roleId == 1 && <th className="uppercase">Borrado</th>}
                <th className="uppercase">Fecha Alta</th>
                <th className="uppercase">Accion</th>
              </tr>
            </thead>
            <tbody>
              {isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={8}>
                    <Lottie
                      animationData={loadingAnimation}
                      style={{ width: 100, height: 100, margin: "0 auto" }}
                      loop={true}
                    />
                  </td>
                </tr>
              )}
              {zones?.length > 0 &&
                !isFetching &&
                zones?.map((zone) => {
                  return (
                    <tr key={zone.id}>
                      <td>{zone.id}</td>
                      {user.roleId == 1 && <td>{zone.company.name}</td>}
                      <td>{zone.name}</td>
                      <td>{zone.observations}</td>
                      {user.roleId == 1 && (
                        <td>{zone.deleted ? "Si" : "No"}</td>
                      )}
                      <td>
                        {zone.createdAt
                          .substr(0, 10)
                          .split("-")
                          .reverse()
                          .join("/")}
                      </td>
                      <td className="text-center">
                        <button
                          className="bg-transparent"
                          onClick={() => {
                            editZone(zone.id);
                          }}
                        >
                          <FontAwesomeIcon
                            className="m-1 cursor-pointer secondary-color"
                            icon={faPen}
                          />
                        </button>
                        <button
                          className="bg-transparent"
                          onClick={() => {
                            deleteZone(zone.id);
                          }}
                        >
                          <FontAwesomeIcon
                            className="m-1 cursor-pointer secondary-color"
                            icon={faTrash}
                          />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              {zones.length <= 0 && !isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={6} className="text-center">
                    Sin registros (0)
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <Pagination pages={pages} page={page} total={total} path="zonas" />
        </div>
      </Layout>
    </>
  );
};

export default Zones;
