import React, { useState, Fragment, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Dialog, Transition } from "@headlessui/react";
import Layout from "../layout";
import { axiosAuth } from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser } from "../store/slices/mainSlice";
import { useLocation, useParams } from "react-router-dom";
import Pagination from "../components/pagination";
import DateComponent from "../components/date";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/animations/loading.json";
import groupimg from "../assets/img/groups.png";
import door from "../assets/img/door.png";
import floppy from "../assets/img/floppy.png";
import plus from "../assets/img/plus.png";
import useViewport from "../utils/useViewport";
import { motion } from "framer-motion";

const Groups = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [action, setAction] = useState("edit");

  const { page,search } = useParams();
  const user = useSelector((state) => state.main.user);

  const [groups, setGroups] = useState([]);
  const [companies, setCompanies] = useState([]);

  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);

  const [groupId, setGroupId] = useState(0);
  const [companyId, setCompanyId] = useState(user.companyId);
  const [name, setName] = useState("");
  const [active, setActive] = useState(1);
  const [deleted, setDeleted] = useState(0);
  const [searchText, setSearchText] = useState(search || "");

  async function fetchGroups() {
    let pageQuery = page === undefined ? 1 : page;

    setIsFetching(true);

    let groups = await axiosAuth.get("/groups?page=" + pageQuery);

    if (groups.data.error) {
      if (groups.data.message === "token") {
        await axiosAuth.post("/logout");
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    setIsFetching(false);

    setGroups(groups.data.groups.rows);
    setTotal(groups.data.groups.count);
    setPages(groups.data.groups.pages);

    setCompanies(groups.data.companies.rows);
  }

  useEffect(() => {
    fetchGroups();
  }, []);

  useEffect(() => {
    fetchGroups();
  }, [location]);

  const editGroup = (groupId) => {
    setAction("edit");
    setGroupId(groupId);

    let group = groups.filter((group) => group.id === groupId)[0];

    setCompanyId(group.companyId);
    setName(group.name);
    setActive(group.active);
    setDeleted(group.deleted);

    setOpen(true);
  };

  const clearFields = () => {
    setGroupId(0);
    setName("");
    setActive(0);
    setDeleted(0);
  };

  const handleSubmit = async () => {
    if (name === "") {
      alert("Debes completar los campos para continuar.");
      return false;
    }

    if (action === "edit") {
      let id = groupId;
      let newGroupsObj = [];

      let updateGroupRequest = await axiosAuth.put("/groups", {
        id,
        companyId,
        name,
        active,
        deleted,
      });

      if (updateGroupRequest.data.error) {
        if (updateGroupRequest.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      groups.map((group) => {
        if (group.id == id) {
          newGroupsObj.push(updateGroupRequest.data);
        } else {
          newGroupsObj.push(group);
        }
        return "";
      });

      setGroups(newGroupsObj);
    } else {
      let createdGroupRequest = await axiosAuth.post("/groups", {
        companyId,
        name,
        active,
        deleted,
      });

      if (createdGroupRequest.data.error) {
        if (createdGroupRequest.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      let newGroups = [...groups];
      newGroups.push(createdGroupRequest.data);
      setGroups(newGroups);
    }

    setOpen(false);
  };

  const searchGroup = async (searchTxt) => {
    setSearchText(searchTxt);
   

    const searchGroupRequest = await axiosAuth.get(
      "/searchGroup?search=" + searchTxt
    );
   console.log(searchGroupRequest);
    setGroups(searchGroupRequest.data.groups.rows);
    setPages(searchGroupRequest.data.groups.pages);
    setTotal(searchGroupRequest.data.groups.total);
  };

  const deleteGroup = async (groupId) => {
    if (window.confirm("Desea eliminar este grupo ?")) {
      let groupDeleteRequest = await axiosAuth.delete("/groups", {
        params: { id: groupId },
      });

      if (groupDeleteRequest.data.error) {
        if (groupDeleteRequest.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      let newGroupsObj = [];

      groups.map((group) => {
        if (group.id !== groupId) {
          newGroupsObj.push(group);
        } else {
          if (user.roleId == 1) {
            group.deleted = 1;
            newGroupsObj.push(group);
          }
        }
        return "";
      });

      setGroups(newGroupsObj);
    }
  };

  return (
    <>
      <Layout>
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              clearFields();
              setOpen(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto align-items-center justify-center items-center flex">
              <div className="flex justify-center p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel
                    className="relative transform overflow-hidden rounded-3xl bg-white text-left shadow-xl transition-all sm:my-8 w-[280px]"
                    style={{ marginTop: 45 }}
                  >
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-0">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                          <Dialog.Title
                            as="h1"
                            className="text-center font-regular leading-6 primary-color text-3xl"
                          >
                            {action === "edit" ? "Editar" : "Crear"} grupo
                          </Dialog.Title>
                          <div className="mt-5">
                            <div className="w-full max-w-xs">
                              <form className="bg-white rounded">
                                {action === "edit" && (
                                  <div className="mb-4">
                                    <label
                                      className="block text-gray-700 text-sm font-bold"
                                      htmlFor="GroupId"
                                    >
                                      Id
                                    </label>
                                    <input
                                      readOnly
                                      className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      id="GroupId"
                                      type="text"
                                      value={groupId}
                                    />
                                  </div>
                                )}

                                {user.roleId == 1 && (
                                  <div className="mb-4">
                                    <label
                                      className="block text-gray-700 text-sm font-bold"
                                      htmlFor="companyId"
                                    >
                                      Empresa
                                    </label>
                                    <select
                                      className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                      id="companyId"
                                      type="text"
                                      value={companyId}
                                      onChange={(e) =>
                                        setCompanyId(e.target.value)
                                      }
                                    >
                                      <option>Elija una empresa</option>
                                      {companies.length > 0 &&
                                        companies.map((company) => (
                                          <option value={company.id}>
                                            {company.name}
                                          </option>
                                        ))}
                                    </select>
                                  </div>
                                )}

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="name"
                                  >
                                    Nombre
                                  </label>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                    id="name"
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                  />
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="active"
                                  >
                                    Activo
                                  </label>
                                  <select
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="active"
                                    value={active}
                                    onChange={(e) =>
                                      setActive(parseInt(e.target.value))
                                    }
                                  >
                                    <option value={0}>No</option>
                                    <option value={1}>Si</option>
                                  </select>
                                </div>

                                {user.roleId == 1 && (
                                  <div className="mb-4">
                                    <label
                                      className="block text-gray-700 text-sm font-bold"
                                      htmlFor="deleted"
                                    >
                                      Borrado
                                    </label>
                                    <select
                                      className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      id="deleted"
                                      value={deleted}
                                      onChange={(e) =>
                                        setDeleted(parseInt(e.target.value))
                                      }
                                    >
                                      <option value={0}>No</option>
                                      <option value={1}>Si</option>
                                    </select>
                                  </div>
                                )}
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 justify-center flex sm:px-6">
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => {
                          clearFields();
                          setOpen(false);
                        }}
                      >
                        <img src={door} className="w-5" />
                        CERRAR
                      </button>
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => handleSubmit()}
                      >
                        <img src={floppy} className="w-8" />
                        GUARDAR
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

       {/*  <h1 className="ml-5 mt-5 text-3xl secondary-color title font-family-thin">
          Grupos
        </h1>
        <DateComponent className="sm:block hidden" />
        <button
          type="button"
          className="relative right-[25px] sm:right-[50px] bottom-[10px] inline-flex w-[68px] float-right m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
          style={{ alignItems: "center" }}
          onClick={() => {
            setAction("create");
            setOpen(true);
          }}
        >
          <img src={groupimg} className="w-16" />
          CREAR GRUPO
        </button> */}
        <div
          class="w-full top-[-50px] flex flex-col sm:flex-row pb-2 relative bg-white "
          style={{ borderBottom: "1px solid lightgray" }}
        >
          <div className="sm:w-[30%] w-[60%]">
            <h1 className="ml-5 mt-7 text-3x1 secondary-color title font-family-thin">
              Grupos
            </h1>
            <DateComponent className="sm:block hidden" />{" "}
          </div>

          <div class="sm:w-[50%] w-[85%] mb-[1px] sm:ml-0 ml-6 flex flex-row items-center w-200 sm:mt-2 mt-12">
            <label className="block text-gray-700 text-[10px] font-bold uppercase mr-[1px] sm:mt-5">
              Buscar:
            </label>
            <input
              value={searchText}
              className="input-bg-color appearance-none border w-[350px]  rounded  
            py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline leading-[10px] h-[35px] sm:mt-5"
              placeholder="Nombre de Grupos"
              onChange={(e) =>searchGroup(e.target.value)}
            />
            <motion.button
              initial={false}
              whileTap="press"
              onClick={() => {
                  setAction("create");
                  setOpen(true);
              }}
              className="inline-flex flex-row float-right sm:w-[170px] w-[30%] mr-1 justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 relative left-[10px] sm:mt-5 h-[35px] "
            >
              {/*<Suspense fallback={null}>
                <BoxesIcon isHover={true} />
            </Suspense>*/}
              <img alt="" src={plus} className="w-8" />
              CREAR GRUPO
            </motion.button>
          </div>
        </div>

        <div className="w-[95%] p-3 mx-auto overflow-auto relative top-[-20px]">
          <table className="table-auto mx-auto w-full text-xs support-table">
            <thead>
              <tr className="bg-gray-500 h-8">
                <th className="text-left !text-white">Id</th>
                {user.roleId == 1 && <th className="text-left !text-white">Empresa</th>}
                <th className="text-left !text-white">Nombre</th>
                <th className="text-left !text-white">Activo</th>
                {user.roleId == 1 && <th className="text-left !text-white">Borrado</th>}
                <th className="text-left !text-white">Fecha Alta</th>
                <th className="text-center !text-white">Accion</th>
              </tr>
            </thead>
            <tbody>
              {isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={8}>
                    <Lottie
                      animationData={loadingAnimation}
                      style={{ width: 100, height: 100, margin: "0 auto" }}
                      loop={true}
                    />
                  </td>
                </tr>
              )}
              {groups.length > 0 &&
                !isFetching &&
                groups?.map((group) => {
                  return (
                    <tr key={group.id}>
                      <td className="text-left">{group.id}</td>
                      {user.roleId == 1 && <td className="text-left">{group.company.name}</td>}
                      <td className="text-left">{group.name}</td>
                      <td className="text-left">{group.active ? "Si" : "No"}</td>
                      {user.roleId == 1 && (
                        <td className="text-left">{group.deleted ? "Si" : "No"}</td>
                      )}
                      <td className="text-left">
                        {group.createdAt
                          .substr(0, 10)
                          .split("-")
                          .reverse()
                          .join("/")}
                      </td>
                      <td className="text-center">
                        <button
                          className="bg-transparent"
                          onClick={() => {
                            editGroup(group.id);
                          }}
                        >
                          <FontAwesomeIcon
                            className="m-1 cursor-pointer secondary-color"
                            icon={faPen}
                          />
                        </button>
                        <button
                          className="bg-transparent"
                          onClick={() => {
                            deleteGroup(group.id);
                          }}
                        >
                          <FontAwesomeIcon
                            className="m-1 cursor-pointer secondary-color"
                            icon={faTrash}
                          />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              {groups.length <= 0 && !isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={8} className="text-center">
                    Sin registros (0)
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <Pagination path="grupos" page={page} pages={pages} total={total} />
        </div>
      </Layout>
    </>
  );
};

export default Groups;
