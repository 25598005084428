import React, { useState, Fragment, useEffect, useRef } from "react";
import { Dialog, Transition, Tab } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Layout from "../layout";
import { axiosAuth } from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser } from "../store/slices/mainSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DateComponent from "../components/date";
import ComboBox from "../components/combobox";
import useViewport from "../utils/useViewport";
import toast from "react-hot-toast";
import { numberWithCommas, formatDate } from "../utils/constants";
import door from "../assets/img/door.png";
import broom from "../assets/img/broom.png";
import floppy from "../assets/img/floppy.png";
import discard from "../assets/img/discard.png";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/animations/loading.json";

const PurchasesCreate = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const [action, setAction] = useState("create");
  const [description, setDescription] = useState("");
  const [web, setWeb] = useState("");
  const [webDescription, setWebDescription] = useState("");
  const [fiscalDescription, setFiscalDescription] = useState("");
  const [webSpecification, setWebSpecification] = useState("");
  const [factoryDescription, setFactoryDescription] = useState("");
  const [barcode, setBarcode] = useState("");
  const [weight, setWeight] = useState("");
  const [height, setHeight] = useState("");
  const [broad, setBroad] = useState("");
  const [depth, setDepth] = useState("");
  const [NCM, setNCM] = useState("");
  const [extent, setExtent] = useState("");
  const [dimensions, setDimensions] = useState("");
  const [model, setModel] = useState("");
  const [colours, setColours] = useState("");
  const [iva, setIva] = useState("");
  const [internalcode, setInternalCode] = useState("");
  const [factorycode, setFactoryCode] = useState("");
  const [itemTypeId, setItemTypeId] = useState(0);
  const [brandId, setBrandId] = useState(0);
  const [groupId, setGroupId] = useState(0);
  const [measurementUnityId, setMeasurementUnityId] = useState(0);
  const [promotion, setPromotion] = useState(0);
  const [website, setWebsite] = useState(0);
  const [observations, setObservations] = useState("");
  const [price, setPrice] = useState("");
  const [price2, setPrice2] = useState("");
  const [price3, setPrice3] = useState("");
  const [costFob, setCostFob] = useState("");
  const [medCostFob, setMedCostFob] = useState("");
  const [costCif, setCostCif] = useState("");
  const [medCostCif, setMedCostCif] = useState("");
  const [image, setImage] = useState("");
  const [image2, setImage2] = useState("");
  const [image3, setImage3] = useState("");
  const [image4, setImage4] = useState("");
  const [image5, setImage5] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [image2Preview, setImage2Preview] = useState("");
  const [image3Preview, setImage3Preview] = useState("");
  const [image4Preview, setImage4Preview] = useState("");
  const [image5Preview, setImage5Preview] = useState("");
  const [imageFile, setImageFile] = useState([]);
  const [image2File, setImage2File] = useState([]);
  const [image3File, setImage3File] = useState([]);
  const [image4File, setImage4File] = useState([]);
  const [image5File, setImage5File] = useState([]);

  const [itemTypes, setItemTypes] = useState([]);
  const [brands, setBrands] = useState([]);
  const [groups, setGroups] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [measurementUnities, setMeasurementUnities] = useState([]);

  const [lastBuy, setLastBuy] = useState("");
  const [lastSell, setLastSell] = useState("");
  const [name, setName] = useState("");
  const [itemId, setItemId] = useState(0);
  const [active, setActive] = useState(0);
  const [itemIdx, setItemIdx] = useState(0);

  const duesRef = useRef();
  const duesRefB = useRef();

  const { width } = useViewport();

  const { page } = useParams();
  const user = useSelector((state) => state.main.user);

  const [providers, setProviders] = useState([]);
  const [provider, setProvider] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [conditions, setConditions] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [filteredPaymentMethods, setFilteredPaymentMethods] = useState([]);
  const [filteredPaymentMethodsCompleted, setFilteredPaymentMethodsCompleted] =
    useState(false);

  const [open, setOpen] = useState(false);
  const [itemsOpen, setItemsOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [checksOpen, setChecksOpen] = useState(false);
  const [willFocus, setWillFocus] = useState(false);
  const [willFocusB, setWillFocusB] = useState(false);
  const [purchases, setPurchases] = useState([]);
  const [items, setItems] = useState([]);
  const [articles, setArticles] = useState([]);
  const [deposits, setDeposits] = useState([]);

  const [number, setNumber] = useState(0);
  const [expeditionPoint, setExpeditionPoint] = useState();
  const [stamped, setStamped] = useState(user.box.stamped);

  const [totalAmount, setTotalAmount] = useState();
  const [totalAmountIva5, setTotalIva5] = useState();
  const [totalAmountIva10, setTotalIva10] = useState();

  const [checks, setChecks] = useState([
    {
      bankId: 0,
      name: "",
      number: 0,
      depositNumber: 0,
      issueDate: "",
      expirationDate: "",
      amount: 0,
      currencyId: 0,
    },
  ]);
  const [dues, setDues] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [banks, setBanks] = useState([]);
  const [account, setAccount] = useState([]);
  const [accountId, setAccountId] = useState(0);
  const [purchaseId, setPurchaseId] = useState(0);
  const [providerId, setProviderId] = useState(0);
  const [depositId, setDepositId] = useState(0);
  const [currencyId, setCurrencyId] = useState(1);
  const [sellerId, setSellerId] = useState(user.id);
  const [companyId, setCompanyId] = useState(user.companyId);
  const [branchId, setBranchId] = useState(user.branchId);
  const [boxId, setBoxId] = useState(user.boxId);
  const [conditionId, setConditionId] = useState(1);
  const [cotizacion, setCotizacion] = useState(1);
  const [paymentMethodId, setPaymentMethodId] = useState(0);
  const [precioUno, setPrecioUno] = useState(0);
  const [utilidadUno, setUtilidadUno] = useState(0);
  const [precioDos, setPrecioDos] = useState(0);
  const [utilidadDos, setUtilidadDos] = useState(0);
  const [internalMovement, setInternalMovement] = useState(false);



  const [date, setDate] = useState(function () {
    let newDate = new Date().toLocaleDateString();
    return newDate
      .split("/")
      .reverse()
      .map((d) => {
        if (d.length == 1) {
          return "0" + d;
        } else {
          return d;
        }
      })
      .join("-");
  });

  const [deleted, setDeleted] = useState(0);

  async function fetchPurchases() {
    let pageQuery = page === undefined ? 1 : page;

    setIsFetching(true);

    let purchasesRequest = await axiosAuth.get(
      "/purchases?page=" +
      pageQuery +
      "&companyId=" +
      user.companyId +
      "&branchId=" +
      user.branchId
    );

    if (purchasesRequest.data.error) {
      if (purchasesRequest.data.message === "token") {
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    setProviders(purchasesRequest.data.providers.rows);
    setCurrencies(purchasesRequest.data.currencies);
    setBanks(purchasesRequest.data.banks);
    setAccounts(purchasesRequest.data.accounts);
    purchasesRequest.data.items.rows.push({
      id: 'add',
      name: 'Crear articulo',
      price: 0
    });
    setArticles(purchasesRequest.data.items.rows);

    setConditions(purchasesRequest.data.saleconditions);
    setPaymentMethods(purchasesRequest.data.paymentmethods);
    setDeposits(purchasesRequest.data.deposits);
    setItemTypes(purchasesRequest.data.itemTypes);
    setBrands(purchasesRequest.data.brands);
    setGroups(purchasesRequest.data.groups);
    setMeasurementUnities(purchasesRequest.data.measurementUnities);

    if (purchasesRequest.data.deposits.length > 0) {
      setDepositId(purchasesRequest.data.deposits[0].id);
    }

    let newFilteredPaymentMethods = [];

    purchasesRequest.data.paymentmethods.map((paymentMethod) => {
      if (paymentMethod.saleConditionId == conditionId) {
        paymentMethod.amount = null;
        newFilteredPaymentMethods.push(paymentMethod);
      }
    });

    setFilteredPaymentMethods(newFilteredPaymentMethods);

    let currentBox = purchasesRequest.data.boxes.filter(
      (box) => box.id == user.box.id
    )[0];

    // setNumber(currentBox.number + 1);

    let newItems = [];
    Array(10)
      .fill(0)
      .map((_, i) => {
        newItems.push({
          id: 0,
          qty: 0,
          name: "",
          price: 0,
          cost: 0,
          subtotal: 0,
          iva: "",
          utilityPercentage: 0,
          utilityPercentage2: 0,
          utilityPrice2: 0,
          utilityPercentage3: 0,
          utilityPrice3: 0,
        });
      });

    setItems(newItems);
    setIsFetching(false);
  }



  useEffect(() => {
    if (willFocus) {
      duesRef.current.focus();
    }
  }, [willFocus]);

  useEffect(() => {
    if (willFocusB) {
      duesRefB.current.focus();
    }
  }, [willFocusB]);

  useEffect(() => {
    fetchPurchases();
  }, []);

  useEffect(() => {
    setProvider(providers.filter((provider) => provider.id === providerId)[0]);
  }, [providerId]);

  useEffect(() => {
    filterPaymentMethods(conditionId);
  }, [conditionId]);

  useEffect(() => {
    
    // Verificar si ya se ha encontrado el item con id 'add'
    let foundAddItem = false;
    items.forEach((itm, i) => {
      if (itm.id == 'add') {

        setItemIdx(i);
        setItemsOpen(true);

        foundAddItem = true;
      }
    });

    if (!foundAddItem) {
      setItemsOpen(false);
    }

    // Calcular totales
    let totalAmountSum = 0;
    let totalIva5Sum = 0;
    let totalIva10Sum = 0;
    let totalAmountItem = 0;

    items.forEach((item) => {
      totalAmountItem = parseFloat(item.qty * Number(String(item?.cost || 0).replaceAll(",", "")));
      totalAmountSum += totalAmountItem;
      if (item.iva == 5) {
        totalIva5Sum += Math.floor(Number(totalAmountItem / 21)); // calcula el IVA al 5%
      } else if (item.iva == 10) {
        totalIva10Sum += Number(totalAmountItem / 11); // calcula el IVA al 10%
      }
    });

    setTotalAmount(numberWithCommas(totalAmountSum));
    setTotalIva5(numberWithCommas(totalIva5Sum));
    setTotalIva10(numberWithCommas(parseFloat(totalIva10Sum).toFixed(2)));

    // Verificar si se debe agregar un nuevo item
    if (items.length > 0) {
      let isFull = true;
      items.forEach((item) => {
        if (item.name === "") {
          isFull = false;
        }
      });

      if (isFull) {
        setItems((prevItems) => [
          ...prevItems,
          {
            id: 0,
            qty: 0,
            name: "",
            price: 0,
            subtotal: 0,
            cost: 0,
            iva: "",

          },
        ]);
      } else {
        setItems((prevItems) =>
          prevItems.map((itm) => ({
            ...itm,

          }))
        );
      }
    }

    // Generar cuotas si es necesario
    if (conditionId === 2 && filteredPaymentMethods[0].amount > 0 && filteredPaymentMethods[1].amount > 0) {
      let newDues = [];
      for (let due of Array(parseInt(filteredPaymentMethods[0].amount)).keys()) {
        let date = new Date();
        newDues.push({
          number: due + 1,
          expirationDate: formatDate(addDays(date, parseInt(filteredPaymentMethods[1].amount) * due)),
          amount: (Number(String(totalAmountSum).replaceAll(",", "")) / filteredPaymentMethods[0].amount).toFixed(2),
        });
      }
      setDues(newDues);
    }
  }, [items, itemIdx, numberWithCommas, conditionId, filteredPaymentMethods, formatDate]);


  const addDays = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  useEffect(() => {
    
    filteredPaymentMethods.map((filteredPaymentMethod) => {
      if (filteredPaymentMethod.amount > 0) {
        setFilteredPaymentMethodsCompleted(true);
      }
    });
    if (
      conditionId == 2 &&
      filteredPaymentMethods[0].amount > 0 &&
      filteredPaymentMethods[1].amount > 0
    ) {
      let newDues = [];
      for (var due of Array(
        parseInt(filteredPaymentMethods[0].amount)
      ).keys()) {
        let date = new Date();

        newDues.push({
          number: due + 1,
          expirationDate: formatDate(
            addDays(date, parseInt(filteredPaymentMethods[1].amount) * due)
          ),
          amount:
            (Number(String(totalAmount).replaceAll(",", "")) /
              filteredPaymentMethods[0].amount).toFixed(2)
          ,
        });
      }
      setDues(newDues);
    } else {
      let newDues = [];
      setDues(newDues);
    }
  }, [filteredPaymentMethods]);

  const filterPaymentMethods = (saleConditionId) => {
    let newFilteredPaymentMethods = [];

    paymentMethods.map((paymentMethod) => {
      if (paymentMethod.saleConditionId == saleConditionId) {
        //paymentMethod.amount = null;
        newFilteredPaymentMethods.push(paymentMethod);
      }
    });

    setFilteredPaymentMethods(newFilteredPaymentMethods);
  };

  const clearFields = () => {
    setPurchaseId(0);
    setProviderId(0);
    setAccount({});
    setAccountId();
    setDepositId(0);
    setCurrencyId(1);
    setConditionId(1);
    let newItems = [];
    let newDues = [];

    Array(10)
      .fill(0)
      .map((_, i) => {
        newItems.push({
          id: 0,
          qty: 0,
          name: "",
          price: 0,
          cost: 0,
          subtotal: 0,
          iva: "",
          utilityPercentage: 0,
          utilityPercentage2: 0,
          utilityPrice2: 0,
          utilityPercentage3: 0,
          utilityPrice3: 0,
        });
      });

    setItems(prev => newItems);
    setDues(prev => newDues);

    paymentMethods.map((paymentMethod) => {
      if (paymentMethod.saleConditionId == conditionId) {
        paymentMethod.amount = null;
      }
    });

    setDeleted(0);
    setChecks([
      {
        bankId: 0,
        name: "",
        number: 0,
        depositNumber: 0,
        issueDate: "",
        expirationDate: "",
        amount: 0,
        currencyId: 0,
      },
    ]);
  };

  useEffect(() => {
    let account = accounts.filter((account) => account.id == accountId)[0];
    setAccount(account);
  }, [accountId]);

  const handleSubmit = async () => {

    if (action == "edit") {
      return false;
    }

    if (
      providerId === 0 ||
      sellerId === 0 ||
      boxId === 0 ||
      currencyId === 0 ||
      number === 0 ||
      branchId === 0 ||
      depositId === 0 ||
      companyId === 0 ||
      conditionId === 0
    ) {
      alert("Debes completar los campos para continuar.");
      return false;
    }

    var hasItems = false;
    var hasPayments = false;
    var mustSetAccount = false;
    var mustCompleteChecks = false;
    var itemsToSend = [];
    var paymentsToSend = [];

    items.map((item) => {
      if (item.id > 0) {
        hasItems = true;
        itemsToSend.push(item);
      }
    });

    filteredPaymentMethods.map((paymentMethod) => {
      if (paymentMethod.amount > 0) {
        hasPayments = true;
        paymentsToSend.push(paymentMethod);
        if (paymentMethod.id == 3 && accountId == 0) {
          mustSetAccount = true;
        }

        if (paymentMethod.id == 2) {
          checks.map((check) => {
            if (check.amount > 0) {
              if (
                check.bankId == 0 ||
                check.currencyId == 0 ||
                check.issueDate == "" ||
                check.expirationDate == ""
              ) {
                mustCompleteChecks = true;
              }
            }
          });
        }
      }
    });

    if (mustCompleteChecks) {
      alert("Debe completar los datos de los cheques para seguir");
      return false;
    }

    if (mustSetAccount) {
      alert("Debe elegir una cuenta a la cual transferir los fondos");
      return false;
    }

    if (conditionId == 2 && dues.length <= 0) {
      alert("Debes agregar cuotas para continuar.");
      return false;
    }

    if (!hasItems) {
      alert("Debes agregar articulos para continuar.");
      return false;
    }

    if (!hasPayments) {
      alert("Debes agregar un monto de pago para continuar.");
      return false;
    }

    let createdPurchaseRequest = await axiosAuth.post("/purchases", {
      providerId,
      sellerId,
      boxId,
      currencyId,
      branchId,
      depositId,
      companyId,
      checks,
      date,
      conditionId,
      purchasesdetail: itemsToSend,
      payments: paymentsToSend,
      accountId,
      expeditionPoint,
      document_date: date,
      number,
      stamped,
      dues,
      deleted,
      cotizacion,
      internalMovement: internalMovement ? 1 : 0
    });

    if (createdPurchaseRequest.data.error) {
      if (createdPurchaseRequest.data.message === "token") {
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    let newPurchases = [...purchases];
    newPurchases.push(createdPurchaseRequest.data);
    setPurchases(newPurchases);

    // if (window.confirm("Desea imprimir recibo ?")) {
    //   clearFields();
    //   fetchPurchases();
    //   window.open("/imprimirfactura/" + createdPurchaseRequest.data.id);
    // } else {
    toast.success("Compra creada correctamente!");

    setTimeout(function () { window.location.reload(); }, 700);
    //   }
  };

  const searchProviders = async (search) => {
    const searchProvidersRequest = await axiosAuth.get(
      "/searchProviders?search=" + search
    );

    setProviders(searchProvidersRequest.data.providers.rows);
  };

  const searchItems = async (search) => {
    const searchItemsRequest = await axiosAuth.get(
      "/searchItems?search=" + search
    );

    searchItemsRequest.data.items.rows.push({ id: 'add', name: 'Crear articulo', price: 0 });

    setArticles(searchItemsRequest.data.items.rows);


  };

  const deleteSale = async (purchaseId) => {
    if (window.confirm("Desea eliminar esta compra ?")) {
      let purchaseDeleteRequest = await axiosAuth.delete("/purchases", {
        params: { id: purchaseId },
      });

      if (purchaseDeleteRequest.data.error) {
        if (purchaseDeleteRequest.data.message === "token") {
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      let newPurchasesObj = [];

      purchases.map((purchase) => {
        if (purchase.id !== purchaseId) {
          newPurchasesObj.push(purchase);
        } else {
          if (user.roleId == 1) {
            purchase.deleted = 1;
            newPurchasesObj.push(purchase);
          }
        }
        return "";
      });

      setPurchases(newPurchasesObj);
    }
  };
  const handleUtilityPercentageChange = (e, index) => {
    const newUtilidad = parseFloat(e.target.value);
    // console.log("Nuevo porcentaje de utilidad:", newUtilidad); // Depuración
    let newItems = [...items];

    if (!isNaN(newUtilidad)) {
      const cost = parseFloat(String(newItems[index].cost).replace(/,/g, ""));
      const newPrecio = (cost * (newUtilidad / 100)) + cost;
      newItems[index] = {
        ...newItems[index],
        utilityPercentage: newUtilidad,
        price: numberWithCommas(newPrecio.toFixed(2))
      };
    } else {
      newItems[index] = {
        ...newItems[index],
        utilityPercentage: ""
      };
    }

    // console.log("Nuevos items:", newItems); // Depuración
    setItems(newItems);
  };


  const handleItemSubmit = async () => {

    if (
      name === "" ||
      measurementUnityId == 0 ||
      measurementUnityId == "" ||
      currencyId == 0 ||
      currencyId == "" ||
      companyId == 0 ||
      companyId == "" ||
      itemTypeId == 0 ||
      itemTypeId == "" ||
      brandId == 0 ||
      brandId == "" ||
      groupId == 0 ||
      groupId == "" ||
      price == 0
    ) {
      alert("Debes completar los campos para continuar.");
      return false;
    }

    let formData = new FormData();
    formData.append("file", imageFile);
    formData.append("file2", image2File);
    formData.append("file3", image3File);
    formData.append("file4", image4File);
    formData.append("file5", image5File);
    formData.append("image", image);
    formData.append("image2", image2);
    formData.append("image3", image3);
    formData.append("image4", image4);
    formData.append("image5", image5);
    formData.append("name", name);
    formData.append("webSpecification", webSpecification);
    formData.append("description", description);
    formData.append("fiscalDescription", fiscalDescription);
    formData.append("web", web);
    formData.append("webDescription", webDescription);
    formData.append("barcode", barcode);
    formData.append("internalcode", internalcode);
    formData.append("factorycode", factorycode);
    formData.append("iva", iva);
    formData.append("price", String(price).replaceAll(",", ""));
    formData.append("price2", String(price2).replaceAll(",", ""));
    formData.append("price3", String(price3).replaceAll(",", ""));
    formData.append("costFob", String(costFob).replaceAll(",", ""));
    formData.append("medCostFob", String(medCostFob).replaceAll(",", ""));
    formData.append("costCif", String(costCif).replaceAll(",", ""));
    formData.append("medCostCif", String(medCostCif).replaceAll(",", ""));
    formData.append("companyId", companyId);
    formData.append("itemTypeId", itemTypeId);
    formData.append("brandId", brandId);
    formData.append("groupId", groupId);
    formData.append("promotion", promotion);
    formData.append("weight", weight);
    formData.append("height", height);
    formData.append("broad", broad);
    formData.append("depth", depth);
    formData.append("website", website);
    formData.append("NCM", NCM);
    formData.append("measurementUnityId", measurementUnityId);
    formData.append("currencyId", currencyId);
    formData.append("observations", observations);
    formData.append("active", active);
    formData.append("deleted", deleted);

    let createdItemRequest = await axiosAuth.post("/items", formData);

    if (createdItemRequest.data.error) {
      if (createdItemRequest.data.message === "token") {
        await axiosAuth.post("/logout");
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    let newArticles = [...articles];
    newArticles.push(createdItemRequest.data);
    setArticles(newArticles);

    let newItems = [];
    items.map((it, idx) => {
      if (idx == itemIdx) {
        newItems.push({
          id: createdItemRequest.data.id,
          qty: 1,
          name: createdItemRequest.data.name,
          price: createdItemRequest.data.price,
          cost: createdItemRequest.data.costFob,
          subtotal: createdItemRequest.data.costFob,
          iva: createdItemRequest.data.iva,
          utilityPercentage: 0,
          utilityPrice: 0,
          utilityPercentage2: 0,
          utilityPrice2: 0,
        });
      } else {
        newItems.push(it);
      }
    });
    setItems(newItems);

    setItemsOpen(false);
  };

  const handleImageInputChange = (e) => {
    if (e.target.files[0] === undefined) return false;
    setImageFile(e.target.files[0]);
    setImage(String(e.target.files[0].name).replaceAll(" ", ""));
    let file = URL.createObjectURL(e.target.files[0]);
    setImagePreview(file);
  };

  const handleImage2InputChange = (e) => {
    if (e.target.files[0] === undefined) return false;
    setImage2File(e.target.files[0]);
    setImage2(String(e.target.files[0].name).replaceAll(" ", ""));
    let file = URL.createObjectURL(e.target.files[0]);
    setImage2Preview(file);
  };

  const handleImage3InputChange = (e) => {
    if (e.target.files[0] === undefined) return false;
    setImage3File(e.target.files[0]);
    setImage3(String(e.target.files[0].name).replaceAll(" ", ""));
    let file = URL.createObjectURL(e.target.files[0]);
    setImage3Preview(file);
  };

  const handleImage4InputChange = (e) => {
    if (e.target.files[0] === undefined) return false;
    setImage4File(e.target.files[0]);
    setImage4(String(e.target.files[0].name).replaceAll(" ", ""));
    let file = URL.createObjectURL(e.target.files[0]);
    setImage4Preview(file);
  };

  const handleImage5InputChange = (e) => {
    if (e.target.files[0] === undefined) return false;
    setImage5File(e.target.files[0]);
    setImage5(String(e.target.files[0].name).replaceAll(" ", ""));
    let file = URL.createObjectURL(e.target.files[0]);
    setImage5Preview(file);
  };

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };

  return (
    <>
      <Layout>

        <Transition.Root show={itemsOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              clearFields();
              setOpen(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity blur" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-[100%] m-auto overflow-y-auto align-items-center justify-center items-center flex">
              <div className="flex justify-center p-4 text-center sm:p-0 w-[95%]">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel
                    className="relative transform overflow-auto m-auto bg-white text-left shadow-xl transition-all w-[95%] sm:w-[85%] h-[550px]"
                    style={{}}
                  >
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-0">
                      <div className="sm:flex sm:items-start w-full">
                        <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                          <Dialog.Title
                            as="h1"
                            className="text-center font-regular leading-6 primary-color text-3xl mb-2"
                          >
                            {action === "edit" ? "Editar" : "Crear"} articulo
                          </Dialog.Title>
                          <Tab.Group>
                            <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1 mb-[2px]">
                              <Tab
                                className={({ selected }) =>
                                  classNames(
                                    "w-full rounded-lg py-1.5 text-sm leading-5 primary-color text-xs",
                                    "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                                    selected ? "bg-white font-bold" : ""
                                  )
                                }
                              >
                                Datos
                              </Tab>
                              <Tab
                                className={({ selected }) =>
                                  classNames(
                                    "w-full rounded-lg py-1.5 text-sm leading-5 primary-color text-xs",
                                    "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                                    selected ? "bg-white font-bold" : ""
                                  )
                                }
                              >
                                Imagen
                              </Tab>
                              {user.companyId == 2 && <>
                                <Tab
                                  className={({ selected }) =>
                                    classNames(
                                      "w-full rounded-lg py-1.5 text-sm leading-5 primary-color text-xs",
                                      "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                                      selected ? "bg-white font-bold" : ""
                                    )
                                  }
                                >
                                  Especificacion Web
                                </Tab>
                                <Tab
                                  className={({ selected }) =>
                                    classNames(
                                      "w-full rounded-lg py-1.5 text-sm leading-5 primary-color text-xs",
                                      "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                                      selected ? "bg-white font-bold" : ""
                                    )
                                  }
                                >
                                  Descripcion Web
                                </Tab>
                              </>
                              }
                            </Tab.List>
                            <Tab.Panels>
                              <Tab.Panel>
                                <div className="w-full flex flex-col md:flex-row w-full mt-2">
                                  <div className="mb-[1px] flex flex-col md:w-[50%] w-full">
                                    {action === "edit" ? (
                                      <div className="mb-1">
                                        <label
                                          className="inline-block text-gray-700 text-[10px] font-bold"
                                          htmlFor="itemId"
                                        >
                                          ID:
                                        </label>
                                        &nbsp;
                                        <input
                                          readOnly
                                          className="inline-block input-bg-color appearance-none border rounded w-[91.5%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-[23px]"
                                          id="itemId"
                                          type="text"
                                          value={itemId}
                                        />
                                      </div>
                                    ) : (<><div className="h-[23px]"></div></>)}

                                    <div className="mb-[1px]">
                                      <label
                                        className="inline-block text-red-700 text-[10px] font-bold uppercase "
                                        htmlFor="name"
                                      >
                                        (*) Nombre:
                                      </label>
                                      &nbsp;
                                      <input
                                        className="inline-block input-bg-color appearance-none border w-[81%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[23px] uppercase"
                                        id="name"
                                        type="text"
                                        value={name}
                                        onChange={(e) =>
                                          setName(e.target.value)
                                        }
                                      />
                                    </div>

                                    <div className="mb-[1px]">
                                      <label
                                        className="inline-block text-red-700 text-[10px] font-bold uppercase "
                                        htmlFor="description"
                                      >
                                        Descripcion:
                                      </label>
                                      &nbsp;
                                      <input
                                        className="inline-block input-bg-color appearance-none border w-[81%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[23px] uppercase"
                                        id="description"
                                        type="text"
                                        value={description}
                                        onChange={(e) =>
                                          setDescription(e.target.value)
                                        }
                                      />
                                    </div>

                                    <div className="mb-[1px]">
                                      <label
                                        className="inline-block text-red-700 text-[10px] font-bold uppercase "
                                        htmlFor="fiscalDescription"
                                      >
                                        Descripcion Fiscal:
                                      </label>
                                      &nbsp;
                                      <input
                                        className="inline-block input-bg-color appearance-none border w-[81%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[23px] uppercase"
                                        id="fiscalDescription"
                                        type="text"
                                        value={fiscalDescription}
                                        onChange={(e) =>
                                          setFiscalDescription(e.target.value)
                                        }
                                      />
                                    </div>

                                    <div className="mb-[1px]">
                                      <label
                                        className="inline-block text-red-700 text-[10px] font-bold uppercase "
                                        htmlFor="web"
                                      >
                                        Descripcion Web:
                                      </label>
                                      &nbsp;
                                      <input
                                        className="inline-block input-bg-color appearance-none border w-[81%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[23px] uppercase"
                                        id="web"
                                        type="text"
                                        value={web}
                                        onChange={(e) =>
                                          setWeb(e.target.value)
                                        }
                                      />
                                    </div>

                                    <div className="mb-[1px]">
                                      <label
                                        className="inline-block text-gray-700 text-[10px] font-bold uppercase"
                                        htmlFor="barcode"
                                      >
                                        Cod de barras:
                                      </label>
                                      &nbsp;
                                      <input
                                        className="inline-block input-bg-color appearance-none border w-[72.5%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[23px]"
                                        id="barcode"
                                        type="text"
                                        value={barcode}
                                        onChange={(e) =>
                                          setBarcode(e.target.value)
                                        }
                                      />
                                    </div>

                                    <div className="mb-[1px]">
                                      <label
                                        className="inline-block text-gray-700 text-[10px] font-bold uppercase "
                                        htmlFor="internalcode"
                                      >
                                        Cod interno:
                                      </label>
                                      &nbsp;
                                      <input
                                        className="input-bg-color inline-block appearance-none border w-[73%] h-[23px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                        id="internalcode"
                                        type="text"
                                        value={internalcode}
                                        onChange={(e) =>
                                          setInternalCode(e.target.value)
                                        }
                                      />
                                    </div>

                                    <div className="mb-[1px] pb-2 mb-[5px]" style={{ borderBottom: "1px solid lightgray" }}>
                                      <label
                                        className="inline-block text-gray-700 text-[10px] font-bold uppercase"
                                        htmlFor="factorycode"
                                      >
                                        Cod. de fabrica:
                                      </label>
                                      &nbsp;
                                      <input
                                        className="inline-block h-[23px] input-bg-color appearance-none border w-[71.5%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase"
                                        id="factorycode"
                                        type="text"
                                        value={factorycode}
                                        onChange={(e) =>
                                          setFactoryCode(e.target.value)
                                        }
                                      />
                                    </div>

                                    <div className="mb-[1px]">
                                      <h2 className="underline text-center text-2xl relative">Clasificacion</h2>
                                    </div>

                                    <div className="mb-[1px]">
                                      <label
                                        className="inline-block text-red-700 text-[10px] font-bold uppercase"
                                        htmlFor="itemTypeId"
                                      >
                                        (*) Tipo de articulo:
                                      </label>
                                      &nbsp;
                                      <select
                                        className="inline-block input-bg-color appearance-none border w-[71%] h-[23px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase"
                                        id="itemTypeId"
                                        value={itemTypeId}
                                        onChange={(e) => {
                                          setItemTypeId(e.target.value);
                                        }}
                                      >
                                        <option value={0}>
                                          Elija un tipo de articulo
                                        </option>
                                        {itemTypes.map((itemType) => (
                                          <option
                                            key={itemType.id}
                                            value={itemType.id}
                                          >
                                            {itemType.name}
                                          </option>
                                        ))}
                                      </select>
                                    </div>



                                    <div className="mb-[1px]">
                                      <label
                                        className="inline-block text-red-700 text-[10px] font-bold uppercase"
                                        htmlFor="brandId"
                                      >
                                        (*) Marca:
                                      </label>
                                      &nbsp;
                                      <select
                                        className="inline-block input-bg-color appearance-none border w-[82%] h-[23px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase"
                                        id="brandId"
                                        value={brandId}
                                        onChange={(e) => {
                                          setBrandId(e.target.value);
                                        }}
                                      >
                                        <option value={0}>
                                          Elija una marca
                                        </option>
                                        {brands.map((brand) => (
                                          <option
                                            key={brand.id}
                                            value={brand.id}
                                          >
                                            {brand.name}
                                          </option>
                                        ))}
                                      </select>
                                    </div>

                                    <div className="mb-[1px]">
                                      <label
                                        className="inline-block text-red-700 text-[10px] font-bold uppercase"
                                        htmlFor="groupId"
                                      >
                                        (*) Grupo:
                                      </label>
                                      &nbsp;
                                      <select
                                        className="inline-block h-[23px] input-bg-color appearance-none border w-[82.5%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase"
                                        id="groupId"
                                        value={groupId}
                                        onChange={(e) => {
                                          setGroupId(e.target.value);
                                        }}
                                      >
                                        <option value={0}>
                                          Elija un grupo
                                        </option>
                                        {groups.map((group) => (
                                          <option
                                            key={group.id}
                                            value={group.id}
                                          >
                                            {group.name}
                                          </option>
                                        ))}
                                      </select>
                                    </div>

                                    <div className="mb-[1px]">
                                      <label
                                        className="inline-block text-gray-700 text-[10px] font-bold uppercase"
                                        htmlFor="promotion"
                                      >
                                        Promocion:
                                      </label>
                                      &nbsp;
                                      <select
                                        className="inline-block h-[23px] input-bg-color appearance-none border w-[82.5%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase"
                                        id="promotion"
                                        value={promotion}
                                        onChange={(e) => {
                                          setPromotion(e.target.value);
                                        }}
                                      >
                                        <option value={0}>
                                          Elija un valor
                                        </option>
                                        <option value={0}>No</option>
                                        <option value={1}>Si</option>
                                      </select>
                                    </div>

                                    <div className="mb-[1px]">
                                      <label
                                        className="inline-block text-gray-700 text-[10px] font-bold uppercase"
                                        htmlFor="website"
                                      >
                                        Website:
                                      </label>
                                      &nbsp;
                                      <select
                                        className="inline-block h-[23px] input-bg-color appearance-none border w-[82.5%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase"
                                        id="website"
                                        value={website}
                                        onChange={(e) => {
                                          setWebsite(e.target.value);
                                        }}
                                      >
                                        <option value={0}>
                                          Elija un valor
                                        </option>
                                        <option value={0}>No</option>
                                        <option value={1}>Si</option>
                                      </select>
                                    </div>

                                    <div className="mb-[1px]">
                                      <label
                                        className="inline-block text-gray-700 text-[10px] font-bold uppercase"
                                        htmlFor="ncm"
                                      >
                                        NCM:
                                      </label>
                                      &nbsp;
                                      <input
                                        className="inline-block h-[23px] input-bg-color appearance-none border w-[72.5%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                        id="ncm"
                                        type="text"
                                        value={NCM}
                                        onChange={(e) =>
                                          setNCM(e.target.value)
                                        }
                                      />
                                    </div>


                                    <div className="flex flex-row justify-start items-center">
                                      <div className="mb-[1px]">
                                        <label
                                          className="inline-block text-gray-700 text-[10px] font-bold uppercase"
                                          htmlFor="weight"
                                        >
                                          Peso:
                                        </label>
                                        &nbsp;
                                        <input
                                          className="inline-block h-[23px] input-bg-color appearance-none border w-[55%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                          id="weight"
                                          type="text"
                                          value={weight}
                                          onChange={(e) =>
                                            setWeight(e.target.value)
                                          }
                                        />
                                      </div>

                                      <div className="mb-[1px]">
                                        <label
                                          className="inline-block text-gray-700 text-[10px] font-bold uppercase"
                                          htmlFor="height"
                                        >
                                          Alto:
                                        </label>
                                        &nbsp;
                                        <input
                                          className="inline-block h-[23px] input-bg-color appearance-none border w-[55%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                          id="height"
                                          type="text"
                                          value={height}
                                          onChange={(e) =>
                                            setHeight(e.target.value)
                                          }
                                        />
                                      </div>


                                      <div className="mb-[1px]">
                                        <label
                                          className="inline-block text-gray-700 text-[10px] font-bold uppercase"
                                          htmlFor="broad"
                                        >
                                          Largo:
                                        </label>
                                        &nbsp;
                                        <input
                                          className="inline-block h-[23px] input-bg-color appearance-none border w-[55%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                          id="broad"
                                          type="text"
                                          value={broad}
                                          onChange={(e) =>
                                            setBroad(e.target.value)
                                          }
                                        />
                                      </div>

                                      <div className="mb-[1px]">
                                        <label
                                          className="inline-block text-gray-700 text-[10px] font-bold uppercase"
                                          htmlFor="depth"
                                        >
                                          Prof.:
                                        </label>
                                        &nbsp;
                                        <input
                                          className="inline-block h-[23px] input-bg-color appearance-none border w-[55%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                          id="depth"
                                          type="text"
                                          value={depth}
                                          onChange={(e) =>
                                            setDepth(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>


                                  </div>

                                  <div className="mb-[1px] flex flex-col md:w-[50%] w-full">


                                    <div className="flex sm:flex-row flex-col pb-[3px] mt-[5px]" style={{ borderBottom: "1px solid lightgray" }}>
                                      <div className="flex flex-col">
                                        <h2 className="text-center text-2xl underline text-green-600">Precios / Impuesto</h2>
                                        <div className="mb-[1px]">
                                          <label
                                            className="inline-block text-red-700 text-[10px] font-bold uppercase"
                                            htmlFor="price"
                                          >
                                            (*) Precio Tienda:
                                          </label>
                                          &nbsp;
                                          <input
                                            className="inline-block h-[23px] input-bg-color appearance-none border w-[60%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase"
                                            id="price"
                                            type="text"
                                            value={price}
                                            onChange={(e) =>
                                              setPrice(
                                                numberWithCommas(e.target.value)
                                              )
                                            }
                                          />
                                        </div>

                                        <div className="mb-[1px]">
                                          <label
                                            className="inline-block text-gray-700 text-[10px] font-bold uppercase"
                                            htmlFor="price2"
                                          >
                                            Precio Web:
                                          </label>
                                          &nbsp;
                                          <input
                                            className="inline-block input-bg-color appearance-none border w-[65%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[23px] uppercase"
                                            id="price2"
                                            type="text"
                                            value={price2}
                                            onChange={(e) =>
                                              setPrice2(
                                                numberWithCommas(e.target.value)
                                              )
                                            }
                                          />
                                        </div>

                                        <div className="mb-[1px]">
                                          <label
                                            className="inline-block text-gray-700 text-[10px] font-bold uppercase"
                                            htmlFor="price3"
                                          >
                                            Precio Minimo:
                                          </label>
                                          &nbsp;
                                          <input
                                            className="inline-block h-[23px] input-bg-color appearance-none border w-[65%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase"
                                            id="price3"
                                            type="text"
                                            value={price3}
                                            onChange={(e) =>
                                              setPrice3(
                                                numberWithCommas(e.target.value)
                                              )
                                            }
                                          />
                                        </div>

                                        <div className="mb-[1px]">
                                          <label
                                            className="inline-block text-gray-700 text-[10px] font-bold uppercase"
                                            htmlFor="iva"
                                          >
                                            IVA:
                                          </label>
                                          &nbsp;
                                          <input
                                            className="inline-block h-[23px] w-[65%] input-bg-color appearance-none border py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase"
                                            id="iva"
                                            type="text"
                                            value={iva}
                                            onChange={(e) => setIva(e.target.value)}
                                          />
                                        </div>
                                      </div>

                                      <div className="flex flex-col">

                                        <h2 className="text-center text-2xl underline text-red-400">Costos</h2>

                                        <div className="mb-[1px]">
                                          <label
                                            className="inline-block text-gray-700 text-[10px] font-bold uppercase"
                                            htmlFor="costFob"
                                          >
                                            Costo Fob.:
                                          </label>
                                          &nbsp;
                                          <input
                                            className="inline-block h-[23px] input-bg-color appearance-none border w-[65%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase"
                                            id="costFob"
                                            type="text"
                                            value={costFob}
                                            onChange={(e) =>
                                              setCostFob(
                                                numberWithCommas(e.target.value)
                                              )
                                            }
                                          />
                                        </div>

                                        <div className="mb-[1px]">
                                          <label
                                            className="inline-block text-gray-700 text-[10px] font-bold uppercase"
                                            htmlFor="costFob"
                                          >
                                            Med. Costo Fob.:
                                          </label>
                                          &nbsp;
                                          <input
                                            className="inline-block h-[23px] input-bg-color appearance-none border w-[65%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase"
                                            id="costFob"
                                            type="text"
                                            value={medCostFob}
                                            onChange={(e) =>
                                              setMedCostFob(
                                                numberWithCommas(e.target.value)
                                              )
                                            }
                                          />
                                        </div>

                                        <div className="mb-[1px]">
                                          <label
                                            className="inline-block text-gray-700 text-[10px] font-bold uppercase"
                                            htmlFor="costCif"
                                          >
                                            Costo Cif.:
                                          </label>
                                          &nbsp;
                                          <input
                                            className="inline-block h-[23px] input-bg-color appearance-none border w-[65%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase"
                                            id="costCif"
                                            type="text"
                                            value={costCif}
                                            onChange={(e) =>
                                              setCostCif(
                                                numberWithCommas(e.target.value)
                                              )
                                            }
                                          />
                                        </div>

                                        <div className="mb-[1px]">
                                          <label
                                            className="inline-block text-gray-700 text-[10px] font-bold uppercase"
                                            htmlFor="costCif"
                                          >
                                            Med. Costo Cif.:
                                          </label>
                                          &nbsp;
                                          <input
                                            className="inline-block h-[23px] input-bg-color appearance-none border w-[65%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase"
                                            id="costCif"
                                            type="text"
                                            value={medCostCif}
                                            onChange={(e) =>
                                              setMedCostCif(
                                                numberWithCommas(e.target.value)
                                              )
                                            }
                                          />
                                        </div>
                                      </div>

                                    </div>

                                    <div className="sm:mt-11 mt-2"></div>

                                    {user.roleId == 1 &&
                                      <div className="mb-[1px]">
                                        <label
                                          className="inline-block text-gray-700 text-[10px] font-bold uppercase"
                                          htmlFor="companyId"
                                        >
                                          Empresa
                                        </label>
                                        <select
                                          className="inline-block h-[23px] input-bg-color appearance-none border w-[85%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase"
                                          id="companyId"
                                          value={companyId}
                                          onChange={(e) => {
                                            setCompanyId(e.target.value);
                                          }}
                                        >
                                          <option value={0}>
                                            Elija una empresa
                                          </option>
                                          {companies.map((company) => (
                                            <option
                                              key={company.id}
                                              value={company.id}
                                            >
                                              {company.name}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    }


                                    <div className="mb-[1px]">
                                      <label
                                        className="inline-block text-red-700 text-[10px] font-bold uppercase"
                                        htmlFor="measurementUnityId"
                                      >
                                        (*) Unidad de medida:
                                      </label>
                                      &nbsp;
                                      <select
                                        className="inline-block h-[23px] input-bg-color appearance-none border w-[70.5%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase"
                                        id="measurementUnityId"
                                        value={measurementUnityId}
                                        onChange={(e) => {
                                          setMeasurementUnityId(e.target.value);
                                        }}
                                      >
                                        <option value={0}>
                                          Elija una un. de medida
                                        </option>
                                        {measurementUnities.map(
                                          (measurementUnity) => (
                                            <option
                                              key={measurementUnity.id}
                                              value={measurementUnity.id}
                                            >
                                              {measurementUnity.name}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    </div>


                                    <div className="mb-[1px]">
                                      <label
                                        className="inline-block text-red-700 text-[10px] font-bold uppercase"
                                        htmlFor="currencyId"
                                      >
                                        (*) Moneda:
                                      </label>
                                      &nbsp;
                                      <select
                                        className="inline-block input-bg-color h-[23px] appearance-none border w-[85.2%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase"
                                        id="currencyId"
                                        value={currencyId}
                                        onChange={(e) => {
                                          setCurrencyId(e.target.value);
                                        }}
                                      >
                                        <option value={0}>
                                          Elija una moneda
                                        </option>
                                        {currencies.map((currency) => (
                                          <option
                                            key={currency.id}
                                            value={currency.id}
                                          >
                                            {currency.name}
                                          </option>
                                        ))}
                                      </select>
                                    </div>

                                    {user.companyId != 2 && <>



                                      <div className="mb-[1px]">
                                        <label
                                          className="inline-block text-gray-700 text-[10px] font-bold uppercase"
                                          htmlFor="observations"
                                        >
                                          Observaciones:
                                        </label>
                                        &nbsp;
                                        <input
                                          className="inline-block h-[23px] input-bg-color appearance-none border w-[74%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase"
                                          id="observations"
                                          type="text"
                                          value={observations}
                                          onChange={(e) =>
                                            setObservations(e.target.value)
                                          }
                                        />
                                      </div>
                                    </>
                                    }

                                    <div className="mb-[1px]">
                                      <label
                                        className="inline-block text-gray-700 text-[10px] font-bold uppercase"
                                        htmlFor="active"
                                      >
                                        Activa:
                                      </label>
                                      &nbsp;
                                      <select
                                        className="inline-block input-bg-color appearance-none border w-[88%] h-[23px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase"
                                        id="active"
                                        value={active}
                                        onChange={(e) =>
                                          setActive(parseInt(e.target.value))
                                        }
                                      >
                                        <option value={0}>No</option>
                                        <option value={1}>Si</option>
                                      </select>
                                    </div>

                                    {user.roleId == 1 && (
                                      <div className="mb-[1px]">
                                        <label
                                          className="inline-block primary-color text-sm font-bold"
                                          htmlFor="deleted"
                                        >
                                          BORRADO:
                                        </label>
                                        &nbsp;
                                        <select
                                          className="inline-block h-[23px] input-bg-color appearance-none border rounded w-[84%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                          id="deleted"
                                          value={deleted}
                                          onChange={(e) =>
                                            setDeleted(parseInt(e.target.value))
                                          }
                                        >
                                          <option value={0}>No</option>
                                          <option value={1}>Si</option>
                                        </select>
                                      </div>
                                    )}

                                    <div className="mb-[1px]">
                                      <label
                                        className="inline-block text-gray-700 text-[10px] font-bold uppercase"
                                        htmlFor="lastSell"
                                      >
                                        Ultima venta:
                                      </label>
                                      &nbsp;
                                      <input
                                        className="inline-block input-bg-color appearance-none border w-[80%] h-[23px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase"
                                        id="lastSell"
                                        type="date"
                                        value={lastSell}
                                        readOnly
                                        disabled
                                      />
                                    </div>

                                    <div className="mb-[1px]">
                                      <label
                                        className="inline-block text-gray-700 text-[10px] font-bold uppercase"
                                        htmlFor="lastBuy"
                                      >
                                        Ultima compra:
                                      </label>
                                      &nbsp;
                                      <input
                                        className="inline-block input-bg-color appearance-none border w-[80%] h-[23px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize uppercase"
                                        id="lastBuy"
                                        type="date"
                                        value={lastBuy}
                                        readOnly
                                        disabled
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Tab.Panel>
                              <Tab.Panel>
                                <div className="mb-[1px] w-[300px] flex flex-row items-center">
                                  <label
                                    className="block w-[50%] primary-color mr-1 text-sm font-bold"
                                    htmlFor="image"
                                  >
                                    Foto 1
                                    <div
                                      className="input-bg-color bg-cover bg-no-repeat bg-center image-holder w-full h-60 flex items-center justify-center text-lg cursor-pointer relative"
                                      style={{ backgroundImage: "url('" + imagePreview + "')" }}
                                    >
                                      {imagePreview === "" ? "+" : ""}
                                      {imagePreview === "" ? (
                                        ""
                                      ) : (
                                        <div
                                          className="absolute top-0 right-0 cursor-pointer bg-red-700 p-2 rounded-full leading-3 z-10"
                                          onClick={() => {
                                            setImage("");
                                            setImagePreview("");
                                          }}
                                          style={{ top: -10, right: -10 }}
                                        >
                                          x
                                        </div>
                                      )}
                                    </div>
                                  </label>

                                  <label
                                    className="block primary-color w-[50%] text-sm font-bold"
                                    htmlFor="image2"
                                  >
                                    Foto 2
                                    <div
                                      className="input-bg-color bg-cover bg-no-repeat bg-center image-holder w-full h-60 flex items-center justify-center text-lg cursor-pointer relative"
                                      style={
                                        image2Preview != ""
                                          ? {
                                            backgroundImage:
                                              "url(" + image2Preview + ")",
                                          }
                                          : {}
                                      }
                                    >
                                      {image2Preview === "" ? "+" : ""}
                                      {image2Preview === "" ? (
                                        ""
                                      ) : (
                                        <div
                                          className="absolute top-0 right-0 cursor-pointer bg-red-700  p-2 rounded-full leading-3 z-10"
                                          onClick={() => {
                                            setImage2("");
                                            setImage2Preview("");
                                          }}
                                          style={{ top: -10, right: -10 }}
                                        >
                                          x
                                        </div>
                                      )}
                                    </div>
                                  </label>
                                </div>

                                <div className="mb-[1px] w-[300px] flex flex-row items-center">
                                  <label
                                    className="block primary-color w-[50%] text-sm font-bold"
                                    htmlFor="image3"
                                  >
                                    Foto 3
                                    <div
                                      className="input-bg-color bg-cover bg-no-repeat bg-center image-holder w-full h-60 flex items-center justify-center text-lg cursor-pointer relative"
                                      style={
                                        image3Preview != ""
                                          ? {
                                            backgroundImage:
                                              "url(" + image3Preview + ")",
                                          }
                                          : {}
                                      }
                                    >
                                      {image3Preview === "" ? "+" : ""}
                                      {image3Preview === "" ? (
                                        ""
                                      ) : (
                                        <div
                                          className="absolute top-0 right-0 cursor-pointer bg-red-700  p-2 rounded-full leading-3 z-10"
                                          onClick={() => {
                                            setImage3("");
                                            setImage3Preview("");
                                          }}
                                          style={{ top: -10, right: -10 }}
                                        >
                                          x
                                        </div>
                                      )}
                                    </div>
                                  </label>

                                  <label
                                    className="block w-[50%] primary-color ml-1 text-sm font-bold"
                                    htmlFor="image4"
                                  >
                                    Foto 4
                                    <div
                                      className="input-bg-color bg-cover bg-no-repeat bg-center image-holder w-full h-60 flex items-center justify-center text-lg cursor-pointer relative"
                                      style={
                                        image4Preview != ""
                                          ? {
                                            backgroundImage:
                                              "url(" + image4Preview + ")",
                                          }
                                          : {}
                                      }
                                    >
                                      {image4Preview === "" ? "+" : ""}
                                      {image4Preview === "" ? (
                                        ""
                                      ) : (
                                        <div
                                          className="absolute top-0 right-0 cursor-pointer bg-red-700  p-2 rounded-full leading-3 z-10"
                                          onClick={() => {
                                            setImage4("");
                                            setImage4Preview("");
                                          }}
                                          style={{ top: -10, right: -10 }}
                                        >
                                          x
                                        </div>
                                      )}
                                    </div>
                                  </label>
                                </div>
                                <div className="mb-[1px] w-[300px] flex flex-row items-center">
                                  <label
                                    className="block primary-color w-[50%] text-sm font-bold"
                                    htmlFor="image5"
                                  >
                                    Foto 5
                                    <br />
                                    <div
                                      className="input-bg-color bg-cover bg-no-repeat bg-center image-holder w-full h-60 flex items-center justify-center text-lg cursor-pointer relative"
                                      style={
                                        image5Preview != ""
                                          ? {
                                            backgroundImage:
                                              "url(" + image5Preview + ")",
                                          }
                                          : {}
                                      }
                                    >
                                      {image5Preview === "" ? "+" : ""}
                                      {image5Preview === "" ? (
                                        ""
                                      ) : (
                                        <div
                                          className="absolute top-0 right-0 cursor-pointer bg-red-700  p-2 rounded-full leading-3 z-10"
                                          onClick={() => {
                                            setImage5("");
                                            setImage5Preview("");
                                          }}
                                          style={{ top: -10, right: -10 }}
                                        >
                                          x
                                        </div>
                                      )}
                                    </div>
                                  </label>
                                </div>

                                <input
                                  readOnly
                                  className="hidden"
                                  id="image"
                                  type="file"
                                  onChange={(e) => handleImageInputChange(e)}
                                //value={image}
                                />

                                <input
                                  readOnly
                                  className="hidden"
                                  id="image2"
                                  type="file"
                                  onChange={(e) => handleImage2InputChange(e)}
                                //value={image}
                                />

                                <input
                                  readOnly
                                  className="hidden"
                                  id="image3"
                                  type="file"
                                  onChange={(e) => handleImage3InputChange(e)}
                                //value={image}
                                />

                                <input
                                  readOnly
                                  className="hidden"
                                  id="image4"
                                  type="file"
                                  onChange={(e) => handleImage4InputChange(e)}
                                //value={image}
                                />

                                <input
                                  readOnly
                                  className="hidden"
                                  id="image5"
                                  type="file"
                                  onChange={(e) => handleImage5InputChange(e)}
                                //value={image}
                                />
                              </Tab.Panel>
                              <Tab.Panel>
                                <textarea className="w-full h-80" value={webSpecification} onChange={e => setWebSpecification(e.target.value)}>
                                </textarea>
                              </Tab.Panel>
                              <Tab.Panel>
                                <textarea className="w-full h-80" value={webDescription} onChange={e => setWebDescription(e.target.value)}>
                                </textarea>
                              </Tab.Panel>
                            </Tab.Panels>
                          </Tab.Group>
                        </div>
                      </div>
                    </div>

                    <div className="bg-gray-50 px-4 py-3 justify-center flex sm:px-6">
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => {
                          //clearItemFields();
                          setItemsOpen(false);
                        }}
                      >
                        <img alt="" src={door} className="w-5" />
                        CERRAR
                      </button>
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => handleItemSubmit()}
                      >
                        <img alt="" src={floppy} className="w-8" />
                        GUARDAR
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>


        <Transition.Root show={checksOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              clearFields();
              setOpen(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto align-items-center justify-center items-center flex">
              <div className="flex justify-center p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-3xl bg-white text-center shadow-xl transition-all sm:my-8 min-w-[380px] w-[80%]">
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-0">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 w-full">
                          <Dialog.Title
                            as="h1"
                            className="text-center w-full font-regular leading-6 primary-color text-2xl"
                          >
                            Cheques
                          </Dialog.Title>
                          <div className="mt-2">
                            <div className="w-full">
                              <form
                                className="bg-white rounded-3xl overflow-hidden overflow-x-auto"
                                onSubmit={(e) => e.preventDefault()}
                              >
                                <table className="Purchases-table text-[11px] !w-[1024px]">
                                  <thead>
                                    <tr>
                                      <th>Banco</th>
                                      <th>Nombre</th>
                                      <th>Numero</th>
                                      <th>Dep. Numero</th>
                                      <th>Emision</th>
                                      <th>Vencimiento</th>
                                      <th>Monto</th>
                                      <th>Moneda</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {checks.map((check, i) => (
                                      <tr>
                                        <td>
                                          <select
                                            className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-[1.85rem]"
                                            style={{ lineHeight: 0.85 }}
                                            name="bank"
                                            onChange={(e) => {
                                              let newChecks = [...checks];
                                              newChecks.map((newCheck, c) => {
                                                if (c == i) {
                                                  newCheck.bankId =
                                                    e.target.value;
                                                }
                                              });
                                              setChecks(newChecks);
                                            }}
                                            value={check.bankId}
                                          >
                                            <option value={0}>
                                              Elija un banco
                                            </option>
                                            {banks.map((bank) => (
                                              <option value={bank.id}>
                                                {bank.name}
                                              </option>
                                            ))}
                                          </select>
                                        </td>
                                        <td>
                                          <input
                                            className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-6"
                                            name="name"
                                            type="text"
                                            onChange={(e) => {
                                              let newChecks = [...checks];
                                              newChecks.map((newCheck, c) => {
                                                if (c == i) {
                                                  newCheck.name =
                                                    e.target.value;
                                                }
                                              });
                                              setChecks(newChecks);
                                            }}
                                            value={check.name}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-6"
                                            name="number"
                                            type="number"
                                            onChange={(e) => {
                                              let newChecks = [...checks];
                                              newChecks.map((newCheck, c) => {
                                                if (c == i) {
                                                  newCheck.number =
                                                    e.target.value;
                                                }
                                              });
                                              setChecks(newChecks);
                                            }}
                                            value={check.number}
                                          />
                                        </td>

                                        <td>
                                          <input
                                            className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-6"
                                            name="depositNumber"
                                            type="number"
                                            onChange={(e) => {
                                              let newChecks = [...checks];
                                              newChecks.map((newCheck, c) => {
                                                if (c == i) {
                                                  newCheck.depositNumber =
                                                    e.target.value;
                                                }
                                              });
                                              setChecks(newChecks);
                                            }}
                                            value={check.depositNumber}
                                          />
                                        </td>
                                        
                                        <td>
                                          <input
                                            className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-6"
                                            name="issueDate"
                                            type="date"
                                            onChange={(e) => {
                                              let newChecks = [...checks];
                                              newChecks.map((newCheck, c) => {
                                                if (c == i) {
                                                  newCheck.issueDate =
                                                    e.target.value;
                                                }
                                              });
                                              setChecks(newChecks);
                                            }}
                                            value={check.issueDate}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-6"
                                            name="expirationDate"
                                            type="date"
                                            onChange={(e) => {
                                              let newChecks = [...checks];
                                              newChecks.map((newCheck, c) => {
                                                if (c == i) {
                                                  newCheck.expirationDate =
                                                    e.target.value;
                                                }
                                              });
                                              setChecks(newChecks);
                                            }}
                                            value={check.expirationDate}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-6"
                                            name="amount"
                                            type="number"
                                            onChange={(e) => {
                                              let newChecks = [...checks];
                                              newChecks.map((newCheck, c) => {
                                                if (c == i) {
                                                  newCheck.amount =
                                                    e.target.value;
                                                }
                                              });
                                              setChecks(newChecks);
                                            }}
                                            value={numberWithCommas(
                                              check.amount
                                            )}
                                          />
                                        </td>
                                        <td>
                                          <select
                                            className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-[1.85rem]"
                                            style={{ lineHeight: 0.85 }}
                                            name="currency"
                                            onChange={(e) => {
                                              let newChecks = [...checks];
                                              newChecks.map((newCheck, c) => {
                                                if (c == i) {
                                                  newCheck.currencyId =
                                                    e.target.value;
                                                }
                                              });
                                              setChecks(newChecks);
                                            }}
                                            value={check.currencyId}
                                          >
                                            <option value={0}>
                                              Elija una moneda
                                            </option>
                                            {currencies.map((currency) => (
                                              <option value={currency.id}>
                                                {currency.name}
                                              </option>
                                            ))}
                                          </select>
                                        </td>
                                        <td>
                                          {i == checks.length - 1 && (
                                            <button
                                              onClick={() => {
                                                let newChecks = [...checks];
                                                newChecks.push({
                                                  bankId: 0,
                                                  name: "",
                                                  number: 0,
                                                  depositNumber: 0,
                                                  issueDate: "",
                                                  expirationDate: "",
                                                  amount: 0,
                                                  currencyId: 0,
                                                });
                                                setChecks(newChecks);
                                              }}
                                              className="inline-flex w-full rounded-3xl font-family-thin secondary-bg-color justify-center px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto font-family-thin"
                                            >
                                              Agregar
                                            </button>
                                          )}
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 justify-center flex sm:px-6">
                      <button
                        type="button"
                        className="inline-flex w-full rounded-3xl font-family-thin secondary-bg-color justify-center px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto font-family-thin"
                        onClick={() => {
                          setChecks([
                            {
                              bankId: 0,
                              name: "",
                              number: 0,
                              depositNumber: 0,
                              issueDate: "",
                              expirationDate: "",
                              amount: 0,
                              currencyId: 0,
                            },
                          ]);
                          setChecksOpen(false);
                        }}
                      >
                        CANCELAR
                      </button>
                      <button
                        type="button"
                        className="inline-flex w-full align-center rounded-3xl align-items-center justify-center primary-color-bg px-3 py-2 text-sm font-semibold  shadow-sm sm:ml-3 sm:w-auto font-family-thin"
                        style={{ alignItems: "center" }}
                        onClick={() => {
                          setChecksOpen(false);
                        }}
                      >
                        GUARDAR
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              clearFields();
              setOpen(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto align-items-center justify-center items-center flex">
              <div className="flex justify-center p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-3xl bg-white text-center shadow-xl transition-all sm:my-8 w-[340px]">
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-0">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 w-full">
                          <Dialog.Title
                            as="h1"
                            className="text-center w-full font-regular leading-6 primary-color text-2xl"
                          >
                            Transferencia
                          </Dialog.Title>
                          <div className="mt-2">
                            <div className="w-full max-w-xs">
                              <form className="bg-white rounded-3xl">
                                <select
                                  name="accountId"
                                  value={accountId}
                                  className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[30px] text-center m-[2px]"
                                  onChange={(e) => setAccountId(e.target.value)}
                                >
                                  <option value={0}>Elija una cuenta</option>
                                  {accounts.map((account) => (
                                    <option value={account.id}>
                                      {account.name}
                                    </option>
                                  ))}
                                </select>

                                <input
                                  readOnly
                                  disabled
                                  placeholder="Nro. de Cuenta"
                                  name="accountNumber"
                                  value={account?.number}
                                  className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[30px] text-center m-[2px]"
                                />

                                <input
                                  readOnly
                                  disabled
                                  placeholder="Propietario"
                                  name="accountOwner"
                                  value={account?.owner}
                                  className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[30px] text-center m-[2px]"
                                />

                                <input
                                  readOnly
                                  disabled
                                  placeholder="Banco"
                                  name="accountBank"
                                  value={account?.bank?.name}
                                  className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[30px] text-center m-[2px]"
                                />

                                <input
                                  readOnly
                                  disabled
                                  placeholder="Tipo de cuenta"
                                  name="accounType"
                                  value={account?.accountype?.description}
                                  className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[30px] text-center m-[2px]"
                                />

                                <input
                                  readOnly
                                  disabled
                                  placeholder="Moneda"
                                  name="accounCurrency"
                                  value={account?.currency?.name}
                                  className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[30px] text-center m-[2px]"
                                />
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 justify-center flex sm:px-6">
                      <button
                        type="button"
                        className="inline-flex w-full rounded-3xl font-family-thin secondary-bg-color justify-center px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto font-family-thin"
                        onClick={() => {
                          setAccountId(0);
                          setAccount([]);
                          setOpen(false);
                        }}
                      >
                        CANCELAR
                      </button>
                      <button
                        type="button"
                        className="inline-flex w-full align-center rounded-3xl align-items-center justify-center primary-color-bg px-3 py-2 text-sm font-semibold  shadow-sm sm:ml-3 sm:w-auto font-family-thin"
                        style={{ alignItems: "center" }}
                        onClick={() => setOpen(false)}
                      >
                        GUARDAR
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <Transition.Root show={true}>
          <div className="flex flex-row">
            <div className="w-[60%] md:w-[20%] border-slate-200 border-r-2">
              <h1 className="ml-5 mt-5 text-2xl  title secondary-color font-family-thin">
                Compras
              </h1>
              <DateComponent className="!text-[14px] sm:block hidden" />
            </div>
            
            <div className="flex flex-row items-center justify-center">
              <label className="whitespace-nowrap" for="internalMovement">Movimiento interno:</label>
              <input className="m-2" type="checkbox" value={internalMovement} onChange={e => setInternalMovement(!internalMovement)} />
            </div>

            {width > 550 ? (
              <>
                <div className="w-[75%] pt-2 pl-2">
                  <div className="flex flex-row w-full">
                    <div className="w-[50%]">
                      <div className="mb-[1px]">
                        <ComboBox
                          data={providers}
                          setData={setProviderId}
                          value={providerId}
                          placeholder="PROVEEDOR"
                          className="h-[22px] w-[99.7%] !text-[10px]"
                          search={searchProviders}
                        />
                      </div>

                      <div className="mb-[-1px]">
                        <input
                          className="input-bg-color appearance-none border w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[22px] !text-[11px]"
                          id="RUC"
                          type="text"
                          disabled
                          readOnly
                          tabIndex={-1}
                          value={provider?.ruc || ""}
                          placeholder="RUC"
                          onChange={(e) => { }}
                        />
                      </div>

                      <div className="mb-[-1px]">
                        <input
                          className="input-bg-color appearance-none border w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[22px] !text-[11px]"
                          id="address"
                          type="text"
                          readOnly
                          disabled
                          tabIndex={-1}
                          value={provider?.address || ""}
                          placeholder="DIRECCION"
                          onChange={(e) => { }}
                        />
                      </div>

                      <div>
                        <input
                          className="input-bg-color appearance-none border w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[24px] !text-[11px]"
                          id="phone"
                          type="text"
                          readOnly
                          disabled
                          tabIndex={-1}
                          value={provider?.phone || ""}
                          placeholder="TELEFONO"
                          onChange={(e) => { }}
                        />
                      </div>
                    </div>

                    <div className="w-[45%] ml-[1px]">
                      <div className="mb-[0px] flex flex-row">
                        <div className="w-full mt-[-1px] ml-[-1px]">
                          <select
                            className="w-[99%] ml-[1px] input-bg-color appearance-none border w-[100.6%] py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-[22px] !text-[11px] uppercase"
                            id="branch"
                            readOnly
                            disabled
                            tabIndex={-1}
                            value={branchId}
                            onChange={(e) => {
                              setBranchId(e.target.value);
                            }}
                          >
                            <option value={0}>Sucursal</option>
                            <option value={user.branch.id}>
                              Sucursal: {user.branch.description}
                            </option>
                          </select>
                        </div>
                      </div>

                      <div className="mb-[2px]">
                        <div className="w-full">
                          <select
                            className="input-bg-color appearance-none border w-full py-1 px-3 text-gray-700 leading-tight h-[22px] !text-[11px] uppercase"
                            id="deposit"
                            value={depositId}
                            onChange={(e) => {
                              setDepositId(e.target.value);
                            }}
                          >
                            <option value={0}>Elija un deposito</option>
                            {deposits.map((deposit) => (
                              <option value={deposit.id}>
                                Deposito: {deposit.description}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="mt-[1px]">
                          <select
                            className="input-bg-color appearance-none border w-full py-1 px-3 text-gray-700 leading-tight uppercase h-[22px] !text-[11px]"
                            id="branch"
                            value={currencyId}
                            onChange={(e) => {
                              setCurrencyId(e.target.value);
                            }}
                          >
                            <option value={0}>Moneda</option>
                            {currencies.map((currency, i) => (
                              <option value={currency.id} key={i}>
                                Moneda: {currency.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div>
                        <input
                          className="input-bg-color appearance-none border w-full py-1 px-3 text-gray-700 leading-tight h-[22px] !text-[11px]"
                          id="cotizacion"

                          type="text"
                          placeholder="COTIZACION"
                          onChange={(e) => {
                            setCotizacion(e.target.value);

                          }}
                        />
                      </div>
                    </div>

                    <div className="w-[31%] ml-[1px]">
                      <div className="mb-[1px]">
                        <select
                          className="input-bg-color appearance-none border w-full py-1 px-3 text-gray-700 leading-tight capitalize h-[22px] !text-[11px]"
                          id="conditionId"
                          value={conditionId}
                          disabled={action == "edit" ? true : false}
                          readOnly={action == "edit" ? true : false}
                          onChange={(e) => {
                            setConditionId(e.target.value);
                            filterPaymentMethods(e.target.value);
                          }}
                        >
                          <option value={0}>Condicion de compra</option>
                          {conditions.map((condition) => (
                            <option value={condition.id}>
                              {condition.description}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="mb-[1px]">
                        <input
                          className="input-bg-color appearance-none border w-full py-1 px-3 text-gray-700 leading-tight h-[22px] !text-[11px]"
                          id="date"
                          value={date}
                          type="date"
                          placeholder="Fecha"
                          onChange={(e) => {
                            setDate(e.target.value);
                          }}
                        />
                      </div>

                      <div className="mb-[1px] flex flex-row">
                        <input
                          className="input-bg-color appearance-none border w-[49.5%] py-1 px-3 text-gray-700 leading-tight h-[22px] mt-[-1px] mr-[1px] !text-[11px]"
                          id="expeditionPoint"
                          value={expeditionPoint}
                          // readOnly
                          // disabled
                          type="text"
                          placeholder="Punto de exp."
                          onChange={(e) => { setExpeditionPoint(e.target.value) }}
                        />

                        <input
                          className="input-bg-color appearance-none border w-[50%] py-1 px-3 text-gray-700 leading-tight text-right h-[22px] !text-[11px]"
                          id="number"
                          value={number}
                          type="text"
                          placeholder="Numero"
                          onChange={(e) => {
                            setNumber(e.target.value);
                          }}
                        />
                      </div>

                      <div className="mb-[1px] flex flex-row">
                        <select
                          className="input-bg-color appearance-none border w-[50%] py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize mt-[-1px] h-[22px] !text-[11px]"
                          id="boxId"
                          value={boxId}
                          disabled
                          readOnly
                          onChange={(e) => {
                            setBoxId(e.target.value);
                          }}
                        >
                          <option value={0}>Caja</option>
                          <option value={user.box.id}>{user.box.name}</option>
                        </select>

                        <input
                          className="input-bg-color appearance-none border w-[50%] py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mt-[-1px] h-[22px] !text-[11px]"
                          id="stamped"
                          value={stamped}
                          // readOnly
                          // disabled
                          type="text"
                          placeholder="Timbrado"
                          onChange={(e) => { setStamped(e.target.value); }}
                        />
                      </div>
                    </div>

                    <div className="w-[20%] ml-[1px]">
                      {filteredPaymentMethods.map((paymentMethod, i) => (
                        <div className="mb-[1px]">
                          <input
                            ref={duesRefB}
                            readOnly={action == "edit" ? true : false}
                            className={`input-bg-color appearance-none border w-full py-1 px-2 text-gray-700 leading-tight h-[22px] !text-[11px] ${i > 1 ? "h-[22px]" : ""
                              } ${i === filteredPaymentMethods.length - 1 &&
                                conditionId != 2
                                ? "h-[22px]"
                                : ""
                              } ${i === filteredPaymentMethods.length - 1 &&
                                conditionId == 2
                                ? "h-[22px]"
                                : ""
                              }`}
                            value={numberWithCommas(paymentMethod.amount)}
                            type="text"
                            placeholder={paymentMethod.name}
                            onClick={() => {
                              if (
                                paymentMethod.id == 2 &&
                                paymentMethod.amount > 0
                              ) {
                                setChecksOpen(true);
                              }
                              if (
                                paymentMethod.id == 3 &&
                                paymentMethod.amount > 0
                              ) {
                                setOpen(true);
                              }
                            }}
                            onChange={(e) => {
                              let newFilteredPaymentMethods = [];
                              paymentMethods.map((paymentMethodMap) => {
                                if (paymentMethodMap.id == paymentMethod.id) {
                                  paymentMethodMap.amount = e.target.value;
                                  newFilteredPaymentMethods.push(paymentMethodMap);

                                  if (conditionId == 1 && i == 2) {
                                    let typingTimer;
                                    let doneTypingInterval = 1500;

                                    clearTimeout(typingTimer);
                                    typingTimer = setTimeout(function () {
                                      setOpen(true);
                                    }, doneTypingInterval);
                                  }

                                  if (conditionId == 1 && i == 1) {
                                    let typingTimer;
                                    let doneTypingInterval = 1500;

                                    clearTimeout(typingTimer);
                                    typingTimer = setTimeout(function () {
                                      setChecksOpen(true);
                                    }, doneTypingInterval);
                                  }
                                } else {
                                  if (paymentMethodMap.saleConditionId == conditionId) {
                                    newFilteredPaymentMethods.push(paymentMethodMap);
                                  }
                                }
                              });
                              setFilteredPaymentMethods(newFilteredPaymentMethods);

                            }}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>

          <div className="w-[100%] p-4 mx-auto overflow-auto pb-4 pt-0">
            <div className="bg-white px-4 pb-4 p-3 pt-0 mt-[-5px] text-center rounded-3xl">
              <div className="w-full">
                <div className="mt-2">
                  <div className="w-full flex flex-col">
                    {/* {action === "edit" && (
                                  <div className="mb-4">
                                    <label
                                      className="block text-gray-700 text-sm font-bold"
                                      htmlFor="SaleId"
                                    >
                                      Id
                                    </label>
                                    <input
                                      readOnly
                                      className="input-bg-color appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      id="SaleId"
                                      type="text"
                                      value={saleId}
                                    />
                                  </div>
                                )} */}

                    {width > 550 ? (
                      <></>
                    ) : (
                      <>
                        <Tab.Group>
                          <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1 mb-[2px]">
                            <Tab
                              className={({ selected }) =>
                                classNames(
                                  "w-full rounded-lg py-1.5 text-sm leading-5 primary-color text-xs",
                                  "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                                  providerId == 0
                                    ? "bg-red-300"
                                    : "bg-green-200",
                                  selected
                                    ? "bg-white font-bold"
                                    : "primary-color hover:text-slate-500"
                                )
                              }
                            >
                              Cliente
                            </Tab>
                            <Tab
                              className={({ selected }) =>
                                classNames(
                                  "w-full rounded-lg py-1.5 text-sm leading-5 primary-color text-xs",
                                  "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                                  branchId == 0 ||
                                    currencyId == 0 ||
                                    date == "" ||
                                    sellerId == 0
                                    ? "bg-red-300"
                                    : "bg-green-200",
                                  selected
                                    ? "bg-white font-bold"
                                    : "primary-color hover:text-slate-500"
                                )
                              }
                            >
                              Datos
                            </Tab>
                            <Tab
                              onClick={() => {
                                filterPaymentMethods(conditionId);
                              }}
                              className={({ selected }) =>
                                classNames(
                                  "w-full rounded-lg py-1.5 text-sm leading-5 primary-color text-xs",
                                  "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                                  conditionId == 0 || boxId == 0
                                    ? "bg-red-300"
                                    : "bg-green-200",
                                  selected
                                    ? "bg-white font-bold"
                                    : "primary-color hover:bg-white/[0.12] hover:text-slate-500"
                                )
                              }
                            >
                              Detalles
                            </Tab>
                            <Tab
                              className={({ selected }) =>
                                classNames(
                                  "w-full rounded-lg py-1.5 text-sm leading-5 primary-color text-xs",
                                  "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                                  filteredPaymentMethodsCompleted
                                    ? "bg-green-200"
                                    : "bg-red-300",
                                  selected
                                    ? "bg-white font-bold"
                                    : "primary-color hover:bg-white/[0.12] hover:text-slate-500"
                                )
                              }
                            >
                              Formas de Pago
                            </Tab>
                          </Tab.List>
                          <Tab.Panels>
                            <Tab.Panel>
                              <Transition.Child
                                enter="ease-out duration-500"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <div className="mb-[2px]">
                                  <ComboBox
                                    data={providers}
                                    setData={setProviderId}
                                    value={providerId}
                                    className="h-[30px]"
                                    search={searchProviders}
                                  />
                                </div>

                                <div className="mb-[2px]">
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                    id="RUC"
                                    type="text"
                                    disabled
                                    readOnly
                                    value={provider?.ruc || ""}
                                    placeholder="RUC"
                                    onChange={(e) => { }}
                                  />
                                </div>

                                <div className="mb-[2px]">
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                    id="address"
                                    type="text"
                                    readOnly
                                    disabled
                                    value={provider?.address || ""}
                                    placeholder="Direccion"
                                    onChange={(e) => { }}
                                  />
                                </div>

                                <div>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                    id="phone"
                                    type="text"
                                    readOnly
                                    disabled
                                    value={provider?.phone || ""}
                                    placeholder="Telefono"
                                    onChange={(e) => { }}
                                  />
                                </div>
                              </Transition.Child>
                            </Tab.Panel>
                            <Tab.Panel>
                              <Transition.Child
                                enter="ease-out duration-500"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <div className="mb-[2px] flex flex-row w-full">
                                  <div className="w-[50%]">
                                    <select
                                      className="input-bg-color appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                      id="branch"
                                      readOnly
                                      disabled
                                      value={branchId}
                                      onChange={(e) => {
                                        setBranchId(e.target.value);
                                      }}
                                    >
                                      <option value={0}>Sucursal</option>
                                      <option value={user.branch.id}>
                                        Sucursal: {user.branch.description}
                                      </option>
                                    </select>
                                  </div>
                                  <div className="w-[50%]">
                                    <select
                                      className="input-bg-color appearance-none border rounded w-[98%] py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ml-[1px] capitalize h-[29px]"
                                      id="deposit"
                                      value={depositId}
                                      onChange={(e) => {
                                        setDepositId(e.target.value);
                                      }}
                                    >
                                      <option value={0}>
                                        Elija un deposito
                                      </option>
                                      {deposits.map((deposit) => (
                                        <option value={deposit.id}>
                                          {deposit.description}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>

                                <div className="mb-[2px]">
                                  <select
                                    className="input-bg-color appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                    id="branch"
                                    value={currencyId}
                                    onChange={(e) => {
                                      setCurrencyId(e.target.value);
                                    }}
                                  >
                                    <option value={0}>Moneda</option>
                                    {currencies.map((currency, i) => (
                                      <option value={currency.id} key={i}>
                                        Moneda: {currency.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>

                                <div className="mb-[2px]">
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="date"
                                    value={date}
                                    type="date"
                                    placeholder="Fecha"
                                    onChange={(e) => {
                                      setDate(e.target.value);
                                    }}
                                  />
                                </div>
                                <div>
                                  <select
                                    className="input-bg-color appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                    id="sellerId"
                                    disabled
                                    readOnly
                                    value={sellerId}
                                    onChange={(e) => { }}
                                  >
                                    <option value={0}>Vendedor</option>
                                    <option value={user.id}>
                                      Vendedor: {user.fullname}
                                    </option>
                                  </select>
                                </div>
                              </Transition.Child>
                            </Tab.Panel>
                            <Tab.Panel>
                              <Transition.Child
                                enter="ease-out duration-500"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <div className="mb-[2px] flex flex-row">
                                  <input
                                    className="input-bg-color appearance-none border rounded w-[50%] py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="expeditionPoint"
                                    // readOnly
                                    // disabled
                                    value={expeditionPoint}
                                    type="text"
                                    placeholder="Punto de exp"
                                    onChange={(e) => { setExpeditionPoint(e.target.value); }}
                                  />

                                  <input
                                    className="input-bg-color appearance-none border rounded w-[50%] py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="number"
                                    value={number}
                                    type="text"
                                    placeholder="Numero"
                                    onChange={(e) => {
                                      setNumber(e.target.value);
                                    }}
                                  />
                                </div>

                                <div className="mb-[2px] flex flex-row">
                                  <select
                                    className="input-bg-color appearance-none border rounded w-[50%] py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                    id="boxId"
                                    value={boxId}
                                    disabled
                                    readOnly
                                    onChange={(e) => {
                                      setBoxId(e.target.value);
                                    }}
                                  >
                                    <option value={0}>Caja</option>
                                    <option value={user.box.id}>
                                      {user.box.name}
                                    </option>
                                  </select>

                                  <input
                                    className="input-bg-color appearance-none border rounded w-[50%] py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="stamped"
                                    // readOnly
                                    // disabled
                                    value={stamped}
                                    type="text"
                                    placeholder="Timbrado"
                                    onChange={(e) => { setStamped(e.target.value); }}
                                  />
                                </div>

                                <div className="mb-[2px]">
                                  <select
                                    className="input-bg-color appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                    id="conditionId"
                                    value={conditionId}
                                    disabled={action == "edit" ? true : false}
                                    readOnly={action == "edit" ? true : false}
                                    onChange={(e) => {
                                      setConditionId(e.target.value);
                                      filterPaymentMethods(e.target.value);
                                    }}
                                  >
                                    <option value={0}>
                                      Condicion de compra
                                    </option>
                                    {conditions.map((condition) => (
                                      <option value={condition.id}>
                                        {condition.description}
                                      </option>
                                    ))}
                                  </select>
                                </div>

                                {filteredPaymentMethods.length > 0 ? (
                                  <div>
                                    <input
                                      readOnly={action == "edit" ? true : false}
                                      className="input-bg-color appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                      ref={duesRef}
                                      value={numberWithCommas(
                                        filteredPaymentMethods[0]?.amount
                                      )}
                                      placeholder={
                                        filteredPaymentMethods[0]?.name
                                      }
                                      onChange={(e) => {
                                        let newFilteredPaymentMethods = [];
                                        paymentMethods.map((paymentMethod) => {
                                          if (
                                            paymentMethod.id ==
                                            filteredPaymentMethods[0].id
                                          ) {
                                            paymentMethod.amount = Number(
                                              String(e.target.value).replaceAll(
                                                ",",
                                                ""
                                              )
                                            );
                                            newFilteredPaymentMethods.push(
                                              paymentMethod
                                            );
                                          } else {
                                            if (
                                              conditionId ==
                                              paymentMethod.saleConditionId
                                            ) {
                                              newFilteredPaymentMethods.push(
                                                paymentMethod
                                              );
                                            }
                                          }
                                        });
                                        setFilteredPaymentMethods(
                                          newFilteredPaymentMethods
                                        );
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </Transition.Child>
                            </Tab.Panel>
                            <Tab.Panel>
                              <Transition.Child
                                enter="ease-out duration-500"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                {filteredPaymentMethods.map(
                                  (paymentMethod, i) =>
                                    i > 0 && (
                                      <div className="mb-[2px]">
                                        <input
                                          ref={duesRefB}
                                          readOnly={
                                            action == "edit" ? true : false
                                          }
                                          onClick={() => {
                                            if (
                                              paymentMethod.id == 2 &&
                                              paymentMethod.amount > 0
                                            ) {
                                              setChecksOpen(true);
                                            }
                                            if (
                                              paymentMethod.id == 3 &&
                                              paymentMethod.amount > 0
                                            ) {
                                              setOpen(true);
                                            }
                                          }}
                                          className="input-bg-color appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                          value={numberWithCommas(
                                            paymentMethod.amount
                                          )}
                                          type="text"
                                          placeholder={paymentMethod.name}
                                          onChange={(e) => {
                                            let newFilteredPaymentMethods = [];
                                            paymentMethods.map(
                                              (paymentMethodMap, i) => {
                                                if (
                                                  paymentMethodMap.id ==
                                                  paymentMethod.id
                                                ) {
                                                  paymentMethodMap.amount =
                                                    Number(
                                                      String(
                                                        e.target.value
                                                      ).replaceAll(",", "")
                                                    );
                                                  newFilteredPaymentMethods.push(
                                                    paymentMethodMap
                                                  );

                                                  if (
                                                    conditionId == 1 &&
                                                    i == 2
                                                  ) {
                                                    let typingTimer;
                                                    let doneTypingInterval = 1500;

                                                    clearTimeout(typingTimer);
                                                    typingTimer = setTimeout(
                                                      function () {
                                                        setOpen(true);
                                                      },
                                                      doneTypingInterval
                                                    );
                                                  }

                                                  if (
                                                    conditionId == 1 &&
                                                    i == 1
                                                  ) {
                                                    let typingTimer;
                                                    let doneTypingInterval = 1500;

                                                    clearTimeout(typingTimer);
                                                    typingTimer = setTimeout(
                                                      function () {
                                                        setChecksOpen(true);
                                                      },
                                                      doneTypingInterval
                                                    );
                                                  }
                                                } else {
                                                  if (
                                                    conditionId ==
                                                    paymentMethodMap.saleConditionId
                                                  ) {
                                                    newFilteredPaymentMethods.push(
                                                      paymentMethodMap
                                                    );
                                                  }
                                                }
                                              }
                                            );
                                            setFilteredPaymentMethods(
                                              newFilteredPaymentMethods
                                            );
                                          }}
                                        />
                                      </div>
                                    )
                                )}
                              </Transition.Child>
                            </Tab.Panel>
                          </Tab.Panels>
                        </Tab.Group>
                      </>
                    )}

                    <div className="w-full flex flex-row p-1 pt-0">
                      <div className="w-[100%] overflow-x-auto">
                        <div className="flex sm:flex-row flex-col w-full">
                          <div
                            className={`purchases-table sm:min-w-[795px] ${conditionId != 2 ? "w-full" : "w-full sm:w-[347%]"
                              }`}
                          >
                            <div className="text-gray">
                              {width > 620 ? (
                                <div
                                  className="tr flex mb-[1px] primary-color-bg !text-white"
                                  style={{ lineHeight: 2 }}
                                >
                                  <div className="w-[6%] font-bold !text-[11px]">
                                    ID
                                  </div>
                                  <div className="w-[37%] font-bold !text-[11px]">
                                    PRODUCTO
                                  </div>
                                  <div className="w-[7%] font-bold !text-[11px]">
                                    CANT
                                  </div>

                                  <div className="w-[10%] font-bold !text-[11px]">
                                    COSTO UNIT.
                                  </div>
                                  <div className="w-[10%] font-bold !text-[11px]">
                                    TOTAL
                                  </div>
                                  <div className="w-[9%] font-bold !text-[11px]">
                                    IVA
                                  </div>
                                  <div className="w-[5%] font-bold !text-[11px]">
                                    UTIL %
                                  </div>
                                  <div className="w-[10%] font-bold !text-[11px]">
                                    PRECIO TIENDA
                                  </div>
                                  <div className="w-[5%] font-bold !text-[11px]">
                                    UTIL %
                                  </div>
                                  <div className="w-[10%] font-bold !text-[11px]">
                                    PRECIO WEB
                                  </div>
                                  <div className="w-[5%] font-bold !text-[11px]">
                                    UTIL %
                                  </div>
                                  <div className="w-[10%] font-bold !text-[11px]">
                                    PRECIO MINIMO
                                  </div>

                                  <div className="w-[5%] font-bold !text-[11px]">
                                    ACT. PR.
                                  </div>

                                  <div className="w-[5%] font-bold !text-[11px]">
                                  </div>


                                </div>
                              ) : (
                                <div
                                  className="tr flex primary-color-bg !text-white !text-[11px]"
                                  style={{ lineHeight: 2 }}
                                >
                                  <div className="w-[15%] text-[15px] font-bold !text-[11px]">
                                    ID
                                  </div>
                                  <div className="w-[65%] text-[15px] font-bold !text-[11px]">
                                    PRODUCTO
                                  </div>
                                  <div className="w-[10%] text-[15px] font-bold !text-[11px]">
                                    CANT
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="overflow-y-auto overflow-x-hidden  border-botton-ssi" >
                              {isFetching && (
                                <div className="tr">
                                  <div className="td text-center" colSpan={20}>
                                    <Lottie
                                      animationData={loadingAnimation}
                                      style={{ width: 100, height: 100, margin: "0 auto" }}
                                      loop={true}
                                    />
                                  </div>
                                </div>
                              )}
                              {width > 620
                                ? items.map((item, i) => (
                                  <div className="tr flex border-slate-200 border-b-2">
                                    <div className="td w-[6%] m-[-0.5px]">
                                      <input
                                        className="input-bg-color py-1 px-3 w-full text-center h-[26px] !text-[10px]"
                                        type="text"
                                        readOnly
                                        name="code"
                                        value={item.id}
                                      />
                                    </div>

                                    <div className="td w-[37%] m-[-0.5px]">
                                      <ComboBox
                                        data={articles}
                                        value={item.id}
                                        setData={setItems}
                                        items={items}
                                        index={i}
                                        className="h-[26px] !text-[10px]"
                                        search={searchItems}
                                      />
                                    </div>
                                    <div className="td w-[7%] m-[-0.5px]">
                                      <input
                                        className="input-bg-color py-1 px-3 w-full text-center h-[26px] !text-[10px]"
                                        type="text"
                                        name="qty"
                                        value={item.qty}
                                        onChange={(e) => {
                                          let newItems = [...items];
                                          newItems.map((item, it) => {
                                            if (i == it) {
                                              item.qty = e.target.value;
                                              item.subtotal =
                                                String(item?.cost || 0).replaceAll(
                                                  ",",
                                                  ""
                                                ) * item.qty;
                                              item.subtotal =
                                                numberWithCommas(
                                                  item.subtotal
                                                );
                                            }
                                          });
                                          setItems(newItems);
                                        }}
                                      />
                                    </div>
                                    <div className="td w-[10%] m-[-0.5px]">
                                      <input
                                        className="input-bg-color py-1 px-3 w-full text-center h-[26px] !text-[10px]"
                                        type="text"
                                        name="cost"
                                        value={numberWithCommas(item.cost)}
                                        onChange={(e) => {
                                          let newItems = [...items];
                                          newItems.map((item, it) => {
                                            if (i == it) {
                                              item.cost = e.target.value;
                                              item.subtotal =
                                                String(item?.cost || 0).replaceAll(
                                                  ",",
                                                  ""
                                                ) * item.qty;
                                              item.subtotal =
                                                numberWithCommas(
                                                  item.subtotal
                                                );

                                              const newUtilidad = Number(String(item.utilityPercentage).replaceAll(",", ""));
                                              const newPrecio = Number(String(item.cost).replaceAll(",", "")) * (newUtilidad / 100) + Number(String(item.cost).replaceAll(",", ""));
                                              item.price = numberWithCommas(newPrecio);

                                              const newUtilidad2 = Number(String(item.utilityPercentage2).replaceAll(",", ""));
                                              const newPrecio2 = Number(String(item.cost).replaceAll(",", "")) * (newUtilidad2 / 100) + Number(String(item.cost).replaceAll(",", ""));
                                              item.utilityPrice2 = numberWithCommas(newPrecio2);

                                              const newUtilidad3 = Number(String(item.utilityPercentage3).replaceAll(",", ""));
                                              const newPrecio3 = Number(String(item.cost).replaceAll(",", "")) * (newUtilidad3 / 100) + Number(String(item.cost).replaceAll(",", ""));
                                              item.utilityPrice3 = numberWithCommas(newPrecio3);

                                            }
                                          });
                                          setItems(newItems);
                                        }}
                                      />
                                    </div>

                                    <div className="td w-[10%] m-[-0.5px]">
                                      <input
                                        className="input-bg-color py-1 px-3 w-full text-right h-[26px] !text-[10px]"
                                        type="text"
                                        name="subtotal"
                                        readOnly
                                        disabled
                                        value={numberWithCommas(item.subtotal)}
                                        onChange={(e) => {

                                        }}
                                      />
                                    </div>

                                    <div className="td w-[7%] m-[-0.5px]">
                                      <input
                                        className="input-bg-color py-1 px-3 w-full text-center h-[26px] !text-[10px]"
                                        type="text"
                                        name="iva"
                                        value={item.iva}
                                      />
                                    </div>

                                    <div className="td w-[5%] m-[-0.5px]">
                                      <input
                                        className="input-bg-color !bg-teal-100 py-1 px-3 w-full text-right h-[26px] !text-[10px]"
                                        type="text"
                                        name="utilityPercentage"
                                        value={item.utilityPercentage}
                                        onChange={(e) => handleUtilityPercentageChange(e, i)}
                                      />
                                    </div>


                                    <div className="td w-[10%] m-[-0.5px]">
                                      <input
                                        className="input-bg-color !bg-teal-100 py-1 px-3 w-full text-right h-[26px] !text-[10px]"
                                        type="text"
                                        name="price"
                                        value={numberWithCommas(item.price)}
                                        placeholder="Precio"
                                        onChange={(e) => {

                                          let newItems = [...items];
                                          newItems.map((item, it) => {
                                            if (i == it) {
                                              item.utilityPercentage = ((Number(String(e.target.value).replaceAll(",", "")) - Number(String(item.cost).replaceAll(",", ""))) / Number(String(item.cost).replaceAll(",", ""))) * 100;
                                              item.utilityPrice = Number(String(e.target.value).replaceAll(",", ""))
                                              item.utilityPrice = numberWithCommas(item.utilityPrice2);
                                            }
                                          });
                                          setItems(newItems);
                                        }}

                                      />
                                    </div>
                                    <div className="td w-[5%] m-[-0.5px]">
                                      <input
                                        className="input-bg-color !bg-teal-200 py-1 px-3 w-full text-right h-[26px] !text-[10px]"
                                        type="text"
                                        name="utilityPercentage2"
                                        value={item.utilityPercentage2}

                                        onChange={(e) => {
                                          let newItems = [...items];
                                          newItems.map((item, it) => {
                                            if (i == it) {
                                              const newUtilidad = e.target.value;
                                              const newPrecio = Number(String(item.cost).replaceAll(",", "")) * (newUtilidad / 100) + Number(String(item.cost).replaceAll(",", ""));
                                              item.utilityPrice2 = numberWithCommas(newPrecio.toFixed(2));
                                              item.utilityPercentage2 = newUtilidad;
                                            }
                                          });
                                          setPrecioUno(item.utilityPrice2)
                                          setUtilidadUno(item.utilityPercentage2)
                                          setItems(newItems);
                                        }}

                                      />
                                    </div>
                                    <div className="td w-[10%] m-[-0.5px]">
                                      <input
                                        className="input-bg-color !bg-teal-200 py-1 px-3 w-full text-right h-[26px] !text-[10px]"
                                        type="text"
                                        name="utilityPrice2"
                                        value={item.utilityPrice2}
                                        onChange={(e) => {

                                          let newItems = [...items];
                                          newItems.map((item, it) => {
                                            if (i == it) {
                                              item.utilityPercentage2 = ((Number(String(e.target.value).replaceAll(",", "")) - Number(String(item.cost).replaceAll(",", ""))) / Number(String(item.cost).replaceAll(",", ""))) * 100;
                                              item.utilityPrice2 = Number(String(e.target.value).replaceAll(",", ""))
                                              item.utilityPrice2 = numberWithCommas(item.utilityPrice2);
                                            }
                                          });
                                          setItems(newItems);
                                        }}
                                      />

                                    </div>
                                    <div className="td w-[5%] m-[-0.5px]">
                                      <input
                                        className="input-bg-color !bg-teal-300 py-1 px-3 w-full text-right h-[26px] !text-[10px]"
                                        type="text"
                                        name="utilityPercentage3"
                                        value={item.utilityPercentage3}
                                        onChange={(e) => {
                                          let newItems = [...items];
                                          newItems.map((item, it) => {
                                            if (i == it) {
                                              const newUtilidad = e.target.value;
                                              const newPrecio = Number(String(item.cost).replaceAll(",", "")) * (newUtilidad / 100) + Number(String(item.cost).replaceAll(",", ""));
                                              item.utilityPrice3 = numberWithCommas(newPrecio.toFixed(2));
                                              item.utilityPercentage3 = newUtilidad;
                                            }
                                          });
                                          setItems(newItems);
                                        }}

                                      />
                                    </div>
                                    <div className="td w-[10%] m-[-0.5px]">
                                      <input
                                        className="input-bg-color !bg-teal-300 py-1 px-3 w-full text-right h-[26px] !text-[10px]"
                                        type="text"
                                        name="utilityPrice3"
                                        value={item.utilityPrice3}
                                        onChange={(e) => {

                                          let newItems = [...items];
                                          newItems.map((item, it) => {
                                            if (i == it) {
                                              item.utilityPercentage3 = ((Number(String(e.target.value).replaceAll(",", "")) - Number(String(item.cost).replaceAll(",", ""))) / Number(String(item.cost).replaceAll(",", ""))) * 100;
                                              item.utilityPrice3 = Number(String(e.target.value).replaceAll(",", ""))
                                              item.utilityPrice3 = numberWithCommas(item.utilityPrice3);
                                            }
                                          });
                                          setPrecioDos(item.utilityPrice2)
                                          setUtilidadDos(item.utilityPercentage2)
                                          setItems(newItems);
                                        }}
                                      />
                                    </div>

                                    <div className="td w-[5%] m-[-0.5px]">
                                      <input
                                        className="input-bg-color py-1 px-3 w-full text-right h-[20px] !text-[10px]"
                                        type="checkbox"
                                        name="updatePrice"
                                        isChecked={item.updatePrice}
                                        onChange={(e) => {
                                          let newItems = [...items];
                                          newItems.map((item, it) => {
                                            if (i == it) {
                                              item.updatePrice = !item?.updatePrice;
                                            }
                                          });
                                          setItems(newItems);
                                        }}
                                      />

                                    </div>

                                    <div className="td w-[5%] m-[1px] text-center">
                                      <button onClick={() => {
                                        let newItems = [];
                                        items.map((item, it) => {
                                          if (i == it) {
                                            newItems.push({
                                              id: 0,
                                              qty: 0,
                                              name: "",
                                              price: 0,
                                              cost: 0,
                                              subtotal: 0,
                                              iva: "",
                                              utilityPercentage: 0,
                                              utilityPercentage2: 0,
                                              utilityPrice2: 0,
                                              utilityPercentage3: 0,
                                              utilityPrice3: 0,
                                            });
                                          } else {
                                            newItems.push(item);
                                          }
                                        });
                                        setItems(newItems);
                                      }}>
                                        <FontAwesomeIcon
                                          className="m-1 cursor-pointer secondary-color"
                                          icon={faTrash}
                                        />
                                      </button>
                                      {/* <img src={discard} onClick={() => {
                                        let newItems = [];
                                        items.map((item, it) => {
                                          if (i == it) {
                                            newItems.push({
                                              id: 0,
                                              qty: 0,
                                              name: "",
                                              price: 0,
                                              cost: 0,
                                              subtotal: 0,
                                              iva: "",
                                              utilityPercentage: 0,
                                              utilityPercentage2: 0,
                                              utilityPrice2: 0,
                                              utilityPercentage3: 0,
                                              utilityPrice3: 0,
                                            });
                                          }else{
                                            newItems.push(item);
                                          }
                                        });
                                        setItems(newItems);
                                      }} className="w-8 m-[auto] cursor-pointer" /> */}
                                    </div>

                                  </div>
                                ))
                                : items.map((item, i) => (
                                  <div
                                    className="tr flex flex-col m-1 pb-1 border-slate-200 border-b-2"
                                    style={{
                                      borderBottom:
                                        "1px solid rgba(0,0,0,0.5)",
                                    }}
                                  >
                                    <div className="w-full flex flex-row">
                                      <div className="td w-[100%] m-[1px]">
                                        <input
                                          className="input-bg-color py-1 px-3 w-full text-center"
                                          type="text"
                                          readOnly
                                          name="code"
                                          value={item.id}
                                        />
                                      </div>
                                    </div>

                                    <div className="w-full flex flex-row">
                                      <div className="td w-[100%] m-[1px]">
                                        <ComboBox
                                          data={articles}
                                          value={item.id}
                                          setData={setItems}
                                          items={items}
                                          index={i}
                                          search={searchItems}
                                          className="h-[32px]"
                                        />
                                      </div>
                                    </div>

                                    <div className="w-full flex flex-row">
                                      <div className="td w-[100%] m-[1px]">
                                        <input
                                          className="input-bg-color py-1 px-3 w-full text-center"
                                          type="text"
                                          name="cost"
                                          value={numberWithCommas(item.cost)}
                                          onChange={(e) => {
                                            let newItems = [...items];

                                            newItems.map((item, it) => {
                                              if (i == it) {
                                                item.cost = e.target.value;
                                                item.subtotal =
                                                  String(
                                                    item?.cost || 0
                                                  ).replaceAll(",", "") *
                                                  item.qty;
                                                item.subtotal =
                                                  numberWithCommas(
                                                    item.subtotal
                                                  );
                                              }
                                            });
                                            setItems(newItems);
                                          }}
                                        />
                                      </div>
                                    </div>

                                    <div className="w-full flex flex-row">
                                      <div className="td w-[50%]">
                                        <input
                                          className="input-bg-color py-1 px-3 w-full text-center"
                                          type="text"
                                          name="qty"
                                          value={item.qty}
                                          onChange={(e) => {
                                            let newItems = [...items];
                                            newItems.map((item, it) => {
                                              if (i == it) {
                                                item.qty = e.target.value;
                                                item.subtotal =
                                                  String(
                                                    item?.cost || 0
                                                  ).replaceAll(",", "") *
                                                  item.qty;
                                                item.subtotal =
                                                  numberWithCommas(
                                                    item.subtotal
                                                  );
                                              }
                                            });
                                            setItems(newItems);
                                          }}
                                        />
                                      </div>

                                      <div className="td w-[50%]">
                                        <input
                                          className="input-bg-color py-1 px-3 w-full text-right"
                                          type="text"
                                          readOnly
                                          name="subtotal"
                                          value={numberWithCommas(
                                            item.subtotal
                                          )}
                                        />
                                      </div>
                                    </div>

                                    <div className="w-full flex flex-row">
                                      <div className="td w-[50%] m-[1px]">
                                        <input
                                          className="input-bg-color py-1 px-3 w-full text-center"
                                          type="text"
                                          name="price"
                                          value={numberWithCommas(item.price)}
                                          placeholder="Precio"
                                          onChange={(e) => {
                                            let newItems = [...items];
                                            newItems.map((item, it) => {
                                              if (i == it) {
                                                item.price = String(
                                                  e.target.value
                                                ).replaceAll(",", "");
                                                // item.subtotal =
                                                //   String(
                                                //     item.price
                                                //   ).replaceAll(",", "") *
                                                //   item.qty;
                                                // item.subtotal =
                                                //   numberWithCommas(
                                                //     item.subtotal
                                                //   );
                                              }
                                            });
                                            setItems(newItems);
                                          }}
                                        />
                                      </div>

                                      <div className="td w-[50%] m-[1px]">
                                        <input
                                          className="input-bg-color py-1 px-3 w-full text-center"
                                          type="text"
                                          name="iva"
                                          value={item.iva}
                                        />
                                      </div>
                                    </div>

                                    <div className="w-full flex flex-row">
                                      <div className="td w-[100%] m-[1px]">
                                        ACTUALIZAR PRECIOS DE VENTA:
                                        <input
                                          className="input-bg-color py-1 px-3 w-full text-right h-[20px] !text-[10px]"
                                          type="checkbox"
                                          name="updatePrice"
                                          isChecked={item.updatePrice}
                                          onChange={(e) => {
                                            let newItems = [...items];
                                            newItems.map((item, it) => {
                                              if (i == it) {
                                                item.updatePrice = !item?.updatePrice;
                                              }
                                            });
                                            setItems(newItems);
                                          }}
                                        />
                                      </div>
                                    </div>

                                  </div>
                                ))}
                            </div>

                            <div className="tr flex flex-row justify-end items-center mr-3 mt-[2px]">
                              <div className="td text-right text-[14px] font-bold mr-1 primary-color">
                                TOTAL:
                              </div>
                              <div className="td w-[30%] sm:w-[15%] border-b-[1px] border-black pb-[2px]">
                                <input
                                  className="!bg-transparent border-b-[1px] border-t-[1px] border-black !text-black py-1 px-3 w-full text-right font-bold text-[14px]"
                                  type="text"
                                  readOnly
                                  disabled
                                  name="total"
                                  value={totalAmount}
                                  placeholder="Total"
                                />
                              </div>

                            </div>
                            <div className="tr flex flex-row justify-end items-center mr-3 mt-[1px]">
                              <div className="td text-right text-[14px] font-bold mr-1 primary-color">
                                TOTAL IVA 5%:
                              </div>
                              <div className="td w-[30%] sm:w-[15%] border-b-[1px] border-black pb-[2px]">
                                <input
                                  className="!bg-transparent border-b-[1px] border-t-[1px] border-black !text-black py-1 px-3 w-full text-right font-bold text-[14px]"
                                  type="text"
                                  readOnly
                                  disabled
                                  name="total"
                                  value={totalAmountIva5}
                                  placeholder="Total"
                                />
                              </div>
                            </div>
                            <div className="tr flex flex-row justify-end items-center mr-3 mt-[1px]">
                              <div className="td text-right text-[14px] font-bold mr-1 primary-color">
                                TOTAL IVA 10%:
                              </div>
                              <div className="td w-[30%] sm:w-[15%] border-b-[1px] border-black pb-[2px]">
                                <input
                                  className="!bg-transparent border-b-[1px] border-t-[1px] border-black !text-black py-1 px-3 w-full text-right font-bold text-[14px]"
                                  type="text"
                                  readOnly
                                  disabled
                                  name="total"
                                  value={totalAmountIva10}
                                  placeholder="Total"
                                />
                              </div>
                            </div>



                          </div>

                          {conditionId == 2 && (
                            <div className="table !block Purchases-table !w-[98%] sm:w-[22%] ml-2 !h-[250px] overflow-hidden overflow-y-scroll relative pt-0 xl:pt-[23px] min-w-[240px] sm:mt-0 mt-3">
                              <div className="flex flex-row w-full xl:fixed block z-10 mt-0 xl:mt-[-23px]">
                                <label
                                  className="block text-sm font-bold text-center uppercase primary-color-bg !text-white !text-[11px] w-[33%] xl:w-[6.3%]"
                                  style={{ lineHeight: 2 }}
                                  htmlFor="number"
                                >
                                  NRO.
                                </label>
                                <label
                                  className="block text-sm font-bold text-center uppercase primary-color-bg !text-white !text-[11px] w-[33%] xl:w-[6.3%]"
                                  style={{ lineHeight: 2 }}
                                  htmlFor="expirationDate"
                                >
                                  VENC.
                                </label>
                                <label
                                  className="block text-sm font-bold text-center uppercase primary-color-bg !text-white !text-[11px] w-[33%] xl:w-[6.3%]"
                                  style={{ lineHeight: 2 }}
                                  htmlFor="amount"
                                >
                                  MONTO
                                </label>
                              </div>

                              {dues.length > 0 &&
                                dues.map((due, i) => (
                                  <>
                                    <div className="flex flex-row relative">
                                      <div
                                        className={`w-[20%] border-slate-200 border-b-2`}
                                      >
                                        <input
                                          className="w-[45px] input-bg-color appearance-none rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[28px] text-center"
                                          id="number"
                                          readOnly
                                          type="text"
                                          value={due.number}
                                        />
                                      </div>

                                      <div
                                        className={`border-slate-200 border-b-2`}
                                      >
                                        <input
                                          className="input-bg-color appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[28px]"
                                          id="expirationDate"
                                          type="date"
                                          value={due.expirationDate}
                                          onChange={(e) => {
                                            let newDues = [...dues];
                                            newDues.map((due, idx) => {
                                              if (idx == i) {
                                                due.expirationDate =
                                                  e.target.value;
                                              }
                                            });
                                            setDues(newDues);
                                          }}
                                        />
                                      </div>

                                      <div
                                        className={`border-slate-200 border-b-2`}
                                      >
                                        <input
                                          className="input-bg-color appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[28px] text-center"
                                          id="amount"
                                          type="text"
                                          value={numberWithCommas(due.amount)}
                                          onChange={(e) => {
                                            let newDues = [...dues];
                                            newDues.map((due, idx) => {
                                              if (idx == i) {
                                                due.amount = Number(
                                                  String(
                                                    e.target.value
                                                  ).replaceAll(",", "")
                                                );
                                              }
                                            });
                                            setDues(newDues);

                                          }}
                                        />
                                      </div>
                                    </div>
                                  </>
                                ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-2 py-2 justify-start flex relative top-3">
                <button
                  type="button"
                  className="float-left w-[65px] h-[63px] inline-flex flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                  onClick={() => {
                    clearFields();
                  }}
                >
                  <img src={broom} className="w-8" />
                  LIMPIAR
                </button>
                <button
                  readOnly={action == "edit" ? true : false}
                  disabled={action == "edit" ? true : false}
                  type="button"
                  className="float-left inline-flex w-[65px] h-[63] m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                  style={{ alignItems: "center" }}
                  onClick={() => handleSubmit()}
                >
                  <img src={floppy} className="w-8" />
                  GUARDAR
                </button>

                <button
                  readOnly={action == "edit" ? true : false}
                  disabled={action == "edit" ? true : false}
                  type="button"
                  className="float-left inline-flex w-[65px] h-[63px] ml-[-0px] flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                  style={{ alignItems: "center" }}
                  onClick={() => navigate("/")}
                >
                  <img src={door} className="w-5" />
                  SALIR
                </button>
              </div>
            </div>
          </div>
        </Transition.Root>
      </Layout >
    </>
  );
};

export default PurchasesCreate;
