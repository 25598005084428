import React, { useState, Fragment, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Dialog, Transition } from "@headlessui/react";
import Layout from "../layout";
import { axiosAuth } from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser, setTitle } from "../store/slices/mainSlice";
import { useLocation, useParams } from "react-router-dom";
import Pagination from "../components/pagination";
import DateComponent from "../components/date";
import ComboBox from "../components/combobox";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/animations/loading.json";
import providerimg from "../assets/img/provider.webp";
import door from "../assets/img/door.png";
import floppy from "../assets/img/floppy.png";
import { motion } from "framer-motion";
import plus from "../assets/img/plus.png";
import useViewport from "../utils/useViewport";

const Providers = () => {
  const user = useSelector((state) => state.main.user);

  const dispatch = useDispatch();
  const location = useLocation();

  const { page, search } = useParams();

  const [isFetching, setIsFetching] = useState(false);
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState("edit");
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);

  const [providers, setProviders] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [cities, setCities] = useState([]);
  const [zones, setZones] = useState([]);
  const [stampeds, setStampeds] = useState([]);

  const [providerId, setProviderId] = useState(0);
  const [name, setName] = useState("");
  const [ruc, setRuc] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [hood, setHood] = useState("");
  const [companyId, setCompanyId] = useState(0);
  const [cityId, setCityId] = useState(0);
  const [zoneId, setZoneId] = useState(0);
  const [stampedId, setStampedId] = useState(0);
  const [observations, setObservations] = useState("");
  const [active, setActive] = useState(0);
  const [deleted, setDeleted] = useState(0);
  const [searchText, setSearchText] = useState(search || "");
  const { width } = useViewport();

  async function fetchProvidersData() {
    let pageQuery = page == undefined ? 1 : page;

    setIsFetching(true);

    let data = await axiosAuth.get("/providers?page=" + pageQuery);

    if (data.data.error) {
      if (data.data.message === "token") {
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    setIsFetching(false);

    setProviders(data.data.providers.rows);
    setPages(data.data.providers.pages);
    setTotal(data.data.providers.count);

    data.data.companies.unshift({ id: 0, name: "Elija una empresa" });
    setCompanies(data.data.companies);

    setCities(data.data.cities);
    setZones(data.data.zones);
    setStampeds(data.data.stampeds);
  }

  useEffect(() => {
    fetchProvidersData();
  }, []);

  useEffect(() => {
    fetchProvidersData();
    dispatch(setTitle("Proveedores"));
  }, [location]);

  const editProvider = (providerId) => {
    setAction("edit");
    setProviderId(providerId);

    let provider = providers.filter(
      (provider) => provider.id === providerId
    )[0];

    setName(provider.name);
    setRuc(provider.ruc);
    setAddress(provider.address);
    setPhone(provider.phone);
    setEmail(provider.email);
    setHood(provider.hood);
    setCompanyId(provider.companyId);
    setCityId(provider.cityId);
    setZoneId(provider.zoneId);
    setStampedId(provider.stampedId);
    setObservations(provider.observations);
    setActive(provider.active);
    setDeleted(provider.deleted);

    setOpen(true);
  };

  const clearFields = () => {
    setProviderId(0);
    setName("");
    setRuc("");
    setAddress("");
    setEmail("");
    setPhone("");
    setHood("");
    setCompanyId(0);
    setCityId(0);
    setZoneId(0);
    setStampedId(0);
    setObservations("");
    setActive(0);
    setDeleted(0);
  };

  const handleSubmit = async () => {
    if (action === "edit") {
      let objToSend = {
        providerId,
        name,
        ruc,
        address,
        phone,
        email,
        hood,
        companyId,
        cityId,
        zoneId,
        stampedId,
        observations,
        active,
        deleted,
      };

      let updatedProviderRequest = await axiosAuth.put("/providers", objToSend);

      if (updatedProviderRequest.data.error) {
        if (updatedProviderRequest.data.message === "token") {
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      } else {
        let updatedProvidersNewObj = [];

        providers.map((provider) => {
          if (provider.id == providerId) {
            updatedProvidersNewObj.push(updatedProviderRequest.data);
          } else {
            updatedProvidersNewObj.push(provider);
          }
          return "";
        });

        setProviders(updatedProvidersNewObj);
      }
    } else {
      if (
        name === "" ||
        ruc === "" ||
        email === "" ||
        phone === "" ||
        companyId === 0 ||
        cityId === 0 ||
        zoneId === 0
      ) {
        alert("Debe completar todos los campos !");
        return false;
      }

      let createdProvider = await axiosAuth.post("/providers", {
        name,
        ruc,
        address,
        phone,
        email,
        hood,
        companyId,
        cityId,
        zoneId,
        stampedId,
        observations,
        active,
        deleted,
      });

      if (createdProvider.data.error) {
        if (createdProvider.data.message === "token") {
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      } else {
        let newProviders = [...providers];
        newProviders.push(createdProvider.data);
        setProviders(newProviders);
      }
    }
    setOpen(false);
    clearFields();
  };
  const searchProviders = async (searchTxt) => {
    setSearchText(searchTxt);

    const searchProvidersRequest = await axiosAuth.get(
      "/searchProviders?search=" + searchTxt
    );
    console.log(searchProvidersRequest);
    setProviders(searchProvidersRequest.data.providers.rows);
    setPages(searchProvidersRequest.data.providers.pages);
    setTotal(searchProvidersRequest.data.providers.total);
  };
  const deleteProvider = async (providerId) => {
    if (window.confirm("Desea eliminar este proveedor ?")) {
      let providerDeleteRequest = await axiosAuth.delete("/providers", {
        params: { id: providerId },
      });

      if (providerDeleteRequest.data.error) {
        if (providerDeleteRequest.data.message === "token") {
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      let newProvidersObj = [];
      let loggedUser = user;

      providers.map((provider) => {
        if (provider.id !== providerId) {
          newProvidersObj.push(provider);
        } else {
          if (loggedUser.roleId == 1) {
            provider.deleted = 1;
            newProvidersObj.push(provider);
          }
        }
        return "";
      });

      setProviders(newProvidersObj);
    }
  };

  return (
    <>
      <Layout>
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              clearFields();
              setOpen(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div
              className="fixed inset-0 z-10 w-screen overflow-y-auto align-items-center justify-center items-center flex pb-5"
              style={{ paddingTop: 180 }}
            >
              <div className="flex justify-center p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-3xl bg-white text-left shadow-xl transition-all sm:my-8 w-[280px] sm:mt-[415px] mt-[200px] mb-12">
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-0">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 sm:text-left">
                          <Dialog.Title
                            as="h1"
                            className="text-center font-regular leading-6 primary-color text-3xl"
                          >
                            {action === "edit" ? "Editar" : "Crear"} proveedor
                          </Dialog.Title>
                          <div className="mt-5">
                            <div className="w-full max-w-xs">
                              <form className="bg-white rounded">
                                {action === "edit" && (
                                  <div className="mb-4">
                                    <label
                                      className="block text-gray-700 text-sm font-bold"
                                      htmlFor="id"
                                    >
                                      Id
                                    </label>
                                    <input
                                      readOnly
                                      className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      id="id"
                                      type="text"
                                      value={providerId}
                                    />
                                  </div>
                                )}

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="name"
                                  >
                                    Nombre
                                  </label>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                    id="name"
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                  />
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="ruc"
                                  >
                                    RUC
                                  </label>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="ruc"
                                    type="text"
                                    value={ruc}
                                    onChange={(e) => setRuc(e.target.value)}
                                  />
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="address"
                                  >
                                    Direccion
                                  </label>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="address"
                                    type="text"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                  />
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="phone"
                                  >
                                    Telefono
                                  </label>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="phone"
                                    type="text"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                  />
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="email"
                                  >
                                    Email
                                  </label>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="email"
                                    type="text"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                  />
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="hood"
                                  >
                                    Barrio
                                  </label>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="hood"
                                    type="text"
                                    value={hood}
                                    onChange={(e) => setHood(e.target.value)}
                                  />
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="companyId"
                                  >
                                    Empresa
                                  </label>
                                  <ComboBox
                                    data={companies}
                                    setData={setCompanyId}
                                    value={companyId}
                                  />
                                  {/* <select
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="companyId"
                                    value={companyId}
                                    onChange={(e) => {
                                      setCompanyId(e.target.value);
                                    }}
                                  >
                                    <option value={0}>Elija una empresa</option>
                                    {companies.map((company) => (
                                      <option
                                        key={company.id}
                                        value={company.id}
                                      >
                                        {company.name}                                     </option>
                                    ))}
                                  </select> */}
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="cityId"
                                  >
                                    Ciudad
                                  </label>
                                  <select
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="cityId"
                                    value={cityId}
                                    onChange={(e) => {
                                      setCityId(parseInt(e.target.value));
                                    }}
                                  >
                                    <option value={0}>Elija una ciudad</option>
                                    {cities.map((city) => (
                                      <option key={city.id} value={city.id}>
                                        {city.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="zoneId"
                                  >
                                    Zona
                                  </label>
                                  <select
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="zoneId"
                                    value={zoneId}
                                    onChange={(e) => {
                                      setZoneId(parseInt(e.target.value));
                                    }}
                                  >
                                    <option value={0}>Elija una zona</option>
                                    {zones.map((zone) => (
                                      <option key={zone.id} value={zone.id}>
                                        {zone.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="zoneId"
                                  >
                                    Timbrado
                                  </label>
                                  <select
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="stampedId"
                                    value={stampedId}
                                    onChange={(e) => {
                                      setStampedId(parseInt(e.target.value));
                                    }}
                                  >
                                    <option value={0}>Elija un timbrado</option>
                                    {stampeds.map((stamped) => (
                                      <option
                                        key={stamped.id}
                                        value={stamped.id}
                                      >
                                        {stamped.expeditionPoint}{" "}
                                        {stamped.stamped} {stamped.number}
                                      </option>
                                    ))}
                                  </select>
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="observations"
                                  >
                                    Observacion
                                  </label>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="observations"
                                    type="text"
                                    value={observations}
                                    onChange={(e) =>
                                      setObservations(e.target.value)
                                    }
                                  />
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="active"
                                  >
                                    Activo
                                  </label>
                                  <select
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="active"
                                    value={active}
                                    onChange={(e) =>
                                      setActive(parseInt(e.target.value))
                                    }
                                  >
                                    <option value={0}>No</option>
                                    <option value={1}>Si</option>
                                  </select>
                                </div>

                                {user.roleId == 1 && (
                                  <div className="mb-4">
                                    <label
                                      className="block text-gray-700 text-sm font-bold"
                                      htmlFor="deleted"
                                    >
                                      Borrado
                                    </label>
                                    <select
                                      className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      id="deleted"
                                      value={deleted}
                                      onChange={(e) =>
                                        setDeleted(parseInt(e.target.value))
                                      }
                                    >
                                      <option value={0}>No</option>
                                      <option value={1}>Si</option>
                                    </select>
                                  </div>
                                )}
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 justify-center flex sm:px-6">
                    <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => handleSubmit()}
                      >
                        <img src={floppy} className="w-8" />
                        GUARDAR
                      </button>
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => {
                          clearFields();
                          setOpen(false);
                        }}
                      >
                        <img src={door} className="w-5" />
                        CERRAR
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/*  <h1 className="ml-5 mt-5 text-3xl secondary-color title font-family-thin">
          Proveedores
        </h1>
        <DateComponent className="sm:block hidden" />
        <button
          type="button"
          className="relative right-[25px] sm:right-[50px] bottom-[10px] mb-8 inline-flex w-[92px] float-right m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
          style={{ alignItems: "center" }}
          onClick={() => {
            setAction("create");
            setOpen(true);
          }}
        >
          <img src={providerimg} className="w-8" />
          CREAR PROVEEDOR
        </button> */}
        <div
          class="w-full top-[-40px] flex flex-col sm:flex-row pb-2 relative bg-white "
          style={{ borderBottom: "1px solid lightgray" }}
        >
          <div className="sm:w-[30%] w-[60%]">
            <h1 className="ml-5 mt-5 text-3xl  title font-family-thin secondary-color">
              Proveedores
            </h1>
            <DateComponent className="sm:block hidden" />
          </div>
          <div class="sm:w-[50%] w-[85%] mb-[1px] sm:ml-0 ml-6 flex flex-row items-center w-200 sm:mt-2 mt-12">
            <label className="block text-gray-700 text-[10px] font-bold uppercase mr-[3px] sm:mt-5">
              Buscar:
            </label>
            <input
              value={searchText}
              className="input-bg-color appearance-none border w-[350px]  rounded  
            py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline leading-[10px] h-[35px] sm:mt-5"
              placeholder="Nombre del Proveedor"
              onChange={(e) => searchProviders(e.target.value)}
            />
            <motion.button
              initial={false}
              whileTap="press"
              onClick={() => {
                setAction("create");
                setOpen(true);
              }}
              className="inline-flex flex-row float-right sm:w-[170px] w-[30%] mr-1 justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 relative left-[10px] sm:mt-5 h-[35px] "
            >
              <img alt="" src={plus} className="w-8" />
              CREAR PROVEEDOR
            </motion.button>
          </div>
        </div>
        <div className="w-[95%] p-3 mx-auto overflow-auto relative top-[-40px]">
          <table className="w-[95%] table-auto mx-auto w-full text-xs providers-table support-table">
            <thead>
              {width > 1024 ? (
                <tr className="bg-gray-500 h-8">
                  <th className="uppercase text-left !text-white">Id</th>
                  <th className="uppercase text-left !text-white">Nombre</th>
                  <th className="uppercase text-left !text-white">RUC</th>
                  <th className="uppercase text-left !text-white">Direccion</th>
                  <th className="uppercase text-left !text-white">Telefono</th>
                  <th className="uppercase text-left !text-white">Email</th>
                  <th className="uppercase text-left !text-white">Barrio</th>
                  {user.roleId == 1 && (
                    <th className="uppercase text-left !text-white">Empresa</th>
                  )}
                  <th className="uppercase text-left !text-white">Ciudad</th>
                  <th className="uppercase text-left !text-white">Zona</th>
                  <th className="uppercase text-left !text-white">Timbrado</th>
                  <th className="uppercase text-left !text-white">
                    Observacion
                  </th>
                  <th className="uppercase text-left !text-white">Activo</th>
                  {user.roleId == 1 && (
                    <th className="uppercase !text-white">Borrado</th>
                  )}
                  <th className="uppercase !text-white">Fecha Alta</th>
                  <th className="uppercase !text-white">Accion</th>
                </tr>
              ) : (
                <></>
              )}
            </thead>
            <tbody>
              {isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={15}>
                    <Lottie
                      animationData={loadingAnimation}
                      style={{ width: 100, height: 100, margin: "0 auto" }}
                      loop={true}
                    />
                  </td>
                </tr>
              )}
              {providers.length > 0 &&
                !isFetching &&
                providers?.map((provider) => {
                  if (width > 1024) {
                    return (
                      <tr key={provider.id}>
                        <td className="text-left">{provider.id}</td>
                        <td className="uppercase text-left">{provider.name}</td>
                        <td className="text-left">{provider.ruc}</td>
                        <td className="text-left">{provider.address}</td>
                        <td className="text-left">{provider.phone}</td>
                        <td className="text-left">{provider.email}</td>
                        <td className="text-left">{provider.hood}</td>
                        {user.roleId == 1 && (
                          <td className="text-left">{provider.company.name}</td>
                        )}
                        <td className="text-left">{provider.city.name}</td>
                        <td className="text-left">{provider?.zone?.name}</td>
                        <td className="text-left">
                          {provider.stamped.expeditionPoint}{" "}
                          {provider.stamped.stamped} {provider.stamped.number}
                        </td>
                        <td className="text-left">{provider.observations}</td>
                        <td>{provider.active ? "Si" : "No"}</td>
                        {user.roleId == 1 && (
                          <td>{provider.deleted ? "Si" : "No"}</td>
                        )}
                        <td>
                          {provider.createdAt
                            .substr(0, 10)
                            .split("-")
                            .reverse()
                            .join("/")}
                        </td>
                        <td className="text-center">
                          <button
                            className="bg-transparent"
                            onClick={() => {
                              editProvider(provider.id);
                            }}
                          >
                            <FontAwesomeIcon
                              className="m-1 cursor-pointer secondary-color"
                              icon={faPen}
                            />
                          </button>
                          <button
                            className="bg-transparent"
                            onClick={() => {
                              deleteProvider(provider.id);
                            }}
                          >
                            <FontAwesomeIcon
                              className="m-1 cursor-pointer secondary-color"
                              icon={faTrash}
                            />
                          </button>
                        </td>
                      </tr>
                    );
                  } else {
                    return (
                      <tr>
                        <label className="font-bold">ID: </label>
                        {provider.id}
                        <br />
                        <label className="font-bold">NOMBRE: </label>
                        {provider.name}
                        <br />
                        <label className="font-bold">RUC: </label>
                        {provider.ruc}
                        <br />
                        <label className="font-bold">TELEFONO: </label>
                        {provider.phone}
                        <br />
                        <label className="font-bold">ACCION:</label>
                        <button
                          className="m-[1px] bg-transparent"
                          onClick={() => {
                            editProvider(provider.id);
                          }}
                        >
                          <FontAwesomeIcon
                            className="cursor-pointer secondary-color"
                            icon={faPen}
                          />
                        </button>
                        <button
                          className="m-[1px] bg-transparent"
                          onClick={() => {
                            deleteProvider(provider.id);
                          }}
                        >
                          <FontAwesomeIcon
                            className="cursor-pointer secondary-color"
                            icon={faTrash}
                          />
                        </button>
                      </tr>
                    );
                  }
                })}
              {providers.length <= 0 && !isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={15} className="text-center">
                    Sin registros (0)
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <Pagination
            className="providers-pagination-container"
            path="proveedores"
            page={page}
            pages={pages}
            total={total}
          />
        </div>
      </Layout>
    </>
  );
};

export default Providers;
