import React, { useState, Fragment, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Dialog, Transition } from "@headlessui/react";
import Layout from "../layout";
import { axiosAuth } from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser } from "../store/slices/mainSlice";
import { useLocation, useParams } from "react-router-dom";
import Pagination from "../components/pagination";
import DateComponent from "../components/date";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/animations/loading.json";
import bank from "../assets/img/bank.png";
import door from "../assets/img/door.png";
import floppy from "../assets/img/floppy.png";
import { numberWithCommas } from "../utils/constants";

const ReceivedChecks = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [action, setAction] = useState("edit");

  const [byDateFilter, setByDateFilter] = useState("issued");
  const [checkStatementFilter, setCheckStatementFilter] = useState("all");
  const [dateFromFilter, setDateFromFilter] = useState(() => {
    let MyDateString =
      (
        "0" +
        new Date(new Date().getFullYear(), new Date().getMonth(), 1).getDate()
      ).slice(-2) +
      "/" +
      (
        "0" +
        (new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          1
        ).getMonth() +
          1)
      ).slice(-2) +
      "/" +
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1
      ).getFullYear();

    return MyDateString.replaceAll(".", "")
      .replaceAll("/", "-")
      .split("-")
      .reverse()
      .join("-");
  });
  const [dateToFilter, setDateToFilter] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
      .toLocaleDateString()
      .replaceAll("/", "-")
      .split("-")
      .map((d) => (String(d).length == 1 ? "0" + String(d) : d))
      .reverse()
      .join("-")
  );

  const { page } = useParams();
  const user = useSelector((state) => state.main.user);

  const [receivedChecks, setreceivedChecks] = useState([]);
  const [checkStatements, setCheckStatements] = useState([]);

  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);

  const [receivedCheckId, setreceivedCheckId] = useState(0);
  const [number, setNumber] = useState(0);
  const [client, setClient] = useState("");
  const [issueDate, setIssueDate] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const [amount, setAmount] = useState(0);
  const [depositNumber, setDepositNumber] = useState(0);
  const [checkStatementId, setCheckStatementId] = useState(0);
  
  async function fetchReceivedChecks() {
    let pageQuery = page === undefined ? 1 : page;

    setIsFetching(true);

    let receivedChecksRequest = await axiosAuth.get("/receivedchecks?page=" + pageQuery + "&byDateFilter="+byDateFilter+"&dateFrom="+dateFromFilter+"&dateTo="+dateToFilter+"&checkStatementFilter="+checkStatementFilter);

    setIsFetching(false);
    
    if (receivedChecksRequest.data.error) {
      if (receivedChecksRequest.data.message === "token") {
        await axiosAuth.post("/logout");
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    setreceivedChecks(receivedChecksRequest.data.receivedChecks.rows);
    setTotal(receivedChecksRequest.data.receivedChecks.count);
    setPages(receivedChecksRequest.data.receivedChecks.pages);

    setCheckStatements(receivedChecksRequest.data.checkStatements);

  }

  useEffect(() => {
    fetchReceivedChecks();
  }, []);

  useEffect(() => {
    fetchReceivedChecks();
  }, [location, byDateFilter, dateFromFilter, dateToFilter, checkStatementFilter]);

  const editReceivedCheck = (receivedCheckId) => {
    setAction("edit");
    setreceivedCheckId(receivedCheckId);

    let receivedCheck = receivedChecks.filter((receivedCheck) => receivedCheck.id === receivedCheckId)[0];

    setNumber(receivedCheck.number);
    setClient(receivedCheck.payment.sale.client.name);
    setIssueDate(String(receivedCheck.issueDate).substr(0,10));
    setExpirationDate(String(receivedCheck.expirationDate).substr(0,10));
    setAmount(numberWithCommas(receivedCheck.amount));
    setDepositNumber(receivedCheck.depositNumber);
    setCheckStatementId(receivedCheck.checkStatementId);

    setOpen(true);
  };

  const clearFields = () => {
    setreceivedCheckId(0);
    setNumber(0);
    setClient("");
    setIssueDate("");
    setExpirationDate("");
    setAmount(0);
    setDepositNumber(0);
    setCheckStatementId(0);
  };

  const handleSubmit = async () => {
    if (checkStatementId == 0) {
      alert("Debes completar los campos para continuar.");
      return false;
    }

    if (action === "edit") {
      let id = receivedCheckId;
      let newreceivedChecksObj = [];

      let updatereceivedCheckRequest = await axiosAuth.put("/updatereceivedcheckstatement", {
        id,
        checkStatementId,
      });

      if (updatereceivedCheckRequest.data.error) {
        if (updatereceivedCheckRequest.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      receivedChecks.map((receivedCheck) => {
        if (receivedCheck.id == id) {
          newreceivedChecksObj.push(updatereceivedCheckRequest.data.receivedCheck);
        } else {
          newreceivedChecksObj.push(receivedCheck);
        }
        return "";
      });

      setreceivedChecks(newreceivedChecksObj);
      fetchReceivedChecks();
    } 

    setOpen(false);
  };

  return (
    <>
      <Layout>
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              clearFields();
              setOpen(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto align-items-center justify-center items-center flex">
              <div className="flex justify-center p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform sm:mt-[100px] mt-[0px] overflow-hidden bg-white text-left shadow-xl transition-all sm:my-8 w-[270px] pb-4 rounded-3xl">
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-0">
                      <div className="sm:flex sm:items-start w-full">
                        <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                          <Dialog.Title
                            as="h1"
                            className="text-center font-regular leading-6 primary-color text-3xl"
                          >
                            {action === "edit" ? "Editar" : "Crear"} cheque recibido
                          </Dialog.Title>
                          <div className="mt-5">
                            <div className="w-full">
                              <form className="bg-white rounded w-full">
                                {action === "edit" && (
                                  <div className="mb-4">
                                    <label
                                      className="block text-gray-700 text-sm font-bold"
                                      htmlFor="receivedCheckId"
                                    >
                                      Id
                                    </label>
                                    <input
                                      readOnly
                                      className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      id="receivedCheckId"
                                      type="text"
                                      value={receivedCheckId}
                                    />
                                  </div>
                                )}

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="number"
                                  >
                                    Numero
                                  </label> 
                                  <input
                                    readOnly
                                    disabled
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="number"
                                    type="number"
                                    value={number}
                                  />
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="client"
                                  >
                                    Cliente
                                  </label>
                                  <input
                                    readOnly
                                    disabled
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="client"
                                    type="text"
                                    value={client}
                                  />
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="issueDate"
                                  >
                                    Fecha de Emision
                                  </label>
                                  <input
                                    readOnly
                                    disabled
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="issueDate"
                                    type="date"
                                    value={issueDate}
                                  />
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="expirationDate"
                                  >
                                    Fecha de Vencimiento
                                  </label>
                                  <input
                                    readOnly
                                    disabled
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="expirationDate"
                                    type="date"
                                    value={expirationDate}
                                  />
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="amount"
                                  >
                                    Monto
                                  </label>
                                  <input
                                    readOnly
                                    disabled
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="amount"
                                    type="number"
                                    value={amount}
                                  />
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="depositNumber"
                                  >
                                    Numero de Deposito
                                  </label>
                                  <input
                                    readOnly
                                    disabled
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="depositNumber"
                                    type="number"
                                    value={depositNumber}
                                  />
                                </div>
                                
                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="checkStatement"
                                  >
                                    Estado de cheque
                                  </label>
                                  <select
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="checkStatement"
                                    type="number"
                                    value={checkStatementId}
                                    onChange={e => setCheckStatementId(e.target.value)}
                                  >
                                    {checkStatements.map(checkStatement => 
                                      <option value={checkStatement.id}>{checkStatement.description}</option>
                                    )}
                                  </select>
                                </div>

                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 justify-center flex sm:px-6">

                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => handleSubmit()}
                      >
                        <img src={floppy} className="w-8" />
                        GUARDAR
                      </button>
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => {
                          clearFields();
                          setOpen(false);
                        }}
                      >
                        <img src={door} className="w-5" />
                        CERRAR
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
        <div class="w-full top-[-50px] flex flex-col sm:flex-row pb-2 relative bg-white "
          style={{ borderBottom: "1px solid lightgray" }}>

          <div className="sm:w-[30%] w-[60%]">
            <h1 className="ml-5 mt-7 text-3xl text-white title secondary-color font-family-thin">
              Cheques Recibidos
            </h1>
            <DateComponent className="sm:block hidden" />
          </div>
          <div class="sm:w-[50%] w-[85%] mb-[1px] sm:ml-0 ml-6 flex flex-row items-center w-200 sm:mt-2 mt-12">
            <div className="flex flex-col justify-center items-center pt-4">
              <div className="flex flex-row justify-center items-center">
                <label>Emision</label>
                <input className="ml-1" type="checkbox" name="byDateFilter" value="issued" checked={byDateFilter == "issued"} onChange={e => setByDateFilter("issued")} />
              </div>
              <div className="flex flex-row justify-center items-center mt-1">
                <label>Vencimiento</label>
                <input className="ml-1" type="checkbox" name="byDateFilter" value="expiration" checked={byDateFilter == "expiration"} onChange={e => setByDateFilter("expiration")} />
              </div>
            </div>
            <div className="flex flex-col justify-center items-center pt-4 ml-2">
              <div className="flex flex-row justify-center items-center">
                <label>Fecha desde</label>
                <input className="ml-1" type="date" name="dateFromFilter" value={dateFromFilter} onChange={e => setDateFromFilter(e.target.value)} />
              </div>
              <div className="flex flex-row justify-center items-center mt-1">
                <label>Fecha hasta</label>
                <input className="ml-1" type="date" name="dateToFilter" value={dateToFilter} onChange={e => setDateToFilter(e.target.value)} />
              </div>
            </div>
            <div className="flex flex-row justify-center items-center ml-1 relative top-[-5px]">
              <label className="mr-1">Estado</label>
              <select name="checkStatementFilter" value={checkStatementFilter} onChange={e => setCheckStatementFilter(e.target.value)}>
                <option value="all">Todos</option>
                {checkStatements.map(checkStatement => 
                  <option style={{ color: checkStatement.colour }} value={checkStatement.id}>{checkStatement.description}</option>
                )} 
              </select>
            </div>
          </div>
        </div>
        <div className="w-[95%] p-5 mx-auto overflow-auto sm:max-h-[70vh] max-h-[40vh] mt-[-50px]">
          <table className="w-[95%] table-auto mx-auto w-full text-[10px] support-table">
            <thead>
              <tr className="bg-gray-500 h-8">
                <th className="uppercase !text-white text-left">Id</th>
                <th className="uppercase !text-white text-left">Numero</th>
                <th className="uppercase w-80 !text-white text-left">Cliente</th>
                <th className="uppercase !text-white text-left">Fecha</th>
                <th className="uppercase !text-white text-left">Vencimiento</th>
                <th className="uppercase !text-white text-left">Monto</th>
                <th className="uppercase !text-white text-left">Dep. Nro.</th>
                <th className="uppercase !text-white text-center">Accion</th>
              </tr>
            </thead>
            <tbody>
              {isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={18}>
                    <Lottie
                      animationData={loadingAnimation}
                      style={{ width: 100, height: 100, margin: "0 auto" }}
                      loop={true}
                    />
                  </td>
                </tr>
              )}
              {receivedChecks.length > 0 &&
                !isFetching &&
                receivedChecks?.map((receivedCheck) => {
                  return (
                    <tr key={receivedCheck.id} style={{ color: receivedCheck.checkstatement.colour }}>
                      <td>{receivedCheck.id}</td>
                      <td>{receivedCheck.number}</td>
                      <td className="text-left whitespace-nowrap">
                        {receivedCheck.payment.sale.client.name}
                      </td>
                      <td className="text-left whitespace-nowrap">{receivedCheck.issueDate.substr(0,10)}</td>
                      <td className="text-left whitespace-nowrap">{receivedCheck.expirationDate.substr(0,10)}</td>
                      <td className="text-left">{numberWithCommas(receivedCheck.amount)}</td>
                      <td>
                        {receivedCheck.depositNumber}
                      </td>
                      <td className="text-center">
                        <button
                          className="bg-transparent"
                          onClick={() => {
                            editReceivedCheck(receivedCheck.id);
                          }}
                        >
                          <FontAwesomeIcon
                            className="m-1 secondary-color cursor-pointer"
                            icon={faPen}
                          />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              {receivedChecks.length <= 0 && !isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={12} className="text-center">
                    Sin registros (0)
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <Pagination path="cheqrec" page={page} pages={pages} total={total} />
        </div>
      </Layout>
    </>
  );
};

export default ReceivedChecks;
