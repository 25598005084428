import React, { useState, Fragment, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { Dialog, Transition, Tab } from "@headlessui/react";
import Layout from "../layout";
import { axiosAuth } from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser } from "../store/slices/mainSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Pagination from "../components/pagination";
import DateComponent from "../components/date";
import ComboBox from "../components/combobox";
import useViewport from "../utils/useViewport";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/animations/loading.json";
import toast from "react-hot-toast";
import { numberWithCommas } from "../utils/constants";
import forbidden from "../assets/img/forbidden.png";
import check from "../assets/img/check.png";
import discard from "../assets/img/discard.png";
import door from "../assets/img/door.png";
import print from "../assets/img/printer.png";
import pdf from "../assets/img/pdf.png";
import cash from "../assets/img/cash.png";
import floppy from "../assets/img/floppy.png";

const Prices = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [contactOpen, setContactOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [potentials, setPotentials] = useState(false);
  const [canceleds, setCanceleds] = useState(true);
  const [action, setAction] = useState("edit");

  const { width } = useViewport();

  const { page } = useParams();
  const user = useSelector((state) => state.main.user);

  const [clients, setClients] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [users, setUsers] = useState([]);
  const [client, setClient] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [history, setHistory] = useState([]);

  const [prices, setPrices] = useState([]);
  const [items, setItems] = useState([]);
  const [itemsToCheckIn, setItemsToCheckIn] = useState([]);
  const [articles, setArticles] = useState([]);

  const [state, setState] = useState(0);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalClosedAmount, setTotalClosedAmount] = useState(0);

  const [stateId, setStateId] = useState(null);
  const [priceId, setPriceId] = useState(0);
  const [clientId, setClientId] = useState(0);
  const [clientIdFilter, setClientIdFilter] = useState(0);
  const [currencyId, setCurrencyId] = useState(1);
  const [sellerId, setSellerId] = useState(0);
  const [companyId, setCompanyId] = useState(user.companyId);
  const [branchId, setBranchId] = useState(user.branchId);

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const [contactId, setContactId] = useState(0);
  const [description, setDescription] = useState("");
  const [nextTask, setNextTask] = useState("");
  const [nextTaskUserId, setNextTaskUserId] = useState(0);

  const [date, setDate] = useState(
    new Date().toLocaleDateString("en-GB").split("/").reverse().join("-")
  );

  const [dateFromFilter, setDateFromFilter] = useState(() => {
    let MyDateString =
      (
        "0" +
        new Date(new Date().getFullYear(), new Date().getMonth(), 1).getDate()
      ).slice(-2) +
      "/" +
      (
        "0" +
        (new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          1
        ).getMonth() +
          1)
      ).slice(-2) +
      "/" +
      new Date(new Date().getFullYear(), new Date().getMonth(), 1)
        .getFullYear()
        

    return MyDateString.replaceAll(".", "")
      .replaceAll("/", "-")
      .split("-")
      .reverse()
      .join("-");
  });
  const [dateToFilter, setDateToFilter] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
      .toLocaleDateString()
      .replaceAll("/", "-")
      .split("-")
      .map((d) => (String(d).length == 1 ? "0" + String(d) : d))
      .reverse()
      .join("-")
  );

  const [deleted, setDeleted] = useState(0);

  async function fetchPrices() {
    let pageQuery = page === undefined ? 1 : page;
    setIsFetching(true);

    let pricesRequest = await axiosAuth.get(
      "/prices?page=" +
        pageQuery +
        "&clientId=" +
        clientIdFilter +
        "&companyId=" +
        user.companyId +
        "&sellerId=" +
        sellerId +
        "&state=" +
        stateId +
        "&dateFrom=" +
        dateFromFilter +
        "&dateTo=" +
        dateToFilter +
        "&potentials=" +
        potentials +
        "&canceleds=" +
        canceleds
    );

    setIsFetching(false);

    if (pricesRequest.data.error) {
      if (pricesRequest.data.message === "token") {
        await axiosAuth.post("/logout");
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    // pricesRequest.data.prices.clients.unshift({
    //   id: 0,
    //   name: "Elija un cliente",
    // });
    setClients(pricesRequest.data.prices.clients.rows);
    setCurrencies(pricesRequest.data.prices.currencies);
    setUsers(pricesRequest.data.users.rows);
    setArticles(pricesRequest.data.prices.items.rows);

    // let newFilteredPaymentMethods = [];
    // pricesRequest.data.prices.paymentmethods.map((paymentMethod) => {
    //   if (
    //     paymentMethod.priceConditionId ===
    //     pricesRequest.data.prices.priceconditions[0].id
    //   ) {
    //     paymentMethod.value = 0;
    //     newFilteredPaymentMethods.push(paymentMethod);
    //   }
    // });
    // setFilteredPaymentMethods(newFilteredPaymentMethods);

    setPrices(pricesRequest.data.prices.rows);
    setTotal(pricesRequest.data.prices.count);
    setPages(pricesRequest.data.prices.pages);
  }

  useEffect(() => {
    fetchPrices();
  }, []);

  useEffect(() => {
    fetchPrices();
  }, [location, clientIdFilter, sellerId, stateId, potentials, canceleds]);

  useEffect(() => {
    setClient(clients.filter((client) => client.id === clientId)[0]);
  }, [clientId]);

  useEffect(() => {
    let totalAmountSum = 0;
    let totalClosedAmountSum = 0;

    items.map((item) => {
      totalAmountSum +=
        item.qty * Number(String(item.priceAmount).replaceAll(",", ""));
      totalClosedAmountSum +=
        item.qty * Number(String(item.closedPrice).replaceAll(",", ""));
    });

    setTotalAmount(numberWithCommas(totalAmountSum));
    setTotalClosedAmount(numberWithCommas(totalClosedAmountSum));

    let isFull = true;

    items.map((item) => {
      if (item.name == "") {
        isFull = false;
      }
    });

    let newItems = [...items];

    if (isFull) {
      newItems.push({
        id: 0,
        qty: 0,
        name: "",
        priceAmount: 0,
        closedPrice: 0,
        observation: "",
        subtotal: 0,
        iva: "",
      });

      setItems(newItems);
    }
  }, [items]);

  useEffect(() => {
    if (contactId == "add") {
      setContactOpen(true);
    }
  }, [contactId]);

  const editPrice = async (priceId) => {
    setAction("edit");
    setPriceId(priceId);

    let price = prices.filter((price) => price.id === priceId)[0];

    setClientId(price.clientId);
    setState(price.state);
    setHistory(price.priceshistories);
    setSellerId(price.sellerId);

    let clientFiltered = clients.filter(
      (client) => client.id == price.clientId
    )[0];

    if (clientFiltered == undefined) {
      let clientRequest = await axiosAuth.get(
        "/searchClientById?clientId=" + price.clientId
      );
      let newClients = [...clients];
      newClients.push(clientRequest.data.client);
      setClients(newClients);
      setClient(clientRequest.data.client);
      clientRequest.data.client.contacts.push({ id: "add", name: "Crear contacto" });
      setContacts(clientRequest.data.client.contacts);
    } else {
      setClient(clientFiltered);
      clientFiltered.contacts.push({ id: "add", name: "Crear contacto" });
      setContacts(clientFiltered.contacts);
    }

    setCurrencyId(price.currencyId);

    setDate(price.createdAt.substr(0, 10));

    let newItems = [];

    let newArticles = [...articles];

    price.pricesdetails.map(async (pricedetail) => {
      let item = articles.filter((item) => item.id == pricedetail.itemId)[0];

      if (item == undefined) {
        newArticles.push(pricedetail.item);
      }
      pricedetail.closedPrice = (pricedetail?.closedPrice) ? pricedetail?.closedPrice: pricedetail?.priceAmount

      newItems.push({
        pricedetailId: pricedetail.id,
        id: pricedetail.itemId,
        qty: pricedetail.quantity,
        name: pricedetail.item.name,
        priceAmount: pricedetail?.priceAmount,
        closedPrice: pricedetail?.closedPrice,
        observation: pricedetail?.observation,
        subtotal: pricedetail?.priceAmount * pricedetail.quantity,
        iva: "10%",
      });
    });
    setArticles(newArticles);

    if (5 > price.pricesdetails.length) {
      Array(8 - price.pricesdetails.length)
        .fill(0)
        .map((_, i) => {
          newItems.push({
            id: 0,
            qty: 0,
            name: "",
            priceAmount: 0,
            closedPrice: 0,
            observation: "",
            subtotal: 0,
            iva: "",
          });
        });
    }

    setItems(prev => newItems);

    setDeleted(price.deleted);
    setOpen(true);

    setTimeout(function () {
      document.activeElement.blur();
    }, 500);
  };

  const clearFields = () => {
    setPriceId(0);
    setClientId(0);
    setCurrencyId(1);
    let newItems = [];

    Array(8)
      .fill(0)
      .map((_, i) => {
        newItems.push({
          id: 0,
          qty: 0,
          name: "",
          priceAmount: 0,
          closedPrice: 0,
          observation: "",
          subtotal: 0,
          iva: "",
        });
      });

    setItems(newItems);
    setHistory([]);
    setDeleted(0);
  };

  const handleSubmit = async () => {
    // if (action == "edit") {
    //   setOpen(false);
    //   return false;
    // }

    // if (
    //   clientId === 0 ||
    //   sellerId === 0 ||
    //   currencyId === 0 ||
    //   branchId === 0 ||
    //   companyId === 0
    // ) {
    //   alert("Debes completar los campos para continuar.");
    //   return false;
    // }

    var hasItems = false;
    var itemsToSend = [];

    items.map((item) => {
      if (item.id > 0) {
        hasItems = true;
        itemsToSend.push(item);
      }
    });

    // if (!hasItems) {
    //   alert("Debes agregar articulos para continuar.");
    //   return false;
    // }

    let updatedPriceRequest = await axiosAuth.put("/prices", {
      priceId,
      // clientId,
      // sellerId,
      // currencyId,
      // branchId,
      // companyId,
      pricesdetail: itemsToSend,
      // deleted,
    });

    if (updatedPriceRequest.data.error) {
      if (updatedPriceRequest.data.message === "token") {
        await axiosAuth.post("/logout");
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    let newPrices = [];
    prices.map(price => {
      if(price.id == priceId){
        newPrices.push(updatedPriceRequest.data);
      }else{
        newPrices.push(price);
      }
    });
    setPrices(prev => newPrices);

    toast.success("Cotizacion actualizada correctamente!");
  };

  const deletePrice = async (priceId) => {
    if (window.confirm("Desea eliminar esta venta ?")) {
      let priceDeleteRequest = await axiosAuth.delete("/prices", {
        params: { id: priceId },
      });

      if (priceDeleteRequest.data.error) {
        if (priceDeleteRequest.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      let newPricesObj = [];

      prices.map((price) => {
        if (price.id !== priceId) {
          newPricesObj.push(price);
        } else {
          if (user.roleId == 1) {
            price.deleted = 1;
            newPricesObj.push(price);
          }
        }
        return "";
      });

      setPrices(newPricesObj);
    }
  };

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };

  const searchClients = async (search) => {
    const searchClientsRequest = await axiosAuth.get(
      "/searchClients?search=" + search
    );

    setClients(searchClientsRequest.data.clients.rows);
  };

  const handleContactSubmit = async (state) => {
    
    if(clientId == 0){
      window.alert("Debe elegir un cliente para continuar");
      return false;
    }

    let createdContactRequest = await axiosAuth.post("/contacts", {
      clientId,
      name,
      phone,
      email,
      deleted,
    });

    if (createdContactRequest.data.error) {
      if (createdContactRequest.data.message === "token") {
        await axiosAuth.post("/logout");
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    let newContacts = [...contacts];
    newContacts.unshift(createdContactRequest.data);
    setContacts((prev) => newContacts);
    setContactId((prev) => createdContactRequest.data.id);

    let newClients = [...clients];

    newClients.map(c => {
      if(c.id == clientId){
        c.contacts.unshift(createdContactRequest.data);
      }
    })

    setClients(prev => newClients);

    setName("");
    setEmail("");
    setPhone("");
    setContactOpen(false);

    toast.success("Contacto creado correctamente!");
  };

  return (
    <>
      <Layout>
        

      


        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10 w-full prices-modal"
            onClose={() => {
              // clearFields();
              // setOpen(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto align-items-center justify-center items-center flex">
              <div className="flex justify-center p-4 text-center sm:p-0 w-[95%] h-[90%]">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel
                    className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-full overflow-y-auto"
                    style={{ marginTop: 0 }}
                  >
                    {state == 4 && (
                      <div
                        className="w-full h-full fixed top-[0] left-[0] flex flex-col justify-center items-center"
                        style={{
                          zIndex: 9,
                          backgroundColor: "rgba(0,0,0,0.3)",
                        }}
                      >
                        {" "}
                        <h1
                          className="font-bold rotate-[-45deg]"
                          style={{ fontSize: "70px", color: "darkred" }}
                        >
                          ANULADO
                        </h1>
                      </div>
                    )}

                    <div className="px-4 pb-4 pt-5 sm:p-6 sm:pb-0 text-center">
                      <div className="w-full">
                        <Dialog.Title
                          as="h1"
                          className="text-center font-regular leading-6 primary-color text-3xl"
                        >
                          {state == 0 ? (
                            <span class="absolute text-center right-[10] top-[10] inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-[15px] float-right font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20">
                              PENDIENTE
                            </span>
                          ) : state == 1 ? (
                            <span class="absolute text-center right-[10] top-[10] inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-[15px] float-right font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                              APROBADO
                            </span>
                          ) : state == 2 ? (
                            <span class="absolute text-center right-[10] top-[10] inline-flex items-center rounded-md bg-blue-50 px-2 py-1 !text-[15px] float-right font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
                              FACTURADO
                            </span>
                          ) : state == 3 ? (
                            <span class="absolute text-center right-[10] top-[10] inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-[15px] float-right font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
                              RECHAZADO
                            </span>
                          ) : state == 4 ? (
                            <span class="absolute text-center right-[30] inline-flex items-center rounded-md bg-slate-50 px-2 py-1 text-[15px] float-right font-medium text-slate-700 ring-1 ring-inset ring-slate-600/10">
                              ANULADO
                            </span>
                          ) : (
                            ""
                          )}
                        </Dialog.Title>
                        <div className="mt-8">
                          <div className="w-full flex md:flex-row flex-col">
                            <div className="w-full flex flex-col md:w-[60%] w-[100%]">
                              {/* {action === "edit" && (
                                  <div className="mb-4">
                                    <label
                                      className="block text-gray-700 text-sm font-bold"
                                      htmlFor="SaleId"
                                    >
                                      Id
                                    </label>
                                    <input
                                      readOnly
                                      className="input-bg-color appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      id="SaleId"
                                      type="text"
                                      value={priceId}
                                    />
                                  </div>
                                )} */}

                              {width > 550 ? (
                                <>
                                <Dialog.Title
                          as="h1"
                          className="text-center font-regular leading-6 primary-color text-3xl mb-2"
                        >Cotizacion</Dialog.Title>
                                  <div className="flex flex-row w-full">
                                    <div className="w-[50%]">
                                      <div className="mb-[1px]">
                                        <ComboBox
                                          data={clients}
                                          disabled={true}
                                          setData={setClientId}
                                          value={clientId}
                                          placeholder="Cliente"
                                          className="h-[24px] ml-[1px] w-[99.8%]"
                                        />
                                      </div>

                                      <div>
                                        <input
                                          className="input-bg-color appearance-none border w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                          id="RUC"
                                          type="text"
                                          disabled
                                          readOnly
                                          value={client?.ruc || ""}
                                          placeholder="RUC"
                                          onChange={(e) => {}}
                                        />
                                      </div>

                                      <div>
                                        <input
                                          className="input-bg-color appearance-none border w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize mt-[1px]"
                                          id="address"
                                          type="text"
                                          readOnly
                                          disabled
                                          value={client?.address || ""}
                                          placeholder="Direccion"
                                          onChange={(e) => {}}
                                        />
                                      </div>

                                      <div>
                                        <a
                                          target="_blank"
                                          href={
                                            client?.phone?.replaceAll(
                                              " ",
                                              ""
                                            ) != "" &&
                                            client?.phone?.replaceAll(
                                              " ",
                                              ""
                                            ) != " "
                                              ? "https://wa.me/" +
                                                "+595" +
                                                client?.phone
                                                  ?.replaceAll(" ", "")
                                                  .replace("+", "")
                                                  .replace("595", "")
                                              : "/#"
                                          }
                                        >
                                          <input
                                            className="input-bg-color appearance-none border w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize mt-[1px]"
                                            id="phone"
                                            type="text"
                                            readOnly
                                            value={
                                              client?.phone?.replaceAll(
                                                " ",
                                                ""
                                              ) || ""
                                            }
                                            placeholder="Telefono"
                                            onChange={(e) => {}}
                                          />
                                        </a>
                                      </div>
                                    </div>

                                    <div className="w-[50%] ml-[2px]">
                                      <div className="flex flex-row">
                                        <select
                                          className="input-bg-color appearance-none border w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[24px]"
                                          id="branch"
                                          readOnly
                                          disabled
                                          value={branchId}
                                          onChange={(e) => {
                                            setBranchId(e.target.value);
                                          }}
                                        >
                                          <option value={0}>Sucursal</option>
                                          <option value={user.branch.id}>
                                            Sucursal: {user.branch.description}
                                          </option>
                                        </select>
                                      </div>

                                      <div>
                                        <select
                                          className="input-bg-color appearance-none border w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize mt-[1px]"
                                          id="currency"
                                          readOnly
                                          disabled
                                          value={currencyId}
                                          onChange={(e) => {
                                            setCurrencyId(e.target.value);
                                          }}
                                        >
                                          <option value={0}>Moneda</option>
                                          {currencies.map((currency, i) => (
                                            <option value={currency.id} key={i}>
                                              Moneda: {currency.name}
                                            </option>
                                          ))}
                                        </select>
                                      </div>

                                      <div>
                                        <input
                                          className="input-bg-color appearance-none border w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mt-[1px] h-[20px]"
                                          id="date"
                                          readOnly
                                          disabled
                                          value={date}
                                          type="date"
                                          placeholder="Fecha"
                                          onChange={(e) => {
                                            setDate(e.target.value);
                                          }}
                                        />
                                      </div>
                                      <div className="mb-[1px]">
                                        <select
                                          className="h-[20px] input-bg-color appearance-none border w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize mt-[1px]"
                                          id="sellerId"
                                          disabled
                                          readOnly
                                          value={sellerId}
                                          onChange={(e) => {}}
                                        >
                                          <option value={0}>Vendedor</option>
                                          {users.map(usr => (
                                            <option value={usr.id}>{usr.fullname}</option>  
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <Tab.Group>
                                    {/* <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1 mb-[2px]"> */}
                                      {/* <Tab
                                        className={({ selected }) =>
                                          classNames(
                                            "w-full rounded-lg py-1.5 text-sm leading-5 primary-color text-xs",
                                            "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                                            clientId == 0
                                              ? "bg-red-300"
                                              : "bg-green-200",
                                            selected
                                              ? "bg-white font-bold"
                                              : "primary-color hover:text-slate-500"
                                          )
                                        }
                                      >
                                        Cliente
                                      </Tab>
                                      <Tab
                                        className={({ selected }) =>
                                          classNames(
                                            "w-full rounded-lg py-1.5 text-sm leading-5 primary-color text-xs",
                                            "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                                            branchId == 0 ||
                                              currencyId == 0 ||
                                              date == "" ||
                                              sellerId == 0
                                              ? "bg-red-300"
                                              : "bg-green-200",
                                            selected
                                              ? "bg-white font-bold"
                                              : "primary-color hover:text-slate-500"
                                          )
                                        }
                                      >
                                        Datos
                                      </Tab> */}
                                    {/* </Tab.List> */}
                                    <Tab.Panels>
                                      <Tab.Panel>
                                        <Transition.Child
                                        as="div"
                                          enter="ease-out duration-500"
                                          enterFrom="opacity-0"
                                          enterTo="opacity-100"
                                          leave="ease-in duration-300"
                                          leaveFrom="opacity-100"
                                          leaveTo="opacity-0"
                                        >
                                          <div className="mb-[2px]">
                                            <ComboBox
                                              data={clients}
                                              disabled={true}
                                              setData={setClientId}
                                              value={clientId}
                                              placeholder="Cliente"
                                              // className="h-[24px]"
                                            />
                                          </div>

                                          <div className="mb-[2px]">
                                            <input
                                              className="input-bg-color appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                              id="RUC"
                                              type="text"
                                              disabled
                                              readOnly
                                              value={client?.ruc || ""}
                                              placeholder="RUC"
                                              onChange={(e) => {}}
                                            />
                                          </div>

                                          {/* <div className="mb-[2px]">
                                            <input
                                              className="input-bg-color appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                              id="address"
                                              type="text"
                                              readOnly
                                              disabled
                                              value={client?.address || ""}
                                              placeholder="Direccion"
                                              onChange={(e) => {}}
                                            />
                                          </div> */}

                                          {/* <div>
                                            <a
                                              target="_blank"
                                              href={
                                                client?.phone?.replaceAll(
                                                  " ",
                                                  ""
                                                ) != "" &&
                                                client?.phone?.replaceAll(
                                                  " ",
                                                  ""
                                                ) != " "
                                                  ? "https://wa.me/" +
                                                    "+595" +
                                                    client?.phone
                                                      ?.replaceAll(" ", "")
                                                      .replace("+", "")
                                                      .replace("595", "")
                                                  : "/#"
                                              }
                                            >
                                              <input
                                                className="input-bg-color appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                                id="phone"
                                                type="text"
                                                readOnly
                                                value={
                                                  client?.phone?.replaceAll(
                                                    " ",
                                                    ""
                                                  ) || ""
                                                }
                                                placeholder="Telefono"
                                                onChange={(e) => {}}
                                              />
                                            </a>
                                          </div> */}
                                        </Transition.Child>
                                      </Tab.Panel>
                                      <Tab.Panel>
                                        <Transition.Child
                                        as="div"
                                          enter="ease-out duration-500"
                                          enterFrom="opacity-0"
                                          enterTo="opacity-100"
                                          leave="ease-in duration-300"
                                          leaveFrom="opacity-100"
                                          leaveTo="opacity-0"
                                        >
                                          <div className="mb-[2px]">
                                            <select
                                              className="input-bg-color appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                              id="branch"
                                              readOnly
                                              disabled
                                              value={branchId}
                                              onChange={(e) => {
                                                setBranchId(e.target.value);
                                              }}
                                            >
                                              <option value={0}>
                                                Sucursal
                                              </option>
                                              <option value={user.branch.id}>
                                                {user.branch.description}
                                              </option>
                                            </select>
                                          </div>

                                          <div className="mb-[2px]">
                                            <select
                                              className="input-bg-color appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                              id="branch"
                                              readOnly
                                              disabled
                                              value={currencyId}
                                              onChange={(e) => {
                                                setCurrencyId(e.target.value);
                                              }}
                                            >
                                              <option value={0}>Moneda</option>
                                              {currencies.map((currency, i) => (
                                                <option
                                                  value={currency.id}
                                                  key={i}
                                                >
                                                  {currency.name}
                                                </option>
                                              ))}
                                            </select>
                                          </div>

                                          <div className="mb-[2px]">
                                            <input
                                              className="input-bg-color appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                              id="date"
                                              readOnly
                                              disabled
                                              value={date}
                                              type="date"
                                              placeholder="Fecha"
                                              onChange={(e) => {
                                                setDate(e.target.value);
                                              }}
                                            />
                                          </div>
                                          <div>
                                            <select
                                              className="input-bg-color appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                              id="sellerId"
                                              disabled
                                              readOnly
                                              value={sellerId}
                                              onChange={(e) => {}}
                                            >
                                              <option value={0}>
                                                Vendedor
                                              </option>
                                              {users.map(usr => (
                                                <option value={usr.id}>{usr.fullname}</option>
                                              ))}
                                            </select>
                                          </div>
                                        </Transition.Child>
                                      </Tab.Panel>
                                    </Tab.Panels>
                                  </Tab.Group>
                                </>
                              )}

                              <div className="w-full flex flex-row mt-[1px] pt-2">
                                <div className="w-[100%] overflow-x-hidden overflow-y-auto">
                                  <div className="mb-[2px]">
                                    <div className="prices-table w-full h-[36vh] overflow-auto">
                                      <div className="text-gray">
                                        {width > 620 ? (
                                          <div
                                            className="tr flex bg-gray-600 h-[22px] items-center justify-center"
                                          >
                                            <div className="w-[3%] font-bold text-sm !text-white">
                                              ID.
                                            </div>
                                            <div className="w-[38%] font-bold text-sm !text-white">
                                              PRODUCTO
                                            </div>
                                            {/* <div className="w-[16%] font-bold text-sm primary-color">
                                              DESCRIPCION
                                            </div> */}
                                            <div className="w-[10%] font-bold text-sm !text-white">
                                              CANT.
                                            </div>
                                            <div className="w-[15%] font-bold text-sm !text-white">
                                              PRECIO
                                            </div>
                                            <div className="w-[14%] font-bold text-sm !text-white">
                                              TOTAL
                                            </div>
                                            <div className="w-[13%] font-bold text-sm !text-white">
                                              PR. CERRADO
                                            </div>
                                            <div className="w-[3%] font-bold text-sm !text-white">
                                              FACT.
                                            </div>
                                            {/* <div className="w-[9%] font-bold text-xs primary-color">
                                              IVA
                                            </div> */}
                                          </div>
                                        ) : (
                                          // <div className="tr flex">
                                          //   <div className="w-[15%] font-bold text-sm primary-color">
                                          //     ID.
                                          //   </div>
                                          //   <div className="w-[75%] font-bold text-sm primary-color">
                                          //     PRODUCTO
                                          //   </div>
                                          //   <div className="w-[15%] font-bold text-sm primary-color">
                                          //     CANT
                                          //   </div>
                                          // </div>
                                          <></>
                                        )}
                                      </div>
                                      <div className="overflow-y-hidden overflow-x-hidden bg-gray-200">
                                        {width > 620
                                          ? items.map((item, i) => (
                                              <div className="tr flex flex-col" style={{ borderBottom: "1px solid gray" }}>

                                                <div className="flex flex-col">
                                                  <div className="flex flex-row w-full">
                                                <div className="td w-[6%]">
                                                  <input
                                                    className="input-bg-color h-[26px] py-1 px-3 w-full text-center"
                                                    type="text"
                                                    readOnly
                                                    disabled
                                                    name="code"
                                                    value={item.id}
                                                  />
                                                </div>

                                                <div className="td w-[40%] flex flex-col">
                                                  <ComboBox
                                                    data={articles}
                                                    value={item.id}
                                                    setData={setItems}
                                                    items={items}
                                                    index={i}
                                                    className="h-[26px] border-none"
                                                    disabled={true}
                                                  />
                                                </div>
                                                {/* <div className="td w-[15%] m-[1px]">
                                                  
                                                </div> */}
                                                <div className="td w-[7%]">
                                                  <input
                                                    className="input-bg-color py-1 h-[26px] px-3 w-full text-center"
                                                    type="text"
                                                    name="qty"
                                                    readOnly
                                                    disabled
                                                    value={item.qty}
                                                    onChange={(e) => {
                                                      let newItems = [...items];
                                                      newItems.map(
                                                        (item, it) => {
                                                          if (i == it) {
                                                            item.qty =
                                                              e.target.value;
                                                            item.subtotal =
                                                              String(
                                                                item.priceAmount
                                                              ).replaceAll(
                                                                ",",
                                                                ""
                                                              ) * item.qty;
                                                            item.subtotal =
                                                              numberWithCommas(
                                                                item.subtotal
                                                              );
                                                          }
                                                        }
                                                      );
                                                      setItems(newItems);
                                                    }}
                                                  />
                                                </div>
                                                <div className="td w-[15%]">
                                                  <input
                                                    className="input-bg-color h-[26px] py-1 px-3 w-full text-right"
                                                    type="text"
                                                    name="price"
                                                    value={numberWithCommas(
                                                      item.priceAmount
                                                    )}
                                                    readOnly
                                                    disabled
                                                    placeholder="Precio"
                                                    onChange={(e) => {
                                                      let newItems = [...items];
                                                      newItems.map(
                                                        (item, it) => {
                                                          if (i == it) {
                                                            item.priceAmount =
                                                              String(
                                                                e.target.value
                                                              ).replaceAll(
                                                                ",",
                                                                ""
                                                              );
                                                            item.subtotal =
                                                              String(
                                                                item.priceAmount
                                                              ).replaceAll(
                                                                ",",
                                                                ""
                                                              ) * item.qty;
                                                            item.subtotal =
                                                              numberWithCommas(
                                                                item.subtotal
                                                              );
                                                          }
                                                        }
                                                      );
                                                      setItems(newItems);
                                                    }}
                                                  />
                                                </div>
                                                <div className="td w-[15%]">
                                                  <input
                                                    className="input-bg-color h-[26px] py-1 px-3 w-full text-right"
                                                    type="text"
                                                    readOnly
                                                    disabled
                                                    name="subtotal"
                                                    value={numberWithCommas(
                                                      item.subtotal
                                                    )}
                                                  />
                                                </div>
                                                <div className="td w-[13%]">
                                                  <input
                                                    className="input-bg-color py-1 px-3 w-full text-right h-[28px]"
                                                    type="text"
                                                    name="closedPrice"
                                                    value={numberWithCommas(
                                                      item.closedPrice
                                                    )}
                                                    onChange={async (e) => {
                                                      let newItems = [...items];
                                                      newItems.map(
                                                        (newItem, idx) => {
                                                          if (idx == i) {
                                                            newItem.closedPrice =
                                                              e.target.value;
                                                          }
                                                        }
                                                      );
                                                      setItems(newItems);

                                                      if (item.id > 0) {
                                                        await axiosAuth.put(
                                                          "/updatePriceDetailClosedPrice",
                                                          {
                                                            priceId,
                                                            itemId: item.id,
                                                            closedPrice:
                                                              e.target.value,
                                                          }
                                                        );
                                                        fetchPrices();
                                                      }
                                                    }}
                                                  />
                                                </div>
                                                <div className="td w-[3%] m-[1px]">
                                                  <input
                                                    className="input-bg-color py-1 px-3 w-full text-right h-[26px]"
                                                    type="checkbox"
                                                    name="itemsToCheckIn"
                                                    value={item.id}
                                                    onChange={async (e) => {
                                                      let newItemsToCheckIn = [
                                                        ...itemsToCheckIn,
                                                      ];

                                                      if (
                                                        newItemsToCheckIn.length >
                                                        0
                                                      ) {
                                                        let exists = false;

                                                        newItemsToCheckIn.map(
                                                          (ob) => {
                                                            if (
                                                              ob.id ==
                                                              e.target.value
                                                            ) {
                                                              exists = true;
                                                            }
                                                          }
                                                        );

                                                        if (exists) {
                                                          newItemsToCheckIn =
                                                            newItemsToCheckIn.filter(
                                                              (obj) =>
                                                                obj.id !=
                                                                e.target.value
                                                            );
                                                        } else {
                                                          item.closedPrice =
                                                            String(
                                                              item.closedPrice
                                                            ).replaceAll(
                                                              ",",
                                                              ""
                                                            );
                                                          newItemsToCheckIn.push(
                                                            item
                                                          );
                                                        }
                                                      } else {
                                                        item.closedPrice =
                                                          String(
                                                            item.closedPrice
                                                          ).replaceAll(",", "");
                                                        newItemsToCheckIn.push(
                                                          item
                                                        );
                                                      }

                                                      setItemsToCheckIn(
                                                        newItemsToCheckIn
                                                      );
                                                    }}
                                                  />
                                                </div>
                                                {/* <div className="td w-[7%] m-[1px]">
                                                  <input
                                                    className="input-bg-color py-1 px-3 w-full text-center"
                                                    disabled
                                                    readOnly
                                                    type="text"
                                                    name="iva"
                                                    value={item.iva}
                                                  />
                                                </div> */}
                                              </div>
                                              </div>
                                              <div className="w-full">
                                              {item.observation &&
                                                  <textarea
                                                    className="h-[64px] py-1 px-3 w-full text-left"
                                                    type="text"
                                                    name="observation"
                                                    value={item.observation}
                                                    onChange={(e) => {
                                                      let newItems = [...items];
                                                      newItems.map(
                                                        (item, it) => {
                                                          if (i == it) {
                                                            item.observation =
                                                              e.target.value;
                                                          }
                                                        }
                                                      );
                                                      setItems(newItems);
                                                    }}
                                                  ></textarea>
                                                  }
                                              </div>
                                              </div>
                                            ))
                                          : items.map((item, i) => (
                                              <div
                                                className="tr flex flex-col m-1 pb-1"
                                                style={{
                                                  borderBottom:
                                                    "1px solid rgba(0,0,0,0.5)",
                                                }}
                                              >
                                                <div className="w-full flex flex-row">
                                                  {/* <div className="td w-[20%] m-[1px]">
                                                    <input
                                                      className="input-bg-color py-1 px-3 w-full text-center"
                                                      type="text"
                                                      readOnly
                                                      disabled
                                                      name="code"
                                                      value={item.id}
                                                    />
                                                  </div> */}

                                                  <div className="td w-[100%] m-[1px]">
                                                    <ComboBox
                                                      data={articles}
                                                      value={item.id}
                                                      setData={setItems}
                                                      items={items}
                                                      index={i}
                                                      disabled={true}
                                                      className="h-[27px]"
                                                    />
                                                  </div>
                                                </div>

                                                <div className="td w-full m-[1px]">
                                                  <textarea
                                                    className="input-bg-color py-1 px-3 h-[72px] w-full text-left"
                                                    type="text"
                                                    name="observation"
                                                    readOnly
                                                    disabled
                                                    value={item.observation}
                                                    placeholder="Observacion"
                                                    onChange={(e) => {
                                                      let newItems = [...items];
                                                      newItems.map(
                                                        (item, it) => {
                                                          if (i == it) {
                                                            item.observation =
                                                              e.target.value;
                                                          }
                                                        }
                                                      );
                                                      setItems(newItems);
                                                    }}
                                                  ></textarea>
                                                </div>

                                                <div className="w-full flex flex-row">
                                                <div className="td w-[33%] m-[1px]">
                                                    <input
                                                      className="input-bg-color py-1 px-3 w-full text-center"
                                                      type="text"
                                                      name="qty"
                                                      readOnly
                                                      disabled
                                                      value={item.qty}
                                                      onChange={(e) => {
                                                        let newItems = [
                                                          ...items,
                                                        ];
                                                        newItems.map(
                                                          (item, it) => {
                                                            if (i == it) {
                                                              item.qty =
                                                                e.target.value;
                                                              item.subtotal =
                                                                String(
                                                                  item.priceAmount
                                                                ).replaceAll(
                                                                  ",",
                                                                  ""
                                                                ) * item.qty;
                                                              item.subtotal =
                                                                numberWithCommas(
                                                                  item.subtotal
                                                                );
                                                            }
                                                          }
                                                        );
                                                        setItems(newItems);
                                                      }}
                                                    />
                                                  </div>

                                                  <div className="td w-[33%] m-[1px]">
                                                    <input
                                                      className="input-bg-color py-1 px-3 w-full text-center"
                                                      type="text"
                                                      name="price"
                                                      readOnly
                                                      disabled
                                                      value={numberWithCommas(
                                                        item.priceAmount
                                                      )}
                                                      placeholder="Precio"
                                                      onChange={(e) => {
                                                        let newItems = [
                                                          ...items,
                                                        ];
                                                        newItems.map(
                                                          (item, it) => {
                                                            if (i == it) {
                                                              item.priceAmount =
                                                                String(
                                                                  e.target.value
                                                                ).replaceAll(
                                                                  ",",
                                                                  ""
                                                                );
                                                              item.subtotal =
                                                                String(
                                                                  item.price
                                                                ).replaceAll(
                                                                  ",",
                                                                  ""
                                                                ) * item.qty;
                                                              item.subtotal =
                                                                numberWithCommas(
                                                                  item.subtotal
                                                                );
                                                            }
                                                          }
                                                        );
                                                        setItems(newItems);
                                                      }}
                                                    />
                                                  </div>
                                                 
                                                  <div className="td w-[33%] m-[1px]">
                                                    <input
                                                      className="input-bg-color py-1 px-3 w-full text-right"
                                                      type="text"
                                                      readOnly
                                                      disabled
                                                      name="subtotal"
                                                      value={numberWithCommas(
                                                        item.subtotal
                                                      )}
                                                    />
                                                  </div>
                                                  {/* <div className="td w-[20%] m-[1px]">
                                                    <input
                                                      className="input-bg-color py-1 px-3 w-full text-center"
                                                      readOnly
                                                      disabled
                                                      type="text"
                                                      name="iva"
                                                      value={item.iva}
                                                    />
                                                  </div> */}
                                                </div>
                                                <div className="w-full flex flex-row justify-end">
                                                  
                                                  
                                                  <div className="w-[50%]">
                                                  <input
                                                    className="input-bg-color py-1 px-3 w-full ml-5 text-right h-[26px] mt-2"
                                                    type="text"
                                                    name="closedPrice"
                                                    value={numberWithCommas(
                                                      item.closedPrice
                                                    )}
                                                    onChange={async (e) => {
                                                      let newItems = [...items];
                                                      newItems.map(
                                                        (newItem, idx) => {
                                                          if (idx == i) {
                                                            newItem.closedPrice =
                                                              e.target.value;
                                                          }
                                                        }
                                                      );
                                                      setItems(newItems);

                                                      if (item.id > 0) {
                                                        await axiosAuth.put(
                                                          "/updatePriceDetailClosedPrice",
                                                          {
                                                            priceId,
                                                            itemId: item.id,
                                                            closedPrice:
                                                              e.target.value,
                                                          }
                                                        );
                                                        fetchPrices();
                                                      }
                                                    }}
                                                  />
                                                  </div>
                                                  <div className="w-[50%]">
                                                  <input
                                                    className="input-bg-color py-1 px-3 m-2 w-full text-right h-[26px]"
                                                    type="checkbox"
                                                    name="itemsToCheckIn"
                                                    value={item.id}
                                                    onChange={async (e) => {
                                                      let newItemsToCheckIn = [
                                                        ...itemsToCheckIn,
                                                      ];

                                                      if (
                                                        newItemsToCheckIn.length >
                                                        0
                                                      ) {
                                                        let exists = false;

                                                        newItemsToCheckIn.map(
                                                          (ob) => {
                                                            if (
                                                              ob.id ==
                                                              e.target.value
                                                            ) {
                                                              exists = true;
                                                            }
                                                          }
                                                        );

                                                        if (exists) {
                                                          newItemsToCheckIn =
                                                            newItemsToCheckIn.filter(
                                                              (obj) =>
                                                                obj.id !=
                                                                e.target.value
                                                            );
                                                        } else {
                                                          item.closedPrice =
                                                            String(
                                                              item.closedPrice
                                                            ).replaceAll(
                                                              ",",
                                                              ""
                                                            );
                                                          newItemsToCheckIn.push(
                                                            item
                                                          );
                                                        }
                                                      } else {
                                                        item.closedPrice =
                                                          String(
                                                            item.closedPrice
                                                          ).replaceAll(",", "");
                                                        newItemsToCheckIn.push(
                                                          item
                                                        );
                                                      }

                                                      setItemsToCheckIn(
                                                        newItemsToCheckIn
                                                      );
                                                    }}
                                                  />
                                                  </div>
                                                </div>
                                              </div>
                                            ))}
                                      </div>
                                      
                                    </div>
                                  </div>
                                  
                                </div>
                              </div>
                              <div className="tr flex flex-row justify-end items-center m-[2px]">
                                        <div className="td text-right font-bold mr-1 relative sm:left-[-15px]">
                                          TOTALES
                                        </div>
                                        <div className="td w-[40%] sm:w-[13%] relative sm:left-[-12px] z-9">
                                          <input
                                            className="py-1 w-full text-right font-bold"
                                            style={{
                                              backgroundColor: "white",
                                              color: "black",
                                              borderBottom: "1px solid black",
                                            }}
                                            type="text"
                                            readOnly
                                            disabled
                                            name="total"
                                            value={totalAmount}
                                            placeholder="Total"
                                          />
                                        </div>
                                        <div className="td w-[40%] sm:w-[11%] relative sm:left-[-12px]">
                                          <input
                                            className="py-1 px-3 w-full text-right font-bold"
                                            style={{
                                              backgroundColor: "white",
                                              color: "black",
                                              borderBottom: "1px solid black",
                                            }}
                                            type="text"
                                            readOnly
                                            disabled
                                            name="total"
                                            value={totalClosedAmount}
                                            placeholder="Total"
                                          />
                                        </div>
                                        <div className="td w-[2.2%]"></div>
                                      </div>
                            </div>
                            <div className="flex flex-col md:w-[40%] w-[100%] min-w-[310px] pl-3">
                            <Dialog.Title
                          as="h1"
                          className="text-center font-regular leading-6 primary-color text-3xl mb-3 sm:mt-0 mt-5"
                        >Seguimiento</Dialog.Title>
                              <div className="sm:h-[350px] h-[80px] overflow-auto">
                              <table>
                                <thead>
                                  {width > 620 && <tr className="bg-gray-600 h-[23px]">
                                    <th className="!text-white w-[50%]">Descripcion</th>
                                    <th className="!text-white">Prox. Tarea</th>
                                    <th className="!text-white">Usuario (Prox. T.)</th>
                                  </tr>}
                                </thead>
                                <tbody>
                                  {history.map((hist) => {
                                    if(width > 620){
                                      return <tr style={{ borderBottom: "1px solid lightgray" }}>
                                      <td className="text-left"><b>{hist.contact.name} ({hist.contact.phone}):</b> {hist.description} <b>({hist?.registeredByUser?.fullname} {hist.createdAt})</b></td>
                                      <td className="text-left">{hist.nextTask}</td>
                                      <td className="text-left">{hist.nextTaskUser.fullname}</td>
                                    </tr>; 
                                  }else {
                                    return <tr style={{ borderBottom: "1px solid lightgray" }}><td colSpan={3}>
                                      <b>{hist.contact.name} ({hist.contact.phone}):</b> {hist.description} <b>({hist?.registeredByUser?.fullname} {hist.createdAt})</b>
                                      {hist.nextTask}
                                      {hist.nextTaskUser.fullname}</td>
                                    </tr> }
                                  })}
                                </tbody>
                              </table>
                              </div>

                              <div className="flex flex-col justify-center m-1 pt-2 relative top-[-10px]" style={{ borderTop: "1px solid gray" }}>
                                <div className="flex flex-row">
                                <ComboBox
                                  data={contacts}
                                  setData={setContactId}
                                  value={contactId}
                                  placeholder="CONTACTO"
                                  className="h-[25px] w-[33%]"
                                />

                                <input
                                  className="w-[33%%] h-[25px] border mr-[1px] px-2 text-[11px]"
                                  name="nextTask"
                                  placeholder="PROX. TAREA"
                                  onChange={(e) => setNextTask(e.target.value)}
                                  value={nextTask}
                                  type="text"
                                />

                                <ComboBox
                                  data={users}
                                  setData={setNextTaskUserId}
                                  value={nextTaskUserId}
                                  placeholder="USUARIO"
                                  className="h-[25px] w-[33%] ml-[1px]"
                                />

                                </div>

                                <div className="flex flex-row">

                                <textarea
                                  className="w-[95.5%] relative left-[-3px] h-[64px] m-[1px] px-2 text-[11px]"
                                  name="description"
                                  placeholder="DESCRIPCION"
                                  onChange={(e) =>
                                    setDescription(e.target.value)
                                  }
                                  value={description}
                                ></textarea>

                                <button
                                  onClick={async () => {
                                    if (
                                      contactId == 0 ||
                                      nextTaskUserId == 0 ||
                                      description == ""
                                    ) {
                                      alert(
                                        "Debe completar los campos para guardar la actividad"
                                      );
                                      return false;
                                    }

                                    let createPriceHistoryRequest =
                                      await axiosAuth.post("/priceshistory", {
                                        priceId,
                                        contactId,
                                        description,
                                        nextTask,
                                        nextTaskUserId,
                                        registeredByUserId: user.id
                                      });

                                    let newHistory = [...history];
                                    newHistory.unshift(
                                      createPriceHistoryRequest.data
                                    );
                                    setHistory(newHistory);

                                    let newPrices = [...prices];
                                    prices.map((price) => {
                                      if (price.id == priceId) {
                                        price.priceshistories = newHistory;
                                      }
                                    });
                                    setPrices(newPrices);

                                    setTimeout(function () {
                                      setContactId(0);
                                      setDescription("");
                                      setNextTask("");
                                      setNextTaskUserId(0);
                                    }, 1000);
                                  }}
                                  className="p-1 mx-auto !bg-transparent"
                                >
                                  <img src={floppy} className="w-12 relative top-[-15px]" />
                                </button>

                                </div>
                                
                                
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="w-full sm:w-[50%] justify-left flex overflow-x-auto relative top-0 sm:top-[-60px]">
                        <button
                          type="button"
                          className="inline-flex pl-7 pr-7 sm:pl-0 sm:pr-0 flex-col w-[140px] sm:w-[70px] mr-1 justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                          onClick={() => {
                            window.open("/cotizacionpdf/" + priceId);
                          }}
                        >
                          <img src={pdf} className="w-6" />
                          PDF
                        </button>
                        <button
                          type="button"
                          className="inline-flex flex-col w-[70px] mr-1 justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                          onClick={() => {
                            window.open("/imprimircotizacion/" + priceId);
                          }}
                        >
                          <img src={print} className="w-6" />
                          IMPRIMIR
                        </button>
                        <button
                          type="button"
                          className="inline-flex flex-col w-[70px] mr-1 justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                          onClick={async () => {
                            await axiosAuth.put("/priceState", {
                              priceId,
                              state: 4,
                            });
                            let newPrices = [...prices];
                            newPrices.map((newPrice) => {
                              if (priceId == newPrice.id) {
                                newPrice.state = 4;
                              }
                            });
                            setPrices(newPrices);
                            editPrice(priceId);
                          }}
                        >
                          <img src={discard} className="w-6" />
                          ANULAR
                        </button>

                        <button
                          type="button"
                          className="inline-flex flex-col w-[70px] mr-1 justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                          onClick={async () => {
                            await axiosAuth.put("/priceState", {
                              priceId,
                              state: 1,
                            });
                            let newPrices = [...prices];
                            newPrices.map((newPrice) => {
                              if (priceId == newPrice.id) {
                                newPrice.state = 1;
                              }
                            });
                            setPrices(newPrices);
                            editPrice(priceId);
                          }}
                        >
                          <img src={check} className="w-6" />
                          APROBAR
                        </button>
                        <button
                          type="button"
                          className="inline-flex flex-col w-[70px] mr-1 justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                          onClick={async () => {
                            await axiosAuth.put("/priceState", {
                              priceId,
                              state: 3,
                            });
                            let newPrices = [...prices];
                            newPrices.map((newPrice) => {
                              if (priceId == newPrice.id) {
                                newPrice.state = 3;
                              }
                            });
                            setPrices(newPrices);
                            editPrice(priceId);
                          }}
                        >
                          <img src={forbidden} className="w-6" />
                          RECHAZAR
                        </button>

                        <button
                          type="button"
                          className="inline-flex flex-col w-[70px] mr-1 justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                          onClick={async () => {
                            if (itemsToCheckIn.length <= 0) {
                              alert(
                                "Debes seleccionar al menos un articulo para facturar."
                              );
                              return false;
                            }
                            navigate("/facturacion", {
                              state: { priceId, clientId, itemsToCheckIn },
                            });
                          }}
                        >
                          <img src={cash} className="w-6" />
                          FACTURAR
                        </button>

                        <button
                          type="button"
                          className="inline-flex flex-col w-[70px] mr-1 justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                          onClick={async () => {
                            await axiosAuth.put("/priceState", {
                              priceId,
                              state: 2,
                            });
                            let newPrices = [...prices];
                            newPrices.map((newPrice) => {
                              if (priceId == newPrice.id) {
                                newPrice.state = 2;
                              }
                            });
                            setPrices(newPrices);
                            editPrice(priceId);
                          }}
                        >
                          <img src={cash} className="w-6" />
                          FACTURADO
                        </button>

                        <button
                          type="button"
                          className="inline-flex flex-col w-[60px] h-[63px] justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 sm:w-auto mr-1"
                          onClick={() => {
                            handleSubmit();
                          }}
                        >
                          <img src={floppy} className="w-6" />
                          GUARDAR
                        </button>

                        <button
                          type="button"
                          className="inline-flex flex-col w-[60px] h-[63px] justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 sm:w-auto"
                          onClick={() => {
                            clearFields();
                            setOpen(false);
                          }}
                        >
                          <img src={door} className="w-4" />
                          CERRAR
                        </button>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
            <Transition.Root show={contactOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              clearFields();
              setOpen(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto align-items-center justify-center items-center flex">
              <div className="flex justify-center p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel
                    className="relative transform overflow-hidden rounded-3xl bg-white text-left shadow-xl transition-all sm:my-8 w-[280px]"
                    style={{ marginTop: 45 }}
                  >
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-0">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                          <Dialog.Title
                            as="h1"
                            className="text-center font-regular leading-6 primary-color text-3xl"
                          >
                            Crear Contacto
                          </Dialog.Title>
                          <div className="mt-5">
                            <div className="w-full">
                              <form className="bg-white rounded">
                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="name"
                                  >
                                    Nombre
                                  </label>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                    id="name"
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                  />
                                </div>
                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="name"
                                  >
                                    Telefono
                                  </label>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                    id="name"
                                    type="text"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                  />
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="name"
                                  >
                                    Correo
                                  </label>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="name"
                                    type="text"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                  />
                                </div>

                                
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 justify-center flex sm:px-6">
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => {
                          clearFields();
                          setContactOpen(false);
                        }}
                      >
                        <img alt="" src={door} className="w-5" />
                        CERRAR
                      </button>
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => handleContactSubmit()}
                      >
                        <img src={floppy} className="w-8" />
                        GUARDAR
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

          </Dialog>
        </Transition.Root>

        <div
          className="flex flex-row justify-end pb-2 w-full relative top-[-2px]"
          style={{ borderBottom: "1px solid lightgray" }}
        >
          <div className="sm:w-[30%] w-[5%]">
            <h1
              id="titlebig"
              className="ml-5 mt-5 text-3xl title font-family-thin secondary-color"
            >
              Cotizaciones
            </h1>
            <DateComponent className="sm:block hidden" />{" "}
          </div>
          <div className="sm:w-[50%] w-full flex flex-col justify-end items-end pl-12 pr-6 pt-2">
            <div className="w-full flex flex-row justify-end">
              <ComboBox
                data={clients}
                setData={setClientIdFilter}
                value={clientIdFilter}
                placeholder="Cliente"
                className="h-[27px] w-[100%]"
                search={searchClients}
              />
            </div>
            <div className="w-full flex flex-row justify-end">
              <ComboBox
                data={users}
                setData={setSellerId}
                value={sellerId}
                placeholder="Vendedor"
                className="h-[26px] mt-[1px] w-[50%]"
                dataToDisplay="sellers"
              />
              <select
                id="stateFilter"
                name="stateFilter"
                value={stateId}
                className="input-bg-color mt-[1px] appearance-none border
              px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-sm h-[26px] w-[40%] sm:w-[50%]"
                style={{ lineHeight: "13px" }}
                onChange={(e) => setStateId(e.target.value)}
              >
                <option value={null}>Seleccione un estado</option>
                <option value={0}>Pendiente</option>
                <option value={1}>Aprobado</option>
                <option value={2}>Facturado</option>
                <option value={3}>Rechazado</option>
                <option value={4}>Anulado</option>
              </select>
            </div>

            <div className="w-full flex flex-row justify-end">
              <input
                className="input-bg-color appearance-none border text-gray-700 w-[50%] leading-tight capitalize h-[25px] p-[6px] mr-[1px] mt-1 relative left-[-2px]"
                id="date"
                value={dateFromFilter}
                type="date"
                onChange={(e) => {
                  setDateFromFilter(e.target.value);
                }}
              />

              <input
                className="input-bg-color appearance-none border text-gray-700 w-[50%] leading-tight capitalize h-[25px] p-[6px] mt-1"
                id="date"
                value={dateToFilter}
                type="date"
                onChange={(e) => {
                  setDateToFilter(e.target.value);
                }}
                onBlur={() => fetchPrices()}
              />
            </div>
          </div>
          <div className="sm:w-[25%] w-[30%] flex flex-col sm:justify-start justify-end items-center sm:pr-0 pr-5">
            <div className="flex flex-row justify-start w-full mt-1.5">
              <input
                type="checkbox"
                checked={potentials}
                onChange={(e) => setPotentials(!potentials)}
                className="mr-1.5"
              />
              <label className="!text-[12px] uppercase">Potenciales</label>
            </div>
            <div className="flex flex-row justify-start w-full">
              <input
                type="checkbox"
                checked={canceleds}
                onChange={(e) => setCanceleds(!canceleds)}
              />
              <label className="!text-[12px] uppercase">&nbsp;Anulados</label>
            </div>
          </div>
        </div>
        <div className="w-[95%] mx-auto overflow-auto max-h-[80%] relative top-[1px]">
          <table className="w-[95%] table-auto mx-auto w-full text-xs">
            <thead className="bg-gray-600">
              <tr className="h-[20px]">
              {width > 730 ? <><th className="w-6 !text-white">Id</th>
                <th className="w-[120px] !text-white">Fecha</th>
                <th className="!text-white">Cliente</th>
                <th className="w-[120px] !text-white">Vendedor</th>
                <th className="!text-white">Moneda</th>
                {/* <th>Sucursal</th> */}
                {/* <th>Empresa</th> */}
                <th className="!text-white">Estado</th>
                <th className="!text-white">Total</th>
                <th className="!text-white w-[120px]">T. Cerrado</th>
                <th className="!text-white uppercase">Ult. Com.</th>
                <th className="!text-white">Accion</th></> : <></>}
              </tr>
            </thead>
            <tbody>
              {isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={18}>
                    <Lottie
                      animationData={loadingAnimation}
                      style={{ width: 100, height: 100, margin: "0 auto" }}
                      loop={true}
                    />
                  </td>
                </tr>
              )}
              {prices.length > 0 &&
                !isFetching &&
                prices?.map((price, i) => {
                  let priceTotal = 0;
                  let closedPriceTotal = 0;

                  price.pricesdetails.map((detail) => {
                    priceTotal += detail.priceAmount * detail.quantity;
                  });

                  price.pricesdetails.map((detail) => {
                    closedPriceTotal += detail.closedPrice * detail.quantity;
                  });

                  if (width > 730) {
                    return (
                      <tr
                        key={price.id}
                        className={`${
                          price.state == 0
                            ? "bg-yellow-400"
                            : price.state == 1
                            ? "bg-cyan-400"
                            : price.state == 2
                            ? "bg-green-400"
                            : price.state == 3
                            ? "bg-red-400"
                            : price.state == 4
                            ? "bg-orange-400"
                            : ""
                        }`}
                      >
                        <td>{price.id}</td>
                        <td>
                          {price.createdAt
                            .substr(0, 10)
                            .split("-")
                            .reverse()
                            .join("/")}{" "}
                          {/* {price.createdAt.substr(11, 5)} */}
                        </td>
                        <td className="text-left uppercase whitespace-nowrap">
                          {price.client.name}
                        </td>
                        <td className="text-left uppercase whitespace-nowrap">
                          {price.seller.fullname}
                        </td>
                        <td className="text-center uppercase">
                          {price.currency.name}
                        </td>
                        {/* <td className="text-center uppercase">
                        {price.branch.description}
                      </td> */}
                        {/* <td className="text-center uppercase">
                        {price.company.name}
                      </td> */}

                        <td className="uppercase">
                          {price.state == 0
                            ? "Pendiente"
                            : price.state == 1
                            ? "Aprobado"
                            : price.state == 2
                            ? "Facturado"
                            : price.state == 3
                            ? "Rechazado"
                            : price.state == 4
                            ? "Anulado"
                            : ""}
                        </td>

                        <td className="text-right">{numberWithCommas(priceTotal)}</td>
                        <td className="text-right">{numberWithCommas(closedPriceTotal)}</td>
                        <td className="text-left"> { price.priceshistories[0] ? 
                          <>
                            <b>{price.priceshistories[0]?.contact?.name} ({price.priceshistories[0]?.contact?.phone}):</b> 
                            &nbsp;
                              {price.priceshistories[0]?.description}. <b className="uppercase">Registrado por:&nbsp; {price.priceshistories[0]?.user?.fullname}</b></> : <><b className="text-red-600">Sin contacto.</b></> }</td>

                        <td className="text-center">
                          <button
                            onClick={() => {
                              editPrice(price.id);
                            }}
                            className="bg-transparent"
                          >
                            <FontAwesomeIcon
                              className="m-1 cursor-pointer secondary-color"
                              icon={faEye}
                            />
                          </button>
                        </td>
                      </tr>
                    );
                  } else {
                    return (
                      <tr
                        key={price.id}
                        className={`${
                          price.state == 0
                            ? "bg-yellow-400"
                            : price.state == 1
                            ? "bg-cyan-400"
                            : price.state == 2
                            ? "bg-green-400"
                            : price.state == 3
                            ? "bg-red-400"
                            : price.state == 4
                            ? "bg-orange-400"
                            : ""
                        }`}
                        style={{ borderBottom: "1px solid gray" }}
                      >
                        <td colSpan={8} className="text-left p-2">
                          <b className="uppercase">Numero:</b> {price.id} <br />
                          <b className="uppercase">Fecha:</b>{" "}
                          {price.createdAt
                            .substr(0, 10)
                            .split("-")
                            .reverse()
                            .join("/")}{" "}
                          <br />
                          <b className="uppercase">Cliente:</b>{" "}
                          {price.client.name} <br />
                          <b className="uppercase">Vendedor:</b>{" "}
                          {price.seller.fullname} <br />
                          <b className="uppercase">Estado:</b>{" "}
                          {price.state == 0
                            ? "Pendiente"
                            : price.state == 1
                            ? "Aprobado"
                            : price.state == 2
                            ? "Facturado"
                            : price.state == 3
                            ? "Rechazado"
                            : price.state == 4
                            ? "Anulado"
                            : ""}
                          <br />
                          <b className="uppercase">Total:</b>{" "}
                          {numberWithCommas(priceTotal)}
                          <br />
                          { price.priceshistories[0] ? 
                          <>
                           <b>{price.priceshistories[0]?.contact?.name} ({price.priceshistories[0]?.contact?.phone}):</b> 
                            &nbsp;
                              {price.priceshistories[0]?.description}. <b className="uppercase">Registrado por:&nbsp; {price.priceshistories[0]?.user?.fullname}</b></> : <><b className="text-red-600">Sin contacto.</b></> }
                          <button
                            onClick={() => {
                              editPrice(price.id);
                            }}
                            className="bg-transparent"
                          >
                            <FontAwesomeIcon
                              className="m-1 cursor-pointer secondary-color"
                              icon={faEye}
                            />
                          </button>
                        </td>
                      </tr>
                    );
                  }
                })}
              {prices.length <= 0 && !isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={18} className="text-center">
                    Sin registros (0)
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {/* <Pagination
            path="cotizaciones"
            page={page}
            pages={pages}
            total={total}
          /> */}
        </div>
      </Layout>
    </>
  );
};

export default Prices;
