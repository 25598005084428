import React, { useState, useEffect } from "react";
import { axiosAuth } from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser } from "../store/slices/mainSlice";
import { useLocation } from "react-router-dom";
import { numberWithCommas } from "../utils/constants";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/animations/loading.json";
// import "../assets/css/style.css";

const ClientAccountStatePrint = () => {
  const user = useSelector((state) => state.main.user);

  const dispatch = useDispatch();
  const { search } = useLocation();

  const [isFetching, setIsFetching] = useState(false);

  const [clients, setClients] = useState([]);
  const [endTotal, setEndTotal] = useState(0);
  const [endTotalReceived, setEndTotalReceived] = useState(0);
  const [endTotalRest, setEndTotalRest] = useState(0);
  const [params, setParams] = useState(new URLSearchParams(search));

  async function fetchCollectionsData() {
    setIsFetching(true);

    let data = await axiosAuth.get(
      "/clientaccountstate?page=" +
        params.get("page") +
        "&dateFrom=" +
        params.get("dateFromFilter") +
        "&dateTo=" +
        params.get("dateToFilter") +
        "&clientId=" +
        params.get("clientId")
    );

    if (data.data.error) {
      if (data.data.message === "token") {
        await axiosAuth.post("/logout");
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    setIsFetching(false);

    let totalAmount = 0,
      totalPaid = 0,
      totalRest = 0;

    let newClients = [...data.data.collections.rows];
    let newClientsObj = [];

    newClients.map((client, c) => {
      let clientTotalAmount = 0,
        clientTotalPaid = 0,
        clientTotalRest = 0;

      client.sales.map((sale) => {
        let saleTotal = 0;

        sale.dues.map((due, i) => {
          totalAmount += due.amount;
          totalPaid += due.paid;
          totalRest += due.amount - due.paid;

          clientTotalAmount += due.amount;
          clientTotalPaid += due.paid;
          clientTotalRest += due.amount - due.paid;

          saleTotal += due.amount;
        });
        sale.saleTotal = saleTotal;
      });

      client.totalAmount = clientTotalAmount;
      client.totalPaid = clientTotalPaid;
      client.totalRest = clientTotalRest;

      if (client.totalAmount > 0) {
        newClientsObj.push(client);
      }
    });

    setEndTotal(totalAmount);
    setEndTotalReceived(totalPaid);
    setEndTotalRest(totalRest);

    setClients(newClientsObj);

    setTimeout(function () {
      var contenido = document.getElementById("toPrintAccountState").innerHTML;
      document.body.innerHTML = contenido;
      window.print();
      setTimeout(window.close, 0);
    }, 1000);
  }

  useEffect(() => {
    fetchCollectionsData();
  }, []);

  return (
    <>
      <div
        className="w-[100%] p-4 pt-0 pb-0 mx-auto overflow-auto"
        id="toPrintAccountState"
      >
        <div className="bg-white px-4 pb-1 text-center rounded-3xl">
          <div className="w-full">
            <div className="mt-2">
              <div className="w-full flex flex-col">
                {/* <header> */}

                <div className="w-full text-center my-1 border-b-[1px] border-slate-600">
                  <h1 className="text-[9px]">{user.company.name}</h1>
                  <h4 className="text-[9px]">ESTADO DE CUENTA DE CLIENTES</h4>
                </div>

                <div className="w-full flex flex-col justify-start text-left border-b-[1px] pb-[4px] border-slate-600">
                  <p className="text-[9px]">
                    <b>FECHA DESDE:</b>{" "}
                    {params.get("dateFromFilter") == "" ||
                    params.get("dateFromFilter") == undefined
                      ? "TODO"
                      : params.get("dateFromFilter")}
                  </p>
                  <p className="text-[9px]">
                    <b>FECHA HASTA:</b>{" "}
                    {params.get("dateToFilter") == "" ||
                    params.get("dateToFilter") == undefined
                      ? "TODO"
                      : params.get("dateToFilter")}
                  </p>
                </div>

                {/* </header> */}

                <div className="sales-table w-full mt-1">
                  <div className="text-gray">
                    <div className="tr flex border-b-2 border-black mb-[1px]">
                      <div className="w-[14.2%] text-[9px] sm:block hidden font-bold primary-color">
                        EMISION
                      </div>
                      <div className="w-[33%] sm:w-[14.2%] text-[9px] font-bold primary-color">
                        FACTURA
                      </div>
                      <div className="w-[33%] sm:w-[14.2%] text-[9px] font-bold primary-color">
                        TOTAL FAC.
                      </div>
                      <div className="w-[14.2%] sm:block hidden text-[9px] font-bold primary-color">
                        VENCIMIENTO
                      </div>
                      <div className="w-[9%] sm:block hidden text-[9px] font-bold primary-color">
                        CUOTA
                      </div>
                      <div className="w-[14.2%] sm:block hidden text-[9px] font-bold primary-color">
                        MONTO
                      </div>
                      <div className="w-[14.2%] sm:block hidden text-[9px] font-bold primary-color">
                        PAGADO
                      </div>
                      <div className="w-[33%] sm:w-[14.2%] text-[9px] font-bold primary-color">
                        SALDO
                      </div>
                    </div>
                  </div>
                  <div className="overflow-y-auto overflow-x-hidden">
                    {isFetching && (
                      <div className="tr flex justify-center items-center p-3">
                        <Lottie
                          animationData={loadingAnimation}
                          style={{ width: 100, height: 100, margin: "0 auto" }}
                          loop={true}
                        />
                      </div>
                    )}

                    {!isFetching &&
                      clients.map((client, c) => {
                        return (
                          <div className={`pt-2 pb-0 `}>
                            {/* ${c > 0 && c % 7 == 0 && c != clients.length - 1 ? '!mb-48' : ''} */}
                            <div className="flex flex-col justify-start text-[9px]">
                              <p className="text-left">
                                <b>ID:</b> {client.id}
                              </p>
                              <p className="text-left">
                                <b>NOMBRE:</b> {client.name}
                              </p>
                              <p className="text-left">
                                <b>RUC/CI:</b> {client.ruc}
                              </p>
                              <p className="text-left">
                                <b>DIRECCION:</b> {client.address}
                              </p>
                              <p className="text-left">
                                <b>TELEFONOS:</b> {client.phone}
                              </p>
                            </div>

                            {client.sales.map((sale, s) => {
                              return sale.dues.map((due, d) => {
                                return (
                                  <div className={`tr flex text-[9px]`}>
                                    <div className="td w-[14.2%] m-[0.5px] text-center">
                                      {sale.createdAt
                                        .substr(0, 10)
                                        .split("-")
                                        .reverse()
                                        .join("/")}
                                    </div>

                                    <div className="td w-[14.2%] m-[0.5px] text-center whitespace-nowrap">
                                      {sale.expeditionPoint} {sale.number}
                                    </div>

                                    <div className="td w-[14.2%] m-[0.5px] text-center whitespace-nowrap">
                                      {numberWithCommas(sale.saleTotal)}
                                    </div>

                                    <div className="td w-[14.2%] m-[0.5px] sm:block hidden text-center">
                                      {due.expirationDate
                                        .substr(0, 10)
                                        .split("-")
                                        .reverse()
                                        .join("/")}
                                    </div>

                                    <div className="td w-[9%] sm:block hidden m-[0.5px] text-center">
                                      {due.number}/{sale?.dues?.length}
                                    </div>
                                    <div
                                      className={`td w-[14.2%] m-[0.5px] text-right ${
                                        d == sale.dues.length - 1
                                          ? "border-b-[1px] border-slate-400"
                                          : ""
                                      }`}
                                    >
                                      {numberWithCommas(due.amount)}
                                    </div>
                                    <div
                                      className={`td w-[14.2%] m-[0.5px] text-right ${
                                        d == sale.dues.length - 1
                                          ? "border-b-[1px] border-slate-400"
                                          : ""
                                      }`}
                                    >
                                      {numberWithCommas(
                                        Number(
                                          String(due.paid).replaceAll(".", "")
                                        )
                                      )}
                                    </div>
                                    <div
                                      className={`td w-[14.2%] m-[0.5px] text-right ${
                                        d == sale.dues.length - 1
                                          ? "border-b-[1px] border-slate-400"
                                          : ""
                                      }`}
                                    >
                                      {numberWithCommas(
                                        due.amount -
                                          Number(
                                            String(due.paid).replaceAll(".", "")
                                          )
                                      )}
                                    </div>
                                  </div>
                                );
                              });
                            })}

                            <div className="w-full tr flex text-[9px]">
                              <div className="td w-[14.2%] m-[0.5px] text-center"></div>

                              <div className="td w-[14.2%] m-[0.5px] text-center whitespace-nowrap"></div>

                              <div className="td w-[14.2%] m-[0.5px] text-center whitespace-nowrap"></div>

                              <div className="td w-[14.2%] m-[0.5px] sm:block hidden text-center"></div>

                              <div className="td w-[9%] sm:block hidden m-[0.5px] text-center">
                                <b>TOTALES</b>
                              </div>
                              <div className="border-b-[1px] border-slate-400 td w-[14.2%] m-[0.5px] text-right">
                                <b>{numberWithCommas(client.totalAmount)}</b>
                              </div>
                              <div className="border-b-[1px] border-slate-400 td w-[14.2%] m-[0.5px] text-right">
                                <b>{numberWithCommas(client.totalPaid)}</b>
                              </div>
                              <div className="border-b-[1px] border-slate-400 td w-[14.2%] m-[0.5px] text-right">
                                <b>{numberWithCommas(client.totalRest)}</b>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-row justify-end mt-[2px]">
            <div className="w-[10%] text-[9px] mr-5 text-right">
              <b>{numberWithCommas(endTotal)}</b>
            </div>
            <div className="w-[12%] text-[9px] mr-5 text-right">
              <b>{numberWithCommas(endTotalReceived)}</b>
            </div>
            <div className="w-[12%] text-[9px] text-right">
              <b>{numberWithCommas(endTotalRest)}</b>
            </div>
          </div>

          {/* <footer> */}
          {/* <div className="w-full mt-2 tr border-b-2 border-black"></div> */}
          {/* </footer> */}
        </div>
      </div>
    </>
  );
};

export default ClientAccountStatePrint;
