import React, { useState, Fragment, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faPen,
  faTrash,
  faPuzzlePiece,
} from "@fortawesome/free-solid-svg-icons";
import { Dialog, Transition, Tab } from "@headlessui/react";
import Layout from "../layout";
import { axiosAuth } from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser, setTitle } from "../store/slices/mainSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Pagination from "../components/pagination";
import DateComponent from "../components/date";
import ComboBox from "../components/combobox";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/animations/loading.json";
import customer from "../assets/img/customer.png";
import door from "../assets/img/door.png";
import floppy from "../assets/img/floppy.png";
import useViewport from "../utils/useViewport";

const PotentialClients = () => {
  const user = useSelector((state) => state.main.user);
  ///pruebaaaaaaasssssssssssssss
  const dispatch = useDispatch();
  const location = useLocation();
  const { state } = location;

  const { page } = useParams();
  const { width } = useViewport();

  const [isFetching, setIsFetching] = useState(false);
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState("edit");
  const [forMesagge, setFormessage] = useState("");
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);

  const [potentialClients, setPotentialClients] = useState([]);
  const [users, setUsers] = useState([]);

  const [contacts, setContacts] = useState([]);
  const [potentialClientsActivity, setPotentialClientsActivity] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [cities, setCities] = useState([]);
  const [zones, setZones] = useState([]);
  const [dataOrigins, setDataOrigins] = useState([]);

  const [contactName, setContactName] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [contactEmail, setContactEmail] = useState("");

  const [contactId, setContactId] = useState(0);
  const [description, setDescription] = useState("");
  const [nextTask, setNextTask] = useState("");
  const [nextTaskDate, setNextTaskDate] = useState("");
  const [nextTaskUserId, setNextTaskUserId] = useState(1);

  const [potentialClientId, setPotentialClientId] = useState(0);
  const [name, setName] = useState("");
  const [ruc, setRuc] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [hood, setHood] = useState("");
  const [companyId, setCompanyId] = useState(user.companyId);
  const [cityId, setCityId] = useState(0);
  const [zoneId, setZoneId] = useState(0);
  const [dataOriginId, setDataOriginId] = useState(0);
  const [observations, setObservations] = useState("");
  const [search, setSearch] = useState("");
  const [potential, setPotential] = useState(1);
  const [active, setActive] = useState(0);
  const [deleted, setDeleted] = useState(0);
  const [usersSearch, setUsersSearch] = useState([]);
  async function fetchPotentialClientsData() {
    
    let pageQuery = page == undefined ? 1 : page;

    setIsFetching(true);

    let searchString = "";

    if (state?.search != "") {
      searchString = "&search=" + state?.search;
      setSearch(state?.search);
    } else {
      searchString = "&search=";
    }

    let data = await axiosAuth.get(
      "/potentialclients?page=" + pageQuery + searchString
    );

    if (data.data.error) {
      if (data.data.message === "token") {
        await axiosAuth.post("/logout");
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    setIsFetching(false);

    setPotentialClients(data.data.potentialclients.rows);
    setPages(data.data.potentialclients.pages);
    setTotal(data.data.potentialclients.count);

    data.data.companies.unshift({ id: 0, name: "Elija una empresa" });
    setCompanies(data.data.companies);

    setCities(data.data.cities);
    setZones(data.data.zones);
    setUsers(data.data.users.rows);
    setUsersSearch(data.data.users.rows);
    setDataOrigins(data.data.dataorigins);
  }

  useEffect(() => {
    fetchPotentialClientsData();
    dispatch(setTitle("Clientes Potenciales"));
  }, []);

  useEffect(() => {
    fetchPotentialClientsData();
  }, [location]);

  const editPotentialClient = (potentialClientId) => {
    setAction("edit");
    setPotentialClientId(potentialClientId);

    let potentialClient = potentialClients.filter(
      (client) => client.id === potentialClientId
    )[0];

    setContacts(potentialClient.contacts);
    setName(potentialClient.name);
    setRuc(potentialClient.ruc);
    setAddress(potentialClient.address);
    setPhone(potentialClient.phone);
    setEmail(potentialClient.email);
    setHood(potentialClient.hood);
    setCompanyId(potentialClient.companyId);
    setCityId(potentialClient.cityId);
    setZoneId(potentialClient.zoneId);
    setDataOriginId(potentialClient.dataoriginId);
    setObservations(potentialClient.observations);
    setPotential(potentialClient.potential);
    setActive(potentialClient.active);
    setDeleted(potentialClient.deleted);
  


    setOpen(true);
  };

  const clearFields = () => {
    setContacts([]);
    setPotentialClientId(0);
    setName("");
    setRuc("");
    setAddress("");
    setEmail("");
    setPhone("");
    setHood("");
    setCompanyId(0);
    setCityId(0);
    setZoneId(0);
    setObservations("");
    setPotential(1);
    setActive(0);
    setDeleted(0);
  };
  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };
  const handleSubmit = async () => {
    if (action === "edit") {
      let dataoriginId=dataOriginId;
      let objToSend = {
        potentialClientId,
        name,
        ruc,
        address,
        phone,
        email,
        hood,
        companyId,
        cityId,
        zoneId,
        observations,
        potential,
        active,
        dataoriginId,
        deleted,
        
      };

      let updatedClientRequest = await axiosAuth.put(
        "/potentialclients",
        objToSend
      );

      if (updatedClientRequest.data.error) {
        if (updatedClientRequest.data.message === "token") {
          dispatch(setUser(null));
          dispatch(setToken(null));
        }

        setFormessage(updatedClientRequest.message);
      } else {
        let updatedPotentialClientsNewObj = [];

        potentialClients.map((potentialClient) => {
          if (potentialClient.id == potentialClientId) {
            if (updatedClientRequest.potential == 1) {
              updatedPotentialClientsNewObj.push(updatedClientRequest.data);
            }
          } else {
            updatedPotentialClientsNewObj.push(potentialClient);
          }
          return "";
        });

        setPotentialClients(updatedPotentialClientsNewObj);
      }
    } else {
      if (name === "" || companyId === 0 || cityId === 0) {
        alert("Debe completar todos los campos !");
        return false;
      }
      setPotential(1);
      let dataoriginId=dataOriginId;
      let createdClient = await axiosAuth.post("/potentialclients", {
        name,
        ruc,
        address,
        phone,
        email,
        hood,
        companyId,
        cityId,
        zoneId,
        observations,
        potential,
        active,
        dataoriginId,
        deleted,
      });

      if (createdClient.data.error) {
        if (createdClient.data.message === "token") {
          dispatch(setUser(null));
          dispatch(setToken(null));
        }

        setFormessage(createdClient.data.message);
      } else {
        let newPotentialClients = [...potentialClients];
        newPotentialClients.push(createdClient.data);
        setPotentialClients(newPotentialClients);
      }
    }
    setOpen(false);
    clearFields();
  };
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Los meses son de 0 a 11
    const year = date.getFullYear();
    return `${day} / ${month} / ${year}`;
  }
  const deletePotentialClient = async (potentialClientId) => {
    if (window.confirm("Desea eliminar este cliente ?")) {
      let potentialClientDeleteRequest = await axiosAuth.delete("/clients", {
        params: { id: potentialClientId },
      });

      if (potentialClientDeleteRequest.data.error) {
        if (potentialClientDeleteRequest.data.message === "token") {
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      let newPotentialClientsObj = [];
      let loggedUser = user;

      potentialClients.map((potentialClient) => {
        if (potentialClient.id !== potentialClientId) {
          newPotentialClientsObj.push(potentialClient);
        } else {
          if (loggedUser.roleId == 1) {
            potentialClient.deleted = 1;
            newPotentialClientsObj.push(potentialClient);
          }
        }
        return "";
      });

      setPotentialClients(newPotentialClientsObj);
    }
  };

  const searchPotentialClients = async (search) => {
    setSearch((prev) => search);
    const searchPotentialClientsRequest = await axiosAuth.get(
      "/searchPotentialClients?search=" + search
    );

    setPotentialClients(
      searchPotentialClientsRequest.data.potentialClients.rows
    );
    setPages(searchPotentialClientsRequest.data.potentialClients.pages);
    setTotal(searchPotentialClientsRequest.data.potentialClients.count);
  };

  return (
    <>
      <Layout>
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              clearFields();
              setOpen(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto align-items-center justify-center items-center flex pb-5">
              <div className="flex justify-center p-4 text-center sm:p-0 w-[950px]">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel
                    className={`relative transform overflow-hidden  bg-white text-left shadow-xl transition-all sm:my-4 ${
                      action == "edit" ? "w-[90%]" : "w-[50%]"
                    } ${width > 1024 ? "w-[50%]" : "w-[90%]"}`}
                    style={{
                      marginTop: action == "edit" && width < 660 ? 450 : 0,
                    }}
                  >
                    <div className="bg-white px-4 pb-4 pt-1 sm:p-6 sm:pb-0">
                      <div className="sm:flex sm:items-start w-full">
                        <div className="text-center sm:mt-0 sm:text-left w-full">
                          <Dialog.Title
                            as="h2"
                            className="text-center font-regular leading-6 primary-color text-3xl mb-2"
                          >
                            Clientes Potenciales
                          </Dialog.Title>
                        </div>
                      </div>
                    </div>

                    <Tab.Group>
                      <Tab.List
                        className="flex space-x-1 rounded-xl bg-blue-900/20 p-1 mb-[2px] w-[100%] "
                        style={{
                          margin: "18px 18px 0 18px",
                          width: "calc(100% - 36px)",
                        }}
                      >
                        <Tab
                          className={({ selected }) =>
                            classNames(
                              "w-[50%] rounded-lg py-1.5 text-sm leading-5 primary-color text-xs",
                              "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                              selected ? "bg-white font-bold" : ""
                            )
                          }
                        >
                          Datos Clientes Potencial
                        </Tab>
                        {action === "edit" && (
                          <Tab
                            className={({ selected }) =>
                              classNames(
                                "w-[50%] rounded-lg py-1.5 text-sm leading-5 primary-color text-xs",
                                "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                                selected ? "bg-white font-bold" : ""
                              )
                            }
                          >
                            Contactos/Gestiones
                          </Tab>
                        )}
                      </Tab.List>
                      <Tab.Panels>
                        <Tab.Panel>
                          <div className="w-full mt-[20px]">
                            <form
                              className="bg-white rounded w-full"
                              action=""
                              onSubmit={(e) => e.preventDefault()}
                            >
                              <div className="flex flex-col md:flex-row w-full justify-center">
                                <div
                                  className={`${
                                    action == "edit"
                                      ? "w-[90%] md:w-[90%]"
                                      : "w-[90%]"
                                  } sm:border-none border-b-2 border-gray-600 pb-3`}
                                >
                                  {action === "edit" && (
                                    <div className="mb-[1px] flex flex-row items-center">
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      <label
                                        className="block text-gray-700 text-[10px] font-bold uppercase"
                                        htmlFor="id"
                                      >
                                        Id:
                                      </label>
                                      &nbsp;
                                      <input
                                        readOnly
                                        className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-[27px]"
                                        id="id"
                                        type="text"
                                        value={potentialClientId}
                                      />
                                    </div>
                                  )}

                                  <div className="mb-[1px] flex flex-row items-center">
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <label
                                      className="block text-gray-700 text-[10px] font-bold uppercase"
                                      htmlFor="name"
                                    >
                                      Nombre:
                                    </label>
                                    &nbsp;
                                    <input
                                      className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[27px]"
                                      id="name"
                                      type="text"
                                      value={name}
                                      onChange={(e) => setName(e.target.value)}
                                    />
                                  </div>

                                  <div className="mb-[1px] flex flex-row items-center">
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <label
                                      className="block text-gray-700 text-[10px] font-bold uppercase mr-[0.5px]"
                                      htmlFor="ruc"
                                    >
                                      RUC:
                                    </label>
                                    &nbsp;
                                    <input
                                      className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-[27px]"
                                      id="ruc"
                                      type="text"
                                      value={ruc}
                                      onChange={(e) => setRuc(e.target.value)}
                                    />
                                  </div>

                                  <div className="mb-[1px] flex flex-row items-center">
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <label
                                      className="block text-gray-700 text-[10px] font-bold uppercase ml-[2px]"
                                      htmlFor="address"
                                    >
                                      Direccion:
                                    </label>
                                    &nbsp;
                                    <input
                                      className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-[27px]"
                                      id="address"
                                      type="text"
                                      value={address}
                                      onChange={(e) =>
                                        setAddress(e.target.value)
                                      }
                                    />
                                  </div>

                                  <div className="mb-[1px] flex flex-row items-center">
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <label
                                      className="block text-gray-700 text-[10px] font-bold uppercase ml-[2px]"
                                      htmlFor="phone"
                                    >
                                      Telefono:
                                    </label>
                                    &nbsp;
                                    <input
                                      className="input-bg-color appearance-none border py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-6 relative w-full h-[27px]"
                                      id="phone"
                                      type="text"
                                      value={phone}
                                      onChange={(e) => setPhone(e.target.value)}
                                    />
                                  </div>

                                  <div className="mb-[1px] flex flex-row items-center">
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <label
                                      className="block text-gray-700 text-[10px] font-bold uppercase mr-[3px]"
                                      htmlFor="email"
                                    >
                                      Email:
                                    </label>
                                    &nbsp;
                                    <input
                                      className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-[27px]"
                                      id="email"
                                      type="text"
                                      value={email}
                                      onChange={(e) => setEmail(e.target.value)}
                                    />
                                  </div>

                                  <div className="mb-[1px] flex flex-row items-center">
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <label
                                      className="block text-gray-700 text-[10px] font-bold uppercase"
                                      htmlFor="hood"
                                    >
                                      Barrio:
                                    </label>
                                    &nbsp;
                                    <input
                                      className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-[27px]"
                                      id="hood"
                                      type="text"
                                      value={hood}
                                      onChange={(e) => setHood(e.target.value)}
                                    />
                                  </div>

                                  {user.roleId == 1 && (
                                    <>
                                      <div className="mb-[1px] flex flex-row items-center">
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <label
                                          className="block text-gray-700 text-[10px] font-bold uppercase"
                                          htmlFor="companyId"
                                        >
                                          Empresa:
                                        </label>
                                        &nbsp;
                                        <ComboBox
                                          data={companies}
                                          setData={setCompanyId}
                                          value={companyId}
                                          className="border h-[27px] w-[100.5%]"
                                        />
                                      </div>
                                    </>
                                  )}
                                  {/* <select
                                    className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="companyId"
                                    value={companyId}
                                    onChange={(e) => {
                                      setCompanyId(e.target.value);
                                    }}
                                  >
                                    <option value={0}>Elija una empresa</option>
                                    {companies.map((company) => (
                                      <option
                                        key={company.id}
                                        value={company.id}
                                      >
                                        {company.name}
                                      </option>
                                    ))}
                                  </select> */}

                                  <div className="mb-[1px] flex flex-row items-center">
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <label
                                      className="block text-gray-700 text-[10px] font-bold uppercase mr-[1px]"
                                      htmlFor="cityId"
                                    >
                                      Ciudad:
                                    </label>
                                    &nbsp;
                                    <select
                                      className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline leading-[10px] h-[27px]"
                                      id="cityId"
                                      value={cityId}
                                      onChange={(e) => {
                                        setCityId(parseInt(e.target.value));
                                      }}
                                    >
                                      <option value={0}>
                                        Elija una ciudad
                                      </option>
                                      {cities.map((city) => (
                                        <option key={city.id} value={city.id}>
                                          {city.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>

                                  <div className="mb-[1px] flex flex-row items-center">
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <label
                                      className="block text-gray-700 text-[10px] font-bold uppercase mr-[3px]"
                                      htmlFor="zoneId"
                                    >
                                      Zona:
                                    </label>
                                    &nbsp;
                                    <select
                                      className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline leading-[10px] h-[27px]"
                                      id="zoneId"
                                      value={zoneId}
                                      onChange={(e) => {
                                        setZoneId(parseInt(e.target.value));
                                      }}
                                    >
                                      <option value={0}>Elija una zona</option>
                                      {zones.map((zone) => (
                                        <option key={zone.id} value={zone.id}>
                                          {zone.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>

                                  <div className="mb-[1px] flex flex-row items-center">
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <label
                                      className="block text-gray-700 text-[10px] font-bold uppercase mr-[3px]"
                                      htmlFor="dataOriginId"
                                    >
                                      Origen:
                                    </label>
                                    &nbsp;
                                    <select
                                      className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline leading-[10px] h-[27px]"
                                      id="dataOriginId"
                                      value={dataOriginId}
                                      onChange={(e) => {
                                        setDataOriginId(
                                          parseInt(e.target.value)
                                        );
                                      }}
                                    >
                                      <option value={0}>Elija un origen</option>
                                      {dataOrigins.map((dataOrigin) => (
                                        <option
                                          key={dataOrigin.id}
                                          value={dataOrigin.id}
                                        >
                                          {dataOrigin.description}
                                        </option>
                                      ))}
                                    </select>
                                  </div>

                                  <div className="mb-[1px] flex flex-row items-center">
                                    <label
                                      className="block text-gray-700 text-[10px] font-bold uppercase mr-[2px]"
                                      htmlFor="observations"
                                    >
                                      Observacion:
                                    </label>
                                    <input
                                      className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-[27px]"
                                      id="observations"
                                      type="text"
                                      value={observations}
                                      onChange={(e) =>
                                        setObservations(e.target.value)
                                      }
                                    />
                                  </div>

                                  {/* <div className="mb-[1px] flex flex-row items-center">
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      <label
                                        className="block text-gray-700 text-[10px] font-bold uppercase mr-[2px]"
                                        htmlFor="potential"
                                      >
                                        Potencial:
                                      </label>
                                      &nbsp;
                                      <select
                                        className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline leading-[11px] h-[28px]"
                                        id="potential"
                                        value={potential}
                                        onChange={(e) =>
                                          setPotential(parseInt(e.target.value))
                                        }
                                      >
                                        <option value={0}>No</option>
                                        <option value={1}>Si</option>
                                      </select>
                                    </div> */}

                                  <div className="mb-[1px] flex flex-row items-center">
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <label
                                      className="block text-gray-700 text-[10px] font-bold uppercase mr-[2px]"
                                      htmlFor="active"
                                    >
                                      Activo:
                                    </label>
                                    &nbsp;
                                    <select
                                      className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline leading-[11px] h-[27px]"
                                      id="active"
                                      value={active}
                                      onChange={(e) =>
                                        setActive(parseInt(e.target.value))
                                      }
                                    >
                                      <option value={0}>No</option>
                                      <option value={1}>Si</option>
                                    </select>
                                  </div>
                                  <div className="mb-[1px] flex flex-row items-center">
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <label
                                      className="block text-gray-700 text-[10px] font-bold uppercase mr-[2px]"
                                      htmlFor="active"
                                    >
                                      POTENCIAL:
                                    </label>
                                    &nbsp;
                                    <select
                                      className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline leading-[11px] h-[27px]"
                                      id="active"
                                      value={potential}
                                      onChange={(e) =>
                                        setPotential(parseInt(e.target.value))
                                      }
                                    >
                                      <option value={1}>Si</option>
                                      <option value={0}>NO</option>
                                    </select>
                                  </div>

                                  {user.roleId == 1 && (
                                    <div className="mb-[1px] flex flex-row items-center">
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      <label
                                        className="block text-gray-700 text-[10px] font-bold uppercase mr-[2px]"
                                        htmlFor="deleted"
                                      >
                                        Borrado:
                                      </label>
                                      &nbsp;
                                      <select
                                        className="input-bg-color appearance-none border w-[101%] py-2 px-3 text-gray-700 leading-[10px] focus:outline-none focus:shadow-outline h-[27px]"
                                        id="deleted"
                                        value={deleted}
                                        onChange={(e) =>
                                          setDeleted(parseInt(e.target.value))
                                        }
                                      >
                                        <option value={0}>No</option>
                                        <option value={1}>Si</option>
                                      </select>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </form>
                          </div>
                             
                        </Tab.Panel>
                        <Tab.Panel>
                          {action == "edit" && (
                            <div
                              className="flex flex-col justify-center w-[100%] md:w-[100%]"
                              style={{ padding: "0px 18px 18px 18px" }}
                            >
                              <div className="w-[100%] mt-3 md:mt-0 flex flex-col">
                                <h2 className="text-center font-regular leading-8 primary-color text-2xl">
                                  Contactos
                                </h2>
                                <div className="w-full max-h-[30vh] overflow-auto m-1">
                                  <table className="text-[12px] relative !top-0 !w-full">
                                    <thead className="primary-color-bg">
                                      <tr
                                        style={{
                                          borderBottom:
                                            "1px solid rgb(229 231 235 / var(--tw-border-opacity))",
                                        }}
                                        sss
                                      >
                                        <th className="p-2 !text-white text-[11px] font-thin text-left">
                                          Nombre
                                        </th>
                                        <th className="p-2 !text-white text-[11px] w-[23%] font-thin text-left">
                                          Telefono
                                        </th>
                                        <th className="p-2 !text-white text-[11px] font-thin text-left">
                                          Email
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="text-[10px]">
                                      {width > 550 &&
                                        contacts.length > 0 &&
                                        contacts.map((contact) => (
                                          <tr
                                            key={contact.id}
                                            className=" border-b border-gray-200"
                                            style={{
                                              borderBottom:
                                                "1px solid rgb(229 231 235 / var(--tw-border-opacity))",
                                            }}
                                          >
                                            <td className="text-left border-b border-gray-200 p-2">
                                              {contact.name}
                                            </td>
                                            <td className="text-left border-b border-gray-200 p-2">
                                              {contact.phone}
                                            </td>
                                            <td className="text-left border-b border-gray-200 p-2">
                                              {contact.email}
                                            </td>
                                          </tr>
                                        ))}

                                      {width <= 550 &&
                                        contacts.length > 0 &&
                                        contacts.map((contact) => (
                                          <tr
                                            key={contact.id}
                                            className="border-b border-gray-200"
                                            style={{
                                              borderBottom:
                                                "1px solid rgb(229 231 235 / var(--tw-border-opacity))",
                                            }}
                                          >
                                            <td
                                              className="text-left border-b border-gray-200 p-2"
                                              colSpan={3}
                                            >
                                              <b className="uppercase">
                                                Nombre:{" "}
                                              </b>
                                              {contact.name}
                                              <br />
                                              <b className="uppercase">
                                                Telefono:{" "}
                                              </b>
                                              {contact.phone}
                                              <br />
                                              <b className="uppercase">
                                                Email:{" "}
                                              </b>
                                              {contact.email}
                                            </td>
                                          </tr>
                                        ))}
                                    </tbody>
                                  </table>
                                </div>

                                <div className="flex flex-col justify-center sm:flex-row m-1 ml-[0px] border-none sm:border-t-[1px] sm:border-b-2 border-gray-600">
                                  <input
                                    className="w-[100%] sm:w-[33.2%] h-[25px] border rounded m-1 px-2 text-[11px]"
                                    name="contact-name"
                                    placeholder="Nombre"
                                    onChange={(e) =>
                                      setContactName(e.target.value)
                                    }
                                    value={contactName}
                                    type="text"
                                  />
                                  <input
                                    className="w-[100%] sm:w-[33.2%] h-[25px] border rounded m-1 px-2 text-[11px]"
                                    name="contact-phone"
                                    placeholder="Telefono"
                                    onChange={(e) =>
                                      setContactPhone(e.target.value)
                                    }
                                    value={contactPhone}
                                    type="text"
                                  />
                                  <input
                                    className="w-[100%] sm:w-[33.2%] h-[25px] border rounded m-1 px-2 text-[11px]"
                                    name="contact-email"
                                    placeholder="Email"
                                    onChange={(e) =>
                                      setContactEmail(e.target.value)
                                    }
                                    value={contactEmail}
                                    type="text"
                                  />
                                  <button
                                    onClick={async () => {
                                      if (
                                        contactName == "" ||
                                        contactEmail == "" ||
                                        contactPhone == ""
                                      ) {
                                        alert(
                                          "Debe completar los campos para guardar el contacto"
                                        );
                                        return false;
                                      }

                                      let createContactRequest =
                                        await axiosAuth.post("/contacts", {
                                          clientId: potentialClientId,
                                          name: contactName,
                                          phone: contactPhone,
                                          email: contactEmail,
                                        });

                                      let newContacts = [...contacts];
                                      newContacts.push(
                                        createContactRequest.data
                                      );
                                      setContacts(newContacts);

                                      let newPotentialClients = [
                                        ...potentialClients,
                                      ];
                                      newPotentialClients.map(
                                        (potentialClient) => {
                                          if (
                                            potentialClient.id ==
                                            potentialClientId
                                          ) {
                                            potentialClient.contacts =
                                              newContacts;
                                          }
                                        }
                                      );
                                      setPotentialClients(newPotentialClients);

                                      setTimeout(function () {
                                        setContactName("");
                                        setContactPhone("");
                                        setContactEmail("");
                                      }, 1000);
                                    }}
                                    type="button"
                                    className="relative inline-flex w-[25px] h-[25px] float-left mt-[2px] flex-col justify-center items-center rounded ternary-bg-color text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 text-[11px] sm:top-[3px]"
                                  >
                                    <img
                                      src={floppy}
                                      className="w-[25px] relative"
                                    />
                                  </button>
                                </div>
                              </div>

                              <div className="w-[100%] mt-3 md:mt-0 flex flex-col">
                                <h2 className="text-center font-regular leading-8 primary-color text-2xl">
                                  Actividad
                                </h2>
                                <div className="w-full max-h-[30vh] overflow-auto ml-1">
                                  <table className="text-[12px] relative !top-0 !w-full">
                                    <thead className="primary-color-bg">
                                      <tr>
                                        <th className="p-2 !text-white text-[11px] font-thin text-left">
                                          Fecha Alta
                                        </th>
                                        <th className="p-2 !text-white text-[11px] font-thin text-left">
                                          Contacto
                                        </th>
                                        {/*  <th className="p-2 !text-white text-[11px] font-thin text-left">
                                          Prox. T. Usuario
                                        </th> */}
                                        <th className="p-2 !text-white text-[11px] w-[35%] font-thin  text-left">
                                          Descripcion
                                        </th>
                                        <th className="p-2 !text-white text-[11px] w-[25%] font-thin text-left">
                                          Proxima Tarea
                                        </th>
                                        <th className="p-2 !text-white text-[11px] font-thin text-left">
                                          Prox. T. Fecha
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="text-[10px]">
                                      {potentialClientsActivity.length > 0 &&
                                        potentialClientsActivity.map(
                                          (potentialClientsActivityRow) => {
                                            return (
                                              <tr
                                                className="border-b border-gray-200"
                                                style={{
                                                  borderBottom:
                                                    "1px solid rgb(229 231 235 / var(--tw-border-opacity))",
                                                }}
                                              >
                                                <td className="text-left border-b border-gray-200 p-2">
                                                  {formatDate(
                                                    potentialClientsActivityRow.createdAt
                                                  )}
                                                </td>
                                                <td className="text-left border-b border-gray-200 p-2">
                                                  {
                                                    potentialClientsActivityRow
                                                      .contact.name
                                                  }
                                                </td>
                                                {/* <td className="text-left border-b border-gray-200 p-2">
                                                  {
                                                    potentialClientsActivityRow
                                                      .user.fullname
                                                  }
                                                </td> */}
                                                <td className="text-left border-b border-gray-200 p-2">
                                                  {
                                                    potentialClientsActivityRow.description
                                                  }
                                                </td>
                                                <td className="text-left border-b border-gray-200 p-2">
                                                  {
                                                    potentialClientsActivityRow.nextTask
                                                  }
                                                </td>
                                                <td className="text-left border-b border-gray-200 p-2">
                                                  {formatDate(
                                                    potentialClientsActivityRow.nextTaskDate
                                                  )}
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                    </tbody>
                                  </table>
                                </div>

                                <div className="flex flex-col justify-center sm:flex-row mt-2 border-none sm:border-t-[1px] sm:border-b-2 border-gray-600">
                                  <div
                                    className="h-[24px] !w-[150px]"
                                    style={{
                                      marginLeft: "150px",
                                      marginRight: "2px",
                                      borderTop:
                                        "1px solid rgb(229 231 235 / var(--tw-border-opacity))",
                                    }}
                                  >
                                    <ComboBox
                                      data={contacts}
                                      setData={setContactId}
                                      value={contactId}
                                      placeholder="CONTACTO"
                                      className="h-[24px] !w-[150px] mx-1"
                                    />
                                  </div>

                                  {/*  <ComboBox
                                    data={users}
                                    setData={setNextTaskUserId}
                                    value={nextTaskUserId}
                                    placeholder="USUARIO"
                                    className="h-[25px] !w-[150px] mx-1"

                                  /> */}
                                  <input
                                    className="w-[100%] sm:w-[30%] h-[25px] border rounded mx-1 px-2 text-[11px]"
                                    name="description"
                                    placeholder="DESCRIPCION"
                                    onChange={(e) =>
                                      setDescription(e.target.value)
                                    }
                                    value={description}
                                    type="text"
                                  />
                                  <input
                                    className="w-[100%] sm:w-[30%] h-[25px] border rounded mx-1 px-2 text-[11px]"
                                    name="nextTask"
                                    placeholder="Proxima tarea"
                                    onChange={(e) =>
                                      setNextTask(e.target.value)
                                    }
                                    value={nextTask}
                                    type="text"
                                  />

                                  <input
                                    className="w-[100%] sm:w-[30%] h-[25px] border rounded mx-1 px-2 text-[11px]"
                                    name="nextTaskDate"
                                    placeholder="Fecha Prox. t."
                                    onChange={(e) =>
                                      setNextTaskDate(e.target.value)
                                    }
                                    value={nextTaskDate}
                                    type="date"
                                  />

                                  <button
                                    onClick={async () => {
                                      if (
                                        contactId == 0 ||
                                        nextTaskUserId == 0 ||
                                        description == ""
                                      ) {
                                        alert(
                                          "Debe completar los campos para guardar la actividad"
                                        );
                                        return false;
                                      }

                                      let createPotentialClientsActivityRequest =
                                        await axiosAuth.post(
                                          "/potentialclientsactivity",
                                          {
                                            clientId: potentialClientId,
                                            contactId: contactId,
                                            description: description,
                                            nextTask: nextTask,
                                            nextTaskDate: nextTaskDate,
                                            nextTaskUserId: nextTaskUserId,
                                          }
                                        );

                                      let newActivities = [
                                        ...potentialClientsActivity,
                                      ];
                                      newActivities.push(
                                        createPotentialClientsActivityRequest.data
                                      );
                                      setPotentialClientsActivity(
                                        newActivities
                                      );

                                      let newPotentialClients = [
                                        ...potentialClients,
                                      ];
                                      newPotentialClients.map(
                                        (potentialClient) => {
                                          if (
                                            potentialClient.id ==
                                            potentialClientId
                                          ) {
                                            potentialClient.potentialclientsactivity =
                                              newActivities;
                                          }
                                        }
                                      );
                                      setPotentialClients(newPotentialClients);

                                      setTimeout(function () {
                                        setContactId(0);
                                        setDescription("");
                                        setNextTask("");
                                        setNextTaskDate("");
                                        setNextTaskUserId(1);
                                      }, 1000);
                                    }}
                                    className="relative inline-flex w-[25px] h-[25px] float-left mt-[2px] flex-col justify-center items-center rounded ternary-bg-color text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400  text-[11px]"
                                    style={{ marginTop: "0", width: "37px" }}
                                  >
                                    <img
                                      src={floppy}
                                      className="w-[25px] relative"
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </Tab.Panel>
                        <div className="bg-gray-50 px-4 py-3 pt-0 justify-center flex sm:px-6">
                            <button
                              type="button"
                              className="relative inline-flex w-[68px] h-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 text-[11px] top-[-4px] sm:top-0"
                              style={{ alignItems: "center" }}
                              onClick={() => handleSubmit()}
                            >
                              <img src={floppy} className="w-8" />
                              GUARDAR
                            </button>
                            <button
                              type="button"
                              className="relative inline-flex w-[68px] h-[68px] float-left flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 text-[11px] mr-1"
                              style={{ alignItems: "center" }}
                              onClick={() => {
                                clearFields();
                                setOpen(false);
                              }}
                            >
                              <img src={door} className="w-5" />
                              CERRAR
                            </button>
                          </div>
                      </Tab.Panels>
                    </Tab.Group>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
        <div
          style={{ borderBottom: "1px solid lightgray" }}
          className="flex flex-row w-full relative top-[-35px] pb-3"
        >
          <div>
            <h1 className="ml-5 mt-5 text-3xl secondary-color title font-family-thin">
              Clientes (Potenciales)
            </h1>
            <DateComponent className="sm:block hidden" />
          </div>
          <div className="sm:w-[80%] w-[90%] flex flex-row justify-end items-center sm:pr-12">
            <div className="flex flex-col justify-start w-100 float-right relative right-[60px] space-y-2">
              <label className="text-sm font-medium text-gray-700">
                Buscar
              </label>
              <input
                className="input-bg-color p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Nombre, RUC, etc."
                onChange={(e) => searchPotentialClients(e.target.value)}
              />
            </div>
            <button
              type="button"
              className="relative inline-flex w-[68px] float-right flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 mt-4"
              style={{ alignItems: "center" }}
              onClick={() => {
                setAction("create");
                setOpen(true);
              }}
            >
              <FontAwesomeIcon
                className="m-1 cursor-pointer secondary-color !text-[25px]"
                icon={faPuzzlePiece}
              />
              AGREGAR
            </button>
          </div>
        </div>

        <div className="w-[95%] p-2 mx-auto overflow-auto sm:max-h-[80vh] max-h-[65vh] mt-[-30px]">
          <table className="w-[95%] table-auto mx-auto w-full text-xs clients-table support-table">
            <thead className="primary-color-bg !text-white">
              <tr className="bg-gray-500 h-8">
                {/*  <th className="uppercase !text-white p-2">Id</th> */}
                <th className="uppercase !text-white p-2 !text-left mr-2">Nombre</th>
                <th className="uppercase !text-white p-2 !text-left w-[30%]">
                  Observacion
                </th>
                <th className="uppercase !text-white p-2 !text-left">
                  Prox. Cont.
                </th>
                <th className="uppercase !text-white p-2 !text-left">
                  Ult. Cont.
                </th>
                {/* <th className="uppercase">Email</th> */}
                <th className="uppercase !text-white p-2 !text-left">Ciudad</th>
                {/* <th className="uppercase">Observacion</th> */}
                <th className="uppercase !text-white p-2 !text-left">
                  Fecha Alta
                </th>
                <th className="uppercase !text-white p-2 !text-left">Origen</th>
                <th className="uppercase !text-white p-2">Accion</th>
              </tr>
            </thead>
            <tbody>
              {isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={15}>
                    <Lottie
                      animationData={loadingAnimation}
                      style={{ width: 100, height: 100, margin: "0 auto" }}
                      loop={true}
                    />
                  </td>
                </tr>
              )}
              {potentialClients.length > 0 &&
                !isFetching &&
                potentialClients?.map((potentialClient) => {
                console.log(potentialClient.dataoriginId); 
                const filteredData = dataOrigins.filter(
                  (item) => item.id === potentialClient.dataoriginId
                );
                console.log("Filtered Data:", filteredData);
                
                const nameData = filteredData.length > 0 ? filteredData[0].description : "N/A";
                console.log("Name Data:", nameData);
                  if (width > 520) {
                    return (
                      <tr key={potentialClient.id}>
                        {/*  <td>{potentialClient.id}</td> */}
                        <td className="text-left whitespace-nowrap ">
                          {potentialClient.name}
                        </td>
                        <td className="text-left whitespace-normal break-words p-2">
                          {potentialClient.potentialclientsactivities?.[
                            potentialClient.potentialclientsactivities.length -
                              1
                          ]?.description || "N/A"}
                        </td>
                        {/* <td className="text-left whitespace-nowrap">{potentialClient.potentialclientsactivities?.[potentialClient.potentialclientsactivities.length-1]?.nextTaskDate || 'N/A'}
                        </td>  */}
                        <td className="text-left whitespace-nowrap ">
                          {potentialClient.potentialclientsactivities &&
                          potentialClient.potentialclientsactivities.length >
                            0 &&
                          potentialClient.potentialclientsactivities[
                            potentialClient.potentialclientsactivities.length -
                              1
                          ].nextTaskDate
                            ? potentialClient.potentialclientsactivities[
                                potentialClient.potentialclientsactivities
                                  .length - 1
                              ].nextTaskDate
                                .substr(0, 10)
                                .split("-")
                                .reverse()
                                .join("/")
                            : "N/A"}
                        </td>
                        <td className="text-left whitespace-nowrap">
                          {
                            potentialClient.potentialclientsactivities?.[
                              potentialClient.potentialclientsactivities
                                .length - 1
                            ]?.contact?.name
                          }
                        </td>
                        {/* <td>{potentialClient.email}</td> */}
                       
                        <td className="text-left whitespace-nowrap ">
                          {potentialClient?.city?.name}
                        </td>
                        <td className="text-right whitespace-nowrap !text-left">
                          {potentialClient?.createdAt
                            ?.substr(0, 10)
                            ?.split("-")
                            ?.reverse()
                            ?.join("/")}
                        </td>
                        <td className="text-left whitespace-nowrap p-2">
                        {nameData}
                          
                        </td>
                        <td className="text-center">
                          <button
                            className="bg-transparent"
                            onClick={() => {
                              editPotentialClient(potentialClient.id);
                              setPotentialClientsActivity(
                                potentialClient.potentialclientsactivities
                              );
                            }}
                          >
                            <FontAwesomeIcon
                              className="m-1 cursor-pointer secondary-color"
                              icon={faPen}
                            />
                          </button>
                          <button
                            className="bg-transparent"
                            onClick={() => {
                              deletePotentialClient(potentialClient.id);
                            }}
                          >
                            <FontAwesomeIcon
                              className="m-1 cursor-pointer secondary-color"
                              icon={faTrash}
                            />
                          </button>
                        </td>
                      </tr>
                    );
                  } else {
                    return (
                      <tr key={potentialClient.id}>
                        <td className="text-left">
                          <b>Id: </b>
                          {potentialClient.id}
                          <br />
                          <b>Nombre: </b>
                          {potentialClient.name}
                          <br />
                          <b>Prox. Cont.:</b>
                          {potentialClient.potentialclientsactivities?.[
                            potentialClient.potentialclientsactivities.length -
                              1
                          ]?.nextTaskDate || "N/A"}
                          <br />
                          <b>Observacion:</b>
                          {potentialClient.potentialclientsactivities?.[
                            potentialClient.potentialclientsactivities.length -
                              1
                          ]?.description || "N/A"}
                          <br />
                          <b>Contacto:</b>
                          {
                            potentialClient.potentialclientsactivities?.[
                              potentialClient.potentialclientsactivities
                                .length - 1
                            ]?.contact?.name
                          }
                          <br />
                          <b>Telefono:</b>
                          {potentialClient.phone}
                          <br />
                          <b>Empresa:</b>
                          {potentialClient.company.name}
                          <br />
                          <b>Ciudad:</b>
                          {potentialClient?.city?.name}
                          <br />
                          <b>Zona:</b>
                          {potentialClient?.zone?.name}
                          <br />
                          <b>Activo:</b>
                          {potentialClient.active ? "Si" : "No"}
                          <br />
                          <b>Potencial: </b>
                          {user.roleId == 1 && potentialClient.deleted
                            ? "Si"
                            : "No"}
                          <br />
                          <b>Fecha: </b>
                          {potentialClient?.createdAt
                            ?.substr(0, 10)
                            ?.split("-")
                            ?.reverse()
                            ?.join("/")}
                          <br />
                          <button
                            className="bg-transparent"
                            onClick={() => {
                              editPotentialClient(potentialClient.id);
                              setPotentialClientsActivity(
                                potentialClient.potentialclientsactivities
                              );
                            }}
                          >
                            <FontAwesomeIcon
                              className="m-1 cursor-pointer secondary-color"
                              icon={faPen}
                            />
                          </button>
                          <button
                            className="bg-transparent"
                            onClick={() => {
                              deletePotentialClient(potentialClient.id);
                            }}
                          >
                            <FontAwesomeIcon
                              className="m-1 cursor-pointer secondary-color"
                              icon={faTrash}
                            />
                          </button>
                        </td>
                      </tr>
                    );
                  }
                })}
              {potentialClients.length <= 0 && !isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={15} className="text-center">
                    Sin registros (0)
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <Pagination
            className="clients-pagination-container"
            path="potencialesclien"
            page={page}
            pages={pages}
            total={total}
            params={{ search }}
          />
        </div>
      </Layout>
    </>
  );
};

export default PotentialClients;
