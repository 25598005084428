import React, { useState, useRef, useEffect } from "react";
import Layout from "../layout";
import { axiosAuth } from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser, setTitle } from "../store/slices/mainSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DateComponent from "../components/date";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/animations/loading.json";
import ComboBox from "../components/combobox";

import { DownloadTableExcel } from "react-export-table-to-excel";
import xls from "../assets/img/xls.png";
import search from "../assets/img/search.webp";
import door from "../assets/img/door.png";
import pdf from "../assets/img/pdf.png";
import printer from "../assets/img/printer.png";
import { numberWithCommas } from "../utils/constants";

const PurchasesReport = (props) => {
  const tableRef = useRef(null);

  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();
  const [reporType, setReporType] = useState(0);
  const dispatch = useDispatch();
  const [isFetching, setIsFetching] = useState(false);
  const [purchasesReport, setPurchasesReport] = useState(true);
  const [purchasesReportByItem, setPurchasesReportByItem] = useState(false);
  const [bookOfPurchasesReport, setBookOfPurchasesReport] = useState(false);
  const [type, seType] = useState(state != undefined ? state.type : 0);

  const user = useSelector((state) => state.main.user);

  const [companyId, setCompanyId] = useState(
    user != undefined ? user.companyId : 0
  );
  const [providers, setProviders] = useState([]);
  const [providerId, setProviderId] = useState(0);

  const [reports, setReports] = useState([]);

  const [dateFromFilter, setDateFromFilter] = useState(() => {
    if (state != undefined) {
        return state.dateFromFilter;
    } else {
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth() + 1; // getMonth() returns 0-based month, so we add 1
        const formattedMonth = currentMonth < 10 ? `0${currentMonth}` : currentMonth;
        return `${currentYear}-${formattedMonth}-01`;
    }
});

  const [dateToFilter, setDateToFilter] = useState(() => {
    return state != undefined
      ? state.dateToFilter
      : new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
          .toLocaleDateString()
          .replaceAll("/", "-")
          .split("-")
          .reverse()
          .map((d) => (d.length <= 1 ? "0" + String(d) : d))
          .join("-");
  });

  async function fetchReport() {
    setIsFetching(true);

    let reportRequest = await axiosAuth.get(
      "/purchasesreport?type=" +
        type +
        "&providerId=" +
        providerId +
        "&companyId=" +
        companyId +
        "&dateFrom=" +
        dateFromFilter +
        "&dateTo=" +
        dateToFilter +
        "&purchasesReport=" +
        purchasesReport +
        "&purchasesReportByItem=" +
        purchasesReportByItem +
        "&bookOfPurchasesReport=" +
        bookOfPurchasesReport
    );

    if (reportRequest.data.error) {
      if (reportRequest.data.message === "token") {
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    setIsFetching(false);

    let totalPriceUn = 0,
      totalPriceTot = 0,
      totalPriceUnGral = 0,
      totalPriceTotGral = 0,
      totalTaxed10 = 0,
      totalTaxed10Ind = 0,
      totalTaxed5 = 0,
      totalTaxed5Ind = 0,
      totalVat10Ind = 0,
      totalVat5Ind = 0,
      totalExempt = 0,
      totalTotal = 0;

    reportRequest.data.report.map((report, r) => {
      totalPriceUn += parseInt(report.price);
      totalPriceUnGral += parseInt(report.price);
      totalPriceTot += parseInt(report.total);
      totalPriceTotGral += parseInt(report.total);
      totalVat10Ind += parseInt(report.vat10);
      totalVat5Ind += parseInt(report.vat5);
      totalTaxed10Ind += parseInt(report.taxed10);
      totalTaxed5Ind += parseInt(report.taxed5);
      totalTaxed10 += parseInt(report.taxed10 + report.vat10);
      totalTaxed5 += parseInt(report.taxed5 + report.vat5);
      totalExempt += parseInt(report.exempt);
      totalTotal += parseInt(
        report.taxed10 +
          report.vat10 +
          report.taxed5 +
          report.vat5 +
          report.exempt
      );

      report.totalPriceUn = numberWithCommas(totalPriceUn);
      report.totalPriceTot = numberWithCommas(totalPriceTot);

      report.totalPriceUnGral = numberWithCommas(totalPriceUnGral);
      report.totalPriceTotGral = numberWithCommas(totalPriceTotGral);

      report.totalTaxed10 = numberWithCommas(totalTaxed10);
      report.totalVat5Ind = numberWithCommas(totalVat5Ind);
      report.totalVat10Ind = numberWithCommas(totalVat10Ind);
      report.totalTaxed10Ind = numberWithCommas(totalTaxed10Ind);
      report.totalTaxed5 = numberWithCommas(totalTaxed5);
      report.totalTaxed5Ind = numberWithCommas(totalTaxed5Ind);
      report.totalExempt = numberWithCommas(totalExempt);

      report.totalTotal = numberWithCommas(totalTotal);

      if (
        r > 0 &&
        reportRequest.data.report.length > 0 &&
        reportRequest.data.report.length > 1 &&
        r != reportRequest.data.report.length - 1 &&
        reportRequest.data.report[r].id != reportRequest.data.report[r + 1].id
      ) {
        totalPriceUn = 0;
        totalPriceTot = 0;
      }
    });

    setReports(reportRequest.data.report);
  }

  useEffect(() => {
    async function fetchProviders() {
      let providersRequest = await axiosAuth.get("/providers?page=1");

      if (providersRequest.data.error) {
        if (providersRequest.data.message === "token") {
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      setProviders(providersRequest.data.providers.rows);
    }

    fetchProviders();
  }, []);

  useEffect(() => {
    dispatch(setTitle("Reporte de Compras"));
  }, []);

  const searchProviders = async (search) => {
    const searchProvidersRequest = await axiosAuth.get(
      "/searchProviders?search=" + search
    );

    setProviders(searchProvidersRequest.data.providers.rows);
  };

  return (
    <>
      <Layout>
        <div className="flex flex-col md:flex-row border border-gray-200 border-r-0 border-b-1">
          <div className="hidden sm:block w-[70%] md:w-[40%] min-w-[212px] border border-b-0 border-r-2 h-[98%]">
            <h1 className="ml-5 mt-5 text-[20px] secondary-color title font-family-thin">
              Reporte de Compras
            </h1>
            <DateComponent className="!text-[15px] sm:block hidden" />
          </div>

          <div className="flex flex-row md:flex-col lg:flex-row justify-center items-center w-full md:w-[45%] m-[auto] mr-4 sm:ml-2 ml-6">
            <div className="mr-6 flex flex-col items-center justify-start">
              <div className="flex flex-row justify-start items-center">
                <ComboBox
                  data={providers}
                  setData={setProviderId}
                  value={providerId}
                  placeholder="Proveedor"
                  className="h-[27px] w-[100%] mb-2"
                  search={searchProviders}
                />
              </div>

              <div className="w-[100%] flex flex-row justify-start items-center">
                <input
                  id="purchasesReportFilter"
                  name="purchasesReportFilter"
                  checked={purchasesReport}
                  type="checkbox"
                  className="mr-1"
                  onChange={(e) => {
                    setReports([]);
                    setPurchasesReportByItem(false);
                    setBookOfPurchasesReport(false);
                    setPurchasesReport(!purchasesReport);
                  }}
                />
                <label
                  className="block text-gray-700 text-sm uppercase !text-[11px] whitespace-nowrap"
                  htmlFor="purchasesReportFilter"
                >
                  Reporte de compras
                </label>
              </div>

              <div className="w-[100%] flex flex-row justify-start items-center">
                <input
                  id="byItemFilter"
                  name="byItemFilter"
                  checked={purchasesReportByItem}
                  type="checkbox"
                  className="mr-1"
                  onChange={(e) => {
                    setReports([]);
                    setPurchasesReport(false);
                    setBookOfPurchasesReport(false);
                    setPurchasesReportByItem(!purchasesReportByItem);
                  }}
                />
                <label
                  className="block text-gray-700 text-sm uppercase !text-[11px]"
                  htmlFor="byItemFilter"
                >
                  Por articulo
                </label>
              </div>

              <div className="w-[100%] flex flex-row justify-start items-center">
                <input
                  id="purchasesBookFilter"
                  name="purchasesBookFilter"
                  checked={bookOfPurchasesReport}
                  type="checkbox"
                  className="mr-1"
                  onChange={(e) => {
                    setReports([]);
                    setPurchasesReport(false);
                    setPurchasesReportByItem(false);
                    setBookOfPurchasesReport(!bookOfPurchasesReport);
                  }}
                />
                <label
                  className="block text-gray-700 text-sm uppercase !text-[11px]"
                  htmlFor="purchasesBookFilter"
                >
                  Libro de Compras
                </label>
              </div>
            </div>

            <div className="mr-5 w-[25%] min-w-[120px] mb-2">
              <select
                id="typeFilter"
                name="typeFilter"
                value={type}
                className="input-bg-color appearance-none border w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-sm h-[22px] uppercase !text-[11px] mt-2"
                style={{ lineHeight: "13px" }}
                onChange={(e) => {
                  setReports([]);
                  seType(e.target.value);
                }}
              >
                <option value="0">Todos</option>
                <option value="1">Contado</option>
                <option value="2">Credito</option>
              </select>

              <input
                type="date"
                id="dateFromFilter"
                name="dateFromFilter"
                value={dateFromFilter}
                className="input-bg-color appearance-none border w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-[22px] font-[11px] mt-[1px]"
                onChange={(e) => {
                  setReports([]);
                  setDateFromFilter(e.target.value);
                }}
              />

              <input
                type="date"
                id="dateToFilter"
                name="dateToFilter"
                value={dateToFilter}
                className="input-bg-color appearance-none border w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-[22px] font-[11px] mt-[1px]"
                onChange={(e) => {
                  setReports([]);
                  setDateToFilter(e.target.value);
                }}
              />
            </div>
          </div>

          <div className="flex flex-row w-full justify-center md:w-[55%] mt-1 h-[75px] mr-[6%] relative sm:right-0 right-[-7%] purchases-buttons-container">
            <div className="flex flex-col items-center h-[70px]">
              <button
                type="button"
                className="inline-flex flex-col w-[75px] h-[55px] mr-[2px] justify-center items-center rounded ternary-bg-color p-4 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 !text-[11px]"
                onClick={() => {
                  setReports([]);
                  fetchReport();
                }}
              >
                <img src={search} className="w-8" />
                CONSULTAR
              </button>
            </div>

            <div className="flex flex-col items-center h-[70px]">
              <DownloadTableExcel
                filename={
                  purchasesReport
                    ? "REPORTE_COMPRAS_"
                    : "REPORTE_COMPRAS_ARTICULO_" +
                      new Date().toLocaleDateString()
                }
                sheet="REPORTE-COMPRAS"
                currentTableRef={tableRef.current}
              >
                <button
                  type="button"
                  className="inline-flex flex-col w-[75px] h-[55px] justify-center items-center rounded ternary-bg-color p-4 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 !text-[11px] mr-[2px]"
                >
                  <img src={xls} className="w-8" />
                  EXPORTAR
                </button>
              </DownloadTableExcel>
            </div>

            <div className="flex flex-col items-center hidden sm:block">
              <button
                type="button"
                className="inline-flex flex-col w-[75px] h-[55px] mr-[2px] justify-center items-center rounded ternary-bg-color p-4 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 !text-[11px]"
                onClick={() => {}}
              >
                <img src={printer} className="w-8" />
                IMPRIMIR
              </button>
            </div>

            <div className="flex flex-col items-center">
              <button
                type="button"
                className="inline-flex flex-col w-[75px] h-[55px] mr-[2px] justify-center items-center rounded ternary-bg-color p-4 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 !text-[11px]"
                onClick={() => {}}
              >
                <img src={pdf} className="w-8" />
                PDF
              </button>
            </div>

            <div className="flex flex-col items-center">
              <button
                type="button"
                className="inline-flex flex-col w-[75px] h-[55px] mr-[2px] justify-center items-center rounded ternary-bg-color p-4 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 !text-[11px]"
                onClick={() => {
                  navigate("/");
                }}
              >
                <img src={door} className="w-4" />
                SALIR
              </button>
            </div>
          </div>
        </div>

        <div
          className={`w-[95%] p-5 pb-0 mx-auto overflow-auto max-h-[300px] sm:max-h-[400px] relative ${
            purchasesReportByItem ? "" : "pt-4 sm:mt-12 mt-0"
          }`}
        >
          <table
            className="w-[95%] table-auto mx-auto w-full text-xs purchases-report-table min-w-[628px] relative"
            ref={tableRef}
          >
            {purchasesReport && reports.length > 0 && !isFetching && (
              <thead className="bg-gray-600 !text-white relative md:fixed mt-[-30px] w-[87.8%]">
                <tr className="w-full">
                  <th className="!text-white uppercase w-[10%]">NRO.</th>
                  <th className="!text-white uppercase w-[7%]">FECHA</th>
                  <th className="!text-white uppercase w-[33%]">PROVEEDOR</th>
                  <th className="!text-white uppercase w-[5%]">RUC</th>

                  <th className="!text-white uppercase w-[5%] whitespace-nowrap">
                    GRAV 10
                  </th>
                  <th className="!text-white uppercase w-[5%] whitespace-nowrap">
                    GRAV 5
                  </th>
                  <th className="!text-white uppercase w-[5%]">EXCENTAS</th>
                  <th className="!text-white uppercase w-[5%]">TOTAL</th>
                  <th className="!text-white uppercase w-[5%]">COND.</th>
                </tr>
              </thead>
            )}

            {purchasesReportByItem && reports.length > 0 && !isFetching && (
              <thead className="bg-gray-600 !text-white">
                <tr className="w-full">
                  <th className="!text-white uppercase">PROVEEDOR</th>
                  <th className="!text-white uppercase">FECHA</th>
                  <th className="!text-white uppercase">NRO. FACT.</th>
                  <th className="!text-white uppercase">CANT.</th>
                  <th className="!text-white uppercase">PRECIO UN.</th>
                  <th className="!text-white uppercase">PRECIO TOT.</th>
                  {/* <th className="!text-white uppercase w-[5%]">COSTO UN.</th>
                  <th className="!text-white uppercase w-[5%]">COSTO TOT.</th> */}
                </tr>
              </thead>
            )}

            {bookOfPurchasesReport && reports.length > 0 && !isFetching && (
              <thead className="bg-gray-600 !text-white">
                <tr className="w-full">
                  <th className="!text-white uppercase">NRO.</th>
                  <th className="!text-white uppercase">FECHA</th>
                  <th className="!text-white uppercase">RAZON SOCIAL</th>
                  <th className="!text-white uppercase">RUC</th>
                  <th className="!text-white uppercase whitespace-nowrap">
                    GRAV. 5
                  </th>
                  <th className="!text-white uppercase whitespace-nowrap">
                    GRAV. 10
                  </th>
                  <th className="!text-white uppercase whitespace-nowrap">
                    IVA. 5
                  </th>
                  <th className="!text-white uppercase whitespace-nowrap">
                    IVA. 10
                  </th>
                  <th className="!text-white uppercase">Exentas</th>
                  <th className="!text-white uppercase">Total</th>
                  {/* <th className="!text-white uppercase w-[5%]">COSTO UN.</th>
                  <th className="!text-white uppercase w-[5%]">COSTO TOT.</th> */}
                </tr>
              </thead>
            )}
            <tbody>
              {isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={20}>
                    <Lottie
                      animationData={loadingAnimation}
                      style={{ width: 100, height: 100, margin: "0 auto" }}
                      loop={true}
                    />
                  </td>
                </tr>
              )}
             {
  purchasesReport &&
  reports.length > 0 &&
  !isFetching &&
  reports.map((report, r) => {
    let total = 0;
    let number = String(report?.number) || "0";

    // Rellenar el número con ceros a la izquierda hasta que tenga 7 caracteres
    number = number.padStart(7, "0");

    total =
      report.vat5 +
      report.vat10 +
      report.exempt +
      report.taxed5 +
      report.taxed10;

    return (
      <tr key={report.id} className="text-[10px]">
        <td className="w-[12%] whitespace-nowrap">
          {report.expeditionPoint}
          {"-"}
          {number}
        </td>
        <td className="w-[10%]">
          {report.createdAt
            .substr(0, 10)
            .split("-")
            .reverse()
            .join("/")}
        </td>
        <td className="whitespace-nowrap text-left ml-1 w-[38%]">
          {report?.provider?.name || report?.provider}
        </td>
        <td className="text-right w-[7%]">
          {report.provider.ruc}
        </td>
        <td className="text-right w-[6%]">
          {numberWithCommas(report.taxed10 + report.vat10)}
        </td>
        <td className="text-right w-[5%]">
          {numberWithCommas(report.taxed5 + report.vat5)}
        </td>
        <td className="text-right w-[6.5%]">
          {numberWithCommas(report.exempt)}
        </td>
        <td className="text-right w-[8%]">
          {numberWithCommas(total)}
        </td>
        <td>
          {report.saleconditionId == 1 ? "CONTADO" : "CREDITO"}
        </td>
      </tr>
    );
  })
}

              {purchasesReportByItem &&
                reports.length > 0 &&
                !isFetching &&
                reports.map((report, r) => {
                  let number = String(report?.number) || 0;

                  Array(7 - number?.length)
                    .fill(0)
                    .map((_, i) => {
                      number = "0" + number;
                    });

                  return (
                    <>
                      {r == 0 && reports.length > 0 && (
                        <tr key={report.id} className="text-[10px]">
                          <td
                            className="text-left pl-2 text-[12px] font-bold"
                            colSpan={20}
                          >
                            {report.name}
                          </td>
                        </tr>
                      )}

                      {r > 0 &&
                        reports.length > 0 &&
                        reports.length > 1 &&
                        r != reports.length - 1 &&
                        reports[r].id != reports[r - 1].id && (
                          <tr key={report.id} className="text-[10px]">
                            <td
                              className="text-left pl- text-[12px] font-bold"
                              colSpan={20}
                            >
                              {report.name}
                            </td>
                          </tr>
                        )}

                      {reports.length > 0 &&
                        r == reports.length - 1 &&
                        r > 1 && ( // comment the left r condition if breaks
                          <tr key={report.id} className="text-[10px]">
                            <td
                              className="text-left pl- text-[12px] font-bold"
                              colSpan={20}
                            >
                              {report.name}
                            </td>
                          </tr>
                        )}

                      <tr key={report.id} className="text-[10px]">
                        <td className="whitespace-nowrap text-left ml-1">
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          {report?.provider?.name || report?.provider}
                        </td>
                        <td>
                          {report.createdAt
                            .substr(0, 10)
                            .split("-")
                            .reverse()
                            .join("/")}
                        </td>
                        <td>
                          {report.expeditionPoint}
                          {"-"}
                          {number}
                        </td>
                        <td className="text-right">{report.quantity}</td>
                        <td className="text-right">
                          {numberWithCommas(report.price)}
                        </td>
                        <td className="text-right">
                          {numberWithCommas(report.total)}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </td>
                        {/* <td className="text-right">0</td>
                      <td className="text-right">0</td> */}
                      </tr>

                      {r > 0 &&
                        reports.length > 0 &&
                        reports.length > 1 &&
                        r != reports.length - 1 &&
                        reports[r].id != reports[r + 1].id && (
                          <tr key={report.id} className="text-[10px]">
                            <td className="text-left pl- text-[12px] font-bold"></td>
                            <td className="text-left pl- text-[12px] font-bold"></td>
                            <td className="text-left pl- text-[12px] font-bold"></td>
                            <td className="text-left pl- text-[12px] font-bold text-right"></td>
                            <td className="text-left pl- text-[12px] text-right font-bold">
                              TOTALES:
                            </td>
                            {/* <td className="text-left pl- text-[12px] text-right font-bold">{report.totalPriceUn}</td> */}
                            <td className="text-left pl- text-[12px] text-right font-bold">
                              {report.totalPriceTot}
                            </td>
                            <td className="text-left pl- text-[12px] font-bold"></td>
                            <td className="text-left pl- text-[12px] font-bold"></td>
                          </tr>
                        )}

                      {reports.length > 0 && r == reports.length - 1 && (
                        <tr key={report.id} className="text-[10px]">
                          <td className="text-left pl- text-[12px] font-bold"></td>
                          <td className="text-left pl- text-[12px] font-bold"></td>
                          <td className="text-left pl- text-[12px] font-bold"></td>
                          <td className="text-left pl- text-[12px] font-bold text-right"></td>
                          <td className="text-left pl- text-[12px] text-right font-bold">
                            TOTALES:
                          </td>
                          {/* <td className="text-left pl- text-[12px] text-right font-bold">{report.totalPriceUn}</td> */}
                          <td className="text-left pl- text-[12px] text-right font-bold">
                            {report.totalPriceTot}
                          </td>
                          <td className="text-left pl- text-[12px] font-bold"></td>
                          <td className="text-left pl- text-[12px] font-bold"></td>
                        </tr>
                      )}
                    </>
                  );
                })}

              {bookOfPurchasesReport &&
                reports.length > 0 &&
                !isFetching &&
                reports.map((report, r) => {
                  let total = 0;
                  let number = String(report?.number) || 0;

                  Array(7 - number?.length)
                    .fill(0)
                    .map((_, i) => {
                      number = "0" + number;
                    });

                  total =
                    report.vat5 +
                    report.vat10 +
                    report.exempt +
                    report.taxed5 +
                    report.taxed10;

                  return (
                    <>
                      <tr key={report.id} className="text-[10px]">
                        <td className="w-[12%] whitespace-nowrap">
                          {report.expeditionPoint}
                          {"-"}
                          {number}
                        </td>
                        <td className="w-[10%]">
                          {report.createdAt
                            .substr(0, 10)
                            .split("-")
                            .reverse()
                            .join("/")}
                        </td>
                        <td className="whitespace-nowrap text-left ml-1 w-[38%]">
                          {report?.provider?.name}
                        </td>
                        <td className="text-right w-[7%]">
                          {report?.provider?.ruc}
                        </td>

                        <td className="text-right w-[6%]">
                          {numberWithCommas(report.taxed5)}
                        </td>
                        <td className="text-right w-[5%]">
                          {numberWithCommas(report.taxed10)}
                        </td>
                        <td className="text-right w-[6%]">
                          {numberWithCommas(report.vat5)}
                        </td>
                        <td className="text-right w-[5%]">
                          {numberWithCommas(report.vat10)}
                        </td>
                        <td className="text-right w-[6.5%]">
                          {numberWithCommas(report.exempt)}
                        </td>
                        <td className="text-right w-[8%]">
                          {numberWithCommas(total)}
                        </td>
                      </tr>
                    </>
                  );
                })}

              {/* {reports.length <= 0 && !isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={20} className="text-center">
                    Sin registros (0)
                  </td>
                </tr>
              )} */}
            </tbody>
          </table>
        </div>
        {purchasesReport && reports.length > 0 && !isFetching && (
          <>
            <table className="!w-[92%] mx-auto purchases-report-table">
              <tr className="text-[12px]" style={{ lineHeight: 2 }}>
                <td className="w-[12%]"></td>
                <td className="w-[10%]"></td>
                <td className="whitespace-nowrap text-left ml-1 w-[37.6%]"></td>
                <td className="text-right font-bold w-[7%]">TOTALES: </td>

                <td className="text-right font-bold w-[6%] border-gray-600 border-t-[1px] border-b-[1px]">
                  {numberWithCommas(reports[reports.length - 1].totalTaxed10)}
                </td>
                <td className="text-right font-bold w-[5%] border-gray-600 border-t-[1px] border-b-[1px]">
                  {numberWithCommas(reports[reports.length - 1].totalTaxed5)}
                </td>
                <td className="text-right font-bold w-[6.5%] border-gray-600 border-t-[1px] border-b-[1px]">
                  {numberWithCommas(reports[reports.length - 1].totalExempt)}
                </td>
                <td className="text-right font-bold w-[8%] border-gray-600 border-t-[1px] border-b-[1px]">
                  {numberWithCommas(reports[reports.length - 1].totalTotal)}
                </td>
                <td className="text-right font-bold w-[10%]"></td>
              </tr>
              <tr className="text-[12px]">
                <td className="w-[12%]"></td>
                <td className="w-[10%]"></td>
                <td className="whitespace-nowrap text-left ml-1 w-[37.6%]"></td>
                <td className="text-right font-bold w-[7%]"></td>

                <td className="text-right font-bold w-[6%] border-gray-600 border-t-[1px] border-b-[1px]"></td>
                <td className="text-right font-bold w-[5%] border-gray-600 border-t-[1px] border-b-[1px]"></td>
                <td className="text-right font-bold w-[6.5%] border-gray-600 border-t-[1px] border-b-[1px]"></td>
                <td className="text-right font-bold w-[8%] border-gray-600 border-t-[1px] border-b-[1px]"></td>
                <td className="text-right font-bold w-[10%]"></td>
              </tr>
            </table>
          </>
        )}

        {purchasesReportByItem && reports.length > 0 && !isFetching && (
          <>
            <table className="!w-[93%] mx-auto purchases-report-table">
              <tr className="text-[10px]">
                <td className="text-left pl- text-[12px] font-bold"></td>
                <td className="text-left pl- text-[12px] font-bold"></td>
                <td className="text-left pl- text-[12px] font-bold"></td>
                {/* <td className="text-left pl- text-[12px] text-right font-bold w-[15%]"></td> */}
                <td className="text-left pl- text-[12px] font-bold text-right w-[79%]">
                  TOTALES:{" "}
                </td>
                {/* <td className="text-left pl- text-[12px] text-right font-bold w-[15%]">{reports[reports.length-1].totalPriceUnGral}</td> */}
                <td className="text-left pl- text-[12px] text-right font-bold">
                  {reports[reports.length - 1].totalPriceTotGral}
                </td>
                <td className="text-left pl- text-[12px] font-bold"></td>
                <td className="text-left pl- text-[12px] font-bold"></td>
                <td className="text-left pl- text-[12px] font-bold"></td>
                <td className="text-left pl- text-[12px] font-bold"></td>
                <td className="text-left pl- text-[12px] font-bold"></td>
              </tr>
            </table>
          </>
        )}

        {bookOfPurchasesReport && reports.length > 0 && !isFetching && (
          <>
            <table className="!w-[93%] mx-auto purchases-report-table">
              <tr className="text-[10px] leading-5">
                <td className="text-left text-[12px] font-bold"></td>
                <td className="text-left text-[12px] font-bold"></td>
                <td className="text-left text-[12px] font-bold"></td>
                <td className="text-left text-[12px] font-bold text-right w-[77%]">
                  TOTALES:{" "}
                </td>
                <td className="text-left text-[12px] font-bold w-[1%] border-t-[1px] border-b-[1px] border-gray-600">
                  {reports[reports.length - 1].totalTaxed5Ind}
                </td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] text-center w-[8%] font-bold border-t-[1px] border-b-[1px] border-gray-600">
                  {reports[reports.length - 1].totalTaxed10Ind}
                </td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="w-[3%] text-left text-[12px] text-right font-bold border-t-[1px] border-b-[1px] border-gray-600">
                  {reports[reports.length - 1].totalVat5Ind}
                </td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="w-[4%] text-left text-[12px] text-right font-bold border-t-[1px] border-b-[1px] border-gray-600">
                  {reports[reports.length - 1].totalVat10Ind}
                </td>
                <td className="w-[6%] text-left text-[12px] text-right font-bold border-t-[1px] border-b-[1px] border-gray-600">
                  {reports[reports.length - 1].totalExempt}
                </td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] text-right font-bold border-t-[1px] border-b-[1px] border-gray-600">
                  {reports[reports.length - 1].totalTotal}
                </td>
                <td className="text-left text-[12px] font-bold"></td>
                <td className="text-left text-[12px] font-bold"></td>
              </tr>
              <tr className="text-[10px] leading-5">
                <td className="text-left text-[12px] font-bold"></td>
                <td className="text-left text-[12px] font-bold"></td>
                <td className="text-left text-[12px] font-bold"></td>
                <td className="text-left text-[12px] font-bold text-right w-[77%]"></td>
                <td className="text-left text-[12px] font-bold w-[1%] border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] text-center w-[8%] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="w-[3%] text-left text-[12px] text-right font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="w-[4%] text-left text-[12px] text-right font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="w-[6%] text-left text-[12px] text-right font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] text-right font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold"></td>
                <td className="text-left text-[12px] font-bold"></td>
              </tr>
            </table>
          </>
        )}
      </Layout>
    </>
  );
};

export default PurchasesReport;
