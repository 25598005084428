import React, { useEffect, Fragment, useState } from "react";
import Header from "./components/header";
import Aside from "./components/aside";
import Content from "./components/content";
import { Toaster } from "react-hot-toast";
import logo from "../src/assets/img/logo-inversed.png";
import { Transition } from "@headlessui/react";

import DateComponent from "./components/date";
import { motion } from "framer-motion";
import { useAnimate } from "framer-motion";

import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { useLocation, useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import speechWave from "./assets/animations/speech-wave.json";
import { useSelector } from "react-redux";

const Layout = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state) => state.main.user);
  const title = useSelector((state) => state.main.title);

  const { transcript, listening, resetTranscript } = useSpeechRecognition();

  const startListeningMethod = () => {
    SpeechRecognition.startListening({ language: "es-PY" });
  };

  useEffect(() => {
    if (
      String(transcript)
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLocaleLowerCase() == "llevame a articulos"
    ) {
      navigate("/articulos");
    }
  }, [transcript]);

  useEffect(() => {
    if (listening) {
      setTimeout(function () {
        resetTranscript();
        SpeechRecognition.stopListening();
      }, 4000);
    }
  }, [listening]);

  return (
    <>
      <div
        className="animated"
        style={{
          position: "absolute",
          background: "#6078a4",
          top: 0,
          width: "100%",
          height: "100%",
          zIndex: 999999999,
        }}
      ></div>

      <div
        className="animated2"
        style={{
          position: "absolute",
          background: "#35538b",
          top: 0,
          width: "100%",
          height: "100%",
          zIndex: 999999999,
        }}
      ></div>

      <Transition.Root show={listening} as={Fragment}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="container w-full h-full absolute left-[0] top-[0] flex justify-center items-center"
            style={{
              backgroundColor: "rgba(0,0,0,0.7)",
              zIndex: 9,
            }}
          >
            <Lottie
              animationData={speechWave}
              style={{ width: "70%" }}
              loop={true}
            />
          </div>
        </Transition.Child>
      </Transition.Root>

      <div className="container m-auto h-auto sm:h-full">
        <div className="flex flex-row">
          <div className="flex flex-col w-[35%] justify-center text-center items-center pl-5">
            <img
              src={logo}
              style={{ width: 100 }}
              className="logo-top-mobile hidden mt-8 m-auto"
            />

            <h5 className="w-full text-center text-xs hidden company-name-mobile !text-white font-bold whitespace-nowrap">
              {user.company.name}
            </h5>
          </div>

          <div className="w-[100%]">
            <div className="block sm:hidden text-right pr-6">
              <h1 className="ml-5 mt-6 text-[20px] !text-white font-family-thin">
                {title}
              </h1>
              <DateComponent className="!text-[15px] relative !text-white" />
            </div>
          </div>
        </div>

        <div className="flex h-auto sm:h-full">
          <Aside />
          <Content {...props} startListeningMethod={startListeningMethod} />
        </div>
      </div>
      <Toaster />
    </>
  );
};

export default Layout;
