import React, { useState, Fragment, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faTrash,
  faWindowRestore,
} from "@fortawesome/free-solid-svg-icons";
import { Dialog, Transition } from "@headlessui/react";
import Layout from "../layout";
import { axiosAuth } from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser } from "../store/slices/mainSlice";
import { useLocation, useParams } from "react-router-dom";
import Pagination from "../components/pagination";
import DateComponent from "../components/date";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/animations/loading.json";
import { numberWithCommas } from "../utils/constants";
import ComboBox from "../components/combobox";
import printerimg from "../assets/img/printer.png";

const PricesList = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isFetching, setIsFetching] = useState(false);

  const { page } = useParams();
  const user = useSelector((state) => state.main.user);

  const [pricesList, setPricesList] = useState([]);
  const [brands, setBrands] = useState([]);
  const [groups, setGroups] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [brandId, setBrandId] = useState(0);
  const [groupId, setGroupId] = useState(0);

  async function fetchPricesList(brandId, groupId) {
    let pageQuery = page === undefined ? 1 : page;

    if (brandId === undefined) {
      brandId = "";
    }
    if (groupId === undefined) {
      groupId = "";
    }

    setIsFetching(true);

    let pricesListRequest = await axiosAuth.get(
      "/priceslist?page=" +
        pageQuery +
        "&brandId=" +
        brandId +
        "&groupId=" +
        groupId
    );

    setIsFetching(false);

    if (pricesListRequest.data.error) {
      if (pricesListRequest.data.message === "token") {
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    setPricesList(pricesListRequest.data.items.rows);
    setTotal(pricesListRequest.data.items.count);
    setPages(pricesListRequest.data.items.pages);
    setBrands(pricesListRequest.data.brands);
    setGroups(pricesListRequest.data.groups);
  }

  useEffect(() => {
    fetchPricesList();
  }, []);

  useEffect(() => {
    fetchPricesList();
  }, [location]);

  useEffect(() => {
    fetchPricesList(brandId, groupId);
  }, [brandId, groupId]);

  const printDiv = (nombreDiv) => {
    var contenido = document.getElementById(nombreDiv).innerHTML;
    var contenidoOriginal = document.body.innerHTML;

    document.body.innerHTML = contenido;

    window.print();

    document.body.innerHTML = contenidoOriginal;
    //window.location.href = window.location.href;
  };

  return (
    <>
      <Layout>
        <h1 className="ml-5 mt-5 text-3xl  title secondary-color font-family-thin">
          Lista de Precios
        </h1>
        <DateComponent className="sm:block hidden" />{" "}
        <div className="flex flex-row justify-end items-center pr-14 relative top-[-5px] w-full">
          <div className="m-2 flex flex-col">
            <label className="text-xs mb-[2px]">Buscar:</label>
            <input
              type="text"
              id="bus"
              name="bus"
              required
              minlength="4"
              maxlength="8"
              size="10"
              className="h-[28px] input-bg-color w-48"
            />
          </div>
          <div className="m-2 flex flex-col">
            <label className="text-xs mb-[2px]">Marcas</label>
            <ComboBox
              data={brands}
              setData={setBrandId}
              value={brandId}
              placeholder="Marcas"
              className="h-[28px] input-bg-color"
            />
          </div>
          <div className="m-2 flex flex-col">
            <label className="text-xs mb-[2px]">Grupos</label>
            <ComboBox
              data={groups}
              setData={setGroupId}
              value={groupId}
              placeholder="Grupos"
              className="h-[28px] input-bg-color"
            />
          </div>

          <button
            type="button"
            className="inline-flex w-[92px] float-right m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
            style={{ alignItems: "center" }}
            onClick={() => printDiv("grid1")}
          >
            <img src={printerimg} className="w-8" />
            IMPRIMIR
          </button>
        </div>
        <div className="w-[95%] p-5 mx-auto overflow-auto" id="grid1">
          <br></br>
          <table className="w-[95%] table-auto mx-auto w-full text-xs">
            <thead>
              <tr>
                <th className="uppercase">Id</th>
                <th className="uppercase">Nombre</th>
                <th className="uppercase">Cod. Barra</th>
                <th className="uppercase">Precio 1</th>
                <th className="uppercase">Precio 2</th>
                <th className="uppercase">Precio 3</th>
                <th className="uppercase">Fecha Alta</th>
                {/* <th className="uppercase">Accion</th> */}
              </tr>
            </thead>
            <tbody>
              {isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={18}>
                    <Lottie
                      animationData={loadingAnimation}
                      style={{ width: 100, height: 100, margin: "0 auto" }}
                      loop={true}
                    />
                  </td>
                </tr>
              )}
              {pricesList.length > 0 &&
                !isFetching &&
                pricesList?.map((priceList) => {
                  return (
                    <tr key={priceList.id}>
                      <td>{priceList.id}</td>
                      <td>{priceList.name}</td>
                      <td>{priceList.barcode}</td>
                      <td>{numberWithCommas(priceList.price)}</td>
                      <td>{numberWithCommas(priceList.price2)}</td>
                      <td>{numberWithCommas(priceList.price3)}</td>

                      <td>
                        {priceList?.createdAt
                          ?.substr(0, 10)
                          .split("-")
                          .reverse()
                          .join("/")}
                      </td>
                      {/* <td className="text-center"> */}
                      {/* <button
                          className="bg-transparent"
                          onClick={() => {
                            editpriceList(priceList.id);
                          }}
                        >
                          <FontAwesomeIcon
                            className="m-1 secondary-color cursor-pointer"
                            icon={faPen}
                          />
                        </button> */}
                      {/* <button
                          className="bg-transparent"
                          onClick={() => {
                            deletepriceList(priceList.id);
                          }}
                        >
                          <FontAwesomeIcon
                            className="m-1 secondary-color cursor-pointer"
                            icon={faTrash}
                          />
                        </button> */}
                      {/* </td> */}
                    </tr>
                  );
                })}
              {pricesList.length <= 0 && !isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={12} className="text-center">
                    Sin registros (0)
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Pagination
          path="listadeprecios"
          page={page}
          pages={pages}
          total={total}
        />
      </Layout>
    </>
  );
};

export default PricesList;
