import React, { useState, Fragment, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faL, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Dialog, Transition } from "@headlessui/react";
import Layout from "../layout";
import { axiosAuth } from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser } from "../store/slices/mainSlice";
import { useLocation, useParams } from "react-router-dom";
import Pagination from "../components/pagination";
import DateComponent from "../components/date";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/animations/loading.json";
import branchimg from "../assets/img/branch.webp";
import door from "../assets/img/door.png";
import floppy from "../assets/img/floppy.png";

const Branches = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [action, setAction] = useState("edit");

  const { page } = useParams();
  const user = useSelector((state) => state.main.user);

  const [branches, setBranches] = useState([]);
  const [companies, setCompanies] = useState([]);

  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);

  const [branchId, setBranchId] = useState(0);
  const [description, setDescription] = useState("");
  const [companyId, setCompanyId] = useState(0);
  const [deleted, setDeleted] = useState(0);

  async function fetchBranches() {
    let pageQuery = page === undefined ? 1 : page;

    setIsFetching(true);

    let branches = await axiosAuth.get("/branches?page=" + pageQuery);

    setIsFetching(false);

    if (branches.data.error) {
      if (branches.data.message === "token") {
        await axiosAuth.post("/logout");
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    setBranches(branches.data.branches.rows);
    setTotal(branches.data.branches.count);
    setPages(branches.data.branches.pages);
    setCompanies(branches.data.companies);
  }

  useEffect(() => {
    fetchBranches();
  }, []);

  useEffect(() => {
    fetchBranches();
  }, [location]);

  const editBranch = (branchId) => {
    setAction("edit");
    setBranchId(branchId);

    let branch = branches.filter((branch) => branch.id === branchId)[0];

    setDescription(branch.description);
    setDeleted(branch.deleted);
    setCompanyId(branch.companyId);

    setOpen(true);
  };

  const clearFields = () => {
    setBranchId(0);
    setDescription("");
    setCompanyId(0);
    setDeleted(0);
  };

  const handleSubmit = async () => {
    if (description === "" || companyId === 0) {
      alert("Debes completar los campos para continuar.");
      return false;
    }

    if (action === "edit") {
      let id = branchId;
      let newBranchesObj = [];

      let updateBranchRequest = await axiosAuth.put("/branches", {
        id,
        description,
        companyId,
        deleted,
      });

      if (updateBranchRequest.data.error) {
        if (updateBranchRequest.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      branches.map((branch) => {
        if (branch.id == id) {
          newBranchesObj.push(updateBranchRequest.data);
        } else {
          newBranchesObj.push(branch);
        }
        return "";
      });

      setBranches(newBranchesObj);
    } else {
      let createBranchRequest = await axiosAuth.post("/branches", {
        description,
        companyId,
        deleted,
      });

      if (createBranchRequest.data.error) {
        if (createBranchRequest.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      let newBranches = [...branches];
      newBranches.push(createBranchRequest.data);
      setBranches(newBranches);
    }

    setOpen(false);
    clearFields();
  };

  const deleteBranch = async (branchId) => {
    if (window.confirm("Desea eliminar esta sucursal ?")) {
      let branchDeleteRequest = await axiosAuth.delete("/branches", {
        params: { id: branchId },
      });

      if (branchDeleteRequest.data.error) {
        if (branchDeleteRequest.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      let newBranchesObj = [];

      branches.map((branch) => {
        if (branch.id !== branchId) {
          newBranchesObj.push(branch);
        } else {
          if (user.roleId == 1) {
            branch.deleted = 1;
            newBranchesObj.push(branch);
          }
        }
        return "";
      });

      setBranches(newBranchesObj);
    }
  };

  return (
    <>
      <Layout>
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              clearFields();
              setOpen(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto align-items-center justify-center items-center flex">
              <div className="flex justify-center p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel
                    className="relative transform overflow-hidden rounded-3xl bg-white text-left shadow-xl transition-all sm:my-8 w-[280px]"
                    style={{ marginTop: 45 }}
                  >
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-0">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                          <Dialog.Title
                            as="h1"
                            className="text-center font-regular leading-6 primary-color text-3xl"
                          >
                            {action === "edit" ? "Editar" : "Crear"} sucursal
                          </Dialog.Title>
                          <div className="mt-5">
                            <div className="w-full max-w-xs">
                              <form className="bg-white rounded">
                                {action === "edit" && (
                                  <div className="mb-4">
                                    <label
                                      className="block text-gray-700 text-sm font-bold"
                                      htmlFor="branchId"
                                    >
                                      Id
                                    </label>
                                    <input
                                      readOnly
                                      className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      id="branchId"
                                      type="text"
                                      value={branchId}
                                    />
                                  </div>
                                )}

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="description"
                                  >
                                    Descripcion
                                  </label>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                    id="description"
                                    type="text"
                                    value={description}
                                    onChange={(e) =>
                                      setDescription(e.target.value)
                                    }
                                  />
                                </div>
                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="companyId"
                                  >
                                    Empresa
                                  </label>
                                  <select
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="companyId"
                                    value={companyId}
                                    onChange={(e) =>
                                      setCompanyId(e.target.value)
                                    }
                                  >
                                    <option key={0} value={0}>
                                      Elija una empresa
                                    </option>
                                    {companies?.map((company) => (
                                      <option
                                        key={company.id}
                                        value={company.id}
                                      >
                                        {company.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                {user.roleId == 1 && (
                                  <div className="mb-4">
                                    <label
                                      className="block text-gray-700 text-sm font-bold"
                                      htmlFor="deleted"
                                    >
                                      Borrado
                                    </label>
                                    <select
                                      className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      id="deleted"
                                      value={deleted}
                                      onChange={(e) =>
                                        setDeleted(parseInt(e.target.value))
                                      }
                                    >
                                      <option value={0}>No</option>
                                      <option value={1}>Si</option>
                                    </select>
                                  </div>
                                )}
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 justify-center flex sm:px-6">
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => {
                          clearFields();
                          setOpen(false);
                        }}
                      >
                        <img src={door} className="w-5" />
                        CERRAR
                      </button>
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => handleSubmit()}
                      >
                        <img src={floppy} className="w-8" />
                        GUARDAR
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <h1 className="ml-5 mt-5 text-3xl secondary-color title font-family-thin">
          Sucursales
        </h1>
        <DateComponent className="sm:block hidden" />
        <button
          type="button"
          className="relative right-[25px] sm:right-[50px] bottom-[10px] inline-flex w-[78px] float-right m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
          style={{ alignItems: "center" }}
          onClick={() => {
            setAction("create");
            setOpen(true);
          }}
        >
          <img src={branchimg} className="w-16" />
          CREAR SUCURSAL
        </button>

        <div className="w-[95%] container-95 p-5 mx-auto overflow-auto">
          <table className="w-[95%] table-auto mx-auto w-full text-xs">
            <thead>
              <tr>
                <th className="uppercase">Id</th>
                <th className="uppercase">Descripcion</th>
                <th className="uppercase">Empresa</th>
                {user.roleId == 1 && <th className="uppercase">Borrado</th>}
                <th className="uppercase">Fecha Alta</th>
                <th className="uppercase">Accion</th>
              </tr>
            </thead>
            <tbody>
              {isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={8}>
                    <Lottie
                      animationData={loadingAnimation}
                      style={{ width: 100, height: 100, margin: "0 auto" }}
                      loop={true}
                    />
                  </td>
                </tr>
              )}
              {branches.length > 0 &&
                !isFetching &&
                branches?.map((branch) => {
                  return (
                    <tr key={branch.id}>
                      <td>{branch.id}</td>
                      <td>{branch.description}</td>
                      <td>{branch.company.name}</td>
                      {user.roleId == 1 && (
                        <td>{branch.deleted ? "Si" : "No"}</td>
                      )}
                      <td>
                        {branch.createdAt
                          .substr(0, 10)
                          .split("-")
                          .reverse()
                          .join("/")}
                      </td>
                      <td className="text-center">
                        <button
                          className="bg-transparent"
                          onClick={() => {
                            editBranch(branch.id);
                          }}
                        >
                          <FontAwesomeIcon
                            className="m-1 cursor-pointer secondary-color"
                            icon={faPen}
                          />
                        </button>
                        <button
                          className="bg-transparent"
                          onClick={() => {
                            deleteBranch(branch.id);
                          }}
                        >
                          <FontAwesomeIcon
                            className="m-1 cursor-pointer secondary-color"
                            icon={faTrash}
                          />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              {branches.length <= 0 && !isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={5} className="text-center">
                    Sin registros (0)
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <Pagination
            path="sucursales"
            page={page}
            pages={pages}
            total={total}
          />
        </div>
      </Layout>
    </>
  );
};

export default Branches;
