import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { axiosAuth, axiosNoAuth } from "../utils/axios";
import logo from "../assets/img/logo-big.jpg";
import { numberWithCommas, numeroALetras } from "../utils/constants";
import { setToken, setUser } from "../store/slices/mainSlice";
import { usePDF } from "react-to-pdf";
import axios from "axios";
import logocusmas from "../assets/img/logo.png";
import logoapp from "../assets/img/logo-app.png";


function formatDate(dateString) {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses en JavaScript van de 0 a 11
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

const CollectionPDF = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { collectionId } = useParams();
  const [collection, setCollection] = useState([]);
  const date = new Date().toLocaleDateString();
  const [dateObj, setDateObj] = useState(new Date());
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Setiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  const { toPDF, targetRef } = usePDF({
    filename: "RECIBO_" + collectionId + "_" + date + ".pdf",
  });

  const fetchCollections = async () => {
    let collectionRequest = await axiosAuth("/collection/" + collectionId);

    if (collectionRequest.data.error) {
      if (collectionRequest.data.message === "token") {
        await axiosAuth.post("/logout");
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    while (String(collectionRequest.data.collection.number).length < 7) {
      collectionRequest.data.collection.number =
        "0" + String(collectionRequest.data.collection.number);
    }

    setCollection(collectionRequest.data.collection.rows[0]);

     setTimeout(function () {
       toPDF();
       setTimeout(function () {
         window.close();
       }, 1000);
     }, 2000);
  };

  useEffect(() => {
    fetchCollections();
  }, []);

  return (
    <>
      <div
        style={{
          fontFamily: "Arial !important",
          width: "765px",
          backgroundColor: "white",
        }}
        className="p-2"
        ref={targetRef}
      >
        <div>
          <div className="flex flex-row" style={{ border: "1px solid gray" }}>
            <div
              className="w-[43.3%] p-1 text-center flex flex-row justify-around items-center"
              style={{ borderRight: "1px solid gray" }}
            >
              <img src={logo} className="w-20 h-[86]" />
              <img src={logoapp} className="w-20 h-[86]" />
              <img src={logocusmas} className="w-24 h-[56]" />
            </div>
            <div
              className="w-[28.3%] p-4 flex flex-col text-center font-bold text-[10px]"
              style={{ borderRight: "1px solid gray" }}
            >
              <p style={{ lineHeight: 1.2 }}>OCUS S.A.</p>
              <p style={{ lineHeight: 1.2 }}>RUC: 80090280-7</p>
              <p style={{ lineHeight: 1.2 }}>TELEFONO: +595 984 317 179</p>
              <p style={{ lineHeight: 1.2 }}>Vicente Paul c/ Carmen de Peña</p>
              <p style={{ lineHeight: 1.2 }}>Itaugua - Paraguay</p>
            </div>
            <div className="w-[28.3%] p-4 flex flex-col text center">
              <h3 className="text-center">
                <b>RECIBO DE DINERO</b>
              </h3>
              <p className="text-center">
                {collection?.box?.expeditionPoint} {collection?.number}
              </p>
            </div>
          </div>
          <div
            className="flex flex-row p-2"
            style={{
              borderBottom: "1px solid gray",
              borderLeft: "1px solid gray",
              borderRight: "1px solid gray",
            }}
          >
            <div className="w-[65%]">
              <p className="text-[10px]">
                <b>FECHA:</b>{" "}
                <i>
                  {formatDate(collection.createdAt)}
                </i>
              </p>
              <p className="text-[10px]">
                <b>RECIBI(MOS) DE:</b> <i>{collection?.client?.name}</i>
              </p>
              <p className="text-[10px]">
                <b>RUC:</b> <i>{collection?.client?.ruc}</i>
              </p>
              <p className="text-[10px]">
                <b>LA CANTIDAD DE GUARANIES:</b>{" "}
                <i>{numeroALetras(collection?.amount)}</i>
              </p>
            </div>
            <div className="w-[35%]">
              <h1 className="text-lg text-right mt-3">
                <b>GS.</b> {numberWithCommas(collection?.amount)}
              </h1>
            </div>
          </div>
          <div
            className="text-center pb-3 mt-[-8px]"
            style={{
              borderBottom: "1px solid gray",
              borderLeft: "1px solid gray",
              borderRight: "1px solid gray",
            }}
          >
            <h1 className="uppercase underline text-[11px] mt-2">
              <b>Detalle de las facturas cobradas</b>
            </h1>
            <table className="collections-table w-[60%] m-auto mt-2">
              <thead>
                <tr style={{ borderBottom: "2px solid gray", lineHeight: 2.5 }}>
                  <td className="text-center text-[11px] font-bold">NUMERO</td>
                  <td className="text-center text-[11px] font-bold">FECHA</td>
                  <td className="text-center text-[11px] font-bold">COBRADO</td>
                </tr>
              </thead>
              <tbody>
                {collection?.collectionsdetails?.map((collectiondetail) => {
                  return (
                    <tr style={{ lineHeight: 2.5 }}>
                      <td className="text-center text-[11px]">
                        {collectiondetail?.due?.sale?.expeditionPoint}{" "}
                        {collectiondetail?.due?.sale?.number}
                      </td>
                      <td className="text-center text-[11px]">
                        {collectiondetail?.due?.createdAt
                          .substr(0, 10)
                          .split("-")
                          .reverse()
                          .join("/")}
                      </td>
                      <td className="text-center text-[11px]">
                        {numberWithCommas(collectiondetail?.amount)}
                      </td>
                    </tr>
                  );
                })}

                <tr className="relative" style={{ lineHeight: 2 }}>
                  <td className="text-center text-[13px] font-bold" colSpan={2}>
                    TOTAL COBRADO
                  </td>
                  <td className="text-center text-[13px]">
                    <b>{numberWithCommas(collection?.amount)}</b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            className="text-center flex flex-col pb-2"
            style={{
              borderBottom: "1px solid gray",
              borderLeft: "1px solid gray",
              borderRight: "1px solid gray",
            }}
          >
            <h1 className="underline text-[11px] mt-1">
              <b>DESCRIPCION DE LOS VALORES RECIBIDOS</b>
            </h1>
            <h3 className="text-left text-[11px] ml-3">TRANSFERENCIAS</h3>
            <p className="text-left text-[11px] ml-3">
              Transferencia bancaria recibida en la cuenta Nro. 4887331 Banco
              VISION por {numeroALetras(collection?.amount)}.
            </p>
            <div className="flex flex-row justify-end mr-4 mt-7">
              <div>
                <h1 className="text-[12px]">
                  <b>OCUS S.A.</b>
                </h1>
                <p className="text-[12px]">RUC 80090280-7</p>
                <p
                  className="underline relative top-[-18px]"
                  style={{ height: 3 }}
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </p>

                <p className="text-[12px]">
                  {dateObj.getDate()} {months[dateObj.getMonth()]}{" "}
                  {dateObj.getFullYear()}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CollectionPDF;
