import React, { useState, Fragment, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Dialog, Transition } from "@headlessui/react";
import Layout from "../layout";
import { axiosAuth } from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser } from "../store/slices/mainSlice";
import { useLocation, useParams } from "react-router-dom";
import Pagination from "../components/pagination";
import DateComponent from "../components/date";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/animations/loading.json";
import city from "../assets/img/city.png";
import door from "../assets/img/door.png";
import floppy from "../assets/img/floppy.png";

const Cities = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.main.user);

  const [isFetching, setIsFetching] = useState(false);
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState("edit");
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);

  const { page } = useParams();

  const [cities, setCities] = useState([]);

  const [cityId, setCityId] = useState(0);
  const [name, setName] = useState("");
  const [deleted, setDeleted] = useState(0);

  async function fetchCities() {
    let pageQuery = page == undefined ? 1 : page;

    setIsFetching(true);

    let cities = await axiosAuth.get("/cities?page=" + pageQuery);

    if (cities.data.error) {
      if (cities.data.message === "token") {
        await axiosAuth.post("/logout");
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    setIsFetching(false);

    setCities(cities.data.rows);
    setPages(cities.data.pages);
    setTotal(cities.data.count);
  }

  useEffect(() => {
    fetchCities();
  }, []);

  useEffect(() => {
    fetchCities();
  }, [location]);

  let editCity = (cityId) => {
    setAction("edit");
    setCityId(cityId);

    let city = cities.filter((city) => city.id === cityId);

    setName(city[0].name);
    setDeleted(city[0].deleted);
    setOpen(true);
  };

  const clearFields = () => {
    setName("");
    setCityId(0);
    setDeleted(0);
  };

  const handleSubmit = async () => {
    if (action === "edit") {
      let newCities = [];

      let updateCityRequest = await axiosAuth.put("/cities", {
        id: cityId,
        name: name,
        deleted: deleted,
      });

      if (updateCityRequest.data.error) {
        if (updateCityRequest.data.message === "token") {
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      cities.map((city) => {
        if (city.id == cityId) {
          newCities.push(updateCityRequest.data);
        } else {
          newCities.push(city);
        }
        return "";
      });

      setCities(newCities);
    } else {
      let newCities = [...cities];

      let createCityRequest = await axiosAuth.post("/cities", {
        name,
        deleted,
      });

      if (createCityRequest.data.error) {
        if (createCityRequest.data.message === "token") {
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      newCities.push(createCityRequest.data);
      setCities(newCities);
    }

    setOpen(false);
  };

  const deleteCity = async (id) => {
    if (window.confirm("Desea eliminar esta ciudad ?")) {
      let deleteCityRequest = await axiosAuth.delete("/cities", {
        params: { id },
      });

      if (deleteCityRequest.data.error) {
        if (deleteCityRequest.data.message === "token") {
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      let newCities = [];

      cities.map((city) => {
        if (city.id !== id) {
          newCities.push(city);
        } else {
          if (user.roleId == 1) {
            city.deleted = 1;
            newCities.push(city);
          }
        }
        return "";
      });

      setCities(newCities);
    }
  };

  return (
    <>
      <Layout>
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              clearFields();
              setOpen(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto align-items-center justify-center items-center flex">
              <div className="flex justify-center p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-3xl bg-white text-left shadow-xl transition-all w-[280px]">
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-0">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 sm:text-left">
                          <Dialog.Title
                            as="h1"
                            className="text-center font-regular leading-6 primary-color text-3xl"
                          >
                            {action === "edit" ? "Editar" : "Crear"} ciudad
                          </Dialog.Title>
                          <div className="mt-5">
                            <div className="w-full max-w-xs">
                              <form className="bg-white rounded">
                                {action === "edit" && (
                                  <div className="mb-4">
                                    <label
                                      className="block text-gray-700 text-sm font-bold"
                                      htmlFor="id"
                                    >
                                      Id
                                    </label>
                                    <input
                                      readOnly
                                      className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      id="id"
                                      type="text"
                                      value={cityId}
                                    />
                                  </div>
                                )}
                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="description"
                                  >
                                    Nombre
                                  </label>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                    id="description"
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                  />
                                </div>
                                {user.roleId == 1 && (
                                  <div className="mb-4">
                                    <label
                                      className="block text-gray-700 text-sm font-bold"
                                      htmlFor="deleted"
                                    >
                                      Borrado
                                    </label>
                                    <select
                                      className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      id="deleted"
                                      value={deleted}
                                      onChange={(e) =>
                                        setDeleted(parseInt(e.target.value))
                                      }
                                    >
                                      <option value={0}>No</option>
                                      <option value={1}>Si</option>
                                    </select>
                                  </div>
                                )}
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 justify-center flex sm:px-6">
                      
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => handleSubmit()}
                      >
                        <img src={floppy} className="w-8" />
                        GUARDAR
                      </button>
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => {
                          clearFields();
                          setOpen(false);
                        }}
                      >
                        <img src={door} className="w-5" />
                        CERRAR
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <h1 className="ml-5 mt-5 text-3xl secondary-color title font-family-thin">
          Ciudades
        </h1>
        <DateComponent className="sm:block hidden" />
        <button
          type="button"
          className="relative right-[25px] sm:right-[50px] bottom-[10px] inline-flex w-[68px] float-right m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
          style={{ alignItems: "center" }}
          onClick={() => {
            setAction("create");
            setOpen(true);
          }}
        >
          <img src={city} className="w-8" />
          CREAR CIUDAD
        </button>

        <div className="w-[95%] p-5 mx-auto overflow-auto sm:max-h-[70vh] max-h-[40vh] mt-[-50px]">
          <table className="w-[95%] table-auto mx-auto w-full text-[10px] support-table">
            <thead>
              <tr className="bg-gray-500 h-8">
                <th className="uppercase !text-white">Id</th>
                <th className="uppercase w-80 !text-white">Nombre</th>
                {user.roleId == 1 && <th className="uppercase !text-white">Borrado</th>}
                <th className="uppercase !text-white">Fecha Alta</th>
                <th className="uppercase !text-white">Accion</th>
              </tr>
            </thead>
            <tbody>
              {isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={8}>
                    <Lottie
                      animationData={loadingAnimation}
                      style={{ width: 100, height: 100, margin: "0 auto" }}
                      loop={true}
                    />
                  </td>
                </tr>
              )}
              {cities.length > 0 &&
                !isFetching &&
                cities?.map((city) => {
                  return (
                    <tr key={city.id}>
                      <td>{city.id}</td>
                      <td className="text-left">{city.name}</td>
                      {user.roleId == 1 && (
                        <td>{city.deleted ? "Si" : "No"}</td>
                      )}
                      <td>
                        {city.createdAt
                          .substr(0, 10)
                          .split("-")
                          .reverse()
                          .join("/")}
                      </td>
                      <td className="text-center">
                        <button
                          className="bg-transparent"
                          onClick={() => {
                            editCity(city.id);
                          }}
                        >
                          <FontAwesomeIcon
                            className="m-1 cursor-pointer secondary-color"
                            icon={faPen}
                          />
                        </button>
                        <button
                          className="bg-transparent"
                          onClick={() => {
                            deleteCity(city.id);
                          }}
                        >
                          <FontAwesomeIcon
                            className="m-1 cursor-pointer secondary-color"
                            icon={faTrash}
                          />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              {cities.length <= 0 && !isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={5} className="text-center">
                    Sin registros (0)
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <Pagination pages={pages} page={page} total={total} path="ciudades" />
        </div>
      </Layout>
    </>
  );
};

export default Cities;
