import React, { useState, Fragment, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Dialog, Transition } from "@headlessui/react";
import Layout from "../layout";
import { axiosAuth } from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser, setTitle } from "../store/slices/mainSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Pagination from "../components/pagination";
import DateComponent from "../components/date";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/animations/loading.json";
import ComboBox from "../components/combobox";
import door from "../assets/img/door.png";
import floppy from "../assets/img/floppy.png";
import printer from "../assets/img/printer.png";
import documenticon from "../assets/img/document.webp";
import pen from "../assets/img/pen.png";
import pdf from "../assets/img/pdf.png";
import ver from "../assets/img/eye.png";

import useViewport from "../utils/useViewport";

const SupportDetail = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);
  const [openConvertasion, setOpenConvertasion] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [action, setAction] = useState("edit");
  const { width } = useViewport();
  const navigate = useNavigate();

  const { page } = useParams();
  const user = useSelector((state) => state.main.user);

  const [supportDetail, setSupportDetail] = useState([]);
  const [history, setHistory] = useState([]);
  const [users, setUsers] = useState([]);
  const [clients, setClients] = useState([]);
  const [divisions ,setDivisions] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [pending, setPending] = useState(0);
  const [completed, setCompleted] = useState(0);

  const [inProccess, setInProccess] = useState(0);
  const [unaproved, setUnaproved] = useState(0);
  const [urgent, setUrgent] = useState(0);
  const [approved, setApproved] = useState(0);

  const [state, setState] = useState(0);

  const [totalHistory, setTotalHistory] = useState(0);
  const [pendingHistory, setPendingHistory] = useState(0);
  const [completedHistory, setCompletedHistory] = useState(0);
  const [unaprovedHistory, setUnaprovedHistory] = useState(0);

  const [supportDetailId, setSupportDetailId] = useState(0);
  const [companyId, setCompanyId] = useState(0);
  const [supportId, setSupportId] = useState(0);
  const [description, setDescription] = useState("");
  const [observation, setObservation] = useState("");
  const [convertasion, setConvertasion] = useState(""); 
  const [comment, setComment] = useState("");
  const [userId, setUserId] = useState(0);
  const [divisionsId, setDivisionsId] = useState(0);
  const [clientIdFilter, setClientIdFilter] = useState(0);
  const [stateFilterId, setStateFilterId] = useState(null);
  const [divisionsFilterId,setDivisionsFilterId] = useState();
  const [divisionsFilterIdEdit,setDivisionsFilterIdEdit] = useState();
  const [dateFromFilter, setDateFromFilter] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()-31)
      .toLocaleDateString()
      .replaceAll("/", "-")
      .split("-")
      .map((d) => (String(d).length == 1 ? "0" + String(d) : d))
      .reverse()
      .join("-")
  );
  const [dateToFilter, setDateToFilter] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
      .toLocaleDateString()
      .replaceAll("/", "-")
      .split("-")
      .map((d) => (String(d).length == 1 ? "0" + String(d) : d))
      .reverse()
      .join("-")
  );

  async function fetchHistory(clientIdParam) {
    let historyRequest = await axiosAuth.get(
      "/supportdetail?page=1&clientId=" + clientIdParam + "&history=true"
    );

    if (historyRequest.data.error) {
      if (historyRequest.data.message === "token") {
        await axiosAuth.post("/logout");
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    setHistory(historyRequest.data.supportdetails.rows);
    setTotalHistory(historyRequest.data.supportdetails.count);
    setPendingHistory(historyRequest.data.supportdetails.pending);
    setCompletedHistory(historyRequest.data.supportdetails.completed);
    setUnaprovedHistory(historyRequest.data.supportdetails.unaproved);
    setOpenHistory(true);
  }

  const viewConvertasion = (supportDetailId) => {
    setSupportDetailId(supportDetailId);

    let supportdetail = supportDetail.filter(
      (supportDetailObj) => supportDetailObj.id == supportDetailId
    )[0];
  
    setConvertasion(supportdetail.convertasion);
    setOpenConvertasion(true);
  };
  const calculateDate30DaysBefore = () => {
    // Obtiene el último día del mes actual
    const today = new Date();
    const thirtyDaysBefore = new Date(today);
    thirtyDaysBefore.setDate(today.getDate() - 30);
    
    const year = thirtyDaysBefore.getFullYear();
    const month = String(thirtyDaysBefore.getMonth() + 1).padStart(2, '0');
    const day = String(thirtyDaysBefore.getDate()).padStart(2, '0');

    // Formatea la fecha como AÑO-MES-DIA
    return `${year}-${month}-${day}`;
};
const calculateDate = () => {
  // Obtiene el último día del mes actual
  const today = new Date();
  const thirtyDaysBefore = new Date(today);
  thirtyDaysBefore.setDate(today.getDate());
  
  const year = thirtyDaysBefore.getFullYear();
  const month = String(thirtyDaysBefore.getMonth() + 1).padStart(2, '0');
  const day = String(thirtyDaysBefore.getDate()).padStart(2, '0');

  // Formatea la fecha como AÑO-MES-DIA
  return `${year}-${month}-${day}`;
};

  async function fetchSupportDetail() {
    
    let pageQuery = page === undefined ? 1 : page;

  //let dateFromFilter = calculateDate30DaysBefore();
  setDateFromFilter(dateFromFilter); 
  //let dateToFilter = calculateDate();
 setDateToFilter(dateToFilter);

    setIsFetching(true);
     let divi =divisionsFilterId>0? "&divisionId="+  divisionsFilterId:"";

    let supportDetailRequest = await axiosAuth.get(
      "/supportdetail?page=" +
        pageQuery +
        "&clientId=" +
        clientIdFilter +
        "&state=" +
        stateFilterId +
        "&dateFrom=" +
       // dateFromFilter +
       dateFromFilter+
        "&dateTo=" +
        dateToFilter +
        "&history=false"+
        divi

    );

    if (supportDetailRequest.data.error) {
      if (supportDetailRequest.data.message === "token") {
        await axiosAuth.post("/logout");
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    setIsFetching(false);

    supportDetailRequest.data.supportdetails.rows.map((supportdetail) => {
      supportdetail.pendingStateUser =
        supportDetailRequest.data.users.rows.filter(
          (user) => supportdetail.pendingStateUserId == user.id
        )[0];
      supportdetail.inProccessStateUser =
        supportDetailRequest.data.users.rows.filter(
          (user) => supportdetail.inProccessStateUserId == user.id
        )[0];
      supportdetail.endedStateUser =
        supportDetailRequest.data.users.rows.filter(
          (user) => supportdetail.endedStateUserId == user.id
        )[0];
      supportdetail.unaprovedStateUser =
        supportDetailRequest.data.users.rows.filter(
          (user) => supportdetail.unaprovedStateUserId == user.id
        )[0];
      supportdetail.urgentStateUser =
        supportDetailRequest.data.users.rows.filter(
          (user) => supportdetail.urgentStateUserId == user.id
        )[0];
      supportdetail.aprovedStateUser =
        supportDetailRequest.data.users.rows.filter(
          (user) => supportdetail.aprovedStateUserId == user.id
        )[0];
    });

    setSupportDetail(supportDetailRequest.data.supportdetails.rows);
    setTotal(supportDetailRequest.data.supportdetails.count);
    setPages(supportDetailRequest.data.supportdetails.pages);

    setPending(supportDetailRequest.data.supportdetails.pending);
    setInProccess(supportDetailRequest.data.supportdetails.inproccess);
    setCompleted(supportDetailRequest.data.supportdetails.completed);
    setUnaproved(supportDetailRequest.data.supportdetails.unaproved);
    setUrgent(supportDetailRequest.data.supportdetails.urgent);
    setApproved(supportDetailRequest.data.supportdetails.approved);

    setUsers(supportDetailRequest.data.users.rows);
    setClients(supportDetailRequest.data.clients.rows);
    setDivisions(supportDetailRequest.data.division.rows);

    console.log(supportDetailRequest.data);
  
  }

  useEffect(() => {
    fetchSupportDetail();
    dispatch(setTitle("Soporte - Seguimiento"));
  }, []);

  useEffect(() => {
    fetchSupportDetail();
  }, [location, clientIdFilter, stateFilterId,divisionsFilterId]);

  const editSupportDetail = (supportDetailId) => {
    
    setAction("edit");
    setSupportDetailId(supportDetailId);

    let supportdetail = supportDetail.filter(
      (supportDetailObj) => supportDetailObj.id == supportDetailId
    )[0];
  

    setCompanyId(supportdetail.companyId);
    setSupportId(supportdetail.supportId);
    setDescription(supportdetail.description);
    setObservation(supportdetail.observation);
    setComment(supportdetail.comment);
    setState(supportdetail.state);
    setUserId(supportdetail.support.userId);
    setDivisionsFilterIdEdit(supportdetail.divisionsId);
    setOpen(true);
  };
 

  const clearFields = () => {
    setSupportDetailId(0);
    setCompanyId(0);
    setSupportId(0);
    setDescription("");
    setObservation("");
    setComment("");
    setState(0);
    setUserId(0);
  };

  const handleSubmit = async () => {
    if (comment === "") {
      alert("Debes completar los campos para continuar.");
      return false;
    }

    if (action === "edit") {
      let id = supportDetailId;
      let newSupportDetailObj = [];

      let updateSupportDetailRequest = await axiosAuth.put("/supportdetail", {
        id,
        companyId,
        supportId,
        description,
        observation,
        comment,
        state,
        userId,
        divisionsFilterIdEdit,
      });

      if (updateSupportDetailRequest.data.error) {
        if (updateSupportDetailRequest.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }
      fetchSupportDetail();
     /*  supportDetail.map((supportdetail) => {
        if (supportdetail.id == id) {
          console.log(supportdetail);
          newSupportDetailObj.push(updateSupportDetailRequest.data);
        } else {
          newSupportDetailObj.push(supportdetail);
        }
        return "";
      }); */
      //console.log(newSupportDetailObj);

      //setSupportDetail(newSupportDetailObj);
    } else {
      let createSupportDetailRequest = await axiosAuth.post("/supportdetail", {
        companyId,
        supportId,
        description,
        observation,
        comment,
        state,
        userId,
      });

      if (createSupportDetailRequest.data.error) {
        if (createSupportDetailRequest.data.message === "token") {
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      let newSupportDetail = [...supportDetail];
      newSupportDetail.push(createSupportDetailRequest.data);
      setSupportDetail(newSupportDetail);
    }

    setOpen(false);
  };

  const deleteSupportDetail = async (supportDetailId) => {
    if (window.confirm("Desea eliminar esta marca ?")) {
      let SupportDetailDeleteRequest = await axiosAuth.delete(
        "/supportdetail",
        {
          params: { id: supportDetailId },
        }
      );

      if (SupportDetailDeleteRequest.data.error) {
        if (SupportDetailDeleteRequest.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      let newSupportDetailObj = [];

      supportDetail.map((supportDetail) => {
        if (supportDetail.id !== supportDetailId) {
          newSupportDetailObj.push(supportDetail);
        } else {
          if (user.roleId == 1) {
            supportDetail.deleted = 1;
            newSupportDetailObj.push(supportDetail);
          }
        }
        return "";
      });

      setSupportDetail(newSupportDetailObj);
    }
  };

  const printDiv = (nombreDiv) => {
    var contenido = document.getElementById(nombreDiv).innerHTML;
    var contenidoOriginal = document.body.innerHTML;

    document.body.innerHTML = contenido;

    window.print();

    document.body.innerHTML = contenidoOriginal;
    //window.location.href = window.location.href;
  };

  const searchClients = async (search) => {
    const searchClientsRequest = await axiosAuth.get(
      "/searchClients?search=" + search
    );

    setClients(searchClientsRequest.data.clients.rows);
    
  };

  return (
    <>
      <Layout>
      <Transition.Root show={openConvertasion} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              clearFields();
              setOpen(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto align-items-center justify-center items-center flex pb-5">
              <div className="flex justify-center p-4 text-center sm:p-0 !w-[85%]">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel
                    className="relative transform overflow-hidden rounded-3xl bg-white text-left shadow-xl transition-all sm:my-8 w-[100%]"
                    style={{
                      marginTop: 70,
                    }}
                  >
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-0 w-full">
                      <div className="sm:flex sm:items-start w-full">
                        <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                          <Dialog.Title
                            as="h1"
                            className="text-center text-lg font-regular leading-6 primary-color text-3xl"
                          >
                            Conversacion{" "}
                            
                                  <textarea
                                    className="input-bg-color appearance-none border !text-[10px] 
                                    w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-80"
                                    readOnly
                                    disabled
                                    id="description"
                                    style={{
                                      backgroundColor: "gainsboro",
                                      color: "black",
                                      border: "1px solid grey",
                                    }}
                                    value={
                                      convertasion 
                                    }
                                    
                                  ></textarea>
                          </Dialog.Title>

                         

                        
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-2 py-2 justify-center flex sm:px-6">
                      <button
                        type="button"
                        className="float-left inline-flex w-[68px] h-[60px] ml-[-0px] flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => {
                          setOpenConvertasion(false);
                        }}
                      >
                        <img src={door} className="w-5" />
                        SALIR
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
                  
                  

                         
          </Dialog>
        </Transition.Root>

        <Transition.Root show={openHistory} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              clearFields();
              setOpen(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto align-items-center justify-center items-center flex pb-5">
              <div className="flex justify-center p-4 text-center sm:p-0 !w-[85%]">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel
                    className="relative transform overflow-hidden rounded-3xl bg-white text-left shadow-xl transition-all sm:my-8 w-[100%]"
                    style={{
                      marginTop: 70,
                    }}
                  >
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-0 w-full">
                      <div className="sm:flex sm:items-start w-full">
                        <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                          <Dialog.Title
                            as="h1"
                            className="text-center text-lg font-regular leading-6 primary-color text-3xl"
                          >
                            Historial{" "}
                            {history.length > 0 &&
                              history[0].support?.client?.name}
                          </Dialog.Title>

                          <div className="text-center flex justify-around my-2">
                            <label className="text-xs">
                              <span className="font-bold">Total:</span>{" "}
                              {totalHistory}
                            </label>
                            <label className="text-xs">
                              <span className="font-bold">Pend.:</span>{" "}
                              {pendingHistory}
                            </label>
                            <label className="text-xs">
                              <span className="font-bold">Completados:</span>{" "}
                              {completedHistory}
                            </label>
                            <label className="text-xs">
                              <span className="font-bold">Sin aprobar:</span>{" "}
                              {unaprovedHistory}
                            </label>
                          </div>

                          <div className="h-[400px] overflow-auto">
                            <table className="w-[95%] table-auto mx-auto w-full text-xs sales-table">
                              <thead>
                                {width > 1024 ? (
                                  <tr>
                                    <th className="uppercase">Fecha Alta</th>
                                    <th className="uppercase w-36">Contacto</th>
                                    <th className="uppercase w-[180px]">
                                      Descripcion
                                    </th>
                                    <th className="uppercase">Observacion</th>
                                    <th className="uppercase">Comentario</th>
                                    <th className="uppercase w-16">Estado</th>
                                    <th className="uppercase">Usuario</th>
                                    {/* <th className="uppercase">Modificado</th>
                                  <th className="uppercase">Historial</th> */}
                                  </tr>
                                ) : (
                                  <></>
                                )}
                              </thead>
                              <tbody>
                                {history.length > 0 &&
                                  history.map((supportdetail) => {
                                    if (width > 1024) {
                                      return (
                                        <tr
                                          key={supportdetail.id}
                                          className={`${
                                            supportdetail.state == 0
                                              ? "bg-red-300"
                                              : supportdetail.state == 1
                                              ? "bg-teal-300"
                                              : supportdetail.state == 2
                                              ? "bg-green-300"
                                              : supportdetail.state == 3
                                              ? "bg-amber-300"
                                              : supportdetail.state == 4
                                              ? "bg-red-800 font-white"
                                              : supportdetail.state == 5
                                              ? "bg-yellow-300"
                                              : ""
                                          } h-24`}
                                        >
                                          <td>
                                            Registrado por:{" "}
                                            {
                                              supportdetail?.support?.user
                                                ?.fullname
                                            }
                                            <br />
                                            {String(supportdetail.createdAt)
                                              .substr(0, 10)
                                              .split("-")
                                              .reverse()
                                              .join("/")}
                                            &nbsp;
                                            {String(
                                              supportdetail.createdAt
                                            ).substr(11, 5)}{" "}
                                            <br />
                                            Terminado por:
                                            {
                                              supportdetail?.endedStateUser
                                                ?.fullname
                                            }
                                            <br />
                                            En fecha:{" "}
                                            {supportdetail?.endedStateDate !=
                                              "0000-00-00 00:00:00" &&
                                              supportdetail?.endedStateDate}
                                          </td>
                                          <td className="text-left">
                                            <a
                                              target="_blank"
                                              href={
                                                supportdetail.support.contact.phone.replaceAll(
                                                  " ",
                                                  ""
                                                ) != "" &&
                                                supportdetail.support.contact.phone.replaceAll(
                                                  " ",
                                                  ""
                                                ) != " "
                                                  ? "https://wa.me/+595" +
                                                    supportdetail.support.contact.phone
                                                      ?.replaceAll(" ", "")
                                                      .replace("+", "")
                                                      .replace("595", "")
                                                  : "/#"
                                              }
                                            >
                                              {
                                                supportdetail.support.contact
                                                  .name
                                              }
                                              {" - "}
                                              {
                                                supportdetail.support.contact
                                                  .phone
                                              }
                                            </a>
                                          </td>
                                          <td className="text-left w-[180px]">
                                            {supportdetail.description}
                                          </td>
                                          <td className="text-left">
                                            {supportdetail.observation}
                                          </td>
                                          <td className="text-left">
                                            {supportdetail.comment}
                                          </td>
                                          <td className="text-center">
                                            {supportdetail.state == 0
                                              ? "Pendiente"
                                              : supportdetail.state == 1
                                              ? "En proceso"
                                              : supportdetail.state == 2
                                              ? "Terminado"
                                              : supportdetail.state == 3
                                              ? "No aprobado"
                                              : supportdetail.state == 4
                                              ? "URGENTE"
                                              : supportdetail.state == 5
                                              ? "Aprobado"
                                              : ""}
                                          </td>

                                          <td>
                                            {supportdetail?.user?.fullname}
                                          </td>
                                          <td></td>
                                          <td>
                                            {/* Pendiente por:{" "}
                          {supportdetail?.pendingStateUser?.fullname}
                          <br />
                          En fecha:{" "}
                          {supportdetail?.pendingStateDate !=
                            "0000-00-00 00:00:00" &&
                            supportdetail?.pendingStateDate}
                          <br />
                          En proceso por:
                          {supportdetail?.inProcessStateUser?.fullname}
                          <br />
                          En fecha: {supportdetail?.inProcessStateDate}
                          <br /> */}

                                            {/* <br />
                          No aprobado por:
                          {supportdetail?.unaprovedStateUser?.fullname}
                          <br />
                          En fecha:{" "}
                          {supportdetail?.unaprovedStateDate !=
                            "0000-00-00 00:00:00" &&
                            supportdetail?.unaprovedStateDate}
                          <br />
                          Urgente por:
                          {supportdetail?.urgentStateUser?.fullname}
                          <br />
                          En fecha:{" "}
                          {supportdetail?.urgentStateDate !=
                            "0000-00-00 00:00:00" &&
                            supportdetail?.urgentStateDate}
                          <br />
                          Aprobado por:
                          {supportdetail?.aprovedStateUser?.fullname}
                          <br />
                          En fecha:{" "}
                          {supportdetail?.aprovedStateDate !=
                            "0000-00-00 00:00:00" &&
                            supportdetail?.aprovedStateDate} */}
                                            <br />
                                          </td>
                                        </tr>
                                      );
                                    } else {
                                      return (
                                        <tr
                                          key={supportdetail.id}
                                          className={`${
                                            supportdetail.state == 0
                                              ? "bg-red-300"
                                              : supportdetail.state == 1
                                              ? "bg-teal-300"
                                              : supportdetail.state == 2
                                              ? "bg-green-300"
                                              : supportdetail.state == 3
                                              ? "bg-amber-300"
                                              : supportdetail.state == 4
                                              ? "bg-red-800 font-white"
                                              : supportdetail.state == 5
                                              ? "bg-yellow-300"
                                              : ""
                                          } block p-3`}
                                        >
                                          <label className="font-bold">
                                            Fecha Alta:{" "}
                                          </label>
                                          {String(supportdetail.createdAt)
                                            .substr(0, 10)
                                            .split("-")
                                            .reverse()
                                            .join("/")}{" "}
                                          {String(
                                            supportdetail.createdAt
                                          ).substr(11, 5)}
                                          <br />
                                          <label className="font-bold">
                                            Contacto:{" "}
                                          </label>
                                          <a
                                            target="_blank"
                                            href={
                                              supportdetail.support.contact.phone.replaceAll(
                                                " ",
                                                ""
                                              ) != "" &&
                                              supportdetail.support.contact.phone.replaceAll(
                                                " ",
                                                ""
                                              ) != " "
                                                ? "https://wa.me/+595" +
                                                  supportdetail.support.contact.phone
                                                    .replaceAll(" ", "")
                                                    .replace("+", "")
                                                    .replace("595")
                                                : "/#"
                                            }
                                          >
                                            {
                                              supportdetail?.support?.contact
                                                ?.name
                                            }
                                          </a>
                                          <br />
                                          <label className="font-bold">
                                            Descripcion:{" "}
                                          </label>
                                          {supportdetail.description}
                                          <br />
                                          <label className="font-bold">
                                            Observacion:{" "}
                                          </label>
                                          {supportdetail.observation}
                                          <br />
                                          <label className="font-bold">
                                            Comentario:{" "}
                                          </label>
                                          {supportdetail.comment}
                                          <br />
                                          <label className="font-bold">
                                            Estado:{" "}
                                          </label>
                                          {supportdetail.state == 0
                                            ? "Pendiente"
                                            : supportdetail.state == 1
                                            ? "En proceso"
                                            : supportdetail.state == 2
                                            ? "Terminado"
                                            : supportdetail.state == 3
                                            ? "No aprobado"
                                            : supportdetail.state == 4
                                            ? "URGENTE"
                                            : supportdetail.state == 5
                                            ? "Aprobado"
                                            : ""}
                                          <br />
                                          <label className="font-bold">
                                            REGISTRADO POR:{" "}
                                          </label>
                                          {
                                            supportdetail?.support?.user
                                              ?.fullname
                                          }
                                          <br />
                                          <label className="font-bold">
                                            MODIFICADO POR:{" "}
                                          </label>
                                          {supportdetail?.user?.fullname}
                                          <br />
                                          {/* Pendiente por:{" "}
                        {supportdetail?.pendingStateUser?.fullname}
                        <br />
                        En fecha:{" "}
                        {supportdetail?.pendingStateDate !=
                          "0000-00-00 00:00:00" &&
                          supportdetail?.pendingStateDate}
                        <br />
                        En proceso por:
                        {supportdetail?.inProcessStateUser?.fullname}
                        <br />
                        En fecha: {supportdetail?.inProcessStateDate}
                        <br /> */}
                                          Terminado por:
                                          {
                                            supportdetail?.endedStateUser
                                              ?.fullname
                                          }
                                          <br />
                                          En fecha:{" "}
                                          {supportdetail?.endedStateDate !=
                                            "0000-00-00 00:00:00" &&
                                            supportdetail?.endedStateDate}
                                          <br />
                                          {/* No aprobado por:
                        {supportdetail?.unaprovedStateUser?.fullname}
                        <br />
                        En fecha:{" "}
                        {supportdetail?.unaprovedStateDate !=
                          "0000-00-00 00:00:00" &&
                          supportdetail?.unaprovedStateDate}
                        <br />
                        Urgente por:
                        {supportdetail?.urgentStateUser?.fullname}
                        <br />
                        En fecha:{" "}
                        {supportdetail?.urgentStateDate !=
                          "0000-00-00 00:00:00" &&
                          supportdetail?.urgentStateDate}
                        <br />
                        Aprobado por:
                        {supportdetail?.aprovedStateUser?.fullname}
                        <br />
                        En fecha:{" "}
                        {supportdetail?.aprovedStateDate !=
                          "0000-00-00 00:00:00" &&
                          supportdetail?.aprovedStateDate}
                        <br /> */}
                                        </tr>
                                      );
                                    }
                                  })}
                                {history.length <= 0 && (
                                  <tr>
                                    <td></td>
                                    <td colSpan={10} className="text-center">
                                      Sin registros (0)
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-2 py-2 justify-center flex sm:px-6">
                      <button
                        type="button"
                        className="float-left inline-flex w-[68px] h-[60px] ml-[-0px] flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => {
                          setOpenHistory(false);
                        }}
                      >
                        <img src={door} className="w-5" />
                        SALIR
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              clearFields();
              setOpen(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto align-items-center justify-center items-center flex pb-5">
              <div className="flex justify-center p-4 text-center sm:p-0 !w-[60%]">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel
                    className="relative transform overflow-hidden bg-white text-left shadow-xl transition-all sm:my-8 w-[100%]"
                    style={{ marginTop: 55 }}
                  >
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-0 w-full">
                      <div className="sm:flex sm:items-start w-full">
                        <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                          <Dialog.Title
                            as="h1"
                            className="text-center font-regular leading-6 primary-color text-3xl"
                          >
                            Detalle {supportDetailId}
                          </Dialog.Title>
                          <div className="w-full mt-5">
                            <div className="w-full">
                              <form className="bg-white w-full">
                                <div className="mb-[1px] flex flex-row justify-start items-center">
                                  <label
                                    className="block text-gray-700 text-[10px] ml-3 font-bold uppercase"
                                    htmlFor="state"
                                  >
                                    Estado:
                                  </label>
                                  <select
                                    className="ml-2 input-bg-color appearance-none border w-full px-3 text-gray-700 focus:outline-none focus:shadow-outline h-[25px] w-[100%] sm:!w-[32%]"
                                    id="state"
                                    style={{ lineHeight: "15px" }}
                                    value={state}
                                    onChange={(e) =>
                                      setState(parseInt(e.target.value))
                                    }
                                  >
                                    <option>Seleccione un estado</option>
                                    <option value={0}>Pendiente</option>
                                    <option value={1}>En proceso</option>
                                    <option value={2}>Terminado</option>
                                    <option value={3}>No aprobado</option>
                                    <option value={4}>Urgente</option>
                                    <option value={5}>Aprobado</option>
                                  </select>
                                </div>

                                <div className="mb-2 flex flex-row justify-center items-center w-[100%] sm:w-[39%]">
                                  <label
                                    className="block text-gray-700 text-[10px] font-bold uppercase"
                                    htmlFor="deleted"
                                  >
                                    Asignado:
                                  </label>
                                  <ComboBox
                                    data={users}
                                    setData={setUserId}
                                    value={userId}
                                    placeholder="Usuario"
                                    className="h-[25px] ml-2 !w-[100%]"
                                  />
                                </div>
                                <div className="mb-2 flex flex-row justify-center items-center w-[100%] sm:w-[39%]">
                                <label
                                    className="block text-gray-700 text-[10px] font-bold uppercase"
                                    htmlFor="deleted"
                                  >
                                    Division:
                                  </label>
                                <select value={divisionsFilterIdEdit} className="input-bg-color mt-[1px] appearance-none border ml-2
                     w-full px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-sm h-[24px] w-[50%]"
                                  id="divisionsId" 
                                    onChange={(e) =>{
                                      setDivisionsFilterIdEdit(e.target.value);
                                    }
                                    }
                                     >
                                     <option  value={0}> 
                                       Seleccione Division
                                   </option>
                                   {divisions?.map((division) => (
                                     <option key={division.id} value={division.id}>
                                       {division.name}
                                     </option>
                                   ))}
                                        
                                     </select>
                                     </div>

                                <div className="mb-2 w-full">
                                  <label
                                    className="block text-gray-700 text-[10px] font-bold uppercase underline"
                                    htmlFor="description"
                                  >
                                    Descripcion
                                  </label>
                                  <textarea
                                    className="input-bg-color appearance-none border !text-[10px] w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-20"
                                    readOnly
                                    disabled
                                    id="description"
                                    style={{
                                      backgroundColor: "gainsboro",
                                      color: "black",
                                      border: "1px solid grey",
                                    }}
                                    value={
                                      description +
                                      " \nOBSERVACION: " +
                                      observation
                                    }
                                    onChange={(e) =>
                                      setDescription(e.target.value)
                                    }
                                  ></textarea>
                                </div>

                                <div className="mb-2">
                                  <label
                                    className="block text-gray-700 text-[10px] font-bold uppercase underline"
                                    htmlFor="comment"
                                  >
                                    Comentario
                                  </label>
                                  <textarea
                                    className="appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-[10px] h-20 bg-white"
                                    style={{ border: "1px solid grey" }}
                                    id="comment"
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                  ></textarea>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-2 py-2 justify-end flex sm:px-6">
                      <button
                        type="button"
                        className="float-left inline-flex w-[68px] h-[60px] m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-[10px] text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => handleSubmit()}
                      >
                        <img alt="" src={floppy} className="w-8" />
                        GUARDAR
                      </button>

                      <button
                        type="button"
                        className="float-left inline-flex w-[68px] h-[60px] ml-[-0px] flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-[10px] text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => {
                          setOpen(false);
                        }}
                      >
                        <img alt="" src={door} className="w-5" />
                        SALIR
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
        <div className="w-[100%]" style={{ borderBottom: "1px solid grey" }}>
          <div
            className="w-[70%] sm:w-[18%] sm:h-24 h-0"
            style={{ borderRight: "1px solid grey" }}
          >
            <h1 className="ml-5 sm:mt-5 !mt-2 !text-[18px] secondary-color title font-bold font-family-thin hidden sm:block">
              Soporte - Seguimiento
            </h1>
            <DateComponent className="hidden sm:block" />
          </div>
        </div>

        <div
          className="flex sm:flex-row flex-col justify-end items-center sm:border-none 
        border-slate-400 border-t-[1px] w-[85%] m-[auto] sm:pl-0 sm:pr-0 relative sm:right-[-15%] mt-[0px] sm:mt-[-65px]"
        >
          
          <div className="flex flex-col w-[100%] mt-1 sm:mt-0">
            <div className="flex flex-col min-w-[300px] w-[87.5%]">
              {/* <label
              className="block text-gray-700 text-sm font-bold"
              htmlFor="dateFromFilter"
            >
              Cliente:
            </label> */}
              <ComboBox
                data={clients}
                setData={setClientIdFilter}
                value={clientIdFilter}
                placeholder="Cliente"
                className="h-[25px] w-[100%] ml-[1px]"
                search={searchClients}
              />
            </div>
            <div className="flex flex-row">
              <div className="w-[99px]">
                {/* <label
              className="block text-gray-700 text-sm font-bold"
              htmlFor="dateFromFilter"
            >
              Fecha desde:
            </label> */}
                <input
                  type="date"
                  id="dateFromFilter"
                  name="dateFromFilter"
                  value={dateFromFilter}
                  className="input-bg-color mt-[1px] appearance-none border w-full 
                       py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-[24px]"
                  onChange={(e) => setDateFromFilter(e.target.value)}
                />
              </div>

              <div className="w-[99px]">
                {/* <label
              className="block text-gray-700 text-sm font-bold"
              htmlFor="dateToFilter"
            >
              Fecha hasta:
            </label> */}
                <input
                  type="date"
                  id="dateToFilter"
                  name="dateToFilter"
                  value={dateToFilter}
                  className="input-bg-color mt-[1px] appearance-none border w-full py-2  ml-1
                       px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-[24px]"
                  onChange={(e) => setDateToFilter(e.target.value)}
                  onBlur={() => fetchSupportDetail()}
                />
              </div>
              <div className="w-[140px]">
                {/* <label
              className="block text-gray-700 text-sm font-bold"
              htmlFor="stateFilter"
            >
              Estado:
            </label> */}
                <select
                  id="stateFilter"
                  name="stateFilter"
                  value={stateFilterId}
                  className="input-bg-color mt-[1px] appearance-none border ml-2
                     w-full px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-sm h-[24px] w-[50%]"
                  style={{ lineHeight: "13px" }}
                  onChange={(e) => setStateFilterId(e.target.value)}
                >
                  <option value={null}>Seleccione un estado</option>
                  <option value={0}>Pendiente</option>
                  <option value={1}>En proceso</option>
                  <option value={2}>Terminado</option>
                  <option value={3}>No aprobado</option>
                  <option value={4}>Urgente</option>
                  <option value={5}>Aprobado</option>
                </select>
              </div>

              
              <div className="w-[140px]">
              <select value={divisionsFilterId} className="input-bg-color mt-[1px] appearance-none border ml-2
                     w-full px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-sm h-[24px] w-[50%]"
                                  id="divisionsId" 
                                    onChange={(e) =>{
                                      setDivisionsFilterId(e.target.value);
                                    }
                                    }
                                     >
                                     <option  value={0}> 
                                       Seleccione Division
                                   </option>
                                   {divisions?.map((division) => (
                                     <option key={division.id} value={division.id}>
                                       {division.name}
                                     </option>
                                   ))}
                                        
                                     </select>
                
              </div>
            </div>
          </div>

          <div className="flex flex-col sm:flex-row w-[100%]">
            <div className="w-[100%] sm:w-[50%] mr-3 flex sm:flex-row flex-col ">
              <div className="flex sm:flex-col flex-row w-full justify-center sm:mt-0 mt-2 pl-12">
                <div className="mr-3 flex justify-start items-center text-[10px]">
                  <label className="font-bold mr-1 uppercase">Total: </label>
                  {total}
                </div>
                <div className="mr-3 flex justify-start items-center text-[10px]">
                  <label className="font-bold mr-1 whitespace-nowrap uppercase text-red-400">
                    Pendiente:{" "}
                  </label>
                  {pending}
                </div>
                <div className="mr-3 flex justify-start items-center text-[10px] ">
                  <label className="font-bold mr-1 uppercase text-red-700">
                    Urgentes:{" "}
                  </label>
                  {urgent}
                </div>
                <div className="mr-3 flex justify-start items-center text-[10px]">
                  <label className="font-bold mr-1 uppercase text-yellow-300">
                    Aprobados:{" "}
                  </label>
                  {approved}
                </div>
              </div>
            </div>

            <div className="w-[100%] sm:w-[50%] sm:flex-col flex-row flex ml-3 justify-center">
              <div className="mr-3 flex justify-start items-center text-[10px]">
                <label className="font-bold mr-1 whitespace-nowrap uppercase whitespace-nowrap text-blue-400">
                  En proceso:{" "}
                </label>
                {inProccess}
              </div>

              <div className="mr-3 flex justify-start items-center text-[10px]">
                <label className="font-bold mr-1 uppercase">Completos: </label>
                {completed}
              </div>
              <div className="mr-3 flex justify-start items-center text-[10px]">
                <label className="font-bold mr-1 uppercase whitespace-nowrap text-yellow-600">
                  No aprobados:{" "}
                </label>
                {unaproved}
              </div>
            </div>
          </div>
          <div className="flex flex-row mt-1 w-[130%] support-buttons-container">
            <div className="flex flex-row items-center hidden sm:block sm:float-right">
              <button
                type="button"
                className="inline-flex flex-col w-[75px] h-[55px] mr-[2px] justify-center items-center rounded ternary-bg-color p-4 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 !text-[11px]"
                onClick={() => {
                  printDiv("print");
                }}
              >
                <img alt="" src={printer} className="w-8" />
                IMPRIMIR
              </button>

              <button
                type="button"
                className="inline-flex flex-col w-[75px] h-[55px] mr-[2px] justify-center items-center rounded ternary-bg-color p-4 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 !text-[11px]"
                onClick={() => {}}
              >
                <img alt="" src={pdf} className="w-8" />
                PDF
              </button>

              <button
                type="button"
                className="inline-flex flex-col w-[75px] h-[55px] mr-[2px] justify-center items-center rounded ternary-bg-color p-4 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 !text-[11px]"
                onClick={() => {
                  navigate("/");
                }}
              >
                <img alt="" src={door} className="w-5" />
                SALIR
              </button>
            </div>

            {/* <div className="flex flex-col items-center sm:float-right">
              <button
                type="button"
                className="inline-flex flex-col w-[75px] h-[55px] mr-[2px] justify-center items-center rounded ternary-bg-color p-4 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 !text-[11px]"
                onClick={() => {
                  if (clientIdFilter == "" || clientIdFilter == 0) {
                    alert("Debes elegir un cliente para ver su historial");
                  } else {
                    fetchHistory();
                    setOpenHistory(true);
                  }
                }}
              >
                <img src={documenticon} className="w-8" />
                HISTORIAL
              </button>
            </div> */}
          </div>
        </div>

        <div
          id="print"
          className="w-[95%] p-5 mx-auto overflow-auto sm:max-h-[70vh] max-h-[60vh] mt-3"
        >
          <table className="w-[95%] mt-6 table-auto mx-auto w-full text-[10px] support-table">
            <thead className="bg-gray-600">
              {width > 1024 ? (
                <tr className="h-8">
                  <th className="!text-white uppercase w-[5%]">Numero</th>

                  <th className="!text-white uppercase w-[22%]">Cliente</th>
                  <th className="!text-white uppercase w-[10%]">Contacto</th>
                  <th className="!text-white uppercase w-[15%]">Auditoria</th>
                  <th className="!text-white uppercase w-[30%]">Descripcion</th>
                   
                  <th className="!text-white uppercase w-[10%]">Estado</th>
                  <th className="!text-white uppercase">División</th>
                  <th className="!text-white uppercase w-[8%]">Accion</th>
                </tr>
              ) : (
                <></>
              )}
            </thead>
            <tbody>
              {isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={10}>
                    <Lottie
                      animationData={loadingAnimation}
                      style={{ width: 100, height: 100, margin: "0 auto" }}
                      loop={true}
                    />
                  </td>
                </tr>
              )}
              {supportDetail.length > 0 &&
                !isFetching &&
                supportDetail.map((supportdetail) => {
                  //console.log(supportdetail);
                  if (width > 1024) {
                    return (
                      <tr
                        key={supportdetail.id}
                        className={`${
                          supportdetail.state == 0
                            ? "bg-red-400"
                            : supportdetail.state == 1
                            ? "bg-teal-300"
                            : supportdetail.state == 2
                            ? "bg-green-300"
                            : supportdetail.state == 3
                            ? "bg-orange-300"
                            : supportdetail.state == 4
                            ? "bg-red-800 font-white"
                            : supportdetail.state == 5
                            ? "bg-yellow-300"
                            : ""
                        } h-18`}
                      >
                        <td>{supportdetail.id}</td>
                        <td className="text-left font-bold">
                          {supportdetail.support.client.name}
                        </td>
                        <td className="text-left">
                          <a
                            target="_blank"
                            href={
                              supportdetail?.support?.contact?.phone?.replaceAll(
                                " ",
                                ""
                              ) != "" &&
                              supportdetail?.support?.contact?.phone?.replaceAll(
                                " ",
                                ""
                              ) != " "
                                ? "https://wa.me/" +
                                  "+595" +
                                  supportdetail?.support?.contact?.phone
                                    ?.replaceAll(" ", "")
                                    .replace("+", "")
                                    .replace("595", "")
                                : "/#"
                            }
                          >
                            {supportdetail.support.contact.name}
                            {" - "}
                            {supportdetail.support.contact.phone}
                          </a>
                        </td>
                        <td
                          className="text-left"
                          style={{
                            fontSize: "10px",
                            lineHeight: "12px",
                            paddingLeft: "20px !important",
                          }}
                        >
                          <b className="font-bold uppercase">Registrado por:</b>{" "}
                          {supportdetail?.support?.user?.fullname}
                          <br />
                          {String(supportdetail.createdAt)
                            .substr(0, 10)
                            .split("-")
                            .reverse()
                            .join("/")}{" "}
                          {String(supportdetail.createdAt).substr(11, 5)}
                          <br />
                          <b className="font-bold uppercase">Terminado por:</b>
                          {supportdetail?.endedStateUser?.fullname}
                          <br />
                          {supportdetail?.endedStateDate !=
                            "0000-00-00 00:00:00" &&
                            supportdetail?.endedStateDate}
                        </td>

                        <td className="text-left w-[180px]">
                          {supportdetail.description}
                          <br />

                          <b className="underline">
                            {supportdetail.observation != "" && "OBSERVACION: "}
                          </b>
                          {supportdetail.observation != "" &&
                            supportdetail.observation}
                          <br />
                          <b className="underline">
                            {supportdetail.comment != "" && "COMENTARIO: "}
                          </b>
                          {supportdetail.comment != "" && supportdetail.comment}
                        </td>
                        {/* <td className="text-left">{supportdetail.comment}</td> */}
                        <td className="text-center uppercase">
                          {supportdetail.state == 0
                            ? "Pendiente"
                            : supportdetail.state == 1
                            ? "En proceso"
                            : supportdetail.state == 2
                            ? "Terminado"
                            : supportdetail.state == 3
                            ? "No aprobado"
                            : supportdetail.state == 4
                            ? "URGENTE"
                            : supportdetail.state == 5
                            ? "Aprobado"
                            : ""}
                        </td>
                        <td className="text-center uppercase">
                          {supportdetail?.division?.name} 
                        </td>

                        {/* <td>{supportdetail?.user?.fullname}</td> */}
                        {/* <td> */}
                        {/* Pendiente por:{" "}
                          {supportdetail?.pendingStateUser?.fullname}
                          <br />
                          En fecha:{" "}
                          {supportdetail?.pendingStateDate !=
                            "0000-00-00 00:00:00" &&
                            supportdetail?.pendingStateDate}
                          <br />
                          En proceso por:
                          {supportdetail?.inProcessStateUser?.fullname}
                          <br />
                          En fecha: {supportdetail?.inProcessStateDate}
                          <br /> */}

                        {/* <br />
                          No aprobado por:
                          {supportdetail?.unaprovedStateUser?.fullname}
                          <br />
                          En fecha:{" "}
                          {supportdetail?.unaprovedStateDate !=
                            "0000-00-00 00:00:00" &&
                            supportdetail?.unaprovedStateDate}
                          <br />
                          Urgente por:
                          {supportdetail?.urgentStateUser?.fullname}
                          <br />
                          En fecha:{" "}
                          {supportdetail?.urgentStateDate !=
                            "0000-00-00 00:00:00" &&
                            supportdetail?.urgentStateDate}
                          <br />
                          Aprobado por:
                          {supportdetail?.aprovedStateUser?.fullname}
                          <br />
                          En fecha:{" "}
                          {supportdetail?.aprovedStateDate !=
                            "0000-00-00 00:00:00" &&
                            supportdetail?.aprovedStateDate} */}
                        <br />
                        {/* </td> */}
                        <td className="text-center flex flex-row items-center justify-center">
                          <button
                            className="bg-transparent"
                            onClick={() => {
                              editSupportDetail(supportdetail.id);
                              
                            }}
                          >
                            <img alt="" src={pen} className="w-5" />
                            
                          </button>
                          <img
                            alt=""
                            src={documenticon}
                            onClick={() => {
                              fetchHistory(supportdetail.support.client.id);
                            }}
                            className="w-8"
                          />
                             <img
                            alt=""
                            src={ver}
                            onClick={() => {
                              viewConvertasion(supportdetail.id);
                            }}
                            className="w-8"
                          />
                          {/* <button
                                className="bg-transparent"
                                onClick={() => {
                                  deleteSupportDetail(supportdetail.id);
                                }}
                              >
                                <FontAwesomeIcon
                                  className="m-1 cursor-pointer secondary-color"
                                  icon={faTrash}
                                />
                              </button> */}
                        </td>
                      </tr>
                    );
                  } else {
                    return (
                      <tr
                        key={supportdetail.id}
                        className={`${
                          supportdetail.state == 0
                            ? "bg-red-300"
                            : supportdetail.state == 1
                            ? "bg-teal-300"
                            : supportdetail.state == 2
                            ? "bg-green-300"
                            : supportdetail.state == 3
                            ? "bg-orange-300"
                            : supportdetail.state == 4
                            ? "bg-red-800 font-white"
                            : supportdetail.state == 5
                            ? "bg-yellow-300"
                            : ""
                        } block p-3`}
                      >
                        <label className="font-bold">Id: </label>
                        {supportdetail.id}
                        <br />
                        <label className="font-bold">Fecha Alta: </label>
                        {String(supportdetail.createdAt)
                          .substr(0, 10)
                          .split("-")
                          .reverse()
                          .join("/")}{" "}
                        {String(supportdetail.createdAt).substr(11, 5)}
                        <br />
                        <label className="font-bold">Cliente: </label>
                        {supportdetail?.support?.client?.name}
                        <br />
                        <a
                          target="_blank"
                          href={
                            supportdetail?.support?.contact?.phone?.replaceAll(
                              " ",
                              ""
                            ) != "" &&
                            supportdetail?.support?.contact?.phone?.replaceAll(
                              " ",
                              ""
                            ) != " "
                              ? "https://wa.me/" +
                                "+595" +
                                supportdetail?.support?.contact?.phone
                                  ?.replaceAll(" ", "")
                                  .replace("+", "")
                                  .replace("595", "")
                              : "/#"
                          }
                        >
                          <label className="font-bold">Contacto: </label>
                          {supportdetail?.support?.contact?.name}
                        </a>
                        <br />
                        <label className="font-bold">Descripcion: </label>
                        {supportdetail.description}
                        <br />
                        <label className="font-bold">Observacion: </label>
                        {supportdetail.observation}
                        <br />
                        <label className="font-bold">Comentario: </label>
                        {supportdetail.comment}
                        <br />
                        <label className="font-bold">Estado: </label>
                        {supportdetail.state == 0
                          ? "Pendiente"
                          : supportdetail.state == 1
                          ? "En proceso"
                          : supportdetail.state == 2
                          ? "Terminado"
                          : supportdetail.state == 3
                          ? "No aprobado"
                          : supportdetail.state == 4
                          ? "URGENTE"
                          : supportdetail.state == 5
                          ? "Aprobado"
                          : ""}
                        <br />
                        <label className="font-bold">División: </label>
                        {supportdetail?.division?.name}
                        <br/>
                        <label className="font-bold">REGISTRADO POR: </label>
                        {supportdetail?.support?.user?.fullname}
                        <br />
                        <label className="font-bold">MODIFICADO POR: </label>
                        {supportdetail?.user?.fullname}
                        <br />
                        {/* Pendiente por:{" "}
                        {supportdetail?.pendingStateUser?.fullname}
                        <br />
                        En fecha:{" "}
                        {supportdetail?.pendingStateDate !=
                          "0000-00-00 00:00:00" &&
                          supportdetail?.pendingStateDate}
                        <br />
                        En proceso por:
                        {supportdetail?.inProcessStateUser?.fullname}
                        <br />
                        En fecha: {supportdetail?.inProcessStateDate}
                        <br /> */}
                        Terminado por:
                        {supportdetail?.endedStateUser?.fullname}
                        <br />
                        En fecha:{" "}
                        {supportdetail?.endedStateDate !=
                          "0000-00-00 00:00:00" &&
                          supportdetail?.endedStateDate}
                        <br />
                       
                        {/* No aprobado por:
                        {supportdetail?.unaprovedStateUser?.fullname}
                        <br />
                        En fecha:{" "}
                        {supportdetail?.unaprovedStateDate !=
                          "0000-00-00 00:00:00" &&
                          supportdetail?.unaprovedStateDate}
                        <br />
                        Urgente por:
                        {supportdetail?.urgentStateUser?.fullname}
                        <br />
                        En fecha:{" "}
                        {supportdetail?.urgentStateDate !=
                          "0000-00-00 00:00:00" &&
                          supportdetail?.urgentStateDate}
                        <br />
                        Aprobado por:
                        {supportdetail?.aprovedStateUser?.fullname}
                        <br />
                        En fecha:{" "}
                        {supportdetail?.aprovedStateDate !=
                          "0000-00-00 00:00:00" &&
                          supportdetail?.aprovedStateDate}
                        <br /> */}
                        <div className="flex flex-row">
                          <button
                            className="bg-transparent"
                            onClick={() => {
                              editSupportDetail(supportdetail.id);
                            
                            }}
                          >
                            <img alt="" src={pen} className="w-5" />
                          </button>
                          <img
                            alt=""
                            src={documenticon}
                            onClick={() => {
                              fetchHistory(supportdetail.support.client.id);
                            }}
                            className="w-8 mt-1"
                          />
                            <img
                            alt=""
                            src={ver}
                            onClick={() => {
                              viewConvertasion(supportdetail.id);
                            }}
                            className="w-8"
                          />
                        </div>
                      </tr>
                    );
                  }
                })}
              {supportDetail.length <= 0 && !isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={10} className="text-center">
                    Sin registros (0)
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* <Pagination
          path="seguimiento"
          page={page}
          pages={pages}
          total={total}
          className="w-[92.5%] m-auto support-pagination-container"
        /> */}
      </Layout>
    </>
  );
};

export default SupportDetail;
