import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const Pagination = (props) => {
  let { page, pages, total, path, params, style } = props;
  path = "/" + path + "/";
  page = page == undefined ? 1 : page;

  useEffect(() => {
  }, []);

  let showing = (page || 1) * 15 - 15 <= 0 ? 1 : (page || 1) * 15 - 15 + 1;

  if (total == 0) showing = 0;

  const range = (start, end) => {
    var ans = [];
    for (let i = start; i <= end; i++) {
      ans.push(i);
    }
    return ans;
  };

  return (
    <div
      style={style}
      className={`flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6 pagination-container rounded-b-xl ${props.className}`}
    >
      <div className="flex flex-1 justify-between sm:hidden">
        <Link
          to={path + (page > 1 ? page - 1 : 1)}
          state={{ ...params }}
          className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          Anterior
        </Link>
        <Link
          to={path + (Number(page) + 1 > pages ? page : Number(page) + 1)}
          state={{ ...params }}
          className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          Siguiente
        </Link>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Mostrando
            <span className="m-2 font-medium">{showing}</span>
            al
            <span className="m-2 font-medium">
              {(page || 1) * 15 > total ? total : (page || 1) * 15}
            </span>
            de
            <span className="m-2 font-medium">{total}</span>
            resultados
          </p>
        </div>
        <div>
          <nav
            className="inline-flex max-w-[300px] -space-x-px rounded-md shadow-sm w-full overflow-x-auto pb-1"
            aria-label="Pagination"
          >
            <Link
              to={path + (page > 1 ? page - 1 : 1)}
              state={{ ...params }}
              className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">Anterior</span>
              <svg
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                  clipRule="evenodd"
                />
              </svg>
            </Link>
            {range(1, pages).map((page_i) => (
              <Link
                key={page_i - 1}
                to={path + page_i}
                state={{ ...params }}
                aria-current="page"
                className={
                  page == page_i || (page == undefined && page_i == 1)
                    ? "relative z-10 inline-flex items-center secondary-bg-color px-4 py-2 text-sm font-semibold another-text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    : "relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                }
              >
                {page_i}
              </Link>
            ))}

            <Link
              to={
                path + (page + 1 > pages ? (pages == 0 ? 1 : pages) : page + 1)
              }
              state={{ ...params }}
              className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">Siguiente</span>
              <svg
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                  clipRule="evenodd"
                />
              </svg>
            </Link>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
