import React, { useState, Fragment, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Dialog, Transition, Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/react";
import Layout from "../layout";
import { axiosAuth } from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser } from "../store/slices/mainSlice";
import { useLocation, useParams } from "react-router-dom";
import Pagination from "../components/pagination";
import DateComponent from "../components/date";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/animations/loading.json";
import company from "../assets/img/company.png";
import door from "../assets/img/door.png";
import floppy from "../assets/img/floppy.png";

const Companies = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.main.user);

  const { page } = useParams();

  const [isFetching, setIsFetching] = useState(false);
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState("edit");

  const [companies, setCompanies] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);

  const [companyId, setCompanyId] = useState(0);
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [cellphone, setCellphone] = useState("");
  const [deleted, setDeleted] = useState(0);
  const [saleconditionId, setSaleConditionId] = useState(0);
  const [saleConditions, setSaleConditions] = useState([]);
  const [selectedCondition, setSelectedCondition] = useState([])
  

 
  
  async function fetchCompanies() {
    let pageQuery = page == undefined ? 1 : page;

    setIsFetching(true);

    let companies = await axiosAuth.get("/companies?page=" + pageQuery);

    if (companies.data.error) {
      if (companies.data.message === "token") {
        await axiosAuth.post("/logout");
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    setIsFetching(false);

    setCompanies(companies.data.rows);
    setPages(companies.data.pages);
    setTotal(companies.data.count);
  }
  async function fetchSaleconditions() {
    let pageQuery = page == undefined ? 1 : page;
    let response = await axiosAuth.get("/saleconditions?page=" + pageQuery);
    if (response.data.error) {
      if (response.data.message === "token") {
        await axiosAuth.post("/logout");
        dispatch(setUser(null));
        dispatch(setToken(null));
        
      }
    }
    setSaleConditions(response.data.saleConditions.rows);
  }
 
  useEffect(() => {
    fetchSaleconditions();
    fetchCompanies();
  }, []); 

  useEffect(() => {
 
    fetchCompanies();
  }, [location]);
  

  const editCompany = async (companyId) => {
    setAction("edit");
    setCompanyId(companyId); 

    let company = companies.filter((company) => company.id === companyId)[0];
    const cd = await axiosAuth.get("/companies/"+companyId);
   // Extract saleconditionsId from details
   const saleconditionIds = cd.data.companiesdetails.map(detail => detail.saleconditionId);
   
    // Filter saleconditions based on saleconditionIds
    const filteredSaleConditions = cd.data.companiesdetails.saleconditions.filter(condition => 
     saleconditionIds.includes(condition.id)
    );
    
    // Set the filtered sale conditions to selectedCondition
    //setSelectedCondition(filteredSaleConditions);
  
    //setSelectedCondition(filteredSaleConditions);
    setAddress(company.address);
    setPhone(company.phone);
    setCellphone(company.cellphone);
    setDeleted(company.deleted);
    setSaleConditions(cd.data.companiesdetails.rows);
    setOpen(true);
    console.log(cd);
  };

  const clearFields = () => {
    setCompanyId(0);
    setName("");
    setAddress("");
    setPhone("");
    setCellphone("");
    setDeleted(0);
    setSelectedCondition([]);
  };

  const handleSubmit = async () => {
    if (action === "edit") {
      let newCompaniesObj = [];
      let id = companyId;

      let updateCompanyRequest = await axiosAuth.put("/companies", {
        id,
        name,
        address,
        phone,
        cellphone,
        deleted,
      });

      if (updateCompanyRequest.data.error) {
        if (updateCompanyRequest.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      companies.map((company) => {
        if (company.id == companyId) {
          newCompaniesObj.push(updateCompanyRequest.data);
        } else {
          newCompaniesObj.push(company);
        }
        return "";
      });

      setCompanies(newCompaniesObj);
    } else {
      if (name === "" || address === "") {
        alert("Debe completar todos los campos para crear la empresa.");
        return false;
      }
      var hasItems = false;
      var conditionToSend = [];
      selectedCondition.map((item) => {
        if (item.id > 0) {
          hasItems = true;
          conditionToSend.push(item);
        }
      });
      let createCompanyRequest = await axiosAuth.post("/companies", {
        name,
        address,
        phone,
        cellphone,
        deleted,
        companiesdetails:conditionToSend,
      });

      if (createCompanyRequest.data.error) {
        if (createCompanyRequest.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      let newCompanies = [...companies];
      newCompanies.push(createCompanyRequest.data);
      setCompanies(newCompanies);
    }

    setOpen(false);
    clearFields();
  };

  const deleteCompany = async (id) => {
    if (window.confirm("Desea eliminar esta empresa ?")) {
      let deleteCompanieRequest = await axiosAuth.delete("/companies", {
        params: { id },
      });

      if (deleteCompanieRequest.data.error) {
        if (deleteCompanieRequest.data.message == "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      let newCompaniesObj = [];

      companies.map((company) => {
        if (company.id !== id) {
          newCompaniesObj.push(company);
        } else {
          if (user.roleId == 1) {
            company.deleted = 1;
            newCompaniesObj.push(company);
          }
        }
        return "";
      });

      setCompanies(newCompaniesObj);
    }
  };
  const toggleBlocked = async (companyId, blocked) => {
    try {
      let response = await axiosAuth.put(`/companies/${companyId}/block`, {
        blocked: blocked
      });
  
      if (response.data.error) {
        if (response.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      } else {
        // Actualiza el estado de la lista de empresas con el nuevo valor de "blocked"
        let updatedCompanies = companies.map((company) => {
          if (company.id === companyId) {
            return { ...company, blocked: blocked };
          }
          return company;
        });
  
        setCompanies(updatedCompanies);
      }
    } catch (error) {
      console.error("Error al bloquear/desbloquear la empresa", error);
    }
  };
  return (
    <>
      <Layout>
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              clearFields();
              setOpen(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto align-items-center justify-center items-center flex">
              <div className="flex justify-center p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel
                    className="relative transform overflow-hidden rounded-3xl bg-white text-left shadow-xl transition-all sm:my-8 w-[325px]"
                    style={{ marginTop: 35 }}
                  >
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-0">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                          <Dialog.Title
                            as="h1"
                            className="text-center font-regular leading-6 primary-color text-3xl"
                          >
                            {action === "edit" ? "Editar" : "Crear"} empresa
                          </Dialog.Title>
                          <div className="mt-5">
                            <div className="w-full max-w-xs">
                              <form className="bg-white rounded">
                                {action === "edit" && (
                                  <div className="mb-4">
                                    <label
                                      className="block text-gray-700 text-sm font-bold"
                                      htmlFor="companyId"
                                    >
                                      Id
                                    </label>
                                    <input
                                      readOnly
                                      className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      id="companyId"
                                      type="text"
                                      value={companyId}
                                    />
                                  </div>
                                )}
                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="name"
                                  >
                                    Nombre
                                  </label>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                    id="name"
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                  />
                                </div>
                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="address"
                                  >
                                    Direccion
                                  </label>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="address"
                                    type="text"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                  />
                                </div>
                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="phone"
                                  >
                                    Telefono
                                  </label>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="phone"
                                    type="text"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                  />
                                </div>
                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="cellphone"
                                  >
                                    Celular
                                  </label>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full
                                     py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="cellphone"
                                    type="text"
                                    value={cellphone}
                                    onChange={(e) =>
                                      setCellphone(e.target.value)
                                    }
                                  />
                                </div>
                                <div className="mb-4">
                                  <label>
                                    Condiciones de Ventas
                                  </label>

                                  <Listbox 
                                   value={selectedCondition} onChange={setSelectedCondition} multiple>
                                    <ListboxButton className="bg-gray-500  rounded-0 w-full
                                     text-gray-100  h-15 "> 
                                      {selectedCondition.map((c) => c.description).join(', ')}
                                      </ListboxButton>
                                    <ListboxOptions 
                                    anchor="right end" className="absolute z-10 mt-1 w-32  
                                     shadow-lg max-h-60 rounded-md py-1 text-gray-700 ring-1 ring-black
                                      ring-opacity-5 overflow-auto focus:outline-none">
                                      {saleConditions.map((c) => (
                                        <ListboxOption key={c.id} value={c} className="data-[focus]:bg-blue-500
                                        
                                        ">
                                          {c.description}
                                        </ListboxOption>
                                      ))}
                                    </ListboxOptions>
                                  </Listbox>


                                </div>
                                {user.roleId == 1 && (
                                  <div className="mb-4">
                                    <label
                                      className="block text-gray-700 text-sm font-bold"
                                      htmlFor="deleted"
                                    >
                                      Borrado
                                    </label>
                                    <select
                                      className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      id="deleted"
                                      value={deleted}
                                      onChange={(e) =>
                                        setDeleted(parseInt(e.target.value))
                                      }
                                    >
                                      <option value={0}>No</option>
                                      <option value={1}>Si</option>
                                    </select>
                                  </div>
                                )}
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 justify-center flex sm:px-6">
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => {
                          clearFields();
                          setOpen(false);
                        }}
                      >
                        <img src={door} className="w-5" />
                        CERRAR
                      </button>
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => handleSubmit()}
                      >
                        <img src={floppy} className="w-8" />
                        GUARDAR
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <h1 className="ml-5 mt-5 text-3xl secondary-color title font-family-thin">
          Empresas
        </h1>
        <DateComponent className="sm:block hidden" />
        <button
          type="button"
          className="relative right-[25px] sm:right-[50px] bottom-[10px] inline-flex w-[68px] float-right m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
          style={{ alignItems: "center" }}
          onClick={() => {
            setAction("create");
            setOpen(true);
          }}
        >
          <img src={company} className="w-16" />
          CREAR EMPRESA
        </button>

        <div className="w-[100%] p-5 mx-auto overflow-auto">
          <table className="w-[100%] table-auto mx-auto w-full text-xs">
            <thead>
              <tr>
                <th className="uppercase">Id</th>
                <th className="uppercase">Nombre</th>
                <th className="uppercase">Direccion</th>
                <th className="uppercase">Telefono</th>
                <th className="uppercase">Celular</th>
                {user.roleId == 1 && <th className="uppercase">Borrado</th>}
                <th className="uppercase">Fecha Alta</th>
                <th className="uppercase">Accion</th>
                <th className="uppercase">Bloqueado</th>
              </tr>
            </thead>
            <tbody>
  {companies.length > 0 &&
    !isFetching &&
    companies?.map((company) => {
      return (
        <tr key={company.id}>
          <td>{company.id}</td>
          <td>{company.name}</td>
          <td>{company.address}</td>
          <td>{company.phone}</td>
          <td>{company.cellphone}</td>
          {user.roleId == 1 && (
            <td>{company.deleted ? "Si" : "No"}</td>
          )}
          <td>
            {company.createdAt
              .substr(0, 10)
              .split("-")
              .reverse()
              .join("/")}
          </td>
          <td>
            <input
              type="checkbox"
              checked={company.blocked} // Mostrar si la empresa está bloqueada
              onChange={() => toggleBlocked(company.id, !company.blocked)} // Llamar función al cambiar
            />
          </td>
          <td className="text-center">
            <button
              className="bg-transparent"
              onClick={() => {
                editCompany(company.id);
              }}
            >
              <FontAwesomeIcon
                className="m-1 cursor-pointer secondary-color"
                icon={faPen}
              />
            </button>
            <button
              className="bg-transparent"
              onClick={() => {
                deleteCompany(company.id);
              }}
            >
              <FontAwesomeIcon
                className="m-1 cursor-pointer secondary-color"
                icon={faTrash}
              />
            </button>
          </td>
        </tr>
      );
    })}
</tbody>
          </table>

          <Pagination path="empresas" page={page} pages={pages} total={total} />
        </div>
      </Layout>
    </>
  );
};

export default Companies;
