import React, { useState, Fragment, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Dialog, Transition } from "@headlessui/react";
import Layout from "../layout";
import { axiosAuth } from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser } from "../store/slices/mainSlice";
import { useLocation, useParams } from "react-router-dom";
import Pagination from "../components/pagination";
import DateComponent from "../components/date";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/animations/loading.json";
import coin from "../assets/img/salecondition.webp";
import door from "../assets/img/door.png";
import floppy from "../assets/img/floppy.png";

const Currencies = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [openC, setOpenC] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [action, setAction] = useState("edit");

  const { page } = useParams();
  const user = useSelector((state) => state.main.user);

  const [currencies, setCurrencies] = useState([]);

  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);

  const [currencyId, setCurrencyId] = useState(1);
  const [name, setName] = useState("");
  const [abreviation, setAbreviation] = useState("");
  const [active, setActive] = useState(1);
  const [deleted, setDeleted] = useState(0);
  const [exchangeRates, setExchangeRates] = useState([]);
  const [price, setPrice] = useState([]);

  async function fetchCurrencies() {
    let pageQuery = page === undefined ? 1 : page;

    setIsFetching(true);

    let currencies = await axiosAuth.get("/currencies?page=" + pageQuery);

    if (currencies.data.error) {
      if (currencies.data.message === "token") {
        await axiosAuth.post("/logout");
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    setIsFetching(false);
    let currentt = currencies.data.currencies.rows.find(c => c.id == currencyId);
  
    setCurrencies(currencies.data.currencies.rows);
    setExchangeRates(currentt.exchangerates.reverse());
    setTotal(currencies.data.currencies.count);
    setPages(currencies.data.currencies.pages);
  }

  useEffect(() => {
    fetchCurrencies();
  }, [currencyId]);

  useEffect(() => {
    fetchCurrencies();
  }, [location]);

  const editCurrency = (currencyId) => {
    setAction("edit");
    setCurrencyId(currencyId);

    let currency = currencies.filter(
      (currency) => currency.id === currencyId
    )[0];

    setName(currency.name);
    setActive(currency.active);
    setAbreviation(currency.abreviation);
    setDeleted(currency.deleted);

    setOpen(true);
  };

  const clearFields = () => {
    setCurrencyId(1);
    setName("");
    setAbreviation("");
    setActive(0);
    setDeleted(0);
  };
  const clearFieldsC = () => {
    setCurrencyId(1);
    setPrice("");
    
  };

  const handleSubmit = async () => {
    if (name === "" || abreviation === "") {
      alert("Debes completar los campos para continuar.");
      return false;
    }

    if (action === "edit") {
      let id = currencyId;
      let newCurrenciesObj = [];

      let updateCurrencyRequest = await axiosAuth.put("/currencies", {
        id,
        name,
        abreviation,
        active,
        deleted,
      });

      if (updateCurrencyRequest.data.error) {
        if (updateCurrencyRequest.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      currencies.map((currency) => {
        if (currency.id == id) {
          newCurrenciesObj.push(updateCurrencyRequest.data);
        } else {
          newCurrenciesObj.push(currency);
        }
        return "";
      });

      setCurrencies(newCurrenciesObj);
    } else {
      let createCurrencyRequest = await axiosAuth.post("/currencies", {
        name,
        abreviation,
        active,
        deleted,
      });

      if (createCurrencyRequest.data.error) {
        if (createCurrencyRequest.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      let newCurrencies = [...currencies];
      newCurrencies.push(createCurrencyRequest.data);
      setCurrencies(newCurrencies);
    }
    clearFields();
    setOpen(false);
   
  };
  const  handleSubmitPrice = async () => {
    

    
      let createCurrencyRequest = await axiosAuth.post("/exchangerate", {
        price,
        currencyId
      });

      if (createCurrencyRequest.data.error) {
        if (createCurrencyRequest.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      let newExchangeRate = [...exchangeRates];
      newExchangeRate.push(createCurrencyRequest.data);
      setExchangeRates(newExchangeRate.reverse());
   
      clearFieldsC();
    setOpenC(false);
  };

  const deleteCurrency = async (currencyId) => {
    if (window.confirm("Desea eliminar esta moneda ?")) {
      let currencyDeleteRequest = await axiosAuth.delete("/currencies", {
        params: { id: currencyId },
      });

      if (currencyDeleteRequest.data.error) {
        if (currencyDeleteRequest.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      let newCurrenciesObj = [];

      currencies.map((currency) => {
        if (currency.id !== currencyId) {
          newCurrenciesObj.push(currency);
        } else {
          if (user.roleId == 1) {
            currency.deleted = 1;
            newCurrenciesObj.push(currency);
          }
        }
        return "";
      });

      setCurrencies(newCurrenciesObj);
    }
  };

  return (
    <>
      <Layout>
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              clearFields();
              setOpen(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto align-items-center justify-center items-center flex">
              <div className="flex justify-center p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel
                    className="relative transform overflow-hidden rounded-3xl bg-white text-left shadow-xl transition-all sm:my-8 w-[280px]"
                    style={{ marginTop: 45 }}
                  >
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-0">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                          <Dialog.Title
                            as="h1"
                            className="text-center font-regular leading-6 primary-color text-3xl"
                          >
                            {action === "edit" ? "Editar" : "Crear"} moneda
                          </Dialog.Title>
                          <div className="mt-5">
                            <div className="w-full">
                              <form className="bg-white rounded">
                                {action === "edit" && (
                                  <div className="mb-4">
                                    <label
                                      className="block text-gray-700 text-sm font-bold"
                                      htmlFor="BrandId"
                                    >
                                      Id
                                    </label>
                                    <input
                                      readOnly
                                      className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      id="BrandId"
                                      type="text"
                                      value={currencyId}
                                    />
                                  </div>
                                )}

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="name"
                                  >
                                    Nombre
                                  </label>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                    id="name"
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                  />
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="abreviation"
                                  >
                                    Abreviacion
                                  </label>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="abreviation"
                                    type="text"
                                    value={abreviation}
                                    onChange={(e) =>
                                      setAbreviation(e.target.value)
                                    }
                                  />
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="active"
                                  >
                                    Activa
                                  </label>
                                  <select
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="active"
                                    value={active}
                                    onChange={(e) =>
                                      setActive(parseInt(e.target.value))
                                    }
                                  >
                                    <option value={0}>No</option>
                                    <option value={1}>Si</option>
                                  </select>
                                </div>

                                {user.roleId == 1 && (
                                  <div className="mb-4">
                                    <label
                                      className="block text-gray-700 text-sm font-bold"
                                      htmlFor="deleted"
                                    >
                                      Borrado
                                    </label>
                                    <select
                                      className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      id="deleted"
                                      value={deleted}
                                      onChange={(e) =>
                                        setDeleted(parseInt(e.target.value))
                                      }
                                    >
                                      <option value={0}>No</option>
                                      <option value={1}>Si</option>
                                    </select>
                                  </div>
                                )}
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 justify-center flex sm:px-6">
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => {
                          clearFields();
                          setOpen(false);
                        }}
                      >
                        <img src={door} className="w-5" />
                        CERRAR
                      </button>
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => handleSubmit()}
                      >
                        <img src={floppy} className="w-8" />
                        GUARDAR
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
        <Transition.Root show={openC} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              clearFields();
              setOpen(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto align-items-center justify-center items-center flex">
              <div className="flex justify-center p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel
                    className="relative transform overflow-hidden rounded-3xl bg-white text-left shadow-xl transition-all sm:my-8 w-[400px]"
                    style={{ marginTop: 45 }}
                  >
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-0">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                          <Dialog.Title
                            as="h1"
                            className="text-center font-regular leading-6 primary-color text-3xl"
                          >
                            {action === "edit" ? "Editar" : "Crear"} cotizacion
                          </Dialog.Title>
                          <div className="mt-5">
                            <div className="w-full">
                              <form className="bg-white rounded">
                                {action === "edit" && (
                                  <div className="mb-4">
                                    <label
                                      className="block text-gray-700 text-sm font-bold"
                                      htmlFor="BrandId"
                                    >
                                      Id
                                    </label>
                                    <input
                                      readOnly
                                      className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      id="BrandId"
                                      type="text"
                                      value={currencyId}
                                    />
                                  </div>
                                )}

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="name"
                                  >
                                    Moneda
                                  </label>
                                  <select
                                    className="input-bg-color appearance-none border w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize  h-[28.1px]"
                                    id="branch"
                                    value={currencyId}
                                    onChange={(e) =>
                                     setCurrencyId(e.target.value)}

                                  >

                                    {currencies.map((currency, i) => (
                                      <option value={currency.id} key={i}>
                                        Moneda: {currency.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="price"
                                    
                                  >
                                    Precio
                                  </label>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="price"
                                    type="text"
                                    value={price}
                                    onChange={(e) =>
                                      setPrice(e.target.value)
                                    }
                                  />
                                </div>
                                <div className="w-full overflow-hidden border border-gray-200 shadow-md sm:rounded-lg">
                                  <div className="bg-gray-100 px-6 py-3 border-b border-gray-200">
                                    <div className="flex justify-between items-center">
                                      <div className="text-sm font-medium text-gray-700">Precio</div>
                                      <div className="text-sm font-medium text-gray-700">Fecha</div>
                                    </div>
                                  </div>
                                  <div className="overflow-y-auto max-h-96">
                                    <div className="divide-y divide-gray-200">
                                      {exchangeRates.map((rate, index) => (
                                        <div key={index} className="px-6 py-4 flex justify-between items-center">
                                          <div className="text-sm text-gray-700 text-left">{rate.price}</div>
                                          <div className="text-sm text-gray-700">{rate.createdAt}</div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>





                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 justify-center flex sm:px-6">
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => {
                          clearFieldsC();
                          setOpenC(false);
                        }}
                      >
                        <img src={door} className="w-5" />
                        CERRAR
                      </button>
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => handleSubmitPrice()}
                      >
                        <img src={floppy} className="w-8" />
                        GUARDAR
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <h1 className="ml-5 mt-5 text-3xl secondary-color title font-family-thin">
          Monedas
        </h1>
        <DateComponent className="sm:block hidden" />
        <button
          type="button"
          className="relative right-[25px] sm:right-[50px] bottom-[10px] inline-flex w-[68px] float-right m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
          style={{ alignItems: "center" }}
          onClick={() => {
            setAction("create");
            setOpen(true);
          }}
        >
          <img src={coin} className="w-8" />
          CREAR MONEDA
        </button>
        <button
          type="button"
          className="relative right-[25px] sm:right-[50px] bottom-[10px] inline-flex w-[68px] float-right m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
          style={{ alignItems: "center" }}
          onClick={() => {
            setAction("create");
            setOpenC(true);
          }}
        >
          <img src={coin} className="w-8" />
          NUEVA COTIZACION
        </button>

        <div className="w-[97%] p-5 mx-auto overflow-auto">
          <table className="w-[97%] table-auto mx-auto w-full text-xs">
            <thead>
              <tr>
                <th>Id</th>
                <th>Nombre</th>
                <th>Abreviacion</th>
                <th>Activa</th>
                {user.roleId == 1 && <th>Borrado</th>}
                <th>Fecha Alta</th>
              </tr>
            </thead>
            <tbody>
              {isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={8}>
                    <Lottie
                      animationData={loadingAnimation}
                      style={{ width: 100, height: 100, margin: "0 auto" }}
                      loop={true}
                    />
                  </td>
                </tr>
              )}
              {currencies.length > 0 &&
                !isFetching &&
                currencies?.map((currency) => {
                  return (
                    <tr key={currency.id}>
                      <td>{currency.id}</td>
                      <td>{currency.name}</td>
                      <td>{currency.abreviation}</td>
                      <td>{currency.active ? "Si" : "No"}</td>
                      {user.roleId == 1 && (
                        <td>{currency.deleted ? "Si" : "No"}</td>
                      )}
                      <td>
                        {currency.createdAt
                          .substr(0, 10)
                          .split("-")
                          .reverse()
                          .join("/")}
                      </td>
                      <td className="text-center">
                        <button
                          className="bg-transparent"
                          onClick={() => {
                            editCurrency(currency.id);
                          }}
                        >
                          <FontAwesomeIcon
                            className="m-1 cursor-pointer secondary-color"
                            icon={faPen}
                          />
                        </button>
                        <button
                          className="bg-transparent"
                          onClick={() => {
                            deleteCurrency(currency.id);
                          }}
                        >
                          <FontAwesomeIcon
                            className="m-1 cursor-pointer secondary-color"
                            icon={faTrash}
                          />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              {currencies.length <= 0 && !isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={8} className="text-center">
                    Sin registros (0)
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <Pagination path="monedas" page={page} pages={pages} total={total} />
        </div>
      </Layout>
    </>
  );
};

export default Currencies;
