import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: null,
  user: null,
  title: 'Dashboard'
};

export const mainSlice = createSlice({
  name: "mainSlice",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setUserImage: (state, action) => {
      state.user.image = action.payload;
    },
    setTitle: (state, action) => {
      state.title = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setToken, setUser, setUserImage, setTitle } = mainSlice.actions;
export default mainSlice.reducer;
