import React, { useState, Fragment, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Dialog, Transition } from "@headlessui/react";
import Layout from "../layout";
import { axiosAuth } from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser } from "../store/slices/mainSlice";
import { useLocation, useParams } from "react-router-dom";
import Pagination from "../components/pagination";
import DateComponent from "../components/date";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/animations/loading.json";
import menuimg from "../assets/img/menu.png";
import door from "../assets/img/door.png";
import floppy from "../assets/img/floppy.png";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const Menus = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [action, setAction] = useState("edit");

  const { page } = useParams();
  const user = useSelector((state) => state.main.user);

  const [menus, setMenus] = useState([]);

  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);

  const [menuId, setMenuId] = useState(0);
  const [name, setName] = useState("");
  const [path, setPath] = useState("");
  const [icon, setIcon] = useState("");

  async function fetchMenus() {
    let pageQuery = page === undefined ? 1 : page;

    setIsFetching(true);

    let menus = await axiosAuth.get("/menus?page=" + pageQuery);

    if (menus.data.error) {
      if (menus.data.message === "token") {
        await axiosAuth.post("/logout");
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    setIsFetching(false);

    setMenus(menus.data.menus.rows);
    setTotal(menus.data.menus.count);
    setPages(menus.data.menus.pages);
  }

  useEffect(() => {
    fetchMenus();
  }, []);

  useEffect(() => {
    fetchMenus();
  }, [location]);

  const editMenu = (menuId) => {
    setAction("edit");
    setMenuId(menuId);

    let menu = menus.filter((menu) => menu.id === menuId)[0];

    setName(menu.name);
    setPath(menu.path);
    setIcon(menu.icon);

    setOpen(true);
  };

  const clearFields = () => {
    setMenuId(0);
    setName("");
    setPath("");
    setIcon("");
  };

  const handleSubmit = async () => {
    if (name === "" || icon === "") {
      alert("Debes completar los campos para continuar.");
      return false;
    }

    if (action === "edit") {
      let id = menuId;
      let newMenusObj = [];

      let updateMenuRequest = await axiosAuth.put("/menus", {
        id,
        name,
        path,
        icon,
      });

      if (updateMenuRequest.data.error) {
        if (updateMenuRequest.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      menus.map((menu) => {
        if (menu.id == id) {
          newMenusObj.push(updateMenuRequest.data);
        } else {
          newMenusObj.push(menu);
        }
        return "";
      });

      setMenus(newMenusObj);
    } else {
      let createMenuRequest = await axiosAuth.post("/menus", {
        name,
        path,
        icon,
      });

      if (createMenuRequest.data.error) {
        if (createMenuRequest.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      let newMenus = [...menus];
      newMenus.push(createMenuRequest.data);
      setMenus(newMenus);
    }

    setOpen(false);
  };

  const onDragEnd = async (result) => {
    if (result.destination == null) return false;
    const newItems = Array.from(menus);
    const [removed] = newItems.splice(result.source.index, 1);
    removed.order = result.destination.index + 1;
    newItems.splice(result.destination.index, 0, removed);
    newItems.map((newItem, i) => (newItem.order = i + 1));
    setMenus(newItems);

    newItems.map(async (newItem, i) => {
      await axiosAuth.put("/menus", {
        id: newItem.id,
        order: newItem.order,
      });
    });

    setTimeout(async function () {
      let accessRequest = await axiosAuth.get("/useraccess");

      if (accessRequest.data.error) {
        if (accessRequest.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      dispatch(setUser({ ...user, access: accessRequest.data.access }));
    }, 1500);
  };

  const deleteMenu = async (menuId) => {
    if (window.confirm("Desea eliminar este menu ?")) {
      let MenuDeleteRequest = await axiosAuth.delete("/menus", {
        params: { id: menuId },
      });

      if (MenuDeleteRequest.data.error) {
        if (MenuDeleteRequest.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      let newMenusObj = [];

      menus.map((menu) => {
        if (menu.id !== menuId) {
          newMenusObj.push(menu);
        } else {
          if (user.roleId == 1) {
            menu.deleted = 1;
            newMenusObj.push(menu);
          }
        }
        return "";
      });

      setMenus(newMenusObj);
    }
  };

  return (
    <>
      <Layout>
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              clearFields();
              setOpen(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto align-items-center justify-center items-center flex">
              <div className="flex justify-center p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel
                    className="relative transform overflow-hidden rounded-3xl bg-white text-left shadow-xl transition-all sm:my-8 w-[280px]"
                    style={{ marginTop: 45 }}
                  >
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-0">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                          <Dialog.Title
                            as="h1"
                            className="text-center font-regular leading-6 primary-color text-3xl"
                          >
                            {action === "edit" ? "Editar" : "Crear"} menu
                          </Dialog.Title>
                          <div className="mt-5">
                            <div className="w-full max-w-xs">
                              <form className="bg-white rounded">
                                {action === "edit" && (
                                  <div className="mb-4">
                                    <label
                                      className="block text-gray-700 text-sm font-bold"
                                      htmlFor="MenuId"
                                    >
                                      Id
                                    </label>
                                    <input
                                      readOnly
                                      className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      id="MenuId"
                                      type="text"
                                      value={menuId}
                                    />
                                  </div>
                                )}

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="name"
                                  >
                                    Nombre
                                  </label>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                    id="name"
                                    type="text"
                                    value={name}
                                    onChange={(e) => {
                                      setName(e.target.value);
                                      setPath(
                                        String(e.target.value)
                                          .toLocaleLowerCase()
                                          .replace(" ", "")
                                      );
                                    }}
                                  />
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="path"
                                  >
                                    Ruta
                                  </label>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="path"
                                    type="text"
                                    value={path}
                                    onChange={(e) => setPath(e.target.value)}
                                  />
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="icon"
                                  >
                                    Icono (FontAwesome)
                                  </label>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="icon"
                                    type="text"
                                    value={icon}
                                    onChange={(e) => setIcon(e.target.value)}
                                  />
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 justify-center flex sm:px-6">
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => {
                          clearFields();
                          setOpen(false);
                        }}
                      >
                        <img src={door} className="w-5" />
                        CERRAR
                      </button>
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => handleSubmit()}
                      >
                        <img src={floppy} className="w-8" />
                        GUARDAR
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <h1 className="ml-5 mt-5 text-3xl secondary-color title font-family-thin">
          Menus
        </h1>
        <DateComponent className="sm:block hidden" />
        <button
          type="button"
          className="relative right-[25px] sm:right-[50px] bottom-[10px] inline-flex w-[68px] float-right m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
          style={{ alignItems: "center" }}
          onClick={() => {
            setAction("create");
            setOpen(true);
          }}
        >
          <img src={menuimg} className="w-16" />
          CREAR MENU
        </button>

        <div className="w-[95%] p-5 mx-auto overflow-auto">
          <div className="table w-[95%] table-auto mx-auto w-full text-xs">
            <div className="thead">
              <div className="tr">
                <div className="th uppercase w-[14%]">Id</div>
                <div className="th uppercase w-[10%]">Orden</div>
                <div className="th uppercase w-[10%]">Nombre</div>
                <div className="th uppercase w-[10%]">Ruta</div>
                <div className="th uppercase w-[10%]">Icono</div>
                <div className="th uppercase w-[10%]">Fecha Alta</div>
                <div className="th uppercase w-[10%]">Accion</div>
              </div>
            </div>
            <div className="tbody pt-4">
              {isFetching && (
                <div className="tr">
                  <div className="td w-full">
                    <Lottie
                      animationData={loadingAnimation}
                      style={{ width: 100, height: 100, margin: "0 auto" }}
                      loop={true}
                    />
                  </div>
                </div>
              )}

              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {menus.length > 0 &&
                        !isFetching &&
                        menus.map((menu, index) => {
                          return (
                            <Draggable
                              key={menu.id}
                              draggableId={String(menu.id)}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  snapshot={snapshot}
                                  {...provided.dragHandleProps}
                                  {...provided.draggableProps}
                                  className="tr"
                                  key={menu.id}
                                >
                                  <div className="td w-[14%] text-center">
                                    {menu.id}
                                  </div>
                                  <div className="td w-[10%] text-center">
                                    {index + 1}
                                  </div>
                                  <div className="td w-[10%] text-center">
                                    {menu.name}
                                  </div>
                                  <div className="td w-[10%] text-center">
                                    {menu.path}
                                  </div>
                                  <div className="td w-[10%] text-center">
                                    {menu.icon}
                                  </div>
                                  <div className="td w-[10%] text-center">
                                    {menu.createdAt
                                      .substr(0, 10)
                                      .split("-")
                                      .reverse()
                                      .join("/")}
                                  </div>
                                  <div className="td w-[10%] text-center text-center">
                                    <button
                                      className="bg-transparent"
                                      onClick={() => {
                                        editMenu(menu.id);
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        className="m-1 cursor-pointer secondary-color"
                                        icon={faPen}
                                      />
                                    </button>
                                    {/* <button
                          className="bg-transparent"
                          onClick={() => {
                            deleteMenu(menu.id);
                          }}
                        >
                          <FontAwesomeIcon
                            className="m-1 cursor-pointer secondary-color"
                            icon={faTrash}
                          />
                        </button> */}
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

              {menus.length <= 0 && !isFetching && (
                <div className="tr">
                  <div className="w-full td text-center">Sin registros (0)</div>
                </div>
              )}
            </div>
          </div>

          <Pagination
            className="menus-pagination-container"
            path="menus"
            page={page}
            pages={pages}
            total={total}
          />
        </div>
      </Layout>
    </>
  );
};

export default Menus;
