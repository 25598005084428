import React, { useEffect, useState } from "react";
import logo from "../assets/img/logo-inversed.png";
import { useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { axiosNoAuth } from "../utils/axios";
import { setToken, setUser } from "../store/slices/mainSlice";
import CBOR from "cbor-web";
import { motion } from "framer-motion";
import Lottie from "lottie-react";
import heartAnimation from "../assets/animations/heart.json";

const Login = () => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.main.token);
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [animateImage, setAnimateImage] = useState(false);

  useEffect(() => {
    if (token != null) {
      navigate("/");
    }
  }, [token]);

  const makeLogin = async () => {
    if (email == "" || password == "") {
      alert("Complete los campos para acceder");
      return false;
    }

    const loginRequest = await axiosNoAuth.post("/login", { email, password });

    if (loginRequest.data.error) {
      setError(loginRequest.data.msg);
      return false;
    }

    dispatch(setUser(loginRequest.data.user));
    dispatch(setToken(loginRequest.data.token));
  };

  useEffect(() => {
    
    // const publicKeyCredentialCreationOptions = {
    //   challenge: Uint8Array.from(
    //     "askldal;!-#_@_#sha;kda;lkwhdlaskDADJOWJDd_84232",
    //     (c) => c.charCodeAt(0)
    //   ),
    //   rp: {
    //     name: "OCUS MAS",
    //     id: "localhost",
    //   },
    //   user: {
    //     id: Uint8Array.from("UZSL85T9AFC", (c) => c.charCodeAt(0)),
    //     name: "jorgeama97@gmail.com",
    //     displayName: "Jorge Amarilla",
    //   },
    //   pubKeyCredParams: [
    //     { alg: -257, type: "public-key" },
    //     { alg: -7, type: "public-key" },
    //   ],
    //   authenticatorSelection: {
    //     authenticatorAttachment: "cross-platform",
    //   },
    //   timeout: 60000,
    //   attestation: "direct",
    // };
    // const credential = await window.navigator.credentials.create({
    //   publicKey: publicKeyCredentialCreationOptions,
    // });
    // // decode the clientDataJSON into a utf-8 string
    // const utf8Decoder = new TextDecoder("utf-8");
    // const decodedClientData = utf8Decoder.decode(
    //   credential.response.clientDataJSON
    // );
    // // parse the string as an object
    // const clientDataObj = JSON.parse(decodedClientData);
    // const decodedAttestationObj = CBOR.decode(
    //   credential.response.attestationObject
    // );
    // const { authData } = decodedAttestationObj;
    // // get the length of the credential ID
    // const dataView = new DataView(new ArrayBuffer(2));
    // const idLenBytes = authData.slice(53, 55);
    // idLenBytes.forEach((value, index) => dataView.setUint8(index, value));
    // const credentialIdLength = dataView.getUint16();
    // // get the credential ID
    // const credentialId = authData.slice(55, 55 + credentialIdLength);
    // // get the public key object
    // const publicKeyBytes = authData.slice(55 + credentialIdLength);
    // // the publicKeyBytes are encoded again as CBOR
    // const publicKeyObject = CBOR.decode(publicKeyBytes.buffer);

  }, []);

  const container = {
    hidden: { opacity: 1 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        duration: 0.8,
        delay: 0.5,
        ease: [0, 0.71, 0.2, 1.01],
      },
    },
  };

  const listItem = {
    hidden: { opacity: 0, y: 65 },
    show: { opacity: 1, y: 0 },
  };

  return (
    <>
      <div
        className="animated"
        style={{
          position: "absolute",
          background: "#6078a4",
          top: 0,
          width: "100%",
          height: "100%",
          zIndex: 999999999,
        }}
      ></div>

      <div
        className="animated2"
        style={{
          position: "absolute",
          background: "#35538b",
          top: 0,
          width: "100%",
          height: "100%",
          zIndex: 999999999,
        }}
      ></div>
      <motion.div
        className="w-[300px] m-auto h-full text-center flex justify-center flex-col items-center"
        initial="hidden"
        animate="show"
        variants={container}
      >
        <motion.img
          variants={listItem}
          src={logo}
          alt="logo"
          className="mx-auto w-[180px]"
        />
        <motion.div className="mb-4 w-80 mt-6 mx-auto" variants={listItem}>
          <label
            className="block text-gray-400 text-sm font-medium mb-1 text-left"
            htmlFor="email"
          >
            Email
          </label>
          <input
            className="input-bg-color-inverted rounded-lg appearance-none border w-full py-2 px-3 text-gray-700 leading-tight !text-[13px] focus:outline-none focus:shadow-outline"
            id="email"
            required
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="example@gmail.com"
          />
        </motion.div>
        <motion.div className="mb-4 w-80 mt-1 mx-auto" variants={listItem}>
          <label
            className="block text-gray-400 text-sm font-medium mb-1 text-left"
            htmlFor="password"
          >
            Contraseña
          </label>
          <input
            className="input-bg-color-inverted rounded-lg appearance-none border w-full py-2 px-3 text-gray-700 mb-3 leading-tight !text-[13px] focus:outline-none focus:shadow-outline"
            id="password"
            type="password"
            required
            value={password}
            onKeyDown={(e) => { if(e.key === 'Enter'){ makeLogin(); } }}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="********"
          />
        </motion.div>

        <motion.button
          variants={listItem}
          type="button"
          onClick={() => {
            //navigate("/usuarios");
            makeLogin();
          }}
          className="w-80 text-lg font-white px-3 rounded-lg py-2 text-md font-bold shadow-sm w-32"
          style={{ backgroundColor: "#6078a4" }}
        >
          Acceder
        </motion.button>
        <br />
        <br />
        {error != "" && (
          <span className="inline-flex items-center rounded-md bg-pink-50 px-2 py-1 text-xs font-bold text-pink-700 ring-1 ring-inset ring-pink-700/10">
            {error}
          </span>
        )}

        <div className="flex flex-row justify-center items-center relative bottom-1 text-opaque">
          <span className="font-family-thin !text-[12px] relative right-[-8px] z-10">
            Made with
          </span>
          <Lottie
            animationData={heartAnimation}
            style={{ width: 40, height: 40 }}
            loop={true}
          />
          <span className="relative !text-[12px] left-[-7px] font-family-thin">
            by&nbsp;
          </span>
          <b className="relative !text-[12px] left-[-7px]">
            <Link to={"https://www.ocus.com.py"} target="_blank">
              {" "}
              Ocus
            </Link>
          </b>
        </div>

      </motion.div>
    </>
  );
};

export default Login;
