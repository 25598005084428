import React, { useState, Fragment, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Dialog, Transition, Tab, DialogPanel, DialogTitle, Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/react";
import Layout from "../layout";
import { axiosAuth } from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser, setTitle } from "../store/slices/mainSlice";
import { useLocation, useParams } from "react-router-dom";
import Pagination from "../components/pagination";
import DateComponent from "../components/date";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/animations/loading.json";
import box from "../assets/img/box.png";
import door from "../assets/img/door.png";
import floppy from "../assets/img/floppy.png";
import forbidden from "../assets/img/forbidden.png";
import { numberWithCommas } from "../utils/constants";
import ver from "../assets/img/eye.png";
import useViewport from "../utils/useViewport";
import { ChevronDownIcon } from '@heroicons/react/20/solid'


const BoxOpenings = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [action, setAction] = useState("edit");

  const { page } = useParams();
  const user = useSelector((state) => state.main.user);

  const [boxOpenings, setBoxOpenings] = useState([]);
  const [branches, setBranches] = useState([]);
  const [cashiers, setCashiers] = useState([]);
  const [boxes, setBoxes] = useState([]);
  const [filteredBoxes, setFilteredBoxes] = useState([]);

  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [boxOpeningId, setBoxOpeningId] = useState(0);
  const [branchId, setBranchId] = useState(0);
  const [boxId, setBoxId] = useState(0);
  const [cashierId, setCashierId] = useState(0);
  const [date, setDate] = useState("");
  const [initialAmount, setInitialAmount] = useState(0);
  const [closing, setClosing] = useState("");
  const [openDetail, setOpenDetail] = useState(false);
  const [sales, setSales] = useState([]);
  const [companyId, setCompanyId] = useState(user.companyId);
  const { width } = useViewport();
  async function fetchBoxOpenings() {
    let pageQuery = page === undefined ? 1 : page;

    setIsFetching(true);

    let boxOpeningsRequest = await axiosAuth.get("/boxopenings?page=" + pageQuery+"&companyId=" + user.companyId);

    setIsFetching(false);

    if (boxOpeningsRequest.data.error) {
      if (boxOpeningsRequest.data.message === "token") {
        await axiosAuth.post("/logout");
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }
    ///estoy alzando de vuelta apertura de cajasssss
    setBoxOpenings(boxOpeningsRequest.data.boxopenings.rows);
    setTotal(boxOpeningsRequest.data.boxopenings.count);
    setPages(boxOpeningsRequest.data.boxopenings.pages);
    setBranches(boxOpeningsRequest.data.branches);
    setBoxes(boxOpeningsRequest.data.boxes);
    setCashiers(boxOpeningsRequest.data.cashiers.rows);

    let newFilteredBoxes = [];
    boxOpeningsRequest.data.boxes.map(bx => {
      if (bx.branchId == boxOpeningsRequest.data.branches[0].id) {
        newFilteredBoxes.push(bx);
      }
    })
    setFilteredBoxes(newFilteredBoxes);
  }

  async function fetchDetail(boxIdParam, idaper, fecha) {
    let pageQuery = page === undefined ? 1 : page;
    setIsFetching(true);
    if (fecha == '0000-00-00 00:00:00' || fecha == null) {
      let salesRequest = await axiosAuth.get(
        "/sales?page=" + pageQuery + "&boxId=" + boxIdParam + "&ccaja=0&companyId=" + user.companyId
      );
      setSales(salesRequest.data.sales);
    }
    else {
      let salesRequest = await axiosAuth.get(
        "/sales?page=" + pageQuery + "&boxId=" + boxIdParam + "&ccaja=" + idaper + "&companyId=" + user.companyId
      );
      setSales(salesRequest.data.sales);
    }


    setIsFetching(false);
    setOpenDetail(true);
  }

  useEffect(() => {

    let newFilteredBoxes = [];
    boxes.map(bx => {
      if (bx.branchId == branchId) {
        newFilteredBoxes.push(bx);
      }
    })
    setFilteredBoxes(newFilteredBoxes);
  }, [branchId]);

  useEffect(() => {
    fetchBoxOpenings();
    dispatch(setTitle('Apertura de Cajas'));

  }, []);

  useEffect(() => {
    fetchBoxOpenings();
  }, [location]);


  const editBox = (boxId) => {
    setAction("edit");
    setBoxOpeningId(boxId);

    let box = boxOpenings.filter((box) => box.id === boxId)[0];

    setBranchId(box.branchId);
    setBoxId(box.boxId);
    setCashierId(box.cashierId);
    setDate(box.date);
    setInitialAmount(box.initialAmount);
    setClosing(box.closing);

    setOpen(true);
  };

  const clearFields = () => {
    setBranchId(0);
    setBoxId(0);
    setCashierId(0);
    setDate("");
    setInitialAmount(0);
    setClosing("");
  };

  const handleSubmit = async () => {
    if (branchId === 0 || boxId === 0 || cashierId === 0 || date == "") {
      alert("Debes completar los campos para continuar.");
      return false;
    }

    if (action === "edit") {
      let id = boxOpeningId;
      let newBoxOpeningsObj = [];

      let updateBoxRequest = await axiosAuth.put("/boxopenings", {
        id,
        branchId,
        boxId,
        cashierId,
        companyId,
        date,
        initialAmount: Number(String(initialAmount).replaceAll(",", "")),
        closing,
      });

      if (updateBoxRequest.data.error) {
        if (updateBoxRequest.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      boxOpenings.map((box) => {
        if (box.id == id) {
          newBoxOpeningsObj.push(updateBoxRequest.data);
        } else {
          newBoxOpeningsObj.push(box);
        }
        return "";
      });

      setBoxOpenings(newBoxOpeningsObj);
    } else {
      const createBoxRequest = await axiosAuth.post("/boxopenings", {
        branchId,
        boxId,
        cashierId,
        companyId,
        date: new Date(),
        initialAmount: Number(String(initialAmount).replaceAll(",", "")),
      });

      if (createBoxRequest.data.error) {
        if (createBoxRequest.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }
      fetchBoxOpenings();
      //let newBoxOpenings = [...boxOpenings];
      //newBoxOpenings.push(createBoxRequest.data);
      //setBoxOpenings(...boxOpenings,createBoxRequest.data);
      //  console.log(boxOpenings); 

    }

    setOpen(false);
    clearFields();
  };

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };

  const totalSum = sales.reduce((acc, sale) => {
    let saleTotal = sale.salesdetails.reduce((sum, detail) => sum + detail.price * detail.quantity, 0);
    return sale.saleconditionId == 1 && sale.state != 1 ? acc + saleTotal : acc;
  }, 0);

  const totalSumcredito = sales.reduce((acc, sale) => {
    let saleTotal = sale.salesdetails.reduce((sum, detail) => sum + detail.price * detail.quantity, 0);
    return sale.saleconditionId == 2 && sale.state != 1 ? acc + saleTotal : acc;
  }, 0);



  return (
    <>
      <Layout>
        <Transition.Root show={openDetail} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10 "
            onClose={() => {
              clearFields();
              setOpen(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-40 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto align-items-center 
            justify-center items-center flex pb-5">
              <div className="flex justify-center p-4 text-center sm:p-0 !w-[100%]">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <DialogPanel
                    className="relative transform 
                    overflow-hidden rounded-0 bg-white text-left shadow-xl transition-all
                    sm:w-[90%]
                    sm:my-8 w-full"
                    style={{
                      marginTop: 40,
                    }}
                  >
                    <DialogTitle
                      as="h1"
                      className="text-center mt-1 text-lg font-regular leading-6 primary-color text-3xl"
                    >

                      {<div class="flex sm:flex-row flex-col justify-center">
                        <div class="basis-1/8 font-bold uppercase sm:mr-20 " >
                          {sales.length > 0 &&
                            sales[0].company?.name}
                        </div>
                      </div>}

                      <div class="flex sm:flex-row flex-col">
                        <div class="basis-1/8 font-bold uppercase sm:mr-20 sm:ml-5" >Sucursal:{sales.length > 0 &&
                          sales[0].branch?.description}</div>
                        <div class="basis-1/8 font-bold uppercase" >Caja:{sales.length > 0 &&
                          sales[0].box?.name}</div>

                      </div>


                    </DialogTitle>
                    <Transition.Child
                      enter="ease-out duration-500"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Disclosure as="div" className="w-[98%] sm:w-[98%] 
                                     mx-auto bg-white rounded-lg shadow-md 
                                     overflow-hidden">

                        <DisclosureButton className="w-full flex sm:w-full
                                justify-between items-center px-4 py-2 text-
                                sm font-medium text-left text-gray-900 bg-gray-600
                                  hover:bg-gray-500 focus:outline-none focus-visible:ring 
                                focus-visible:ring-gray-500 focus-visible:ring-opacity-75">Ventas al Contado
                          <ChevronDownIcon className="w-5 group-data-[open]:rotate-180" />
                        </DisclosureButton>

                        <DisclosurePanel as="div" className="px-4 py-2 text-sm text-gray-700
                       bg-gray-100 rounded-b-lg w-full sm:w-full">

                          <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-0 w-full">

                            <div className="sm:flex sm:items-start w-full  " >
                              <div className="mt-0 text-center sm:mt-0 sm:text-left w-full  ">

                                <div className="mt-[-12] sm:mt-[-12] w-full  h-[400px]  overflow-auto"  >
                                  <table className="table-auto mx-auto  text-xs">
                                    <thead className="bg-gray-600 sticky top-0">
                                      {width > 1024 ? (
                                        <tr>
                                          <th className="uppercase !text-white w-[8%]">Nro Factura</th>
                                          <th className="uppercase !text-white w-[8%]">Fecha</th>
                                          <th className="uppercase !text-white  text-left">
                                            Cliente
                                          </th>
                                          <th className="uppercase !text-white text-right ">Total</th>
                                          <th className="uppercase !text-white ">Forma de pago</th>
                                        </tr>
                                      ) : (
                                        <></>
                                      )}
                                    </thead>
                                    <tbody>
                                      {sales.length > 0 &&
                                        sales.map((sale) => {
                                          let saleTotal = 0;
                                          let metodos = "";
                                          sale.salesdetails.map((detail) => {
                                            saleTotal += detail.price * detail.quantity;
                                          });
                                          sale.payments.map((m) => {
                                            metodos += m.paymentmethod.name + " (" + numberWithCommas(m.amount) + ") ";
                                          });

                                          if (width > 1024) {

                                            if (sale.saleconditionId == 1) {
                                              return (
                                                <tr
                                                  key={sale.id}
                                                  className={`!text-[10px] ${sale.state == 1 ? "!bg-red-200" : ""
                                                    }`}
                                                >
                                                  <td className="uppercase">
                                                    {sale.expeditionPoint + " " + sale.number}
                                                  </td>
                                                  <td className="uppercase">
                                                    {sale.updatedAt
                                                      .substr(0, 10)
                                                      .split("-")
                                                      .reverse()
                                                      .join("/")}
                                                  </td>
                                                  <td className=" uppercase text-left">
                                                    {sale.client.name}
                                                  </td>
                                                  <td className="text-right">
                                                    {sale.state != 1 ? numberWithCommas(saleTotal) : 0}
                                                  </td>
                                                  <td>
                                                    {sale.state != 1 ? metodos : 0}
                                                  </td>

                                                </tr>
                                              );
                                            }

                                          } else {
                                            if (sale.saleconditionId == 1) {
                                              return (
                                                <tr style={{ borderBottom: "1.5px solid lightgray" }}
                                                  key={sale.id}
                                                  className={` !text-[10px] ${sale.state == 1 ? "!bg-red-200" : ""
                                                    } `}
                                                >
                                                  <div className="w-full flex flex-col ">
                                                    <div className=" w-[100%] flex flex-row">
                                                      <label className="font-bold">FACTURA: </label>
                                                      {sale.expeditionPoint + " " + sale.number}
                                                    </div>
                                                    <div className=" w-full flex flex-row">
                                                      <label className="font-bold">FECHA: </label>
                                                      {sale.updatedAt
                                                        .substr(0, 10)
                                                        .split("-")
                                                        .reverse()
                                                        .join("/")}
                                                      <div />
                                                    </div>
                                                    <div className=" w-full flex flex-row">
                                                      <label className="font-bold">CLIENTE: </label>
                                                      {sale.client.name}
                                                    </div>
                                                    <div className=" w-full flex flex-row">
                                                      <label className="font-bold">TOTAL: </label>
                                                      {sale.state != 1 ? numberWithCommas(saleTotal) : 0}
                                                    </div>
                                                    <div className=" w-full flex flex-row">
                                                      <div className=" w-[90px] flex flex-row" >
                                                        <label className="font-bold">FORMA DE PAGO: </label>
                                                      </div>
                                                      <div className="mb-1  w-[180px] flex flex-row" >
                                                        {sale.state != 1 ? metodos : 0}
                                                      </div>



                                                    </div>
                                                  </div>


                                                </tr>
                                              );
                                            }
                                          }

                                        }

                                        )

                                      }

                                      {sales.length <= 0 && (
                                        <tr>
                                          <td></td>
                                          <td colSpan={10} className="text-center">
                                            Sin registros (0)
                                          </td>
                                        </tr>
                                      )}

                                    </tbody>
                                    
                                    <tfoot class="bg-gray-600 sticky bottom-[-1]">
                                    
                                    
                                      <tr  >
                                      
                                        <td class="sm:py-0 sm:px-0 border-t" ></td>
                                        <td class="sm:py-0 sm:px-0 border-t" ></td>
                                        <td class="sm:py-0 sm:px-0 border-t" ></td>
                                        <td class="sm:py-1 py-1 px-0 sm:px-0 border-t sm:text-right !text-white font-bold
                                          " >TOTAL:{numberWithCommas(totalSum)}</td>
                                        <td class="py-2 px-10 border-t" ></td>
                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>



                              </div>
                            </div>
                          </div>


                        </DisclosurePanel>

                      </Disclosure>

                    </Transition.Child>
                    <Transition.Child
                      enter="ease-out duration-500"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Disclosure as="div" className="w-[98%] sm:w-[98%] mt-1
                                     mx-auto bg-white rounded-lg shadow-md 
                                     overflow-hidden">

                        <DisclosureButton className="w-full flex sm:w-full
                                justify-between items-center px-4 py-2 text-
                                sm font-medium text-left text-gray-900 bg-gray-600
                                  hover:bg-gray-500 focus:outline-none focus-visible:ring 
                                focus-visible:ring-gray-500 focus-visible:ring-opacity-75">Ventas a Crédito
                          <ChevronDownIcon className="w-5 group-data-[open]:rotate-180" />
                        </DisclosureButton>

                        <DisclosurePanel as="div" className="px-4 py-2 text-sm text-gray-700 bg-gray-100 rounded-b-lg w-full sm:w-full">

                          <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-0 w-full">

                            <div className="sm:flex sm:items-start w-full">
                              <div className="mt-0 text-center sm:mt-0 sm:text-left w-full "
                              >

                                <div className="h-[400px] overflow-auto mt-[-12] sm:mt-[-12]">
                                  <table className=" table-auto

">
                                    <thead className="bg-gray-600 sticky top-0">
                                      {width > 1024 ? (
                                        <tr>
                                          <th className="uppercase !text-white w-[8%]">Nro Factura</th>
                                          <th className="uppercase !text-white w-[8%]">Fecha</th>
                                          <th className="uppercase !text-white  text-left">
                                            Cliente
                                          </th>
                                          <th className="uppercase !text-white text-right ">Totales</th>
                                          <th className="uppercase !text-white ">Forma de pago</th>

                                        </tr>
                                      ) : (
                                        <></>
                                      )}
                                    </thead>
                                    <tbody>
                                      {sales.length > 0 &&
                                        sales.map((sale) => {
                                          let saleTotal = 0;
                                          let metodos = "";
                                          sale.salesdetails.map((detail) => {
                                            saleTotal += detail.price * detail.quantity;
                                          });
                                          sale.payments.map((m) => {
                                            metodos += m.paymentmethod.name + " (" + numberWithCommas(m.amount) + ") ";
                                          });

                                          if (width > 1024) {

                                            if (sale.saleconditionId == 2) {
                                              return (
                                                <tr
                                                  key={sale.id}
                                                  className={`!text-[10px] ${sale.state == 1 ? "!bg-red-200" : ""
                                                    }`}
                                                >
                                                  <td className="uppercase">
                                                    {sale.expeditionPoint + " " + sale.number}
                                                  </td>
                                                  <td className="uppercase">
                                                    {sale.updatedAt
                                                      .substr(0, 10)
                                                      .split("-")
                                                      .reverse()
                                                      .join("/")}
                                                  </td>
                                                  <td className=" uppercase text-left">
                                                    {sale.client.name}
                                                  </td>
                                                  <td className="text-right">
                                                    {sale.state != 1 ? numberWithCommas(saleTotal) : 0}
                                                  </td>
                                                  <td>
                                                    {sale.state != 1 ? metodos : 0}
                                                  </td>

                                                </tr>
                                              );
                                            }


                                          } else {
                                            if (sale.saleconditionId == 2) {
                                              return (
                                                <tr style={{ borderBottom: "1.5px solid lightgray" }}
                                                  key={sale.id}
                                                  className={` !text-[10px] ${sale.state == 1 ? "!bg-red-200" : ""
                                                    } `}
                                                >
                                                  <div className="w-full flex flex-col ">
                                                    <div className=" w-[100%] flex flex-row">
                                                      <label className="font-bold">FACTURA: </label>
                                                      {sale.expeditionPoint + " " + sale.number}
                                                    </div>
                                                    <div className=" w-full flex flex-row">
                                                      <label className="font-bold">FECHA: </label>
                                                      {sale.updatedAt
                                                        .substr(0, 10)
                                                        .split("-")
                                                        .reverse()
                                                        .join("/")}
                                                      <div />
                                                    </div>
                                                    <div className=" w-full flex flex-row">
                                                      <label className="font-bold">CLIENTE: </label>
                                                      {sale.client.name}
                                                    </div>
                                                    <div className=" w-full flex flex-row">
                                                      <label className="font-bold">TOTAL: </label>
                                                      {sale.state != 1 ? numberWithCommas(saleTotal) : 0}
                                                    </div>
                                                    <div className=" w-full flex flex-row">
                                                      <div className=" w-[90px] flex flex-row" >
                                                        <label className="font-bold">FORMA DE PAGO: </label>
                                                      </div>
                                                      <div className="mb-1  w-[180px] flex flex-row" >
                                                        {sale.state != 1 ? metodos : 0}
                                                      </div>



                                                    </div>
                                                  </div>


                                                </tr>
                                              );
                                            }
                                          }
                                        })}
                                      {sales.length <= 0 && (
                                        <tr>
                                          <td></td>
                                          <td colSpan={10} className="text-center">
                                            Sin registros (0)
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                    <tfoot class="bg-gray-600 sticky bottom-[-1]">

                                      <tr  >
                                        <td class="py-2 px-4 border-t" ></td>
                                        <td class="py-2 px-4 border-t" ></td>
                                        <td class="py-2 px-4 border-t" ></td>
                                        <td class="py-1 px-0 border-t text-right !text-white font-bold" >TOTAL:{numberWithCommas(totalSumcredito)}</td>
                                        <td class="py-2 px-4 border-t" ></td>
                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>



                              </div>
                            </div>
                          </div>


                        </DisclosurePanel>

                      </Disclosure>

                    </Transition.Child>


                    <div className="bg-gray-50 px-2 py-2 justify-end flex sm:px-6">
                      <button
                        type="button"
                        className="float-left inline-flex w-[68px] h-[60px] 
                        ml-[-0px] flex-col justify-center items-center rounded 
                        ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => {
                          setOpenDetail(false);
                        }}
                      >
                        <img src={door} className="w-5" />
                        SALIR
                      </button>
                    </div>
                  </DialogPanel>
                </Transition.Child>
              </div>
            </div>




          </Dialog>
        </Transition.Root>


        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              clearFields();
              setOpen(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto align-items-center justify-center items-center flex">
              <div className="flex justify-center p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative sm:mt-[75px] mt-[25px] mb-[55px] transform overflow-hidden rounded-3xl bg-white text-left shadow-xl transition-all">
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-0">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                          <Dialog.Title
                            as="h1"
                            className="text-center font-regular leading-6 primary-color text-3xl"
                          >
                            {action === "edit" ? "Editar" : "Crear"} caja
                          </Dialog.Title>
                          <div className="mt-5">
                            <div className="w-full">
                              <form className="bg-white rounded">
                                {action === "edit" && (
                                  <div className="mb-4">
                                    <label
                                      className="block text-gray-700 text-sm font-bold"
                                      htmlFor="boxId"
                                    >
                                      Id
                                    </label>
                                    <input
                                      readOnly
                                      className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      id="boxId"
                                      type="text"
                                      value={boxOpeningId}
                                    />
                                  </div>
                                )}

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="branch"
                                  >
                                    Sucursal
                                  </label>
                                  <select
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                    id="branch"
                                    type="text"
                                    value={branchId}
                                    onChange={(e) => setBranchId(e.target.value)}
                                  >
                                    <option value={0}>Elija una sucursal</option>
                                    {branches.map(br => (
                                      <option value={br.id}>{br.description}</option>
                                    ))}
                                  </select>
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="box"
                                  >
                                    Caja
                                  </label>
                                  <select
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="box"
                                    type="text"
                                    value={boxId}
                                    onChange={(e) => setBoxId(e.target.value)}
                                  >
                                    <option value={0}>Elija una caja</option>
                                    {filteredBoxes.map(fb => (
                                      <option value={fb.id}>{fb.name}</option>
                                    ))}
                                  </select>
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="cashier"
                                  >
                                    Cajero
                                  </label>
                                  <select
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="cashier"
                                    type="cashier"
                                    value={cashierId}
                                    onChange={(e) => setCashierId(e.target.value)}
                                  >
                                    <option value={0}>Elija un cajero</option>
                                    {cashiers.map(csh => (
                                      <option value={csh.id}>{csh.fullname}</option>
                                    ))}
                                  </select>
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="date"
                                  >
                                    Fecha
                                  </label>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="date"
                                    type="datetime"
                                    value={date}
                                    readOnly
                                    disabled
                                  />
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="initialAmount"
                                  >
                                    Monto inicial
                                  </label>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="initialAmount"
                                    type="text"
                                    value={initialAmount}
                                    onChange={(e) =>
                                      setInitialAmount(numberWithCommas(e.target.value))
                                    }
                                  />
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="closing"
                                  >
                                    Cierre (Fecha)
                                  </label>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="closing"
                                    type="datetime"
                                    value={closing}
                                    readOnly
                                    disabled
                                  />
                                </div>

                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 justify-center flex sm:px-6">
                      {action == 'edit' &&
                        <button
                          type="button"
                          className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                          style={{ alignItems: "center" }}
                          onClick={async () => {
                            const closingBoxRequest = await axiosAuth.put("/closeBox", { boxOpeningId, boxId });
                            setClosing(closingBoxRequest.data.closing);
                            let newBoxOpenings = [];
                            boxOpenings.map(bo => {
                              if (bo.id == boxOpeningId) {
                                newBoxOpenings.push(closingBoxRequest.data);
                              } else {
                                newBoxOpenings.push(bo);
                              }
                            });
                            setBoxOpenings(newBoxOpenings);
                            // clearFields();
                            // setOpen(false);
                          }}
                        >
                          <img src={forbidden} className="w-5" />
                          CIERRE
                        </button>
                      }

                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => handleSubmit()}
                      >
                        <img src={floppy} className="w-8" />
                        GUARDAR
                      </button>

                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => {
                          clearFields();
                          setOpen(false);
                        }}
                      >
                        <img src={door} className="w-5" />
                        CERRAR
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <div className="w-full sm:mt-[2px] sm:top-[-30px] flex flex-col sm:flex-row pb-0 relative bg-white " style={{ borderBottom: "1px solid lightgray" }}>

          <div className="sm:w-[350px] w-[60%]" style={{ borderRight: "1px solid lightgray" }}>
            <h1 className="ml-5 mt-0 secondary-color title font-family-thin">
              Apertura y Cierre de Cajas
            </h1>
            <DateComponent className="sm:block hidden" />{" "}
          </div>
          {/* <button
          className="rounded-md secondary-bg-color px-2 py-1 float-right relative right-10 bottom-10 cursor-pointer title-button mt-8"
          onClick={() => {
            setAction("create");
            setOpen(true);
          }}
        >
          Crear caja
        </button> */}
          <div className="w-[100px] sm:w-[100px] sm:mt-[15px] mt-[-30px] mb-1 h-[55px]
                 sm:ml-[300px]
          " >
            <button
              type="button"
              className="relative right-[25px] sm:right-[0px] 
              inline-flex w-[68px] float-right  flex-col top-[3px]
              justify-center items-center rounded ternary-bg-color px-3 py-2
              !text-sm text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
              style={{ alignItems: "center" }}
              onClick={() => {
                setAction("create");
                let date = new Date().toLocaleDateString();
                let hours = new Date().getHours() + ":" + new Date().getMinutes();
                setDate(date + " " + hours);
                setOpen(true);
              }}
            >
              <img src={box} className="w-8" />
              ABRIR
            </button>
          </div>

        </div>

        <div className="w-[97%] p-0 mx-auto overflow-auto relative sm:mt-[-25px] sm:max-h-[60vh] max-h-[60vh]">
          <table className="w-[97%] table-auto mx-auto w-full text-xs ">
            <thead className="bg-gray-600">
              {width > 1024 ? (
                <tr>
                  <th className="uppercase !text-white">Id</th>
                  <th className="uppercase !text-white">Sucursal</th>
                  <th className="uppercase !text-white">Caja</th>
                  <th className="uppercase !text-white">Cajero</th>
                  <th className="uppercase !text-white">Monto Inicial</th>
                  <th className="uppercase !text-white">Fecha Apertura</th>
                  <th className="uppercase !text-white">Fecha Cierre</th>
                  <th className="uppercase !text-white"></th>

                </tr>
              ) : (<></>
              )}

            </thead>
            <tbody>
              {isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={8}>
                    <Lottie
                      animationData={loadingAnimation}
                      style={{ width: 100, height: 100, margin: "0 auto" }}
                      loop={true}
                    />
                  </td>
                </tr>
              )}
              {boxOpenings.length > 0 &&
                !isFetching &&
                boxOpenings?.map((box) => {
                  if (width > 1024) {
                    return (
                      <tr key={box.id}>
                        <td>{box.id}</td>
                        <td className="text-center">{box.branch.description}</td>
                        <td className="text-center">{box.box.name}</td>
                        <td className="text-center">{box.cashier.fullname}</td>
                        <td className="text-center">{numberWithCommas(box.initialAmount)}</td>
                        <td>{box.date
                          .substr(0, 10)
                          .split("-")
                          .reverse()
                          .join("/") + " " + (box.date
                            .substr(12, 19)
                            .split("-")
                            .reverse()
                            .join("/")
                          )

                        }</td>
                        <td>{box.closing}</td>
                        <td className="text-center">
                          <button
                            className="bg-transparent"
                            onClick={() => {
                              editBox(box.id);
                            }}
                          >
                            <FontAwesomeIcon
                              className="m-1 cursor-pointer secondary-color"
                              icon={faPen}
                            />
                          </button>
                          <button
                            className="bg-transparent"
                            onClick={() => {
                              fetchDetail(box.box.id, box.id, box.closing);

                            }}
                          >
                            <FontAwesomeIcon
                              className="m-1 cursor-pointer secondary-color"
                              icon="fa-solid fa-eye"
                            />
                          </button>

                        </td>

                      </tr>
                    );
                  } else {
                    return (

                      <tr key={box.id} style={{ borderBottom: "2px solid lightgray" }}>
                        <div className="w-[100%] flex flex-row" >


                          <div className="w-[70%]">
                            <label className="font-bold">ID: </label>
                            {box.id}
                            <br />
                            <label className="font-bold">SUCURSAL: </label>
                            {box.branch.description}
                            <br />
                            <label className="font-bold">CAJA: </label>
                            {box.box.name}
                            <br />
                            <label className="font-bold">CAJERO: </label>
                            {box.cashier.fullname}
                            <br />
                            <label className="font-bold">MONTO INICIAL: </label>
                            {numberWithCommas(box.initialAmount)}
                            <br />
                            <label className="font-bold">FECHA APERTURA: </label>
                            {box.date
                              .substr(0, 10)
                              .split("-")
                              .reverse()
                              .join("/") + " " + (box.date
                                .substr(12, 19)
                                .split("-")
                                .reverse()
                                .join("/")
                              )

                            }
                            <br />
                            <label className="font-bold">FECHA CIERRE: </label>
                            {box.closing}
                            <br />
                          </div>
                          <div className="w-[15%] content-center">

                            <button
                              className="bg-transparent"
                              onClick={() => {
                                editBox(box.id);
                              }}
                            >
                              <FontAwesomeIcon
                                className="m-1 cursor-pointer secondary-color"
                                icon={faPen}
                              />
                            </button>
                            <button
                              className="m-[1px] bg-transparent"
                              onClick={() => {
                                fetchDetail(box.box.id, box.id, box.closing);

                              }}
                            >
                              <FontAwesomeIcon
                                className="m-1 cursor-pointer secondary-color"
                                icon="fa-solid fa-eye"
                              />
                            </button>
                          </div>
                        </div>


                      </tr>


                    );
                  }

                })}
              {boxOpenings.length <= 0 && !isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={8} className="text-center">
                    Sin registros (0)
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <Pagination path="aperturasdeca" page={page} pages={pages} total={total} />
        </div>
      </Layout>
    </>
  );
};

export default BoxOpenings;
