import React, { useState, Fragment, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Dialog, Transition } from "@headlessui/react";
import Layout from "../layout";
import { axiosAuth } from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser } from "../store/slices/mainSlice";
import { useLocation, useParams } from "react-router-dom";
import Pagination from "../components/pagination";
import DateComponent from "../components/date";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/animations/loading.json";
import bank from "../assets/img/bank.png";
import door from "../assets/img/door.png";
import floppy from "../assets/img/floppy.png";

const Banks = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [action, setAction] = useState("edit");

  const {page, search } = useParams();
  const user = useSelector((state) => state.main.user);

  const [banks, setBanks] = useState([]);

  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);

  const [bankId, setBankId] = useState(0);
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [active, setActive] = useState(1);
  const [deleted, setDeleted] = useState(0);
  const [searchText, setSearchText] = useState(search || "");
  async function fetchBanks() {
    let pageQuery = page === undefined ? 1 : page;

    setIsFetching(true);

    let banks = await axiosAuth.get("/banks?page=" + pageQuery);

    setIsFetching(false);
    let bank = await axiosAuth.get(
      "/banks?page=" + pageQuery + "&search=" + search
    );
    if (banks.data.error) {
      if (banks.data.message === "token") {
        await axiosAuth.post("/logout");
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    setBanks(banks.data.banks.rows);
    setTotal(banks.data.banks.count);
    setPages(banks.data.banks.pages);

  }

  useEffect(() => {
    fetchBanks();
  }, []);

  useEffect(() => {
    fetchBanks();
  }, [location]);

  const editBank = (bankId) => {
    setAction("edit");
    setBankId(bankId);

    let bank = banks.filter((bank) => bank.id === bankId)[0];

    setName(bank.name);
    setEmail(bank.email);
    setAddress(bank.address);
    setPhone(bank.phone);
    setActive(bank.active);
    setDeleted(bank.deleted);

    setOpen(true);
  };

  const clearFields = () => {
    setBankId(0);
    setName("");
    setEmail("");
    setAddress("");
    setPhone("");
    setActive(1);
    setDeleted(0);
  };

  const handleSubmit = async () => {
    if (name === "" || email === "" || address === "" || phone === "") {
      alert("Debes completar los campos para continuar.");
      return false;
    }

    if (action === "edit") {
      let id = bankId;
      let newBanksObj = [];

      let updateBankRequest = await axiosAuth.put("/banks", {
        id,
        name,
        email,
        phone,
        address,
        active,
        deleted,
      });

      if (updateBankRequest.data.error) {
        if (updateBankRequest.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      banks.map((bank) => {
        if (bank.id == id) {
          newBanksObj.push(updateBankRequest.data);
        } else {
          newBanksObj.push(bank);
        }
        return "";
      });

      setBanks(newBanksObj);
    } else {
      let createBankRequest = await axiosAuth.post("/banks", {
        name,
        email,
        phone,
        address,
        active,
        deleted,
      });

      if (createBankRequest.data.error) {
        if (createBankRequest.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      let newBanks = [...banks];
      newBanks.push(createBankRequest.data);
      setBanks(newBanks);
    }

    setOpen(false);
  };
  const searchbanks = async (searchTxt) => {
    setSearchText((prev) => searchTxt);
    const searchbanksRequest = await axiosAuth.get(
      "/searchbanks?search=" + searchTxt
    );
   //console.log(searchbanksRequest);
    setBanks(searchbanksRequest.data.bank.rows);
    setPages(searchbanksRequest.data.bank.pages);
    setTotal(searchbanksRequest.data.bank.count);
  };

  const deleteBank = async (bankId) => {
    if (window.confirm("Desea eliminar este banco ?")) {
      let BankDeleteRequest = await axiosAuth.delete("/banks", {
        params: { id: bankId },
      });

      if (BankDeleteRequest.data.error) {
        if (BankDeleteRequest.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      let newBanksObj = [];

      banks.map((bank) => {
        if (bank.id !== bankId) {
          newBanksObj.push(bank);
        } else {
          if (user.roleId == 1) {
            bank.deleted = 1;
            newBanksObj.push(bank);
          }
        }
        return "";
      });

      setBanks(newBanksObj);
    }
  };

  return (
    <>
      <Layout>
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              clearFields();
              setOpen(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto align-items-center justify-center items-center flex">
              <div className="flex justify-center p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform sm:mt-[100px] mt-[0px] overflow-hidden bg-white text-left shadow-xl transition-all sm:my-8 w-[270px] pb-4 rounded-3xl">
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-0">
                      <div className="sm:flex sm:items-start w-full">
                        <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                          <Dialog.Title
                            as="h1"
                            className="text-center font-regular leading-6 primary-color text-3xl"
                          >
                            {action === "edit" ? "Editar" : "Crear"} banco
                          </Dialog.Title>
                          <div className="mt-5">
                            <div className="w-full">
                              <form className="bg-white rounded w-full">
                                {action === "edit" && (
                                  <div className="mb-4">
                                    <label
                                      className="block text-gray-700 text-sm font-bold"
                                      htmlFor="BankId"
                                    >
                                      Id
                                    </label>
                                    <input
                                      readOnly
                                      className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      id="BankId"
                                      type="text"
                                      value={bankId}
                                    />
                                  </div>
                                )}

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="name"
                                  >
                                    Nombre
                                  </label>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="bankname"
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                  />
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="address"
                                  >
                                    Direccion
                                  </label>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="address"
                                    type="text"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                  />
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="email"
                                  >
                                    Email
                                  </label>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="email"
                                    type="text"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                  />
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="phone"
                                  >
                                    Telefono
                                  </label>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="phone"
                                    type="text"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                  />
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="active"
                                  >
                                    Activa
                                  </label>
                                  <select
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="active"
                                    value={active}
                                    onChange={(e) =>
                                      setActive(parseInt(e.target.value))
                                    }
                                  >
                                    <option value={0}>No</option>
                                    <option value={1}>Si</option>
                                  </select>
                                </div>

                                {user.roleId == 1 && (
                                  <div className="mb-4">
                                    <label
                                      className="block text-gray-700 text-sm font-bold"
                                      htmlFor="deleted"
                                    >
                                      Borrado
                                    </label>
                                    <select
                                      className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      id="deleted"
                                      value={deleted}
                                      onChange={(e) =>
                                        setDeleted(parseInt(e.target.value))
                                      }
                                    >
                                      <option value={0}>No</option>
                                      <option value={1}>Si</option>
                                    </select>
                                  </div>
                                )}
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 justify-center flex sm:px-6">

                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => handleSubmit()}
                      >
                        <img src={floppy} className="w-8" />
                        GUARDAR
                      </button>
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => {
                          clearFields();
                          setOpen(false);
                        }}
                      >
                        <img src={door} className="w-5" />
                        CERRAR
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
        <div class="w-full top-[-50px] flex flex-col sm:flex-row pb-2 relative bg-white "
          style={{ borderBottom: "1px solid lightgray" }}>

          <div className="sm:w-[30%] w-[60%]">
            <h1 className="ml-5 mt-5 text-3xl text-white title secondary-color font-family-thin">
              Bancos
            </h1>
            <DateComponent className="sm:block hidden" />
          </div>
          <div class="sm:w-[50%] w-[85%] mb-[1px] sm:ml-0 ml-6 flex flex-row items-center w-200 sm:mt-2 mt-12">
            <label className="block text-gray-700 text-[10px] font-bold uppercase mr-[1px] sm:mt-5">
              Buscar:
            </label>
            <input
              value={searchText}
              className="input-bg-color appearance-none border w-[350px]  rounded  
            py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline leading-[10px] h-[35px] sm:mt-5"
              placeholder="Nombre del Banco"
              onChange={(e) => searchbanks(e.target.value)}
            />
            <button
              type="button"
              className="inline-flex flex-row float-right sm:w-[170px] w-[30%] mr-1 justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 relative left-[10px] sm:mt-5 h-[35px]"
              style={{ alignItems: "center" }}
              onClick={() => {
                setAction("create");
                setOpen(true);
              }}
            >
              <img src={bank} className="w-8" />
              CREAR BANCO
            </button>
          </div>
        </div>
        <div className="w-[95%] p-5 mx-auto overflow-auto sm:max-h-[70vh] max-h-[40vh] mt-[-50px]">
          <table className="w-[95%] table-auto mx-auto w-full text-[10px] support-table">
            <thead>
              <tr className="bg-gray-500 h-8">
                <th className="uppercase !text-white text-left">Id</th>
                <th className="uppercase w-80 !text-white text-left">Nombre</th>
                <th className="uppercase !text-white text-left">Direccion</th>
                <th className="uppercase !text-white text-left">Email</th>
                <th className="uppercase !text-white text-left">Telefono</th>
                <th className="uppercase !text-white text-center">Activa</th>
                {user.roleId == 1 && <th className="uppercase !text-white text-center">Borrado</th>}
                <th className="uppercase !text-white text-center ">Fecha Alta</th>
                <th className="uppercase !text-white text-center">Accion</th>
              </tr>
            </thead>
            <tbody>
              {isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={18}>
                    <Lottie
                      animationData={loadingAnimation}
                      style={{ width: 100, height: 100, margin: "0 auto" }}
                      loop={true}
                    />
                  </td>
                </tr>
              )}
              {banks.length > 0 &&
                !isFetching &&
                banks?.map((bank) => {
                  return (
                    <tr key={bank.id}>
                      <td>{bank.id}</td>
                      <td className="text-left whitespace-nowrap">
                        {bank.name}
                      </td>
                      <td className="text-left whitespace-nowrap">{bank.address}</td>
                      <td className="text-left whitespace-nowrap">{bank.email}</td>
                      <td className="text-left">{bank.phone}</td>
                      <td className="text-center">{bank.active ? "Si" : "No"}</td>
                      {user.roleId == 1 && (
                        <td className="text-center">{bank.deleted ? "Si" : "No"}</td>
                      )}
                      <td>
                        {bank.createdAt
                          .substr(0, 10)
                          .split("-")
                          .reverse()
                          .join("/")}
                      </td>
                      <td className="text-center">
                        <button
                          className="bg-transparent"
                          onClick={() => {
                            editBank(bank.id);
                          }}
                        >
                          <FontAwesomeIcon
                            className="m-1 secondary-color cursor-pointer"
                            icon={faPen}
                          />
                        </button>
                        <button
                          className="bg-transparent"
                          onClick={() => {
                            deleteBank(bank.id);
                          }}
                        >
                          <FontAwesomeIcon
                            className="m-1 secondary-color cursor-pointer"
                            icon={faTrash}
                          />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              {banks.length <= 0 && !isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={12} className="text-center">
                    Sin registros (0)
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <Pagination path="bancos" page={page} pages={pages} total={total} />
        </div>
      </Layout>
    </>
  );
};

export default Banks;
