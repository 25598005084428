import React, { useEffect, useState } from "react";
import { axiosAuth } from "../utils/axios";
import Layout from "../layout";
import ComboBox from "../components/combobox";

const CreateSubscriptionModal = ({ isOpen, onClose, onCreate }) => {
  const [plan, setPlan] = useState("");
  const [clientId, setClientId] = useState("");
  const [startDate, setStartDate] = useState("");

  const [clients, setClients] = useState([]);
  const [plans, setPlans] = useState([]);

  const searchClients = async (search) => {
    try {
      const response = await axiosAuth.get(`/searchClients?search=${search}`);
      setClients(response.data.clients.rows);
    } catch (error) {
      console.error("Error al buscar clientes:", error);
    }
  };

  const searchPlans = async (search) => {
    try {
      const response = await axiosAuth.get(`/searchPlans?search=${search}`);
      setPlans(response.data.plans.rows);
    } catch (error) {
      console.error("Error al buscar planes:", error);
    }
  };

  const handleCreate = () => {
    if (plan && clientId && startDate) {
      onCreate({ plan, client: clientId, startDate });
      onClose();
    } else {
      alert("Por favor completa todos los campos.");
    }
  };

  return isOpen ? (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
        <h3 className="text-xl font-semibold mb-4">Crear Suscripción</h3>
        <label className="block mb-2">
          Cliente:
          <ComboBox
            data={clients}
            setData={setClientId}
            value={clientId}
            placeholder="Selecciona un cliente"
            className="h-[22px] w-full text-[10px]"
            search={searchClients}
          />
        </label>
        <label className="block mb-2">
          Plan:
          <ComboBox
            data={plans}
            setData={setPlan}
            value={plan}
            placeholder="Selecciona un plan"
            className="h-[22px] w-full text-[10px]"
            search={searchPlans}
          />
        </label>
        <label className="block mb-4">
          Fecha de Inicio:
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded mt-1"
          />
        </label>
        <div className="flex justify-end">
          <button
            onClick={handleCreate}
            className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
          >
            Crear
          </button>
          <button
            onClick={onClose}
            className="bg-gray-500 text-white px-4 py-2 rounded"
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>
  ) : null;
};

const SubscriptionReports = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    axiosAuth.get("/subscriptions")
      .then((response) => {
        setSubscriptions(response.data.subscriptions);
      })
      .catch((error) => console.error("Error al cargar los datos:", error));
  }, []);

  const handleCreateSubscription = (newSubscription) => {
    setSubscriptions([...subscriptions, { ...newSubscription, id: subscriptions.length + 1, endDate: new Date().toISOString().split('T')[0], status: 'Activo' }]);
  };

  const calculateRates = (subscriptions) => {
    if (subscriptions.length === 0)
      return { resubscriptionRate: 0, newSubscriberGrowthRate: 0 };

    const currentDate = new Date();
    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const endOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );

    const currentMonthSubscriptions = subscriptions.filter(
      (sub) =>
        new Date(sub.startDate) <= endOfMonth &&
        new Date(sub.endDate) >= startOfMonth
    );

    const resubscriptionRate = currentMonthSubscriptions.filter(
      (sub) => new Date(sub.firstSubscriptionDate) < startOfMonth
    ).length;

    const newSubscriberGrowthRate = currentMonthSubscriptions.filter(
      (sub) => new Date(sub.firstSubscriptionDate) >= startOfMonth
    ).length;

    return {
      resubscriptionRate,
      newSubscriberGrowthRate,
      totalCurrentSubscriptions: currentMonthSubscriptions.length,
    };
  };

  const {
    resubscriptionRate,
    newSubscriberGrowthRate,
    totalCurrentSubscriptions,
  } = calculateRates(subscriptions);

  const totalSubscribers = subscriptions.length;
  const inactiveSubscribers = totalSubscribers - totalCurrentSubscriptions;

  return (
    <Layout>
      <div className="p-5 font-sans">
        <h2 className="text-2xl font-bold mb-6 flex items-center justify-between">
          Reportes de Suscripciones
        </h2>
        <div className="mb-6 grid grid-cols-3 gap-4">
          <div className="bg-gray-100 p-5 rounded-lg shadow-lg">
            <div className="text-xl font-semibold mb-2">Crecimiento Mensual</div>
            <div className="text-2xl font-bold">
              {newSubscriberGrowthRate}
            </div>
            <div
              className="h-4 bg-blue-500 rounded mt-2"
              style={{
                width: `${
                  newSubscriberGrowthRate > 100
                    ? 100
                    : newSubscriberGrowthRate
                }%`,
              }}
            ></div>
            <div className="mt-2 text-lg text-gray-600">
              {newSubscriberGrowthRate > 0
                ? `+${newSubscriberGrowthRate}% desde el mes pasado`
                : `0.00% desde el mes pasado`}
            </div>
          </div>

          <div className="bg-gray-100 p-5 rounded-lg shadow-lg">
            <h2 className="text-xl font-semibold mb-2">
              Tasa de Re-suscripción Mensual
            </h2>
            <div className="text-2xl font-bold">{resubscriptionRate}</div>
            <div
              className="h-4 bg-green-500 rounded mt-2"
              style={{ width: `${resubscriptionRate}%` }}
            ></div>
            <div className="mt-2 text-lg text-green-600">
              +70.59% desde el mes pasado
            </div>
          </div>

          <div className="bg-gray-100 p-5 rounded-lg shadow-lg">
            <h2 className="text-xl font-semibold mb-2">Suscriptores Inactivos</h2>
            <div className="text-2xl font-bold">{inactiveSubscribers}</div>
          </div>
        </div>

        <div>
          <h2 className="text-xl font-semibold mb-4 flex items-center justify-between">
            Suscripciones
            <button
              onClick={() => setIsModalOpen(true)}
              className="bg-blue-500 text-white px-4 py-2 rounded shadow"
            >
              Crear Suscripción
            </button>
          </h2>
          <div className="w-[97%] max-h-[75vh] mx-auto overflow-x-auto">
            <div className="overflow-y-auto max-h-[75vh]">
              <table className="w-full table-auto mx-auto border-collapse bg-white shadow-md rounded-lg">
                <thead className="sticky top-0 bg-gray-500">
                  <tr className="text-white">
                    <th className="!text-white border p-2 text-left">ID</th>
                    <th className="!text-white border p-2 text-left">Usuario</th>
                    <th className="!text-white border p-2 text-left">
                      Primera Suscripción
                    </th>
                    <th className="!text-white border p-2 text-left">
                      Fecha de Inicio
                    </th>
                    <th className="!text-white border p-2 text-left">
                      Fecha de Fin
                    </th>
                    <th className="!text-white border p-2 text-left">Estado</th>
                    <th className="!text-white border p-2 text-left">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {subscriptions.map((sub) => (
                    <tr key={sub.id}>
                      <td className="border p-2">{sub.id}</td>
                      <td className="border p-2">{sub.user}</td>
                      <td className="border p-2">
                        {sub.firstSubscriptionDate}
                      </td>
                      <td className="border p-2">{sub.startDate}</td>
                      <td className="border p-2">{sub.endDate}</td>
                      <td className="border p-2">{sub.status}</td>
                      <td className="border p-2">
                        <button
                          className="text-blue-500 hover:text-blue-700"
                          onClick={() => alert(`Editar suscripción ${sub.id}`)}
                        >
                          Editar
                        </button>
                        <button
                          className="text-red-500 hover:text-red-700 ml-4"
                          onClick={() => alert(`Eliminar suscripción ${sub.id}`)}
                        >
                          Eliminar
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <CreateSubscriptionModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onCreate={handleCreateSubscription}
        />
      </div>
    </Layout>
  );
};

export default SubscriptionReports;
