import React, { useState, Fragment, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Dialog, Transition } from "@headlessui/react";
import Layout from "../layout";
import { axiosAuth } from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser,setTitle  } from "../store/slices/mainSlice";
import { useLocation, useParams } from "react-router-dom";
import Pagination from "../components/pagination";
import DateComponent from "../components/date";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/animations/loading.json";
import ComboBox from "../components/combobox";
import contactimg from "../assets/img/contacts.png";
import door from "../assets/img/door.png";
import { motion } from "framer-motion";
import floppy from "../assets/img/floppy.png";
import plus from "../assets/img/plus.png";
import useViewport from "../utils/useViewport";

const Contacts = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [action, setAction] = useState("edit");

  const { page } = useParams();
  const user = useSelector((state) => state.main.user);

  const [contacts, setContacts] = useState([]);
  const [clients, setClients] = useState([]);

  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);

  const [contactId, setContactId] = useState(0);
  const [clientId, setClientId] = useState(0);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [deleted, setDeleted] = useState(0);

  async function fetchContacts() {
    let pageQuery = page === undefined ? 1 : page;

    setIsFetching(true);

    let contactsRequest = await axiosAuth.get("/contacts?page=" + pageQuery);

    if (contactsRequest.data.error) {
      if (contactsRequest.data.message === "token") {
        await axiosAuth.post("/logout");
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    setIsFetching(false);

    setContacts(contactsRequest.data.contacts.rows);
    setTotal(contactsRequest.data.contacts.count);
    setPages(contactsRequest.data.contacts.pages);
    setClients(contactsRequest.data.clients.rows);
  }

  useEffect(() => {
    fetchContacts();
  }, []);

  useEffect(() => {
    fetchContacts();
    dispatch(setTitle('Contactos'));
  }, [location]);

  const editContact = (contactId) => {
    setAction("edit");
    setContactId(contactId);

    let contact = contacts.filter((contact) => contact.id === contactId)[0];

    setClientId(contact.clientId);
    setName(contact.name);
    setPhone(contact.phone);
    setEmail(contact.email);
    setDeleted(contact.deleted);

    setOpen(true);
  };

  const clearFields = () => {
    setContactId(0);
    setClientId(0);
    setName("");
    setPhone("");
    setEmail("");
    setDeleted(0);
  };

  const handleSubmit = async () => {
    if (name === "") {
      alert("Debes completar los campos para continuar.");
      return false;
    }

    if (action === "edit") {
      let id = contactId;
      let newContactsObj = [];

      let updateContactRequest = await axiosAuth.put("/contacts", {
        id,
        clientId,
        name,
        phone,
        email,
        deleted,
      });

      if (updateContactRequest.data.error) {
        if (updateContactRequest.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      contacts.map((contact) => {
        if (contact.id == id) {
          newContactsObj.push(updateContactRequest.data);
        } else {
          newContactsObj.push(contact);
        }
        return "";
      });

      setContacts(newContactsObj);
    } else {
      let createContactRequest = await axiosAuth.post("/contacts", {
        clientId,
        name,
        phone,
        email,
        deleted,
      });

      if (createContactRequest.data.error) {
        if (createContactRequest.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      let newContacts = [...contacts];
      newContacts.push(createContactRequest.data);
      setContacts(newContacts);
    }

    setOpen(false);
  };

  const deleteContact = async (contactId) => {
    if (window.confirm("Desea eliminar este contacto ?")) {
      let contactDeleteRequest = await axiosAuth.delete("/contacts", {
        params: { id: contactId },
      });

      if (contactDeleteRequest.data.error) {
        if (contactDeleteRequest.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      let newContactsObj = [];

      contacts.map((contact) => {
        if (contact.id !== contactId) {
          newContactsObj.push(contact);
        } else {
          if (user.roleId == 1) {
            contact.deleted = 1;
            newContactsObj.push(contact);
          }
        }
        return "";
      });

      setContacts(newContactsObj);
    }
  };

  const searchClients = async (search) => {
    const searchClientsRequest = await axiosAuth.get(
      "/searchClients?search=" + search
    );

    setClients(searchClientsRequest.data.clients.rows);
  };

  return (
    <>
      <Layout>
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              clearFields();
              setOpen(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto align-items-center justify-center items-center flex">
              <div className="flex justify-center p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel
                    className="relative transform overflow-hidden rounded-3xl bg-white text-left shadow-xl transition-all sm:my-8 w-[280px]"
                    style={{ marginTop: 25 }}
                  >
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-0">
                      <div className="sm:flex sm:items-start w-full">
                        <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                          <Dialog.Title
                            as="h1"
                            className="text-center font-regular leading-6 primary-color text-3xl"
                          >
                            {action === "edit" ? "Editar" : "Crear"} contacto
                          </Dialog.Title>
                          <div className="mt-5">
                            <div className="w-full">
                              <form className="bg-white rounded">
                                {action === "edit" && (
                                  <div className="mb-4">
                                    <label
                                      className="block text-gray-700 text-sm font-bold"
                                      htmlFor="ContactId"
                                    >
                                      Id
                                    </label>
                                    <input
                                      readOnly
                                      className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      id="ContactId"
                                      type="text"
                                      value={contactId}
                                    />
                                  </div>
                                )}

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="client"
                                  >
                                    Cliente
                                  </label>
                                  <ComboBox
                                    data={clients}
                                    setData={setClientId}
                                    value={clientId}
                                    search={searchClients}
                                  />
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="name"
                                  >
                                    Nombre
                                  </label>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                    id="name"
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                  />
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="name"
                                  >
                                    Telefono
                                  </label>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                    id="phone"
                                    type="text"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                  />
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="name"
                                  >
                                    Email
                                  </label>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="email"
                                    type="text"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                  />
                                </div>

                                {user.roleId == 1 && (
                                  <div className="mb-4">
                                    <label
                                      className="block text-gray-700 text-sm font-bold"
                                      htmlFor="deleted"
                                    >
                                      Borrado
                                    </label>
                                    <select
                                      className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      id="deleted"
                                      value={deleted}
                                      onChange={(e) =>
                                        setDeleted(parseInt(e.target.value))
                                      }
                                    >
                                      <option value={0}>No</option>
                                      <option value={1}>Si</option>
                                    </select>
                                  </div>
                                )}
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 justify-center flex sm:px-6">
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => handleSubmit()}
                      >
                        <img src={floppy} className="w-8" />
                        GUARDAR
                      </button>
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => {
                          clearFields();
                          setOpen(false);
                        }}
                      >
                        <img src={door} className="w-5" />
                        CERRAR
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
        <div
          class="w-full top-[-50px] flex flex-col sm:flex-row pb-2 relative bg-white "
          style={{ borderBottom: "1px solid lightgray" }}
        >
          <div className="sm:w-[30%] w-[60%]">
            <h1 className="ml-5 mt-7 secondary-color  font-family-thin">
              Contactos
            </h1>
            <DateComponent className="sm:block hidden" />{" "}
          </div>
          <div class="sm:w-[50%] w-[85%] mb-[1px] sm:ml-0 ml-6 flex flex-row items-center w-200 sm:mt-2 mt-12">
            <motion.button
              initial={false}
              whileTap="press"
              onClick={() => {
                setAction("create");
                setOpen(true);
              }}
              className="inline-flex flex-row float-right sm:w-[170px] w-[30%] mr-1 justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 relative left-[600px] sm:mt-5 h-[35px] "
            >
              {/*<Suspense fallback={null}>
                <BoxesIcon isHover={true} />
            </Suspense>*/}
              <img alt="" src={plus} className="w-8" />
              CREAR CONTACTO
            </motion.button>
          </div>
        </div>
        <div className="w-[95%] p-6 mx-auto overflow-auto sm:max-h-[70vh] max-h-[70vh] mt-[-50px]">
          <table className="w-[95%] table-auto mx-auto w-full text-[10px] support-table">
            <thead className="bg-gray-500 h-8">
              <tr>
                <th className="uppercase text-left !text-white">Id</th>
                <th className="uppercase text-left !text-white">Cliente</th>
                <th className="uppercase text-left !text-white">Nombre</th>
                <th className="uppercase text-left !text-white">Telefono</th>
                <th className="uppercase text-left !text-white">Email</th>
                {user.roleId == 1 && (
                  <th className="uppercase !text-white">Borrado</th>
                )}
                <th className="uppercase text-left !text-white">Fecha Alta</th>
                <th className="uppercase !text-white">Accion</th>
              </tr>
            </thead>
            <tbody>
              {isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={8}>
                    <Lottie
                      animationData={loadingAnimation}
                      style={{ width: 100, height: 100, margin: "0 auto" }}
                      loop={true}
                    />
                  </td>
                </tr>
              )}
              {contacts.length > 0 &&
                !isFetching &&
                contacts?.map((contact) => {
                  return (
                    <tr key={contact.id}>
                      <td className="uppercase text-left ">{contact.id}</td>
                      <td className="uppercase text-left ">
                        {contact.client.name}
                      </td>
                      <td className="uppercase text-left ">{contact.name}</td>
                      <td className="uppercase text-left ">{contact.phone}</td>
                      <td className="uppercase text-left ">{contact.email}</td>
                      {user.roleId == 1 && (
                        <td>{contact.deleted ? "Si" : "No"}</td>
                      )}
                      <td>
                        {contact?.createdAt
                          ?.substr(0, 10)
                          .split("-")
                          .reverse()
                          .join("/")}
                      </td>
                      <td className="text-center">
                        <button
                          className="bg-transparent"
                          onClick={() => {
                            editContact(contact.id);
                          }}
                        >
                          <FontAwesomeIcon
                            className="m-1 cursor-pointer secondary-color"
                            icon={faPen}
                          />
                        </button>
                        <button
                          className="bg-transparent"
                          onClick={() => {
                            deleteContact(contact.id);
                          }}
                        >
                          <FontAwesomeIcon
                            className="m-1 cursor-pointer secondary-color"
                            icon={faTrash}
                          />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              {contacts.length <= 0 && !isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={8} className="text-center">
                    Sin registros (0)
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <Pagination
            path="contactos"
            page={page}
            pages={pages}
            total={total}
          />
        </div>
      </Layout>
    </>
  );
};

export default Contacts;
