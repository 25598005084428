import React, { useState, Fragment, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faTrash,
  faPlus,
  faL,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { Dialog, Transition, Tab } from "@headlessui/react";
import Layout from "../layout";
import { axiosAuth } from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser } from "../store/slices/mainSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Pagination from "../components/pagination";
import DateComponent from "../components/date";
import ComboBox from "../components/combobox";
import useViewport from "../utils/useViewport";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/animations/loading.json";
import toast from "react-hot-toast";
import { numberWithCommas } from "../utils/constants";
import discard from "../assets/img/discard.png";
import door from "../assets/img/door.png";
import pdf from "../assets/img/pdf.png";
import printer from "../assets/img/printer.png";

const CollectionsViewer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [action, setAction] = useState("edit");

  const { width } = useViewport();

  const { page } = useParams();
  const user = useSelector((state) => state.main.user);

  const [collection, setCollection] = useState([]);

  const [collections, setCollections] = useState([]);

  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);

  const [collectionId, setCollectionId] = useState(0);
  const [state, setState] = useState(0);

  const [dateFromFilter, setDateFromFilter] = useState(() => {
    let MyDateString =
      (
        "0" +
        new Date(new Date().getFullYear(), new Date().getMonth(), 1).getDate()
      ).slice(-2) +
      "/" +
      (
        "0" +
        (new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          1
        ).getMonth() +
          1)
      ).slice(-2) +
      "/" +
      new Date(new Date().getFullYear(), new Date().getMonth(), 1)
        .getFullYear()
        

    return MyDateString.replaceAll(".", "")
      .replaceAll("/", "-")
      .split("-")
      .reverse()
      .join("-");
  });
  const [dateToFilter, setDateToFilter] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
      .toLocaleDateString()
      .replaceAll("/", "-")
      .split("-")
      .map((d) => (String(d).length == 1 ? "0" + String(d) : d))
      .reverse()
      .join("-")
  );

  async function fetchCollections() {
    let pageQuery = page === undefined ? 1 : page;
    setIsFetching(true);

    let collectionsRequest = await axiosAuth.get(
      "/collectionsviewer?page=" +
        pageQuery +
        "&companyId=" +
        user.companyId +
        "&dateFrom=" +
        dateFromFilter +
        "&dateTo=" +
        dateToFilter
    );

    setIsFetching(false);

    if (collectionsRequest.data.error) {
      if (collectionsRequest.data.message === "token") {
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    setCollections(collectionsRequest.data.collections);
    // setTotal(salesRequest.data.sales.count);
    // setPages(salesRequest.data.sales.pages);
  }

  useEffect(() => {
    fetchCollections();
  }, []);

  useEffect(() => {
    fetchCollections();
  }, [location]);

  const editCollection = (collectionId) => {
    setOpen(true);
    setCollectionId(collectionId);
    collections.map((collectionObj) => {
      if (collectionObj.id == collectionId) {
        setCollection(collectionObj);
      }
    });
  };

  return (
    <>
      <Layout>
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10 w-full sales-modal"
            onClose={() => {
              setOpen(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto align-items-center justify-center items-center flex">
              <div className="flex justify-center p-4 text-center sm:p-0 w-[90%] sm:w-[50%]">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel
                    className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-full overflow-y-auto min-h-[40vh]"
                    style={{ marginTop: 0 }}
                  >
                    {collection.state == 1 && (
                      <div
                        className="w-full h-full fixed top-[0] left-[0] flex flex-col justify-center items-center"
                        style={{
                          zIndex: 9,
                          backgroundColor: "rgba(0,0,0,0.3)",
                        }}
                      >
                        {" "}
                        <h1
                          className="font-bold rotate-[-45deg]"
                          style={{ fontSize: "70px", color: "darkred" }}
                        >
                          ANULADO
                        </h1>
                      </div>
                    )}

                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-0 text-center">
                      <div className="w-full">
                        <Dialog.Title
                          as="h1"
                          className="text-center font-regular leading-6 primary-color text-2xl"
                        >
                          Cobros
                        </Dialog.Title>
                        <div className="mt-5">
                          <div className="w-full flex flex-col pt-2 overflow-auto">
                            <table className="mt-4 support-table">
                              <thead>
                                <tr className="bg-gray-500 h-8">
                                  <td className="text-center !text-white">Nro Fact.</td>
                                  <td className="text-center !text-white">Num.</td>
                                  <td className="text-center !text-white">Monto</td>
                                  {/* <td className="text-center sm:block hidden">
                                    Pagado
                                  </td> */}
                                  <td className="text-center !text-white">Cobrado</td>
                                </tr>
                              </thead>
                              <tbody>
                                {collection != [] &&
                                  collection?.collectionsdetails?.length > 0 &&
                                  collection?.collectionsdetails?.map((det) => {
                                    return (
                                      <tr className="text-xs sm:text-md">
                                        <td className="text-center whitespace-nowrap overflow-hidden">
                                          {collection.expeditionPoint}
                                          {" - "}
                                          {det?.sale?.number || 0}
                                        </td>
                                        <td className="text-center">
                                          {det.due.number}
                                        </td>
                                        {/* <td className="text-center"></td> */}
                                        <td className="text-center">
                                          {numberWithCommas(det.amount)}
                                        </td>
                                        {/* <td className="text-center sm:block hidden">{numberWithCommas(det.due.paid)}</td> */}
                                        {/* <td className="text-center sm:block hidden"></td> */}
                                        <td className="text-center">
                                          {numberWithCommas(det.amount)}
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>

                            <div className="px-4 py-2 justify-center flex">
                              {collection?.state == 0 && (
                                <button
                                  type="button"
                                  className="inline-flex flex-col w-[63px] h-[60px] mr-1 justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                                  onClick={async () => {
                                    if (
                                      window.confirm(
                                        "Desea anular este cobro ?"
                                      )
                                    ) {
                                      let cancelCollection =
                                        await axiosAuth.put(
                                          "/cancelCollection",
                                          {
                                            id: collectionId,
                                          }
                                        );

                                      let newCollections = [...collections];
                                      newCollections.map((newCollection) => {
                                        if (newCollection.id == collectionId) {
                                          newCollection.state = 1;
                                          newCollection.collectionsdetails.map(
                                            (det) => {
                                              // console.log(det);
                                              //det.payment.state = 1;
                                            }
                                          );
                                          setCollection(newCollection);
                                        }
                                      });
                                      setCollections(newCollections);
                                      toast.success(
                                        "Cobro anulado correctamente!"
                                      );
                                    }
                                  }}
                                >
                                  <img src={discard} className="w-6" />
                                  ANULAR
                                </button>
                              )}

                              <button
                                type="button"
                                className="inline-flex flex-col mr-1 !w-[63px] h-[60px] justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                                onClick={() => {
                                  window.open(
                                    "/cobranzasimprimir/" + collectionId
                                  );
                                }}
                              >
                                
                                <img src={printer} className="w-6" />
                                IMPRIMIR
                              </button>
                              <button
                                type="button"
                                className="inline-flex flex-col mr-1 !w-[63px] h-[60px] justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                                onClick={() => {
                                  window.open(
                                    "/cobranzasrecibo/" + collectionId
                                  );
                                }}
                              >
                                <img src={pdf} className="w-6" />
                                PDF
                              </button>

                              <button
                                type="button"
                                className="inline-flex flex-col !w-[63px] h-[60px] justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                                onClick={() => {
                                  setOpen(false);
                                }}
                              >
                                <img src={door} className="w-4" />
                                SALIR
                              </button>

                              {/* <button
                                type="button"
                                disabled={action == "edit" ? false : true}
                                readOnly={action == "edit" ? false : true}
                                className="inline-flex w-[120px] align-center align-items-center justify-center rounded-3xl primary-color-bg px-3 py-1 text-sm font-semibold shadow-sm m-1 font-family-thin"
                                style={{ alignItems: "center" }}
                                onClick={() => {
                                  window.open("/facturapdf/" + saleId);
                                }}
                              >
                                CREAR PDF
                              </button> */}
                              {/* <button
                                type="button"
                                disabled={action == "edit" ? false : true}
                                readOnly={action == "edit" ? false : true}
                                className="inline-flex w-[80px] align-center align-items-center justify-center rounded-3xl primary-color-bg px-3 py-1 text-sm font-semibold shadow-sm m-1 font-family-thin"
                                style={{ alignItems: "center" }}
                                onClick={() => {
                                  window.open("/imprimirfactura/" + saleId);
                                }}
                              >
                                IMPRIMIR
                              </button> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <h1
          id="titlebig"
          className="ml-5 mt-5 text-3xl title font-family-thin secondary-color"
        >
          Visor de Cobros
        </h1>
        <DateComponent className="sm:block hidden" />
        <div className="flex flex-row w-full justify-end">
          <div className="w-[80%] sm:w-[40%] float-right flex flex=row pr-12">
            <input
              className="input-bg-color appearance-none border w-[50%] text-gray-700 leading-tight capitalize h-[25px] p-[6px] mr-[1px]"
              id="date"
              value={dateFromFilter}
              type="date"
              onChange={(e) => {
                setDateFromFilter(e.target.value);
              }}
            />

            <input
              className="input-bg-color appearance-none border w-[50%] text-gray-700 leading-tight capitalize h-[25px] p-[6px]"
              id="date"
              value={dateToFilter}
              type="date"
              onChange={(e) => {
                setDateToFilter(e.target.value);
              }}
              onBlur={() => fetchCollections()}
            />
          </div>
        </div>

        <div className="w-[95%] min-w-[780px] max-h-[70vh] p-5 mx-auto overflow-auto">
          <table className="w-[95%] table-auto mx-auto w-full text-xs mt-5 relative support-table">
            <thead>
              <tr className="bg-gray-500 h-8">
                <th className="text-left !text-white">Nro.</th>
                <th className="text-left !text-white">Cliente</th>
                <th className="text-left !text-white">Sucursal</th>
                <th className="text-left !text-white">Caja</th>
                <th className="text-left !text-white">Cobrador</th>
                <th className="text-left !text-white">Moneda</th>
                <th className="text-left !text-white">Monto</th>
                <th className="text-left !text-white">Fecha</th>
                <th className="text-left !text-white">Estado</th>
                <th className="text-left !text-white">Accion</th>
              </tr>
            </thead>
            <tbody className="relative">
              {isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={18}>
                    <Lottie
                      animationData={loadingAnimation}
                      style={{ width: 100, height: 100, margin: "0 auto" }}
                      loop={true}
                    />
                  </td>
                </tr>
              )}
              {collections.length > 0 &&
                !isFetching &&
                collections?.map((collection, i) => {
                  return (
                    <tr
                      key={collection.id}
                      className={`text-[10px] ${
                        collection?.state == 1 ? "bg-red-200" : ""
                      }`}
                    >
                      <td className="text-left uppercase">
                        {collection.expeditionPoint + " - " + collection.number}
                      </td>
                      <td className="text-left uppercase whitespace-nowrap overflow-hidden">
                        {collection.state != 1
                          ? collection.client.name
                          : "ANULADO"}
                      </td>
                      <td className="text-left uppercase">
                        {collection.state != 1
                          ? collection.branch.description
                          : "ANULADO"}
                      </td>
                      <td className="text-left uppercase">
                        {collection.state != 1
                          ? collection.box.name
                          : "ANULADO"}
                      </td>
                      <td className="text-left uppercase">
                        {collection.state != 1
                          ? collection.collector.fullname
                          : "ANULADO"}
                      </td>
                      <td className="text-left uppercase">
                        {collection.state != 1
                          ? collection.currency.name
                          : "ANULADO"}
                      </td>
                      <td className="text-left uppercase">
                        {collection.state != 1
                          ? numberWithCommas(collection.amount)
                          : 0}
                      </td>
                      <td>
                        {collection.createdAt
                          .substr(0, 10)
                          .split("-")
                          .reverse()
                          .join("/")}
                      </td>
                      <td>{collection.state == 1 ? "ANULADO" : "ACTIVO"}</td>
                      <td className="text-center">
                        <button
                          onClick={() => {
                            editCollection(collection.id);
                          }}
                          className="bg-transparent"
                        >
                          <FontAwesomeIcon
                            className="m-1 cursor-pointer secondary-color"
                            icon={faEye}
                          />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              {collections.length <= 0 && !isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={18} className="text-center">
                    Sin registros (0)
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Layout>
    </>
  );
};

export default CollectionsViewer;
