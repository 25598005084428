import React, { useState, Fragment, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash, faPuzzlePiece } from "@fortawesome/free-solid-svg-icons";
import { Dialog, Transition } from "@headlessui/react";
import Layout from "../layout";
import { axiosAuth } from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser, setTitle } from "../store/slices/mainSlice";
import { useLocation, useParams } from "react-router-dom";
import Pagination from "../components/pagination";
import DateComponent from "../components/date";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/animations/loading.json";
import itemtypeimg from "../assets/img/itemTypes.png";
import door from "../assets/img/door.png";
import floppy from "../assets/img/floppy.png";
import ComboBox from "../components/combobox";
import useViewport from "../utils/useViewport";
import { numberWithCommas } from "../utils/constants";

const Contracts = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [action, setAction] = useState("edit");

  const { page } = useParams();
  const user = useSelector((state) => state.main.user);

  const { width } = useViewport();

  const [contracts, setContracts] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [clients, setClients] = useState([]);
  const [articles, setArticles] = useState([]);

  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);

  const [contractsTotal, setContractsTotal] = useState(0);
  const [contractsAmountTotal, setContractsAmountTotal] = useState(0);
  const [contractId, setContractId] = useState(0);
  const [companyId, setCompanyId] = useState(user.companyId);
  const [clientId, setClientId] = useState(0);
  const [itemId, setItemId] = useState(0);
  const [amount, setAmount] = useState(0);
  const [vencimiento, setVencimiento] = useState(0);
  const [active, setActive] = useState(1);
  const [deleted, setDeleted] = useState(0);

  async function fetchContracts() {

    dispatch(setTitle("Contratos"));

    let pageQuery = page === undefined ? 1 : page;

    setIsFetching(true);

    let contractsRequest = await axiosAuth.get("/contracts?page=" + pageQuery);

    if (contractsRequest.data.error) {
      if (contractsRequest.data.message === "token") {
        await axiosAuth.post("/logout");
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    setIsFetching(false);

    setClients(contractsRequest.data.clients.rows);
    setArticles(contractsRequest.data.items.rows);

    setCompanies(contractsRequest.data.companies.rows);
    setContracts(contractsRequest.data.contracts.rows);
    setContractsTotal(contractsRequest.data.contracts.total);
    setContractsAmountTotal(numberWithCommas(contractsRequest.data.contracts.totalAmount));
    setTotal(contractsRequest.data.contracts.count);
    setPages(contractsRequest.data.contracts.pages);

    setCompanies(contractsRequest.data.companies.rows);
  }

  useEffect(() => {
    fetchContracts();
  }, []);

  useEffect(() => {
    fetchContracts();
  }, [location]);

  const editContract = (contractId) => {
    setAction("edit");
    setContractId(contractId);

    let contract = contracts.filter(
      (contract) => contract.id === contractId
    )[0];

    setCompanyId(contract.companyId);
    setClientId(contract.clientId);
    setItemId(contract.itemId);
    setAmount(contract.amount);
    setVencimiento(contract.vencimiento);
    setActive(contract.active);
    setDeleted(contract.deleted);

    setOpen(true);
  };

  const clearFields = () => {
    setContractId(0);
    setClientId(0);
    setItemId(0);
    setAmount(0);
    setDeleted(0);
  };

  const handleSubmit = async () => {
    if (clientId == 0 || itemId == 0 || amount == 0) {
      alert("Debes completar los campos para continuar.");
      return false;
    }

    if (action === "edit") {
      let id = contractId;
      let newContractsObj = [];

      let updateContractRequest = await axiosAuth.put("/contracts", {
        id,
        companyId,
        clientId,
        itemId,
        amount: Number(String(amount).replaceAll(",", "")),
        vencimiento,
        active,
        deleted,
      });

      if (updateContractRequest.data.error) {
        if (updateContractRequest.data.message === "token") {
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      contracts.map((contract) => {
        if (contract.id == id) {
          newContractsObj.push(updateContractRequest.data);
        } else {
          newContractsObj.push(contract);
        }
        return "";
      });

      setContracts(newContractsObj);
    } else {
      let createContractRequest = await axiosAuth.post("/contracts", {
        companyId,
        clientId,
        itemId,
        amount: Number(String(amount).replaceAll(",", "")),
        vencimiento,
        active,
        deleted,
      });

      if (createContractRequest.data.error) {
        if (createContractRequest.data.message === "token") {
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      let newContracts = [...contracts];
      if (createContractRequest.data.deleted == 0) {
        newContracts.push(createContractRequest.data);
        setContracts(newContracts);
      }
    }

    clearFields();
    setOpen(false);
    fetchContracts();
  };

  const deleteContract = async (contractId) => {
    if (window.confirm("Desea eliminar este contrato ?")) {
      let contractDeleteRequest = await axiosAuth.delete("/contracts", {
        params: { id: contractId },
      });

      if (contractDeleteRequest.data.error) {
        if (contractDeleteRequest.data.message === "token") {
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      let newContractsObj = [];

      contracts.map((contract) => {
        if (contract.id !== contractId) {
            newContractsObj.push(contract);
        } else {
          if (user.roleId == 1) {
            contract.deleted = 1;
            newContractsObj.push(contract);
          }
        }
        return "";
      });

      setContracts(newContractsObj);
      fetchContracts();
    }
  };

  const searchClients = async (search) => {
    const searchClientsRequest = await axiosAuth.get(
      "/searchClients?search=" + search
    );

    setClients(searchClientsRequest.data.clients.rows);
  };

  const searchItems = async (search) => {
    const searchItemsRequest = await axiosAuth.get(
      "/searchItems?search=" + search
    );

    setArticles(searchItemsRequest.data.items.rows);
  };

  return (
    <>
      <Layout>
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              clearFields();
              setOpen(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto align-items-center justify-center items-center flex">
              <div className="flex justify-center p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel
                    className="relative transform overflow-hidden rounded-3xl bg-white text-left shadow-xl transition-all sm:my-8 w-[280px]"
                    style={{ marginTop: 45 }}
                  >
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-0">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                          <Dialog.Title
                            as="h1"
                            className="text-center font-regular leading-6 primary-color text-3xl"
                          >
                            {action === "edit" ? "Editar" : "Crear"} contrato
                          </Dialog.Title>
                          <div className="mt-5">
                            <div className="w-full">
                              <form className="bg-white rounded">
                                {action === "edit" && (
                                  <div className="mb-4">
                                    <label
                                      className="block text-gray-700 text-sm font-bold"
                                      htmlFor="contractId"
                                    >
                                      Id
                                    </label>
                                    <input
                                      readOnly
                                      className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      id="contractId"
                                      type="text"
                                      value={contractId}
                                    />
                                  </div>
                                )}

                                {user.roleId == 1 && (
                                  <div className="mb-4">
                                    <label
                                      className="block text-gray-700 text-sm font-bold"
                                      htmlFor="companyId"
                                    >
                                      Empresa
                                    </label>
                                    <select
                                      className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                      id="companyId"
                                      type="text"
                                      value={companyId}
                                      onChange={(e) =>
                                        setCompanyId(e.target.value)
                                      }
                                    >
                                      <option>Elija una empresa</option>
                                      {companies.length > 0 &&
                                        companies.map((company) => (
                                          <option value={company.id}>
                                            {company.name}
                                          </option>
                                        ))}
                                    </select>
                                  </div>
                                )}

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="companyId"
                                  >
                                    Cliente
                                  </label>
                                  <ComboBox
                                    data={clients}
                                    setData={setClientId}
                                    value={clientId}
                                    placeholder="Cliente"
                                    className="h-[25px] mt-[2px] w-full min-w-[80px] mb-2"
                                    search={searchClients}
                                  />
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="companyId"
                                  >
                                    Articulo
                                  </label>
                                  <ComboBox
                                    data={articles}
                                    setData={setItemId}
                                    value={itemId}
                                    placeholder="Articulo"
                                    className="h-[25px] mt-[2px] w-full min-w-[80px] mb-2"
                                    search={searchItems}
                                  />
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="contractId"
                                  >
                                    Monto
                                  </label>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="amount"
                                    type="text"
                                    value={numberWithCommas(amount)}
                                    onChange={(e) => setAmount(e.target.value)}
                                  />
                                </div>
                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="contractId"
                                  >
                                    Dia de vencimiento
                                  </label>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="vencimiento"
                                    type="text"
                                    value={numberWithCommas(vencimiento)}
                                    onChange={(e) => setVencimiento(e.target.value)}
                                  />
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="active"
                                  >
                                    Activa
                                  </label>
                                  <select
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="active"
                                    value={active}
                                    onChange={(e) =>
                                      setActive(parseInt(e.target.value))
                                    }
                                  >
                                    <option value={0}>No</option>
                                    <option value={1}>Si</option>
                                  </select>
                                </div>

                                {user.roleId == 1 && (
                                  <div className="mb-4">
                                    <label
                                      className="block text-gray-700 text-sm font-bold"
                                      htmlFor="deleted"
                                    >
                                      Borrado
                                    </label>
                                    <select
                                      className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      id="deleted"
                                      value={deleted}
                                      onChange={(e) =>
                                        setDeleted(parseInt(e.target.value))
                                      }
                                    >
                                      <option value={0}>No</option>
                                      <option value={1}>Si</option>
                                    </select>
                                  </div>
                                )}
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 justify-center flex sm:px-6">
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => {
                          clearFields();
                          setOpen(false);
                        }}
                      >
                        <img src={door} className="w-5" />
                        CERRAR
                      </button>
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => handleSubmit()}
                      >
                        <img src={floppy} className="w-8" />
                        GUARDAR
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <div style={{ borderBottom: "1px solid lightgray" }} className="flex flex-row w-full relative top-[-35px] pb-3">

        <div>
        <h1 className="ml-5 mt-5 text-3xl secondary-color title font-family-thin">
          Contratos
        </h1>
        <DateComponent className="hidden sm:block" />
        </div>
        
        <div className="sm:w-[80%] w-[90%] flex flex-row justify-end items-center sm:pr-12">
        <div className="flex flex-col pr-4">
        <span className="!text-[10px] text-right"><b className="!text-[10px] uppercase">Total contratos:</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {contractsTotal}</span>
        <span className="!text-[10px] text-right"><b className="!text-[10px] uppercase">Total montos:</b> {contractsAmountTotal}</span>
        </div>
        <button
          type="button"
          className="relative inline-flex w-[68px] float-right flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 mt-4"
          style={{ alignItems: "center" }}
          onClick={() => {
            setAction("create");
            setOpen(true);
          }}
          >
          <FontAwesomeIcon className="m-1 cursor-pointer secondary-color !text-[25px]" icon={faPuzzlePiece} />
          AGREGAR
        </button>
          </div>
        </div>

        <div className="w-[95%] p-2 mx-auto overflow-auto sm:max-h-[80vh] max-h-[65vh] mt-[-30px]">
          <table className="w-[95%] table-auto mx-auto w-full text-xs">
            <thead>
              {width > 475 &&
              <tr className="bg-gray-600 h-[20px]">
                <th className="uppercase !text-white">Id</th>
                {user.roleId == 1 && <th className="uppercase !text-white">Empresa</th>}
                <th className="uppercase !text-white">Cliente</th>
                <th className="uppercase !text-white">Articulo</th>
                <th className="uppercase !text-white text-right">Monto</th>
                <th className="uppercase !text-white text-right">Dia Vence</th>
                {/* <th className="uppercase !text-white">Activa</th> */}
                {user.roleId == 1 && <th className="uppercase !text-white">Borrado</th>}
                <th className="uppercase !text-white">Fecha Alta</th>
                <th className="uppercase !text-white">Accion</th>
              </tr>
            }
            </thead>
            <tbody>
              {isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={8}>
                    <Lottie
                      animationData={loadingAnimation}
                      style={{ width: 100, height: 100, margin: "0 auto" }}
                      loop={true}
                    />
                  </td>
                </tr>
              )}
              {contracts.length > 0 &&
                !isFetching &&
                contracts?.map((contract) => {
                  if(width > 475){
                  return (
                    <tr key={contract.id}>
                      <td>{contract.id}</td>
                      {user.roleId == 1 && <td className="text-left">{contract.company.name}</td>}
                      <td className="text-left">{contract.client.name}</td>
                      <td className="text-left whitespace-nowrap">{contract.item.name}</td>
                      <td className="text-right">{numberWithCommas(contract.amount)}</td>
                      <td className="text-right">{contract.vencimiento}</td>
                      {/* <td>{contract.active ? "Si" : "No"}</td> */}
                      {user.roleId == 1 && (
                        <td>{contract.deleted ? "Si" : "No"}</td>
                      )}
                      <td>
                        {contract.createdAt
                          .substr(0, 10)
                          .split("-")
                          .reverse()
                          .join("/")}
                      </td>
                      <td className="text-center">
                        <button
                          className="bg-transparent"
                          onClick={() => {
                            editContract(contract.id);
                          }}
                        >
                          <FontAwesomeIcon
                            className="m-1 cursor-pointer secondary-color"
                            icon={faPen}
                          />
                        </button>
                        <button
                          className="bg-transparent"
                          onClick={() => {
                            deleteContract(contract.id);
                          }}
                        >
                          <FontAwesomeIcon
                            className="m-1 cursor-pointer secondary-color"
                            icon={faTrash}
                          />
                        </button>
                      </td>
                    </tr>
                  );
                  }else{
                    return (
                    <tr key={contract.id} style={{ borderBottom: "1px solid lightgray" }}>
                      <td className="text-left">
                      <b className="uppercase">Id: </b>{contract.id}<br />
                      <b className="uppercase">Cliente: </b>{contract.client.name}<br/>
                      <b className="uppercase">Articulo: </b>{contract.item.name}<br/>
                      <b className="uppercase">Monto: </b>{numberWithCommas(contract.amount)}<br/>
                      <b className="uppercase">Fecha:</b>{contract.createdAt
                          .substr(0, 10)
                          .split("-")
                          .reverse()
                          .join("/")}<br/>

                        <button
                          className="bg-transparent"
                          onClick={() => {
                            editContract(contract.id);
                          }}
                        >
                          <FontAwesomeIcon
                            className="m-1 !text-xl mr-6 cursor-pointer secondary-color"
                            icon={faPen}
                          />
                        </button>
                        <button
                          className="bg-transparent"
                          onClick={() => {
                            deleteContract(contract.id);
                          }}
                        >
                          <FontAwesomeIcon
                            className="m-1 !text-xl cursor-pointer secondary-color"
                            icon={faTrash}
                          />
                        </button>
                      </td>
                    </tr>
                    );
                  }
                })}
              {contracts.length <= 0 && !isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={8} className="text-center">
                    Sin registros (0)
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <Pagination
            path="contratos"
            page={page}
            pages={pages}
            total={total}
          />
        </div>
      </Layout>
    </>
  );
};

export default Contracts;
