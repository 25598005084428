import React, { useState, Fragment, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faTrash,
  faPlus,
  faL,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { Dialog, Transition, Tab } from "@headlessui/react";
import Layout from "../layout";
import { axiosAuth } from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser } from "../store/slices/mainSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Pagination from "../components/pagination";
import DateComponent from "../components/date";
import ComboBox from "../components/combobox";
import useViewport from "../utils/useViewport";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/animations/loading.json";
import toast from "react-hot-toast";
import { numberWithCommas } from "../utils/constants";
import noteimg from "../assets/img/note.png";

const SaleCreditNotes = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [action, setAction] = useState("edit");

  const { width } = useViewport();

  const { page } = useParams();
  const user = useSelector((state) => state.main.user);

  const [clients, setClients] = useState([]);
  const [client, setClient] = useState([]);
  const [currencies, setCurrencies] = useState([]);

  const [saleCreditNotes, setSaleCreditNotes] = useState([]);
  const [items, setItems] = useState([]);
  const [articles, setArticles] = useState([]);
  const [deposits, setDeposits] = useState([]);

  const [number, setNumber] = useState(user.box.number + 1);
  const [expeditionPoint, setExpeditionPoint] = useState(
    user.box.expeditionPoint
  );
  const [stamped, setStamped] = useState(user.box.stamped);

  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [state, setState] = useState(0);
  const [totalAmount, setTotalAmount] = useState();

  const [saleCreditNoteId, setSaleCreditNoteId] = useState(0);
  const [clientId, setClientId] = useState(0);
  const [currencyId, setCurrencyId] = useState(1);
  const [sellerId, setSellerId] = useState(user.id);
  const [companyId, setCompanyId] = useState(user.companyId);
  const [branchId, setBranchId] = useState(user.branchId);
  const [depositId, setDepositId] = useState(0);
  const [boxId, setBoxId] = useState(user.boxId);

  const [date, setDate] = useState(
    new Date().toLocaleDateString("en-GB").split("/").reverse().join("-")
  );

  const [deleted, setDeleted] = useState(0);

  async function fetchSaleCreditNotes() {
    let pageQuery = page === undefined ? 1 : page;
    setIsFetching(true);

    let saleCreditNotesRequest = await axiosAuth.get(
      "/saleCreditNotes?page=" +
        pageQuery +
        "&companyId=" +
        user.companyId +
        "&branchId=" +
        user.branchId
    );

    setIsFetching(false);

    if (saleCreditNotesRequest.data.error) {
      if (saleCreditNotesRequest.data.message === "token") {
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    // saleCreditNotessRequest.data.saleCreditNotess.clients.unshift({
    //   id: 0,
    //   name: "Elija un cliente",
    // });

    setClients(saleCreditNotesRequest.data.salecreditnotes.clients.rows);
    setCurrencies(saleCreditNotesRequest.data.salecreditnotes.currencies);
    setArticles(saleCreditNotesRequest.data.salecreditnotes.items.rows);

    setDeposits(saleCreditNotesRequest.data.salecreditnotes.deposits);

    setSaleCreditNotes(saleCreditNotesRequest.data.salecreditnotes.rows);
    setTotal(saleCreditNotesRequest.data.salecreditnotes.count);
    setPages(saleCreditNotesRequest.data.salecreditnotes.pages);
  }

  useEffect(() => {
    fetchSaleCreditNotes();
  }, []);

  useEffect(() => {
    fetchSaleCreditNotes();
  }, [location]);

  useEffect(() => {
    setClient(clients.filter((client) => client.id === clientId)[0]);
  }, [clientId]);

  useEffect(() => {
    let totalAmountSum = 0;

    items.map((item) => {
      totalAmountSum +=
        item.qty * Number(String(item.price).replaceAll(".", ""));
    });

    setTotalAmount(numberWithCommas(totalAmountSum));

    let isFull = true;

    items.map((item) => {
      if (item.name == "") {
        isFull = false;
      }
    });

    let newItems = [...items];

    if (isFull) {
      newItems.push({
        id: 0,
        qty: 0,
        name: "",
        price: 0,
        subtotal: 0,
        iva: "",
      });

      setItems(newItems);
    }
  }, [items]);

  const editSaleCreditNote = async (saleCreditNoteId) => {
    setAction("edit");
    setSaleCreditNoteId(saleCreditNoteId);

    let saleCreditNote = saleCreditNotes.filter(
      (saleCreditNotes) => saleCreditNotes.id === saleCreditNoteId
    )[0];

    setState(saleCreditNote.state);

    let filteredClient = clients.filter(
      (client) => client.id == saleCreditNote.clientId
    )[0];

    if (filteredClient == undefined) {
      let clientRequest = await axiosAuth.get(
        "/searchClientById?clientId=" + saleCreditNote.clientId
      );
      let newClients = [...clients];
      newClients.push(clientRequest.data.client);
      setClients(newClients);
      setClient(clientRequest.data.client);
    } else {
      setClient(filteredClient);
    }

    setClientId(saleCreditNote.clientId);
    setCurrencyId(saleCreditNote.currencyId);
    setDepositId(saleCreditNote.depositId);

    setNumber(saleCreditNote.box.number + 1);
    setExpeditionPoint(saleCreditNote.box.expeditionPoint);
    setStamped(saleCreditNote.box.stamped);

    setDate(saleCreditNote.createdAt.substr(0, 10));

    let newItems = [];

    saleCreditNote.salecreditnotesdetails.map((saleCreditNoteDetail) => {
      newItems.push({
        id: saleCreditNoteDetail.itemId,
        qty: saleCreditNoteDetail.quantity,
        name: saleCreditNoteDetail.item.name,
        price: saleCreditNoteDetail?.price,
        subtotal: saleCreditNoteDetail?.price * saleCreditNoteDetail.quantity,
        iva: "10%",
      });
    });

    if (5 > saleCreditNote.salecreditnotesdetails.length) {
      Array(8 - saleCreditNote.salecreditnotesdetails.length)
        .fill(0)
        .map((_, i) => {
          newItems.push({
            id: 0,
            qty: 0,
            name: "",
            price: 0,
            subtotal: 0,
            iva: "",
          });
        });
    }

    setItems(newItems);

    setDeleted(saleCreditNote.deleted);
    setOpen(true);
  };

  const clearFields = () => {
    setSaleCreditNoteId(0);
    setClientId(0);
    setCurrencyId(1);
    setDepositId(0);
    let newItems = [];

    Array(8)
      .fill(0)
      .map((_, i) => {
        newItems.push({
          id: 0,
          qty: 0,
          name: "",
          price: 0,
          subtotal: 0,
          iva: "",
        });
      });

    setItems(newItems);
    setDeleted(0);
  };

  const handleSubmit = async () => {
    if (action == "edit") {
      setOpen(false);
      return false;
    }

    if (
      clientId === 0 ||
      sellerId === 0 ||
      boxId === 0 ||
      currencyId === 0 ||
      branchId === 0 ||
      depositId === 0 ||
      companyId === 0
    ) {
      alert("Debes completar los campos para continuar.");
      return false;
    }

    var hasItems = false;
    var itemsToSend = [];

    items.map((item) => {
      if (item.id > 0) {
        hasItems = true;
        itemsToSend.push(item);
      }
    });

    if (!hasItems) {
      alert("Debes agregar articulos para continuar.");
      return false;
    }

    let createdSaleCreditNoteRequest = await axiosAuth.post(
      "/saleCreditNotes",
      {
        clientId,
        sellerId,
        boxId,
        currencyId,
        branchId,
        depositId,
        companyId,
        salecreditnotesdetail: itemsToSend,
        expeditionPoint,
        number,
        stamped,
        deleted,
      }
    );

    if (createdSaleCreditNoteRequest.data.error) {
      if (createdSaleCreditNoteRequest.data.message === "token") {
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    let newSaleCreditNotes = [...saleCreditNotes];
    newSaleCreditNotes.push(createdSaleCreditNoteRequest.data);
    setSaleCreditNotes(newSaleCreditNotes);

    toast.success("Nota de credito creada correctamente!");
    clearFields();
  };

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };

  const searchClients = async (search) => {
    const searchClientsRequest = await axiosAuth.get(
      "/searchClients?search=" + search
    );

    setClients(searchClientsRequest.data.clients.rows);
  };

  return (
    <>
      <Layout>
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10 w-full saleCreditNotess-modal"
            onClose={() => {
              clearFields();
              setOpen(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto align-items-center justify-center items-center flex">
              <div className="flex justify-center p-4 text-center sm:p-0 w-[95%] h-[90%]">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel
                    className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-full overflow-y-auto"
                    style={{ marginTop: 0 }}
                  >
                    {state == 1 && (
                      <div
                        className="w-full h-full absolute top-[0] left-[0] flex flex-col justify-center items-center"
                        style={{
                          zIndex: 9,
                          backgroundColor: "rgba(0,0,0,0.5)",
                        }}
                      >
                        {" "}
                        <h1
                          className="font-black rotate-[-45deg]"
                          style={{ fontSize: "70px", color: "red" }}
                        >
                          ANULADO
                        </h1>
                      </div>
                    )}

                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-0 text-center">
                      <div className="w-full">
                        <Dialog.Title
                          as="h1"
                          className="text-center font-regular leading-6 primary-color text-3xl"
                        >
                          Notas de credito (Ventas)
                        </Dialog.Title>
                        <div className="mt-5">
                          <div className="w-full flex flex-col">
                            {/* {action === "edit" && (
                                  <div className="mb-4">
                                    <label
                                      className="block text-gray-700 text-sm font-bold"
                                      htmlFor="SaleCreditNoteId"
                                    >
                                      Id
                                    </label>
                                    <input
                                      readOnly
                                      className="input-bg-color appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      id="SaleCreditNoteId"
                                      type="text"
                                      value={saleCreditNotesId}
                                    />
                                  </div>
                                )} */}

                            {width > 550 ? (
                              <>
                                <div className="flex flex-row w-full">
                                  <div className="w-[33.8%]">
                                    <div className="mb-[2px]">
                                      <ComboBox
                                        data={clients}
                                        setData={setClientId}
                                        value={clientId}
                                        placeholder="Cliente"
                                        className="h-[30px]"
                                        search={searchClients}
                                      />
                                    </div>

                                    <div className="mb-[2px]">
                                      <input
                                        className="input-bg-color appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                        id="RUC"
                                        type="text"
                                        disabled
                                        readOnly
                                        value={client?.ruc || ""}
                                        placeholder="RUC"
                                        onChange={(e) => {}}
                                      />
                                    </div>

                                    <div className="mb-[2px]">
                                      <input
                                        className="input-bg-color appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                        id="address"
                                        type="text"
                                        readOnly
                                        disabled
                                        value={client?.address || ""}
                                        placeholder="Direccion"
                                        onChange={(e) => {}}
                                      />
                                    </div>

                                    <div>
                                      <input
                                        className="input-bg-color appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                        id="phone"
                                        type="text"
                                        readOnly
                                        disabled
                                        value={client?.phone || ""}
                                        placeholder="Telefono"
                                        onChange={(e) => {}}
                                      />
                                    </div>
                                  </div>

                                  <div className="w-[33.8%] ml-[2px]">
                                    <div className="mb-[2px] flex flex-row">
                                      <select
                                        className="input-bg-color appearance-none border rounded w-[49.5%] py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[30px]"
                                        id="branch"
                                        readOnly
                                        disabled
                                        value={branchId}
                                        onChange={(e) => {
                                          setBranchId(e.target.value);
                                        }}
                                      >
                                        <option value={0}>Sucursal</option>
                                        <option value={user.branch.id}>
                                          Sucursal: {user.branch.description}
                                        </option>
                                      </select>

                                      <select
                                        className="input-bg-color appearance-none border rounded w-[49.5%] py-1 px-3 text-gray-700 leading-tight focus:outline-none ml-[2px] focus:shadow-outline capitalize h-[30px]"
                                        id="deposit"
                                        readOnly={
                                          action == "edit" ? true : false
                                        }
                                        disabled={
                                          action == "edit" ? true : false
                                        }
                                        value={depositId}
                                        onChange={(e) => {
                                          setDepositId(e.target.value);
                                        }}
                                      >
                                        <option value={0}>
                                          Elija un deposito
                                        </option>
                                        {deposits.map((deposit) => (
                                          <option value={deposit.id}>
                                            {deposit.description}
                                          </option>
                                        ))}
                                      </select>
                                    </div>

                                    <div className="mb-[2px]">
                                      <select
                                        className="input-bg-color appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                        id="branch"
                                        readOnly
                                        disabled
                                        value={currencyId}
                                        onChange={(e) => {
                                          setCurrencyId(e.target.value);
                                        }}
                                      >
                                        <option value={0}>Moneda</option>
                                        {currencies.map((currency, i) => (
                                          <option value={currency.id} key={i}>
                                            Moneda: {currency.name}
                                          </option>
                                        ))}
                                      </select>
                                    </div>

                                    <div className="mb-[2px]">
                                      <input
                                        className="input-bg-color appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-[28px]"
                                        id="date"
                                        value={date}
                                        type="date"
                                        readOnly
                                        disabled
                                        placeholder="Fecha"
                                        onChange={(e) => {
                                          setDate(e.target.value);
                                        }}
                                      />
                                    </div>
                                    <div className="mb-[2px]">
                                      <select
                                        className="input-bg-color appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                        id="sellerId"
                                        disabled
                                        readOnly
                                        value={sellerId}
                                        onChange={(e) => {}}
                                      >
                                        <option value={0}>Vendedor</option>
                                        <option value={user.id}>
                                          Vendedor: {user.fullname}
                                        </option>
                                      </select>
                                    </div>
                                  </div>

                                  <div className="w-[33.8%] ml-[2px]">
                                    <div className="mb-[2px]">
                                      <input
                                        className="input-bg-color appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-[28px]"
                                        id="expeditionPoint"
                                        value={expeditionPoint}
                                        readOnly={
                                          action == "edit" ? true : false
                                        }
                                        disabled={
                                          action == "edit" ? true : false
                                        }
                                        type="text"
                                        placeholder="Pto. De Exped."
                                        onChange={(e) => {
                                          setExpeditionPoint(e.target.value);
                                        }}
                                      />
                                    </div>

                                    <div className="mb-[2px]">
                                      <input
                                        className="input-bg-color appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-[27px]"
                                        id="expeditionPoint"
                                        value={number}
                                        readOnly={
                                          action == "edit" ? true : false
                                        }
                                        disabled={
                                          action == "edit" ? true : false
                                        }
                                        type="text"
                                        placeholder="Numero"
                                        onChange={(e) => {
                                          setNumber(e.target.value);
                                        }}
                                      />
                                    </div>

                                    <div className="mb-[2px]">
                                      <input
                                        className="input-bg-color appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-[28px]"
                                        id="stamped"
                                        value={stamped}
                                        readOnly={
                                          action == "edit" ? true : false
                                        }
                                        disabled={
                                          action == "edit" ? true : false
                                        }
                                        type="text"
                                        placeholder="Timbrado"
                                        onChange={(e) => {}}
                                      />
                                    </div>

                                    <div className="mb-[2px]">
                                      <select
                                        className="input-bg-color appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                        id="boxId"
                                        value={boxId}
                                        disabled
                                        readOnly
                                        onChange={(e) => {
                                          setBoxId(e.target.value);
                                        }}
                                      >
                                        <option value={0}>Caja</option>
                                        <option value={user.box.id}>
                                          {user.box.name}
                                        </option>
                                      </select>
                                    </div>

                                    <div className="mb-[2px]"></div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <Tab.Group>
                                  <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1 mb-[2px]">
                                    <Tab
                                      className={({ selected }) =>
                                        classNames(
                                          "w-full rounded-lg py-1.5 text-sm leading-5 primary-color text-xs",
                                          "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                                          clientId == 0
                                            ? "bg-red-300"
                                            : "bg-green-200",
                                          selected
                                            ? "bg-white font-bold"
                                            : "primary-color hover:text-slate-500"
                                        )
                                      }
                                    >
                                      Cliente
                                    </Tab>
                                    <Tab
                                      className={({ selected }) =>
                                        classNames(
                                          "w-full rounded-lg py-1.5 text-sm leading-5 primary-color text-xs",
                                          "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                                          branchId == 0 ||
                                            currencyId == 0 ||
                                            date == "" ||
                                            sellerId == 0
                                            ? "bg-red-300"
                                            : "bg-green-200",
                                          selected
                                            ? "bg-white font-bold"
                                            : "primary-color hover:text-slate-500"
                                        )
                                      }
                                    >
                                      Datos
                                    </Tab>
                                    <Tab
                                      onClick={() => {}}
                                      className={({ selected }) =>
                                        classNames(
                                          "w-full rounded-lg py-1.5 text-sm leading-5 primary-color text-xs",
                                          "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                                          boxId == 0
                                            ? "bg-red-300"
                                            : "bg-green-200",
                                          selected
                                            ? "bg-white font-bold"
                                            : "primary-color hover:bg-white/[0.12] hover:text-slate-500"
                                        )
                                      }
                                    >
                                      Detalles
                                    </Tab>
                                  </Tab.List>
                                  <Tab.Panels>
                                    <Tab.Panel>
                                      <Transition.Child
                                        enter="ease-out duration-500"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in duration-300"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                      >
                                        <div className="mb-[2px]">
                                          <ComboBox
                                            data={clients}
                                            setData={setClientId}
                                            value={clientId}
                                            search={searchClients}
                                          />
                                        </div>

                                        <div className="mb-[2px]">
                                          <input
                                            className="input-bg-color appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                            id="RUC"
                                            type="text"
                                            disabled
                                            readOnly
                                            value={client?.ruc || ""}
                                            placeholder="RUC"
                                            onChange={(e) => {}}
                                          />
                                        </div>

                                        <div className="mb-[2px]">
                                          <input
                                            className="input-bg-color appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                            id="address"
                                            type="text"
                                            readOnly
                                            disabled
                                            value={client?.address || ""}
                                            placeholder="Direccion"
                                            onChange={(e) => {}}
                                          />
                                        </div>

                                        <div>
                                          <input
                                            className="input-bg-color appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                            id="phone"
                                            type="text"
                                            readOnly
                                            disabled
                                            value={client?.phone || ""}
                                            placeholder="Telefono"
                                            onChange={(e) => {}}
                                          />
                                        </div>
                                      </Transition.Child>
                                    </Tab.Panel>
                                    <Tab.Panel>
                                      <Transition.Child
                                        enter="ease-out duration-500"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in duration-300"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                      >
                                        <div className="mb-[2px]">
                                          <select
                                            className="input-bg-color appearance-none border rounded w-[49.5%] py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                            id="branch"
                                            readOnly
                                            disabled
                                            value={branchId}
                                            onChange={(e) => {
                                              setBranchId(e.target.value);
                                            }}
                                          >
                                            <option value={0}>Sucursal</option>
                                            <option value={user.branch.id}>
                                              {user.branch.description}
                                            </option>
                                          </select>

                                          <select
                                            className="input-bg-color appearance-none border rounded w-[49.5%] ml-[2px] py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                            id="deposit"
                                            readOnly
                                            disabled
                                            value={depositId}
                                            onChange={(e) => {
                                              setDepositId(e.target.value);
                                            }}
                                          >
                                            <option value={0}>
                                              Elija un deposito
                                            </option>
                                            {deposits.map((deposit) => (
                                              <option value={deposit.id}>
                                                {deposit.description}
                                              </option>
                                            ))}
                                          </select>
                                        </div>

                                        <div className="mb-[2px]">
                                          <select
                                            className="input-bg-color appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                            id="currency"
                                            readOnly
                                            disabled
                                            value={currencyId}
                                            onChange={(e) => {
                                              setCurrencyId(e.target.value);
                                            }}
                                          >
                                            <option value={0}>Moneda</option>
                                            {currencies.map((currency, i) => (
                                              <option
                                                value={currency.id}
                                                key={i}
                                              >
                                                {currency.name}
                                              </option>
                                            ))}
                                          </select>
                                        </div>

                                        <div className="mb-[2px]">
                                          <input
                                            className="input-bg-color appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="date"
                                            value={date}
                                            type="date"
                                            readOnly
                                            disabled
                                            placeholder="Fecha"
                                            onChange={(e) => {
                                              setDate(e.target.value);
                                            }}
                                          />
                                        </div>
                                        <div>
                                          <select
                                            className="input-bg-color appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                            id="sellerId"
                                            disabled
                                            readOnly
                                            value={sellerId}
                                            onChange={(e) => {}}
                                          >
                                            <option value={0}>Vendedor</option>
                                            <option value={user.id}>
                                              {user.fullname}
                                            </option>
                                          </select>
                                        </div>
                                      </Transition.Child>
                                    </Tab.Panel>
                                    <Tab.Panel>
                                      <Transition.Child
                                        enter="ease-out duration-500"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in duration-300"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                      >
                                        <div className="mb-[2px]">
                                          <input
                                            className="input-bg-color appearance-none border rounded w-[50%] py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="expeditionPoint"
                                            readOnly
                                            disabled
                                            value={expeditionPoint}
                                            type="text"
                                            placeholder="Pto. De Exp."
                                            onChange={(e) => {
                                              setExpeditionPoint(
                                                e.target.value
                                              );
                                            }}
                                          />

                                          <input
                                            className="input-bg-color appearance-none border rounded w-[50%] py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="number"
                                            readOnly
                                            disabled
                                            value={number}
                                            type="text"
                                            placeholder="Numero"
                                            onChange={(e) => {
                                              setNumber(e.target.value);
                                            }}
                                          />
                                        </div>

                                        <div className="mb-[2px]">
                                          <input
                                            className="input-bg-color appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="stamped"
                                            readOnly
                                            disabled
                                            value={stamped}
                                            type="text"
                                            placeholder="Timbrado"
                                            onChange={(e) => {}}
                                          />
                                        </div>

                                        <div className="mb-[2px]">
                                          <select
                                            className="input-bg-color appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                            id="boxId"
                                            value={boxId}
                                            disabled
                                            readOnly
                                            onChange={(e) => {
                                              setBoxId(e.target.value);
                                            }}
                                          >
                                            <option value={0}>Caja</option>
                                            <option value={user.box.id}>
                                              {user.box.name}
                                            </option>
                                          </select>
                                        </div>

                                        <div className="mb-[2px]"></div>
                                      </Transition.Child>
                                    </Tab.Panel>
                                  </Tab.Panels>
                                </Tab.Group>
                              </>
                            )}

                            <div className="w-full h-[45vh] lg:h-[50vh] flex flex-row mt-2 bg-gray-400 p-2">
                              <div className="w-[100%] overflow-x-auto pb-2">
                                <div className="mb-[2px]">
                                  <div className="saleCreditNotess-table w-full">
                                    <div className="text-gray">
                                      {width > 620 ? (
                                        <div className="tr flex">
                                          <div className="w-[10%] font-bold primary-color">
                                            COD.
                                          </div>
                                          <div className="w-[43%] font-bold primary-color">
                                            PRODUCTO
                                          </div>
                                          <div className="w-[10%] font-bold primary-color">
                                            CANT
                                          </div>
                                          <div className="w-[15%] font-bold primary-color">
                                            PRECIO
                                          </div>
                                          <div className="w-[15%] font-bold primary-color">
                                            TOTAL
                                          </div>
                                          <div className="w-[9%] font-bold primary-color">
                                            IVA
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="tr flex">
                                          <div className="w-[15%] font-bold primary-color">
                                            COD.
                                          </div>
                                          <div className="w-[70%] font-bold primary-color">
                                            PRODUCTO
                                          </div>
                                          <div className="w-[15%] font-bold primary-color">
                                            CANT
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    <div className="overflow-y-auto overflow-x-hidden">
                                      {width > 620
                                        ? items.map((item, i) => (
                                            <div className="tr flex">
                                              <div className="td w-[10%] m-[1px]">
                                                <input
                                                  className="input-bg-color py-1 px-3 w-full text-center"
                                                  type="text"
                                                  readOnly
                                                  disabled
                                                  name="code"
                                                  value={item.id}
                                                />
                                              </div>

                                              <div className="td w-[43%] m-[1px]">
                                                <ComboBox
                                                  data={articles}
                                                  value={item.id}
                                                  setData={setItems}
                                                  items={items}
                                                  index={i}
                                                  className="h-[27px]"
                                                  disabled={
                                                    action == "edit"
                                                      ? true
                                                      : false
                                                  }
                                                />
                                              </div>
                                              <div className="td w-[10%] m-[1px]">
                                                <input
                                                  className="input-bg-color py-1 px-3 w-full text-center"
                                                  type="text"
                                                  name="qty"
                                                  readOnly={
                                                    action == "edit"
                                                      ? true
                                                      : false
                                                  }
                                                  disabled={
                                                    action == "edit"
                                                      ? true
                                                      : false
                                                  }
                                                  value={item.qty}
                                                  onChange={(e) => {
                                                    let newItems = [...items];
                                                    newItems.map((item, it) => {
                                                      if (i == it) {
                                                        item.qty =
                                                          e.target.value;
                                                        item.subtotal =
                                                          String(
                                                            item.price
                                                          ).replaceAll(
                                                            ".",
                                                            ""
                                                          ) * item.qty;
                                                        item.subtotal =
                                                          numberWithCommas(
                                                            item.subtotal
                                                          );
                                                      }
                                                    });
                                                    setItems(newItems);
                                                  }}
                                                />
                                              </div>
                                              <div className="td w-[15%] m-[1px]">
                                                <input
                                                  className="input-bg-color py-1 px-3 w-full text-right"
                                                  type="text"
                                                  name="price"
                                                  readOnly={
                                                    action == "edit"
                                                      ? true
                                                      : false
                                                  }
                                                  disabled={
                                                    action == "edit"
                                                      ? true
                                                      : false
                                                  }
                                                  value={numberWithCommas(
                                                    item.price
                                                  )}
                                                  placeholder="Precio"
                                                  onChange={(e) => {
                                                    let newItems = [...items];
                                                    newItems.map((item, it) => {
                                                      if (i == it) {
                                                        item.price = String(
                                                          e.target.value
                                                        ).replaceAll(".", "");
                                                        item.subtotal =
                                                          String(
                                                            item.price
                                                          ).replaceAll(
                                                            ".",
                                                            ""
                                                          ) * item.qty;
                                                        item.subtotal =
                                                          numberWithCommas(
                                                            item.subtotal
                                                          );
                                                      }
                                                    });
                                                    setItems(newItems);
                                                  }}
                                                />
                                              </div>
                                              <div className="td w-[15%] m-[1px]">
                                                <input
                                                  className="input-bg-color py-1 px-3 w-full text-right"
                                                  type="text"
                                                  readOnly
                                                  disabled
                                                  name="subtotal"
                                                  value={numberWithCommas(
                                                    item.subtotal
                                                  )}
                                                />
                                              </div>
                                              <div className="td w-[7%] m-[1px]">
                                                <input
                                                  className="input-bg-color py-1 px-3 w-full text-center"
                                                  type="text"
                                                  name="iva"
                                                  value={item.iva}
                                                />
                                              </div>
                                            </div>
                                          ))
                                        : items.map((item, i) => (
                                            <div
                                              className="tr flex flex-col m-1 pb-1"
                                              style={{
                                                borderBottom:
                                                  "1px solid rgba(0,0,0,0.5)",
                                              }}
                                            >
                                              <div className="w-full flex flex-row">
                                                <div className="td w-[20%] m-[1px]">
                                                  <input
                                                    className="input-bg-color py-1 px-3 w-full text-center"
                                                    type="text"
                                                    readOnly
                                                    disabled
                                                    name="code"
                                                    value={item.id}
                                                  />
                                                </div>

                                                <div className="td w-[60%] m-[1px]">
                                                  <ComboBox
                                                    data={articles}
                                                    value={item.id}
                                                    setData={setItems}
                                                    items={items}
                                                    index={i}
                                                    className="h-[26px]"
                                                    disabled={
                                                      action == "edit"
                                                        ? true
                                                        : false
                                                    }
                                                  />
                                                </div>
                                                <div className="td w-[20%] m-[1px]">
                                                  <input
                                                    className="input-bg-color py-1 px-3 w-full text-center"
                                                    type="text"
                                                    name="qty"
                                                    value={item.qty}
                                                    readOnly={
                                                      action == "edit"
                                                        ? true
                                                        : false
                                                    }
                                                    disabled={
                                                      action == "edit"
                                                        ? true
                                                        : false
                                                    }
                                                    onChange={(e) => {
                                                      let newItems = [...items];
                                                      newItems.map(
                                                        (item, it) => {
                                                          if (i == it) {
                                                            item.qty =
                                                              e.target.value;
                                                            item.subtotal =
                                                              String(
                                                                item.price
                                                              ).replaceAll(
                                                                ".",
                                                                ""
                                                              ) * item.qty;
                                                            item.subtotal =
                                                              numberWithCommas(
                                                                item.subtotal
                                                              );
                                                          }
                                                        }
                                                      );
                                                      setItems(newItems);
                                                    }}
                                                  />
                                                </div>
                                              </div>

                                              <div className="w-full flex flex-row">
                                                <div className="td w-[33%] m-[1px]">
                                                  <input
                                                    className="input-bg-color py-1 px-3 w-full text-center"
                                                    type="text"
                                                    name="price"
                                                    value={numberWithCommas(
                                                      item.price
                                                    )}
                                                    readOnly={
                                                      action == "edit"
                                                        ? true
                                                        : false
                                                    }
                                                    disabled={
                                                      action == "edit"
                                                        ? true
                                                        : false
                                                    }
                                                    placeholder="Precio"
                                                    onChange={(e) => {
                                                      let newItems = [...items];
                                                      newItems.map(
                                                        (item, it) => {
                                                          if (i == it) {
                                                            item.price = String(
                                                              e.target.value
                                                            ).replaceAll(
                                                              ".",
                                                              ""
                                                            );
                                                            item.subtotal =
                                                              String(
                                                                item.price
                                                              ).replaceAll(
                                                                ".",
                                                                ""
                                                              ) * item.qty;
                                                            item.subtotal =
                                                              numberWithCommas(
                                                                item.subtotal
                                                              );
                                                          }
                                                        }
                                                      );
                                                      setItems(newItems);
                                                    }}
                                                  />
                                                </div>
                                                <div className="td w-[33%] m-[1px]">
                                                  <input
                                                    className="input-bg-color py-1 px-3 w-full text-center"
                                                    type="text"
                                                    readOnly
                                                    disabled
                                                    name="subtotal"
                                                    value={numberWithCommas(
                                                      item.subtotal
                                                    )}
                                                  />
                                                </div>
                                                <div className="td w-[33%] m-[1px]">
                                                  <input
                                                    className="input-bg-color py-1 px-3 w-full text-center"
                                                    type="text"
                                                    name="iva"
                                                    value={item.iva}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tr flex flex-row justify-end items-center m-[2px]">
                        <div className="td text-right font-bold mr-1">
                          TOTAL
                        </div>
                        <div className="td w-[40%] sm:w-[15%]">
                          <input
                            className="input-bg-color py-1 px-3 w-full text-right font-bold"
                            type="text"
                            readOnly
                            disabled
                            name="total"
                            value={totalAmount}
                            placeholder="Total"
                          />
                        </div>
                        <div className="td w-[7%]"></div>
                      </div>
                      <div className="px-4 py-2 justify-center flex">
                        <button
                          type="button"
                          className="inline-flex w-[80px] align-center align-items-center justify-center rounded-3xl secondary-bg-color px-3 py-1 text-sm font-semibold shadow-sm m-1 font-family-thin"
                          style={{ alignItems: "center" }}
                          onClick={() => {
                            clearFields();
                            setOpen(false);
                          }}
                        >
                          SALIR
                        </button>
                        <button
                          type="button"
                          disabled={action == "edit" ? false : true}
                          readOnly={action == "edit" ? false : true}
                          className="inline-flex w-[80px] align-center align-items-center justify-center rounded-3xl primary-color-bg px-3 py-1 text-sm font-semibold shadow-sm m-1 font-family-thin"
                          style={{ alignItems: "center" }}
                          onClick={() => {
                            clearFields();
                            setOpen(false);
                          }}
                        >
                          IMPRIMIR
                        </button>
                        <button
                          type="button"
                          disabled={action == "edit" ? true : false}
                          readOnly={action == "edit" ? true : false}
                          className="inline-flex w-[80px] align-center align-items-center justify-center rounded-3xl primary-color-bg px-3 py-1 text-sm font-semibold shadow-sm m-1 font-family-thin"
                          style={{ alignItems: "center" }}
                          onClick={() => {
                            handleSubmit();
                          }}
                        >
                          GUARDAR
                        </button>
                        <button
                          type="button"
                          disabled={action == "edit" ? false : true}
                          readOnly={action == "edit" ? false : true}
                          className="inline-flex w-[80px] align-center align-items-center justify-center rounded-3xl primary-color-bg px-3 py-1 text-sm font-semibold shadow-sm m-1 font-family-thin"
                          style={{ alignItems: "center" }}
                          onClick={async () => {
                            let cancelSaleCreditNote = await axiosAuth.put(
                              "/cancelSaleCreditNote",
                              { id: saleCreditNoteId }
                            );

                            let newSaleCreditNotes = [...saleCreditNotes];
                            newSaleCreditNotes.map((newSaleCreditNote) => {
                              if (newSaleCreditNote.id == saleCreditNoteId) {
                                newSaleCreditNote.state = 1;
                              }
                            });
                            setSaleCreditNotes(newSaleCreditNotes);
                            setState(1);

                            toast.success(
                              "Nota de credito anulada correctamente!"
                            );
                          }}
                        >
                          ANULAR
                        </button>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <h1 className="ml-5 mt-5 text-3xl title font-family-thin secondary-color">
          Notas de Credito (Ventas)
        </h1>
        <DateComponent className="sm:block hidden" />
        <button
          type="button"
          className="relative right-[25px] sm:right-[50px] inline-flex w-[92px] float-right m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
          style={{ alignItems: "center" }}
          onClick={() => {
            setAction("create");

            let newItems = [];

            Array(8)
              .fill(0)
              .map((_, i) => {
                newItems.push({
                  id: 0,
                  qty: 0,
                  name: "",
                  price: 0,
                  subtotal: 0,
                  iva: "",
                });
              });

            setItems(newItems);

            setOpen(true);
          }}
        >
          <img src={noteimg} className="w-8" />
          CREAR NOTA DE CREDITO
        </button>

        <div className="w-[95%] p-5 mx-auto overflow-auto">
          <table className="w-[95%] table-auto mx-auto w-full text-xs mt-5">
            <thead>
              <tr>
                {/* <th>Id</th> */}
                <th>Nro. Fact.</th>
                <th>Fecha</th>
                <th>Cliente</th>
                <th>Vendedor</th>
                <th>Caja</th>
                <th>Moneda</th>
                <th>Sucursal</th>
                <th>Total</th>
                <th>IVA5</th>
                <th>GRAV5</th>
                <th>IVA10</th>
                <th>GRAV10</th>
                <th>Exento</th>
                <th>Accion</th>
              </tr>
            </thead>
            <tbody>
              {isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={18}>
                    <Lottie
                      animationData={loadingAnimation}
                      style={{ width: 100, height: 100, margin: "0 auto" }}
                      loop={true}
                    />
                  </td>
                </tr>
              )}
              {saleCreditNotes.length > 0 &&
                !isFetching &&
                saleCreditNotes?.map((saleCreditNote, i) => {
                  let saleCreditNotesTotal = 0;

                  saleCreditNote.salecreditnotesdetails.map((detail) => {
                    saleCreditNotesTotal += detail.item.price * detail.quantity;
                  });

                  return (
                    <tr key={saleCreditNote.id}>
                      {/* <td>{saleCreditNotes.id}</td> */}
                      <td className="text-center uppercase">
                        {saleCreditNote.stamped +
                          " " +
                          saleCreditNote.expeditionPoint +
                          " " +
                          saleCreditNote.number}
                      </td>
                      <td>
                        {saleCreditNote.createdAt
                          .substr(0, 10)
                          .split("-")
                          .reverse()
                          .join("/")}
                      </td>
                      <td className="text-left uppercase">
                        {saleCreditNote.client.name}
                      </td>
                      <td className="text-left uppercase">
                        {saleCreditNote.seller.fullname}
                      </td>
                      <td className="text-center uppercase">
                        {saleCreditNote.box.name}
                      </td>
                      <td className="text-center uppercase">
                        {saleCreditNote.currency.name}
                      </td>
                      <td className="text-center uppercase">
                        {saleCreditNote.branch.description}
                      </td>
                      <td className="">
                        {numberWithCommas(saleCreditNotesTotal)}
                      </td>
                      <td className="">
                        {numberWithCommas(saleCreditNote.vat5)}
                      </td>
                      <td className="">
                        {numberWithCommas(saleCreditNote.taxed5)}
                      </td>
                      <td className="">
                        {numberWithCommas(saleCreditNote.vat10)}
                      </td>
                      <td className="">
                        {numberWithCommas(saleCreditNote.taxed10)}
                      </td>
                      <td className="">
                        {numberWithCommas(saleCreditNote.exempt)}
                      </td>

                      <td className="text-center">
                        <button
                          onClick={() => {
                            editSaleCreditNote(saleCreditNote.id);
                          }}
                          className="bg-transparent"
                        >
                          <FontAwesomeIcon
                            className="m-1 cursor-pointer secondary-color"
                            icon={faEye}
                          />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              {saleCreditNotes.length <= 0 && !isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={18} className="text-center">
                    Sin registros (0)
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <Pagination
            path="crednotes"
            page={page}
            pages={pages}
            total={total}
          />
        </div>
      </Layout>
    </>
  );
};

export default SaleCreditNotes;
