import React, { useEffect, useState } from "react";
import Layout from "../layout";
import DateComponent from "../components/date";
import * as JSPM from "jsprintmanager";
import toast from "react-hot-toast";
import { axiosAuth} from "../utils/axios";
const ConfImpresoras = () => {

    const [documentPrinters, setDocumentPrinters] = useState(() => {
        // Cargar desde localStorage si existen valores guardados
        const savedData = localStorage.getItem('documentPrinters');
        return savedData ? JSON.parse(savedData) : {};
      });

    const [printers, setPrinters] = useState([]);
    const documentTypes = [
        'Reportes',
        'Facturas',
        'Tickets',
      ];



       async function listar_impresoras() {
        let imp = await axiosAuth("http://localhost:8080/printers");
        console.log("impresoras:"+imp)
        setPrinters(imp.data);
       }

      useEffect(async () => {
          // Conectarse al servicio JSPrintManager
          /*   JSPM.JSPrintManager.auto_reconnect = true;
           JSPM.JSPrintManager.start();
       
           JSPM.JSPrintManager.WS.onStatusChanged = () => {
             if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Open) {
               console.log('Conectado a JSPrintManager');
             } else {
               console.log('Desconectado de JSPrintManager');
             }
           };  
           JSPM.JSPrintManager.WS.onOpen = () => {
            JSPM.JSPrintManager.getPrinters().then((printerList) => {
              setPrinters(printerList); // Guardar impresoras en el estado
            });
          }; */
          listar_impresoras();
          
      }, []);
       // Función para manejar el cambio de impresora para un tipo de documento
  const handlePrinterChange = (type, selectedPrinter) => {
    const updatedPrinters = {
      ...documentPrinters,
      [type]: selectedPrinter,
    };
    setDocumentPrinters(updatedPrinters);

    // Guardar en localStorage
    localStorage.setItem('documentPrinters', JSON.stringify(updatedPrinters));
    toast.success("DATOS ACTUALIZADOS!");
  };
    return (
        <>
            <Layout>
                <div style={{ borderBottom: "1px solid gray" }}>
                    <h1 className="ml-5 mt-5 text-3xl secondary-color title font-family-thin">
                        Configuraciones de Impresoras
                    </h1>

                    <DateComponent className="sm:block hidden" />
                </div>
                <div className="flex justify-center mt-8">
      <div className="w-full max-w-4xl">
        <table className="table-auto w-full border-collapse border border-gray-300">
          <thead>
            <tr className="bg-gray-100">
              <th className="border border-gray-300 px-4 py-2 text-left">Tipos de Documentos</th>
              <th className="border border-gray-300 px-4 py-2 text-left">Impresora Predeterminada</th>
            </tr>
          </thead>
          <tbody>
            {documentTypes.map((type, index) => (
              <tr key={index} className="hover:bg-gray-50">
                <td className="border border-gray-300 px-4 py-2">{type}</td>
                <td className="border border-gray-300 px-4 py-2">
                  <select className="block w-full border border-gray-300 rounded py-1 px-2"
                    value={documentPrinters[type] || ''}
                    onChange={(e) => handlePrinterChange(type, e.target.value)}
                  >
               <option value="">Seleccione una impresora</option>
                    {printers.length > 0 ? (
                      printers.map((printer, idx) => (
                        <option key={idx} value={printer}>
                          {printer}
                        </option>
                      ))
                    ) : (
                      <option>Cargando impresoras...</option>
                    )}



                    {/* {printers.map((printer, idx) => (
                      <option key={idx} value={printer}>
                        {printer}
                      </option>
                    ))} */}

                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
            </Layout>
        </>
    );
};
export default ConfImpresoras;