import React, { useState, Fragment, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faTrash,
  faPlus,
  faL,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { Dialog, Transition, Tab } from "@headlessui/react";
import Layout from "../layout";
import { axiosAuth } from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser } from "../store/slices/mainSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Pagination from "../components/pagination";
import DateComponent from "../components/date";
import ComboBox from "../components/combobox";
import useViewport from "../utils/useViewport";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/animations/loading.json";
import toast from "react-hot-toast";
import { numberWithCommas } from "../utils/constants";
import discard from "../assets/img/discard.png";
import door from "../assets/img/door.png";
import pdf from "../assets/img/pdf.png";
import printer from "../assets/img/printer.png";

const ProviderPaymentsViewer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [action, setAction] = useState("edit");

  const { width } = useViewport();

  const { page } = useParams();
  const user = useSelector((state) => state.main.user);

  const [payment, setPayment] = useState([]);

  const [payments, setPayments] = useState([]);

  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);

  const [paymentId, setPaymentId] = useState(0);
  const [state, setState] = useState(0);

  const [dateFromFilter, setDateFromFilter] = useState(() => {
    let date = new Date(new Date().getFullYear(), new Date().getMonth(), 1);

    let MyDateString =
      date.getFullYear() +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + date.getDate()).slice(-2);

    return MyDateString;
  });

  const [dateToFilter, setDateToFilter] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
      .toLocaleDateString()
      .replaceAll("/", "-")
      .split("-")
      .map((d) => (String(d).length == 1 ? "0" + String(d) : d))
      .reverse()
      .join("-")
  );

  async function fetchPayments() {
    let pageQuery = page === undefined ? 1 : page;
    setIsFetching(true);

    let paymentsRequest = await axiosAuth.get(
      "/providerpaymentsviewer?page=" +
        pageQuery +
        "&companyId=" +
        user.companyId +
        "&dateFrom=" +
        dateFromFilter +
        "&dateTo=" +
        dateToFilter
    );

    setIsFetching(false);

    if (paymentsRequest.data.error) {
      if (paymentsRequest.data.message === "token") {
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    setPayments(paymentsRequest.data.providerpayments);
    // setTotal(salesRequest.data.sales.count);
    // setPages(salesRequest.data.sales.pages);
  }

  useEffect(() => {
    fetchPayments();
  }, []);

  useEffect(() => {
    fetchPayments();
  }, [location]);

  return (
    <>
      <Layout>
        <h1
          id="titlebig"
          className="ml-5 mt-5 text-3xl title font-family-thin secondary-color"
        >
          Visor de Pagos (Proveedores)
        </h1>
        <DateComponent className="sm:block hidden" />
        <div className="flex flex-row w-full justify-end">
          <div className="w-[80%] sm:w-[40%] float-right flex flex=row pr-12">
            <input
              className="input-bg-color appearance-none border w-[50%] text-gray-700 leading-tight capitalize h-[25px] p-[6px] mr-[1px]"
              id="date"
              value={dateFromFilter}
              type="date"
              onChange={(e) => {
                setDateFromFilter(e.target.value);
              }}
            />

            <input
              className="input-bg-color appearance-none border w-[50%] text-gray-700 leading-tight capitalize h-[25px] p-[6px]"
              id="date"
              value={dateToFilter}
              type="date"
              onChange={(e) => {
                setDateToFilter(e.target.value);
              }}
              onBlur={() => fetchPayments()}
            />
          </div>
        </div>

        <div className="w-[95%] min-w-[780px] max-h-[70vh] p-5 mx-auto overflow-auto">
          <table className="w-[95%] table-auto mx-auto w-full text-xs mt-5 relative">
            <thead>
              <tr>
                <th>Compra Nro.</th>
                <th>Proveedor</th>
                <th>Cuota Nro.</th>
                <th>Monto</th>
                <th>Intereses</th>
                <th>Fecha</th>
                <th>Estado</th>
                <th>Accion</th>
              </tr>
            </thead>
            <tbody className="relative">
              {isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={18}>
                    <Lottie
                      animationData={loadingAnimation}
                      style={{ width: 100, height: 100, margin: "0 auto" }}
                      loop={true}
                    />
                  </td>
                </tr>
              )}
              {payments.length > 0 &&
                !isFetching &&
                payments?.map((payment, i) => {
                  return (
                    <tr
                      key={payment.id}
                      className={`text-[10px] ${
                        payment?.state == 1 ? "bg-red-200" : ""
                      }`}
                    >
                      <td className="text-center uppercase">
                        {payment.state != 1
                          ? payment.purchase.expeditionPoint +
                            " - " +
                            payment.purchase.number
                          : "ANULADO"}
                      </td>
                      <td className="text-left uppercase whitespace-nowrap overflow-hidden">
                        {payment.state != 1
                          ? payment.purchase.provider.name
                          : "ANULADO"}
                      </td>
                      <td className="text-center uppercase whitespace-nowrap overflow-hidden">
                        {payment.state != 1 ? payment.due.number : "ANULADO"}
                      </td>
                      <td className="text-center uppercase">
                        {payment.state != 1
                          ? numberWithCommas(payment.amount)
                          : 0}
                      </td>
                      <td className="text-center uppercase whitespace-nowrap overflow-hidden">
                        {payment.state != 1 ? numberWithCommas(payment.interests) : "ANULADO"}
                      </td>
                      <td>
                        {payment.state != 1
                          ? payment.createdAt
                              .substr(0, 10)
                              .split("-")
                              .reverse()
                              .join("/")
                          : "ANULADO"}
                      </td>
                      <td>{payment.state == 1 ? "ANULADO" : "ACTIVO"}</td>
                      <td className="text-center">
                        {payment.state != 1 ? (
                          <button
                            onClick={async () => {
                              //editPayment(payment.id);

                              if (window.confirm("Desea anular este pago?")) {
                                await axiosAuth.put("/cancelproviderpayment", {
                                  id: payment.id,
                                });

                                let newPayments = [...payments];
                                newPayments.map((paymnt) => {
                                  if (paymnt.id == payment.id) {
                                    paymnt.state = 1;
                                  }
                                });
                                setPayments(newPayments);
                              }
                            }}
                            className=""
                          >
                            ANULAR
                          </button>
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  );
                })}
              {payments.length <= 0 && !isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={18} className="text-center">
                    Sin registros (0)
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Layout>
    </>
  );
};

export default ProviderPaymentsViewer;
