import { motion } from "framer-motion-3d";
import { degreesToRadians } from "popmotion";
import { useGLTF } from "@react-three/drei";
import { Canvas, useThree } from "@react-three/fiber";
import { useEffect } from "react";
import * as THREE from 'three';


export function BoxesIcon({ isHover }) {
  
  const nodes = useGLTF("boxes.glb");
  // const nodes2 = useGLTF("iphone.glb");

  useEffect(() => {
  }, []);
  
  return (
    <Canvas
      dpr={[1, 3]}
      style={{ width: 70, height: 40 }}
      camera={{ position: [0, -30, -75], fov: 90, rotateOnWorldAxis: [90 ,90, 90]  }}
      className="mb-2"
    >
      {lights.map(([x, y, z, intensity], i) => (
        <pointLight
          key={i}
          intensity={intensity}
          position={[x / 8, y / 8, z / 8]}
          color="#fff"
        />
      ))}
      <group dispose={null}>
        <motion.mesh
          geometry={nodes.nodes.Object_2.geometry}
          rotation={[Math.PI / 2, 0, degreesToRadians(360)]}
          scale={1.2}
          translateY={-10}
          animate={[isHover ? "hover" : ""]}
          variants={{
            hover: {
              rotateZ: 0,
              rotateY: 0,
              transition: {
                rotateZ: { duration: 3, ease: "linear", repeat: Infinity }
              }
            }
          }}
        >
          <meshPhongMaterial
            color="#ffdd00"
            emissive="#ff9500"
            specular="#fff"
            shininess="100"
          />
        </motion.mesh>
      </group>

    </Canvas>
  );
}

const lights = [
  [2, 1, 4, 1],
  [8, 0, 4, 1]
];
