import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { axiosAuth } from "../utils/axios";
import logo from "../assets/img/logopng.png";
import { setToken, setUser } from "../store/slices/mainSlice";
import { numberWithCommas, numeroALetras } from "../utils/constants";
import "../assets/css/documents.css";

const SalePrint = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { saleId } = useParams();
  const [date, setDate] = useState("");
  const [sale, setSale] = useState([]);
  const [total, setTotal] = useState(0);

  const getSaleData = async () => {
    if (saleId == null) navigate("/ventas");

    let saleR = await axiosAuth("/sales/" + saleId);

    if (saleR.data.error) {
      if (saleR.data.message === "token") {
        await axiosAuth.post("/logout");
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }
    console.log(saleR);
    let totalSum = 0;

    saleR.data.sale.salesdetails.map((detail) => {
      totalSum += detail.quantity * detail.price;
    });

    if (saleR.data.sale.salesdetails.length < 8) {
      let toAdd = 8 - saleR.data.sale.salesdetails.length;
      let toMap = [...Array(toAdd).keys()];

      toMap.map((itm) => {
        saleR.data.sale.salesdetails.push({
          quantity: "",
          item: { name: "" },
          price: "",
        });
      });
    }

    setTotal(totalSum);

    while (String(saleR.data.sale.number).length < 7) {
      saleR.data.sale.number = "0" + String(saleR.data.sale.number);
    }

    let dateFormat = new Date(saleR.data.sale.createdAt);
    // dateFormat.setDate(dateFormat.getDate() + 1);
    //
    let months = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Setiembre",
      "Octubre",
      "Noviembre",
      "Diciembre"
    ];
    let day =
      String(dateFormat.getDate()).length == 1
        ? "0" + dateFormat.getDate()
        : dateFormat.getDate();
    setDate(
      day + " " + months[dateFormat.getMonth()] + " " + dateFormat.getFullYear()
    );

    setSale(saleR.data.sale);

    setTimeout(function () {
      window.print();
      window.close();
    }, 2000);
  };

  useEffect(() => {
    getSaleData();
  }, []);
  const empresa = {
    nombre: "Carniceria & Faenamientos SANTA ANA",
    descripcion: "Comercio al por menor de carnes y pescados",
    direccion: "Gral. Caballero N 565 e/ Julia Miranda Cueto y Defensores del chaco",
    telefono: "0984 185 641",
    ubicacion: "San Lorenzo - Paraguay"
  };

  return (
    <>
      <div style={{ fontFamily: "Arial !important" }} id="divToPrint">

        <div
          className="bg-white w-[19.8cm] h-[9.8cm] p-2 ml-[20px]"
          style={{ transform: "scale(0.98)", position: "relative", }}
        >
          {sale.state === 1 && (
            <div
              style={{
                position: "absolute",
                top: "28%",
                left: "20%",
                transform: " rotate(-24deg)",
                fontSize: "87px",
                color: "red",
                opacity: 0.5,
                zIndex: 9999,
                pointerEvents: "none",
              }}
            >
              ANULADO
            </div>
          )}
          <div className="flex">
            <div
              className="w-[18%] text-[10px] text-center"
              style={{
                borderLeft: "1px solid gray",
                borderBottom: "1px solid gray",
                borderTop: "1px solid gray",
              }}
            >
              <h2 className="text-lg font-bold mt-7">{empresa.nombre}</h2>
              <h3 className="leading-3 text-[9px]">
                {empresa.descripcion.split("\n").map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </h3>
              <p className="leading-3 text-[9px] mt-[10px]">
                {empresa.direccion}
              </p>
              <p className="leading-3 text-[9px]">{`Tel.: ${empresa.telefono}`}</p>
              <p className="leading-3 text-[9px]">{empresa.ubicacion}</p>
            </div>
            <div className="w-full flex flex-col border border-solid border-gray-500 text-[10px] text-center">
              <div className="flex h-[115px]">
                <div className="w-[67%] text-[11px] text-left pb-3 pt-3 pl-3 flex flex-row justify-between">
                  <div>
                    <p className="text-[9px]">
                      <b>
                        FECHA&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                      </b>{" "}
                      <span className="italic">{date}</span>
                    </p>
                    <p className="text-[9px]">
                      <b>SEÑORES&nbsp;&nbsp;&nbsp;:</b>{" "}
                      <span className="italic">{sale?.client?.name}</span>
                    </p>
                    <p className="text-[9px]">
                      <b>
                        RUC&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                      </b>{" "}
                      <span className="italic">{sale?.client?.ruc}</span>
                    </p>
                    <p className="text-[9px]">
                      <b>DIRECCION&nbsp;:</b>{" "}
                      <span className="italic">{sale?.client?.address}</span>
                    </p>
                  </div>
                  <div className="mr-4 w-40">
                    <p>
                      <b>CONDICION:</b>{" "}
                      <span className="italic">
                        {sale?.salecondition?.description}
                      </span>
                    </p>
                  </div>
                </div>
                <div
                  className="w-[30%] text-[10px] text-right pr-3 justify-end items-center flex"
                  style={{ borderLeft: "1px solid gray" }}
                >
                  <p className="text-[8px] relative bottom-[-28px] right-[-20px]">
                    {sale.expeditionPoint} {sale.number}
                  </p>
                </div>
              </div>

              <div className="flex">
                <div
                  className="w-[66.2%] text-[11px] text-left pl-1 flex flex-row"
                  style={{
                    borderTop: "1px solid gray",
                    borderBottom: "1px solid gray",
                  }}
                >
                  <div className="w-full flex flex-row">
                    <p className="w-[9%] text-center text-[9px]">
                      <b className="relative bottom-[-2px]">CANT.</b>
                    </p>
                    <p className="w-[75%] text-center text-[9px]">
                      <b className="relative bottom-[-2px]">DESCRIPCION</b>
                    </p>
                    <p className="w-[23%] text-center text-[9px]">
                      <b className="relative bottom-[-2px]">PR. UNITARIO</b>
                    </p>
                  </div>
                </div>
                <div
                  className="w-[34%] text-[10px] text-right"
                  style={{
                    borderTop: "1px solid  gray",
                    borderBottom: "1px solid  gray",
                  }}
                >
                  <div className="w-full flex flex-row justify-around">
                    <p className="w-[34%] text-center text-[9px]">
                      <b className="relative bottom-[-1px]">EXENTAS</b>
                    </p>
                    <p className="w-[34%] text-center text-[9px]">
                      <b className="relative bottom-[-1px]">5%</b>
                    </p>
                    <p className="w-[34%] text-center text-[9px]">
                      <b className="relative bottom-[-1px]">10%</b>
                    </p>
                  </div>
                </div>
              </div>

              {sale?.salesdetails?.map((saledetail) => (
                <div className="flex w-full">
                  <div
                    className="w-[66.2%] text-[11px] text-left flex flex-row"
                    style={{ borderRight: "1px solid gray" }}
                  >
                    <div className="w-full flex flex-row">
                      <p className="w-[10%] text-center text-[8px]">
                        {saledetail.quantity}
                      </p>
                      <p
                        className="w-[72%] text-left pl-1 text-[8px]"
                        style={{
                          borderLeft: "1px solid gray",
                          borderRight: "1px solid gray",
                        }}
                      >
                        {saledetail.item.name}
                      </p>
                      <p className="w-[18.5%] text-right text-[8px] pr-1">
                        {numberWithCommas(saledetail.price)}
                      </p>
                    </div>
                  </div>
                  <div className="w-[33%] text-[10px] text-right">
                    <div className="w-full flex flex-row justify-around">
                      <p className="w-[33%] text-center pt-1">
                        <b></b>
                      </p>
                      <p
                        className="w-[33%] text-center pt-1"
                        style={{
                          borderLeft: "1px solid gray",
                          borderRight: "1px solid gray",
                        }}
                      >
                        <b></b>
                      </p>
                      <p className="w-[33%] text-right text-[8px]">
                        {(saledetail.price * saledetail.quantity != 0 &&
                          numberWithCommas(
                            saledetail.price * saledetail.quantity
                          )) || <br />}
                      </p>
                    </div>
                  </div>
                </div>
              ))}

              <div
                className="flex"
                style={{
                  borderTop: "1px solid gray",
                  borderBottom: "1px solid gray",
                }}
              >
                <div
                  className="w-[66.2%] text-[11px] text-left flex flex-row"
                  style={{ borderRight: "1px solid gray" }}
                >
                  <div className="w-full flex flex-row">
                    <p className="w-[100%] text-left p-[1px] text-[9px]">
                      <b className="relative top-[1px]">SUBTOTALES</b>
                    </p>
                  </div>
                </div>
                <div className="w-[33%] text-[10px] text-right">
                  <div className="w-full flex flex-row justify-around h-[100%]">
                    <p className="w-[33%] text-center pt-1">
                      <b></b>
                    </p>
                    <p
                      className="w-[33%] text-center pt-1"
                      style={{
                        borderLeft: "1px solid gray",
                        borderRight: "1px solid gray",
                      }}
                    >
                      <b></b>
                    </p>
                    <p className="w-[32.9%] text-center pt-1 text-[9px] text-right">
                      <span className="relative top-[-1px]">
                        {numberWithCommas(total)}
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex" style={{}}>
                <div className="w-[90%] text-[11px] text-left flex flex-row">
                  <div className="w-full flex flex-row">
                    <p className="w-[100%] text-left p-1">
                      <b className="text-[9px]">TOTAL A PAGAR: </b>
                      <span className="text-[9px] italic">
                        {numeroALetras(total, {
                          plural: "GUARANIES",
                          singular: "GUARANI",
                          centPlural: "CENTAVOS",
                          centSingular: "CENTAVO",
                        })}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="w-[15%] text-[10px] text-right">
                  <div className="w-full flex flex-row justify-end h-[100%]">
                    <p className="w-[62%] text-center pt-1">
                      <b className="relative right-[0px]">
                        {numberWithCommas(total)}
                      </b>
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex" style={{ borderTop: "1px solid gray" }}>
                <div className="w-full text-[11px] text-left flex flex-row">
                  <div className="w-full flex flex-row">
                    <p className="w-[30%] text-left p-[1px]">
                      <b className="text-[9px] relative top-[2px] left-[2px]">
                        LIQUIDACION DEL IVA
                      </b>
                    </p>
                    <p className="w-[20%] text-left p-[1px]">
                      <b className="text-[9px]">5%:</b>{" "}
                      <span className="italic font-extralight">
                        {numberWithCommas(sale.vat5)}
                      </span>
                    </p>
                    <p className="w-[20%] text-left p-[1px]">
                      <b className="text-[9px]">10%:</b>{" "}
                      <span className="italic font-extralight">
                        {numberWithCommas(sale.vat10)}
                      </span>
                    </p>
                    <p className="w-[20%] text-left p-[1px]">
                      <b className="text-[9px]">TOTAL IVA:</b>{" "}
                      <span className="italic font-extralight">
                        {numberWithCommas(sale.vat10)}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="h-12 flex items-end justify-around text-[10px] p-2"
            style={{
              borderBottom: "1px solid gray",
              borderRight: "1px solid gray",
              borderLeft: "1px solid gray",
            }}
          >
            <h3 className="relative left-[-70px]">FECHA:</h3>
            <h3 className="relative left-[-70px]">FIRMA:</h3>
            <h3 className="relative left-[-30px]">ACLARACION:</h3>
            <h3 className="relative left-[-30px]">C.I.:</h3>
          </div>
        </div>
      </div>

      <div
        className="mt-4"
        style={{ fontFamily: "Arial !important" }}
        id="divToPrint">

        <div
          className="bg-white w-[19.8cm] h-[9.8cm] p-2 ml-[20px]"
          style={{ transform: "scale(0.98)", position: "relative", }}
        >
          {sale.state === 1 && (
            <div
              style={{
                position: "absolute",
                top: "28%",
                left: "20%",
                transform: " rotate(-24deg)",
                fontSize: "87px",
                color: "red",
                opacity: 0.5,
                zIndex: 9999,
                pointerEvents: "none",
              }}
            >
              ANULADO
            </div>
          )}
          <div className="flex">
          <div
              className="w-[18%] text-[10px] text-center"
              style={{
                borderLeft: "1px solid gray",
                borderBottom: "1px solid gray",
                borderTop: "1px solid gray",
              }}
            >
              <h2 className="text-lg font-bold mt-7">{empresa.nombre}</h2>
              <h3 className="leading-3 text-[9px]">
                {empresa.descripcion.split("\n").map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </h3>
              <p className="leading-3 text-[9px] mt-[10px]">
                {empresa.direccion}
              </p>
              <p className="leading-3 text-[9px]">{`Tel.: ${empresa.telefono}`}</p>
              <p className="leading-3 text-[9px]">{empresa.ubicacion}</p>
            </div>
            <div className="w-full flex flex-col border border-solid border-gray-500 text-[10px] text-center">
              <div className="flex h-[115px]">
                <div className="w-[67%] text-[11px] text-left pb-3 pt-3 pl-3 flex flex-row justify-between">
                  <div>
                    <p className="text-[9px]">
                      <b>
                        FECHA&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                      </b>{" "}
                      <span className="italic">{date}</span>
                    </p>
                    <p className="text-[9px]">
                      <b>SEÑORES&nbsp;&nbsp;&nbsp;:</b>{" "}
                      <span className="italic">{sale?.client?.name}</span>
                    </p>
                    <p className="text-[9px]">
                      <b>
                        RUC&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                      </b>{" "}
                      <span className="italic">{sale?.client?.ruc}</span>
                    </p>
                    <p className="text-[9px]">
                      <b>DIRECCION&nbsp;:</b>{" "}
                      <span className="italic">{sale?.client?.address}</span>
                    </p>
                  </div>
                  <div className="mr-4 w-40">
                    <p>
                      <b>CONDICION:</b>{" "}
                      <span className="italic">
                        {sale?.salecondition?.description}
                      </span>
                    </p>
                  </div>
                </div>
                <div
                  className="w-[30%] text-[10px] text-right pr-3 justify-end items-center flex"
                  style={{ borderLeft: "1px solid gray" }}
                >
                  <p className="text-[8px] relative bottom-[-28px] right-[-20px]">
                    {sale.expeditionPoint} {sale.number}
                  </p>
                </div>
              </div>

              <div className="flex">
                <div
                  className="w-[66.2%] text-[11px] text-left pl-1 flex flex-row"
                  style={{
                    borderTop: "1px solid gray",
                    borderBottom: "1px solid gray",
                  }}
                >
                  <div className="w-full flex flex-row">
                    <p className="w-[9%] text-center text-[9px]">
                      <b className="relative bottom-[-2px]">CANT.</b>
                    </p>
                    <p className="w-[75%] text-center text-[9px]">
                      <b className="relative bottom-[-2px]">DESCRIPCION</b>
                    </p>
                    <p className="w-[23%] text-center text-[9px]">
                      <b className="relative bottom-[-2px]">PR. UNITARIO</b>
                    </p>
                  </div>
                </div>
                <div
                  className="w-[34%] text-[10px] text-right"
                  style={{
                    borderTop: "1px solid  gray",
                    borderBottom: "1px solid  gray",
                  }}
                >
                  <div className="w-full flex flex-row justify-around">
                    <p className="w-[34%] text-center text-[9px]">
                      <b className="relative bottom-[-1px]">EXENTAS</b>
                    </p>
                    <p className="w-[34%] text-center text-[9px]">
                      <b className="relative bottom-[-1px]">5%</b>
                    </p>
                    <p className="w-[34%] text-center text-[9px]">
                      <b className="relative bottom-[-1px]">10%</b>
                    </p>
                  </div>
                </div>
              </div>

              {sale?.salesdetails?.map((saledetail) => (
                <div className="flex w-full">
                  <div
                    className="w-[66.2%] text-[11px] text-left flex flex-row"
                    style={{ borderRight: "1px solid gray" }}
                  >
                    <div className="w-full flex flex-row">
                      <p className="w-[10%] text-center text-[8px]">
                        {saledetail.quantity}
                      </p>
                      <p
                        className="w-[72%] text-left pl-1 text-[8px]"
                        style={{
                          borderLeft: "1px solid gray",
                          borderRight: "1px solid gray",
                        }}
                      >
                        {saledetail.item.name}
                      </p>
                      <p className="w-[18.5%] text-right text-[8px] pr-1">
                        {numberWithCommas(saledetail.price)}
                      </p>
                    </div>
                  </div>
                  <div className="w-[33%] text-[10px] text-right">
                    <div className="w-full flex flex-row justify-around">
                      <p className="w-[33%] text-center pt-1">
                        <b></b>
                      </p>
                      <p
                        className="w-[33%] text-center pt-1"
                        style={{
                          borderLeft: "1px solid gray",
                          borderRight: "1px solid gray",
                        }}
                      >
                        <b></b>
                      </p>
                      <p className="w-[33%] text-right text-[8px]">
                        {(saledetail.price * saledetail.quantity != 0 &&
                          numberWithCommas(
                            saledetail.price * saledetail.quantity
                          )) || <br />}
                      </p>
                    </div>
                  </div>
                </div>
              ))}

              <div
                className="flex"
                style={{
                  borderTop: "1px solid gray",
                  borderBottom: "1px solid gray",
                }}
              >
                <div
                  className="w-[66.2%] text-[11px] text-left flex flex-row"
                  style={{ borderRight: "1px solid gray" }}
                >
                  <div className="w-full flex flex-row">
                    <p className="w-[100%] text-left p-[1px] text-[9px]">
                      <b className="relative top-[1px]">SUBTOTALES</b>
                    </p>
                  </div>
                </div>
                <div className="w-[33%] text-[10px] text-right">
                  <div className="w-full flex flex-row justify-around h-[100%]">
                    <p className="w-[33%] text-center pt-1">
                      <b></b>
                    </p>
                    <p
                      className="w-[33%] text-center pt-1"
                      style={{
                        borderLeft: "1px solid gray",
                        borderRight: "1px solid gray",
                      }}
                    >
                      <b></b>
                    </p>
                    <p className="w-[32.9%] text-center pt-1 text-[9px] text-right">
                      <span className="relative top-[-1px]">
                        {numberWithCommas(total)}
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex" style={{}}>
                <div className="w-[90%] text-[11px] text-left flex flex-row">
                  <div className="w-full flex flex-row">
                    <p className="w-[100%] text-left p-1">
                      <b className="text-[9px]">TOTAL A PAGAR: </b>
                      <span className="text-[9px] italic">
                        {numeroALetras(total, {
                          plural: "GUARANIES",
                          singular: "GUARANI",
                          centPlural: "CENTAVOS",
                          centSingular: "CENTAVO",
                        })}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="w-[15%] text-[10px] text-right">
                  <div className="w-full flex flex-row justify-end h-[100%]">
                    <p className="w-[62%] text-center pt-1">
                      <b className="relative right-[0px]">
                        {numberWithCommas(total)}
                      </b>
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex" style={{ borderTop: "1px solid gray" }}>
                <div className="w-full text-[11px] text-left flex flex-row">
                  <div className="w-full flex flex-row">
                    <p className="w-[30%] text-left p-[1px]">
                      <b className="text-[9px] relative top-[2px] left-[2px]">
                        LIQUIDACION DEL IVA
                      </b>
                    </p>
                    <p className="w-[20%] text-left p-[1px]">
                      <b className="text-[9px]">5%:</b>{" "}
                      <span className="italic font-extralight">
                        {numberWithCommas(sale.vat5)}
                      </span>
                    </p>
                    <p className="w-[20%] text-left p-[1px]">
                      <b className="text-[9px]">10%:</b>{" "}
                      <span className="italic font-extralight">
                        {numberWithCommas(sale.vat10)}
                      </span>
                    </p>
                    <p className="w-[20%] text-left p-[1px]">
                      <b className="text-[9px]">TOTAL IVA:</b>{" "}
                      <span className="italic font-extralight">
                        {numberWithCommas(sale.vat10)}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="h-12 flex items-end justify-around text-[10px] p-2"
            style={{
              borderBottom: "1px solid gray",
              borderRight: "1px solid gray",
              borderLeft: "1px solid gray",
            }}
          >
            <h3 className="relative left-[-70px]">FECHA:</h3>
            <h3 className="relative left-[-70px]">FIRMA:</h3>
            <h3 className="relative left-[-30px]">ACLARACION:</h3>
            <h3 className="relative left-[-30px]">C.I.:</h3>
          </div>
        </div>
      </div>

      <div
        style={{ fontFamily: "Arial !important", marginTop: "15px" }}
        id="divToPrint"
      >
        <div
          className="bg-white w-[19.8cm] h-[9.8cm] p-2 ml-[20px]"
          style={{ transform: "scale(0.98)", position: "relative", }}
        >
          {sale.state === 1 && (
            <div
              style={{
                position: "absolute",
                top: "28%",
                left: "20%",
                transform: " rotate(-24deg)",
                fontSize: "87px",
                color: "red",
                opacity: 0.5,
                zIndex: 9999,
                pointerEvents: "none",
              }}
            >
              ANULADO
            </div>
          )}
          <div className="flex">
          <div
              className="w-[18%] text-[10px] text-center"
              style={{
                borderLeft: "1px solid gray",
                borderBottom: "1px solid gray",
                borderTop: "1px solid gray",
              }}
            >
              <h2 className="text-lg font-bold mt-7">{empresa.nombre}</h2>
              <h3 className="leading-3 text-[9px]">
                {empresa.descripcion.split("\n").map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </h3>
              <p className="leading-3 text-[9px] mt-[10px]">
                {empresa.direccion}
              </p>
              <p className="leading-3 text-[9px]">{`Tel.: ${empresa.telefono}`}</p>
              <p className="leading-3 text-[9px]">{empresa.ubicacion}</p>
            </div>
            <div className="w-full flex flex-col border border-solid border-gray-500 text-[10px] text-center">
              <div className="flex h-[115px]">
                <div className="w-[67%] text-[11px] text-left pb-3 pt-3 pl-3 flex flex-row justify-between">
                  <div>
                    <p className="text-[9px]">
                      <b>
                        FECHA&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                      </b>{" "}
                      <span className="italic">{date}</span>
                    </p>
                    <p className="text-[9px]">
                      <b>SEÑORES&nbsp;&nbsp;&nbsp;:</b>{" "}
                      <span className="italic">{sale?.client?.name}</span>
                    </p>
                    <p className="text-[9px]">
                      <b>
                        RUC&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                      </b>{" "}
                      <span className="italic">{sale?.client?.ruc}</span>
                    </p>
                    <p className="text-[9px]">
                      <b>DIRECCION&nbsp;:</b>{" "}
                      <span className="italic">{sale?.client?.address}</span>
                    </p>
                  </div>
                  <div className="mr-4 w-40">
                    <p>
                      <b>CONDICION:</b>{" "}
                      <span className="italic">
                        {sale?.salecondition?.description}
                      </span>
                    </p>
                  </div>
                </div>
                <div
                  className="w-[30%] text-[10px] text-right pr-3 justify-end items-center flex"
                  style={{ borderLeft: "1px solid gray" }}
                >
                  <p className="text-[8px] relative bottom-[-28px] right-[-20px]">
                    {sale.expeditionPoint} {sale.number}
                  </p>
                </div>
              </div>

              <div className="flex">
                <div
                  className="w-[66.2%] text-[11px] text-left pl-1 flex flex-row"
                  style={{
                    borderTop: "1px solid gray",
                    borderBottom: "1px solid gray",
                  }}
                >
                  <div className="w-full flex flex-row">
                    <p className="w-[9%] text-center text-[9px]">
                      <b className="relative bottom-[-2px]">CANT.</b>
                    </p>
                    <p className="w-[75%] text-center text-[9px]">
                      <b className="relative bottom-[-2px]">DESCRIPCION</b>
                    </p>
                    <p className="w-[23%] text-center text-[9px]">
                      <b className="relative bottom-[-2px]">PR. UNITARIO</b>
                    </p>
                  </div>
                </div>
                <div
                  className="w-[34%] text-[10px] text-right"
                  style={{
                    borderTop: "1px solid  gray",
                    borderBottom: "1px solid  gray",
                  }}
                >
                  <div className="w-full flex flex-row justify-around">
                    <p className="w-[34%] text-center text-[9px]">
                      <b className="relative bottom-[-1px]">EXENTAS</b>
                    </p>
                    <p className="w-[34%] text-center text-[9px]">
                      <b className="relative bottom-[-1px]">5%</b>
                    </p>
                    <p className="w-[34%] text-center text-[9px]">
                      <b className="relative bottom-[-1px]">10%</b>
                    </p>
                  </div>
                </div>
              </div>

              {sale?.salesdetails?.map((saledetail) => (
                <div className="flex w-full">
                  <div
                    className="w-[66.2%] text-[11px] text-left flex flex-row"
                    style={{ borderRight: "1px solid gray" }}
                  >
                    <div className="w-full flex flex-row">
                      <p className="w-[10%] text-center text-[8px]">
                        {saledetail.quantity}
                      </p>
                      <p
                        className="w-[72%] text-left pl-1 text-[8px]"
                        style={{
                          borderLeft: "1px solid gray",
                          borderRight: "1px solid gray",
                        }}
                      >
                        {saledetail.item.name}
                      </p>
                      <p className="w-[18.5%] text-right text-[8px] pr-1">
                        {numberWithCommas(saledetail.price)}
                      </p>
                    </div>
                  </div>
                  <div className="w-[33%] text-[10px] text-right">
                    <div className="w-full flex flex-row justify-around">
                      <p className="w-[33%] text-center pt-1">
                        <b></b>
                      </p>
                      <p
                        className="w-[33%] text-center pt-1"
                        style={{
                          borderLeft: "1px solid gray",
                          borderRight: "1px solid gray",
                        }}
                      >
                        <b></b>
                      </p>
                      <p className="w-[33%] text-right text-[8px]">
                        {(saledetail.price * saledetail.quantity != 0 &&
                          numberWithCommas(
                            saledetail.price * saledetail.quantity
                          )) || <br />}
                      </p>
                    </div>
                  </div>
                </div>
              ))}

              <div
                className="flex"
                style={{
                  borderTop: "1px solid gray",
                  borderBottom: "1px solid gray",
                }}
              >
                <div
                  className="w-[66.2%] text-[11px] text-left flex flex-row"
                  style={{ borderRight: "1px solid gray" }}
                >
                  <div className="w-full flex flex-row">
                    <p className="w-[100%] text-left p-[1px] text-[9px]">
                      <b className="relative top-[1px]">SUBTOTALES</b>
                    </p>
                  </div>
                </div>
                <div className="w-[33%] text-[10px] text-right">
                  <div className="w-full flex flex-row justify-around h-[100%]">
                    <p className="w-[33%] text-center pt-1">
                      <b></b>
                    </p>
                    <p
                      className="w-[33%] text-center pt-1"
                      style={{
                        borderLeft: "1px solid gray",
                        borderRight: "1px solid gray",
                      }}
                    >
                      <b></b>
                    </p>
                    <p className="w-[32.9%] text-center pt-1 text-[9px] text-right">
                      <span className="relative top-[-1px]">
                        {numberWithCommas(total)}
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex" style={{}}>
                <div className="w-[90%] text-[11px] text-left flex flex-row">
                  <div className="w-full flex flex-row">
                    <p className="w-[100%] text-left p-1">
                      <b className="text-[9px]">TOTAL A PAGAR: </b>
                      <span className="text-[9px] italic">
                        {numeroALetras(total, {
                          plural: "GUARANIES",
                          singular: "GUARANI",
                          centPlural: "CENTAVOS",
                          centSingular: "CENTAVO",
                        })}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="w-[15%] text-[10px] text-right">
                  <div className="w-full flex flex-row justify-end h-[100%]">
                    <p className="w-[62%] text-center pt-1">
                      <b className="relative right-[0px]">
                        {numberWithCommas(total)}
                      </b>
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex" style={{ borderTop: "1px solid gray" }}>
                <div className="w-full text-[11px] text-left flex flex-row">
                  <div className="w-full flex flex-row">
                    <p className="w-[30%] text-left p-[1px]">
                      <b className="text-[9px] relative top-[2px] left-[2px]">
                        LIQUIDACION DEL IVA
                      </b>
                    </p>
                    <p className="w-[20%] text-left p-[1px]">
                      <b className="text-[9px]">5%:</b>{" "}
                      <span className="italic font-extralight">
                        {numberWithCommas(sale.vat5)}
                      </span>
                    </p>
                    <p className="w-[20%] text-left p-[1px]">
                      <b className="text-[9px]">10%:</b>{" "}
                      <span className="italic font-extralight">
                        {numberWithCommas(sale.vat10)}
                      </span>
                    </p>
                    <p className="w-[20%] text-left p-[1px]">
                      <b className="text-[9px]">TOTAL IVA:</b>{" "}
                      <span className="italic font-extralight">
                        {numberWithCommas(sale.vat10)}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SalePrint;
