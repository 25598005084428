import { configureStore } from "@reduxjs/toolkit";
import mainReducer from "./slices/mainSlice";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";

const persistConfig = {
  key: "ocuStatePersist",
  storage,
};

const persistedReducer = persistReducer(persistConfig, mainReducer);

export const store = configureStore({
  reducer: {
    main: persistedReducer,
  },
  middleware: [thunk],
});

export const persistor = persistStore(store);
