import React, { useState, useEffect, Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";
import Layout from "../layout";
import { axiosAuth } from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser, setTitle } from "../store/slices/mainSlice";
import { useParams } from "react-router-dom";
import DateComponent from "../components/date";
import ComboBox from "../components/combobox";
import toast from "react-hot-toast";
import broom from "../assets/img/broom.png";
import plus from "../assets/img/plus.png";
import floppy from "../assets/img/floppy.png";
import door from "../assets/img/door.png";
import useViewport from "../utils/useViewport";
const SupportCreate = () => {
  const dispatch = useDispatch();
  const [action, setAction] = useState("create");

  const { page } = useParams();
  const user = useSelector((state) => state.main.user);

  const [open, setOpen] = useState(false);
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [contact, setContact] = useState([]);
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [descriptions, setDescriptions] = useState([""]);
  const [observations, setObservations] = useState([""]);
  const [convertasions, setConvertasions] = useState([""]);
  const [states, setStates] = useState([0]);

  const [clientId, setClientId] = useState(0);
  const [contactId, setContactId] = useState(0);
  const [userId, setUserId] = useState(user.id);

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [deleted, setDeleted] = useState(0);
  const { width } = useViewport();
  const [divisions, setDivisions] = useState([]);
  const [divisionsId, setDivisionsId] = useState([1]);

  async function fetchSupport() {
    let pageQuery = page === undefined ? 1 : page;

    let supportRequest = await axiosAuth.get("/support?page=" + pageQuery);
    if (supportRequest.data.error) {
      if (supportRequest.data.message === "token") {
        await axiosAuth.post("/logout");
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }
    // supportRequest.data.support.clients.unshift({
    //   id: 0,
    //   name: "Elija un cliente",
    // });

    setClients(supportRequest.data.clients.rows);
    supportRequest.data.contacts.rows.push({
      id: "add",
      name: "Crear contacto",
    });
    setContacts(supportRequest.data.contacts.rows);
    setDivisions(supportRequest.data.divisions.rows);
  }

  useEffect(() => {
    fetchSupport();
    dispatch(setTitle("Soporte - Registrar"));
  }, []);

  useEffect(() => {
    filterContacts(clientId);
    setClient(clients.filter((client) => client.id === clientId)[0]);
  }, [clientId]);

  useEffect(() => {
    setContact(
      filteredContacts.filter((contact) => contact.id === contactId)[0]
    );

    if (contactId == "add") {
      setOpen(true);
    }
  }, [contactId]);

  useEffect(() => {
    // console.log(states);
  }, [states]);

  const filterContacts = (clientId) => {
    let newFilteredContacts = [];

    contacts.map((contact) => {
      if (contact.clientId == clientId) {
        newFilteredContacts.push(contact);
      }
    });

    newFilteredContacts.push({ id: "add", name: "Crear contacto" });

    setFilteredContacts(newFilteredContacts);
  };

  const searchClients = async (search) => {
    const searchClientsRequest = await axiosAuth.get(
      "/searchClients?search=" + search
    );
console.log(searchClientsRequest.data.clients.rows);
    setClients(searchClientsRequest.data.clients.rows);
    let newContacts = [];

    searchClientsRequest.data.clients.rows.map((client) => {
      client.contacts.map((contact) => {
        newContacts.push(contact);
      });
    });

    newContacts.push({ id: "add", name: "Crear contacto" });

    setContacts(newContacts);
  };

  const clearFields = () => {
    setClient();
    setContact();
    setClientId(0);
    setContactId(0);
    setObservations([""]);
    setDescriptions([""]);
    setConvertasions([""])
    setDivisionsId([1]);
  };

  const handleSubmit = async (state) => {
    if (action == "edit") {
      return false;
    }

    if (
      clientId === 0 ||
      contactId === 0 ||
      (descriptions.length <= 1 && descriptions[0] == "")
    ) {
      alert("Debes completar los campos para continuar.");
      return false;
    }
    let createdSupportRequest = await axiosAuth.post("/support", {
      clientId,
      contactId,
      descriptions,
      observations,
      convertasions,
      states,
      divisionsId,
    });
  //  console.log(createdSupportRequest)

    if (createdSupportRequest.data.error) {
      if (createdSupportRequest.data.message === "token") {
        await axiosAuth.post("/logout");
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    toast.success("Ticket de soporte creado correctamente!");
    clearFields();
  };

  const handleContactSubmit = async (state) => {
    if (action == "edit") {
      return false;
    }

    if (clientId === 0) {
      alert("Debes completar el campo de cliente para continuar.");
      return false;
    }

    let createdContactRequest = await axiosAuth.post("/contacts", {
      clientId,
      name,
      phone,
      email,
      deleted,
    });

    if (createdContactRequest.data.error) {
      if (createdContactRequest.data.message === "token") {
        await axiosAuth.post("/logout");
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    let newFilteredContacts = [...filteredContacts];
    newFilteredContacts.push(createdContactRequest.data);
    setFilteredContacts((prev) => newFilteredContacts);
    setContactId((prev) => createdContactRequest.data.id);

    setName("");
    setEmail("");
    setPhone("");
    setOpen(false);

    setContact(
      (prev) =>
        filteredContacts.filter(
          (contact) => contact.id === createdContactRequest.data.id
        )[0]
    );

    toast.success("Contacto creado correctamente!");
  };
  return (
    <>
      <Layout>
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              clearFields();
              //setOpen(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto align-items-center justify-center items-center flex">
              <div className="flex justify-center p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel
                    className="relative transform overflow-hidden rounded-3xl bg-white text-left shadow-xl transition-all sm:my-8 w-[280px]"
                    style={{ marginTop: 45 }}
                  >
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-0">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                          <Dialog.Title
                            as="h1"
                            className="text-center font-regular leading-6 primary-color text-3xl"
                          >
                            {action === "edit" ? "Editar" : "Crear"} Contacto
                          </Dialog.Title>
                          <div className="mt-5">
                            <div className="w-full">
                              <form className="bg-white rounded">
                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="name"
                                  >
                                    Nombre
                                  </label>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                    id="name"
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                  />
                                </div>
                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="name"
                                  >
                                    Telefono
                                  </label>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                    id="name"
                                    type="text"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                  />
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="name"
                                  >
                                    Correo
                                  </label>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="name"
                                    type="text"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                  />
                                </div>

                                {user.roleId == 1 && (
                                  <div className="mb-4">
                                    <label
                                      className="block text-gray-700 text-sm font-bold"
                                      htmlFor="deleted"
                                    >
                                      Borrado
                                    </label>
                                    <select
                                      className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      id="deleted"
                                      value={deleted}
                                      onChange={(e) =>
                                        setDeleted(parseInt(e.target.value))
                                      }
                                    >
                                      <option value={0}>No</option>
                                      <option value={1}>Si</option>
                                    </select>
                                  </div>
                                )}
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 justify-center flex sm:px-6">
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => {
                          clearFields();
                          setOpen(false);
                        }}
                      >
                        <img alt="" src={door} className="w-5" />
                        CERRAR
                      </button>
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => handleContactSubmit()}
                      >
                        <img src={floppy} className="w-8" />
                        GUARDAR
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
        <Transition.Root show={true}>
          <div
            class="w-[100%] flex flex-col sm:flex-row pb-2 relative bg-white top-[-20px] h-40 mb-2"
            style={{ borderBottom: "1px solid gray" }}
          >
            <div
              className="sm:w-[30%] w-[60%] pt-4"
              style={{ borderRight: "1px solid grey" }}
            >
              <h1 className="ml-5 mt-7 text-2xl secondary-color title font-family-thin hidden sm:block">
                Soporte - Registrar
              </h1>
              <DateComponent className="hidden sm:block" />
            </div>
            <div class="sm:w-[50%] w-[85%] mb-[1px] sm:ml-0 ml-[-1px] ml-6 flex flex-col items-center pt-[27px]  ">
              <div className="flex flex-col w-[90%]">
                <ComboBox
                  data={clients}
                  setData={setClientId}
                  value={clientId}
                  placeholder="Ruc, Nombres, Nombre de Fantasia"
                  className="h-[30px] w-[77%] text-gray-700 "
                  search={searchClients}
                />
              </div>
              <div className="flex flex-row w-[90%]">
                <ComboBox
                  data={filteredContacts}
                  setData={setContactId}
                  value={contactId}
                  placeholder="Contacto"
                  className="h-[30px] w-[100%] mt-[1] rounded-0xl"
                />
                <a
                  target="_blank"
                  className="w-full"
                  href={
                    contact?.phone?.replaceAll(" ", "") != "" &&
                    contact?.phone?.replaceAll(" ", "") != " "
                      ? "https://wa.me/" +
                        "+595" +
                        contact?.phone
                          ?.replaceAll(" ", "")
                          .replace("+", "")
                          .replace("595", "")
                      : "/#"
                  }
                >
                  <input
                    className="input-bg-color h-[30px] appearance-none border w-[52.5%]
                  py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize ml-1 mt-[1]"
                    id="phone"
                    type="text"
                    readOnly
                    value={contact?.phone?.replaceAll(" ", "") || ""}
                    placeholder="Telefono"
                    onChange={(e) => {}}
                  />
                </a>
              </div>
            </div>
          </div>

          <div className="w-[100%] p-2 mx-auto overflow-auto pb-6 relative top-[-40px]">
            <div className="px-2 pb-2 p-2 text-center rounded-3xl">
              <div className="w-full">
                <div className="mt-1">
                  <div className="flex flex-row w-full"></div>
                  <div className="w-full flex flex-col">
                    <div
                      className="w-full flex flex-row mt-2 bg-white p-2 overflow-y-auto overflow-x-auto"
                      style={{ borderBottom: "1px solid gray" }}
                    >
                      <div className="w-[100%] pb-2">
                        <div>
                          <div className="support-table w-full ">
                            <div className="text-gray">
                              <div className="tr flex bg-gray-500 h-8 flex flex-row justify-center items-center">
                                <div className="w-[50%] sm:w-[45%] font-bold another-text-white">
                                  DESCRIPCION
                                </div>
                                <div className="w-[50%] sm:w-[45%] font-bold another-text-white">
                                  OBSERVACION
                                </div>
                                <div className="w-[50%] sm:w-[45%] font-bold another-text-white">
                                  CONVERSACION
                                </div>
                                <div className="w-[10%] font-bold another-text-white hidden sm:block"></div>
                              </div>
                            </div>
                            <div className="overflow-y-auto overflow-x-auto h-[45vh]">
                              {descriptions.map((description, i) => (
                                <div className="tr  flex items-center w-full sm:flex-row flex-col h-[160px] mb-1 pb-1">
                                  <div className="td sm:w-[45%] w-[100%] m-[1px] mt-2">
                                    <textarea
                                      className="py-1 px-3 w-full text-left h-full !rounded border border-slate-500"
                                      name="descriptions"
                                      value={description}
                                      onChange={(e) => {
                                        let newDescriptions = [...descriptions];
                                        newDescriptions.map(
                                          (description, idx) => {
                                            if (idx == i) {
                                              newDescriptions[idx] =
                                                e.target.value;
                                            }
                                          }
                                        );
                                        setDescriptions(newDescriptions);
                                      }}
                                    ></textarea>
                                  </div>

                                  <div className="td sm:w-[45%] w-[100%] m-[1px] mt-2">
                                    <textarea
                                      className="py-1 px-3 w-full text-left h-full !rounded border border-slate-500"
                                      name="observations"
                                      value={observations[i]}
                                      onChange={(e) => {
                                        let newObservations = [...observations];
                                        newObservations.map(
                                          (observation, idx) => {
                                            if (idx == i) {
                                              newObservations[idx] =
                                                e.target.value;
                                            }
                                          }
                                        );
                                        setObservations(newObservations);
                                      }}
                                    ></textarea>
                                  </div>

                                  <div className="td sm:w-[45%] w-[100%] m-[1px] mt-2">
                                    <textarea
                                      className="py-1 px-3 w-full text-left h-full !rounded border border-slate-500"
                                      name="convertasions"
                                      value={convertasions[i]}
                                      onChange={(e) => {
                                        let newConvertasions = [...convertasions];
                                        newConvertasions.map(
                                          (convertasion, idx) => {
                                            if (idx == i) {
                                              newConvertasions[idx] =
                                                e.target.value;
                                            }
                                          }
                                        );
                                        setConvertasions(newConvertasions);
                                      }}
                                    ></textarea>
                                  </div>

                                  <div
                                    className="td sm:w-[10%] w-[100%] m-[1px] flex sm:flex-col flex-row  justify-center items-center uppercase
                                          
                                  "
                                  >
                                    <div className="text-lg flex flex-row justify-center items-center m-1 text-green-600">
                                      <input
                                        className="w-[20px] h-[20px] ml-2"
                                        name="state"
                                        type="checkbox"
                                        checked={states[i] == 2 ? true : false}
                                        onChange={(e) => {
                                          let newStates = [...states];
                                          newStates = newStates.map(
                                            (stateNum, s) => {
                                              if (s == i) {
                                                stateNum = e.target.checked
                                                  ? 2
                                                  : 0;
                                                return stateNum;
                                              } else {
                                                return stateNum;
                                              }
                                            }
                                          );
                                          setStates(newStates);
                                        }}
                                      />
                                      &nbsp;Realizado
                                    </div>

                                    <div className="text-lg flex flex-row justify-center items-center m-1 text-yellow-600">
                                      <input
                                        className="w-[20px] h-[20px] ml-2"
                                        name="state"
                                        type="checkbox"
                                        checked={states[i] == 5 ? true : false}
                                        onChange={(e) => {
                                          let newStates = [...states];
                                          newStates = newStates.map(
                                            (stateNum, s) => {
                                              if (s == i) {
                                                stateNum = e.target.checked
                                                  ? 5
                                                  : 0;
                                                return stateNum;
                                              } else {
                                                return stateNum;
                                              }
                                            }
                                          );
                                          setStates(newStates);
                                        }}
                                      />
                                      &nbsp;Aprobado
                                    </div>

                                    <div className="text-lg flex flex-row justify-center items-center m-1 text-red-600">
                                      <input
                                        className="w-[20px] h-[20px] ml-2 ml-[-5px]"
                                        name="state"
                                        type="checkbox"
                                        checked={states[i] == 4 ? true : false}
                                        onChange={(e) => {
                                          let newStates = [...states];
                                          newStates = newStates.map(
                                            (stateNum, s) => {
                                              if (s == i) {
                                                stateNum = e.target.checked
                                                  ? 4
                                                  : 0;
                                                return stateNum;
                                              } else {
                                                return stateNum;
                                              }
                                            }
                                          );
                                          setStates(newStates);
                                        }}
                                      />
                                      &nbsp;URGENTE
                                    </div>
                                    <div className="text-lg flex flex-row justify-center items-center m-1 text-red-600">
                                    <select value={divisionsId[i]}
                                             
                                             id="divisionsId"
                                    onChange={(e) =>{
                                      let newDivisions =[...divisionsId];
                                      newDivisions = newDivisions.map(
                                           (stateDiv, d)=>{
                                            if(d==i){
                                              stateDiv = e.target.value
                                              return stateDiv;
                                           }else{
                                            return stateDiv;
                                           }
                                            
                                           }

                                      );
                                      setDivisionsId(newDivisions);

                                    }}
                                     >
                                     <option key={0} value={0}>
                                     Elija División
                                   </option>
                                   {divisions?.map((division) => (
                                     <option key={division.id} value={division.id}>
                                       {division.name}
                                     </option>
                                   ))}
                                        
                                     </select>
                                    </div>
                                    {i > 0 && (
                                      <button
                                        onClick={() => {
                                          let newDescripcions = [];
                                          let newObservations = [];
                                          let newConvertasions=[];
                                          let newStates = [];
                                          descriptions.map(
                                            (description, idx) => {
                                              if (idx != i) {
                                                newDescripcions.push(
                                                  description
                                                );
                                              }
                                            }
                                          );
                                          observations.map(
                                            (observation, idx) => {
                                              if (idx != i) {
                                                newObservations.push(
                                                  observation
                                                );
                                              }
                                            }
                                          );
                                          convertasions.map(
                                            (convertasion, idx) => {
                                              if (idx != i) {
                                                newConvertasions.push(
                                                  convertasion
                                                );
                                              }
                                            }
                                          );
                                          states.map((state, idx) => {
                                            if (idx != i) {
                                              newStates.push(state);
                                            }
                                          });
                                          setDescriptions(newDescripcions);
                                          setObservations(newObservations);
                                          setConvertasions(newConvertasions);
                                          setStates(newStates);
                                        }}
                                        className="!text-black border border-slate-500 !rounded m-2 w-24 relative right-[-8px] p-1 text-sm"
                                      >
                                        Borrar
                                      </button>
                                    )}
                                  </div>
                                </div>
                              ))}
                              <div></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="py-2 justify-end flex relative">
                <button
                  type="button"
                  className="float-left inline-flex w-[68px] h-[60px] m-[2px] flex-col 
                  justify-center items-center rounded ternary-bg-color px-3 py-2 text-sm 
                  text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                  style={{ alignItems: "center" }}
                  onClick={() => {
                    let newDescripcions = [...descriptions];
                    newDescripcions.push("");
                    let newObservations = [...observations];
                    newObservations.push("");
                    let newConvertasions =[...convertasions];
                    newConvertasions.push("");
                    let newStates = [...states];
                    
                    newStates.push(0);
                    let newDivisions = [...divisionsId]
                    newDivisions.push(0);
                    setDescriptions(newDescripcions);
                    setObservations(newObservations);
                    setStates(newStates);
                    setDivisionsId(newDivisions);
                    setConvertasions(newConvertasions);
                    
                  }}
                >
                  <img alt="" src={plus} className="w-8" />
                  AGREGAR
                </button>

                <button
                  type="button"
                  className="float-left inline-flex w-[68px] h-[60px] m-[2px]
                   flex-col justify-center items-center rounded ternary-bg-color 
                   px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                  style={{ alignItems: "center" }}
                  onClick={() => clearFields()}
                >
                  <img alt="" src={broom} className="w-8" />
                  LIMPIAR
                </button>

                <button
                  type="button"
                  className="float-left inline-flex w-[68px] h-[60px] m-[2px] 
                  flex-col justify-center items-center rounded ternary-bg-color 
                  px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                  style={{ alignItems: "center" }}
                  onClick={() => handleSubmit()}
                >
                  <img alt="" src={floppy} className="w-8" />
                  GUARDAR
                </button>
              </div>
            </div>
          </div>
        </Transition.Root>
      </Layout>
    </>
  );
};

export default SupportCreate;
