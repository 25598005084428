import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

var hostvar;

if(window.location.host.indexOf("localhost") > -1){
  hostvar = "http://localhost:8000";
}else{
  hostvar = "https://www.api.ocus.com.py";
}

function restaurarElementos(saleId) {

    const papelElement = document.querySelector('.papel');
    papelElement.innerHTML = ''; // Vacía el contenido de .papel

    const datosElementos = localStorage.getItem('configuracionElementos');

    if (datosElementos) {
        const elementos = JSON.parse(datosElementos);

        elementos.forEach(infoElemento => {
            const elemento = document.createElement('div');
            elemento.classList.add('draggable-item', 'classs');
            elemento.id = infoElemento.id;
            elemento.style.position = 'absolute';
            elemento.style.top = infoElemento.top;
            elemento.style.left = infoElemento.left;
            elemento.style.height = infoElemento.height;
            elemento.style.width = infoElemento.width;
            elemento.style.fontSize = infoElemento.fontSize;

            const boxOps = document.createElement('div');
            boxOps.classList.add('boxOps');
            boxOps.dataset.name = infoElemento.attribute;
            boxOps.innerHTML = `<p>${infoElemento.attribute}</p>`;

            elemento.appendChild(boxOps);
            papelElement.appendChild(elemento);
        });
        imprimirPapel(saleId)
    }
}
function imprimirPapel(saleId) {

    const myHeaders = new Headers();
    const datosElementos = localStorage.getItem('persist:ocuStatePersist');
    const parsedData = JSON.parse(datosElementos);
    const token = parsedData.token.replace(/^"(.*)"$/, '$1');
    console.log(token);
    
    myHeaders.append("Authorization", "Bearer "+token);
    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };
    fetch(hostvar+"/sales/"+saleId, requestOptions)
        .then((response) => response.json())
        .then((data) => {
            console.log(data);
            const articuloDiv = document.querySelector('[data-name="Articulo"]');
            articuloDiv.innerHTML = '';
            const precioDiv = document.querySelector('[data-name="Precio"]');
            precioDiv.innerHTML = '';
            const cantidadDiv = document.querySelector('[data-name="Cantidad"]');
            cantidadDiv.innerHTML = '';
            const iva10Div = document.querySelector('[data-name="IVA10"]');
            iva10Div.innerHTML = '';

          
            const clienteDiv = document.querySelector('[data-name="Cliente"]');
            clienteDiv.textContent = data.sale.client.name.trim();
            const rucDiv = document.querySelector('[data-name="Ruc"]');
            rucDiv.textContent = data.sale.client.ruc.trim();
            const direccionDiv = document.querySelector('[data-name="Direccion"]');
            direccionDiv.textContent = data.sale.client.address.trim();
            const condicionDiv = document.querySelector('[data-name="Condicion"]');
            condicionDiv.textContent = data.sale.salecondition.description;

            const fechaDiv = document.querySelector('[data-name="Fecha_var"]');
            fechaDiv.textContent = data.sale.createdAt;

            // Calcular y mostrar el IVA 10%
            const totalIva10Div = document.querySelector('[data-name="TotalIVA10"]');
            const totalIva10 = data.sale.vat10;
            totalIva10Div.textContent = formatearNumero(Math.round(totalIva10));

            // Iterar sobre los detalles de la venta y agregarlos a los divs correspondientes
            data.sale.salesdetails.forEach((detail) => {
                const nuevoArticuloDiv = document.createElement('div');
                const nuevoPrecioDiv = document.createElement('div');
                const nuevaCantidadDiv = document.createElement('div');
                const nuevoIva10Div = document.createElement('div');

                nuevoArticuloDiv.textContent = detail.item.name.trim();
                nuevoPrecioDiv.textContent = formatearNumero(detail.item.price);
                nuevaCantidadDiv.textContent = detail.quantity;
                nuevoIva10Div.textContent = formatearNumero(Math.round(detail.item.price/11));

                nuevoIva10Div.style.textAlign = 'right';
                nuevoPrecioDiv.style.textAlign = 'right';
                nuevaCantidadDiv.style.textAlign = 'right';

                articuloDiv.appendChild(nuevoArticuloDiv);
                precioDiv.appendChild(nuevoPrecioDiv);
                cantidadDiv.appendChild(nuevaCantidadDiv);
                iva10Div.appendChild(nuevoIva10Div);
            });
            sacar_impresion();
        })
        .catch((error) => console.error(error));
}
function formatearNumero(numero) {
    return numero.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}
function sacar_impresion() {
  const papelElement = document.querySelector('.papel');
  const originalContent = document.body.innerHTML;
  const papelContent = papelElement.outerHTML;
  document.body.innerHTML = papelContent;
  window.print();
  document.body.innerHTML = originalContent;
 
}
function MiComponente() {
  const { saleId } = useParams();
    useEffect(() => {
      restaurarElementos(saleId);
    },[saleId]);

    return (
        <div className="papel" style={{ background: 'white', width: '21cm', height: '28cm' }}>
        
        </div>
    );
}

export default MiComponente;