import React, { useState, useEffect } from "react";
import { Transition, Tab } from "@headlessui/react";
import Layout from "../layout";
import { axiosAuth } from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser } from "../store/slices/mainSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DateComponent from "../components/date";
import ComboBox from "../components/combobox";
import door from "../assets/img/door.png";
import printer from "../assets/img/printer.png";
import floppy from "../assets/img/floppy.png";
import { numberWithCommas } from "../utils/constants";
import useViewport from "../utils/useViewport";
import broom from "../assets/img/broom.png";
import searchimg from "../assets/img/search.webp";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/animations/loading.json";

const ClientAccountState = () => {
  const user = useSelector((state) => state.main.user);

  const { width } = useViewport();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { page } = useParams();

  const [isFetching, setIsFetching] = useState(false);

  const [saleDetail, setSaleDetail] = useState([]);
  const [sales, setSales] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState({});
  const [clientId, setClientId] = useState(0);
  const [companyId, setCompanyId] = useState(user.companyId);
  const [endTotal, setEndTotal] = useState(0);
  const [endTotalReceived, setEndTotalReceived] = useState(0);
  const [endTotalRest, setEndTotalRest] = useState(0);
  const [amountToCharge, setAmountToCharge] = useState(0);
  const [detail, setDetail] = useState("");

  const [dateFromFilter, setDateFromFilter] = useState("");
  const [dateToFilter, setDateToFilter] = useState("");

  async function fetchCollectionsData() {
    let pageQuery = page == undefined ? 1 : page;

    setIsFetching(true);

    let data = await axiosAuth.get(
      "/clientaccountstate?page=" +
        pageQuery +
        "&dateFrom=" +
        dateFromFilter +
        "&dateTo=" +
        dateToFilter +
        "&clientId=" +
        clientId
    );

    if (data.data.error) {
      if (data.data.message === "token") {
        await axiosAuth.post("/logout");
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    setIsFetching(false);

    setClients(data.data.collections.rows);
    setCurrencies(data.data.currencies);

    if (clientId > 0) {
      setClient(
        clients.filter((client) => {
          if (client.id === clientId) {
            setSales(client.sales);
            return true;
          }
        })[0]
      );
    } else {
      let newSales = [];
      data.data.collections.rows.map((client) => [
        client.sales.map((sale) => {
          newSales.push(sale);
        }),
      ]);
      setSales(newSales);
    }
  }

  useEffect(() => {
    fetchCollectionsData();
  }, [clientId, location]);

  useEffect(() => {
    setSales(sales);
  }, [sales]);

  const clearFields = () => {
    setClientId(0);
    setClient([]);
    setDateFromFilter("");
    setDateToFilter("");
    setSales([]);
    setSaleDetail("");
    setEndTotal(0);
    setEndTotalReceived(0);
    setEndTotalRest(0);
    setAmountToCharge(0);
  };

  useEffect(() => {
    let totalAmount = 0,
      totalPaid = 0,
      totalRest = 0;

    clients.map((client) => {
      client.sales.map((sale) => {
        sale.dues.map((due, i) => {
          totalAmount += due.amount;
          totalPaid += due.paid;
          totalRest += due.amount - due.paid;
        });
      });
    });

    setEndTotal(totalAmount);
    setEndTotalReceived(totalPaid);
    setEndTotalRest(totalRest);
  }, [clientId, dateFromFilter, dateToFilter, sales]);

  const searchCollections = async (search) => {
    const searchCollectionsRequest = await axiosAuth.get(
      "/searchCollections?search=" + search + "&companyId=" + companyId
    );

    setClients(searchCollectionsRequest.data.collections.rows);
  };

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };

  return (
    <>
      <Layout>
        <Transition.Root show={true}>
          <div className="flex flex-col md:flex-row pt-2 ">
            <div>
              <h1 className="ml-5 sm:text-3xl text-xl !mt-2 sm:!mt-4 title secondary-color font-family-thin">
                Estado de Cuenta (Clientes)
              </h1>
              <DateComponent className="sm:block hidden" />{" "}
            </div>

            <div className="md:w-[35%] w-[100%] mr-6 md:ml-24 ml-8 mb-2 flex flex-col mb-1 justify-center">
              <div className="w-[100%]">
                <div className="mb-2 mt-2">
                  <ComboBox
                    data={clients}
                    setData={setClientId}
                    value={clientId}
                    placeholder="CLIENTE"
                    className="h-[25px]"
                    search={searchCollections}
                  />
                </div>
              </div>

              <div className="w-[100%]">
                <div className="flex flex-row">
                  <input
                    className="input-bg-color appearance-none border w-full text-gray-700 leading-tight capitalize h-[25px] p-[6px] ml-[-4px]"
                    id="date"
                    value={dateFromFilter}
                    type="date"
                    onChange={(e) => {
                      setDateFromFilter(e.target.value);
                    }}
                  />

                  <input
                    className="input-bg-color appearance-none border w-full text-gray-700 leading-tight capitalize h-[25px] p-[6px]"
                    id="date"
                    value={dateToFilter}
                    type="date"
                    onChange={(e) => {
                      setDateToFilter(e.target.value);
                    }}
                    onBlur={() => fetchCollectionsData()}
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-row justify-center pl-12">
              <button
                type="button"
                className="float-right w-[72px] h-[70px] inline-flex flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 mr-[4px]"
                onClick={() => {
                  clearFields();
                }}
              >
                <img src={broom} className="w-8" />
                LIMPIAR
              </button>

              <a
                target="_blank"
                href={
                  "/estadodecuentaimprimir?page=1&dateFromFilter=" +
                  dateFromFilter +
                  "&dateToFilter=" +
                  dateToFilter +
                  "&clientId=" +
                  clientId
                }
              >
                <button
                  type="button"
                  className="float-right inline-flex w-[72px] h-[70px] flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 mr-[4px]"
                  style={{ alignItems: "center" }}
                >
                  <img src={printer} className="w-8" />
                  IMPRIMIR
                </button>
              </a>

              <button
                type="button"
                className="float-right inline-flex w-[72px] h-[70px] ml-[-0px] flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 mr-[4px]"
                style={{ alignItems: "center" }}
                onClick={() => {
                  fetchCollectionsData();
                }}
              >
                <img src={searchimg} className="w-8" />
                CONSULTAR
              </button>

              <button
                type="button"
                className="float-right inline-flex w-[72px] h-[70px] ml-[-0px] flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                style={{ alignItems: "center" }}
                onClick={() => navigate("/")}
              >
                <img src={door} className="w-5" />
                SALIR
              </button>
            </div>
          </div>

          <div className="w-[100%] p-4 pt-0 pb-0 mx-auto overflow-auto">
            <div className="bg-white px-4 pb-1 text-center rounded-3xl">
              <div className="w-full">
                <div className="mt-2">
                  <div className="w-full flex flex-col">
                    {/* {action === "edit" && (
                                  <div className="mb-4">
                                    <label
                                      className="block text-gray-700 text-sm font-bold"
                                      htmlFor="SaleId"
                                    >
                                      Id
                                    </label>
                                    <input
                                      readOnly
                                      className="input-bg-color appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      id="SaleId"
                                      type="text"
                                      value={saleId}
                                    />
                                  </div>
                                )} */}

                    <div className="w-full flex flex-row mt-[1px] p-1 sm:max-h-[450px] max-h-[400px] overflow-auto border-b-[2px] border-gray-400">
                      <div className="w-[100%] overflow-x-auto pb-2 pr-2">
                        <div>
                          <div className="sales-table w-full mt-2">
                            <div className="text-gray">
                              <div className="tr flex h-10 pt-2 border-b-2 bg-gray-600 !text-white border-slate-400 mb-[1px]">
                                {clientId == 0 ? (
                                  <div className="w-[34%] sm:w-[34.2%] text-[11px] font-bold text-center">
                                    CLIENTE
                                  </div>
                                ) : (
                                  <div className="w-[0%] text-[11px] font-bold"></div>
                                )}

                                <div
                                  className={`w-[33%] sm:w-[14.2%] ${
                                    clientId != 0 ? "!w-[27%]" : ""
                                  } text-[11px] font-bold text-left`}
                                >
                                  FACTURA
                                </div>
                                <div
                                  className={`w-[14.2%] ${
                                    clientId != 0 ? "w-[10%]" : ""
                                  } text-[11px] sm:block hidden font-bold text-left`}
                                >
                                  EMISION
                                </div>
                                <div
                                  className={`w-[14.2%] ${
                                    clientId != 0 ? "w-[8%]" : ""
                                  } sm:block hidden text-[11px] font-bold text-left`}
                                >
                                  VENCIMIENTO
                                </div>
                                <div className="w-[7%] sm:block hidden text-[11px] font-bold text-right">
                                  CUOTA
                                </div>
                                <div className="w-[15%] sm:block hidden text-[11px] font-bold text-right">
                                  MONTO
                                </div>
                                <div
                                  className={`w-[14.2%] ${
                                    clientId != 0 ? "w-[10%]" : ""
                                  } sm:block hidden text-[11px] font-bold text-right`}
                                >
                                  PAGADO
                                </div>
                                <div className="w-[33%] sm:w-[14.2%] text-[11px] font-bold text-right">
                                  SALDO
                                </div>
                              </div>
                            </div>
                            <div className="overflow-y-auto overflow-x-hidden">
                              {isFetching && (
                                <div className="tr flex justify-left items-center p-3">
                                  <Lottie
                                    animationData={loadingAnimation}
                                    style={{
                                      width: 100,
                                      height: 100,
                                      margin: "0 auto",
                                    }}
                                    loop={true}
                                  />
                                </div>
                              )}
                              {!isFetching &&
                                clientId != 0 &&
                                sales.map((sale) => {
                                  return sale.dues.map((due, i) => {
                                    if (
                                      due.amount -
                                        Number(
                                          String(due.paid).replaceAll(".", "")
                                        ) >
                                      0
                                    ) {
                                      return width > 480 ? (
                                        <div className="tr flex text-xs">
                                          <div
                                            className={`w-[33%] sm:w-[14.2%] ${
                                              clientId != 0 ? "!w-[27%]" : ""
                                            } text-[11px] font-bold text-left`}
                                          >
                                            {sale.expeditionPoint} {sale.number}
                                          </div>
                                          <div className="td w-[14.2%] m-[0.5px] text-left">
                                            {sale.createdAt
                                              .substr(0, 10)
                                              .split("-")
                                              .reverse()
                                              .join("/")}
                                          </div>
                                          <div className="td w-[14.2%] m-[0.5px] sm:block hidden text-center">
                                            {due.expirationDate
                                              .substr(0, 10)
                                              .split("-")
                                              .reverse()
                                              .join("/")}
                                          </div>

                                          <div className="td w-[14.2%] sm:block hidden m-[0.5px] text-center">
                                            {due.number}//{sale?.dues?.length}
                                          </div>
                                          <div className="td w-[14.2%] m-[0.5px] text-right">
                                            {numberWithCommas(due.amount)}
                                          </div>
                                          <div className="td w-[14.2%] m-[0.5px] text-right">
                                            {numberWithCommas(
                                              Number(
                                                String(due.paid).replaceAll(
                                                  ".",
                                                  ""
                                                )
                                              )
                                            )}
                                          </div>
                                          <div className="td w-[14.2%] m-[0.5px] text-right">
                                            {numberWithCommas(
                                              due.amount -
                                                Number(
                                                  String(due.paid).replaceAll(
                                                    ".",
                                                    ""
                                                  )
                                                )
                                            )}
                                          </div>
                                        </div>
                                      ) : (
                                        <div
                                          className="tr flex flex-col text-xs pb-1 pt-1"
                                          style={{
                                            borderBottom: "1px solid gray",
                                          }}
                                        >
                                          <div className="flex flex-row w-[100%]">
                                            <div className="td w-[33%] m-[0.5px] text-left whitespace-nowrap">
                                              {sale.expeditionPoint}{" "}
                                              {sale.number}
                                            </div>
                                            <div className="td w-[33%] m-[0.5px] text-left">
                                              {sale.createdAt
                                                .substr(0, 10)
                                                .split("-")
                                                .reverse()
                                                .join("/")}
                                            </div>
                                            <div className="td w-[33%] m-[0.5px] text-center">
                                              {due.expirationDate
                                                .substr(0, 10)
                                                .split("-")
                                                .reverse()
                                                .join("/")}
                                            </div>
                                          </div>

                                          <div className="flex flex-row">
                                            <div className="td w-[33%] m-[0.5px] text-right">
                                              {numberWithCommas(due.amount)}
                                            </div>
                                            <div className="td w-[33%] m-[0.5px] text-right">
                                              {numberWithCommas(
                                                Number(
                                                  String(due.paid).replaceAll(
                                                    ".",
                                                    ""
                                                  )
                                                )
                                              )}
                                            </div>
                                            <div className="td w-[33%] m-[0.5px] text-right">
                                              {numberWithCommas(
                                                due.amount -
                                                  Number(
                                                    String(due.paid).replaceAll(
                                                      ".",
                                                      ""
                                                    )
                                                  )
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                  });
                                })}

                              {!isFetching &&
                                clientId === 0 &&
                                clients.map((client, c) => {
                                  console.log(client);
                                  let mustShow = false;
                                  let amountTotal = 0;
                                  let amountPaid = 0;
                                  let amountRest = 0;
                                  let nameShown = false;

                                  // Calcular los totales
                                  client.sales.forEach((sale) => {
                                    sale.dues.forEach((due) => {
                                      if (
                                        due.amount -
                                          Number(
                                            String(due.paid).replaceAll(".", "")
                                          ) >
                                        0
                                      ) {
                                        mustShow = true;
                                        amountTotal += due.amount;
                                        amountPaid += due.paid;
                                        amountRest += due.amount - due.paid;
                                      }
                                    });
                                  });

                                  if (!mustShow) {
                                    return null; // No mostrar nada si no se deben mostrar datos
                                  }

                                  return (
                                    <>
                                      {client.sales.map((sale, s) => {
                                        return sale.dues.map((due, i) => {
                                          if (
                                            due.amount -
                                              Number(
                                                String(due.paid).replaceAll(
                                                  ".",
                                                  ""
                                                )
                                              ) >
                                            0
                                          ) {
                                            return width > 480 ? (
                                              <div
                                                className="tr flex text-xs"
                                                key={`${client.id}-${s}-${i}`}
                                              >
                                                <div className="td w-[34.2%] m-[0.5px] whitespace-nowrap text-left overflow-hidden">
                                                  {!nameShown && (
                                                    <>
                                                      <div className="text-left m-[1px]">
                                                        CLIENTE :{" "}
                                                        <strong>
                                                          {client.name}
                                                        </strong>
                                                      </div>
                                                      <div className="text-left m-[1px]">
                                                        RUC :{" "}
                                                        <strong>
                                                          {client.ruc}
                                                        </strong>
                                                      </div>
                                                      <div className="text-left m-[1px]">
                                                        DIRECCION :{" "}
                                                        <strong>
                                                          {client.address}
                                                        </strong>
                                                      </div>
                                                      <div className="text-left m-[1px]">
                                                        TELEF. :{" "}
                                                        <strong>
                                                          {client.phone}
                                                        </strong>
                                                      </div>
                                                      {(nameShown = true)}
                                                    </>
                                                  )}
                                                </div>
                                                <div className="td w-[14.2%] m-[0.5px] text-left whitespace-nowrap">
                                                  {sale.expeditionPoint}{" "}
                                                  {sale.number}
                                                </div>
                                                <div className="td w-[14.2%] m-[0.5px] text-left">
                                                  {sale.createdAt
                                                    .substr(0, 10)
                                                    .split("-")
                                                    .reverse()
                                                    .join("/")}
                                                </div>
                                                <div className="td w-[14.2%] m-[0.5px] sm:block hidden text-left">
                                                  {due.expirationDate
                                                    .substr(0, 10)
                                                    .split("-")
                                                    .reverse()
                                                    .join("/")}
                                                </div>
                                                <div className="td w-[5.2%] sm:block hidden m-[0.5px] text-left">
                                                  {due.number}/
                                                  {sale?.dues?.length}
                                                </div>
                                                <div className="td w-[14.2%] m-[0.5px] text-right">
                                                  {numberWithCommas(due.amount)}
                                                </div>
                                                <div className="td w-[14.2%] m-[0.5px] text-right">
                                                  {numberWithCommas(
                                                    Number(
                                                      String(
                                                        due.paid
                                                      ).replaceAll(".", "")
                                                    )
                                                  )}
                                                </div>
                                                <div className="td w-[14.2%] m-[0.5px] text-right">
                                                  {numberWithCommas(
                                                    due.amount -
                                                      Number(
                                                        String(
                                                          due.paid
                                                        ).replaceAll(".", "")
                                                      )
                                                  )}
                                                </div>
                                              </div>
                                            ) : (
                                              <div
                                                className="tr flex flex-col text-xs pb-1 pt-1"
                                                key={`${client.id}-${s}-${i}`}
                                              >
                                                <div className="td w-[100%] m-[0.5px] whitespace-nowrap text-left">
                                                  {/* {client.name} tamaño cel */}
                                                </div>
                                                <div className="flex flex-row w-[100%]">
                                                  <div className="td w-[33%] m-[0.5px] text-left whitespace-nowrap">
                                                    {sale.expeditionPoint}{" "}
                                                    {sale.number}
                                                  </div>
                                                  <div className="td w-[33%] m-[0.5px] text-left">
                                                    {sale.createdAt
                                                      .substr(0, 10)
                                                      .split("-")
                                                      .reverse()
                                                      .join("/")}
                                                  </div>
                                                  <div className="td w-[33%] m-[0.5px] text-left">
                                                    {due.expirationDate
                                                      .substr(0, 10)
                                                      .split("-")
                                                      .reverse()
                                                      .join("/")}
                                                  </div>
                                                </div>
                                                <div className="flex flex-row">
                                                  <div className="td w-[33%] m-[0.5px] text-right">
                                                    {numberWithCommas(
                                                      due.amount
                                                    )}
                                                  </div>
                                                  <div className="td w-[33%] m-[0.5px] text-right">
                                                    {numberWithCommas(
                                                      Number(
                                                        String(
                                                          due.paid
                                                        ).replaceAll(".", "")
                                                      )
                                                    )}
                                                  </div>
                                                  <div className="td w-[33%] m-[0.5px] text-right">
                                                    {numberWithCommas(
                                                      due.amount -
                                                        Number(
                                                          String(
                                                            due.paid
                                                          ).replaceAll(".", "")
                                                        )
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          }
                                          return null;
                                        });
                                      })}
                                      <div className="tr flex text-xs">
                                        {
                                          <div className="td w-[14.2%] m-[0.5px] text-center whitespace-nowrap"></div>
                                        }
                                        <div className="td w-[14.2%] m-[0.5px] text-center whitespace-nowrap"></div>
                                        <div className="td w-[14.2%] m-[0.5px] text-center"></div>
                                        <div className="td w-[14.2%] m-[0.5px] sm:block hidden text-center"></div>
                                        <div className="td w-[20%] m-[0.5px] text-right">
                                          <b className="uppercase">Totales:</b>
                                        </div>
                                        <div
                                          style={{
                                            borderTop: "1px solid lightgray",
                                          }}
                                          className="td w-[15%] m-[4px] text-right"
                                        >
                                          <b>{numberWithCommas(amountTotal)}</b>
                                        </div>
                                        <div
                                          style={{
                                            borderTop: "1px solid lightgray",
                                          }}
                                          className="td w-[15%] m-[4px] text-right"
                                        >
                                          <b>{numberWithCommas(amountPaid)}</b>
                                        </div>
                                        <div
                                          style={{
                                            borderTop: "1px solid lightgray",
                                          }}
                                          className="td w-[15%] m-[4px] mr-2 text-right"
                                        >
                                          <b>{numberWithCommas(amountRest)}</b>
                                        </div>
                                      </div>
                                      <div className="tr flex text-xs">
                                        {
                                          <div
                                            style={{
                                              borderTop: "1px solid lightgray",
                                            }}
                                            className="td w-[14.2%] m-[0.5px] text-center whitespace-nowrap"
                                          ></div>
                                        }
                                        <div
                                          style={{
                                            borderTop: "1px solid lightgray",
                                          }}
                                          className="td w-[14.2%] m-[0.5px] text-center whitespace-nowrap"
                                        ></div>
                                        <div
                                          style={{
                                            borderTop: "1px solid lightgray",
                                          }}
                                          className="td w-[14.2%] m-[0.5px] text-center"
                                        ></div>
                                        <div
                                          style={{
                                            borderTop: "1px solid lightgray",
                                          }}
                                          className="td w-[14.2%] m-[0.5px] sm:block hidden text-center"
                                        ></div>
                                        <div
                                          style={{
                                            borderTop: "1px solid lightgray",
                                          }}
                                          className="td w-[20%] m-[0.5px] text-right"
                                        ></div>
                                        <div
                                          style={{
                                            borderTop: "1px solid lightgray",
                                          }}
                                          className="td w-[15%] m-[0.5px] text-right"
                                        ></div>
                                        <div
                                          style={{
                                            borderTop: "1px solid lightgray",
                                          }}
                                          className="td w-[15%] m-[0.5] text-right"
                                        ></div>
                                        <div
                                          style={{
                                            borderTop: "1px solid lightgray",
                                          }}
                                          className="td w-[15%] m-[0.5] mr-2 text-right"
                                        ></div>
                                      </div>
                                    </>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {
                <div className="flex flex-row justify-end mt-[2px]">
                  <div className="sm:w-[10%] text-xs w-[28.2%] sm:mr-1 mr-3 text-right">
                    <b>{numberWithCommas(endTotal)}</b>
                  </div>
                  <div className="sm:w-[11%] text-xs w-[28.2%] sm:mr-1 mr-4 text-right">
                    <b>{numberWithCommas(endTotalReceived)}</b>
                  </div>
                  <div className="sm:w-[11.5%] text-xs w-[28.2%] text-right">
                    <b>{numberWithCommas(endTotalRest)}</b>
                  </div>
                </div>
              }
            </div>
          </div>
        </Transition.Root>
      </Layout>
    </>
  );
};

export default ClientAccountState;
