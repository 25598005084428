import React, { useState, Fragment, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Dialog, Transition } from "@headlessui/react";
import Layout from "../layout";
import { axiosAuth } from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser } from "../store/slices/mainSlice";
import { useLocation, useParams } from "react-router-dom";
import Pagination from "../components/pagination";
import DateComponent from "../components/date";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/animations/loading.json";
import stampedimg from "../assets/img/stamped.png";
import door from "../assets/img/door.png";
import floppy from "../assets/img/floppy.png";

const Stampeds = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [action, setAction] = useState("edit");

  const { page } = useParams();
  const user = useSelector((state) => state.main.user);

  const [stampeds, setStampeds] = useState([]);

  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);

  const [stampedId, setStampedId] = useState(0);
  const [stamped, setStamped] = useState(0);
  const [current, setCurrent] = useState(0);
  //   const [deleted, setDeleted] = useState(0);

  async function fetchStampeds() {
    let pageQuery = page === undefined ? 1 : page;

    setIsFetching(true);

    let stampeds = await axiosAuth.get("/stampeds?page=" + pageQuery);

    if (stampeds.data.error) {
      if (stampeds.data.message === "token") {
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    setIsFetching(false);

    setStampeds(stampeds.data.stampeds.rows);
    setTotal(stampeds.data.stampeds.count);
    setPages(stampeds.data.stampeds.pages);
  }

  useEffect(() => {
    fetchStampeds();
  }, []);

  useEffect(() => {
    fetchStampeds();
  }, [location]);

  const editStamped = (stampedId) => {
    setAction("edit");
    setStampedId(stampedId);

    let stamped = stampeds.filter((stamped) => stamped.id === stampedId)[0];

    setStamped(stamped.stamped);
    setCurrent(stamped.current);

    setOpen(true);
  };

  const clearFields = () => {
    setStampedId(0);
    setStamped(0);
    setCurrent(0);
  };

  const handleSubmit = async () => {
    if (stamped === 0) {
      alert("Debes completar los campos para continuar.");
      return false;
    }

    if (action === "edit") {
      let id = stampedId;
      let newStampedsObj = [];

      let updateStampedRequest = await axiosAuth.put("/stampeds", {
        id,
        stamped,
        current,
      });

      if (updateStampedRequest.data.error) {
        if (updateStampedRequest.data.message === "token") {
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      stampeds.map((stamped) => {
        if (stamped.id == id) {
          newStampedsObj.push(updateStampedRequest.data);
        } else {
          newStampedsObj.push(stamped);
        }
        return "";
      });

      setStampeds(newStampedsObj);
    } else {
      let createStampedRequest = await axiosAuth.post("/stampeds", {
        stamped,
        current,
      });

      if (createStampedRequest.data.error) {
        if (createStampedRequest.data.message === "token") {
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      let newStampeds = [...stampeds];
      newStampeds.push(createStampedRequest.data);
      setStampeds(newStampeds);
    }

    setOpen(false);
  };

  const deleteStamped = async (stampedId) => {
    if (window.confirm("Desea eliminar este timbrado ?")) {
      let StampedDeleteRequest = await axiosAuth.delete("/stampeds", {
        params: { id: stampedId },
      });

      if (StampedDeleteRequest.data.error) {
        if (StampedDeleteRequest.data.message === "token") {
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      let newStampedsObj = [];

      stampeds.map((stamped) => {
        if (stamped.id !== stampedId) {
          newStampedsObj.push(stamped);
        } else {
          if (user.roleId == 1) {
            stamped.deleted = 1;
            newStampedsObj.push(stamped);
          }
        }
        return "";
      });

      setStampeds(newStampedsObj);
    }
  };

  return (
    <>
      <Layout>
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              clearFields();
              setOpen(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto align-items-center justify-center items-center flex">
              <div className="flex justify-center p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel
                    className="relative transform overflow-hidden rounded-3xl bg-white text-left shadow-xl transition-all sm:my-8 w-[280px]"
                    style={{ marginTop: 45 }}
                  >
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-0">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                          <Dialog.Title
                            as="h1"
                            className="text-center font-regular leading-6 primary-color text-3xl"
                          >
                            {action === "edit" ? "Editar" : "Crear"} timbrado
                          </Dialog.Title>
                          <div className="mt-5">
                            <div className="w-full max-w-xs">
                              <form className="bg-white rounded">
                                {action === "edit" && (
                                  <div className="mb-4">
                                    <label
                                      className="block text-gray-700 text-sm font-bold"
                                      htmlFor="StampedId"
                                    >
                                      Id
                                    </label>
                                    <input
                                      readOnly
                                      className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      id="StampedId"
                                      type="text"
                                      value={stampedId}
                                    />
                                  </div>
                                )}

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="stamped"
                                  >
                                    Timbrado
                                  </label>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                    id="stamped"
                                    type="number"
                                    value={stamped}
                                    onChange={(e) => setStamped(e.target.value)}
                                  />
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="current"
                                  >
                                    Vigente
                                  </label>
                                  <select
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="current"
                                    value={current}
                                    onChange={(e) =>
                                      setCurrent(parseInt(e.target.value))
                                    }
                                  >
                                    <option value={0}>No</option>
                                    <option value={1}>Si</option>
                                  </select>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 justify-center flex sm:px-6">
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => {
                          clearFields();
                          setOpen(false);
                        }}
                      >
                        <img src={door} className="w-5" />
                        CERRAR
                      </button>
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => handleSubmit()}
                      >
                        <img src={floppy} className="w-8" />
                        GUARDAR
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <h1 className="ml-5 mt-5 text-3xl secondary-color title font-family-thin">
          Timbrados
        </h1>
        <DateComponent className="sm:block hidden" />

        <button
          type="button"
          className="relative right-[25px] sm:right-[50px] bottom-[10px] inline-flex w-[78px] float-right m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
          style={{ alignItems: "center" }}
          onClick={() => {
            setAction("create");
            setOpen(true);
          }}
        >
          <img src={stampedimg} className="w-16" />
          CREAR TIMBRADO
        </button>

        <div className="w-[95%] p-5 mx-auto overflow-auto">
          <table className="w-[95%] table-auto mx-auto w-full text-xs">
            <thead>
              <tr>
                <th className="uppercase">Id</th>
                <th className="uppercase">Timbrado</th>
                <th className="uppercase">Vigente</th>
                <th className="uppercase">Fecha Alta</th>
              </tr>
            </thead>
            <tbody>
              {isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={8}>
                    <Lottie
                      animationData={loadingAnimation}
                      style={{ width: 100, height: 100, margin: "0 auto" }}
                      loop={true}
                    />
                  </td>
                </tr>
              )}
              {stampeds.length > 0 &&
                !isFetching &&
                stampeds?.map((stamped) => {
                  return (
                    <tr key={stamped.id}>
                      <td>{stamped.id}</td>
                      <td>{stamped.stamped}</td>
                      <td>{stamped.current ? "Si" : "No"}</td>
                      <td>
                        {stamped.createdAt
                          .substr(0, 10)
                          .split("-")
                          .reverse()
                          .join("/")}
                      </td>
                      <td className="text-center">
                        <button
                          className="bg-transparent"
                          onClick={() => {
                            editStamped(stamped.id);
                          }}
                        >
                          <FontAwesomeIcon
                            className="m-1 cursor-pointer secondary-color"
                            icon={faPen}
                          />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              {stampeds.length <= 0 && !isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={8} className="text-center">
                    Sin registros (0)
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <Pagination
            path="timbrados"
            page={page}
            pages={pages}
            total={total}
          />
        </div>
      </Layout>
    </>
  );
};

export default Stampeds;
