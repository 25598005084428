import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { axiosAuth } from "../utils/axios";
import { setToken, setUser } from "../store/slices/mainSlice";
import { numberWithCommas, numeroALetras } from "../utils/constants";
import "../assets/css/documents.css";

const SalePrint = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { saleId } = useParams();
  const [sale, setSale] = useState(null);
  const [date, setDate] = useState("");
  const [showGrid, setShowGrid] = useState(false);
  const [copyCount, setCopyCount] = useState(2);
  const [copySpacing, setCopySpacing] = useState(149);
  const [positions, setPositions] = useState({
    date: { name: 'Fecha', column: 21, row: 9.5, show: true },
    xMark: { name: 'Condición de venta', column: 143, row: 8.5, show: true },
    clientName: { name: 'Nombre Cliente', column: 28, row: 14.5, show: true },
    ruc: { name: 'RUC', column: 116.5, row: 20, show: true },
    itemName: { name: 'Nombre Artículo', column: 33.5, row: 36, show: true },
    quantity: { name: 'Cantidad', column: 20, row: 36, show: true },
    unitPrice: { name: 'Precio Unitario', column: 108, row: 36, show: true },
    totalPrice: { name: 'Precio Total', column: 159, row: 36, show: true },
    vat5: { name: 'IVA 5%', column: 26, row: 111, show: true },
    vat10: { name: 'IVA 10%', column: 76, row: 111, show: true },
    totalVat: { name: 'Total IVA', column: 126, row: 111, show: true },
    subtotal: { name: 'Subtotal', column: 159.5, row: 100, show: true },
    total: { name: 'Total', column: 159.5, row: 106, show: true },
    totalLetra: { name: 'Total en Letras', column: 17.5, row: 106.2, show: true },
});


  useEffect(() => {
    const getSaleData = async () => {
      if (!saleId) return navigate("/ventas");

      try {
        const { data } = await axiosAuth.get(`/sales/${saleId}`);
        const saleData = data.sale;

        if (data.error && data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
          return;
        }

        const dateFormat = new Date(saleData.createdAt);
        const day = dateFormat.getDate().toString().padStart(2, "0");
        const month = [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Setiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ][dateFormat.getMonth()];
        const formattedDate = `${day} ${month} ${dateFormat.getFullYear()}`;

        saleData.number = saleData.number.toString().padStart(7, "0");

        while (saleData.salesdetails.length < 8) {
          saleData.salesdetails.push({
            quantity: "",
            item: { name: "" },
            price: "",
          });
        }

        setDate(formattedDate);
        setSale(saleData);

        setTimeout(() => {
          window.print();
         // window.close();
        }, 2000);
      } catch (error) {
        console.error("Failed to fetch sale data", error);
      }
    };

    getSaleData();
  }, [saleId, navigate, dispatch]);

  const handlePositionChange = (key, axis, value) => {
    setPositions((prevPositions) => ({
      ...prevPositions,
      [key]: {
        ...prevPositions[key],
        [axis]: parseFloat(value) || 0,
      },
    }));
  };

  const handleShowChange = (key) => {
    setPositions((prevPositions) => ({
      ...prevPositions,
      [key]: {
        ...prevPositions[key],
        show: !prevPositions[key].show,
      },
    }));
  };

  const handleCopy = () => {
    const formattedPositions = Object.entries(positions)
      .map(
        ([key, { column, row, show, name }]) =>
          `${key}: { name: '${name}', column: ${column}, row: ${row}, show: ${show} },`
      )
      .join("\n");

    navigator.clipboard.writeText(formattedPositions).then(
      () => {
        alert("Posiciones copiadas al portapapeles");
      },
      (err) => {
        console.error("Error al copiar al portapapeles", err);
      }
    );
  };


  const generateGrid = () => {
    const lines = [];
    const gridSize = 5;
    const margin = 10;

    for (let i = margin; i <= 290; i += gridSize) {
      lines.push(
        <div
          key={`h-${i}`}
          style={{
            position: "absolute",
            top: `${i}mm`,
            left: `${margin}mm`,
            width: `calc(100% - ${2 * margin}mm)`,
            borderTop: "1px solid lightgray",
            opacity: 1,
            zIndex: 1,
            fontSize: "8px",
            color: "gray",
          }}
        >
          {i > margin && (
            <span style={{ position: "absolute", left: `-17px`, top: "calc(50% - 4.5mm)" }}>
              {i - margin}
            </span>
          )}
        </div>
      );
    }

    for (let i = margin; i <= 200; i += gridSize) {
      lines.push(
        <div
          key={`v-${i}`}
          style={{
            position: "absolute",
            top: `${margin}mm`,
            left: `${i}mm`,
            height: `calc(100% - ${2 * margin}mm)`,
            borderLeft: "1px solid lightgray",
            opacity: 1,
            zIndex: 1,
            fontSize: "8px",
            color: "gray",
          }}
        >
          {i > margin && (
            <span style={{ position: "absolute", top: `-15px`, left: "calc(50% - 2.5mm)", transform: "translateX(-50%)" }}>
              {i - margin}
            </span>
          )}
        </div>
      );
    }

    return lines;
  };

  const generateDiv = (content, position, key, copyIndex = 0) => (
    <div
      key={`${key}-${copyIndex}`}
      style={{
        position: "absolute",
        width: "100%",
        top: `${position.row + copyIndex * copySpacing}mm`,
        left: `${position.column}mm`,
        fontSize: "8px",
        zIndex: 2,
        display: position.show ? "block" : "none", // Condicional para mostrar u ocultar
      }}
    >
      {content}
    </div>
  );

  const detailRowIncrement = 5;

  if (!sale) return null;

  const totalSum = sale.salesdetails.reduce(
    (sum, detail) => sum + detail.quantity * detail.price,
    0
  );

  return (
    <div style={{ display: "flex", height: "100vh" }}>
      <div
        style={{
          width: "210mm",
          height: "297mm",
          backgroundColor: "white",
          position: "relative",
          padding: "20mm",
          boxSizing: "border-box",
          overflow: "auto", // Scroll si el contenido excede
        }}
        id="divToPrint"
      >
        {showGrid && generateGrid()}

        <div style={{ fontFamily: "Arial", position: "relative" }}>
          {sale.state === 1 && (
            <div
              style={{
                position: "absolute",
                top: "28%",
                left: "20%",
                transform: "rotate(-24deg)",
                fontSize: "87px",
                color: "red",
                opacity: 0.9,
                zIndex: 9999,
                pointerEvents: "none",
              }}
            >
              ANULADO
            </div>
          )}

          {Array.from({ length: copyCount }).map((_, copyIndex) => (
            <React.Fragment key={`copy-${copyIndex}`}>
              {generateDiv(date, positions.date, "date", copyIndex)}
              {generateDiv("X", positions.xMark, "xMark", copyIndex)}
              {generateDiv(sale.client.name, positions.clientName, "clientName", copyIndex)}
              {generateDiv(sale.client.ruc, positions.ruc, "ruc", copyIndex)}

              {sale.salesdetails.map((detail, index) => {
                const dynamicRowPosition = positions.itemName.row + index * detailRowIncrement;

                return (
                  <React.Fragment key={`${index}-copy-${copyIndex}`}>
                    {generateDiv(
                      detail.item.name,
                      { ...positions.itemName, row: dynamicRowPosition },
                      `itemName-${index}`,
                      copyIndex
                    )}
                    {generateDiv(
                      detail.quantity,
                      { ...positions.quantity, row: dynamicRowPosition },
                      `quantity-${index}`,
                      copyIndex
                    )}
                    {generateDiv(
                      numberWithCommas(detail.price),
                      { ...positions.unitPrice, row: dynamicRowPosition },
                      `unitPrice-${index}`,
                      copyIndex
                    )}
                    {detail.price * detail.quantity !== 0 &&
                      generateDiv(
                        numberWithCommas(detail.price * detail.quantity),
                        { ...positions.totalPrice, row: dynamicRowPosition },
                        `totalPrice-${index}`,
                        copyIndex
                      )}
                  </React.Fragment>
                );
              })}

              {generateDiv(numberWithCommas(sale.vat5), positions.vat5, "vat5", copyIndex)}
              {generateDiv(numberWithCommas(sale.vat10), positions.vat10, "vat10", copyIndex)}
              {generateDiv(numberWithCommas(sale.vat10), positions.totalVat, "totalVat", copyIndex)}
              {generateDiv(numberWithCommas(totalSum), positions.subtotal, "subtotal", copyIndex)}
              {generateDiv(numberWithCommas(totalSum), positions.total, "total", copyIndex)}

              {generateDiv(
                numeroALetras(totalSum, {
                  plural: "GUARANIES",
                  singular: "GUARANI",
                  centPlural: "CENTAVOS",
                  centSingular: "CENTAVO",
                }),
                positions.totalLetra,
                "totalLetra",
                copyIndex
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
      {showGrid && (
        <>
          <div className="ml-5 p-4 bg-gray-100 rounded-lg max-h-full overflow-y-auto">
            <h4 className="text-lg font-semibold mb-4">Posiciones</h4>
            <div className="mb-4">
              <div className="grid grid-cols-4 gap-4 mb-2">
                <span className="font-medium">Nombre</span>
                <span className="font-medium">Fila</span>
                <span className="font-medium">Columna</span>
                <span className="font-medium text-center">Mostrar</span>
              </div>
              {Object.keys(positions).map((key) => (
                <div key={key} className="grid grid-cols-4 gap-4 items-center mb-2">
                  <span className="capitalize">{positions[key].name}</span>
                  <input
                    type="number"
                    value={positions[key].row}
                    onChange={(e) => handlePositionChange(key, "row", e.target.value)}
                    className="border border-gray-300 rounded p-1 text-sm"
                    step="0.5"
                    style={{ width: "60px" }}
                  />
                  <input
                    type="number"
                    value={positions[key].column}
                    onChange={(e) => handlePositionChange(key, "column", e.target.value)}
                    className="border border-gray-300 rounded p-1 text-sm"
                    step="0.5"
                    style={{ width: "60px" }}
                  />
                  <input
                    type="checkbox"
                    checked={positions[key].show}
                    onChange={() => handleShowChange(key)}
                    className="cursor-pointer"
                  />
                </div>
              ))}
            </div>

            <button
              onClick={handleCopy}
              className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
            >
              Copiar Posiciones
            </button>

            <div className="mt-6">
              <h4 className="text-lg font-semibold mb-4">Copias y Espaciado</h4>
              <div className="mb-4">
                <label className="block mb-2 text-sm font-medium">
                  Cantidad de copias:
                  <input
                    type="number"
                    value={copyCount}
                    onChange={(e) => setCopyCount(Math.max(1, parseInt(e.target.value) || 1))}
                    className="border border-gray-300 rounded p-1 text-sm ml-2"
                    step="0.5" // Permite incrementos de 0.5
                    style={{ width: "60px" }}
                  />
                </label>
              </div>
              <div>
                <label className="block mb-2 text-sm font-medium">
                  Espaciado entre copias (mm):
                  <input
                    type="number"
                    value={copySpacing}
                    onChange={(e) => setCopySpacing(parseFloat(e.target.value) || 0)}
                    className="border border-gray-300 rounded p-1 text-sm ml-2"
                    step="0.5" // Permite incrementos de 0.5
                    style={{ width: "60px" }}
                  />
                </label>
              </div>
            </div>

            {/* Botón para mostrar/ocultar la grilla */}
            <button
              onClick={() => setShowGrid(!showGrid)}
              className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 mt-4"
            >
              {showGrid ? "Ocultar Grilla" : "Mostrar Grilla"}
            </button>
          </div>
        </>
      )}
    </div>

  );
};

export default SalePrint;
