import React, { useState, Fragment, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faTrash,
  faPlus,
  faL,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { Dialog, Transition, Tab } from "@headlessui/react";
import Layout from "../layout";
import { axiosAuth } from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser } from "../store/slices/mainSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Pagination from "../components/pagination";
import DateComponent from "../components/date";
import ComboBox from "../components/combobox";
import useViewport from "../utils/useViewport";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/animations/loading.json";
import toast from "react-hot-toast";
import { numberWithCommas } from "../utils/constants";
import SwipeToRevealActions from "react-swipe-to-reveal-actions";
import search from "../assets/img/search.webp";
import floppy from "../assets/img/floppy.png";
import door from "../assets/img/door.png";
import printer from "../assets/img/printer.png";
import discard from "../assets/img/discard.png";
import pdf from "../assets/img/pdf.png";
import PaymentAlert from "../components/paymentAlert";
const Sales = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [transferOpen, setTransferOpen] = useState(false);
  const [checksOpen, setChecksOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [action, setAction] = useState("edit");
  const [billNumber, setBillNumber] = useState("");

  const { width } = useViewport();

  const { page } = useParams();
  const user = useSelector((state) => state.main.user);
  const title = useSelector((state) => state.main.title);

  const [checks, setChecks] = useState([
    {
      bankId: 0,
      name: "",
      issueDate: "",
      expirationDate: "",
      amount: 0,
      currencyId: 0,
    },
  ]);
  const [sale, setSale] = useState([]);
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [saleConditions, setSaleConditions] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [filteredPaymentMethods, setFilteredPaymentMethods] = useState([]);
  const [filteredPaymentMethodsCompleted, setFilteredPaymentMethodsCompleted] =
    useState(false);

  const [clientIdFilter, setClientIdFilter] = useState(0);

  const [sales, setSales] = useState([]);
  const [banks, setBanks] = useState([]);
  const [items, setItems] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [account, setAccount] = useState([]);
  const [accountId, setAccountId] = useState([]);

  const [articles, setArticles] = useState([]);
  const [deposits, setDeposits] = useState([]);

  const [number, setNumber] = useState(null);
  const [expeditionPoint, setExpeditionPoint] = useState(null);
  const [stamped, setStamped] = useState(null);

  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [totalAmount, setTotalAmount] = useState();

  const [finalTotal, setFinalTotal] = useState(0);
  const [finalVat5, setFinalVat5] = useState(0);
  const [finalVat10, setFinalVat10] = useState(0);
  const [finalTaxed5, setFinalTaxed5] = useState(0);
  const [finalTaxed10, setFinalTaxed10] = useState(0);
  const [finalExempt, setFinalExempt] = useState(0);

  const [saleId, setSaleId] = useState(0);
  const [clientId, setClientId] = useState(0);
  const [currencyId, setCurrencyId] = useState(1);
  const [sellerId, setSellerId] = useState(user.id);
  const [companyId, setCompanyId] = useState(user.companyId);
  const [branchId, setBranchId] = useState(user.branchId);
  const [depositId, setDepositId] = useState(0);
  const [boxId, setBoxId] = useState(user.boxId);
  const [saleConditionId, setSaleConditionId] = useState(1);
  const [paymentMethodId, setPaymentMethodId] = useState(0);
  const [state, setState] = useState(0);

  const [date, setDate] = useState();
  const [dateFromFilter, setDateFromFilter] = useState(() => {
    let MyDateString =
      (
        "0" +
        new Date(new Date().getFullYear(), new Date().getMonth(), 1).getDate()
      ).slice(-2) +
      "/" +
      (
        "0" +
        (new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          1
        ).getMonth() +
          1)
      ).slice(-2) +
      "/" +
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1
      ).getFullYear();

    return MyDateString.replaceAll(".", "")
      .replaceAll("/", "-")
      .split("-")
      .reverse()
      .join("-");
  });
  const [dateToFilter, setDateToFilter] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
      .toLocaleDateString()
      .replaceAll("/", "-")
      .split("-")
      .map((d) => (String(d).length == 1 ? "0" + String(d) : d))
      .reverse()
      .join("-")
  );

  const [deleted, setDeleted] = useState(0);

  const setTotals = async () => {
    let salesRequest = await axiosAuth.get(
      "/sales?page=1&clientId=" +
      clientIdFilter +
      "&billNumber=" +
      billNumber +
      "&companyId=" +
      user.companyId +
      "&dateFrom=" +
      dateFromFilter +
      "&dateTo=" +
      dateToFilter
    );

    let toFinalTotal = 0;
    let toFinalVat5 = 0;
    let toFinalVat10 = 0;
    let toFinalTaxed5 = 0;
    let toFinalTaxed10 = 0;
    let toFinalExempt = 0;

    salesRequest.data.sales.map((saleObj) => {
      saleObj.salesdetails.map((det) => {
        if (det.quantity >= 1 && det.price > 0 && saleObj.state != 1) {
          toFinalTotal += parseInt(det.quantity * det.price);
        }
      });

      if (saleObj.state != 1 && saleObj.vat5 > 0) {
        toFinalVat5 += parseInt(saleObj.vat5);
      }

      if (saleObj.state != 1 && saleObj.vat10 > 0) {
        toFinalVat10 += parseInt(saleObj.vat10);
      }

      if (saleObj.state != 1 && saleObj.taxed5 > 0) {
        toFinalTaxed5 += parseInt(saleObj.taxed5);
      }

      if (saleObj.state != 1 && saleObj.taxed10 > 0) {
        toFinalTaxed10 += parseInt(saleObj.taxed10);
      }

      if (saleObj.state != 1 && saleObj.exempt > 0) {
        toFinalExempt += parseInt(saleObj.exempt);
      }
    });

    setFinalTotal(toFinalTotal);
    setFinalVat5(toFinalVat5);
    setFinalVat10(toFinalVat10);
    setFinalTaxed5(toFinalTaxed5);
    setFinalTaxed10(toFinalTaxed10);
    setFinalExempt(toFinalExempt);
  };

  useEffect(() => {
    setTotals();
  }, []);

  async function fetchSales() {
    let pageQuery = page === undefined ? 1 : page;
    setIsFetching(true);

    let salesRequest = await axiosAuth.get(
      "/sales?page=" +
      pageQuery +
      "&clientId=" +
      clientIdFilter +
      "&billNumber=" +
      billNumber +
      "&companyId=" +
      user.companyId +
      "&dateFrom=" +
      dateFromFilter +
      "&dateTo=" +
      dateToFilter
    );

    setIsFetching(false);

    if (salesRequest.data.error) {
      if (salesRequest.data.message === "token") {
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    // salesRequest.data.sales.clients.unshift({
    //   id: 0,
    //   name: "Elija un cliente",
    // });
    setClients(salesRequest.data.clients.rows);
    setCurrencies(salesRequest.data.currencies);
    setArticles(salesRequest.data.items.rows);

    setSaleConditions(salesRequest.data.saleconditions);
    setPaymentMethods(salesRequest.data.paymentmethods);
    setDeposits(salesRequest.data.deposits);
    setAccounts(salesRequest.data.accounts);
    setBanks(salesRequest.data.banks);

    // let newFilteredPaymentMethods = [];
    // salesRequest.data.sales.paymentmethods.map((paymentMethod) => {
    //   if (
    //     paymentMethod.saleConditionId ===
    //     salesRequest.data.sales.saleconditions[0].id
    //   ) {
    //     paymentMethod.value = 0;
    //     newFilteredPaymentMethods.push(paymentMethod);
    //   }
    // });
    // setFilteredPaymentMethods(newFilteredPaymentMethods);

    setSales(salesRequest.data.sales);
    // setTotal(salesRequest.data.sales.count);
    // setPages(salesRequest.data.sales.pages);
    setTotals();
  }

  useEffect(() => {
    fetchSales();
  }, []);

  useEffect(() => {
    fetchSales();
  }, [location]);

  useEffect(() => {
    setClient(clients.filter((client) => client.id === clientId)[0]);
  }, [clientId]);

  useEffect(() => {
    filterPaymentMethods(saleConditionId);
  }, [saleConditionId, paymentMethods]);

  useEffect(() => {
    let totalAmountSum = 0;

    items.map((item) => {
      totalAmountSum +=
        item.qty * Number(String(item.price).replaceAll(".", ""));
    });

    setTotalAmount(numberWithCommas(totalAmountSum));

    let isFull = true;

    items.map((item) => {
      if (item.name == "") {
        isFull = false;
      }
    });

    let newItems = [...items];

    if (isFull) {
      newItems.push({
        id: 0,
        qty: 0,
        name: "",
        price: 0,
        subtotal: 0,
        iva: "",
      });

      setItems(newItems);
    }
  }, [items]);

  useEffect(() => {
    filteredPaymentMethods.map((filteredPaymentMethod) => {
      if (filteredPaymentMethod.amount > 0) {
        setFilteredPaymentMethodsCompleted(true);
      }
    });
  }, [filteredPaymentMethods]);

  const filterPaymentMethods = (saleConditionId) => {
    let newFilteredPaymentMethods = [];

    paymentMethods.map((paymentMethod) => {
      if (paymentMethod.saleConditionId == saleConditionId) {
        paymentMethod.amount = null;
        sale?.payments?.map((payment) => {
          if (payment.paymentMethodId == paymentMethod.id) {
            paymentMethod.amount = payment.amount;
          }
        });
        newFilteredPaymentMethods.push(paymentMethod);
      }
    });

    setFilteredPaymentMethods(newFilteredPaymentMethods);
  };

  const editSale = async (saleId) => {
    setAction("edit");
    setSaleId(saleId);

    let sale = sales.filter((sale) => sale.id === saleId)[0];
    setSale(sale);

    setState(sale.state);

    setClientId(sale.clientId);

    let clientFiltered = clients.filter(
      (client) => client.id == sale.clientId
    )[0];

    if (clientFiltered == undefined) {
      let clientRequest = await axiosAuth.get(
        "/searchClientById?clientId=" + sale.clientId
      );
      let newClients = [...clients];
      newClients.push(clientRequest.data.client);
      setClients(newClients);
      setClient(clientRequest.data.client);
    } else {
      setClient(clientFiltered);
    }

    setCurrencyId(sale.currencyId);
    setSaleConditionId(sale.saleconditionId);
    setDepositId(sale.depositId);
    filterPaymentMethods(sale.saleconditionId);

    setNumber(sale.number);
    setExpeditionPoint(sale.box.expeditionPoint);
    setStamped(sale.box.stamped);

    paymentMethods.map((paymentMethod) => {
      if (paymentMethod.saleConditionId == saleConditionId) {
        paymentMethod.amount = null;

        sale?.payments?.map((payment) => {
          if (payment.paymentMethodId == paymentMethod.id) {
            paymentMethod.amount = payment.amount;
          }

          if (payment.paymentMethodId == 2) {
            setChecks(payment.checks);
          }

          if (payment.paymentMethodId == 3) {
            console.log(payment);
            setAccount(payment?.transfers[0]?.account);
            setAccountId(payment?.transfers[0]?.account.id);
          }
        });
      }
    });

    setDate(sale.updatedAt.substr(0, 10));

    let newItems = [];

    sale.salesdetails.map(async (saledetail) => {
      newItems.push({
        id: saledetail.itemId,
        qty: saledetail.quantity,
        name: saledetail.item.name,
        price: saledetail?.price,
        subtotal: saledetail?.price * saledetail.quantity,
        iva: saledetail.item.iva,
      });

      let exists = false;

      articles.map((article) => {
        if (article.id == saledetail.itemId) {
          exists = true;
        }
      });

      if (!exists) {
        let itemRequest = await axiosAuth.get(
          "/searchItemById?id=" + saledetail.itemId
        );
        let newArticles = [...articles];
        newArticles.push(itemRequest.data.item[0]);
        setArticles(newArticles);
      }
    });

    if (5 > sale.salesdetails.length) {
      Array(8 - sale.salesdetails.length)
        .fill(0)
        .map((_, i) => {
          newItems.push({
            id: 0,
            qty: 0,
            name: "",
            price: 0,
            subtotal: 0,
            iva: "",
          });
        });
    }

    setItems(newItems);

    setDeleted(sale.deleted);
    setOpen(true);

    setTimeout(function () {
      document.activeElement.blur();
    }, 500);
  };

  const clearFields = () => {
    setSaleId(0);
    setSale([]);
    setAccount({});
    setAccountId(0);
    setChecks([]);
    setClientId(0);
    setCurrencyId(1);
    setDepositId(0);
    setState(0);
    setSaleConditionId(1);
    let newItems = [];

    Array(8)
      .fill(0)
      .map((_, i) => {
        newItems.push({
          id: 0,
          qty: 0,
          name: "",
          price: 0,
          subtotal: 0,
          iva: "",
        });
      });

    setItems(newItems);

    paymentMethods.map((paymentMethod) => {
      if (paymentMethod.saleConditionId == saleConditionId) {
        paymentMethod.amount = null;
      }
    });

    setDeleted(0);
  };

  const handleSubmit = async () => {
    if (action == "edit") {
      // var itemsToSend = [];
      // var paymentsToSend = [];

      // items.map((item) => {
      //   if (item.id > 0) {
      //     itemsToSend.push(item);
      //   }
      // });

      // filteredPaymentMethods.map((paymentMethod) => {
      //   if (paymentMethod.amount > 0) {
      //     paymentsToSend.push(paymentMethod);
      //   }
      // });

      let objToSend = {
        id: saleId,
        // clientId,
        // sellerId,
        // boxId,
        // currencyId,
        // branchId,
        // depositId,
        // companyId,
        createdAt: date,
        // saleconditionId: saleConditionId,
        // salesdetail: itemsToSend,
        // payments: paymentsToSend,
        expeditionPoint,
        number,
        stamped,
        deleted,
      };

      let updatedSaleRequest = await axiosAuth.put("/sales", objToSend);

      if (updatedSaleRequest.data.error) {
        if (updatedSaleRequest.data.message === "token") {
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      let newSales = [];
      sales.map((saleObj) => {
        if (saleObj.id == saleId) {
          newSales.push(updatedSaleRequest.data);
        } else {
          newSales.push(saleObj);
        }
      });
      setSales(newSales);

      setOpen(false);
      return false;
    }

    if (
      clientId === 0 ||
      sellerId === 0 ||
      boxId === 0 ||
      currencyId === 0 ||
      branchId === 0 ||
      depositId === 0 ||
      companyId === 0
    ) {
      alert("Debes completar los campos para continuar.");
      return false;
    }

    var hasItems = false;
    var hasPayments = false;
    var itemsToSend = [];
    var paymentsToSend = [];

    items.map((item) => {
      if (item.id > 0) {
        hasItems = true;
        itemsToSend.push(item);
      }
    });

    filteredPaymentMethods.map((paymentMethod) => {
      if (paymentMethod.amount > 0) {
        hasPayments = true;
        paymentsToSend.push(paymentMethod);
      }
    });

    if (!hasItems) {
      alert("Debes agregar articulos para continuar.");
      return false;
    }

    if (!hasPayments) {
      alert("Debes agregar un monto de pago para continuar.");
      return false;
    }

    let createdSaleRequest = await axiosAuth.post("/sales", {
      clientId,
      sellerId,
      boxId,
      currencyId,
      branchId,
      depositId,
      companyId,
      createdAt: date,
      saleconditionId: saleConditionId,
      salesdetail: itemsToSend,
      payments: paymentsToSend,
      expeditionPoint,
      number,
      stamped,
      deleted,
    });

    if (createdSaleRequest.data.error) {
      if (createdSaleRequest.data.message === "token") {
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    let newSales = [...sales];
    newSales.push(createdSaleRequest.data);
    setSales(newSales);

    toast.success("Venta creada correctamente!");
    if (window.confirm("Desea imprimir factura ?")) {
      window.open("/imprimirfactura/" + createdSaleRequest.data.id);
    } else {
      clearFields();
    }
  };

  const deleteSale = async (saleId) => {
    if (window.confirm("Desea eliminar esta venta ?")) {
      let saleDeleteRequest = await axiosAuth.delete("/sales", {
        params: { id: saleId },
      });

      if (saleDeleteRequest.data.error) {
        if (saleDeleteRequest.data.message === "token") {
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      let newSalesObj = [];

      sales.map((sale) => {
        if (sale.id !== saleId) {
          newSalesObj.push(sale);
        } else {
          if (user.roleId == 1) {
            sale.deleted = 1;
            newSalesObj.push(sale);
          }
        }
        return "";
      });

      setSales(newSalesObj);
    }
  };

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };

  const searchClients = async (search) => {
    const searchClientsRequest = await axiosAuth.get(
      "/searchClients?search=" + search
    );

    setClients(searchClientsRequest.data.clients.rows);
  };

  return (
    <>
      <Layout>
     {/*  {companyId == 11
         && <PaymentAlert />} */}
        <Transition.Root show={checksOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              clearFields();
              setOpen(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto align-items-center justify-center items-center flex">
              <div className="flex justify-center p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-3xl bg-white text-center shadow-xl transition-all sm:my-8 min-w-[380px]">
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-0">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 w-full">
                          <Dialog.Title
                            as="h1"
                            className="text-center w-full font-regular leading-6 primary-color text-2xl"
                          >
                            Cheques
                          </Dialog.Title>
                          <div className="mt-2">
                            <div className="w-full max-w-xs">
                              <form
                                className="bg-white rounded-3xl overflow-hidden"
                                onSubmit={(e) => e.preventDefault()}
                              >
                                <div className="sales-table text-[11px]">
                                  <div className="thead">
                                    <div className="tr flex flex-row justify-around pl-1">
                                      <div className="th">Banco</div>
                                      <div className="th">Nombre</div>
                                      <div className="th">Emision</div>
                                      <div className="th">Vencimiento</div>
                                      <div className="th">Monto</div>
                                      <div className="th">Moneda</div>
                                      <div className="th"></div>
                                    </div>
                                  </div>
                                  <div className="tbody">
                                    {checks.map((check, i) => (
                                      <>
                                        <div className="tr flex flex-col w-full m-1">
                                          <div className="td">
                                            <select
                                              disabled
                                              readOnly
                                              className=" appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-[1.85rem] m-[2px]"
                                              style={{ lineHeight: 0.85 }}
                                              name="bank"
                                              value={check.bankId}
                                            >
                                              <option value={0}>
                                                Elija un banco
                                              </option>
                                              {banks.map((bank) => (
                                                <option value={bank.id}>
                                                  {bank.name}
                                                </option>
                                              ))}
                                            </select>
                                          </div>
                                          <div className="td">
                                            <input
                                              disabled
                                              readOnly
                                              className=" appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-6 m-[2px]"
                                              name="name"
                                              type="text"
                                              value={check.name}
                                            />
                                          </div>
                                          <div className="td">
                                            <input
                                              disabled
                                              readOnly
                                              className=" appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-6 m-[2px]"
                                              name="issueDate"
                                              type="date"
                                              value={check.issueDate.substring(
                                                0,
                                                10
                                              )}
                                            />
                                          </div>
                                          <div className="td">
                                            <input
                                              disabled
                                              readOnly
                                              className=" appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-6 m-[2px]"
                                              name="expirationDate"
                                              type="date"
                                              value={check.expirationDate.substring(
                                                0,
                                                10
                                              )}
                                            />
                                          </div>
                                          <div className="td">
                                            <input
                                              disabled
                                              readOnly
                                              className=" appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-6 m-[2px]"
                                              name="amount"
                                              type="number"
                                              value={numberWithCommas(
                                                check.amount
                                              )}
                                            />
                                          </div>
                                          <div className="td">
                                            <select
                                              disabled
                                              readOnly
                                              className=" appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-[1.85rem] m-[2px]"
                                              style={{ lineHeight: 0.85 }}
                                              name="currency"
                                              value={check.currencyId}
                                            >
                                              <option value={0}>
                                                Elija una moneda
                                              </option>
                                              {currencies.map((currency) => (
                                                <option value={currency.id}>
                                                  {currency.name}
                                                </option>
                                              ))}
                                            </select>
                                          </div>
                                        </div>
                                        <div className="tr h-2 border border-b-2 border-gray-400"></div>
                                      </>
                                    ))}
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 justify-center flex sm:px-6">
                      <button
                        type="button"
                        className="inline-flex w-full rounded-3xl font-family-thin secondary-bg-color justify-center px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto font-family-thin"
                        onClick={() => {
                          setChecksOpen(false);
                        }}
                      >
                        CANCELAR
                      </button>
                      <button
                        type="button"
                        className="inline-flex w-full align-center rounded-3xl align-items-center justify-center primary-color-bg px-3 py-2 text-sm font-semibold  shadow-sm sm:ml-3 sm:w-auto font-family-thin"
                        style={{ alignItems: "center" }}
                        onClick={() => {
                          setChecksOpen(false);
                        }}
                      >
                        GUARDAR
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <Transition.Root show={transferOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              clearFields();
              setOpen(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto align-items-center justify-center items-center flex">
              <div className="flex justify-center p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-3xl bg-white text-center shadow-xl transition-all sm:my-8 w-[340px]">
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-0">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 w-full">
                          <Dialog.Title
                            as="h1"
                            className="text-center w-full font-regular leading-6 primary-color text-2xl"
                          >
                            Transferencia
                          </Dialog.Title>
                          <div className="mt-2">
                            <div className="w-full max-w-xs">
                              <form className="bg-white rounded-3xl">
                                <select
                                  readOnly
                                  disabled
                                  name="accountId"
                                  value={accountId}
                                  className=" appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[30px] text-center m-[2px]"
                                >
                                  <option value={0}>Elija una cuenta</option>
                                  {accounts.map((account) => (
                                    <option value={account.id}>
                                      {account.name}
                                    </option>
                                  ))}
                                </select>

                                <input
                                  readOnly
                                  disabled
                                  placeholder="Nro. de Cuenta"
                                  name="accountNumber"
                                  value={account?.number}
                                  className=" appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[30px] text-center m-[2px]"
                                />

                                <input
                                  readOnly
                                  disabled
                                  placeholder="Propietario"
                                  name="accountOwner"
                                  value={account?.owner}
                                  className=" appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[30px] text-center m-[2px]"
                                />

                                <input
                                  readOnly
                                  disabled
                                  placeholder="Banco"
                                  name="accountBank"
                                  value={account?.bank?.name}
                                  className=" appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[30px] text-center m-[2px]"
                                />

                                <input
                                  readOnly
                                  disabled
                                  placeholder="Tipo de cuenta"
                                  name="accounType"
                                  value={account?.accountype?.description}
                                  className=" appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[30px] text-center m-[2px]"
                                />

                                <input
                                  readOnly
                                  disabled
                                  placeholder="Moneda"
                                  name="accounCurrency"
                                  value={account?.currency?.name}
                                  className=" appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[30px] text-center m-[2px]"
                                />
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 justify-center flex sm:px-6">
                      <button
                        type="button"
                        className="inline-flex w-full rounded-3xl font-family-thin secondary-bg-color justify-center px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto font-family-thin"
                        onClick={() => {
                          setAccountId(0);
                          setAccount([]);
                          setTransferOpen(false);
                        }}
                      >
                        CANCELAR
                      </button>
                      <button
                        type="button"
                        className="inline-flex w-full align-center rounded-3xl align-items-center justify-center primary-color-bg px-3 py-2 text-sm font-semibold  shadow-sm sm:ml-3 sm:w-auto font-family-thin"
                        style={{ alignItems: "center" }}
                        onClick={() => setTransferOpen(false)}
                      >
                        GUARDAR
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10 w-full sales-modal"
            onClose={() => {
              // clearFields();
              // setOpen(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto align-items-center justify-center items-center flex">
              <div className="flex justify-center p-4 text-center sm:p-0 w-[95%] h-[90%]">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel
                    className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-full overflow-y-auto"
                    style={{ marginTop: 0 }}
                  >


                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-0 text-center">
                      <div className="w-full">
                        <Dialog.Title
                          as="h1"
                          className="text-center font-regular leading-6 primary-color text-3xl"
                        >
                          Ventas
                        </Dialog.Title>
                        <div className="mt-5">
                          <div className="w-full flex flex-col">
                            {/* {action === "edit" && (
                                  <div className="mb-4">
                                    <label
                                      className="block text-gray-700 text-sm font-bold"
                                      htmlFor="SaleId"
                                    >
                                      Id
                                    </label>
                                    <input
                                      readOnly
                                      className="input-bg-color appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      id="SaleId"
                                      type="text"
                                      value={saleId}
                                    />
                                  </div>
                                )} */}

                            {width > 550 ? (
                              <>
                                {state == 1 && (
                                  <div
                                    className="w-full h-full absolute top-[0] left-[0] flex flex-col justify-center items-center"
                                    style={{
                                      zIndex: 9,
                                      backgroundColor: "rgba(0,0,0,0.5)",
                                    }}
                                  >
                                    {" "}
                                    <h1
                                      className="font-black rotate-[-45deg]"
                                      style={{ fontSize: "70px", color: "red" }}
                                    >
                                      ANULADO
                                    </h1>
                                  </div>
                                )}
                                <div className="flex flex-row w-full">
                                  <div className="w-[24.8%]">
                                    <div className="mb-[2px] font-bold">
                                      <ComboBox
                                        data={clients}
                                        disabled={true}
                                        setData={setClientId}
                                        value={clientId}
                                        placeholder="Cliente"
                                        className="h-[24px]"
                                      />
                                    </div>

                                    <div className="mb-[2px] font-bold">
                                      <input
                                        className="input-bg-color appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                        id="RUC"
                                        type="text"
                                        disabled
                                        readOnly
                                        value={client?.ruc || ""}
                                        placeholder="RUC"
                                        onChange={(e) => { }}
                                      />
                                    </div>

                                    <div className="mb-[2px] font-bold">
                                      <input
                                        className="input-bg-color appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                        id="address"
                                        type="text"
                                        readOnly
                                        disabled
                                        value={client?.address || ""}
                                        placeholder="Direccion"
                                        onChange={(e) => { }}
                                      />
                                    </div>

                                    <div className="mb-[2px] font-bold">
                                      <input
                                        className="input-bg-color appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                        id="phone"
                                        type="text"
                                        readOnly
                                        disabled
                                        value={client?.phone || ""}
                                        placeholder="Telefono"
                                        onChange={(e) => { }}
                                      />
                                    </div>
                                  </div>

                                  <div className="w-[24.8%] ml-[2px]">
                                    <div className="mb-[2px] flex flex-row font-bold">
                                      <select
                                        className="input-bg-color appearance-none border rounded w-[49.5%] py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[23px]"
                                        id="branch"
                                        readOnly
                                        disabled
                                        value={branchId}
                                        onChange={(e) => {
                                          setBranchId(e.target.value);
                                        }}
                                      >
                                        <option value={0}>Sucursal</option>
                                        <option value={user.branch.id}>
                                          Sucursal: {user.branch.description}
                                        </option>
                                      </select>

                                      <select
                                        className="input-bg-color appearance-none border rounded w-[49.5%] py-1 px-3 text-gray-700 leading-tight focus:outline-none ml-[2px] focus:shadow-outline capitalize h-[23px]"
                                        id="deposit"
                                        readOnly
                                        disabled
                                        value={depositId}
                                        onChange={(e) => {
                                          setDepositId(e.target.value);
                                        }}
                                      >
                                        <option value={0}>
                                          Elija un deposito
                                        </option>
                                        {deposits.map((deposit) => (
                                          <option value={deposit.id}>
                                            {deposit.description}
                                          </option>
                                        ))}
                                      </select>
                                    </div>

                                    <div className="mb-[2px] font-bold">
                                      <select
                                        className="input-bg-color appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                        id="branch"
                                        value={currencyId}
                                        onChange={(e) => {
                                          setCurrencyId(e.target.value);
                                        }}
                                      >
                                        <option value={0}>Moneda</option>
                                        {currencies.map((currency, i) => (
                                          <option value={currency.id} key={i}>
                                            Moneda: {currency.name}
                                          </option>
                                        ))}
                                      </select>
                                    </div>

                                    <div className="mb-[2px] font-bold">
                                      <input
                                        className="input-bg-color appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-[24px]"
                                        id="date"
                                        value={date}
                                        type="date"
                                        placeholder="Fecha"
                                        onChange={(e) => {
                                          setDate(e.target.value);
                                        }}
                                      />
                                    </div>
                                    <div className="mb-[2px] font-bold">
                                      <select
                                        className="input-bg-color appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                        id="sellerId"
                                        disabled
                                        readOnly
                                        value={sellerId}
                                        onChange={(e) => { }}
                                      >
                                        <option value={0}>Vendedor</option>
                                        <option value={user.id}>
                                          Vendedor: {user.fullname}
                                        </option>
                                      </select>
                                    </div>
                                  </div>

                                  <div className="w-[24.8%] ml-[2px] font-bold">
                                    <div className="mb-[2px]">
                                      <input
                                        className="input-bg-color appearance-none border rounded py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-[50%]"
                                        id="expeditionPoint"
                                        value={expeditionPoint}
                                        readOnly
                                        disabled
                                        type="text"
                                        placeholder="Pto. De Exped."
                                        onChange={(e) => { }}
                                      />

                                      <input
                                        className=" mb-[2px] font-bold input-bg-color appearance-none border rounded py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-[50%]"
                                        id="number"
                                        value={number}
                                        type="text"
                                        placeholder="Numero"
                                        onChange={(e) => {
                                          setNumber(e.target.value);
                                        }}
                                      />
                                    </div>

                                    <div className="mb-[2px] font-bold">
                                      <select
                                        className="input-bg-color appearance-none border rounded w-[50%] py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                        id="boxId"
                                        value={boxId}
                                        disabled
                                        readOnly
                                        onChange={(e) => {
                                          setBoxId(e.target.value);
                                        }}
                                      >
                                        <option value={0}>Caja</option>
                                        <option value={user.box.id}>
                                          {user.box.name}
                                        </option>
                                      </select>

                                      <input
                                        className="input-bg-color appearance-none border rounded w-[50%] py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        id="stamped"
                                        value={stamped}
                                        readOnly
                                        disabled
                                        type="text"
                                        placeholder="Timbrado"
                                        onChange={(e) => { }}
                                      />
                                    </div>

                                    <div className="mb-[2px] font-bold">
                                      <select
                                        className="input-bg-color appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                        id="saleConditionId"
                                        value={saleConditionId}
                                        disabled={
                                          action == "edit" ? true : false
                                        }
                                        readOnly={
                                          action == "edit" ? true : false
                                        }
                                        onChange={(e) => {
                                          setSaleConditionId(e.target.value);
                                          filterPaymentMethods(e.target.value);
                                        }}
                                      >
                                        <option value={0}>
                                          Condicion de venta
                                        </option>
                                        {saleConditions.map((saleCondition) => (
                                          <option value={saleCondition.id}>
                                            {saleCondition.description}
                                          </option>
                                        ))}
                                      </select>
                                    </div>

                                    {filteredPaymentMethods.length > 0 ? (
                                      <div>
                                        <input
                                          readOnly={
                                            action == "edit" ? true : false
                                          }
                                          className="input-bg-color appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[21px]"
                                          value={numberWithCommas(
                                            filteredPaymentMethods[0]?.amount
                                          )}
                                          placeholder={
                                            filteredPaymentMethods[0]?.name
                                          }
                                          onChange={(e) => {
                                            let newFilteredPaymentMethods = [];
                                            paymentMethods.map(
                                              (paymentMethod) => {
                                                if (
                                                  paymentMethod.id ==
                                                  filteredPaymentMethods[0].id
                                                ) {
                                                  paymentMethod.amount =
                                                    e.target.value;
                                                  newFilteredPaymentMethods.push(
                                                    paymentMethod
                                                  );
                                                } else {
                                                  newFilteredPaymentMethods.push(
                                                    paymentMethod
                                                  );
                                                }
                                              }
                                            );
                                            setFilteredPaymentMethods(
                                              newFilteredPaymentMethods
                                            );
                                          }}
                                        />
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                  </div>

                                  <div className="w-[24.8%] ml-[2px] font-bold">
                                    {filteredPaymentMethods.map(
                                      (paymentMethod, i) =>
                                        i < 1 ? (
                                          <></>
                                        ) : (
                                          <div className="mb-[2px]">
                                            <input
                                              readOnly={
                                                action == "edit" ? true : false
                                              }
                                              className="input-bg-color appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-[24px]"
                                              onClick={() => {
                                                if (
                                                  paymentMethod.id == 2 &&
                                                  paymentMethod.amount > 0
                                                ) {
                                                  setChecksOpen(true);
                                                }
                                                if (
                                                  paymentMethod.id == 3 &&
                                                  paymentMethod.amount > 0
                                                ) {
                                                  setTransferOpen(true);
                                                }
                                              }}
                                              value={numberWithCommas(
                                                paymentMethod.amount
                                              )}
                                              type="text"
                                              placeholder={paymentMethod.name}
                                              onChange={(e) => {
                                                let newFilteredPaymentMethods =
                                                  [];
                                                paymentMethods.map(
                                                  (paymentMethodMap) => {
                                                    if (
                                                      paymentMethodMap.id ==
                                                      paymentMethod.id
                                                    ) {
                                                      paymentMethodMap.amount =
                                                        e.target.value;
                                                      newFilteredPaymentMethods.push(
                                                        paymentMethodMap
                                                      );
                                                    } else {
                                                      newFilteredPaymentMethods.push(
                                                        paymentMethodMap
                                                      );
                                                    }
                                                  }
                                                );
                                                setFilteredPaymentMethods(
                                                  newFilteredPaymentMethods
                                                );
                                              }}
                                            />
                                          </div>
                                        )
                                    )}
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <Tab.Group>
                                  <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1 mb-[2px]">
                                    <Tab
                                      className={({ selected }) =>
                                        classNames(
                                          "w-full rounded-lg py-1.5 text-sm leading-5 primary-color text-xs",
                                          "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                                          clientId == 0
                                            ? "bg-red-300"
                                            : "bg-green-200",
                                          selected
                                            ? "bg-white font-bold"
                                            : "primary-color hover:text-slate-500"
                                        )
                                      }
                                    >
                                      Cliente
                                    </Tab>
                                    <Tab
                                      className={({ selected }) =>
                                        classNames(
                                          "w-full rounded-lg py-1.5 text-sm leading-5 primary-color text-xs",
                                          "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                                          branchId == 0 ||
                                            currencyId == 0 ||
                                            date == "" ||
                                            sellerId == 0
                                            ? "bg-red-300"
                                            : "bg-green-200",
                                          selected
                                            ? "bg-white font-bold"
                                            : "primary-color hover:text-slate-500"
                                        )
                                      }
                                    >
                                      Datos
                                    </Tab>
                                    <Tab
                                      onClick={() => {
                                        filterPaymentMethods(saleConditionId);
                                      }}
                                      className={({ selected }) =>
                                        classNames(
                                          "w-full rounded-lg py-1.5 text-sm leading-5 primary-color text-xs",
                                          "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                                          saleConditionId == 0 || boxId == 0
                                            ? "bg-red-300"
                                            : "bg-green-200",
                                          selected
                                            ? "bg-white font-bold"
                                            : "primary-color hover:bg-white/[0.12] hover:text-slate-500"
                                        )
                                      }
                                    >
                                      Detalles
                                    </Tab>
                                    <Tab
                                      className={({ selected }) =>
                                        classNames(
                                          "w-full rounded-lg py-1.5 text-sm leading-5 primary-color text-xs",
                                          "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                                          filteredPaymentMethodsCompleted
                                            ? "bg-green-200"
                                            : "bg-red-300",
                                          selected
                                            ? "bg-white font-bold"
                                            : "primary-color hover:bg-white/[0.12] hover:text-slate-500"
                                        )
                                      }
                                    >
                                      Formas de Pago
                                    </Tab>
                                  </Tab.List>
                                  <Tab.Panels>
                                    <Tab.Panel>
                                      <Transition.Child
                                        enter="ease-out duration-500"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in duration-300"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                      >
                                        <div className="mb-[2px] font-bold">
                                          <ComboBox
                                            data={clients}
                                            disabled={true}
                                            setData={setClientId}
                                            value={clientId}
                                            placeholder="Cliente"
                                          // className="h-[24px]"
                                          />
                                        </div>

                                        <div className="mb-[2px] font-bold">
                                          <input
                                            className="input-bg-color appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                            id="RUC"
                                            type="text"
                                            disabled
                                            readOnly
                                            value={client?.ruc || ""}
                                            placeholder="RUC"
                                            onChange={(e) => { }}
                                          />
                                        </div>

                                        <div className="mb-[2px] font-bold">
                                          <input
                                            className="input-bg-color appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                            id="address"
                                            type="text"
                                            readOnly
                                            disabled
                                            value={client?.address || ""}
                                            placeholder="Direccion"
                                            onChange={(e) => { }}
                                          />
                                        </div>

                                        <div className="font-bold">
                                          <input
                                            className="font-bold input-bg-color appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                            id="phone"
                                            type="text"
                                            readOnly
                                            disabled
                                            value={client?.phone || ""}
                                            placeholder="Telefono"
                                            onChange={(e) => { }}
                                          />
                                        </div>
                                      </Transition.Child>
                                    </Tab.Panel>
                                    <Tab.Panel>
                                      <Transition.Child
                                        enter="ease-out duration-500"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in duration-300"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                      >
                                        <div className="mb-[2px] mb-[2px] font-bold">
                                          <select
                                            className="input-bg-color appearance-none border rounded w-[49.5%] py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                            id="branch"
                                            readOnly
                                            disabled
                                            value={branchId}
                                            onChange={(e) => {
                                              setBranchId(e.target.value);
                                            }}
                                          >
                                            <option value={0}>Sucursal</option>
                                            <option value={user.branch.id}>
                                              {user.branch.description}
                                            </option>
                                          </select>

                                          <select
                                            className="input-bg-color appearance-none border rounded w-[49.5%] ml-[2px] py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                            id="deposit"
                                            readOnly
                                            disabled
                                            value={depositId}
                                            onChange={(e) => {
                                              setDepositId(e.target.value);
                                            }}
                                          >
                                            <option value={0}>
                                              Elija un deposito
                                            </option>
                                            {deposits.map((deposit) => (
                                              <option value={deposit.id}>
                                                {deposit.description}
                                              </option>
                                            ))}
                                          </select>
                                        </div>

                                        <div className="mb-[2px] mb-[2px] font-bold">
                                          <select
                                            className="input-bg-color appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                            id="branch"
                                            value={currencyId}
                                            onChange={(e) => {
                                              setCurrencyId(e.target.value);
                                            }}
                                          >
                                            <option value={0}>Moneda</option>
                                            {currencies.map((currency, i) => (
                                              <option
                                                value={currency.id}
                                                key={i}
                                              >
                                                {currency.name}
                                              </option>
                                            ))}
                                          </select>
                                        </div>

                                        <div className="mb-[2px] font-bold">
                                          <input
                                            className="input-bg-color appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="date"
                                            value={date}
                                            type="date"
                                            placeholder="Fecha"
                                            onChange={(e) => {
                                              setDate(e.target.value);
                                            }}
                                          />
                                        </div>
                                        <div>
                                          <select
                                            className="input-bg-color appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                            id="sellerId"
                                            disabled
                                            readOnly
                                            value={sellerId}
                                            onChange={(e) => { }}
                                          >
                                            <option value={0}>Vendedor</option>
                                            <option value={user.id}>
                                              {user.fullname}
                                            </option>
                                          </select>
                                        </div>
                                      </Transition.Child>
                                    </Tab.Panel>
                                    <Tab.Panel>
                                      <Transition.Child
                                        enter="ease-out duration-500"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in duration-300"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                      >
                                        <div className="mb-[2px]">
                                          <input
                                            className="input-bg-color appearance-none border rounded w-[33%] py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="number"
                                            readOnly
                                            disabled
                                            value={expeditionPoint}
                                            type="text"
                                            placeholder="Pto. De Exp."
                                            onChange={(e) => { }}
                                          />

                                          <input
                                            className="input-bg-color appearance-none border rounded w-[33%] py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="stamped"
                                            readOnly
                                            disabled
                                            value={stamped}
                                            type="text"
                                            placeholder="Timbrado"
                                            onChange={(e) => { }}
                                          />

                                          <input
                                            className="input-bg-color appearance-none border rounded w-[33%] py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="number"
                                            value={number}
                                            type="text"
                                            placeholder="Numero"
                                            onChange={(e) => {
                                              setNumber(e.target.value);
                                            }}
                                          />
                                        </div>

                                        <div className="mb-[2px]">
                                          <select
                                            className="input-bg-color appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                            id="boxId"
                                            value={boxId}
                                            disabled
                                            readOnly
                                            onChange={(e) => {
                                              setBoxId(e.target.value);
                                            }}
                                          >
                                            <option value={0}>Caja</option>
                                            <option value={user.box.id}>
                                              {user.box.name}
                                            </option>
                                          </select>
                                        </div>

                                        <div className="mb-[2px]">
                                          <select
                                            className="input-bg-color appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                            id="saleConditionId"
                                            value={saleConditionId}
                                            disabled={
                                              action == "edit" ? true : false
                                            }
                                            readOnly={
                                              action == "edit" ? true : false
                                            }
                                            onChange={(e) => {
                                              setSaleConditionId(
                                                e.target.value
                                              );
                                              filterPaymentMethods(
                                                e.target.value
                                              );
                                            }}
                                          >
                                            <option value={0}>
                                              Condicion de venta
                                            </option>
                                            {saleConditions.map(
                                              (saleCondition) => (
                                                <option
                                                  value={saleCondition.id}
                                                >
                                                  {saleCondition.description}
                                                </option>
                                              )
                                            )}
                                          </select>
                                        </div>

                                        {filteredPaymentMethods.length > 0 ? (
                                          <div>
                                            <input
                                              readOnly={
                                                action == "edit" ? true : false
                                              }
                                              className="input-bg-color appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                              value={numberWithCommas(
                                                filteredPaymentMethods[0]
                                                  ?.amount
                                              )}
                                              placeholder={
                                                filteredPaymentMethods[0]?.name
                                              }
                                              onChange={(e) => {
                                                let newFilteredPaymentMethods =
                                                  [];
                                                paymentMethods.map(
                                                  (paymentMethod) => {
                                                    if (
                                                      paymentMethod.id ==
                                                      filteredPaymentMethods[0]
                                                        .id
                                                    ) {
                                                      paymentMethod.amount =
                                                        e.target.value;
                                                      newFilteredPaymentMethods.push(
                                                        paymentMethod
                                                      );
                                                    } else {
                                                      newFilteredPaymentMethods.push(
                                                        paymentMethod
                                                      );
                                                    }
                                                  }
                                                );
                                                setFilteredPaymentMethods(
                                                  newFilteredPaymentMethods
                                                );
                                              }}
                                            />
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                      </Transition.Child>
                                    </Tab.Panel>
                                    <Tab.Panel>
                                      <Transition.Child
                                        enter="ease-out duration-500"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in duration-300"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                      >
                                        {filteredPaymentMethods.map(
                                          (paymentMethod, i) =>
                                            i > 0 && (
                                              <div className="mb-[2px]">
                                                <input
                                                  readOnly={
                                                    action == "edit"
                                                      ? true
                                                      : false
                                                  }
                                                  onClick={() => {
                                                    if (
                                                      paymentMethod.id == 2 &&
                                                      paymentMethod.amount > 0
                                                    ) {
                                                      setChecksOpen(true);
                                                    }
                                                    if (
                                                      paymentMethod.id == 3 &&
                                                      paymentMethod.amount > 0
                                                    ) {
                                                      setTransferOpen(true);
                                                    }
                                                  }}
                                                  className="input-bg-color appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                  value={numberWithCommas(
                                                    paymentMethod.amount
                                                  )}
                                                  type="text"
                                                  placeholder={
                                                    paymentMethod.name
                                                  }
                                                  onChange={(e) => {
                                                    let newFilteredPaymentMethods =
                                                      [];
                                                    paymentMethods.map(
                                                      (paymentMethodMap) => {
                                                        if (
                                                          paymentMethodMap.id ==
                                                          paymentMethod.id
                                                        ) {
                                                          paymentMethodMap.amount =
                                                            e.target.value;
                                                          newFilteredPaymentMethods.push(
                                                            paymentMethodMap
                                                          );
                                                        } else {
                                                          newFilteredPaymentMethods.push(
                                                            paymentMethodMap
                                                          );
                                                        }
                                                      }
                                                    );
                                                    setFilteredPaymentMethods(
                                                      newFilteredPaymentMethods
                                                    );
                                                  }}
                                                />
                                              </div>
                                            )
                                        )}
                                      </Transition.Child>
                                    </Tab.Panel>
                                  </Tab.Panels>
                                </Tab.Group>
                              </>
                            )}

                            <div className="w-full h-[45vh] lg:h-[50vh] flex flex-row mt-2  p-2">
                              <div className="w-[100%] overflow-x-auto pb-2">
                                <div className="mb-[2px]">
                                  <div className="sales-table w-full">
                                    <div className="!text-white primary-color-bg">
                                      {width > 620 ? (
                                        <div className="tr flex">
                                          <div className="w-[10%] font-bold !text-white">
                                            COD.
                                          </div>
                                          <div className="w-[43%] font-bold !text-white">
                                            PRODUCTO
                                          </div>
                                          <div className="w-[10%] font-bold !text-white">
                                            CANT
                                          </div>
                                          <div className="w-[15%] font-bold !text-white">
                                            PRECIO
                                          </div>
                                          <div className="w-[15%] font-bold !text-white">
                                            TOTAL
                                          </div>
                                          <div className="w-[9%] font-bold !text-white">
                                            IVA
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="tr flex">
                                          <div className="w-[15%] font-bold !text-white">
                                            COD.
                                          </div>
                                          <div className="w-[75%] font-bold !text-white">
                                            PRODUCTO
                                          </div>
                                          <div className="w-[15%] font-bold !text-white">
                                            CANT
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    <div className="overflow-y-auto overflow-x-hidden">
                                      {width > 620
                                        ? items.map((item, i) => (
                                          <div className="tr flex">
                                            <div className="td w-[10%]">
                                              <input
                                                className="input-bg-color py-1 px-3 w-full text-center"
                                                type="text"
                                                name="code"
                                                value={item.id}
                                              />
                                            </div>

                                            <div className="td w-[43%]">
                                              <ComboBox
                                                data={articles}
                                                value={item.id}
                                                setData={setItems}
                                                items={items}
                                                index={i}
                                                className="h-[24px]"
                                              />
                                            </div>
                                            <div className="td w-[10%]">
                                              <input
                                                className="input-bg-color py-1 px-3 w-full text-center"
                                                type="text"
                                                name="qty"
                                                value={item.qty}
                                                onChange={(e) => {
                                                  let newItems = [...items];
                                                  newItems.map((item, it) => {
                                                    if (i == it) {
                                                      item.qty =
                                                        e.target.value;
                                                      item.subtotal =
                                                        String(
                                                          item.price
                                                        ).replaceAll(
                                                          ".",
                                                          ""
                                                        ) * item.qty;
                                                      item.subtotal =
                                                        numberWithCommas(
                                                          item.subtotal
                                                        );
                                                    }
                                                  });
                                                  setItems(newItems);
                                                }}
                                              />
                                            </div>
                                            <div className="td w-[15%]">
                                              <input
                                                className="input-bg-color py-1 px-3 w-full text-right"
                                                type="text"
                                                name="price"
                                                value={numberWithCommas(
                                                  item.price
                                                )}
                                                placeholder="Precio"
                                                onChange={(e) => {
                                                  let newItems = [...items];
                                                  newItems.map((item, it) => {
                                                    if (i == it) {
                                                      item.price = Number(
                                                        String(
                                                          e.target.value
                                                        ).replaceAll(".", "")
                                                      );
                                                      item.subtotal =
                                                        String(
                                                          item.price
                                                        ).replaceAll(
                                                          ".",
                                                          ""
                                                        ) * item.qty;
                                                      item.subtotal =
                                                        numberWithCommas(
                                                          item.subtotal
                                                        );
                                                    }
                                                  });
                                                  setItems(newItems);
                                                }}
                                              />
                                            </div>
                                            <div className="td w-[15%]">
                                              <input
                                                className="input-bg-color py-1 px-3 w-full text-right"
                                                type="text"
                                                name="subtotal"
                                                value={numberWithCommas(
                                                  item.subtotal
                                                )}
                                              />
                                            </div>
                                            <div className="td w-[7%]">
                                              <input
                                                className="input-bg-color py-1 px-3 w-full text-center"
                                                type="text"
                                                name="iva"
                                                value={item.iva}
                                              />
                                            </div>
                                          </div>
                                        ))
                                        : items.map((item, i) => (
                                          <div
                                            className="tr flex flex-col m-1 pb-1"
                                            style={{
                                              borderBottom:
                                                "1px solid rgba(0,0,0,0.5)",
                                            }}
                                          >
                                            <div className="w-full flex flex-row">
                                              <div className="td w-[20%]">
                                                <input
                                                  className="input-bg-color py-1 px-3 w-full text-center"
                                                  type="text"
                                                  readOnly
                                                  disabled
                                                  name="code"
                                                  value={item.id}
                                                />
                                              </div>

                                              <div className="td w-[60%]">
                                                <ComboBox
                                                  data={articles}
                                                  value={item.id}
                                                  setData={setItems}
                                                  items={items}
                                                  index={i}
                                                  className="h-[25px]"
                                                />
                                              </div>
                                              <div className="td w-[20%]">
                                                <input
                                                  className="input-bg-color py-1 px-3 w-full text-center"
                                                  type="text"
                                                  name="qty"
                                                  value={item.qty}
                                                  onChange={(e) => {
                                                    let newItems = [...items];
                                                    newItems.map(
                                                      (item, it) => {
                                                        if (i == it) {
                                                          item.qty =
                                                            e.target.value;
                                                          item.subtotal =
                                                            String(
                                                              item.price
                                                            ).replaceAll(
                                                              ".",
                                                              ""
                                                            ) * item.qty;
                                                          item.subtotal =
                                                            numberWithCommas(
                                                              item.subtotal
                                                            );
                                                        }
                                                      }
                                                    );
                                                    setItems(newItems);
                                                  }}
                                                />
                                              </div>
                                            </div>

                                            <div className="w-full flex flex-row">
                                              <div className="td w-[20%]">
                                                <input
                                                  className="input-bg-color py-1 px-3 w-full text-center"
                                                  type="text"
                                                  name="price"
                                                  value={numberWithCommas(
                                                    item.price
                                                  )}
                                                  placeholder="Precio"
                                                  onChange={(e) => {
                                                    let newItems = [...items];
                                                    newItems.map(
                                                      (item, it) => {
                                                        if (i == it) {
                                                          item.price = Number(
                                                            String(
                                                              e.target.value
                                                            ).replaceAll(
                                                              ".",
                                                              ""
                                                            )
                                                          );
                                                          item.subtotal =
                                                            String(
                                                              item.price
                                                            ).replaceAll(
                                                              ".",
                                                              ""
                                                            ) * item.qty;
                                                          item.subtotal =
                                                            numberWithCommas(
                                                              item.subtotal
                                                            );
                                                        }
                                                      }
                                                    );
                                                    setItems(newItems);
                                                  }}
                                                />
                                              </div>
                                              <div className="td w-[60%]">
                                                <input
                                                  className="input-bg-color py-1 px-3 w-full text-right"
                                                  type="text"
                                                  readOnly
                                                  disabled
                                                  name="subtotal"
                                                  value={numberWithCommas(
                                                    item.subtotal
                                                  )}
                                                />
                                              </div>
                                              <div className="td w-[20%]">
                                                <input
                                                  className="input-bg-color py-1 px-3 w-full text-center"
                                                  type="text"
                                                  name="iva"
                                                  value={item.iva}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                    </div>
                                    <div className="tr flex flex-row justify-end items-center m-[2px]">
                                      <div className="td text-right font-bold mr-1">
                                        TOTAL
                                      </div>
                                      <div className="td w-[15%]">
                                        <input
                                          className="input-bg-color py-1 px-3 w-full text-right font-bold"
                                          type="text"
                                          readOnly
                                          disabled
                                          name="total"
                                          value={totalAmount}
                                          placeholder="Total"
                                        />
                                      </div>
                                      <div className="td w-[7%]"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`px-4 py-2 pl-36 sm:pl-0 justify-center flex overflow-hidden overflow-x-auto fixed z-[200] ${state === 1 ? "" : "hidden"
                          }`}
                          style={{   left: "calc(50% - 116px)"}}
                      >
                        {state === 1 && (
                          <>


                            <button
                              type="button"
                              disabled={action === "edit" ? false : true}
                              readOnly={action === "edit" ? false : true}
                              className="float-left inline-flex sm:w-[110px] w-[150px] h-[63] ml-[1px] flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 !text-[10px]"
                              style={{ alignItems: "center" }}
                              onClick={() => {
                                const configuracionElementos = localStorage.getItem("configuracionElementos");
                                const url = configuracionElementos ? "/imprimirfactura/" + saleId : "/imprimirfactura/" + saleId;
                                window.open(url);
                              }}
                            >
                              <img src={printer} className="w-8" />
                              IMPRIMIR
                            </button>



                            <button
                              type="button"
                              className="float-left inline-flex sm:w-[110px] w-[150px] h-[63] ml-[1px] flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 !text-[10px]"
                              style={{ alignItems: "center" }}
                              onClick={() => {
                                clearFields();
                                setOpen(false);
                              }}
                            >
                              <img src={door} className="w-6" />
                              SALIR
                            </button>
                          </>
                        )}
                      </div>

                      {/* Mostrar solo los estilos fijos cuando la venta está anulada */}
                      <div
                        className={`px-4 py-2 pl-36 sm:pl-0 justify-center flex overflow-hidden overflow-x-auto  ${state === 1 ? "hidden" : ""}`}
                      >
                        <button
                          type="button"
                          className="float-left inline-flex sm:w-[110px] w-[150px] h-[63] ml-[1px] flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 !text-[10px]"
                          onClick={async () => {
                            if (window.confirm("Desea anular esta venta?")) {
                              let cancelSale = await axiosAuth.put("/cancelSale", {
                                id: saleId,
                              });
                              let newSales = [...sales];
                              newSales.map((newSale) => {
                                if (newSale.id == saleId) {
                                  newSale.state = 1;
                                }
                              });
                              setState(1);
                              setSales(newSales);
                              toast.success("Venta anulada correctamente!");
                            }
                          }}
                        >
                          <img src={discard} className="w-6" />
                          ANULAR
                        </button>

                        <button
                          type="button"
                          disabled={action === "edit" ? false : true}
                          readOnly={action === "edit" ? false : true}
                          className="float-left inline-flex sm:w-[110px] w-[150px] h-[63] ml-[1px] flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 !text-[10px]"
                          style={{ alignItems: "center" }}
                          onClick={() => {
                            const configuracionElementos = localStorage.getItem("configuracionElementos");
                            const url = configuracionElementos ? "/imprimirfactura/" + saleId : "/imprimirfactura/" + saleId;
                            if(companyId == 11){
                              window.open("/imprimirfacturaTop/" + saleId);
                            }else if(companyId == 9){
                              window.open("/imprimirfacturasantaana/" + saleId);
                            }else{
                              window.open(url);
                            }
                            
                          }}
                        >
                          <img src={printer} className="w-8" />
                          IMPRIMIR
                        </button>

                        <button
                          type="button"
                          disabled={action === "edit" ? false : true}
                          readOnly={action === "edit" ? false : true}
                          className="float-left inline-flex sm:w-[110px] w-[150px] h-[63] ml-[1px] flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 !text-[10px]"
                          style={{ alignItems: "center" }}
                          onClick={() => {
                            window.open("/facturapdf/" + saleId);
                          }}
                        >
                          <img src={pdf} className="w-8" />
                          PDF
                        </button>

                        <button
                          type="button"
                          disabled={action === "edit" ? false : true}
                          readOnly={action === "edit" ? false : true}
                          className="float-left inline-flex sm:w-[110px] w-[150px] h-[63] ml-[1px] flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 !text-[10px]"
                          style={{ alignItems: "center" }}
                          onClick={() => handleSubmit()}
                        >
                          <img src={floppy} className="w-8" />
                          GUARDAR
                        </button>

                        <button
                          type="button"
                          className="float-left inline-flex sm:w-[110px] w-[150px] h-[63] ml-[1px] flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 !text-[10px]"
                          style={{ alignItems: "center" }}
                          onClick={() => {
                            clearFields();
                            setOpen(false);
                          }}
                        >
                          <img src={door} className="w-6" />
                          SALIR
                        </button>
                      </div>

                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <div
          className="flex flex-row w-[100%] relative sm:top-[-2px]"
          style={{ borderBottom: "1px solid lightgray" }}
        >
          <div className="w-[50%] sm:w-[35%]">
            <h1
              id="titlebig"
              className="ml-5 mt-5 text-3xl title font-family-thin secondary-color"
            >
              Visor de Ventas
            </h1>
            <DateComponent className="sm:block hidden" />{" "}
          </div>

          <div className="flex flex-row justify-end w-full sm:w-[75%] sm:mr-[10%] pt-1">
            <div className="flex flex-row justify-end w-full">
              <div className="flex flex-col items-end w-full relative right-0 sm:right-[-4%] md:right-[-2.5%]">
                <ComboBox
                  data={clients}
                  setData={setClientIdFilter}
                  value={clientIdFilter}
                  placeholder="Cliente"
                  className="h-[25px] mt-[2px] w-[80%] sm:w-[220px] min-w-[80px] mb-2"
                  search={searchClients}
                />
                <input
                  className=" w-[80%] sm:w-[220px] min-w-[80px] appearance-none border text-gray-700 leading-tight capitalize h-[25px] p-[6px] mr-[1px] mt-1"
                  id="billNumber"
                  value={billNumber}
                  type="text"
                  placeholder="Nro. Factura"
                  onChange={(e) => {
                    setBillNumber(e.target.value);
                  }}
                />
              </div>
              <div className="flex flex-col items-end w-[200px]">
                <input
                  className=" appearance-none border text-gray-700 leading-tight capitalize h-[25px] p-[6px] mr-[1px] mt-1"
                  id="date"
                  value={dateFromFilter}
                  type="date"
                  onChange={(e) => {
                    setDateFromFilter(e.target.value);
                  }}
                />

                <input
                  className=" appearance-none border text-gray-700 leading-tight capitalize h-[25px] p-[6px] mt-1"
                  id="date"
                  value={dateToFilter}
                  type="date"
                  onChange={(e) => {
                    setDateToFilter(e.target.value);
                  }}
                // onBlur={() => fetchSales()}
                />
              </div>

              <button
                type="button"
                className="float-left inline-flex w-[65px] h-[63] m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 !text-[10px]"
                style={{ alignItems: "center" }}
                onClick={() => {
                  fetchSales();
                }}
              >
                <img src={search} className="w-8" />
                CONSULTAR
              </button>

              <button
                type="button"
                className="float-left inline-flex sm:w-[110px] w-[150px] h-[63] ml-[1px] flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 !text-[10px]"
                style={{ alignItems: "center" }}
                onClick={() => {
                  navigate("/");
                }}
              >
                <img src={door} className="w-6" />
                SALIR
              </button>
            </div>
          </div>
        </div>

        {/* <button
          className="rounded-md bg-white px-2 py-1 font-semibold float-right relative right-10 bottom-10 cursor-pointer title-button"
          onClick={() => {
            setAction("create");

            let newItems = [];

            Array(5)
              .fill(0)
              .map((_, i) => {
                newItems.push({
                  id: 0,
                  qty: 0,
                  name: "",
                  price: 0,
                  subtotal: 0,
                  iva: "",
                });
              });

            setItems(newItems);

            setOpen(true);
            filterPaymentMethods(saleConditionId);
          }}
        >
          Crear venta
        </button> */}

        <div className="w-[97%] max-h-[70vh] mx-auto overflow-x-auto">
          <table className="w-[97%] table-auto mx-auto w-full relative support-table">
            <thead>
              <tr className="bg-gray-500 h-8 !text-white">
                {width > 600 ? (
                  <>
                    <th className="!text-white">Nro. Fact.</th>
                    <th className="!text-white">Fecha</th>
                    <th className="!text-white w-[300px]">Cliente</th>
                    <th className="!text-white text-left">Vendedor</th>
                    <th className="!text-white text-left">Condicion</th>
                    <th className="!text-white text-left">Moneda</th>
                    <th className="!text-white text-right">IVA5</th>
                    <th className="!text-white text-right">GRAV5</th>
                    <th className="!text-white text-right">IVA10</th>
                    <th className="!text-white text-right">GRAV10</th>
                    <th className="!text-white text-right">Exento</th>
                    <th className="!text-white text-right">Total</th>
                    <th className="!text-white">Accion</th>
                  </>
                ) : (
                  <></>
                )}
              </tr>
            </thead>
            <tbody>
              {isFetching && (
                <tr>
                  <td className="text-center" colSpan={20}>
                    <Lottie
                      animationData={loadingAnimation}
                      style={{ width: 100, height: 100, margin: "0 auto" }}
                      loop={true}
                    />
                  </td>
                </tr>
              )}
              {sales.length > 0 &&
                !isFetching &&
                sales?.map((sale, i) => {
                  let saleTotal = 0;

                  sale.salesdetails.map((detail) => {
                    saleTotal += detail.price * detail.quantity;
                  });

                  if (width > 600) {
                    return (
                      // <SwipeToRevealActions
                      //   actionButtons={[
                      //     {
                      //       content: (
                      //         <div className="primary-color-bg">
                      //           <span>EDIT</span>
                      //         </div>
                      //       ),
                      //       onClick: () => alert("Pressed the EDIT button"),
                      //     },
                      //     {
                      //       content: (
                      //         <div className="primary-color-bg">
                      //           <span>DELETE</span>
                      //         </div>
                      //       ),
                      //       onClick: () => alert("Pressed the DELETE button"),
                      //     },
                      //   ]}
                      //   actionButtonMinWidth={50}
                      //   height={20}
                      //   className="w-full"
                      // >
                      //   <div
                      //     key={sale.id}
                      //     className={`tr !w-full !text-[10px] ${
                      //       sale.state == 1 ? "!bg-red-200" : ""
                      //     }`}
                      //   >
                      //     {/* <td>{sale.id}</td> */}
                      //     <div className="w-[5%] td text-center uppercase">
                      //       {sale.expeditionPoint + " " + sale.number}
                      //     </div>
                      //     <div className="w-[6.3%] ">
                      //       {sale.updatedAt
                      //         .substr(0, 10)
                      //         .split("-")
                      //         .reverse()
                      //         .join("/")}
                      //     </div>
                      //     <div className="w-[400%] text-left block uppercase whitespace-nowrap overflow-hidden ml-2">
                      //       {sale.state != 1 ? sale.client.name : "ANULADO"}
                      //     </div>
                      //     <div className="w-[15%] td text-left uppercase whitespace-nowrap">
                      //       {sale.state != 1 ? sale.seller.fullname : "ANULADO"}
                      //     </div>
                      //     <div className="w-[6.3%] td text-center uppercase">
                      //       {sale.state != 1
                      //         ? sale?.salecondition.description
                      //         : "ANULADO"}
                      //     </div>
                      //     {/* <div className="w-[6.3%] td text-center uppercase whitespace-nowrap">
                      //       {sale.state != 1 ? sale.box.name : "ANULADO"}
                      //     </div> */}
                      //     <div className="w-[6.3%] td text-center uppercase">
                      //       {sale.state != 1 ? sale.currency.name : "ANULADO"}
                      //     </div>
                      //     {/* <div className="w-[6.3%] td text-center uppercase">
                      //       {sale.state != 1
                      //         ? sale.branch.description
                      //         : "ANULADO"}
                      //     </div> */}
                      //     <div className="w-[6.3%] td text-right">
                      //       {sale.state != 1 ? numberWithCommas(saleTotal) : 0}
                      //     </div>
                      //     <div className="w-[6.3%] td text-right">
                      //       {sale.state != 1 ? numberWithCommas(sale.vat5) : 0}
                      //     </div>
                      //     <div className="w-[6.3%] td text-right">
                      //       {sale.state != 1 ? numberWithCommas(sale.taxed5) : 0}
                      //     </div>
                      //     <div className="w-[6.3%] td text-right">
                      //       {sale.state != 1 ? numberWithCommas(sale.vat10) : 0}
                      //     </div>
                      //     <div className="w-[6.3%] td text-right">
                      //       {sale.state != 1 ? numberWithCommas(sale.taxed10) : 0}
                      //     </div>
                      //     <div className="w-[6.3%] td text-right">
                      //       {sale.state != 1 ? numberWithCommas(sale.exempt) : 0}
                      //     </div>

                      //     <div className="w-[6.3%]">
                      //       {sale.state == 1 ? "ANULADO" : "ACTIVO"}
                      //     </div>

                      //     <div className="w-[6.3%] text-center">
                      //       <button
                      //         onClick={() => {
                      //           editSale(sale.id);
                      //         }}
                      //         className="bg-transparent"
                      //       >
                      //         <FontAwesomeIcon
                      //           className="m-1 cursor-pointer secondary-color"
                      //           icon={faEye}
                      //         />
                      //       </button>
                      //     </div>
                      //   </div>
                      // </SwipeToRevealActions>

                      <tr
                        key={sale.id}
                        className={`!text-[10px] ${sale.state == 1 ? "!bg-red-200" : ""
                          }`}
                      >
                        {/* <td>{sale.id}</td> */}
                        <td className="text-center uppercase">
                          {sale.expeditionPoint + " " + sale.number}
                        </td>
                        <td>
                          {sale.updatedAt
                            .substr(0, 10)
                            .split("-")
                            .reverse()
                            .join("/")}
                        </td>
                        <td className="text-left">
                          {sale.state != 1 ? sale.client.name : "ANULADO"}
                        </td>
                        <td className="text-left">
                          {sale.state != 1 ? sale.seller.fullname : "ANULADO"}
                        </td>
                        <td className="text-left">
                          {sale.state != 1
                            ? sale?.salecondition.description
                            : "ANULADO"}
                        </td>
                        {/* <div className="w-[6.3%] td text-center uppercase whitespace-nowrap">
                          {sale.state != 1 ? sale.box.name : "ANULADO"}
                        </div> */}
                        <td>
                          {sale.state != 1 ? sale.currency.name : "ANULADO"}
                        </td>
                        {/* <div className="w-[6.3%] td text-center uppercase">
                          {sale.state != 1
                            ? sale.branch.description
                            : "ANULADO"}
                        </div> */}

                        <td className="text-right">
                          {sale.state != 1 ? numberWithCommas(sale.vat5) : 0}
                        </td>
                        <td className="text-right">
                          {sale.state != 1 ? numberWithCommas(sale.taxed5) : 0}
                        </td>
                        <td className="text-right">
                          {sale.state != 1 ? numberWithCommas(sale.vat10) : 0}
                        </td>
                        <td className="text-right">
                          {sale.state != 1 ? numberWithCommas(sale.taxed10) : 0}
                        </td>
                        <td className="text-right">
                          {sale.state != 1 ? numberWithCommas(sale.exempt) : 0}
                        </td>
                        <td className="text-right font-bold">
                          {sale.state != 1 ? numberWithCommas(saleTotal) : 0}
                        </td>
                        {/* <td>{sale.state == 1 ? "ANULADO" : "ACTIVO"}</td> */}

                        <td className="text-center">
                          <button
                            onClick={() => {
                              editSale(sale.id);
                            }}
                            className="bg-transparent"
                          >
                            <FontAwesomeIcon
                              className="m-1 cursor-pointer secondary-color"
                              icon={faEye}
                            />
                          </button>
                        </td>
                      </tr>
                    );
                  } else {
                    return (
                      // <SwipeToRevealActions
                      //   actionButtons={[
                      //     {
                      //       content: (
                      //         <div className="primary-color-bg">
                      //           <span>EDIT</span>
                      //         </div>
                      //       ),
                      //       onClick: () => alert("Pressed the EDIT button"),
                      //     },
                      //     {
                      //       content: (
                      //         <div className="primary-color-bg">
                      //           <span>DELETE</span>
                      //         </div>
                      //       ),
                      //       onClick: () => alert("Pressed the DELETE button"),
                      //     },
                      //   ]}
                      //   actionButtonMinWidth={50}
                      //   height={20}
                      //   className="w-full"
                      // >
                      //   <div
                      //     key={sale.id}
                      //     className={`tr !w-full !text-[10px] ${
                      //       sale.state == 1 ? "!bg-red-200" : ""
                      //     }`}
                      //   >
                      //     {/* <td>{sale.id}</td> */}
                      //     <div className="w-[5%] td text-center uppercase">
                      //       {sale.expeditionPoint + " " + sale.number}
                      //     </div>
                      //     <div className="w-[6.3%] ">
                      //       {sale.updatedAt
                      //         .substr(0, 10)
                      //         .split("-")
                      //         .reverse()
                      //         .join("/")}
                      //     </div>
                      //     <div className="w-[400%] text-left block uppercase whitespace-nowrap overflow-hidden ml-2">
                      //       {sale.state != 1 ? sale.client.name : "ANULADO"}
                      //     </div>
                      //     <div className="w-[15%] td text-left uppercase whitespace-nowrap">
                      //       {sale.state != 1 ? sale.seller.fullname : "ANULADO"}
                      //     </div>
                      //     <div className="w-[6.3%] td text-center uppercase">
                      //       {sale.state != 1
                      //         ? sale?.salecondition.description
                      //         : "ANULADO"}
                      //     </div>
                      //     {/* <div className="w-[6.3%] td text-center uppercase whitespace-nowrap">
                      //       {sale.state != 1 ? sale.box.name : "ANULADO"}
                      //     </div> */}
                      //     <div className="w-[6.3%] td text-center uppercase">
                      //       {sale.state != 1 ? sale.currency.name : "ANULADO"}
                      //     </div>
                      //     {/* <div className="w-[6.3%] td text-center uppercase">
                      //       {sale.state != 1
                      //         ? sale.branch.description
                      //         : "ANULADO"}
                      //     </div> */}
                      //     <div className="w-[6.3%] td text-right">
                      //       {sale.state != 1 ? numberWithCommas(saleTotal) : 0}
                      //     </div>
                      //     <div className="w-[6.3%] td text-right">
                      //       {sale.state != 1 ? numberWithCommas(sale.vat5) : 0}
                      //     </div>
                      //     <div className="w-[6.3%] td text-right">
                      //       {sale.state != 1 ? numberWithCommas(sale.taxed5) : 0}
                      //     </div>
                      //     <div className="w-[6.3%] td text-right">
                      //       {sale.state != 1 ? numberWithCommas(sale.vat10) : 0}
                      //     </div>
                      //     <div className="w-[6.3%] td text-right">
                      //       {sale.state != 1 ? numberWithCommas(sale.taxed10) : 0}
                      //     </div>
                      //     <div className="w-[6.3%] td text-right">
                      //       {sale.state != 1 ? numberWithCommas(sale.exempt) : 0}
                      //     </div>

                      //     <div className="w-[6.3%]">
                      //       {sale.state == 1 ? "ANULADO" : "ACTIVO"}
                      //     </div>

                      //     <div className="w-[6.3%] text-center">
                      //       <button
                      //         onClick={() => {
                      //           editSale(sale.id);
                      //         }}
                      //         className="bg-transparent"
                      //       >
                      //         <FontAwesomeIcon
                      //           className="m-1 cursor-pointer secondary-color"
                      //           icon={faEye}
                      //         />
                      //       </button>
                      //     </div>
                      //   </div>
                      // </SwipeToRevealActions>

                      <tr
                        key={sale.id}
                        style={{ borderBottom: "1px solid gray" }}
                        className={`!text-[10px] ${sale.state == 1 ? "!bg-red-200" : ""
                          }`}
                      >
                        <td className="text-left" colSpan={3}>
                          <b>NRO. FACT. : </b>{" "}
                          {sale.expeditionPoint + " " + sale.number}
                          <br />
                          <b>FECHA ALTA : </b>{" "}
                          {sale.updatedAt
                            .substr(0, 10)
                            .split("-")
                            .reverse()
                            .join("/")}
                          <br />
                          <b>CLIENTE : </b>
                          {sale.state != 1 ? sale.client.name : "ANULADO"}
                          <br />
                          <b>VENDEDOR : </b>
                          {sale.state != 1 ? sale.seller.fullname : "ANULADO"}
                          <br />
                          <b>CONDICION : </b>
                          {sale.state != 1
                            ? sale?.salecondition.description
                            : "ANULADO"}
                          <br />
                          <b>MONEDA : </b>
                          {sale.state != 1 ? sale.currency.name : "ANULADO"}
                          <b>IVA5 : </b>
                          {sale.state != 1 ? numberWithCommas(sale.vat5) : 0}
                          <br />
                          <b>GRAV5 : </b>
                          {sale.state != 1 ? numberWithCommas(sale.taxed5) : 0}
                          <br />
                          <b>IVA10 : </b>
                          {sale.state != 1 ? numberWithCommas(sale.vat10) : 0}
                          <br />
                          <b>GRAV10 : </b>
                          {sale.state != 1 ? numberWithCommas(sale.taxed10) : 0}
                          <br />
                          <b>EXENTO : </b>
                          {sale.state != 1 ? numberWithCommas(sale.exempt) : 0}
                          <br />
                          <b>TOTAL : </b>
                          {sale.state != 1 ? numberWithCommas(saleTotal) : 0}
                          <br />
                          <br />
                          <button
                            onClick={() => {
                              editSale(sale.id);
                            }}
                            className="bg-transparent"
                          >
                            <FontAwesomeIcon
                              className="m-1 cursor-pointer secondary-color"
                              icon={faEye}
                            />
                          </button>
                        </td>
                      </tr>
                    );
                  }
                })}

              {sales.length <= 0 && !isFetching && (
                <tr>
                  <td colSpan={20} className="text-center">
                    Sin registros (0)
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {/* <Pagination path="ventas" page={page} pages={pages} total={total} /> */}
        </div>
        {/* <table className="m-auto !w-[92.5%] mt-7">
          <thead>
            <tr className="text-xs" style={{ color: "transparent !important" }}>
              <th>Total</th>
              <th>IVA5</th>
              <th>GRAV5</th>
              <th>IVA10</th>
              <th>GRAV10</th>
              <th>Exento</th>
            </tr>
          </thead>
          <tbody>
            <tr className={`text-[12px]`}>
              <td className="text-center">{numberWithCommas(finalTotal)}</td>
              <td className="text-center">{numberWithCommas(finalVat5)}</td>
              <td className="text-center">{numberWithCommas(finalTaxed5)}</td>
              <td className="text-center">{numberWithCommas(finalVat10)}</td>
              <td className="text-center">{numberWithCommas(finalTaxed10)}</td>
              <td className="text-center">{numberWithCommas(finalExempt)}</td>
            </tr>
          </tbody>
        </table> */}
        {/* <table className="m-auto !w-[92.5%] mt-7">
          <thead>
            <tr className="text-xs" style={{ color: "transparent !important" }}>
              <th>Total</th>
              <th>IVA5</th>
              <th>GRAV5</th>
              <th>IVA10</th>
              <th>GRAV10</th>
              <th>Exento</th>
            </tr>
          </thead>
          <tbody>
            <tr className={`text-[12px]`}>
              <td className="text-center">{numberWithCommas(finalTotal)}</td>
              <td className="text-center">{numberWithCommas(finalVat5)}</td>
              <td className="text-center">{numberWithCommas(finalTaxed5)}</td>
              <td className="text-center">{numberWithCommas(finalVat10)}</td>
              <td className="text-center">{numberWithCommas(finalTaxed10)}</td>
              <td className="text-center">{numberWithCommas(finalExempt)}</td>
            </tr>
          </tbody>
        </table> */}
      </Layout>
    </>
  );
};

export default Sales;
