import React, { useState, Fragment, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Dialog, Transition } from "@headlessui/react";
import Layout from "../layout";
import { axiosAuth } from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser } from "../store/slices/mainSlice";
import { useLocation, useParams } from "react-router-dom";
import Pagination from "../components/pagination";
import DateComponent from "../components/date";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/animations/loading.json";
import ComboBox from "../components/combobox";
import stockimg from "../assets/img/stock.webp";
import door from "../assets/img/door.png";
import floppy from "../assets/img/floppy.png";

const Stock = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [action, setAction] = useState("edit");

  const { page } = useParams();
  const user = useSelector((state) => state.main.user);

  const [stocks, setStocks] = useState([]);
  const [deposits, setDeposits] = useState([]);
  const [items, setItems] = useState([]);

  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);

  const [stockId, setStockId] = useState(0);
  const [existence, setExistence] = useState(0);
  const [depositId, setDepositId] = useState(0);
  const [itemId, setItemId] = useState(0);
  const [deleted, setDeleted] = useState(0);

  async function fetchStocks() {
    let pageQuery = page === undefined ? 1 : page;

    setIsFetching(true);

    let stocks = await axiosAuth.get("/stocks?page=" + pageQuery);

    setIsFetching(false);

    if (stocks.data.error) {
      if (stocks.data.message === "token") {
        await axiosAuth.post("/logout");
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    setStocks(stocks.data.stock.rows);
    setTotal(stocks.data.stock.count);
    setPages(stocks.data.stock.pages);
    setItems(stocks.data.items.rows);
    setDeposits(stocks.data.deposits);
  }

  useEffect(() => {
    fetchStocks();
  }, []);

  useEffect(() => {
    fetchStocks();
  }, [location]);

  const editStock = (stockId) => {
    setAction("edit");
    setStockId(stockId);

    let stock = stocks.filter((stock) => stock.id === stockId)[0];

    setItemId(stock.itemId);
    setDepositId(stock.depositId);
    setExistence(stock.existence);

    setDeleted(stock.deleted);

    setOpen(true);
  };

  const clearFields = () => {
    setStockId(0);
    setDepositId(0);
    setItemId(0);
    setExistence(0);
    setDeleted(0);
  };

  const handleSubmit = async () => {
    if (existence === "" || existence === 0 || depositId == 0 || itemId == 0) {
      alert("Debes completar los campos para continuar.");
      return false;
    }

    if (action === "edit") {
      let id = stockId;
      let newStocksObj = [];

      let updateStockRequest = await axiosAuth.put("/stocks", {
        id,
        depositId,
        itemId,
        existence,
        deleted,
      });

      if (updateStockRequest.data.error) {
        if (updateStockRequest.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      stocks.map((stock) => {
        if (stock.id == id) {
          newStocksObj.push(updateStockRequest.data);
        } else {
          newStocksObj.push(stock);
        }
        return "";
      });

      setStocks(newStocksObj);
    } else {
      let createStockRequest = await axiosAuth.post("/stocks", {
        depositId,
        itemId,
        existence,
        deleted,
      });

      if (createStockRequest.data.error) {
        if (createStockRequest.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }

        if (createStockRequest.data.message === "exists") {
          alert(
            "El articulo ya cuenta con registro de stock en este deposito."
          );
          return false;
        }
      }

      let newStocks = [...stocks];
      newStocks.push(createStockRequest.data);
      setStocks(newStocks);
    }

    setOpen(false);
    clearFields();
  };

  const deleteStock = async (stockId) => {
    if (window.confirm("Desea eliminar este stock ?")) {
      let StockDeleteRequest = await axiosAuth.delete("/stocks", {
        params: { id: stockId },
      });

      if (StockDeleteRequest.data.error) {
        if (StockDeleteRequest.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      let newStocksObj = [];

      stocks.map((stock) => {
        if (stock.id !== stockId) {
          newStocksObj.push(stock);
        } else {
          if (user.roleId == 1) {
            stock.deleted = 1;
            newStocksObj.push(stock);
          }
        }
        return "";
      });

      setStocks(newStocksObj);
    }
  };

  const searchItems = async (search) => {
    const searchItemsRequest = await axiosAuth.get(
      "/searchItems?search=" + search
    );

    setItems(searchItemsRequest.data.items.rows);
  };

  return (
    <>
      <Layout>
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              clearFields();
              setOpen(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto align-items-center justify-center items-center flex">
              <div className="flex justify-center p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel
                    className="relative transform overflow-hidden rounded-3xl bg-white text-left shadow-xl transition-all sm:my-8 w-[280px]"
                    style={{ marginTop: 35 }}
                  >
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-0">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                          <Dialog.Title
                            as="h1"
                            className="text-center font-regular leading-6 primary-color text-3xl"
                          >
                            {action === "edit" ? "Editar" : "Ajustar"} stock
                          </Dialog.Title>
                          <div className="mt-5">
                            <div className="w-full max-w-xs">
                              <form className="">
                                {action === "edit" && (
                                  <div className="mb-4">
                                    <label
                                      className="block text-gray-700 text-sm font-bold"
                                      htmlFor="StockId"
                                    >
                                      Id
                                    </label>
                                    <input
                                      readOnly
                                      className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      id="StockId"
                                      type="text"
                                      value={stockId}
                                    />
                                  </div>
                                )}

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="companyId"
                                  >
                                    Articulo
                                  </label>
                                  <ComboBox
                                    data={items}
                                    setData={setItemId}
                                    value={itemId}
                                    className="h-[34px]"
                                    search={searchItems}
                                  />
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="companyId"
                                  >
                                    Deposito
                                  </label>
                                  <select
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="depositId"
                                    value={depositId}
                                    onChange={(e) =>
                                      setDepositId(e.target.value)
                                    }
                                  >
                                    <option key={0} value={0}>
                                      Elija un deposito
                                    </option>
                                    {deposits?.map((deposit) => (
                                      <option
                                        key={deposit.id}
                                        value={deposit.id}
                                      >
                                        {deposit.description}
                                      </option>
                                    ))}
                                  </select>
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="existence"
                                  >
                                    Existencia
                                  </label>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                    id="existence"
                                    type="text"
                                    value={existence}
                                    onChange={(e) =>
                                      setExistence(e.target.value)
                                    }
                                  />
                                </div>

                                {user.roleId == 1 && (
                                  <div className="mb-4">
                                    <label
                                      className="block text-gray-700 text-sm font-bold"
                                      htmlFor="deleted"
                                    >
                                      Borrado
                                    </label>
                                    <select
                                      className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      id="deleted"
                                      value={deleted}
                                      onChange={(e) =>
                                        setDeleted(parseInt(e.target.value))
                                      }
                                    >
                                      <option value={0}>No</option>
                                      <option value={1}>Si</option>
                                    </select>
                                  </div>
                                )}
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 justify-center flex sm:px-6">
                      
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => handleSubmit()}
                      >
                        <img src={floppy} className="w-8" />
                        GUARDAR
                      </button>
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => {
                          clearFields();
                          setOpen(false);
                        }}
                      >
                        <img src={door} className="w-5" />
                        CERRAR
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <h1 className="ml-5 mt-5 text-3xl secondary-color title font-family-thin">
          Stock
        </h1>
        <DateComponent className="sm:block hidden" />

        <button
          type="button"
          className="relative right-[25px] sm:right-[50px] bottom-[10px] inline-flex w-[78px] float-right m-1 mb-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
          style={{ alignItems: "center" }}
          onClick={() => {
            setAction("create");
            setOpen(true);
          }}
        >
          <img src={stockimg} className="w-16" />
          AJUSTAR STOCK
        </button>

        <div className="w-[95%] p-5 mx-auto overflow-auto">
          <table className="w-[95%] table-auto mx-auto w-full text-xs">
            <thead>
              <tr>
                <th className="uppercase">Id</th>
                <th className="uppercase">Deposito</th>
                <th className="uppercase">Producto</th>
                <th className="uppercase">Existencia</th>
                <th className="uppercase">Fecha Alta</th>
              </tr>
            </thead>
            <tbody>
              {isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={8}>
                    <Lottie
                      animationData={loadingAnimation}
                      style={{ width: 100, height: 100, margin: "0 auto" }}
                      loop={true}
                    />
                  </td>
                </tr>
              )}
              {stocks.length > 0 &&
                !isFetching &&
                stocks?.map((stock) => {
                  return (
                    <tr key={stock.id}>
                      <td>{stock.id}</td>
                      <td>{stock.deposit.description}</td>
                      <td>{stock.item.name}</td>
                      <td>{stock.existence}</td>
                      <td>
                        {stock.createdAt
                          .substr(0, 10)
                          .split("-")
                          .reverse()
                          .join("/")}
                      </td>
                      <td className="text-center">
                        <button
                          className="bg-transparent"
                          onClick={() => {
                            editStock(stock.id);
                          }}
                        >
                          <FontAwesomeIcon
                            className="m-1 cursor-pointer secondary-color"
                            icon={faPen}
                          />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              {stocks.length <= 0 && !isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={8} className="text-center">
                    Sin registros (0)
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <Pagination path="stock" page={page} pages={pages} total={total} />
        </div>
      </Layout>
    </>
  );
};

export default Stock;
