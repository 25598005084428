import React, { useState, Fragment, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faTrash,
  faPlus,
  faL,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { Dialog, Transition, Tab } from "@headlessui/react";
import Layout from "../layout";
import { axiosAuth } from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser } from "../store/slices/mainSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Pagination from "../components/pagination";
import DateComponent from "../components/date";
import ComboBox from "../components/combobox";
import useViewport from "../utils/useViewport";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/animations/loading.json";
import toast from "react-hot-toast";
import { numberWithCommas } from "../utils/constants";
import axios from "axios";
import door from "../assets/img/door.png";
import search from "../assets/img/search.webp";
import floppy from "../assets/img/floppy.png";

import cancel from "../assets/img/forbidden.png";

const Purchases = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [action, setAction] = useState("edit");

  const { width } = useViewport();

  const { page } = useParams();
  const user = useSelector((state) => state.main.user);

  const [providers, setProviders] = useState([]);
  const [provider, setProvider] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [saleConditions, setSaleConditions] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [filteredPaymentMethods, setFilteredPaymentMethods] = useState([]);
  const [filteredPaymentMethodsCompleted, setFilteredPaymentMethodsCompleted] =
    useState(false);
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState([]);
  const [purchases, setPurchases] = useState([]);
  const [items, setItems] = useState([]);
  const [articles, setArticles] = useState([]);
  const [deposits, setDeposits] = useState([]);
  const [billNumber, setBillNumber] = useState("");
  const [number, setNumber] = useState(null);
  const [expeditionPoint, setExpeditionPoint] = useState(null);
  const [stamped, setStamped] = useState(null);

  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [state, setState] = useState(0);
  const [totalAmount, setTotalAmount] = useState();

  const [purchaseId, setPurchaseId] = useState(0);
  const [providerId, setProviderId] = useState(0);
  const [currencyId, setCurrencyId] = useState(1);
  const [sellerId, setSellerId] = useState(user.id);
  const [companyId, setCompanyId] = useState(user.companyId);
  const [branchId, setBranchId] = useState(user.branchId);
  const [depositId, setDepositId] = useState(0);
  const [boxId, setBoxId] = useState(user.boxId);
  const [conditionId, setConditionId] = useState(1);
  const [cotizacion, setCotizacion] = useState(1);
  const [paymentMethodId, setPaymentMethodId] = useState(0);
  const [providerIdFilter, setproviderIdFilter] = useState(0);
  const [internalMovement, setInternalMovement] = useState(0);

  const [date, setDate] = useState(
    new Date().toLocaleDateString("en-GB").split("/").reverse().join("-")
  );


  const [dateFromFilter, setDateFromFilter] = useState(() => {
    let MyDateString =
      (
        "0" +
        new Date(new Date().getFullYear(), new Date().getMonth(), 1).getDate()
      ).slice(-2) +
      "/" +
      (
        "0" +
        (new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          1
        ).getMonth() +
          1)
      ).slice(-2) +
      "/" +
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1
      ).getFullYear();

    return MyDateString.replaceAll(".", "")
      .replaceAll("/", "-")
      .split("-")
      .reverse()
      .join("-");
  });
  const [dateToFilter, setDateToFilter] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
      .toLocaleDateString()
      .replaceAll("/", "-")
      .split("-")
      .map((d) => (String(d).length == 1 ? "0" + String(d) : d))
      .reverse()
      .join("-")
  );
  const [deleted, setDeleted] = useState(0);

  async function fetchPurchases() {
    let pageQuery = page === undefined ? 1 : page;
    setIsFetching(true);

    let purchasesRequest = await axiosAuth.get(
      "/purchases?page=" +
      pageQuery +
      "&providerId=" +
      providerIdFilter +
      "&billNumber=" +
      billNumber +
      "&companyId=" +
      user.companyId +
      "&branchId=" +
      user.branchId
    );

    setIsFetching(false);

    if (purchasesRequest.data.error) {
      if (purchasesRequest.data.message === "token") {
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    // purchasesRequest.data.purchases.clients.unshift({
    //   id: 0,
    //   name: "Elija un cliente",
    // });

    setProviders(purchasesRequest.data.providers.rows);
    setCurrencies(purchasesRequest.data.currencies);
    setArticles(purchasesRequest.data.items.rows);

    setSaleConditions(purchasesRequest.data.saleconditions);
    setPaymentMethods(purchasesRequest.data.paymentmethods);
    setDeposits(purchasesRequest.data.deposits);

    // let newFilteredPaymentMethods = [];
    // purchasesRequest.data.purchases.paymentmethods.map((paymentMethod) => {
    //   if (
    //     paymentMethod.purchaseConditionId ===
    //     purchasesRequest.data.purchases.purchaseconditions[0].id
    //   ) {
    //     paymentMethod.value = 0;
    //     newFilteredPaymentMethods.push(paymentMethod);
    //   }
    // });
    // setFilteredPaymentMethods(newFilteredPaymentMethods);

    setPurchases(purchasesRequest.data.purchases.rows);
    setTotal(purchasesRequest.data.purchases.count);
    setPages(purchasesRequest.data.purchases.pages);
  }

  useEffect(() => {
    fetchPurchases();
  }, []);

  useEffect(() => {
    fetchPurchases();
  }, [location]);

  useEffect(() => {
    setProvider(providers.filter((provider) => provider.id === providerId)[0]);
    setStamped(provider?.stamped?.stamped);
  }, [providerId]);

  useEffect(() => {
    filterPaymentMethods(conditionId);
  }, [conditionId]);

  useEffect(() => {
    let totalAmountSum = 0;

    items.map((item) => {
      totalAmountSum +=
        item.qty * Number(String(item?.cost || 0).replaceAll(",", ""));
    });

    setTotalAmount(numberWithCommas(totalAmountSum));

    let isFull = true;

    items.map((item) => {
      if (item.name == "") {
        isFull = false;
      }
    });

    let newItems = [...items];

    if (isFull) {
      newItems.push({
        id: 0,
        qty: 0,
        name: "",
        price: 0,
        subtotal: 0,
        iva: "",
      });

      setItems(newItems);
    }
  }, [items]);

  useEffect(() => {
    filteredPaymentMethods.map((filteredPaymentMethod) => {
      if (filteredPaymentMethod.amount > 0) {
        setFilteredPaymentMethodsCompleted(true);
      }
    });
  }, [filteredPaymentMethods]);

  const filterPaymentMethods = (conditionId) => {
    let newFilteredPaymentMethods = [];

    paymentMethods.map((paymentMethod) => {
      if (paymentMethod.saleConditionId == conditionId) {
        paymentMethod.amount = null;
        newFilteredPaymentMethods.push(paymentMethod);
      }
    });

    setFilteredPaymentMethods(newFilteredPaymentMethods);
  };
  const searchClients = async (search) => {
    const searchClientsRequest = await axiosAuth.get(
      "/searchProviders?search=" + search
    );
    console.log(searchClientsRequest);
    setClients(searchClientsRequest.data.providers.rows);
  };

  const editPurchase = async (purchaseId) => {
    setAction("edit");
    setPurchaseId(purchaseId);

    let purchase = purchases.filter(
      (purchase) => purchase.id === purchaseId
    )[0];

    setState(purchase.state);

    let filteredProvider = providers.filter(
      (provider) => provider.id == purchase.providerId
    )[0];

    if (filteredProvider == undefined) {
      let providerRequest = await axiosAuth.get(
        "/searchProviderById?providerId=" + purchase.providerId
      );
      let newProviders = [...providers];
      newProviders.push(providerRequest.data.provider);
      setProviders(newProviders);
      setProvider(providerRequest.data.provider);
    } else {
      setProvider(filteredProvider);
    }

    setProviderId(purchase.providerId);
    setCurrencyId(purchase.currencyId);
    setConditionId(purchase.conditionId);
    setDepositId(purchase.depositId);
    filterPaymentMethods(purchase.conditionId);

    setNumber(purchase.number);
    setExpeditionPoint(purchase.expeditionPoint);
    setCotizacion(purchase.cotizacion);
    setStamped(purchase.box.stamped);
    setInternalMovement(purchase.internalMovement == 1 ? true : false);
    paymentMethods.map((paymentMethod) => {
      if (paymentMethod.saleConditionId == conditionId) {
        paymentMethod.amount = null;

        purchase?.payments?.map((payment) => {
          if (payment.paymentMethodId == paymentMethod.id) {
            paymentMethod.amount = payment.amount;
          }
        });
      }
    });

    setDate(purchase.createdAt.substr(0, 10));

    let newItems = [];
    let newArticles = [...articles];

    purchase.purchasesdetails.map(async (purchasedetail) => {
      let productExists = false;

      articles.map((itm) => {
        if (purchasedetail.itemId == itm.id) {
          productExists = true;
        } else {
          productExists = false;
        }
      });

      if (!productExists) {
        newArticles.push(purchasedetail.item);
      }

      newItems.push({
        id: purchasedetail.itemId,
        qty: purchasedetail.quantity,
        name: purchasedetail.item.name,
        price: purchasedetail?.price,
        cost: purchasedetail?.cost,
        subtotal: purchasedetail?.cost * purchasedetail.quantity,

      });
    });

    setArticles(newArticles);

    if (5 > purchase.purchasesdetails.length) {
      Array(8 - purchase.purchasesdetails.length)
        .fill(0)
        .map((_, i) => {
          newItems.push({
            id: 0,
            qty: 0,
            name: "",
            price: 0,
            cost: 0,
            subtotal: 0,
            iva: "",
          });
        });
    }

    setItems(newItems);

    setDeleted(purchase.deleted);
    setOpen(true);
  };

  const clearFields = () => {
    setPurchaseId(0);
    setProviderId(0);
    setCurrencyId(1);
    setDepositId(0);
    setConditionId(1);
    let newItems = [];

    Array(8)
      .fill(0)
      .map((_, i) => {
        newItems.push({
          id: 0,
          qty: 0,
          name: "",
          price: 0,
          subtotal: 0,
          iva: "",
        });
      });

    setItems(newItems);

    paymentMethods.map((paymentMethod) => {
      if (paymentMethod.saleConditionId == conditionId) {
        paymentMethod.amount = null;
      }
    });

    setDeleted(0);
  };

  const handleSubmit = async () => {
    if (action == "edit") {
      setOpen(false);
      return false;
    }

    if (
      providerId === 0 ||
      sellerId === 0 ||
      boxId === 0 ||
      currencyId === 0 ||
      branchId === 0 ||
      depositId === 0 ||
      companyId === 0
    ) {
      alert("Debes completar los campos para continuar.");
      return false;
    }

    var hasItems = false;
    var hasPayments = false;
    var itemsToSend = [];
    var paymentsToSend = [];

    items.map((item) => {
      if (item.id > 0) {
        hasItems = true;
        itemsToSend.push(item);
      }
    });

    filteredPaymentMethods.map((paymentMethod) => {
      if (paymentMethod.amount > 0) {
        hasPayments = true;
        paymentsToSend.push(paymentMethod);
      }
    });

    if (!hasItems) {
      alert("Debes agregar articulos para continuar.");
      return false;
    }

    if (!hasPayments) {
      alert("Debes agregar un monto de pago para continuar.");
      return false;
    }

    let createdPurchaseRequest = await axiosAuth.post("/purchases", {
      providerId,
      sellerId,
      boxId,
      currencyId,
      branchId,
      depositId,
      companyId,
      conditionId,
      purchasesdetail: itemsToSend,
      payments: paymentsToSend,
      expeditionPoint,
      createdAt: date,
      number,
      stamped,
      deleted,
    });

    if (createdPurchaseRequest.data.error) {
      if (createdPurchaseRequest.data.message === "token") {
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    let newPurchases = [...purchases];
    newPurchases.push(createdPurchaseRequest.data);
    setPurchases(newPurchases);

    toast.success("Compra creada correctamente!");
    clearFields();
  };

  const deletePurchase = async (purchaseId) => {
    if (window.confirm("Desea eliminar esta compra ?")) {
      let purchaseDeleteRequest = await axiosAuth.delete("/purchases", {
        params: { id: purchaseId },
      });

      if (purchaseDeleteRequest.data.error) {
        if (purchaseDeleteRequest.data.message === "token") {
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      let newPurchasesObj = [];

      purchases.map((purchase) => {
        if (purchase.id !== purchaseId) {
          newPurchasesObj.push(purchase);
        } else {
          if (user.roleId == 1) {
            purchase.deleted = 1;
            newPurchasesObj.push(purchase);
          }
        }
        return "";
      });

      setPurchases(newPurchasesObj);
    }
  };

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };

  return (
    <>
      <Layout>
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10 w-full purchases-modal"
            onClose={() => {
              clearFields();
              setOpen(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto align-items-center justify-center items-center flex">
              <div className="flex justify-center p-4 text-center sm:p-0 w-[95%] h-[90%]">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel
                    className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-full overflow-y-auto"
                    style={{ marginTop: 0 }}
                  >
                    {state == 1 && (
                      <div
                        className="w-full h-full absolute top-[0] left-[0] flex flex-col justify-center items-center"
                        style={{
                          zIndex: 9,
                          backgroundColor: "rgba(0,0,0,0.5)",
                        }}
                      >
                        {" "}
                        <h1
                          className="font-black rotate-[-45deg]"
                          style={{ fontSize: "70px", color: "red" }}
                        >
                          ANULADO
                        </h1>
                      </div>
                    )}

                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-0 text-center">
                      <div className="w-full">
                        <Dialog.Title
                          as="h1"
                          className="text-center font-regular leading-6 primary-color text-3xl"
                        >
                          Visor de Compras
                        </Dialog.Title>
                        <div className="mt-5">
                          <div className="w-full flex flex-col">

                          <div className="flex flex-row items-center justify-center">
                            <label className="whitespace-nowrap" for="internalMovement">Movimiento interno:</label>
                            <input className="m-2" type="checkbox" value={internalMovement} checked={internalMovement} onChange={e => setInternalMovement(!internalMovement)} />
                          </div>
                            {/* {action === "edit" && (
                                  <div className="mb-4">
                                    <label
                                      className="block text-gray-700 text-sm font-bold"
                                      htmlFor="PurchaseId"
                                    >
                                      Id
                                    </label>
                                    <input
                                      readOnly
                                      className="input-bg-color appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      id="PurchaseId"
                                      type="text"
                                      value={purchaseId}
                                    />
                                  </div>
                                )} */}

                            {width > 550 ? (
                              <>
                                <div className="flex flex-row w-full">
                                  <div className="w-[24.8%]">
                                    <div className="mb-[2px]">
                                      <ComboBox
                                        data={providers}
                                        setData={setProviderId}
                                        value={providerId}
                                        placeholder="Proveedor"
                                        className="h-[30px]"
                                        disabled={true}
                                      />
                                    </div>

                                    <div className="mb-[2px]">
                                      <input
                                        className="input-bg-color appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                        id="RUC"
                                        type="text"
                                        disabled
                                        readOnly
                                        value={provider?.ruc || ""}
                                        placeholder="RUC"
                                        onChange={(e) => { }}
                                      />
                                    </div>

                                    <div className="mb-[2px]">
                                      <input
                                        className="input-bg-color appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                        id="address"
                                        type="text"
                                        readOnly
                                        disabled
                                        value={provider?.address || ""}
                                        placeholder="Direccion"
                                        onChange={(e) => { }}
                                      />
                                    </div>

                                    <div>
                                      <input
                                        className="input-bg-color appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                        id="phone"
                                        type="text"
                                        readOnly
                                        disabled
                                        value={provider?.phone || ""}
                                        placeholder="Telefono"
                                        onChange={(e) => { }}
                                      />
                                    </div>
                                  </div>

                                  <div className="w-[24.8%] ml-[2px]">
                                    <div className="mb-[2px] flex flex-row">
                                      <select
                                        className="input-bg-color appearance-none border rounded w-[49.5%] py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[30px]"
                                        id="branch"
                                        readOnly
                                        disabled
                                        value={branchId}
                                        onChange={(e) => {
                                          setBranchId(e.target.value);
                                        }}
                                      >
                                        <option value={0}>Sucursal</option>
                                        <option value={user.branch.id}>
                                          Sucursal: {user.branch.description}
                                        </option>
                                      </select>

                                      <select
                                        className="input-bg-color appearance-none border rounded w-[49.5%] py-1 px-3 text-gray-700 leading-tight focus:outline-none ml-[2px] focus:shadow-outline capitalize h-[30px]"
                                        id="deposit"
                                        readOnly
                                        disabled
                                        value={depositId}
                                        onChange={(e) => {
                                          setDepositId(e.target.value);
                                        }}
                                      >
                                        <option value={0}>
                                          Elija un deposito
                                        </option>
                                        {deposits.map((deposit) => (
                                          <option value={deposit.id}>
                                            {deposit.description}
                                          </option>
                                        ))}
                                      </select>
                                    </div>

                                    <div className="mb-[2px]">
                                      <select
                                        className="input-bg-color appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                        id="branch"
                                        readOnly
                                        disabled
                                        value={currencyId}
                                        onChange={(e) => {
                                          setCurrencyId(e.target.value);
                                        }}
                                      >
                                        <option value={0}>Moneda</option>
                                        {currencies.map((currency, i) => (
                                          <option value={currency.id} key={i}>
                                            Moneda: {currency.name}
                                          </option>
                                        ))}
                                      </select>
                                    </div>

                                    <div className="mb-[2px]">
                                      <input
                                        className="input-bg-color appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-[28px]"
                                        id="date"
                                        value={date}
                                        type="date"
                                        readOnly
                                        disabled
                                        placeholder="Fecha"
                                        onChange={(e) => {
                                          setDate(e.target.value);
                                        }}
                                      />
                                    </div>
                                    <div className="mb-[2px]">
                                      <input
                                        className="input-bg-color appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        id="number"
                                        value={
                                          "Cotización: " + numberWithCommas(cotizacion)
                                        }
                                        readOnly
                                        disabled
                                        type="text"
                                        placeholder="Cotizacion"
                                        onChange={(e) => { }}
                                      />
                                    </div>
                                  </div>

                                  <div className="w-[24.8%] ml-[2px]">
                                    <div className="mb-[2px]">
                                      <input
                                        className="input-bg-color appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        id="number"
                                        value={
                                          expeditionPoint +
                                          "   " +
                                          stamped +
                                          "   " +
                                          number
                                        }
                                        readOnly
                                        disabled
                                        type="text"
                                        placeholder="Numero"
                                        onChange={(e) => { }}
                                      />
                                    </div>

                                    <div className="mb-[2px]">
                                      <select
                                        className="input-bg-color appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                        id="boxId"
                                        value={boxId}
                                        disabled
                                        readOnly
                                        onChange={(e) => {
                                          setBoxId(e.target.value);
                                        }}
                                      >
                                        <option value={0}>Caja</option>
                                        <option value={user.box.id}>
                                          {user.box.name}
                                        </option>
                                      </select>
                                    </div>

                                    <div className="mb-[2px]">
                                      <select
                                        className="input-bg-color appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                        id="conditionId"
                                        value={conditionId}
                                        // disabled={
                                        //   action == "edit" ? true : false
                                        // }
                                        // readOnly={
                                        //   action == "edit" ? true : false
                                        // }
                                        readOnly
                                        disabled
                                        onChange={(e) => {
                                          setConditionId(e.target.value);
                                          filterPaymentMethods(e.target.value);
                                        }}
                                      >
                                        <option value={0}>
                                          Condicion de compra
                                        </option>
                                        {saleConditions.map((saleCondition) => (
                                          <option value={saleCondition.id}>
                                            {saleCondition.description}
                                          </option>
                                        ))}
                                      </select>
                                    </div>

                                    {filteredPaymentMethods.length > 0 ? (
                                      <div>
                                        <input
                                          readOnly
                                          disabled
                                          // readOnly={
                                          //   action == "edit" ? true : false
                                          // }
                                          // disabled={
                                          //   action == "edit" ? true : false
                                          // }
                                          className="input-bg-color appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                          value={numberWithCommas(
                                            filteredPaymentMethods[0]?.amount
                                          )}
                                          placeholder={
                                            filteredPaymentMethods[0]?.name
                                          }
                                          onChange={(e) => {
                                            let newFilteredPaymentMethods = [];
                                            paymentMethods.map(
                                              (paymentMethod) => {
                                                if (
                                                  paymentMethod.id ==
                                                  filteredPaymentMethods[0].id
                                                ) {
                                                  paymentMethod.amount =
                                                    e.target.value;
                                                  newFilteredPaymentMethods.push(
                                                    paymentMethod
                                                  );
                                                } else {
                                                  newFilteredPaymentMethods.push(
                                                    paymentMethod
                                                  );
                                                }
                                              }
                                            );
                                            setFilteredPaymentMethods(
                                              newFilteredPaymentMethods
                                            );
                                          }}
                                        />
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                  </div>

                                  <div className="w-[24.8%] ml-[2px]">
                                    {filteredPaymentMethods.map(
                                      (paymentMethod) =>
                                        paymentMethod.id === 1 ? (
                                          <></>
                                        ) : (
                                          <div className="mb-[2px]">
                                            <input
                                              // readOnly={
                                              //   action == "edit" ? true : false
                                              // }
                                              // disabled={
                                              //   action == "edit" ? true : false
                                              // }
                                              readOnly
                                              disabled
                                              className="input-bg-color appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                              value={numberWithCommas(
                                                paymentMethod.amount
                                              )}
                                              type="text"
                                              placeholder={paymentMethod.name}
                                              onChange={(e) => {
                                                let newFilteredPaymentMethods =
                                                  [];
                                                paymentMethods.map(
                                                  (paymentMethodMap) => {
                                                    if (
                                                      paymentMethodMap.id ==
                                                      paymentMethod.id
                                                    ) {
                                                      paymentMethodMap.amount =
                                                        e.target.value;
                                                      newFilteredPaymentMethods.push(
                                                        paymentMethodMap
                                                      );
                                                    } else {
                                                      newFilteredPaymentMethods.push(
                                                        paymentMethodMap
                                                      );
                                                    }
                                                  }
                                                );
                                                setFilteredPaymentMethods(
                                                  newFilteredPaymentMethods
                                                );
                                              }}
                                            />
                                          </div>
                                        )
                                    )}
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <Tab.Group>
                                  <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1 mb-[2px]">
                                    <Tab
                                      className={({ selected }) =>
                                        classNames(
                                          "w-full rounded-lg py-1.5 text-sm leading-5 primary-color text-xs",
                                          "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                                          providerId == 0
                                            ? "bg-red-300"
                                            : "bg-green-200",
                                          selected
                                            ? "bg-white font-bold"
                                            : "primary-color hover:text-slate-500"
                                        )
                                      }
                                    >
                                      Proveedor

                                    </Tab>
                                    <Tab
                                      className={({ selected }) =>
                                        classNames(
                                          "w-full rounded-lg py-1.5 text-sm leading-5 primary-color text-xs",
                                          "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                                          branchId == 0 ||
                                            currencyId == 0 ||
                                            date == "" ||
                                            sellerId == 0
                                            ? "bg-red-300"
                                            : "bg-green-200",
                                          selected
                                            ? "bg-white font-bold"
                                            : "primary-color hover:text-slate-500"
                                        )
                                      }
                                    >
                                      Datos
                                    </Tab>
                                    <Tab
                                      onClick={() => {
                                        filterPaymentMethods(conditionId);
                                      }}
                                      className={({ selected }) =>
                                        classNames(
                                          "w-full rounded-lg py-1.5 text-sm leading-5 primary-color text-xs",
                                          "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                                          conditionId == 0 || boxId == 0
                                            ? "bg-red-300"
                                            : "bg-green-200",
                                          selected
                                            ? "bg-white font-bold"
                                            : "primary-color hover:bg-white/[0.12] hover:text-slate-500"
                                        )
                                      }
                                    >
                                      Detalles
                                    </Tab>
                                    <Tab
                                      className={({ selected }) =>
                                        classNames(
                                          "w-full rounded-lg py-1.5 text-sm leading-5 primary-color text-xs",
                                          "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                                          filteredPaymentMethodsCompleted
                                            ? "bg-green-200"
                                            : "bg-red-300",
                                          selected
                                            ? "bg-white font-bold"
                                            : "primary-color hover:bg-white/[0.12] hover:text-slate-500"
                                        )
                                      }
                                    >
                                      Formas de Pago
                                    </Tab>
                                  </Tab.List>
                                  <Tab.Panels>
                                    <Tab.Panel>
                                      <Transition.Child
                                        enter="ease-out duration-500"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in duration-300"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                      >
                                        <div className="mb-[2px]">
                                          <ComboBox
                                            data={providers}
                                            setData={setProviderId}
                                            value={providerId}
                                            disabled={true}
                                          />
                                        </div>

                                        <div className="mb-[2px]">
                                          <input
                                            className="input-bg-color appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                            id="RUC"
                                            type="text"
                                            disabled
                                            readOnly
                                            value={provider?.ruc || ""}
                                            placeholder="RUC"
                                            onChange={(e) => { }}
                                          />
                                        </div>

                                        <div className="mb-[2px]">
                                          <input
                                            className="input-bg-color appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                            id="address"
                                            type="text"
                                            readOnly
                                            disabled
                                            value={provider?.address || ""}
                                            placeholder="Direccion"
                                            onChange={(e) => { }}
                                          />
                                        </div>

                                        <div>
                                          <input
                                            className="input-bg-color appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                            id="phone"
                                            type="text"
                                            readOnly
                                            disabled
                                            value={provider?.phone || ""}
                                            placeholder="Telefono"
                                            onChange={(e) => { }}
                                          />
                                        </div>
                                      </Transition.Child>
                                    </Tab.Panel>
                                    <Tab.Panel>
                                      <Transition.Child
                                        enter="ease-out duration-500"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in duration-300"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                      >
                                        <div className="mb-[2px]">
                                          <select
                                            className="input-bg-color appearance-none border rounded w-[49.5%] py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                            id="branch"
                                            readOnly
                                            disabled
                                            value={branchId}
                                            onChange={(e) => {
                                              setBranchId(e.target.value);
                                            }}
                                          >
                                            <option value={0}>Sucursal</option>
                                            <option value={user.branch.id}>
                                              {user.branch.description}
                                            </option>
                                          </select>

                                          <select
                                            className="input-bg-color appearance-none border rounded w-[49.5%] ml-[2px] py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                            id="deposit"
                                            readOnly
                                            disabled
                                            value={depositId}
                                            onChange={(e) => {
                                              setDepositId(e.target.value);
                                            }}
                                          >
                                            <option value={0}>
                                              Elija un deposito
                                            </option>
                                            {deposits.map((deposit) => (
                                              <option value={deposit.id}>
                                                {deposit.description}
                                              </option>
                                            ))}
                                          </select>
                                        </div>

                                        <div className="mb-[2px]">
                                          <select
                                            className="input-bg-color appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                            id="currency"
                                            readOnly
                                            disabled
                                            value={currencyId}
                                            onChange={(e) => {
                                              setCurrencyId(e.target.value);
                                            }}
                                          >
                                            <option value={0}>Moneda</option>
                                            {currencies.map((currency, i) => (
                                              <option
                                                value={currency.id}
                                                key={i}
                                              >
                                                {currency.name}
                                              </option>
                                            ))}
                                          </select>
                                        </div>

                                        <div className="mb-[2px]">
                                          <input
                                            className="input-bg-color appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="date"
                                            value={date}
                                            type="date"
                                            readOnly
                                            disabled
                                            placeholder="Fecha"
                                            onChange={(e) => {
                                              setDate(e.target.value);
                                            }}
                                          />
                                        </div>
                                        <div>
                                          <select
                                            className="input-bg-color appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                            id="sellerId"
                                            disabled
                                            readOnly
                                            value={sellerId}
                                            onChange={(e) => { }}
                                          >
                                            <option value={0}>Vendedor</option>
                                            <option value={user.id}>
                                              {user.fullname}
                                            </option>
                                          </select>
                                        </div>
                                      </Transition.Child>
                                    </Tab.Panel>
                                    <Tab.Panel>
                                      <Transition.Child
                                        enter="ease-out duration-500"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in duration-300"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                      >
                                        <div className="mb-[2px]">
                                          <input
                                            className="input-bg-color appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="number"
                                            readOnly
                                            disabled
                                            value={
                                              expeditionPoint +
                                              "   " +
                                              stamped +
                                              "   " +
                                              number
                                            }
                                            type="text"
                                            placeholder="Numero"
                                            onChange={(e) => { }}
                                          />
                                        </div>

                                        <div className="mb-[2px]">
                                          <select
                                            className="input-bg-color appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                            id="boxId"
                                            value={boxId}
                                            disabled
                                            readOnly
                                            onChange={(e) => {
                                              setBoxId(e.target.value);
                                            }}
                                          >
                                            <option value={0}>Caja</option>
                                            <option value={user.box.id}>
                                              {user.box.name}
                                            </option>
                                          </select>
                                        </div>

                                        <div className="mb-[2px]">
                                          <select
                                            className="input-bg-color appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                            id="conditionId"
                                            value={conditionId}
                                            // disabled={
                                            //   action == "edit" ? true : false
                                            // }
                                            // readOnly={
                                            //   action == "edit" ? true : false
                                            // }
                                            readOnly
                                            disabled
                                            onChange={(e) => {
                                              setConditionId(e.target.value);
                                              filterPaymentMethods(
                                                e.target.value
                                              );
                                            }}
                                          >
                                            <option value={0}>
                                              Condicion de compra
                                            </option>
                                            {saleConditions.map(
                                              (saleCondition) => (
                                                <option
                                                  value={saleCondition.id}
                                                >
                                                  {saleCondition.description}
                                                </option>
                                              )
                                            )}
                                          </select>
                                        </div>

                                        {filteredPaymentMethods.length > 0 ? (
                                          <div>
                                            <input
                                              // readOnly={
                                              //   action == "edit" ? true : false
                                              // }
                                              // disabled={
                                              //   action == "edit" ? true : false
                                              // }
                                              readOnly
                                              disabled
                                              className="input-bg-color appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                              value={numberWithCommas(
                                                filteredPaymentMethods[0]
                                                  ?.amount
                                              )}
                                              placeholder={
                                                filteredPaymentMethods[0]?.name
                                              }
                                              onChange={(e) => {
                                                let newFilteredPaymentMethods =
                                                  [];
                                                paymentMethods.map(
                                                  (paymentMethod) => {
                                                    if (
                                                      paymentMethod.id ==
                                                      filteredPaymentMethods[0]
                                                        .id
                                                    ) {
                                                      paymentMethod.amount =
                                                        e.target.value;
                                                      newFilteredPaymentMethods.push(
                                                        paymentMethod
                                                      );
                                                    } else {
                                                      newFilteredPaymentMethods.push(
                                                        paymentMethod
                                                      );
                                                    }
                                                  }
                                                );
                                                setFilteredPaymentMethods(
                                                  newFilteredPaymentMethods
                                                );
                                              }}
                                            />
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                      </Transition.Child>
                                    </Tab.Panel>
                                    <Tab.Panel>
                                      <Transition.Child
                                        enter="ease-out duration-500"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in duration-300"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                      >
                                        {filteredPaymentMethods.map(
                                          (paymentMethod, i) =>
                                            i > 0 && (
                                              <div className="mb-[2px]">
                                                <input
                                                  // disabled={
                                                  //   action == "edit"
                                                  //     ? true
                                                  //     : false
                                                  // }
                                                  // readOnly={
                                                  //   action == "edit"
                                                  //     ? true
                                                  //     : false
                                                  // }
                                                  readOnly
                                                  disabled
                                                  className="input-bg-color appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                  value={numberWithCommas(
                                                    paymentMethod.amount
                                                  )}
                                                  type="text"
                                                  placeholder={
                                                    paymentMethod.name
                                                  }
                                                  onChange={(e) => {
                                                    let newFilteredPaymentMethods =
                                                      [];
                                                    paymentMethods.map(
                                                      (paymentMethodMap) => {
                                                        if (
                                                          paymentMethodMap.id ==
                                                          paymentMethod.id
                                                        ) {
                                                          paymentMethodMap.amount =
                                                            e.target.value;
                                                          newFilteredPaymentMethods.push(
                                                            paymentMethodMap
                                                          );
                                                        } else {
                                                          newFilteredPaymentMethods.push(
                                                            paymentMethodMap
                                                          );
                                                        }
                                                      }
                                                    );
                                                    setFilteredPaymentMethods(
                                                      newFilteredPaymentMethods
                                                    );
                                                  }}
                                                />
                                              </div>
                                            )
                                        )}
                                      </Transition.Child>
                                    </Tab.Panel>
                                  </Tab.Panels>
                                </Tab.Group>
                              </>
                            )}

                            <div className="w-full h-[45vh] lg:h-[50vh] flex flex-row mt-2 bg-gray-400 p-2">
                              <div className="w-[100%] overflow-x-auto pb-2">
                                <div className="mb-[2px]">
                                  <div className="purchases-table w-full">
                                    <div className="text-gray">
                                      {width > 620 ? (
                                        <div className="tr flex">
                                          <div className="w-[10%] font-bold primary-color">
                                            COD.
                                          </div>
                                          <div className="w-[43%] font-bold primary-color">
                                            PRODUCTO
                                          </div>
                                          <div className="w-[10%] font-bold primary-color">
                                            CANT
                                          </div>
                                          <div className="w-[10%] font-bold primary-color">
                                            COSTO
                                          </div>
                                          <div className="w-[15%] font-bold primary-color">
                                            PRECIO TIENDA
                                          </div>
                                          <div className="w-[15%] font-bold primary-color">
                                            TOTAL
                                          </div>
                                          <div className="w-[9%] font-bold primary-color">
                                            IVA
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="tr flex">
                                          <div className="w-[15%] font-bold primary-color">
                                            COD.
                                          </div>
                                          <div className="w-[70%] font-bold primary-color">
                                            PRODUCTO
                                          </div>
                                          <div className="w-[15%] font-bold primary-color">
                                            CANT
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    <div className="overflow-y-auto overflow-x-hidden">
                                      {width > 620
                                        ? items.map((item, i) => (
                                          <div className="tr flex">
                                            <div className="td w-[10%] m-[1px]">
                                              <input
                                                className="input-bg-color py-1 px-3 w-full text-center"
                                                type="text"
                                                readOnly
                                                disabled
                                                name="code"
                                                value={item.id}
                                              />
                                            </div>

                                            <div className="td w-[43%] m-[1px]">
                                              <ComboBox
                                                data={articles}
                                                value={item.id}
                                                setData={setItems}
                                                items={items}
                                                index={i}
                                                className="h-[22px] ml-[1px] mr-[2px]"
                                                disabled={true}
                                              />
                                            </div>
                                            <div className="td w-[10%] m-[1px]">
                                              <input
                                                className="input-bg-color ml-[1px] py-1 px-3 w-full text-center"
                                                type="text"
                                                name="qty"
                                                readOnly
                                                disabled
                                                value={item.qty}
                                                onChange={(e) => {
                                                  let newItems = [...items];
                                                  newItems.map((item, it) => {
                                                    if (i == it) {
                                                      item.qty =
                                                        e.target.value;
                                                      item.subtotal =
                                                        String(
                                                          item.price
                                                        ).replaceAll(
                                                          ",",
                                                          ""
                                                        ) * item.qty;
                                                      item.subtotal =
                                                        numberWithCommas(
                                                          item.subtotal
                                                        );
                                                    }
                                                  });
                                                  setItems(newItems);
                                                }}
                                              />
                                            </div>
                                            <div className="td w-[10%] m-[1px]">
                                              <input
                                                className="input-bg-color ml-[1px] py-1 px-3 w-full text-center"
                                                type="text"
                                                name="cost"
                                                readOnly
                                                disabled
                                                value={numberWithCommas(item.cost)}
                                              />
                                            </div>
                                            <div className="td w-[15%] m-[1px]">
                                              <input
                                                className="input-bg-color py-1 px-3 w-full text-right"
                                                type="text"
                                                name="price"
                                                readOnly
                                                disabled
                                                value={numberWithCommas(
                                                  item.price
                                                )}
                                                placeholder="Precio"
                                                onChange={(e) => {
                                                  let newItems = [...items];
                                                  newItems.map((item, it) => {
                                                    if (i == it) {
                                                      item.price = Number(
                                                        String(
                                                          e.target.value
                                                        ).replaceAll(",", "")
                                                      );
                                                      item.subtotal =
                                                        String(
                                                          item.price
                                                        ).replaceAll(
                                                          ",",
                                                          ""
                                                        ) * item.qty;
                                                      item.subtotal =
                                                        numberWithCommas(
                                                          item.subtotal
                                                        );
                                                    }
                                                  });
                                                  setItems(newItems);
                                                }}
                                              />
                                            </div>
                                            <div className="td w-[15%] m-[1px]">
                                              <input
                                                className="input-bg-color py-1 px-3 w-full text-right"
                                                type="text"
                                                readOnly
                                                disabled
                                                name="subtotal"
                                                value={numberWithCommas(
                                                  item.subtotal
                                                )}
                                              />
                                            </div>
                                            <div className="td w-[7%] m-[1px]">
                                              <input
                                                className="input-bg-color py-1 px-3 w-full text-center"
                                                type="text"
                                                name="iva"
                                                value={item.iva}
                                              />
                                            </div>
                                          </div>
                                        ))
                                        : items.map((item, i) => (
                                          <div
                                            className="tr flex flex-col m-1 pb-1"
                                            style={{
                                              borderBottom:
                                                "1px solid rgba(0,0,0,0.5)",
                                            }}
                                          >
                                            <div className="w-full flex flex-row">
                                              <div className="td w-[20%] m-[1px]">
                                                <input
                                                  className="input-bg-color py-1 px-3 w-full text-center"
                                                  type="text"
                                                  readOnly
                                                  disabled
                                                  name="code"
                                                  value={item.id}
                                                />
                                              </div>

                                              <div className="td w-[60%] m-[1px]">
                                                <ComboBox
                                                  data={articles}
                                                  value={item.id}
                                                  setData={setItems}
                                                  items={items}
                                                  index={i}
                                                  className="h-[23px] ml-[1px]"
                                                  disabled={true}
                                                />
                                              </div>
                                              <div className="td w-[20%] m-[1px]">
                                                <input
                                                  className="input-bg-color py-1 px-3 w-full text-center"
                                                  type="text"
                                                  name="qty"
                                                  value={item.qty}
                                                  readOnly
                                                  disabled
                                                  onChange={(e) => {
                                                    let newItems = [...items];
                                                    newItems.map(
                                                      (item, it) => {
                                                        if (i == it) {
                                                          item.qty =
                                                            e.target.value;
                                                          item.subtotal =
                                                            String(
                                                              item.price
                                                            ).replaceAll(
                                                              ",",
                                                              ""
                                                            ) * item.qty;
                                                          item.subtotal =
                                                            numberWithCommas(
                                                              item.subtotal
                                                            );
                                                        }
                                                      }
                                                    );
                                                    setItems(newItems);
                                                  }}
                                                />
                                              </div>
                                            </div>

                                            <div className="w-full flex flex-row">
                                              <div className="td w-[100%] m-[1px]">
                                                <input
                                                  className="input-bg-color py-1 px-3 w-full text-center"
                                                  type="text"
                                                  name="cost"
                                                  value={numberWithCommas(item.cost)}
                                                  readOnly
                                                  disabled
                                                />
                                              </div>
                                            </div>

                                            <div className="w-full flex flex-row">
                                              <div className="td w-[20%] m-[1px]">
                                                <input
                                                  className="input-bg-color py-1 px-3 w-full text-center"
                                                  type="text"
                                                  name="price"
                                                  value={numberWithCommas(
                                                    item.price
                                                  )}
                                                  readOnly
                                                  disabled
                                                  placeholder="Precio"
                                                  onChange={(e) => {
                                                    let newItems = [...items];
                                                    newItems.map(
                                                      (item, it) => {
                                                        if (i == it) {
                                                          item.price = Number(
                                                            String(
                                                              e.target.value
                                                            ).replaceAll(
                                                              ",",
                                                              ""
                                                            )
                                                          );
                                                          item.subtotal =
                                                            String(
                                                              item.price
                                                            ).replaceAll(
                                                              ",",
                                                              ""
                                                            ) * item.qty;
                                                          item.subtotal =
                                                            numberWithCommas(
                                                              item.subtotal
                                                            );
                                                        }
                                                      }
                                                    );
                                                    setItems(newItems);
                                                  }}
                                                />
                                              </div>
                                              <div className="td w-[60%] m-[1px]">
                                                <input
                                                  className="input-bg-color py-1 px-3 w-full text-center"
                                                  type="text"
                                                  readOnly
                                                  disabled
                                                  name="subtotal"
                                                  value={numberWithCommas(
                                                    item.subtotal
                                                  )}
                                                />
                                              </div>
                                              <div className="td w-[20%] m-[1px]">
                                                <input
                                                  className="input-bg-color py-1 px-3 w-full text-center"
                                                  type="text"
                                                  name="iva"
                                                  value={item.iva}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tr flex flex-row justify-end items-center m-[2px]">
                        <div className="td text-right font-bold mr-1">
                          TOTAL
                        </div>
                        <div className="td w-[40%] sm:w-[15%]">
                          <input
                            className="input-bg-color py-1 px-3 w-full text-right font-bold"
                            type="text"
                            readOnly
                            disabled
                            name="total"
                            value={totalAmount}
                            placeholder="Total"
                          />
                        </div>
                        <div className="td w-[7%]"></div>
                      </div>
                      <div className="px-4 py-2 justify-center flex">
                        <button
                          type="button"
                          className="float-left inline-flex sm:w-[70px] h-[63] m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 !text-[10px]"
                          style={{ alignItems: "center" }}
                          onClick={() => {
                            clearFields();
                            setOpen(false);
                          }}
                        >
                          <img src={door} className="w-6" />
                          SALIR
                        </button>

                        <button
                          type="button"
                          className="float-left inline-flex sm:w-[70px] h-[63] flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 !text-[10px]"
                          style={{ alignItems: "center" }}
                          onClick={async () => {
                            if (window.confirm("Desea anular esta compra?")) {
                              let cancelPurchase = await axiosAuth.put(
                                "/cancelPurchase",
                                { id: purchaseId }
                              );

                              let newPurchases = [...purchases];
                              newPurchases.map((newPurchase) => {
                                if (newPurchase.id == purchaseId) {
                                  newPurchase.state = 1;
                                }
                              });
                              setPurchases(newPurchases);
                              setState(1);

                              toast.success("Compra anulada correctamente!");
                            }
                          }}
                        >
                          <img src={cancel} className="w-6" />
                          ANULAR
                        </button>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <div className="flex flex-row w-[100%] relative sm:top-[-2px]" style={{ borderBottom: "1px solid lightgray" }}>
          <div className="w-[50%] sm:w-[35%]">
            <h1
              id="titlebig"
              className="ml-5 mt-5 text-3xl title font-family-thin secondary-color"
            >
              Visor de Compras
            </h1>
            <DateComponent className="sm:block hidden" />{" "}
          </div>

          <div className="flex flex-row justify-end w-full sm:w-[75%] sm:mr-[10%] pt-1">
            <div className="flex flex-row justify-end w-full">
              <div className="flex flex-col items-end w-full relative right-0 sm:right-[-4%] md:right-[-2.5%]">
                <ComboBox
                  data={clients}
                  setData={setproviderIdFilter}
                  value={providerIdFilter}
                  placeholder="Proveedor"
                  className="h-[25px] mt-[2px] w-[80%] sm:w-[220px] min-w-[80px] mb-2"
                  search={searchClients}
                />
                <input
                  className=" w-[80%] sm:w-[220px] min-w-[80px] appearance-none border text-gray-700 leading-tight capitalize h-[25px] p-[6px] mr-[1px] mt-1"
                  id="billNumber"
                  value={billNumber}
                  type="text"
                  placeholder="Nro. factura"
                  onChange={(e) => {
                    setBillNumber(e.target.value);
                  }}
                />
              </div>
              <div className="flex flex-col items-end w-[200px]">
                <input
                  className=" appearance-none border text-gray-700 leading-tight capitalize h-[25px] p-[6px] mr-[1px] mt-2"
                  id="date"
                  value={dateFromFilter}
                  type="date"
                  onChange={(e) => {
                    setDateFromFilter(e.target.value);
                  }}
                />

                <input
                  className=" appearance-none border text-gray-700 leading-tight capitalize h-[25px] p-[6px] mt-2"
                  id="date"
                  value={dateToFilter}
                  type="date"
                  onChange={(e) => {
                    setDateToFilter(e.target.value);
                  }}
                />
              </div>

              <button
                type="button"
                className="float-left inline-flex sm:w-[110px] w-[150px] h-[63]  m-[5px] flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 !text-[10px]"
                style={{ alignItems: "center" }}
                onClick={() => {
                  fetchPurchases(); // Mismo nombre de función
                }}
              >
                <img src={search} className="w-8" />
                CONSULTAR
              </button>

              <button
                type="button"
                className="float-left inline-flex sm:w-[110px] w-[150px] h-[63] m-[5px] flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 !text-[10px]"
                style={{ alignItems: "center" }}
                onClick={() => {
                  navigate("/");
                }}
              >
                <img src={door} className="w-6" />
                SALIR
              </button>
            </div>
          </div>
        </div>

        {/* <button
          className="rounded-md bg-white px-2 py-1 font-semibold float-right relative right-10 bottom-10 cursor-pointer title-button"
          onClick={() => {
            setAction("create");

            let newItems = [];

            Array(5)
              .fill(0)
              .map((_, i) => {
                newItems.push({
                  id: 0,
                  qty: 0,
                  name: "",
                  price: 0,
                  subtotal: 0,
                  iva: "",
                });
              });

            setItems(newItems);

            setOpen(true);
            filterPaymentMethods(purchaseConditionId);
          }}
        >
          Crear compra
        </button> */}

        <div className="w-[97%] max-h-[75vh] mx-auto overflow-x-auto">
          <div className="overflow-y-auto max-h-[75vh]">
            <table className="w-[97%] table-auto mx-auto w-full relative support-table">
              <thead className="sticky top-0 bg-gray-500">
                <tr className="bg-gray-500 h-8 !text-white">
                  <th className="!text-white">Nro. Fact.</th>
                  <th className="!text-white">Fecha</th>
                  <th className="!text-white">Proveedor</th>
                  <th className="!text-white">Condicion</th>
                  <th className="!text-white">Moneda</th>
                  <th className="!text-white">GRAV10</th>
                  <th className="!text-white">IVA10</th>
                  <th className="!text-white">GRAV5</th>
                  <th className="!text-white">IVA5</th>
                  <th className="!text-white">Exento</th>
                  <th className="!text-white">Total</th>
                  <th className="!text-white">Anulado</th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {isFetching && (
                  <tr>
                    <td></td>
                    <td colSpan={18}>
                      <Lottie
                        animationData={loadingAnimation}
                        style={{ width: 100, height: 100, margin: "0 auto" }}
                        loop={true}
                      />
                    </td>
                  </tr>
                )}
                {purchases.length > 0 &&
                  !isFetching &&
                  purchases?.map((purchase, i) => {
                    let purchaseTotal = 0;

                    purchase.purchasesdetails.map((detail) => {
                      purchaseTotal += detail.cost * detail.quantity;
                    });

                    return (
                      <tr key={purchase.id} onClick={() => {
                        editPurchase(purchase.id);
                      }} className={purchase.state ? "!bg-red-200 mt-5" : "mt-5"}>
                        {/* <td>{purchase.id}</td> */}
                        <td className="text-right uppercase p-2">
                          {
                            purchase.expeditionPoint + "-" + String(purchase.number).padStart(7, '0')
                          }
                        </td>
                        <td>
                          {(purchase.document_date || purchase.createdAt)
                            .substr(0, 10)
                            .split("-")
                            .reverse()
                            .join("/")}
                        </td>
                        <td className="text-left uppercase whitespace-nowrap">
                          {purchase.provider.name}
                        </td>

                        <td className="text-center uppercase">
                          {purchase.conditionId
                            === 1 ? 'contado' : purchase.conditionId
                              === 2 ? 'crédito' : ''}
                        </td>

                        <td className="text-center uppercase">
                          {purchase.currency.name}
                        </td>
                        <td className="text-right">{numberWithCommas(purchase.taxed10)}</td>
                        <td className="text-right">{numberWithCommas(purchase.vat10)}</td>


                        <td className="text-right">{numberWithCommas(purchase.taxed5)}</td>
                        <td className="text-right">{numberWithCommas(purchase.vat5)}</td>
                        <td className="text-right">{numberWithCommas(purchase.exempt)}</td>


                        <td className="text-right font-bold">{numberWithCommas(purchaseTotal)}</td>

                        <td>{purchase.state ? "Si" : "No"}</td>


                      </tr>
                    );
                  })}
                {purchases.length <= 0 && !isFetching && (
                  <tr>
                    <td></td>
                    <td colSpan={18} className="text-center">
                      Sin registros (0)
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Purchases;
