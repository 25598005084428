import React, { useEffect, useState } from "react";

const DateComponent = (props) => {
  const [date, setDate] = useState("");
  const days = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miercoles",
    "Jueves",
    "Viernes",
    "Sabado",
  ];
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  useEffect(() => {
    let newDate = new Date();
    let day = days[newDate.getDay()];
    let month = months[newDate.getMonth()];

    setDate(
      day + " " + newDate.getDate() + ", " + month + " " + newDate.getFullYear()
    );
  }, []);

  return (
    <h2
      className={`ml-5 text-[16px] date relative font-family-extlt ${props.className}`}
    >
      {date}
    </h2>
  );
};

export default DateComponent;
