import React, { useState, Fragment, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Dialog, Transition, Switch } from "@headlessui/react";
import Layout from "../layout";
import { axiosAuth } from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser,setTitle } from "../store/slices/mainSlice";
import { useLocation, useParams } from "react-router-dom";
import Pagination from "../components/pagination";
import DateComponent from "../components/date";
import { endpoint } from "../utils/constants";
import { setUserImage } from "../store/slices/mainSlice";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/animations/loading.json";
import usersimg from "../assets/img/users.png";
import door from "../assets/img/door.png";
import floppy from "../assets/img/floppy.png";
import { socket } from "../utils/socket";
import { motion } from "framer-motion";
import plus from "../assets/img/plus.png";
import useViewport from "../utils/useViewport";

const Users = () => {
  const user = useSelector((state) => state.main.user);

  const dispatch = useDispatch();
  const location = useLocation();

  const { page, search } = useParams();

  const [isFetching, setIsFetching] = useState(false);
  const [open, setOpen] = useState(false);
  const [accessOpen, setAccessOpen] = useState(false);
  const [action, setAction] = useState("edit");
  const [forMesagge, setFormessage] = useState("");
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);

  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [branches, setBranches] = useState([]);
  const [boxes, setBoxes] = useState([]);
  const [menus, setMenus] = useState([]);
  const [filteredBranches, setFilteredBranches] = useState([]);
  const [filteredBoxes, setFilteredBoxes] = useState([]);

  const [userId, setUserId] = useState(0);
  const [fullname, setFullName] = useState("");
  const [image, setImage] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [imageFile, setImageFile] = useState([]);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [roleId, setRoleId] = useState(0);
  const [companyId, setCompanyId] = useState(0);
  const [branchId, setBranchId] = useState(0);
  const [boxId, setBoxId] = useState(0);
  const [password, setPassword] = useState("");
  const [rptPassword, setRptPassword] = useState("");
  const [active, setActive] = useState(1);
  const [deleted, setDeleted] = useState(0);
  const [userAccess, setUserAccess] = useState([]);
  const [searchText, setSearchText] = useState(search || "");
  const { width } = useViewport();

  async function fetchUsersData() {
    let pageQuery = page == undefined ? 1 : page;

    setIsFetching(true);

    let data = await axiosAuth.get("/users?page=" + pageQuery);

    if (data.data.error) {
      if (data.data.message === "token") {
        await axiosAuth.post("/logout");
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    setIsFetching(false);

    setUsers(data.data.users.rows);
    setPages(data.data.users.pages);
    setTotal(data.data.users.count);

    setRoles(data.data.roles);
    setBranches(data.data.branches);
    setCompanies(data.data.companies);
    setBoxes(data.data.boxes);

    let newMenus = [...data.data.menus];

    newMenus.map((menu) => {
      menu.selected = false;
      menu.submenus?.map((submenu) => {
        submenu.selected = false;
      });
    });

    setTimeout(function () {
      setMenus(newMenus);
    }, 500);

    let filteredBranchesObj = [];

    data.data.branches.map((branch) => {
      if (branch.companyId === data.data.companies[0].id) {
        filteredBranchesObj.push(branch);
      }
      return "";
    });

    setFilteredBranches(filteredBranchesObj);

    let filteredBoxesObj = [];

    data.data.boxes.map((box) => {
      if (box.branchId == data.data.branches[0].id) {
        filteredBoxesObj.push(box);
      }
    });

    setFilteredBoxes(filteredBoxesObj);
  }



  useEffect(() => {
    fetchUsersData();
    dispatch(setTitle('Usuarios'));
  }, [location]);
//dddd
  useEffect(() => {
    function onConnect() {
      console.log("!isconnected");
    }

    function onDisconnect() {
      console.log("!isdisconnected");
    }

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);

    //socket.emit("setUserId", { userId: user.id });

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
    };
  }, []);

  const searchUser = async (searchTxt) => {
    setSearchText(searchTxt);

    const searchUserRequest = await axiosAuth.get(
      "/searchUser?search=" + searchTxt
    );
    console.log(searchUserRequest);
    setUsers(searchUserRequest.data.user.rows);
    setPages(searchUserRequest.data.user.pages);
    setTotal(searchUserRequest.data.user.total);
  };
  const editUser = (userId) => {
    setAction("edit");
    setUserId(userId);

    let user = users.filter((user) => user.id === userId)[0];

    setFullName(user.fullname);
    setImage(user.image);
    if (user.image != "") {
      setImagePreview(endpoint + "userImages/" + user.image);
    } else {
      setImagePreview("");
    }
    setUserAccess(user.access);
    setEmail(user.email);
    setPhone(user.phone);
    setRoleId(user.roleId);
    setCompanyId(user.companyId);
    setBranchId(user.branchId);
    setBoxId(user.boxId);
    setActive(user.active);
    setDeleted(user.deleted);

    setOpen(true);
  };

  const filterBranches = (companyId) => {
    let filteredBranchesObj = [];
    branches.map((branch) => {
      if (branch.companyId == companyId) {
        filteredBranchesObj.push(branch);
      }
      return "";
    });
    setFilteredBranches(filteredBranchesObj);

    let filteredBoxesObj = [];
    if (filteredBranchesObj.length > 0) {
      boxes.map((box) => {
        if (box.branchId == filteredBranchesObj[0].id) {
          filteredBoxesObj.push(box);
        }
        return "";
      });
    }
    setFilteredBoxes(filteredBoxesObj);
  };

  const filterBoxes = (branchId) => {
    let filteredBoxesObj = [];
    boxes.map((box) => {
      if (box.branchId == branchId) {
        filteredBoxesObj.push(box);
      }
      return "";
    });
    setFilteredBoxes(filteredBoxesObj);
  };

  const clearFields = () => {
    setFullName("");
    setEmail("");
    setImage("");
    setImagePreview("");
    setUserAccess([]);
    setImageFile([]);
    setPhone("");
    setPassword("");
    setRptPassword("");
    setRoleId(0);
    setCompanyId(0);
    setBranchId(0);
    setBoxId(0);
    setActive(0);
    setDeleted(0);
  };

  const handleSubmit = async () => {
    if (action === "edit") {
      if (
        password.length > 0 &&
        rptPassword.length > 0 &&
        password !== rptPassword
      ) {
        alert("Las claves no coinciden !");
        return false;
      }

      let formData = new FormData();
      formData.append("id", userId);
      formData.append("fullname", fullname);
      formData.append("file", imageFile);
      formData.append("image", image);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("roleId", roleId);
      formData.append("companyId", companyId);
      formData.append("branchId", branchId);
      formData.append("boxId", boxId);
      formData.append("password", password);
      formData.append("active", active);
      formData.append("deleted", deleted);

      let updatedUserRequest = await axiosAuth.put("/users", formData);

      if (updatedUserRequest.data.error) {
        if (updatedUserRequest.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }

        setFormessage(updatedUserRequest.message);
      } else {
        let updatedUsersNewObj = [];

        users.map((user) => {
          if (user.id == userId) {
            updatedUsersNewObj.push(updatedUserRequest.data);
          } else {
            updatedUsersNewObj.push(user);
          }
          return "";
        });

        setUsers(updatedUsersNewObj);
        if (imageFile != [] && updatedUserRequest.data.id == user.id) {
          dispatch(setUserImage(updatedUserRequest.data.image));
        }
      }
    } else {
      if (
        fullname === "" ||
        email === "" ||
        phone === "" ||
        roleId === 0 ||
        companyId === 0 ||
        branchId === 0 ||
        boxId === 0 ||
        password === ""
      ) {
        alert("Debe completar todos los campos !");
        return false;
      }

      if (password !== rptPassword) {
        alert("Las claves no coinciden !");
        return false;
      }

      let formData = new FormData();
      formData.append("fullname", fullname);
      formData.append("file", imageFile);
      formData.append("image", image);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("roleId", roleId);
      formData.append("companyId", companyId);
      formData.append("branchId", branchId);
      formData.append("boxId", boxId);
      formData.append("password", password);
      formData.append("active", active);
      formData.append("deleted", deleted);

      let createdUser = await axiosAuth.post("/users", formData);

      if (createdUser.data.error) {
        if (createdUser.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }

        setFormessage(createdUser.data.message);
      } else {
        let newUsers = [...users];
        newUsers.push(createdUser.data[0]);
        setUsers(newUsers);
      }
    }
    clearFields();
    setOpen(false);
  };

  const deleteUser = async (userId) => {
    if (window.confirm("Desea eliminar este usuario ?")) {
      let userDeleteRequest = await axiosAuth.delete("/users", {
        params: { id: userId },
      });

      if (userDeleteRequest.data.error) {
        if (userDeleteRequest.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      let newUsersObj = [];
      let loggedUser = user;

      users.map((user) => {
        if (user.id !== userId) {
          newUsersObj.push(user);
        } else {
          if (loggedUser.roleId == 1) {
            user.deleted = 1;
            newUsersObj.push(user);
          }
        }
        return "";
      });

      setUsers(newUsersObj);
    }
  };

  const handleImageInputChange = (e) => {
    if (e.target.files[0] === undefined) return false;
    setImageFile(e.target.files[0]);
    setImage(String(e.target.files[0].name).replaceAll(" ", ""));
    let file = URL.createObjectURL(e.target.files[0]);
    setImagePreview(file);
  };

  const updateUserAccess = async () => {
    let newUserAccess = [];

    menus.map((menu) => {
      if (menu.selected) {
        newUserAccess.push({
          menuId: menu.id,
          submenuId: null,
          userId: userId,
        });
      }

      if (menu.submenus.length > 0) {
        menu.submenus.map((submenu) => {
          if (submenu.selected) {
            newUserAccess.push({
              menuId: menu.id,
              submenuId: submenu.id,
              userId: userId,
            });
          }
        });
      }
    });

    let newUsers = [...users];

    newUsers.map((newUser) => {
      if (newUser.id == userId) {
        newUser.access = newUserAccess;
      }
    });

    setUserAccess(newUserAccess);
    setUsers(newUsers);

    let userAccessRequest = await axiosAuth.put("/useraccess", newUserAccess);

    if (userId == user.id) {
      dispatch(
        setUser({ ...user, access: userAccessRequest.data.permissions })
      );
    }

    clearMenus();
  };

  const clearMenus = () => {
    const newMenus = [...menus];

    newMenus.map((menu) => {
      menu.selected = false;

      if (menu.submenus.length > 0) {
        menu.submenus.map((submenu) => {
          submenu.selected = false;
        });
      }
    });

    setMenus(newMenus);
  };

  return (
    <>
      <Layout>
        <Transition.Root show={accessOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              setAccessOpen(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto align-items-center justify-center items-center flex pt-60 pb-5">
              <div className="flex justify-center p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel
                    className="relative transform overflow-hidden rounded-3xl bg-white text-left shadow-xl transition-all sm:my-8 w-[280px]"
                    style={{ marginTop: 1350 }}
                  >
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-0">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                          <Dialog.Title
                            as="h1"
                            className="text-center font-regular leading-6 primary-color text-2xl"
                          >
                            Gestionar permisos
                          </Dialog.Title>
                          <div className="mt-5">
                            <div className="w-full max-w-sm">
                              <form className="bg-white rounded">
                                {menus.map((menu, m) => (
                                  <div className="mb-2">
                                    <div className="flex flex-row justify-between items-center">
                                      <p className="font-family-thin font-bold !text-[14px]">
                                        {menu.name}
                                      </p>
                                      {menu.submenus?.length <= 0 && (
                                        <Switch
                                          checked={menu.selected}
                                          onChange={() => {
                                            let newMenus = [...menus];
                                            newMenus.map((newMenu) => {
                                              if (newMenu.id == menu.id) {
                                                newMenu.selected =
                                                  !newMenu.selected;
                                              }
                                            });
                                            setMenus(newMenus);
                                          }}
                                          className={`${
                                            menu.selected
                                              ? "bg-teal-700"
                                              : "bg-slate-200"
                                          }
                                          relative inline-flex h-[18px] w-[34px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
                                        >
                                          <span
                                            aria-hidden="true"
                                            className={`${
                                              menu.selected
                                                ? "translate-x-5 translate-y-[-3px]"
                                                : "translate-x-[-2px] translate-y-[-3px]"
                                            }
                                            pointer-events-none inline-block mt-[2px] relative right-[-2px] h-[14px] w-[14px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                                          />
                                        </Switch>
                                      )}
                                    </div>

                                    {menu.submenus?.length > 0 &&
                                      menu.submenus.map((submenu, i) => (
                                        <div
                                          className={`mb-2 ${
                                            i == 0 ? "mt-2" : ""
                                          } ${
                                            i == menu.submenus.length - 1
                                              ? "mb-4"
                                              : ""
                                          } flex flex-row justify-between items-center pl-2 pr-2`}
                                        >
                                          <p className="font-family-thin !text-[13px]">
                                            {submenu.name}
                                          </p>
                                          <Switch
                                            checked={submenu.selected}
                                            onChange={() => {
                                              let newMenus = [...menus];
                                              newMenus.map((newMenu) => {
                                                if (
                                                  newMenu.id == submenu.menuId
                                                ) {
                                                  newMenu.submenus.map(
                                                    (newSubmenu) => {
                                                      if (
                                                        newSubmenu.id ==
                                                        submenu.id
                                                      ) {
                                                        newSubmenu.selected =
                                                          !newSubmenu.selected;
                                                      }
                                                    }
                                                  );
                                                }
                                              });

                                              setMenus(newMenus);
                                            }}
                                            className={`${
                                              submenu.selected
                                                ? "bg-teal-700"
                                                : "bg-slate-200"
                                            }
                                          relative inline-flex h-[18px] w-[34px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
                                          >
                                            <span
                                              aria-hidden="true"
                                              className={`${
                                                submenu.selected
                                                  ? "translate-x-5 translate-y-[-3px]"
                                                  : "translate-x-[-2px] translate-y-[-3px]"
                                              }
                                            pointer-events-none mt-[2px] relative right-[-2px] inline-block h-[14px] w-[14px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                                            />
                                          </Switch>
                                        </div>
                                      ))}
                                  </div>
                                ))}
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 justify-center flex sm:px-6">
                      <button
                        type="button"
                        className="inline-flex w-full secondary-bg-color justify-center rounded-3xl px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto font-family-thin"
                        onClick={() => {
                          clearMenus();
                          setAccessOpen(false);
                        }}
                      >
                        CANCELAR
                      </button>
                      <button
                        type="button"
                        className="inline-flex w-full align-center align-items-center justify-center rounded-3xl primary-color-bg px-3 py-2 text-sm font-semibold  shadow-sm sm:ml-3 sm:w-auto font-family-thin"
                        style={{ alignItems: "center" }}
                        onClick={() => {
                          updateUserAccess();
                          setAccessOpen(false);
                        }}
                      >
                        GUARDAR
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              // clearFields();
              // setOpen(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto align-items-center justify-center items-center flex pt-40 pb-5">
              <div className="flex justify-center p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel
                    className="relative sm:mt-[450px] mt-[380px] transform overflow-hidden rounded-3xl bg-white text-left shadow-xl transition-all sm:my-8 w-[280px]"
                    style={{ marginTop: 450 }}
                  >
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-0">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                          <Dialog.Title
                            as="h1"
                            className="text-center font-regular leading-6 primary-color text-3xl"
                          >
                            {action === "edit" ? "Editar" : "Crear"} usuario
                          </Dialog.Title>
                          <div className="mt-5">
                            <div className="w-full max-w-sm">
                              <form className="bg-white rounded">
                                {action === "edit" && (
                                  <div className="mb-4">
                                    <label
                                      className="block text-gray-700 text-sm font-bold"
                                      htmlFor="id"
                                    >
                                      Id
                                    </label>
                                    <input
                                      readOnly
                                      className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      id="id"
                                      type="text"
                                      value={userId}
                                    />
                                  </div>
                                )}

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="image"
                                  >
                                    Foto
                                    <br />
                                    <div
                                      className="bg-cover bg-no-repeat bg-center image-holder w-[82%] sm:w-[90%] h-80 flex items-center justify-center text-lg cursor-pointer relative input-bg-color m-auto"
                                      style={
                                        imagePreview != ""
                                          ? {
                                              backgroundImage:
                                                "url(" + imagePreview + ")",
                                              borderRadius: 10000,
                                            }
                                          : { borderRadius: 10000 }
                                      }
                                    >
                                      {imagePreview === "" ? "+" : ""}
                                      {imagePreview === "" ? (
                                        ""
                                      ) : (
                                        <div
                                          className="absolute top-0 right-0 cursor-pointer bg-red-700  p-2 rounded-full leading-3 z-10"
                                          onClick={() => {
                                            setImage("");
                                            setImagePreview("");
                                          }}
                                          style={{ top: -10, right: -10 }}
                                        >
                                          x
                                        </div>
                                      )}
                                    </div>
                                  </label>
                                  <input
                                    readOnly
                                    className="hidden"
                                    id="image"
                                    type="file"
                                    onChange={(e) => handleImageInputChange(e)}
                                    //value={image}
                                  />
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="fullname"
                                  >
                                    Nombre completo
                                  </label>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                    id="fullname"
                                    type="text"
                                    value={fullname}
                                    onChange={(e) =>
                                      setFullName(e.target.value)
                                    }
                                  />
                                </div>
                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="email"
                                  >
                                    Email
                                  </label>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="email"
                                    type="text"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                  />
                                </div>
                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="phone"
                                  >
                                    Telefono
                                  </label>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="phone"
                                    type="text"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                  />
                                </div>
                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="rol"
                                  >
                                    Rol
                                  </label>
                                  <select
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="rol"
                                    value={roleId}
                                    onChange={(e) => setRoleId(e.target.value)}
                                  >
                                    <option value={0}>Elija un rol</option>
                                    {roles.map((role) => (
                                      <option key={role.id} value={role.id}>
                                        {role.description}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="company_id"
                                  >
                                    Empresa
                                  </label>
                                  <select
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="company_id"
                                    value={companyId}
                                    onChange={(e) => {
                                      setCompanyId(e.target.value);
                                      filterBranches(e.target.value);
                                    }}
                                  >
                                    <option value={0}>Elija una empresa</option>
                                    {companies.map((company) => (
                                      <option
                                        key={company.id}
                                        value={company.id}
                                      >
                                        {company.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="branch_id"
                                  >
                                    Sucursal
                                  </label>
                                  <select
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="branch_id"
                                    value={branchId}
                                    onChange={(e) => {
                                      setBranchId(e.target.value);
                                      filterBoxes(e.target.value);
                                    }}
                                  >
                                    <option value={0}>
                                      Elija una sucursal
                                    </option>
                                    {filteredBranches.map((branch) => {
                                      return (
                                        <option
                                          key={branch.id}
                                          value={branch.id}
                                        >
                                          {branch.description} -{" "}
                                          {branch.company.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="box_id"
                                  >
                                    Caja
                                  </label>
                                  <select
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="box_id"
                                    value={boxId}
                                    onChange={(e) => {
                                      setBoxId(parseInt(e.target.value));
                                    }}
                                  >
                                    <option value={0}>Elija una caja</option>
                                    {filteredBoxes.map((box) => {
                                      return (
                                        <option key={box.id} value={box.id}>
                                          {box.name} - {box.branch.company.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="active"
                                  >
                                    Activo
                                  </label>
                                  <select
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="active"
                                    value={active}
                                    onChange={(e) =>
                                      setActive(parseInt(e.target.value))
                                    }
                                  >
                                    <option value={0}>No</option>
                                    <option value={1}>Si</option>
                                  </select>
                                </div>

                                {action === "edit" && (
                                  <div className="mb-4">
                                    <label
                                      className="block text-gray-700 text-sm font-bold"
                                      htmlFor="active"
                                    >
                                      Permisos
                                    </label>
                                    <button
                                      type="button"
                                      className="w-full rounded-3xl primary-color-bg px-3 py-2 text-sm font-semibold  shadow-sm font-family-thin"
                                      style={{ alignItems: "center" }}
                                      onClick={() => {
                                        let newMenus = [...menus];

                                        newMenus.map((menu) => {
                                          userAccess.map((userAccessObj) => {
                                            if (
                                              menu.id == userAccessObj.menuId &&
                                              userAccessObj.submenuId == null
                                            ) {
                                              menu.selected = true;
                                            }

                                            if (menu.submenus.length > 0) {
                                              menu.submenus.map((submenu) => {
                                                if (
                                                  menu.id ==
                                                    userAccessObj.menuId &&
                                                  submenu.id ==
                                                    userAccessObj.submenuId
                                                ) {
                                                  submenu.selected = true;
                                                }
                                              });
                                            }
                                          });
                                        });

                                        setMenus(newMenus);

                                        setAccessOpen(true);
                                      }}
                                    >
                                      GESTIONAR ACCESOS
                                    </button>
                                  </div>
                                )}

                                {user.roleId == 1 && (
                                  <div className="mb-4">
                                    <label
                                      className="block text-gray-700 text-sm font-bold"
                                      htmlFor="deleted"
                                    >
                                      Borrado
                                    </label>
                                    <select
                                      className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      id="deleted"
                                      value={deleted}
                                      onChange={(e) =>
                                        setDeleted(parseInt(e.target.value))
                                      }
                                    >
                                      <option value={0}>No</option>
                                      <option value={1}>Si</option>
                                    </select>
                                  </div>
                                )}
                                <div>
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="password"
                                  >
                                    Password
                                  </label>
                                  <input
                                    className={
                                      "input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline " +
                                      (password != rptPassword
                                        ? "border-solid border-2 border-red-500"
                                        : "")
                                    }
                                    id="password"
                                    type="password"
                                    placeholder="***********"
                                    value={password}
                                    onChange={(e) =>
                                      setPassword(e.target.value)
                                    }
                                  />
                                </div>
                                <div>
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="rptPassword"
                                  >
                                    Repetir password
                                  </label>
                                  <input
                                    className={
                                      "input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" +
                                      (password != rptPassword
                                        ? "border-solid border-2 border-red-500"
                                        : "")
                                    }
                                    id="rptPassword"
                                    type="password"
                                    placeholder="***********"
                                    value={rptPassword}
                                    onChange={(e) =>
                                      setRptPassword(e.target.value)
                                    }
                                  />
                                  {password !== rptPassword && (
                                    <p className="text-red-600">
                                      Las contrasenhas no coinciden
                                    </p>
                                  )}
                                  {forMesagge !== "" && (
                                    <p className="text-red-600">{forMesagge}</p>
                                  )}
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 justify-center flex sm:px-6">
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => {
                          clearFields();
                          setOpen(false);
                        }}
                      >
                        <img src={door} className="w-5" />
                        CERRAR
                      </button>
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => handleSubmit()}
                      >
                        <img src={floppy} className="w-8" />
                        GUARDAR
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
        <div
          class="w-full top-[-40px] flex flex-col sm:flex-row pb-2 relative bg-white "
          style={{ borderBottom: "1px solid lightgray" }}
        >
          <div className="sm:w-[30%] w-[60%]">
            <h1 className="ml-5 mt-5 text-3xl  title font-family-thin secondary-color">
              Usuarios
            </h1>
            <DateComponent className="sm:block hidden" />
          </div>
          <div class="sm:w-[50%] w-[85%] mb-[1px] sm:ml-0 ml-6 flex flex-row items-center w-200 sm:mt-2 mt-12">
            <label className="block text-gray-700 text-[10px] font-bold uppercase mr-[3px] sm:mt-5">
              Buscar:
            </label>
            <input
              value={searchText}
              className="input-bg-color appearance-none border w-[350px]  rounded  
            py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline leading-[10px] h-[35px] sm:mt-5"
              placeholder="Nombre del Usuario"
              onChange={(e) => searchUser(e.target.value)}
            />
            <motion.button
              initial={false}
              whileTap="press"
              onClick={() => {
                setAction("create");
                setOpen(true);
              }}
              className="inline-flex flex-row float-right sm:w-[170px] w-[30%] mr-1 justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 relative left-[10px] sm:mt-5 h-[35px] "
            >
              <img alt="" src={plus} className="w-8" />
              CREAR USUARIO
            </motion.button>
          </div>
        </div>
        <div className="w-[95%] p-3 mx-auto overflow-auto relative top-[-20px]">
          <table className="w-[95%] table-auto mx-auto w-full text-xs users-table support-table">
            <thead>
              {width > 1024 ? (
                <tr className="bg-gray-500 h-8">
                  <th className="text-left !text-white">Id</th>
                  <th className="text-left !text-white">Nombre</th>
                  <th className="text-left !text-white">Email</th>
                  <th className="text-left !text-white"> Telefono</th>
                  <th className="text-left !text-white">Rol</th>
                  <th className="text-left !text-white">Empresa</th>
                  <th className="text-left !text-white">Sucursal</th>
                  <th className="text-left !text-white">Caja</th>
                  {user.roleId == 1 && (
                    <th className="text-left !text-white">Borrado</th>
                  )}
                  <th className="text-left !text-white">Fecha Alta</th>
                  <th className="text-left !text-white">Accion</th>
                </tr>
              ) : (
                <></>
              )}
            </thead>
            <tbody>
              {isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={11}>
                    <Lottie
                      animationData={loadingAnimation}
                      style={{ width: 100, height: 100, margin: "0 auto" }}
                      loop={true}
                    />
                  </td>
                </tr>
              )}
              {users.length > 0 &&
                !isFetching &&
                users.map((userMap) => {
                  if (width > 1024) {
                    return (
                      <tr key={userMap.id}>
                        <td className="text-left">{userMap.id}</td>
                        <td className="text-left">{userMap.fullname}</td>
                        <td className="text-left">{userMap.email}</td>
                        <td className="text-left">{userMap.phone}</td>
                        <td className="text-left">
                          {userMap.role.description}
                        </td>
                        <td className="whitespace-nowrap text-left">
                          {userMap.company.name}
                        </td>
                        <td className="text-left">
                          {userMap.branch.description}
                        </td>
                        <td className="text-left">{userMap?.box?.name}</td>
                        {user.roleId == 1 && (
                          <td>{userMap.deleted ? "Si" : "No"}</td>
                        )}
                        <td className="text-left">
                          {userMap.createdAt
                            .substr(0, 10)
                            .split("-")
                            .reverse()
                            .join("/")}
                        </td>
                        <td className="text-center">
                          <button
                            className="bg-transparent"
                            onClick={() => {
                              editUser(userMap.id);
                            }}
                          >
                            <FontAwesomeIcon
                              className="m-1 cursor-pointer secondary-color"
                              icon={faPen}
                            />
                          </button>
                          <button
                            className="bg-transparent"
                            onClick={() => {
                              deleteUser(user.id);
                            }}
                          >
                            <FontAwesomeIcon
                              className="m-1 cursor-pointer secondary-color"
                              icon={faTrash}
                            />
                          </button>
                        </td>
                      </tr>
                    );
                  } else {
                    return (
                      <tr>
                        <label className="font-bold">ID: </label>
                        {userMap.id}
                        <br />
                        <label className="font-bold">NOMBRE: </label>
                        {userMap.fullname}
                        <br />
                        <label className="font-bold">EMPRESA: </label>
                        {userMap.company.name}
                        <br />
                        <label className="font-bold">ROL: </label>
                        {userMap.role.description}
                        <br />
                        <label className="font-bold">ACCION:</label>
                        <button
                          className="m-[1px] bg-transparent"
                          onClick={() => {
                            editUser(userMap.id);
                          }}
                        >
                          <FontAwesomeIcon
                            className="cursor-pointer secondary-color"
                            icon={faPen}
                          />
                        </button>
                        <button
                          className="m-[1px] bg-transparent"
                          onClick={() => {
                            deleteUser(user.id);
                          }}
                        >
                          <FontAwesomeIcon
                            className="cursor-pointer secondary-color"
                            icon={faTrash}
                          />
                        </button>
                      </tr>
                    );
                  }
                })}
              {users.length <= 0 && !isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={11} className="text-center">
                    Sin registros (0)
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <Pagination
            className="users-pagination-container"
            path="usuarios"
            page={page}
            pages={pages}
            total={total}
          />
        </div>
      </Layout>
    </>
  );
};

export default Users;
