import React, { useState, useRef, useEffect } from "react";
import Layout from "../layout";
import { axiosAuth } from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser } from "../store/slices/mainSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DateComponent from "../components/date";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/animations/loading.json";

import { DownloadTableExcel } from "react-export-table-to-excel";
import xls from "../assets/img/xls.png";
import search from "../assets/img/search.webp"; 
import door from "../assets/img/door.png";
import pdf from "../assets/img/pdf.png";
import printer from "../assets/img/printer.png";
import { numberWithCommas } from "../utils/constants";

const BankStatement = () => {
  const tableRef = useRef(null);

  const location = useLocation();
  const navigate = useNavigate();
  const [total, setTotal] = useState(0);
  const [outcomeTotal, setOutcomeTotal] = useState(0);
  const [bigTotal, setBigTotal] = useState(0);
  const dispatch = useDispatch();
  const [isFetching, setIsFetching] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [accountId, setAccountId] = useState(0);
  const [bankStatement, setBankStatement] = useState([]);

  const [dateFromFilter, setDateFromFilter] = useState(() => {
    let MyDateString =
      (
        "0" +
        new Date(new Date().getFullYear(), new Date().getMonth(), 1).getDate()
      ).slice(-2) +
      "/" +
      (
        "0" +
        (new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          1
        ).getMonth() +
          1)
      ).slice(-2) +
      "/" +
      new Date(new Date().getFullYear(), new Date().getMonth(), 1)
        .getFullYear()
        .toLocaleString();

    return MyDateString.replaceAll(".", "")
          .replaceAll("/", "-")
          .split("-")
          .reverse()
          .join("-");
  });
  const [dateToFilter, setDateToFilter] = useState(() => {
    return new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
          .toLocaleDateString()
          .replaceAll("/", "-")
          .split("-")
          .reverse()
          .map((d) => (d.length <= 1 ? "0" + String(d) : d))
          .join("-");
  });

  async function fetchReport() {
    setIsFetching(true);

    let bankStatementReport = await axiosAuth.get(
      "/bankstatement?accountId="+
        accountId+"&dateFrom=" +
        dateFromFilter +
        "&dateTo=" +
        dateToFilter
    );

    if (bankStatementReport.data.error) {
      if (bankStatementReport.data.message === "token") {
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    setIsFetching(false);

    setAccounts(bankStatementReport.data.accounts);
    
    let transfers = [];
    let deposits = []
    let bankstatement = []
    bankStatementReport?.data?.bankstatement?.transfersQ?.map(transfer => transfers.push(transfer));
    bankStatementReport?.data?.bankstatement?.bankdepositsQ?.map(deposit => deposits.push(deposit));

    bankstatement = [...transfers, ...deposits];
    
    setBankStatement(bankstatement);
    
    let toTotal = 0;
    let toOutcomeTotal = 0;
    let toBigTotal = 0;
    bankstatement.map(bnkStatement => {
        toTotal += bnkStatement?.transfer?.payment?.amount || 0;
        toTotal += bnkStatement?.bankdeposit?.amount || 0;
        toBigTotal += bnkStatement?.bankdeposit?.amount || 0;
        
        if(bnkStatement?.transfer?.payment?.purchase){
          toOutcomeTotal += bnkStatement?.transfer?.payment?.amount || 0;
        }

        if(bnkStatement?.transfer?.payment?.sale){
          toBigTotal += (bnkStatement.transfer.payment.sale.vat5 + bnkStatement.transfer.payment.sale.vat10 + bnkStatement.transfer.payment.sale.taxed5 + bnkStatement.transfer.payment.sale.taxed10) - bnkStatement.transfer.payment?.collection?.amount || 0;
        }
        
        if(bnkStatement?.transfer?.payment?.purchase){
          toBigTotal += (bnkStatement.transfer.payment.purchase.vat5 + bnkStatement.transfer.payment.purchase.vat10 + bnkStatement.transfer.payment.purchase.taxed5 + bnkStatement.transfer.payment.purchase.taxed10) - bnkStatement.transfer.payment?.collection?.amount || 0;
        }
    })
    setTotal(toTotal);
    setOutcomeTotal(toOutcomeTotal);
    setBigTotal(toBigTotal);
  }

  useEffect(() => {
      fetchReport();
  }, []);

  return (
    <>
      <Layout>
        <div className="flex flex-col lg:flex-row border border-gray-200 border-r-0 border-b-1">
          <div className="w-[100%] sm:w-[50%] lg:w-[25%] border-none sm:border border-b-0 border-r-2 h-[98%]">
            <h1 className="ml-5 mt-2 text-[20px] secondary-color title font-family-thin">
              Extracto Bancario
            </h1>
            <DateComponent className="!text-[15px] sm:block hidden" />
          </div>

          <div className="flex relative right-[-30px] sm:right-[0px] flex-row justify-start items-start ml-0 sm:ml-[8%] w-[100%] sm:w-[100%] lg:w-[75%] mt-1">
            <div className="flex flex-row justify-start items-center w-[50%] lg:w-[25%]">
              <div className="mr-5 w-[100%]">
                <select
                  id="accountFilter"
                  name="accountFilter"
                  value={accountId}
                  className="input-bg-color appearance-none border w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-sm h-[22px] uppercase !text-[11px]"
                  style={{ lineHeight: "13px" }}
                  onChange={(e) => {
                    setBankStatement([]);
                    setAccountId(e.target.value);
                  }}
                >
                    
                    <option value={0}>Elija una cuenta</option>
                    {accounts.map(account => 
                        <option value={account.id}>{account.name}</option>
                    )}
                </select>

                <input
                  type="date"
                  id="dateFromFilter"
                  name="dateFromFilter"
                  value={dateFromFilter}
                  className="input-bg-color appearance-none border w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-[22px] font-[11px] mt-[1px]"
                  onChange={(e) => {
                    setBankStatement([]);
                    setDateFromFilter(e.target.value);
                  }}
                />

                <input
                  type="date"
                  id="dateToFilter"
                  name="dateToFilter"
                  value={dateToFilter}
                  className="input-bg-color appearance-none border w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-[22px] font-[11px] mt-[1px]"
                  onChange={(e) => {
                    setBankStatement([]);
                    setDateToFilter(e.target.value);
                  }}
                />
              </div>
            </div>

            <div
              className="flex flex-row sm:justify-center mt-1 sm:mt-0 pl-0 sm:pl-8 w-[60%] sm:w-[50%] lg:w-[69%] mt-2.5 h-[75px] mr-0 lg:mr-[8%] overflow-x-auto overflow-y-hidden overflow-visible"
              style={{ margin: "0px auto" }}
            >
              <div className="flex flex-col items-center h-[70px]">
                <button
                  type="button"
                  className="inline-flex flex-col w-[85px] h-[70px] mr-[2px] justify-center items-center rounded ternary-bg-color p-4 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 !text-[11px]"
                  onClick={() => {
                    setBankStatement([]);
                    fetchReport();
                  }}
                >
                  <img src={search} className="w-8" />
                  CONSULTAR
                </button>
              </div>

              <div className="flex flex-col items-center h-[70px]">
                <DownloadTableExcel
                  filename={"EXTRACTO_BANCARIO_" + new Date().toLocaleDateString()}
                  sheet="EXTRACTO_BANCARIO"
                  currentTableRef={tableRef.current}
                >
                  <button
                    type="button"
                    className="hidden sm:inline-flex flex-col w-[85px] h-[70px] justify-center items-center rounded ternary-bg-color p-4 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 !text-[11px] mr-[2px]"
                  >
                    <img src={xls} className="w-6" />
                    PLANILLA ELECTRONICA
                  </button>
                </DownloadTableExcel>
              </div>

              <div className="flex flex-col items-center h-[70px]">
                <button
                  type="button"
                  className="hidden sm:inline-flex flex-col w-[85px] h-[70px] mr-[2px] justify-center items-center rounded ternary-bg-color p-4 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 !text-[11px]"
                  onClick={() => {}}
                >
                  <img src={printer} className="w-8" />
                  IMPRIMIR
                </button>
              </div>

              <div className="flex flex-col items-center h-[70px]">
                <button
                  type="button"
                  className="hidden sm:inline-flex flex-col w-[85px] h-[70px] mr-[2px] justify-center items-center rounded ternary-bg-color p-4 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 !text-[11px]"
                  onClick={() => {}}
                >
                  <img src={pdf} className="w-8" />
                  PDF
                </button>
              </div>

              <div className="flex flex-col items-center h-[70px]">
                <button
                  type="button"
                  className="inline-flex flex-col w-[85px] h-[70px] mr-[2px] justify-center items-center rounded ternary-bg-color p-4 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 !text-[11px]"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <img src={door} className="w-6" />
                  SALIR
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`w-[98%] p-3 pb-0 mx-auto overflow-auto max-h-[400px] relative`}
        >
          <table
            className="w-[98%] table-auto mx-auto w-full text-xs sales-report-table relative"
            ref={tableRef}
          >
            {bankStatement.length > 0 && !isFetching && (
              <thead className="bg-gray-600 !text-white hidden sm:table-header-group">
                <tr className="w-full">
                  <th className="!text-white uppercase">FECHA</th>
                  <th className="!text-white uppercase">NUMERO</th>
                  <th className="!text-white uppercase">OPERACION</th>
                  <th className="!text-white uppercase">CONCEPTO</th>
                  <th className="!text-white uppercase">ENTRADA</th>
                  <th className="!text-white uppercase">SALIDA</th>
                  <th className="!text-white uppercase">SALDO</th>
                </tr>
              </thead>
            )}

            <tbody>
              {isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={20}>
                    <Lottie
                      animationData={loadingAnimation}
                      style={{ width: 100, height: 100, margin: "0 auto" }}
                      loop={true}
                    />
                  </td>
                </tr>
              )}

              { bankStatement.length > 0 && !isFetching && bankStatement.map(bnkStatement => {
                 return(<>
                    <tr>
                        <td>{bnkStatement?.transfer?.createdAt.substr(0,10) || bnkStatement?.bankdeposit?.date?.substr(0,10)}</td>
                        <td>{bnkStatement?.transfer?.id || bnkStatement?.bankdeposit?.id}</td>
                        <td>{bnkStatement?.transfer ? 'TRANSFERENCIA' : ''} {bnkStatement?.bankdeposit ? 'DEPOSITO': ''}</td>
                        <td>
                          {bnkStatement?.transfer?.payment?.sale ? "Cobro de venta Nro. " + bnkStatement?.transfer?.payment?.sale?.number : ""}
                          {bnkStatement?.transfer?.payment?.purchase ? "Pago de compra Nro. " + bnkStatement?.transfer?.payment?.purchase?.number : ""}
                          {bnkStatement?.bankdeposit ? "Deposito Nro. " + bnkStatement?.bankdeposit?.number : ""}
                        </td>
                        <td>{bnkStatement?.transfer?.payment?.sale ? numberWithCommas(bnkStatement.transfer.payment.amount) : ""} {bnkStatement?.bankdeposit?.amount ? numberWithCommas(bnkStatement?.bankdeposit?.amount) : ""}</td>
                        <td>{bnkStatement?.transfer?.payment?.purchase ? numberWithCommas(bnkStatement.transfer.payment.amount) : 0}</td>
                        <td>
                        {bnkStatement?.transfer?.payment?.sale ? numberWithCommas((bnkStatement.transfer.payment.sale.vat5 + bnkStatement.transfer.payment.sale.vat10 + bnkStatement.transfer.payment.sale.taxed5 + bnkStatement.transfer.payment.sale.taxed10) - bnkStatement.transfer.payment?.collection?.amount || 0) : ""}
                          {bnkStatement?.transfer?.payment?.purchase ? numberWithCommas((bnkStatement.transfer.payment.purchase.vat5 + bnkStatement.transfer.payment.purchase.vat10 + bnkStatement.transfer.payment.purchase.taxed5 + bnkStatement.transfer.payment.purchase.taxed10) - bnkStatement.transfer.payment?.collection?.amount || 0) : ""}
                          {bnkStatement?.bankdeposit ? 0 : ""}
                        </td>
                    </tr>
                </>)
                }
              )}

              { bankStatement.length > 0 && !isFetching && (
                <>
                  <tr style={{ lineHeight: "24px" }}>
                    <td></td>
                    <td></td>
                    <td className="text-right text-[15px]" colSpan={2}>
                      <b>TOTAL GENERAL: </b>
                    </td>
                    <td className="text-center text-[15px] border-t-[1px] border-b-[1px] border-gray-400">
                      <b>{numberWithCommas(total)}</b>
                    </td>
                    <td className="text-center text-[15px] border-t-[1px] border-b-[1px] border-gray-400">
                      <b>{numberWithCommas(outcomeTotal)}</b>
                    </td>
                    <td className="text-center text-[15px] border-t-[1px] border-b-[1px] border-gray-400">
                      <b>{numberWithCommas(bigTotal)}</b>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
      </Layout>
    </>
  );
};

export default BankStatement;
