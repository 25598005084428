import React, { useState, Fragment, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Dialog, Transition } from "@headlessui/react";
import Layout from "../layout";
import { axiosAuth } from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser } from "../store/slices/mainSlice";
import { useLocation, useParams } from "react-router-dom";
import Pagination from "../components/pagination";
import DateComponent from "../components/date";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/animations/loading.json";
import paymentmethodsimg from "../assets/img/paymentmethods.webp";
import door from "../assets/img/door.png";
import floppy from "../assets/img/floppy.png";

const PaymentMethods = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [action, setAction] = useState("edit");

  const { page } = useParams();
  const user = useSelector((state) => state.main.user);

  const [saleConditions, setSaleConditions] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);

  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);

  const [paymentMethodId, setPaymentMethodId] = useState(0);
  const [saleConditionId, setSaleConditionId] = useState(0);
  const [name, setName] = useState("");
  const [active, setActive] = useState(1);
  const [deleted, setDeleted] = useState(0);

  async function fetchPaymentMethods() {
    let pageQuery = page === undefined ? 1 : page;

    setIsFetching(true);

    let paymentMethodsRequest = await axiosAuth.get(
      "/paymentmethods?page=" + pageQuery
    );

    if (paymentMethodsRequest.data.error) {
      if (paymentMethodsRequest.data.message === "token") {
        await axiosAuth.post("/logout");
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    setIsFetching(false);

    setSaleConditions(paymentMethodsRequest.data.paymentMethods.saleConditions);

    setPaymentMethods(paymentMethodsRequest.data.paymentMethods.rows);

    setTotal(paymentMethodsRequest.data.paymentMethods.count);
    setPages(paymentMethodsRequest.data.paymentMethods.pages);
  }

  useEffect(() => {
    fetchPaymentMethods();
  }, []);

  useEffect(() => {
    fetchPaymentMethods();
  }, [location]);

  const editPaymentMethod = (paymentMethodId) => {
    setAction("edit");
    setPaymentMethodId(paymentMethodId);

    let paymentMethod = paymentMethods.filter(
      (paymentMethod) => paymentMethod.id === paymentMethodId
    )[0];

    setSaleConditionId(paymentMethod.saleConditionId);
    setName(paymentMethod.name);
    setActive(paymentMethod.active);
    setDeleted(paymentMethod.deleted);

    setOpen(true);
  };

  const clearFields = () => {
    setPaymentMethodId(0);
    setSaleConditionId(0);
    setName("");
    setActive(1);
    setDeleted(0);
  };

  const handleSubmit = async () => {
    if (name === "" || saleConditionId === 0) {
      alert("Debes completar los campos para continuar.");
      return false;
    }

    if (action === "edit") {
      let id = paymentMethodId;
      let newPaymentMethodsObj = [];

      let updatePaymentMethodRequest = await axiosAuth.put("/paymentmethods", {
        id,
        saleConditionId,
        name,
        active,
        deleted,
      });

      if (updatePaymentMethodRequest.data.error) {
        if (updatePaymentMethodRequest.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      paymentMethods.map((paymentMethod) => {
        if (paymentMethod.id == id) {
          newPaymentMethodsObj.push(updatePaymentMethodRequest.data);
        } else {
          newPaymentMethodsObj.push(paymentMethod);
        }
        return "";
      });

      setPaymentMethods(newPaymentMethodsObj);
    } else {
      let createPaymentMethodRequest = await axiosAuth.post("/paymentmethods", {
        saleConditionId,
        name,
        active,
        deleted,
      });

      if (createPaymentMethodRequest.data.error) {
        if (createPaymentMethodRequest.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      let newPaymentMethods = [...paymentMethods];
      newPaymentMethods.push(createPaymentMethodRequest.data);
      setPaymentMethods(newPaymentMethods);
    }

    setOpen(false);
  };

  const deletePaymentMethod = async (paymentMethodId) => {
    if (window.confirm("Desea eliminar esta forma de pago ?")) {
      let paymentMethodDeleteRequest = await axiosAuth.delete(
        "/paymentmethods",
        {
          params: { id: paymentMethodId },
        }
      );

      if (paymentMethodDeleteRequest.data.error) {
        if (paymentMethodDeleteRequest.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      let newPaymentMethodsObj = [];

      paymentMethods.map((paymentMethod) => {
        if (paymentMethod.id !== paymentMethodId) {
          newPaymentMethodsObj.push(paymentMethod);
        } else {
          if (user.roleId == 1) {
            paymentMethod.deleted = 1;
            newPaymentMethodsObj.push(paymentMethod);
          }
        }
        return "";
      });

      setPaymentMethods(newPaymentMethodsObj);
    }
  };

  return (
    <>
      <Layout>
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              clearFields();
              setOpen(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto align-items-center justify-center items-center flex">
              <div className="flex justify-center p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel
                    className="relative transform overflow-hidden rounded-3xl bg-white text-left shadow-xl transition-all sm:my-8 w-[280px]"
                    style={{ marginTop: 45 }}
                  >
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-0">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 sm:text-left">
                          <Dialog.Title
                            as="h1"
                            className="text-center font-regular leading-6 primary-color text-3xl"
                          >
                            {action === "edit" ? "Editar" : "Crear"} forma de
                            pago
                          </Dialog.Title>
                          <div className="mt-5">
                            <div className="w-full max-w-xs">
                              {action === "edit" && (
                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="paymentMethodId"
                                  >
                                    Id
                                  </label>
                                  <input
                                    readOnly
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="paymentMethodId"
                                    type="text"
                                    value={paymentMethodId}
                                  />
                                </div>
                              )}

                              <div className="mb-4">
                                <label
                                  className="block text-gray-700 text-sm font-bold"
                                  htmlFor="paymentConditionId"
                                >
                                  Cond. de venta
                                </label>
                                <select
                                  className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                  id="paymentConditionId"
                                  type="text"
                                  value={saleConditionId}
                                  onChange={(e) =>
                                    setSaleConditionId(e.target.value)
                                  }
                                >
                                  <option key={0} value={0}>
                                    Elija una condicion de venta
                                  </option>
                                  {saleConditions?.map((saleCondition) => (
                                    <option
                                      key={saleCondition.id}
                                      value={saleCondition.id}
                                    >
                                      {saleCondition.description}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              <div className="mb-4">
                                <label
                                  className="block text-gray-700 text-sm font-bold"
                                  htmlFor="name"
                                >
                                  Nombre
                                </label>
                                <input
                                  className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                  id="name"
                                  type="text"
                                  value={name}
                                  onChange={(e) => setName(e.target.value)}
                                />
                              </div>

                              <div className="mb-4">
                                <label
                                  className="block text-gray-700 text-sm font-bold"
                                  htmlFor="active"
                                >
                                  Activa
                                </label>
                                <select
                                  className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                  id="active"
                                  value={active}
                                  onChange={(e) =>
                                    setActive(parseInt(e.target.value))
                                  }
                                >
                                  <option value={0}>No</option>
                                  <option value={1}>Si</option>
                                </select>
                              </div>

                              {user.roleId == 1 && (
                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="deleted"
                                  >
                                    Borrado
                                  </label>
                                  <select
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="deleted"
                                    value={deleted}
                                    onChange={(e) =>
                                      setDeleted(parseInt(e.target.value))
                                    }
                                  >
                                    <option value={0}>No</option>
                                    <option value={1}>Si</option>
                                  </select>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 justify-center flex sm:px-6">
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => {
                          clearFields();
                          setOpen(false);
                        }}
                      >
                        <img src={door} className="w-5" />
                        CERRAR
                      </button>
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => handleSubmit()}
                      >
                        <img src={floppy} className="w-8" />
                        GUARDAR
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <h1 className="ml-5 mt-5 text-3xl secondary-color title font-family-thin">
          Formas de pago
        </h1>
        <DateComponent className="sm:block hidden" />
        <button
          type="button"
          className="relative right-[25px] sm:right-[50px] bottom-[10px] inline-flex w-[88px] float-right m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 !text-sm text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
          style={{ alignItems: "center" }}
          onClick={() => {
            setAction("create");
            setOpen(true);
          }}
        >
          <img src={paymentmethodsimg} className="w-32" />
          CREAR FORMA DE PAGO
        </button>

        <div className="w-[95%] p-5 mx-auto overflow-auto">
          <table className="w-[95%] table-auto mx-auto w-full text-xs">
            <thead>
              <tr>
                <th className="uppercase">Id</th>
                <th className="uppercase">Cond. de venta</th>
                <th className="uppercase">Nombre</th>
                <th className="uppercase">Activa</th>
                {user.roleId == 1 && <th className="uppercase">Borrado</th>}
                <th className="uppercase">Fecha Alta</th>
              </tr>
            </thead>
            <tbody>
              {isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={8}>
                    <Lottie
                      animationData={loadingAnimation}
                      style={{ width: 100, height: 100, margin: "0 auto" }}
                      loop={true}
                    />
                  </td>
                </tr>
              )}
              {paymentMethods.length > 0 &&
                !isFetching &&
                paymentMethods?.map((paymentMethod) => {
                  return (
                    <tr key={paymentMethod.id}>
                      <td>{paymentMethod.id}</td>
                      <td className="text-left">
                        {paymentMethod.salecondition.description}
                      </td>
                      <td className="text-left">{paymentMethod.name}</td>
                      <td>{paymentMethod.active ? "Si" : "No"}</td>
                      {user.roleId == 1 && (
                        <td>{paymentMethod.deleted ? "Si" : "No"}</td>
                      )}
                      <td>
                        {paymentMethod.createdAt
                          .substr(0, 10)
                          .split("-")
                          .reverse()
                          .join("/")}
                      </td>
                      <td className="text-center">
                        <button
                          className="bg-transparent"
                          onClick={() => {
                            editPaymentMethod(paymentMethod.id);
                          }}
                        >
                          <FontAwesomeIcon
                            className="m-1 cursor-pointer secondary-color"
                            icon={faPen}
                          />
                        </button>
                        <button
                          className="bg-transparent"
                          onClick={() => {
                            deletePaymentMethod(paymentMethod.id);
                          }}
                        >
                          <FontAwesomeIcon
                            className="m-1 cursor-pointer secondary-color"
                            icon={faTrash}
                          />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              {paymentMethods.length <= 0 && !isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={8} className="text-center">
                    Sin registros (0)
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <Pagination
            path="formasdepago"
            page={page}
            pages={pages}
            total={total}
          />
        </div>
      </Layout>
    </>
  );
};

export default PaymentMethods;
