import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//import Icon from "./icon";

import { NavLink, useLocation, useNavigate } from "react-router-dom";

import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

import { motion } from "framer-motion";
import { menuitems } from "../utils/constants";

import logoLight from "../assets/img/logo-inversed.png";
import symbol from "../assets/img/symbol.png";

import { useSelector } from "react-redux";

const Aside = () => {
  const user = useSelector((state) => state.main.user);
  const location = useLocation();
  const navigate = useNavigate();

  const [menu, setMenu] = useState([]); //menuitems

  useEffect(() => {
    let menuObj = [];
    let existsOnUserAccess = false;

    user.access.map((menu) => {
      if (
        location.pathname.indexOf(menu.path) > -1 ||
        location.pathname.indexOf(menu?.submenu?.path) > -1
      ) {
        existsOnUserAccess = true;
      }

      if (menu.submenuId == null) {
        menuObj.push({
          id: menu.menuId,
          path: menu.menu.path,
          icon: menu.menu.icon,
          name: menu.menu.name,
          order: menu.menu.order,
        });
      } else {
        let alreadyInObject = false;

        menuObj.map((menuOb) => {
          if (menuOb.id == menu.menuId) {
            alreadyInObject = true;
          }
        });

        if (!alreadyInObject) {
          menuObj.push({
            id: menu.menuId,
            icon: menu.menu.icon,
            name: menu.menu.name,
            order: menu.menu.order,
            submenu: {
              open: false,
              items: [
                {
                  path: menu.submenu.path,
                  icon: menu.submenu.icon,
                  name: menu.submenu.name,
                },
              ],
            },
          });
        } else {
          menuObj.map((menuOb) => {
            if (menuOb.id == menu.menuId) {
              if (menuOb.submenu == undefined) {
                menuOb.submenu = { open: false, items: [] };
              }
              menuOb.submenu.open = false;
              menuOb.submenu.items.push({
                path: menu.submenu.path,
                icon: menu.submenu.icon,
                name: menu.submenu.name,
              });
            }
          });
        }
      }
    });

    if (!existsOnUserAccess) {
      //navigate("/");
    }

    menuObj.map((menuOb, i) => {
      if (Object.hasOwn(menuOb, "submenu")) {
        let setSubmenuOpen = false;

        menuOb.submenu.items.map((item) => {
          if (
            location.pathname != "/" &&
            location.pathname.indexOf(item.path) > -1
          ) {
            setSubmenuOpen = true;
          }
        });

        if (setSubmenuOpen) {
          menuOb.submenu.open = true;
        }

        menuOb.submenu.items = menuOb.submenu.items.sort(function (a, b) {
          const nameA = a.name.toUpperCase(); // ignore upper and lowercase
          const nameB = b.name.toUpperCase(); // ignore upper and lowercase

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        });
      }
    });

    menuObj = menuObj.sort(function (a, b) {
      if (a.order < b.order) {
        return -1;
      }
      if (a.order > b.order) {
        return 1;
      }

      // names must be equal
      return 0;
    });

    // menuObj = menuObj.sort(function (a, b) {
    //   const nameA = a.name.toUpperCase(); // ignore upper and lowercase
    //   const nameB = b.name.toUpperCase(); // ignore upper and lowercase

    //   if (nameA < nameB) {
    //     return -1;
    //   }
    //   if (nameA > nameB) {
    //     return 1;
    //   }

    //   // names must be equal
    //   return 0;
    // });

    setMenu(menuObj);
    console.log(menuObj);
  }, [user.access]);

  const handleItemClick = (item) => {
    console.log(item);
    if (item.name === "Configuración de impresiones") {
      window.open("/" + item.path, "_blank");
    } else if (item.submenu) {
      let newMenu = [...menu];
      newMenu.forEach((newMenuItem) => {
        if (newMenuItem.submenu) {
          if (newMenuItem.name !== item.name) {
            newMenuItem.submenu.open = false;
          }
        }
        if (newMenuItem.name === item.name) {
          newMenuItem.submenu.open = !newMenuItem.submenu.open;
        }
      });
      setMenu(newMenu);

    }
  };
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const aItem = {
    hidden: { display: "none" },
    show: { display: "block" },
  };

  const listItem = {
    hidden: { opacity: 0, x: -25 },
    show: { opacity: 1, x: 0 },
  };

  const subListItem = {
    hidden: { opacity: 0, y: -25, x: 0 },
    show: { opacity: 1, y: 0, x: 0 },
  };

  const subcontainer = {
    hidden: { height: 0, opacity: 0 },
    show: {
      height: "auto",
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const chevronVariants = {
    normal: { rotate: 0 },
    open: { rotate: 180, x: 10 },
  };

  return (
    <aside className="m-auto h-full">
      {/* ref={scope} */}
      <div className="logo mt-2">
        <NavLink to="/">
          <img
            alt="logoLight"
            style={{ width: 60, margin: "0px auto", marginTop: 25 }}
            src={logoLight}
            
          />
        </NavLink>
      </div>

      <h5 className="w-full text-center text-xs !text-white mt-5 font-bold">
        {user.company.name}
      </h5>

      <motion.ul
      initial="hidden"
      animate="show"
      variants={container}
      className="menu w-full mt-10 overflow-y-auto overflow-x-hidden"
    >
      {menu.map((item, i) =>
        item.submenu === undefined ? (
          item.name === "Configuración de impresiones" ? (
            <motion.li
              key={i}
              initial={false}
              animate={false}
              variants={null}
              className="mt-2 cursor-pointer relative z-10"
              onClick={() => handleItemClick(item)}
            >
              <FontAwesomeIcon icon={item.icon} />
              <p className="hidden ml-2">{item.name}</p>
            </motion.li>
          ) : (
            <NavLink
              key={i}
              to={item.path !== "/" ? `/${item.path}` : item.path}
            >
              <motion.li
                key={i}
                variants={listItem}
                className="mt-2 cursor-pointer relative z-10"
              >
                <FontAwesomeIcon icon={item.icon} />
                <p className="hidden ml-2">{item.name}</p>
              </motion.li>
            </NavLink>
          )
        ) : (
          <motion.li
            key={i}
            variants={listItem}
            className="mt-2 cursor-pointer flex flex-col"
            onClick={() => handleItemClick(item)}
          >
            <div className="subitems-container flex flex-row w-full items-center">
              <FontAwesomeIcon icon={item.icon} />
              <p className="hidden ml-2">{item.name}</p>
              <motion.div
                initial="normal"
                animate={item.submenu.open ? "open" : "normal"}
                variants={chevronVariants}
                className="relative top-[-2px]"
              >
                <FontAwesomeIcon
                  className="menu-chevron hidden ml-2"
                  icon={faChevronDown}
                />
              </motion.div>
            </div>
            {item.submenu && (
              <motion.ul
                initial="hidden"
                animate={item.submenu.open ? "show" : "hidden"}
                variants={subcontainer}
                className="submenu-item-container mt-[2px] w-full flex flex-col items-center"
              >
                {item.submenu.items.map((subitem, subIndex) => (
                  <motion.a
                    key={subIndex}
                    href={`/${subitem.path}`}
                    className={
                      location.pathname !== "/" && location.pathname.indexOf(subitem.path) > -1
                        ? "active w-full"
                        : "w-full"
                    }
                    variants={aItem}
                  >
                    <motion.li
                      key={subIndex}
                      variants={subListItem}
                      className="mt-[10px] pl-[0%]"
                    >
                      <div className="flex flex-row w-[95%] items-center">
                        <FontAwesomeIcon icon={subitem.icon} />
                        <p className="hidden ml-2">{subitem.name}</p>
                      </div>
                    </motion.li>
                  </motion.a>
                ))}
              </motion.ul>
            )}
          </motion.li>
        )
      )}
    </motion.ul>
    </aside>
  );
};

export default Aside;
